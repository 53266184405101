import { Panel, Push } from '@mosru/esz_uikit';
import React from 'react';
import { LmLoader } from '@mes-ui/lemma';

type Props = {
  size: number;
  hasPanel?: boolean;
};

export const LoaderCustom = ({ hasPanel, ...props }: Props) => {
  return hasPanel ? (
    <Panel>
      <LoaderInner {...props} />
    </Panel>
  ) : (
    <LoaderInner {...props} />
  );
};

const LoaderInner = ({ size }: Omit<Props, 'hasPanel'>) => (
  <div className="flex justify-center flex-column items-center">
    <Push
      orientation="vertical"
      size={size}
    />
    <LmLoader view="block" />
    <Push
      orientation="vertical"
      size={size}
    />
  </div>
);
