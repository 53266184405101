import React from 'react';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import FormikToggle from '../../../../components/formik/formik-toggle';
import { TemplateService } from '../../../../types/service';

export const Tests = () => {
  const { values } = useFormikContext<TemplateService.Data>();
  const canEditToursNumber = !values?.test?.isRequired;

  return (
    <Panel
      title={() => (
        <div className="flex">
          Вступительные испытания
          <div className="flex font-weight-base">
            <Push
              orientation="horizontal"
              size={16}
            />
            <FormikToggle
              label="Проводятся"
              size="small"
              name="test.isRequired"
            />
          </div>
        </div>
      )}
    >
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Количество туров</div>
          <div className="table-data__body">
            <FormikInputNumber
              disabled={canEditToursNumber}
              name="test.toursNumber"
              placeholder="0"
            />
          </div>
          <div className="table-data__control">
            <FormikToggle
              size="small"
              name="test.canChangeTestAndToursNumber"
            />
          </div>
        </div>
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            <div>
              Срок предоставления док-ов / выкупа брони, дней <span className="table-data__required" />
            </div>
          </div>
          <div className="table-data__body">
            <FormikInputNumber
              placeholder="0"
              name="test.periodGettingDocument"
            />
          </div>
          <div className="table-data__control">
            <FormikToggle
              name="test.canChangePeriodGettingDocument"
              size="small"
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};
