import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { formatBytes } from '../../../../../../../lib/utils/format-bytes';
import { FileData } from '../../../../../../../types/document-modal-other';

type Props = {
  id: number;
  name: string;
  size: number;
  accessCheck: boolean;
  fileIds: number[];
  files: FileData[];
  setFiles: (value: FileData[]) => void;
  setFileIds: (value: number[]) => void;
};

export const File: React.FC<Props> = ({ files, setFiles, setFileIds, fileIds, id, name, size, accessCheck }) => {
  const { setFieldValue } = useFormikContext<any>();

  const deleteFile = () => {
    const newFileIds = fileIds.filter((file) => file !== id);

    setFileIds(newFileIds);
    setFiles(files.filter((file: { id: number }) => file.id !== id));
    setFieldValue('fileIds', newFileIds);
  };

  return (
    <Fragment key={id}>
      <Push size={8} />
      <div className="auth-file">
        <div className="auth-file__object">
          <LmIcon
            icon="filled-files-file"
            color="var(--LM-neutrals-day-700)"
          />
        </div>
        <div className="auth-file__body">
          <div className="auth-file__content">
            {name}
            <div className="auth-file__size">{formatBytes(size)}</div>
          </div>
          <button
            disabled={!accessCheck}
            type="button"
            onClick={deleteFile}
            className="auth-file__btn-close"
          >
            <LmIcon
              icon="filled-edit-close"
              size={20}
              color="var(--LM-neutrals-day-700)"
            />
          </button>
        </div>
      </div>
    </Fragment>
  );
};
