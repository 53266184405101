import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { Document } from '../../types/document';

const getUnsign = async (data: { signId: number; allTypes: boolean; accessObjectId: number }): Promise<Document> =>
  await fetchRequest.get(`${apiService.data}/Document/Unsign`, data);

const documentApi = {
  getUnsign,
};

export default documentApi;
