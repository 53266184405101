import { SystemPropertyEnum } from '../../mock-data/system-property-enum';
import { fetchRequest } from './index';
import { apiService } from '../../config/constants';

export const getSystemProperty = async (name: SystemPropertyEnum): Promise<string> =>
  (
    await fetchRequest.get(`${apiService.data}/SystemProperty`, {
      name,
    })
  ).data;

const systemPropertyApi = {
  getSystemProperty,
};

export default systemPropertyApi;
