import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { getParallelLabel } from '../../../../lib/utils/service';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

export type ParallelOptionsByResponse = {
  id: number;
  parallelName: string;
};

type ParallelFieldProps = FieldProps<SelectOptionType>;

const ParallelField = ({ label = 'Параллель обучения', name, editMode, required, parent }: ParallelFieldProps) => {
  const [options, setOptions] = useState<SelectOptionType[]>([]);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const parallelList = parent ? `${parent}.${name}List` : `${name}List`;

  const fetchOptions = useCallback(async () => {
    const parallels = await dictionariesApi.getParallels(userProfile.organizationId);

    setOptions(parallels);
  }, [userProfile.organizationId]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const [fieldValue, , helpersValue] = useField(parallelList);

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={getParallelLabel(fieldValue?.value)}
    >
      <FormikSelect
        required
        size="small"
        options={options}
        name={parallelList}
        placeholder={fieldValue.value?.length ? '' : 'Выберите...'}
        hasDeletableOptions
        withSearch
        isMulti
        defaultValue={fieldValue.value}
        selectedValue={(options: any) => {
          helpersValue.setValue(options || []);
        }}
        maxMenuHeight={parent ? undefined : 150}
      />
    </Field>
  );
};

export default ParallelField;
