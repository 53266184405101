import React from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmTooltip } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import OrganizationField from '../../components/fields/organization';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { ServiceDuration } from '../../components/fields/service-duration';
import { Dictionary } from '../../components/fields/dictionary';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { GroupMembersField } from '../../components/fields/group-members';
import SimpleInput from '../../../../components/fields/simple-input';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { hasGeneralAccess } from '../../../../lib/utils';
import { generalAccess } from '../../../../types/authorization-data';

const CreateInfo = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const [typeOfAttendanceField] = useField('info.typeOfAttendanceId');
  const [, , rulesOfAttendanceHelper] = useField('rulesOfAttendance');

  return (
    <Panel title={() => <>Сведения о детском объединении</>}>
      <div className="container">
        <OrganizationField
          editMode
          name="organization"
          parent="info"
          required
          disabled={!hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV)}
          label={
            <>
              Образовательная <br /> организация
            </>
          }
        />
        <SimpleInput
          name="info.name"
          label="Наименование объединения"
          editMode
          required
        />
        <ClassificatorNameField
          editMode
          name="classificator"
          parent="info"
          modalTitle="Реестр базовых направлений"
        />
        <LessonLevelField
          label="Уровень программы"
          editMode
          name="programmLevel"
          parent="info"
          disabledPlaceholder="Сначала выберите вид деятельности"
          placeholder="Выберите..."
          required
        />

        <ServiceDuration
          editMode
          parent="info"
        />
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Занятия</div>
          <div className="table-data__body">
            <div className="flex items-start">
              <div className="table-data__group">
                <div className="table-data__label">
                  Форма <span className="table-data__required" />
                </div>
                <div
                  className="table-data__body"
                  style={{
                    width: 200,
                  }}
                >
                  <Dictionary
                    name="serviceForm"
                    parent="info"
                    editMode
                    isNotTableMain
                    dictionaryFunc={dictionariesApi.getServiceForms}
                  />
                </div>
              </div>
              <Push
                size={16}
                orientation="horizontal"
              />
              <div className="table-data__group">
                <div className="table-data__label">
                  Вид посещения <span className="table-data__required" />
                </div>
                <div
                  className="table-data__body"
                  style={{
                    width: 200,
                  }}
                >
                  <Dictionary
                    name="typeOfAttendance"
                    parent="info"
                    editMode
                    isNotTableMain
                    dictionaryFunc={() => dictionariesApi.getVisitTypes(true)}
                    onChange={(value) => {
                      if (value === 1) {
                        rulesOfAttendanceHelper.setValue('');
                      }
                    }}
                  />
                </div>
              </div>
              <Push
                size={16}
                orientation="horizontal"
              />
              <div className="table-data__group">
                <div className="table-data__label">Часов в неделю</div>
                <div
                  className="table-data__body"
                  style={{
                    width: 105,
                  }}
                >
                  <FormikInputNumber
                    name="info.hoursPerWeek"
                    placeholder="0"
                    maxLength={2}
                    max={99}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <GroupMembersField
          parent="info"
          editMode
        />

        <SimpleTextArea
          name="info.programmService"
          label="Описание объединения"
          editMode
          required
          placeholder="Введите..."
          maxLength={1000}
        />
        {typeOfAttendanceField.value === 2 ? (
          <SimpleTextArea
            name="info.rulesOfAttendance"
            label={
              <div className="icon-group">
                <div className="icon-group__text">Правила посещения занятий</div>
                <div className="icon-group__icon">
                  <LmTooltip
                    withArrow
                    placement="bottom"
                    content="Здесь вы можете указать информацию о правилах посещения онлайн-кружка: на какой платформе проводятся занятия, нужно ли дополнительное оборудование, будут ли записи занятий и многое другое. Эта информация передается на Mos.ru"
                  >
                    <LmIcon
                      icon="outline-notifications-info"
                      size={18}
                      color="var(--LM-neutrals-day-700)"
                    />
                  </LmTooltip>
                </div>
              </div>
            }
            editMode
            placeholder="Введите..."
            maxLength={1000}
          />
        ) : null}
      </div>
    </Panel>
  );
};

export default CreateInfo;
