import moment from 'moment';

export const formatDate = (value: string | Date) => moment(value).format('DD.MM.YYYY');

export const formatDateAndTime = (value: string) => moment(value).format('DD.MM.YYYY HH:mm');

export const formatTime = (value: string | undefined) => (value ? moment(value, 'HH:mm:ss').format('HH:mm') : '');

export const formatTimeFromDate = (value: string | undefined) =>
  value ? moment(value, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') : '';

export const formatTimeWithSeconds = (value: string | undefined) =>
  value ? moment(value, 'HH:mm').format('HH:mm:ss') : '';

export const deepReplaceDateToString = (object: any) => {
  if (object instanceof Date) {
    if (moment(object).isValid()) {
      return moment(object).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      return null;
    }
  }

  let p;

  if (typeof object === 'object' && object !== null) {
    const newObject: typeof object = Array.isArray(object) ? [] : {};

    for (p in object) {
      newObject[p] = deepReplaceDateToString(object[p]);
    }

    return newObject;
  }

  return object;
};

export function getDate(date: Date | string): Date {
  switch (typeof date) {
    case 'string':
      return moment(date).toDate();
    default:
      return date;
  }
}

export function getStartDate(date: Date | string): Date {
  return moment(date).startOf('day').toDate();
}

export function getSelectedDate(date: Date | string | null | undefined): Date | undefined {
  return date ? getDate(date) : undefined;
}
