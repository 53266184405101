import React from 'react';
import { LmInfoBox, LmInfoBoxProps } from '@mes-ui/lemma';
import { Push } from '@mosru/esz_uikit';

type InfoboxRefusalProps = Pick<LmInfoBoxProps, 'description'>;

const DEFAULT_DESC =
  'В данном статусе отказ в зачислении недоступен. Для заявителя доступен функционал отзыва заявления на портале Mos.ru';

const InfoboxRefusal = ({ description = DEFAULT_DESC }: InfoboxRefusalProps) => {
  return (
    <div className="container">
      <LmInfoBox
        dataTest="emptyReasons"
        className="infobox--full-width"
        variant="alert"
        hasBorder
        description={description}
        hidenFooter
      />
      <Push size={20} />
    </div>
  );
};

export default InfoboxRefusal;
