/** Сокращение до одних чисел (без пробелов) */
export const cutToNumbers = (value: string | null) => value && value.replace(/[^\d]/g, '');

/** Конвертация номера телефона в формат (XXX) XXX-XX-XX с/без префикса +7 */
export const formatPhoneNumber = (number: string | null | undefined, hasPrefix = true, hasDash = true) => {
  const PHONE_NUMBER_LENGTH = 11;

  if (!number) {
    return number;
  }

  let formatNumber = cutToNumbers(number.replace('+7', ''));

  if (formatNumber?.length === PHONE_NUMBER_LENGTH && (formatNumber[0] === '8' || formatNumber[0] === '7')) {
    formatNumber = formatNumber.slice(1);
  }

  const separator = hasDash ? '-' : ' ';

  return (
    formatNumber &&
    (hasPrefix
      ? formatNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/i, `+7 ($1) $2${separator}$3${separator}$4`)
      : formatNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/i, `($1) $2${separator}$3${separator}$4`))
  );
};

export const addPhonePrefix = (value: string) => value && `+7 ${value}`;
