import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { downloadBlobFile, hasAccessObjectAny, hasGeneralAccess, sliceText } from '../../../../lib/utils';
import { langApi } from '../../../../types/lang';
import { accessAction, accessObject, generalAccess } from '../../../../mock-data/access-enum';
import { AuthorizationData } from '../../../../types/authorization-data';
import RequestStatusName from './column/request-status-name';
import ChildName from './column/child-name';
import RequestNumber from './column/request-number';
import requestsApi from '../../../../lib/api/requests';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';

export const dogm = (allowedEducationTypeId?: number, userProfile?: AuthorizationData) => {
  return [
    {
      dataIndex: 'requestNumber',
      title: 'Номер',
      render: (item: any) => (
        <RequestNumber
          id={item.id}
          requestNumber={item.requestNumber}
        />
      ),
      width: '108px',
    },
    {
      dataIndex: 'requestSourceName',
      title: 'Источник',
      render: (item: any) => item.requestSourceName,
      width: '100px',
    },
    {
      dataIndex: 'requestDate',
      title: 'Подано',
      render: (item: any) => item.requestDate,
      width: '100px',
    },
    {
      dataIndex: 'childName',
      title: 'ФИО ребёнка',
      render: (item: any) => (
        <ChildName
          childName={item.childName}
          hasInContingent={item.hasInContingent}
          childDateOfBirth={item.childDateOfBirth}
        />
      ),
      width: '13%',
    },
    {
      dataIndex: 'applicantName',
      title: 'ФИО заявителя',
      render: (item: any) => (
        <>
          {item.applicantName}
          <Push size={4} />
          <div className="color-gray-dark">{item.applicantPhone}</div>
        </>
      ),
      width: '148px',
    },
    {
      dataIndex: 'serviceName',
      title: langApi.get(allowedEducationTypeId, 'ServiceShortIm'),
      render: (item: any) => sliceText(item.serviceName, 50),
      width: '400px',
    },
    {
      dataIndex: 'placeAddress',
      title: langApi.get(allowedEducationTypeId, 'PlaceAddressName'),
      render: (item: any) => {
        return (
          <div
            style={{
              fontWeight: 400,
              textAlign: 'left',
              minWidth: 116,
            }}
          >
            {userProfile && hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) && (
              <div>{item.organizationName}</div>
            )}
            <div
              className={
                userProfile && hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) ? 'color-gray-dark' : ''
              }
            >
              {item.placeAddress}
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: 'serviceClassCode',
      title: 'Код группы обучения',
      render: (item: any) => item.serviceClassCode,
      width: '100px',
    },
    {
      dataIndex: 'serviceClassName',
      title: 'Наименование группы обучения',
      render: (item: any) => item.serviceClassName,
      width: '100px',
    },
    {
      dataIndex: 'contract',
      title: 'Договор',
      render: (item: any) => (
        <>
          {item.contractNumber && (
            <button
              id="btn-contract"
              type="button"
              className="icon-group"
              onClick={async () => {
                const response = await requestsApi.getContract(item.contractId);

                if (response) {
                  downloadBlobFile(response.contentDisposition, response.blob);
                }
              }}
            >
              <span className="icon-group__text font-weight-bold color-primary">№{item.contractNumber}</span>
            </button>
          )}
          {item.contractStatus && item.contractDate && (
            <>
              <Push size={4} />
              <div>
                {item.contractStatus} от {item.contractDate}
              </div>
            </>
          )}
        </>
      ),
      width: '140px',
    },
    {
      dataIndex: 'requestStatusName',
      title: 'Статус заявления',
      render: (item: any) =>
        (item.requestStatusId !== RequestStatusEnum.WaitingSendingDocuments &&
          item.requestStatusId !== RequestStatusEnum.WaitingDocumentVerification) ||
        (userProfile &&
          (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
            hasGeneralAccess(userProfile, generalAccess.AdminView) ||
            hasAccessObjectAny(userProfile, [accessObject.RequestDOGM], accessAction.ViewRegistry))) ? (
              <RequestStatusName
                id={item.id}
                documentDate={item.documentDate}
                actionDeadline={item.actionDeadline}
                documentNumber={item.documentNumber}
                enrollmentDate={item.enrollmentDate}
                requestStatusId={item.requestStatusId}
                requestStatusName={item.requestStatusName}
              />
            ) : null,
      width: '240px',
    },
  ];
};
