import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistory from '../../../components/change-history';
import { routes } from '../../../config/constants';
import Favorite from '../../../components/favorite';
import TemplateServicesDetails from './details';
import { getHeaderLink, getTabsButton } from '../utils';
import { ServiceContext } from '../index';
import { generateLink, goBack, hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import ToArchiveModal from '../components/modals/to-archive';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import { userProfileSelector } from '../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { AppState } from '../../../redux/types/state';
import RemoveModal from '../../../components/remove-modal';
import { templateApi } from '../../../lib/api/template';
import history from '../../../history';

const TemplateServices = () => {
  const { serviceData, educationTypes, type, accessAddArchive, accessPanelEdit, routeBack } =
    useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);

  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const showCreateByTemplate = useMemo(
    () =>
      (hasAccessObjectAny(
        userProfile,
        [accessObject.ServiceSport, accessObject.ServiceArtHouse],
        accessAction.CreateByTemplate
      ) ||
        hasGeneralAccess(userProfile, generalAccess.AdminEdit)) &&
      serviceData.serviceStatusId !== ServiceStatusEnum.Arhive,
    [userProfile, serviceData.serviceStatusId]
  );

  const canEdit = useMemo(
    () =>
      hasAccessObjectAny(userProfile, [accessObject.TemplateArtHouse, accessObject.TemplateSport], accessAction.Edit) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const showCopyButton = useMemo(
    () => canEdit && serviceData.serviceStatusId !== ServiceStatusEnum.Arhive && serviceData.id > 0,
    [canEdit, serviceData.serviceStatusId, serviceData.id]
  );

  const showDelButton = useMemo(
    () =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.TemplateArtHouse, accessObject.TemplateSport],
        accessAction.Archive
      ) &&
      serviceData.serviceStatusId !== ServiceStatusEnum.Arhive &&
      serviceData.serviceStatusId !== ServiceStatusEnum.Signed &&
      !serviceData.hasSlaveServices &&
      serviceData.id > 0,
    [serviceData.id, serviceData.serviceStatusId, serviceData.hasSlaveServices, userProfile]
  );

  const showFavorite = !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);

  const handleCreateService = () => {
    history.push(
      generateLink(routes.createServiceByTemplate, {
        templateId: serviceData.id,
      })
    );
  };

  const handleCopyTemplate = () => {
    history.push(
      generateLink(routes.copyTemplate, {
        id: serviceData.id,
      })
    );
  };

  const openArchiveModal = () => {
    setShowModalArchive(true);
  };

  const openDeleteModal = () => {
    setShowModalDelete(true);
  };
  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (showCreateByTemplate) {
      buttons.push({
        dataTest: 'serviceFromTemplate',
        icon: 'filled-edit-plus',
        buttonText: 'Услуга на основе шаблона',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleCreateService,
      });
    }
    if (showCopyButton) {
      buttons.push({
        dataTest: 'copyTemplate',
        icon: 'outline-edit-copy',
        buttonText: 'Копировать шаблон',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleCopyTemplate,
      });
      if (checkAccessAddArchive) {
        buttons.push({
          dataTest: 'toArchive',
          icon: 'filled-hardware-hard-drive',
          buttonText: 'В архив',
          rounded: 'small',
          variant: 'secondary',
          onClick: openArchiveModal,
        });
      }
      if (showDelButton) {
        buttons.push({
          dataTest: 'delete',
          icon: 'filled-edit-trash-alt',
          buttonText: 'Удалить',
          rounded: 'small',
          variant: 'secondary',
          onClick: openDeleteModal,
        });
      }
    }

    return buttons;
  };

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={() => goBack(routeBack)}
        title="Шаблон услуги"
        dataTest="templateServiceSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Шаблоны услуг',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          {
            label: 'Описание шаблона услуги',
          },
        ]}
        tabs={[
          {
            title: 'Основные сведения',
            value: 1,
            dataTest: 'basicInfo',
          },
          {
            title: 'История изменений',
            value: 2,
            dataTest: 'changeHistory',
          },
        ]}
        className="content-panel"
        panels={[
          {
            children: <TemplateServicesDetails />,
            value: 1,
          },
          {
            children: (
              <ChangeHistory
                name=""
                entityTypes={entityTypesForHistory.services}
                extendEntityGuid={serviceData.extendEntityGuid}
              />
            ),
            value: 2,
            className: 'content-panel content-panel--auto',
            noPadding: true,
          },
        ]}
        buttonsOrAnyActions={getButtons()}
        description={getTabsButton(serviceData.serviceStatusId, serviceData.serviceStatusName)}
        titleChildren={
          showFavorite && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="шаблон услуги"
              entityTypeId={EntityTypeEnum.Service}
              currentEntityType={serviceData.info.name}
            />
          )
        }
      />
      <ToArchiveModal
        onCloseHandler={() => setShowModalArchive(false)}
        show={showModalArchive}
      />
      <RemoveModal
        show={showModalDelete}
        onRemoveHandler={async () => {
          await templateApi.deleteTemplate(serviceData.id);
          history.push(routes.myTemplates);
        }}
        title="Удаление шаблона услуги"
        deleteBtnTitle="Да, продолжить"
        onCloseHandle={() => setShowModalDelete(false)}
        description="Внимание! Введенные данные будут удалены из системы. Продолжить?"
      />
    </>
  );
};

export default TemplateServices;
