import React from 'react';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import { EducationTypeEnum } from '../../../../types/education-type';
import FieldAsyncSelect from './async-select';

type Props = {
  values: SearchRequestsInitialFormData;
  allowedEducationTypeId: number;
  label: string;
  placeholder: string;
};

const FieldServiceClass: React.FC<Props> = ({ values, allowedEducationTypeId, label, placeholder }) => {
  return (allowedEducationTypeId & EducationTypeEnum.DayCareCentersEducation) !== 0 ? (
    <FieldAsyncSelect
      nameFieldId="serviceClassId"
      nameFieldName="serviceClassName"
      label={label}
      placeholder={placeholder}
      showTooltip
      disabledPortalTooltip
      loadOptions={async (query) => await lookupApi.getServiceClass(query, values.serviceId, values.educationTypeId)}
    />
  ) : null;
};

export default FieldServiceClass;
