import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LmDatePicker, LmFilterVertical, LmSelectNew } from '@mes-ui/lemma';
import securityApi from '../../lib/api/security';
import { initialChangeHistoryFilterFormData } from '../../mock-data/change-history-filter';
import { EntityTypeEnum } from '../../mock-data/entity-type-enum';
import { SelectOptionType } from '../../types/entities';
import { HistoryFilterFormData } from '../../types/history';

type ChangeHistorySearchProps = {
  searchData: HistoryFilterFormData;
  onChangeSearch: Dispatch<SetStateAction<HistoryFilterFormData>>;
  entityTypes: EntityTypeEnum[];
};

const ChangeHistorySearch = ({ searchData, onChangeSearch, entityTypes }: ChangeHistorySearchProps) => {
  const [actionOptions, setActionOptions] = useState<SelectOptionType[]>([
    {
      label: 'Все',
      value: 0,
    },
  ]);
  const [fieldOptions, setFieldOptions] = useState<SelectOptionType[]>([
    {
      label: 'Все',
      value: 0,
    },
  ]);

  useEffect(() => {
    const fetch = async () => {
      for (const entityType of entityTypes) {
        const actions = await securityApi.getActions(entityType);

        setActionOptions((prev) => prev.concat(actions));
      }
      const fields = await securityApi.getFields(entityTypes);

      setFieldOptions((prev) => prev.concat(fields));
    };

    fetch();
  }, [entityTypes]);

  const getCurrentValue = (type: string) => {
    return type === 'action'
      ? actionOptions.find((item) => item.value === searchData.action) || {
        value: 0,
        label: 'Все',
      }
      : fieldOptions.find((item) => item.value === searchData.field) || {
        value: 0,
        label: 'Все',
      };
  };

  return (
    <LmFilterVertical
      dataTest="favoriteFilter"
      classNameContent="filter-content"
      title="Фильтр"
      onClear={() => {
        onChangeSearch(initialChangeHistoryFilterFormData);
      }}
      hidePrimaryButton
      hideSecondaryButton
    >
      <>
        <LmDatePicker
          dataTest="searchData"
          label="Дата или интервал дат"
          name="searchData"
          selectedDay={searchData.date?.[0] || undefined}
          lastSelectDay={searchData.date?.[1] || undefined}
          inputSize="small"
          isIntervalDate
          readOnly
          fullWidth
          onChangeDate={(date) =>
            onChangeSearch((prev) => ({
              ...prev,
              date: date as Date[],
            }))
          }
          placeholder="ДД.ММ.ГГГГ — ДД.ММ.ГГГГ"
          disablePortal
        />
        <LmSelectNew
          dataTest="selectAction"
          label="Действие"
          name="action"
          onChange={(selectedOption: SelectOptionType | null) => {
            if (selectedOption) {
              const { value } = selectedOption as SelectOptionType;

              onChangeSearch((prev) => ({
                ...prev,
                action: value as number,
              }));
            }
          }}
          settingTooltip={{
            showTooltip: true,
            disabledPortalTooltip: true,
          }}
          options={actionOptions}
          value={getCurrentValue('action')}
          placeholder="Выберите действие..."
          size="small"
          withSearch
          grouped={false}
          multiple={false}
          settingDropdownAsPopover={{
            disablePortal: true,
          }}
        />
        <LmSelectNew
          dataTest="selectField"
          name="field"
          label="Поле"
          onChange={(selectedOption: SelectOptionType | null) => {
            if (selectedOption) {
              const { value } = selectedOption as SelectOptionType;

              onChangeSearch((prev) => ({
                ...prev,
                field: value as string,
              }));
            }
          }}
          settingTooltip={{
            showTooltip: true,
            disabledPortalTooltip: true,
          }}
          options={fieldOptions}
          value={getCurrentValue('field')}
          placeholder="Выберите поле..."
          size="small"
          withSearch
          grouped={false}
          multiple={false}
          settingDropdownAsPopover={{
            disablePortal: true,
          }}
        />
      </>
    </LmFilterVertical>
  );
};

export default ChangeHistorySearch;
