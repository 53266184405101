import React, { useEffect, useState } from 'react';
import { LmSelectNew, LmToggle } from '@mes-ui/lemma';
import Popup from '../modals/popup';
import { day, weekday } from './helpers';
import { SelectOptionType } from '../../types/entities';

type Props = {
  show: boolean;
  title: string;
  reset: boolean;
  customAll?: boolean;
  onCloseHandler: () => void;
  resetModal: (value: boolean) => void;
  currentSelectDay?: Weekday[]; // текущий массив которые сейчас в инпуте
  currentShortDay?: string; // текущее значение из инпута
  setCurrentSelectDay?: (value: Weekday[]) => void;
  saveSchedule: (arg: { selectDaysForRequest: string; selectShortDay: string }) => void;
};

export type Weekday = {
  id: number;
  name: string;
  time: string;
  selected: number;
  shortName: string;
  checked: boolean;
};

const SheduleModal = ({
  show,
  onCloseHandler,
  title,
  saveSchedule,
  resetModal,
  reset,
  customAll,
  currentShortDay,
  setCurrentSelectDay,
  currentSelectDay,
}: Props) => {
  const [selectDay, setSelectDay] = useState<Weekday[]>(weekday);
  const [selectShortDay, setSelectShortDay] = useState<string>('');

  useEffect(() => {
    if (reset) {
      setSelectDay(weekday);
    }
  }, [reset]);

  useEffect(() => {
    if (show) {
      setSelectDay(currentSelectDay?.length ? currentSelectDay : weekday);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSelectedDayHandler = (id: number, element: any) => {
    setSelectDay((prevState: Weekday[]) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            time: element.label,
            selected: element.value,
          };
        }

        return {
          ...item,
        };
      });
    });
  };

  const save = () => {
    const selectDays = selectDay.map((item: Weekday, index) => {
      if (item.checked) {
        if (item.selected === 3 && customAll) {
          // кусок кода взят из монолита
          return `${(index + 1) * 10 + 1},${(index + 1) * 10 + 2},${(index + 1) * 10 + 4}`;
        } else {
          return `${item.id}${item.selected}`;
        }
      } else {
        return undefined;
      }
    });
    const selectShortDay = selectDay.map((item: Weekday) =>
      item.checked ? `${item.shortName} - ${item.time}` : undefined
    );

    const saveAll = {
      selectDaysForRequest: selectDays.filter((item) => item !== undefined)?.join(';'),
      selectShortDay: selectShortDay.filter((item) => item !== undefined).join(', '),
    };

    onCloseHandler();
    saveSchedule(saveAll);
    resetModal(false);
    if (setCurrentSelectDay) {
      setCurrentSelectDay(selectDay);
    }
    setSelectShortDay(saveAll.selectShortDay);
  };

  const closePopup = () => {
    if (!selectShortDay && !currentShortDay) {
      setSelectDay(weekday);
    } else if (currentSelectDay) {
      setSelectDay(currentSelectDay);
    }

    onCloseHandler();
  };

  return (
    <Popup
      dataTest="schedulePopup"
      classes={{
        body: 'modal__body--full',
      }}
      open={show}
      title={title}
      onClose={closePopup}
      onSubmit={save}
    >
      <div className="report__schedule--popup">
        {selectDay.map((item, index) => {
          const color = index % 2 === 0 ? '' : 'odd';

          return (
            <div
              key={item.id}
              className={`schedule__popup--item ${color}`}
            >
              <LmToggle
                name={item.id.toString()}
                dataTest={item.id.toString()}
                label={item.name}
                checked={item.checked}
                size="small"
                onChange={(checked) => {
                  setSelectDay(
                    selectDay.map((i) => {
                      return {
                        ...i,
                        checked: i.id === item.id ? checked : i.checked,
                      };
                    })
                  );
                }}
              />
              {item.checked ? (
                <div className="select">
                  <LmSelectNew
                    dataTest="selectDay"
                    name="selectDay"
                    onChange={(element: SelectOptionType | null) => onSelectedDayHandler(item.id, element)}
                    options={day}
                    value={{
                      label: item.time,
                      value: item.selected,
                    }}
                    size="small"
                    placeholder="Выберите значение..."
                    grouped={false}
                    multiple={false}
                    settingDropdownAsPopover={{
                      disablePortal: true,
                    }}
                  />
                </div>
              ) : (
                <span className="no-work">нерабочий</span>
              )}
            </div>
          );
        })}
      </div>
    </Popup>
  );
};

export default SheduleModal;
