import React, { useContext, useMemo, useState } from 'react';
import Info from './info';
import ChildForm from './child';
import ApplicantForm from './applicant';
import Organization from './organization';
import Privileges from './privileges';
import TestResults from './results';
import ActionsForm from './actions';
import Notify from './notify';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import SignModal from '../../../components/sign-modal';
import requestsApi from '../../../lib/api/requests';
import Status from './status';
import { RequestSourceEnum } from '../../../mock-data/request-source-enum';
import { RequestContext } from '../../../lib/utils/requests';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
};

const Details: React.FC<Props> = ({ requestData, updateRequest }) => {
  const [editMode, setEditMode] = useState<string | null>(null);
  const [signModal, showSignModal] = useState(false);
  const canEditRequest = useMemo(
    () =>
      requestData.requestStatusId !== RequestStatusEnum.Included &&
      requestData.requestStatusId !== RequestStatusEnum.Archive &&
      requestData.requestStatusId !== RequestStatusEnum.ApplicantRefused &&
      requestData.requestStatusId !== RequestStatusEnum.IncludeDeclined &&
      requestData.requestStatusId !== RequestStatusEnum.IncludedExcluded &&
      requestData.flagLast,
    [requestData.requestStatusId, requestData.flagLast]
  );
  const { hasCheckPrivileges } = useContext(RequestContext);

  return (
    <>
      {!editMode && (
        <Status
          requestData={requestData}
          showSignModal={showSignModal}
          updateRequest={updateRequest}
        />
      )}
      {!editMode && (
        <Info
          trainingGroup={requestData.trainingGroup}
          educationType={requestData.educationTypeId}
        />
      )}
      {(!editMode || editMode === 'child') && (
        <ChildForm
          requestId={requestData.id}
          requestChild={requestData.child}
          contingentLinkTypeId={requestData.contingentLinkTypeId}
          setEditModeParent={setEditMode}
          editable={canEditRequest}
          updateRequest={updateRequest}
        />
      )}
      {!editMode && (
        <Organization
          contingentGuid={requestData.contingentGuid}
          contingentLinkTypeId={requestData.contingentLinkTypeId}
        />
      )}
      {(!editMode || editMode === 'applicant') && (
        <ApplicantForm
          requestId={requestData.id}
          requestApplicant={requestData.applicant}
          setEditModeParent={setEditMode}
          editable={canEditRequest}
          updateRequest={updateRequest}
          isSourceMosRu={requestData.requestSourceId === RequestSourceEnum.Mpgu}
        />
      )}
      {(!editMode || editMode === 'privileges') && hasCheckPrivileges && <Privileges requestData={requestData} />}
      {(!editMode || editMode === 'results') && requestData?.trainingGroup?.isTestService && (
        <TestResults
          setEditModeParent={setEditMode}
          evaluation={requestData.evaluation}
          editable={canEditRequest}
          requestId={requestData.id}
          updateRequest={updateRequest}
        />
      )}
      {(!editMode || editMode === 'notify') && (
        <Notify
          setEditModeParent={setEditMode}
          approve={requestData.approve}
          editable={canEditRequest}
          requestId={requestData.id}
          updateRequest={updateRequest}
        />
      )}
      {(!editMode || editMode === 'actions') && (
        <ActionsForm
          setEditModeParent={setEditMode}
          requestData={requestData}
          editable={canEditRequest}
          updateRequest={updateRequest}
        />
      )}

      <SignModal
        show={signModal}
        onCloseHandle={() => {
          showSignModal(false);
        }}
        ids={[
          {
            id: 0,
          },
        ]}
        getDataForSignHandle={async () => JSON.stringify(requestData)}
        setSignedDataHandle={async (id, sign) => {
          if (requestData) {
            try {
              await requestsApi.signRequest(requestData.id, requestData.educationTypeId, sign);
              updateRequest();
            } catch {}
          }
        }}
      />
    </>
  );
};

export default Details;
