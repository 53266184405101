import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmLoader, LmSubHeader } from '@mes-ui/lemma';
import DocList from '../../components/doc-list';
import { useGetUserManualQuery } from '../../store/static-content';
import { routes } from '../../config/constants';

const UserManual = () => {
  const { data = [], isFetching } = useGetUserManualQuery();

  return (
    <>
      <LmSubHeader
        sticky
        title="Руководство пользователя"
        dataTest="userManualSubHeader"
        description=""
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Руководство пользователя',
          },
        ]}
        hideReturnButton
      />

      <Push size={12} />
      {isFetching ? (
        <div className="loader-auto">
          <LmLoader view="page" />
        </div>
      ) : (
        <DocList data={data} />
      )}
    </>
  );
};

export default UserManual;
