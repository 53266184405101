export enum RequestStatusEnum {
  // 1 Черновик.
  Draft = 1,
  // 2 Поданы документы.
  DocumentAccepted = 2,

  // 3 Вступительные испытания пройдены.
  TestCompleted = 3,

  // 4 Ожидание прихода Заявителя для заключения договора (Направление на зачисление).
  ForInclude = 4,

  // 5 Зачислен.
  Included = 5,

  // 6 Отчислен.
  Excluded = 6,

  // 7 Отказ в зачислении.
  IncludeDeclined = 7,

  // 8 Ожидание прихода Заявителя на вступительные испытания.
  NeedTest = 8,

  // 9 Архив.
  Archive = 9,

  // 10 Направлен на зачисление (ДОгМ).
  DogmForInclude = 10,

  // Приостановлен
  Suspended = 11,

  // Зачислен-Отчислен
  IncludedExcluded = 12,

  // 13 Новое (ГПД)
  New = 13,

  // 14 На рассмотрении.
  UnderConsideration = 14,

  // 15 Отказ в зачислении по инициативе заявителя
  ApplicantRefused = 15,

  // Ожидание подписания электронного договора
  WaitForElectronicAccept = 16,

  // Договор подписан электронно
  AcceptedElectronically = 17,

  // Ожидание досыла документов заявителем
  WaitingSendingDocuments = 18,

  // Ожидание проверки документов оператором
  WaitingDocumentVerification = 19,

  // Ожидание формирования проекта договора
  WaitingDraftContract = 20,

  // Активные. Псевдостатус, предназначен для выбора заявлений в любых статусах, кроме
  // Зачислен, Отчислен, Отказано, Архив.
  Active = 1001,
}
