import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import { LmFilterVertical } from '@mes-ui/lemma';
import { useHistory, useLocation } from 'react-router-dom';
import FormikCheckbox from '../../../components/formik/formik-checkbox';
import FilterLoader from '../../../components/loader-custom/filter-loader';
import { historyState } from '../../../mock-data/history-state';
import FormikInput from '../../../components/formik/formik-input';
import { SearchGroupTrainingInitialData } from '../../../types/service-class';
import { AuthorizationData, generalAccess } from '../../../types/authorization-data';
import SheduleModal, { Weekday } from '../../../components/shedule-modal';
import { FieldSchedule } from './fields/schedule';
import { ReportSchedule } from '../../../types/reports';
import { accessInitialForm, getCountDiff } from '../../../lib/utils/service-class';
import { FieldPlaceService } from './fields/place-service';
import { FieldOrganization } from './fields/organization';
import { searchGroupTrainingInitialFormData } from './index';
import { FieldPlace } from './fields/service';
import { FieldVedomstvo } from './fields/vedomstvo';
import { FieldEducationType } from './fields/education-type';
import { FieldModule } from './fields/module';
import { hasGeneralAccess, replaceHistoryState } from '../../../lib/utils';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { day, weekday } from '../../../components/shedule-modal/helpers';
import { NotificationField } from '../../../components/fields/notification';
import { sendReachGoal } from '../../../lib/metrica';
import useQuery from '../../../hooks/use-query';
import { EventUrl } from '../../../mock-data/event';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  educationTypeData: SelectOptionType[];
  initialForm: SearchGroupTrainingInitialData | undefined;
  submitForm: (values: SearchGroupTrainingInitialData) => void;
};

const ServiceClassRegistrySearch: React.FC<Props> = ({
  initialForm,
  submitForm,
  isAdmin,
  userProfile,
  educationTypeData,
}) => {
  const [open, setOpen] = useState<boolean>(!!window.history.state[historyState.openAdvanced]);

  const [scheduleModal, setScheduleModal] = useState(false);
  const [resetScheduleModal, setResetScheduleModal] = useState(false);
  const [listSchedule, setListSchedule] = useState<ReportSchedule>({
    selectDaysForRequest: '',
    selectShortDay: '',
  });
  const [currentSelectDay, setCurrentSelectDay] = useState<Weekday[]>([]);

  const [programModules, setProgramModules] = useState<SelectOptionType[]>([]);

  const vedomstvoData: SelectOptionType[] = useGetDataDepartment();

  const accessMain = hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);
  const access = !accessMain && !isAdmin && !!educationTypeData[0];

  const location = useLocation();
  const history = useHistory();
  const eventId = Number(useQuery().get(EventUrl.EventId));

  useEffect(() => {
    if (!initialForm) {
      const state = window.history.state[historyState.search];

      if (state) {
        const scheduleOfService: string[] = state.scheduleOfService.split(';');
        const scheduleOfServiceList = scheduleOfService.reduce((acc: Pick<Weekday, 'id' | 'selected'>[], item) => {
          const selectedItem = item.split(',');

          return [
            ...acc,
            {
              id: Number(selectedItem[0][0]),
              selected: selectedItem.length === 1 ? Number(selectedItem[0][1]) : 3,
            },
          ];
        }, []);

        setListSchedule({
          selectDaysForRequest: state.scheduleOfService || '',
          selectShortDay: state.scheduleOfServiceText || '',
        });
        setCurrentSelectDay(
          weekday.map((item) => {
            const findItem = scheduleOfServiceList.find(({ id }) => item.id === id);

            return {
              ...item,
              selected: findItem?.selected ?? item.selected,
              time: day.find(({ value }) => value === findItem?.selected)?.label ?? item.time,
              checked: !!findItem,
            };
          })
        );
      }
    }
  }, [initialForm]);

  const resetScheduleModalHandler = (value: boolean) => {
    setResetScheduleModal(value);
    setCurrentSelectDay(weekday);
    if (value) {
      setListSchedule({
        selectDaysForRequest: '',
        selectShortDay: '',
      });
    }
  };

  const openScheduleModal = () => {
    setScheduleModal(true);
  };

  const saveSchedule = (value: ReportSchedule) => {
    setListSchedule(value);
  };

  const resetState = () => {
    resetScheduleModalHandler(true);
  };

  const handleClickAdvancedSearch = (isOpen: boolean) => {
    replaceHistoryState({
      [historyState.openAdvanced]: isOpen,
    });
    setOpen(isOpen);
  };

  const handleSearch = (
    values: SearchGroupTrainingInitialData,
    formikHelpers: FormikHelpers<SearchGroupTrainingInitialData>
  ) => {
    sendReachGoal('onclick-service-class-search');
    submitForm(values);
    formikHelpers.setSubmitting(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleSearch}
        enableReinitialize
        initialValues={initialForm || searchGroupTrainingInitialFormData}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          resetForm,
          initialValues,
          setFieldValue,
        }: FormikProps<SearchGroupTrainingInitialData>) => {
          const accessInitial = {
            ...searchGroupTrainingInitialFormData,
            ...accessInitialForm(userProfile, initialValues.educationTypeId as number, initialValues.educationTypeName),
          };

          const resetInitial = () => {
            if (access) {
              return accessInitial;
            } else if (educationTypeData.length === 1) {
              return {
                ...searchGroupTrainingInitialFormData,
                educationTypeId: educationTypeData[0].value as number,
              };
            } else {
              return searchGroupTrainingInitialFormData;
            }
          };

          const onResetForm = () => {
            let newObj: SearchGroupTrainingInitialData = resetInitial();

            if (eventId) {
              history.replace(location.pathname);
              replaceHistoryState({
                [historyState.openAdvanced]: open,
              });
              const { eventId, ...obj } = resetInitial();

              newObj = obj;
            }

            resetState();
            resetForm({
              values: newObj,
            });
            submitForm(resetInitial());
          };

          const countDiff = getCountDiff(values, resetInitial());

          return (
            <form onSubmit={handleSubmit}>
              <LmFilterVertical
                dataTest="serviceClassFilter"
                classNameContent="filter-content"
                open={open}
                searchValue={values.query}
                searchPlaceholder="Поиск по группам обучения..."
                onChange={(value) => setFieldValue('query', value)}
                toggleOpen={handleClickAdvancedSearch}
                onClear={onResetForm}
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !initialForm,
                  type: 'submit',
                }}
                secondaryButtonModifiers={{
                  disabled: !initialForm,
                }}
                buttonSecondaryText={countDiff ? `Сбросить фильтры (${countDiff})` : 'Сбросить фильтры'}
              >
                {!initialForm ? (
                  <FilterLoader />
                ) : (
                  <>
                    <FieldVedomstvo
                      isAdmin={isAdmin}
                      userProfile={userProfile}
                      vedomstvoData={vedomstvoData}
                    />

                    <FieldPlaceService
                      isAdmin={isAdmin}
                      userProfile={userProfile}
                      educationTypeData={educationTypeData}
                    />

                    <FieldEducationType educationTypeData={educationTypeData} />

                    {/* По уведомлению */}
                    <NotificationField />

                    <FieldSchedule
                      isAdmin={isAdmin}
                      userProfile={userProfile}
                      listSchedule={listSchedule}
                      openScheduleModal={openScheduleModal}
                      resetScheduleModalHandler={resetScheduleModalHandler}
                    />

                    <FormikCheckbox
                      name="showArchive"
                      label="Отображать архивные"
                    />

                    <FieldOrganization
                      isAdmin={isAdmin}
                      userProfile={userProfile}
                    />

                    <FieldPlace
                      userProfile={userProfile}
                      setProgramModules={setProgramModules}
                    />

                    <FormikInput
                      label="Код группы"
                      name="code"
                      placeholder="Введите..."
                      size="small"
                    />

                    <FieldModule
                      isAdmin={isAdmin}
                      userProfile={userProfile}
                      programModules={programModules}
                    />
                  </>
                )}
              </LmFilterVertical>
            </form>
          );
        }}
      </Formik>

      <SheduleModal
        customAll
        show={scheduleModal}
        title="Время работы"
        reset={resetScheduleModal}
        saveSchedule={saveSchedule}
        currentSelectDay={currentSelectDay}
        resetModal={resetScheduleModalHandler}
        setCurrentSelectDay={setCurrentSelectDay}
        currentShortDay={listSchedule.selectShortDay}
        onCloseHandler={() => setScheduleModal(false)}
      />
    </>
  );
};

export default ServiceClassRegistrySearch;
