import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { ServiceRegistryContext } from '../../initial-data';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';
import { SelectOptionType } from '../../../../../types/entities';

type Props = {
  title: string;
};

const FieldStatus: React.FC<Props> = ({ title }) => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const { statusesData, type, isAdmin, educationSumm } = React.useContext(ServiceRegistryContext);

  const [selectEducationType, setSelectEducationType] = useState<SelectOptionType[]>([]);

  // При изменении типа образования в селекте, получаем новый список статусов
  useEffect(() => {
    if (type === RegistryTypeEnum.serviceChildType && isAdmin) {
      const fetchStatuses = async () => {
        const edType = !values.educationTypeId ? educationSumm : values.educationTypeId;
        const statusesData = await dictionariesApi.getStatuses(edType);

        setSelectEducationType([
          {
            label: 'Актуальные',
            value: 0,
          },
          ...statusesData,
        ]);
      };

      fetchStatuses();
    }
  }, [values.educationTypeId, isAdmin, type, educationSumm]);

  return (
    <FormikSelect
      label={title}
      name="statusId"
      size="small"
      options={selectEducationType?.length ? selectEducationType : statusesData}
      defaultValue={
        values.statusId
          ? {
              label: values.statusName || '',
              value: values.statusId,
            }
          : {
              label: 'Актуальные',
              value: 0,
            }
      }
      placeholder="Выберите..."
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('statusName', v.label);
        }
      }}
    />
  );
};

export default FieldStatus;
