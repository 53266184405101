import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmBadgeStatus } from '@mes-ui/lemma';
import History from './history';
import historyUrl from '../../../../history';
import { chooseColorForStatusRequest } from '../../../../lib/utils/learners';
import { Learner } from '../../../../types/learners';
import { routes } from '../../../../config/constants';
import { formatDate } from '../../../../lib/utils/date';
import { downloadBlobFile, generateLink, getServiceEditLink } from '../../../../lib/utils';
import requestsApi from '../../../../lib/api/requests';

type Props = {
  requestId: number;
  dateRequest: string;
  serviceName: string;
  serviceId: number;
  startDate: string | null;
  requestNumber: string;
  requestStatus: string;
  requestSource: string;
  organizationName: string;
  educationTypeId: number;
  educationTypeName: string;
  history: Learner.StatementHistory[];
  contractId?: number;
  contractNumber?: string;
};

const Statement: React.FC<Props> = ({
  startDate,
  history,
  requestId,
  requestSource,
  dateRequest,
  organizationName,
  requestNumber,
  requestStatus,
  serviceName,
  serviceId,
  educationTypeId,
  educationTypeName,
  contractId,
  contractNumber,
}) => {
  const [showHistory, setShowHistory] = useState(false);

  const badgeColor = chooseColorForStatusRequest(requestStatus);

  return (
    <li className={`learner-history-list__item ${showHistory ? 'learner-history-list__item--show-history' : ''}`}>
      <div className="container">
        <div className="flex justify-between">
          <span>
            Заявление №
            <Link
              className="brand-link font-size-small"
              onClick={() =>
                historyUrl.push(
                  generateLink(routes.request, {
                    id: requestId,
                  })
                )
              }
              to={`${generateLink(routes.request, {
                id: requestId,
              })}`}
            >
              {requestNumber}
            </Link>
          </span>
          <Push size={4} />
          <LmBadgeStatus
            dataTest="requestStatus"
            color={badgeColor}
          >
            {requestStatus}
          </LmBadgeStatus>
        </div>
        <div className="font-size-small color-gray-dark font-weight-bold">{educationTypeName}</div>
        <Push size={4} />
        <Link
          className="brand-link font-weight-bold"
          to={getServiceEditLink(educationTypeId, serviceId)}
          target="_blank"
        >
          {serviceName}
        </Link>
        <Push size={8} />
        {organizationName}
        <Push size={20} />
        <div className="flex justify-between">
          <div className="flex">
            Дата заявления: {dateRequest ? formatDate(dateRequest) : '—'}
            <Push
              size={20}
              orientation="horizontal"
            />
            План. начало обучения: {startDate ? formatDate(startDate) : '—'}
            <Push
              size={20}
              orientation="horizontal"
            />
            Договор:{' '}
            {contractId ? (
              <button
                id="btn-contract"
                type="button"
                className="icon-group"
                onClick={async () => {
                  const response = await requestsApi.getContract(contractId);

                  if (response) {
                    downloadBlobFile(response.contentDisposition, response.blob);
                  }
                }}
              >
                <span className="icon-group__text font-weight-bold color-primary">№{contractNumber}</span>
              </button>
            ) : (
              <span>&nbsp;—</span>
            )}
            <Push
              size={20}
              orientation="horizontal"
            />
            Источник: {requestSource || '—'}
          </div>

          {history.length ? (
            <button
              type="button"
              onClick={() => setShowHistory(!showHistory)}
              className="icon-group"
            >
              <span className="icon-group__text color-primary font-weight-bold">История</span>
              <span className="icon-group__counter color-primary font-weight-bold">{history.length}</span>
              <span className="icon-group__icon">
                {showHistory ? (
                  <LmIcon
                    icon="filled-chevrons-small-up"
                    size={20}
                    color="var(--LM-blue-200)"
                  />
                ) : (
                  <LmIcon
                    icon="filled-chevrons-small-down"
                    size={20}
                    color="var(--LM-blue-200)"
                  />
                )}
              </span>
            </button>
          ) : null}
        </div>
      </div>
      {showHistory && (
        <>
          <Push size={20} />
          <History data={history} />
        </>
      )}
    </li>
  );
};

export default Statement;
