import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import Field, { FieldProps } from '../../../../components/fields/field';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { organizationFieldIdName } from './organization';
import { SelectOptionType } from '../../../../types/entities';

type TeacherFieldProps = FieldProps<string> & Record<string, any>;

export const supervisorTeacherFieldId = 'supervisorTeacherId';
export const supervisorTeacherFieldName = 'supervisorTeacherName';

const TeacherField = ({ label = 'Руководитель', name, editMode, required, defaultValue = '—' }: TeacherFieldProps) => {
  const fieldName = `${name}Name`;
  const fieldId = `${name}Id`;

  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldValue, , helpersValue] = useField(fieldId);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const [organizationFieldId] = useField<number>(organizationFieldIdName);

  const fetchOptions = useCallback(
    async (query: string) =>
      await lookupApi.getTeachers(query, organizationFieldId.value || userProfile.organizationId),
    [userProfile.organizationId, organizationFieldId.value]
  );

  const defaultSupervisorPerson =
    fieldLabel.value && fieldValue.value
      ? {
          label: fieldLabel?.value,
          value: fieldValue?.value,
        }
      : null;

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      defaultValue={defaultValue}
      value={defaultSupervisorPerson?.label}
    >
      <FormikSelect
        name={fieldId}
        size="small"
        withSearch
        options={[]}
        defaultValue={defaultSupervisorPerson}
        loadOptions={fetchOptions}
        selectedValue={(selected?: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : null);
          helpersValue.setValue(selected ? selected.value : null);
        }}
        placeholder="Начните вводить..."
      />
    </Field>
  );
};

export default TeacherField;
