import React, { useEffect } from 'react';
import Dialog from '../../../components/modals/dialog';
import documentApi from '../../../lib/api/document';
import { accessObject } from '../../../mock-data/access-enum';

const CancellationSignatureModal: React.FC<{
  signId: number;
  show: boolean;
  onCloseHandle: () => void;
  title: string;
  description: string;
  submitSuccess: () => void;
  submitEdit: (value: boolean) => void;
  submit: boolean;
}> = ({ signId, submit, submitEdit, submitSuccess, show, onCloseHandle, title, description }) => {
  useEffect(() => {
    if (submit) {
      submitSuccess();
    }
  }, [submit, submitSuccess]);

  const cancelledRequest = async () => {
    submitEdit(true);
    await documentApi.getUnsign({
      signId,
      allTypes: true,
      accessObjectId: accessObject.EducationalOrganization,
    });
  };

  return (
    <Dialog
      dataTest="cancellationSignature"
      isOpenDialog={show}
      title={title}
      description={description}
      variant="question"
      buttonPrimaryText="Продолжить"
      onClickSeconadaryButton={onCloseHandle}
      onClickPrimaryButton={cancelledRequest}
    />
  );
};

export default CancellationSignatureModal;
