import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import Informer from '../../../components/informer';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import requestsApi from '../../../lib/api/requests';
import { formatDate } from '../../../lib/utils/date';
import { RequestContext } from '../../../lib/utils/requests';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
  showSignModal: (value: boolean) => void;
};

const Status: React.FC<Props> = ({ requestData, updateRequest, showSignModal }) => {
  const { useSign } = useContext(RequestContext);
  const [lastRequestId, setLastRequestId] = useState<number>(requestData.id);

  useEffect(() => {
    const fetch = async () => {
      const newId = await requestsApi.getLastVersion(requestData.id);

      setLastRequestId(newId);
    };

    if (!requestData.flagLast) {
      fetch();
    }
  }, [requestData.flagLast, setLastRequestId, requestData.id]);

  const handleConfirm = async () => {
    try {
      await requestsApi.signRequest(requestData.id, requestData.educationTypeId, null);
      updateRequest();
    } catch {}
  };

  return (
    <>
      {requestData.requestStatusId === RequestStatusEnum.ForInclude && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <Informer
            dataTest="documentsWarning"
            color="warning"
            description={
              <>
                Дата явки заявителя для заключения договора:{' '}
                {requestData?.actionDeadline ? (
                  <span className="informer__text--bold">{formatDate(requestData.actionDeadline)}</span>
                ) : (
                  '—'
                )}
              </>
            }
          />
        </>
      )}
      {requestData.requestStatusId === RequestStatusEnum.NeedTest && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <Informer
            dataTest="processingResultsWarning"
            color="warning"
            description={
              <>
                Дата обработки результатов вступительных испытаний и уведомления заявителя о результатах вступительных
                испытаний:{' '}
                {requestData.actionDeadline ? (
                  <span className="informer__text--bold">{formatDate(requestData.actionDeadline)}</span>
                ) : (
                  '—'
                )}
              </>
            }
          />
        </>
      )}
      {!requestData.flagLast && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <Informer
            dataTest="lastVersionInfo"
            color="primary"
            description={
              <>
                Показаны сведения не последней версии заявления.{' '}
                <Link
                  to={() =>
                    generateLink(routes.request, {
                      id: lastRequestId,
                    })
                  }
                  className="informer__link"
                >
                  {'Перейти к последней версии >'}
                </Link>
              </>
            }
          />
        </>
      )}
      {requestData.requestStatusId === RequestStatusEnum.Draft && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <Informer
            dataTest="editConfirmDanger"
            color="danger"
            title="Внимание!"
            description={
              <>
                После редактирования сведений необходимо подтвердить изменения. Не оставляйте заявление в статусе{' '}
                <span className="informer__text--bold">“Черновик”.</span>
              </>
            }
            controls={
              useSign ? (
                <LmButton
                  dataTest="sign"
                  type="button"
                  color="danger"
                  onClick={() => {
                    showSignModal(true);
                  }}
                >
                  Подписать
                </LmButton>
              ) : (
                <LmButton
                  dataTest="confirm"
                  type="button"
                  color="danger"
                  onClick={handleConfirm}
                >
                  Подтвердить
                </LmButton>
              )
            }
          />
        </>
      )}
    </>
  );
};

export default Status;
