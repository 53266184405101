import React, { useEffect, useMemo, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import { Panel } from '@mosru/esz_uikit';
import { LmDatePicker, LmRadio } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { notify } from '../../../../redux/ducks/notifications';
import {
  TrainingGroupByServiceDonm,
  TrainingGroupRequestData,
  TrainingGroupScheduleData,
} from '../../../../types/requests';
import { serviceIdField } from '../../components/fields/service-donm';
import { formatDate, formatTime, getDate, getSelectedDate } from '../../../../lib/utils/date';
import { bookingApi } from '../../../../lib/api/booking';
import { BookingSlotData } from '../../../../types/booking';
import { buildScheduleDisplayName } from '../../../../lib/utils/service';
import SimpleTable from '../../../../components/table/simple-table';

const slotIdField = 'trainingGroup.slotId';
const scheduleIdField = 'trainingGroup.scheduleOfTimetableId';

const TestAdmissionPlan = () => {
  const { values, setFieldValue } = useFormikContext<{ trainingGroup: TrainingGroupRequestData }>();
  // const [selectedScheduleId, setSelectedScheduleId] = useState(0);
  const [selectedName, setSelectedName] = useState<string | undefined>();
  const [scheduleDate, setScheduleDate] = useState<Date | undefined>();
  const [trainingGroupList, setTrainingGroupList] = useState<TrainingGroupByServiceDonm[] | null>(null);
  const [serviceField] = useField<number>(serviceIdField);
  const [bookingSlotsList, setBookingSlotsList] = useState<BookingSlotData[] | undefined>();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      const tgList = await serviceTemplateApi.getTrainingGroupsByService(serviceField.value ?? 0);

      setTrainingGroupList(tgList);
    };

    if (serviceField.value != null) {
      fetch();
    } else {
      setTrainingGroupList(null);
    }
  }, [serviceField.value]);

  // отслеживаем смену выбора расписания или даты для формирования и отображения выбранного расписания
  useEffect(() => {
    if (values.trainingGroup.scheduleOfTimetableId) {
      const buildSelectedName = (schedule: TrainingGroupScheduleData, dt?: Date) =>
        dt
          ? `${formatDate(dt)} ${formatTime(schedule.timeStart)}-${formatTime(schedule.timeEnd)}`
          : buildScheduleDisplayName(schedule);

      const schedule = findSchedule(values.trainingGroup.scheduleOfTimetableId, trainingGroupList);

      if (schedule) {
        setSelectedName(
          schedule && (schedule.typesOfRepetitionId === 3 || scheduleDate)
            ? buildSelectedName(schedule, scheduleDate)
            : undefined
        );
      }
    }
  }, [values.trainingGroup.scheduleOfTimetableId, scheduleDate, trainingGroupList]);

  // подгружаем слоты для отображения в календаре если нужно (typesOfRepetitionId !== 3)
  useEffect(() => {
    const fetch = async (scheuleId: number, typesOfRepetitionId: number | undefined) => {
      const slots = await bookingApi.getTrainingGroupSlots(scheuleId);

      if (slots?.length === 0) {
        setFieldValue(slotIdField, undefined);
        setFieldValue(scheduleIdField, undefined);
        dispatch(
          notify.danger({
            dataTest: 'fullPlaces',
            title: 'Нет свободных мест для бронирования',
          })
        );
        // если то слот 3 выбирается сразу, в противном случае через календарь
      } else if (typesOfRepetitionId === 3 && slots?.length) {
        setFieldValue(slotIdField, slots[0].id);
      } else {
        setBookingSlotsList(slots);
      }
    };

    if (values.trainingGroup.scheduleOfTimetableId) {
      const schedule = findSchedule(values.trainingGroup.scheduleOfTimetableId, trainingGroupList);

      if (schedule) {
        fetch(values.trainingGroup.scheduleOfTimetableId, schedule.typesOfRepetitionId);
      }
    }
  }, [values.trainingGroup.scheduleOfTimetableId, trainingGroupList, setFieldValue, dispatch]);

  // выбрать слот согласно выбранной дате в графифке расписаний
  const selectBookingSlotByDate = (date: Date | undefined) => {
    if (bookingSlotsList && date) {
      const dt = moment(date).format('YYYY-MM-DDT00:00:00');
      const slot = bookingSlotsList.find((s) => s.trainingGroupData.testDate === dt);

      setFieldValue(slotIdField, slot?.id);
    } else {
      setFieldValue(slotIdField, undefined);
    }
  };

  const dateSlots = useMemo(() => {
    return bookingSlotsList?.reduce((acc: Date[], item) => {
      if (item.trainingGroupData?.testDate) {
        acc.push(getDate(item.trainingGroupData.testDate));
      }

      return acc;
    }, []);
  }, [bookingSlotsList]);

  const findSchedule = (scheduleId: number, trList: TrainingGroupByServiceDonm[] | null) =>
    trList
      ?.map((t) => t.scheduleOfTimetable)
      ?.reduce(function (a: TrainingGroupScheduleData[], b: any) {
        return a.concat(b);
      }, [])
      .find((sch: TrainingGroupScheduleData) => sch.id === scheduleId);

  const buildRadioGroupElement = (item: any, schedule: TrainingGroupScheduleData) => (
    <LmRadio
      boxSize="large"
      name="scheduleOfTimetableGroup"
      dataTest={`${item.id}${schedule.id}`}
      onChange={() => {
        setFieldValue(scheduleIdField, schedule.id);
        setScheduleDate(undefined);
        setBookingSlotsList(undefined);
        setFieldValue(slotIdField, undefined);
      }}
      checked={values.trainingGroup.scheduleOfTimetableId === schedule.id}
    >
      {buildScheduleDisplayName(schedule)}
    </LmRadio>
  );

  return (
    <Panel
      title={() => (
        <>
          Планы приема
          <span className="color-gray-dark">
            {' \u00A0'} {trainingGroupList?.length ?? 0}
          </span>
        </>
      )}
    >
      {trainingGroupList ? (
        <SimpleTable
          data={trainingGroupList}
          columns={[
            {
              dataIndex: 'startDate',
              title: 'Начало занятий',
              render: (item: any) => formatDate(item.educationStartDate),
              width: '15%',
            },
            {
              dataIndex: 'stage',
              title: 'Этап обучения',
              render: (item: any) => item.serviceStage,
              width: '15%',
            },
            {
              dataIndex: 'amount',
              title: 'Кол-во мест',
              render: (item: any) => item.volume ?? 'Без ограничений',
              width: '20%',
            },
            {
              dataIndex: 'teacher',
              title: 'Преподаватель',
              render: (item: any) => {
                return (
                  <>
                    <div className="table-row-data-height">{item.teacher ? item.teacher : '—'}</div>
                    {item.scheduleOfTimetable?.map((schedule: TrainingGroupScheduleData) => {
                      return (
                        <div className="table-row-item-height">
                          {schedule.typesOfRepetitionId !== 3 ? buildRadioGroupElement(item, schedule) : null}
                        </div>
                      );
                    })}
                  </>
                );
              },
              width: '25%',
            },
            {
              dataIndex: 'testDates',
              title: 'Дата и время тестирования',
              render: (item: any) => {
                const finded = item.scheduleOfTimetable?.find(
                  (sch: TrainingGroupScheduleData) => sch.id === values.trainingGroup.scheduleOfTimetableId
                );

                return (
                  <>
                    <div className="table-row-data-height">
                      {finded && selectedName ? selectedName : <span className="color-gray-dark">Не выбрано</span>}
                    </div>
                    {item.scheduleOfTimetable?.map((schedule: TrainingGroupScheduleData) => {
                      return schedule.typesOfRepetitionId !== 3 ? (
                        <div
                          key={schedule.id}
                          className="table-row-item-height"
                        >
                          <LmDatePicker
                            dataTest="scheduleDate"
                            name="scheduleDate"
                            onChangeDate={(d) => {
                              const dt = d ? (Array.isArray(d) ? d[0] : d) : undefined;

                              setScheduleDate(dt);
                              selectBookingSlotByDate(dt);
                            }}
                            placement="bottom-end"
                            inputSize="small"
                            placeholder="ДД.ММ.ГГГ"
                            disabled={values.trainingGroup.scheduleOfTimetableId !== schedule.id}
                            selectedDay={getSelectedDate(scheduleDate)}
                            slots={dateSlots}
                            disablePortal
                          />
                        </div>
                      ) : (
                        <div
                          key={schedule.id}
                          className="table-row-item-height"
                        >
                          {buildRadioGroupElement(item, schedule)}
                        </div>
                      );
                    })}
                  </>
                );
              },
              width: '25%',
            },
          ]}
        />
      ) : null}
    </Panel>
  );
};

export default TestAdmissionPlan;
