import React, { useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { Panel } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import ServiceField from '../components/fields/service';
import { EducationTypeEnum } from '../../../types/education-type';
import SimpleInput from '../../../components/fields/simple-input';
import TrainingGroupField from '../components/fields/training-group';
import DateField from '../components/fields/date';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import PupilInGroupField from '../components/fields/pupil-in-group';
import ModuleField from '../components/fields/module';
import AddressField from '../components/fields/address';
import { ServiceClassData, TrainingGroupItemType } from '../../../types/service-class';
import { ServiceClassContext } from '../service-class';
import serviceClassApi from '../../../lib/api/service-class';
import { maxCommentLength } from '../../../lib/utils/service-class';
import { serviceClassStatusEnum } from '../../../mock-data/service-class-status';
import WarningScheduleModal from '../components/modals/warning-schedule-modal';
import SavePanel from '../../../components/save-panel';
import { capacityOPValidation, validationPreparationTrainDate } from '../utils';

type Props = {
  setEditModeParent: React.Dispatch<React.SetStateAction<string | null>>;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};
const ServiceClassGroup: React.FC<Props> = ({ setEditModeParent, editMode, setEditMode }) => {
  const {
    setStartDateSchedulePeriod,
    setEndDateSchedulePeriod,
    serviceClassData,
    updateServiceClass,
    accessEditServiceClass,
    setCurrentValuesGroupDetails,
  } = useContext(ServiceClassContext);

  const [loading, setLoading] = useState(false);

  const [currentTrainingGroup, setCurrentTrainingGroup] = useState<TrainingGroupItemType | null>(null);

  const [showWarningScheduleModal, setShowWarningScheduleModal] = useState<boolean>(false);

  const initialErrors = useInitialErrors(
    serviceClassData,
    getValidationSchema(currentTrainingGroup, serviceClassData.capacity, serviceClassData.included)
  );

  const submit = async (data: ServiceClassData) => {
    const teacherByModuleId = await serviceClassApi.getTeacherByProgrammModule(data.programmModuleId as number);

    const findTeacher = data?.teacher?.list?.find((teacher) => teacher.id === teacherByModuleId.id);

    const teachers = findTeacher ? data?.teacher?.list : [...(data?.teacher?.list || []), teacherByModuleId];

    const payload = {
      serviceClassId: serviceClassData.id,
      educationTypeId: serviceClassData.educationTypeId,
      list: teachers,
    };

    if (data?.trainStartDate && data?.trainEndDate) {
      if (
        serviceClassData.trainStartDate !== data.trainStartDate ||
        serviceClassData.trainEndDate !== data.trainEndDate
      ) {
        setShowWarningScheduleModal(true);
      } else {
        setLoading(true);

        try {
          await serviceClassApi.updateServiceClass(data);

          // Добавление преподавателя при изменении модуля
          if (!findTeacher) {
            await serviceClassApi.updateServiceClassTeacherList(payload);
          }

          updateServiceClass();
          setEditModeParent && setEditModeParent(null);
          if (setEditMode) {
            setEditMode(false);
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (serviceClassData.trainStartDate && serviceClassData.trainEndDate) {
      setStartDateSchedulePeriod(new Date(serviceClassData.trainStartDate));
      setEndDateSchedulePeriod(new Date(serviceClassData.trainEndDate));
    }
  }, [serviceClassData, setEndDateSchedulePeriod, setStartDateSchedulePeriod]);

  return serviceClassData ? (
    <>
      <Formik
        onSubmit={(values, formikHelpers) => {
          submit(values);
          if (setCurrentValuesGroupDetails) {
            setCurrentValuesGroupDetails(values);
          }
          formikHelpers.setSubmitting(false);
        }}
        enableReinitialize
        initialValues={serviceClassData}
        validationSchema={getValidationSchema(
          currentTrainingGroup,
          serviceClassData.capacity,
          serviceClassData.included
        )}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<ServiceClassData>) => {
          const { isValid, handleSubmit, submitForm, resetForm, values, validateForm } = formikProps;

          const handleChangeCurrentTrainingGroup = (value: TrainingGroupItemType) => {
            setCurrentTrainingGroup(value);
            validateForm();
          };

          const handleCancel = () => {
            setEditModeParent && setEditModeParent(null);
            if (setEditMode) {
              setEditMode(false);
            }
            resetForm();
          };

          return (
            <form onSubmit={handleSubmit}>
              <Panel
                title={() => <>Сведения о группе</>}
                headingControl={() => {
                  return !editMode &&
                    setEditModeParent &&
                    serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                    accessEditServiceClass ? (
                        <button
                          type="button"
                          onClick={() => {
                            setEditModeParent('group');
                            if (setEditMode) {
                              setEditMode(true);
                            }
                          }}
                          className="icon-group"
                        >
                          <span className="icon-group__icon">
                            <LmIcon
                              icon="filled-edit-edit"
                              size={20}
                              color="var(--LM-blue-200)"
                            />
                          </span>
                          <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                        </button>
                      ) : null;
                }}
              >
                <div className="container">
                  <div className="table-data">
                    <ServiceField
                      required
                      name="service"
                      editMode={editMode}
                      educationType={EducationTypeEnum.ProfessionalEducation}
                      disabled={!!serviceClassData.included || serviceClassData.hasActiveLearners}
                      label="Образовательная программа"
                    />
                    <ModuleField
                      name="programmModule"
                      label="Модуль"
                      editMode={editMode}
                      required
                      disabled={serviceClassData.hasActiveLearners}
                    />

                    <SimpleInput
                      required
                      name="name"
                      editMode={editMode}
                      label="Наименование группы"
                    />

                    <SimpleInput
                      name="code"
                      required
                      label="Код группы"
                    />
                    <AddressField
                      name="address"
                      editMode={editMode}
                      required
                      label="Адрес"
                      disabled={serviceClassData.hasActiveLearners}
                    />

                    <TrainingGroupField
                      name="trainingGroup"
                      defaultValue="—"
                      editMode={editMode}
                      required
                      disabled={serviceClassData.hasActiveLearners}
                      setCurrentTrainingGroup={handleChangeCurrentTrainingGroup}
                    />

                    <DateField
                      label="Даты занятий"
                      name="train"
                      editMode={editMode}
                      required
                      disabled={!!values.included}
                      calendarPosition="top-end"
                      showErrorImmediately
                    />

                    <PupilInGroupField
                      name="included"
                      label="Человек в группе"
                      editMode={editMode}
                      required
                    />

                    <SimpleTextArea
                      name="description"
                      label="Комментарий"
                      editMode={editMode}
                      placeholder="Введите..."
                      maxLength={maxCommentLength}
                    />
                  </div>
                </div>
              </Panel>

              {editMode && setEditModeParent && (
                <SavePanel
                  primaryButtonModifiers={{
                    loading,
                    disabled: !isValid,
                  }}
                  onClickSeconadaryButton={handleCancel}
                  onClickPrimaryButton={submitForm}
                />
              )}
            </form>
          );
        }}
      </Formik>
      {setEditMode && (
        <WarningScheduleModal
          setEditMode={setEditMode}
          show={showWarningScheduleModal}
          setEditModeParent={setEditModeParent}
          onCloseHandler={() => setShowWarningScheduleModal(false)}
        />
      )}
    </>
  ) : null;
};

export default ServiceClassGroup;

const getValidationSchema = (
  currentTrainingGroup: TrainingGroupItemType | null,
  initialCapacity?: number | null,
  included?: number | null
) => {
  const depsDate: [string, string] = ['trainStartDate', 'trainEndDate'];

  return objectYup().shape(
    {
      fullServiceName: stringYup().required('Выберите программу').nullable(),
      programmModuleId: stringYup().required('Выберите модуль'),
      name: stringYup().required('Введите наименование группы').nullable(),
      address: stringYup().required('Выберите адрес'),
      trainingGroupId: stringYup().required('Выберите план приема'),
      capacity: capacityOPValidation(currentTrainingGroup, initialCapacity, included),
      ...validationPreparationTrainDate(currentTrainingGroup),
    },
    [depsDate]
  );
};
