import { SelectOptionType } from '@mosru/esz_uikit';
import { typePersonEnum } from '../../mock-data/type-person-enum';
import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { OrderByTypeRequest, PaginationRequest, PagingList, RequestStatus } from '../../types/entities';
import {
  PersonRequestAddressData,
  PersonRequestData,
  RequestData,
  RequestDeclineData,
  RequestDeclineReasonData,
  RequestListData,
  SearchRequestsInitialFormData,
  RequestEnrollmentData,
  TrainingGroupRequestData,
  RequestEditPrivilegeData,
  RequestApproveData,
  EvaluationData,
  SchoolRequestData,
} from '../../types/requests';
import { downloadBase64File } from '../utils';
import { sendReachGoal } from '../metrica';

type GetRequestsProps = SearchRequestsInitialFormData & OrderByTypeRequest & PaginationRequest;

export const getRequestsExcel = async (data: GetRequestsProps): Promise<any> => {
  const resp = await fetchRequest.post(`${apiService.data}/Request/ToExcel`, data);

  downloadBase64File(resp.content, resp.data, resp.name);

  return resp;
};

export const postRequests = async (data: any): Promise<PagingList<RequestListData>> => {
  sendReachGoal('request-search');

  return fetchRequest.post(`${apiService.data}/Request/Search`, data);
};

export const getRequest = async (id: string): Promise<RequestData> => {
  return fetchRequest.get(`${apiService.data}/Request`, {
    id,
  });
};

export const getRequestStatuses = async (): Promise<SelectOptionType[]> => {
  const reqStatList: RequestStatus[] = await fetchRequest.get(`${apiService.data}/Request/RequestStatus`);

  return reqStatList.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export const getProgrammLevels = async (): Promise<SelectOptionType[]> => {
  const programmLevelsList = await fetchRequest.get(`${apiService.data}/Request/ProgrammLevel`);

  return programmLevelsList?.map((item: { id: number; name: string }) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export const getEducationTypeForCreate = async (): Promise<any> =>
  await fetchRequest.get(`${apiService.data}/Request/EducationTypeForCreate`);

export const getRequestDeclineReason = async (
  requestId: number,
  serviceId?: number,
  pupilId?: number
): Promise<SelectOptionType[]> => {
  const reasons = await fetchRequest.get(`${apiService.data}/Request/RequestDeclineReason/${requestId}`, {
    serviceId,
    pupilId,
  });

  return reasons.map((item: RequestDeclineReasonData) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export const createRequest = async (request: RequestData): Promise<number> =>
  await fetchRequest.post(`${apiService.data}/Request`, request);

const saveRequestPerson = async (person: PersonRequestData): Promise<number> =>
  await fetchRequest.patch(`${apiService.data}/Request/Person/${person.id}`, person);

const saveRequestPersonAddress = async (address: PersonRequestAddressData) =>
  await fetchRequest.patch(`${apiService.data}/Request/PersonAddress/${address.id}`, address);

export const postPersonAddress = async (address: PersonRequestAddressData) =>
  await fetchRequest.post(`${apiService.data}/Request/PersonAddress`, address);

const setRequestStatus = async (requestId: number, educationTypeId: number, oldStatusId: number, statusId: number) =>
  await fetchRequest.patch(`${apiService.data}/Request/Status/${requestId}`, {
    requestId,
    educationTypeId,
    oldStatusId,
    statusId,
  });

const rejectionRequest = async (requestId: number, data: RequestDeclineData) =>
  await fetchRequest.patch(`${apiService.data}/Request/Rejection/${requestId}`, data);

const enrollRequest = async (requestId: number, enrollmentData: RequestEnrollmentData) =>
  await fetchRequest.patch(`${apiService.data}/Request/Enrollment/${requestId}`, enrollmentData);

const saveTrainingGroup = async (requestId: number, trainingGroup: TrainingGroupRequestData) =>
  await fetchRequest.patch(`${apiService.data}/Request/TrainingGroup/${requestId}`, trainingGroup);

export const buildRequestByPupil = async (pupilId: number, educationTypeId: number): Promise<RequestData> =>
  await fetchRequest.get(`${apiService.data}/Request/ByPupil/${pupilId}`, {
    educationTypeId,
  });

export const buildRequestByTrainingGroup = async (slotId: string): Promise<RequestData> =>
  await fetchRequest.get(`${apiService.data}/Request/ByTrainingGroup/${slotId}`, {});

export const sendIrsRequests = async (extendEntityGuid: string) =>
  await fetchRequest.post(`${apiService.data}/Request/IrsRequest?extendEntityId=${extendEntityGuid}`, {});

export const getRequestPrivileges = async (requestId: number): Promise<RequestEditPrivilegeData> =>
  await fetchRequest.get(`${apiService.data}/Request/Privilege/${requestId}`, {});

export const signRequest = async (requestId: number, educationTypeId: number, signedDocument: string | null) =>
  await fetchRequest.patch(`${apiService.data}/Request/Sign/${requestId}`, {
    requestId,
    educationTypeId,
    signedDocument,
  });

export const getLastVersion = async (requestId: number): Promise<number> =>
  await fetchRequest.get(`${apiService.data}/Request/LastVersion/${requestId}`, {});

export const saveApprove = async (requestId: number, approve: RequestApproveData): Promise<number> =>
  await fetchRequest.patch(`${apiService.data}/Request/Approve/${requestId}`, approve);

export const saveEvaluation = async (requestId: number, evaluation: EvaluationData): Promise<number> =>
  await fetchRequest.patch(`${apiService.data}/Request/Evaluation/${requestId}`, evaluation);

export const saveEnrollment = async (requestId: number, enrollment: RequestEnrollmentData): Promise<number> =>
  await fetchRequest.patch(`${apiService.data}/Request/Enrollment/NonDONM/${requestId}`, enrollment);

export const saveRejection = async (requestId: number, decline: RequestDeclineData): Promise<number> =>
  await fetchRequest.patch(`${apiService.data}/Request/Rejection/NonDONM/${requestId}`, {
    ...decline,
    requestId,
  });

export const saveOrganization = async (requestId: number, organizationValues: SchoolRequestData): Promise<number> =>
  await fetchRequest.patch(`${apiService.data}/Request/School/${requestId}`, {
    ...organizationValues,
    requestId,
  });

export const newPerson = async (requestId: number, typePerson: typePersonEnum): Promise<{ message: string } | string> =>
  await fetchRequest.post(`${apiService.data}/Request/NewPerson/${requestId}/${typePerson}`);

export const limitOP = async (requestId: number): Promise<{ error: number; message: string }> =>
  await fetchRequest.get(`${apiService.data}/Request/LimitOP`, {
    requestId,
  });

const declineDocument = async (requestId: number, documents: number[]) =>
  await fetchRequest.delete(`${apiService.data}/Request/scannedDocuments/${requestId}`, {}, {}, undefined, documents);

const acceptDocument = async (requestId: number) =>
  await fetchRequest.post(`${apiService.data}/Request/scannedDocuments/${requestId}`);

const getContract = async (contractId: number) =>
  await fetchRequest.get(`${apiService.data}/Request/contract/${contractId}`);
const getSignContract = async (contractId: number) =>
  await fetchRequest.get(`${apiService.data}/Request/contract/${contractId}?downLoadSign=true`);

const getScannedDocument = async (documentId: number) =>
  await fetchRequest.get(`${apiService.data}/Request/scannedDocument/${documentId}`);

const requestsApi = {
  getRequestStatuses,
  getProgrammLevels,
  getRequestsExcel,
  postRequests,
  getRequest,
  getEducationTypeForCreate,
  getRequestDeclineReason,
  createRequest,
  saveRequestPerson,
  saveRequestPersonAddress,
  setRequestStatus,
  rejectionRequest,
  enrollRequest,
  saveTrainingGroup,
  buildRequestByTrainingGroup,
  buildRequestByPupil,
  postPersonAddress,
  sendIrsRequests,
  getRequestPrivileges,
  signRequest,
  getLastVersion,
  saveApprove,
  saveEvaluation,
  saveEnrollment,
  newPerson,
  saveRejection,
  saveOrganization,
  limitOP,
  declineDocument,
  acceptDocument,
  getContract,
  getSignContract,
  getScannedDocument,
};

export default requestsApi;
