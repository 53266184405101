import React, { useContext, useState } from 'react';
import { LmButton } from '@mes-ui/lemma';
import SavePanel from '../../../../components/save-panel';
import Expulsion from '../../components/modals/expulsion';
import Recovery from '../../components/modals/recovery';
import Change from '../../components/modals/change';
import Union from '../../components/modals/union';
import TransferGroup from '../../components/modals/transfer-group';
import { ServiceClassContext } from '../../service-class';
import { ServiceClassTableContext } from '../../utils';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, openDetails, active } = useContext(ServiceClassTableContext);
  const { adminView, adminEdit, accessRecover } = useContext(ServiceClassContext);

  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);

  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);

  const [openUnionPopup, setOpenUnionPopup] = useState<boolean>(false);

  const handleDeselect = () => {
    setSelected([]);
    setOpenDetails(false);
  };

  const showExpulsionModal = () => {
    setOpenExpulsionPopup(true);
  };

  const showChangeModal = () => {
    setOpenChangeModal(true);
  };

  const showTransferGroupModal = () => {
    setOpenTransferGroupPopup(true);
  };

  const showUnionModal = () => {
    setOpenUnionPopup(true);
  };

  const showRecoveryModal = () => {
    setOpenRecoveryPopup(true);
  };

  return (
    <>
      {' '}
      {openDetails && (
        <SavePanel
          hideSecondaryButton
          hidePrimaryButton
        >
          <LmButton
            dataTest="cancelSelection"
            type="button"
            variant="text"
            size="medium"
            onClick={handleDeselect}
          >
            Отменить выбор
          </LmButton>
          {active ? (
            <>
              <LmButton
                dataTest="expel"
                type="button"
                color="danger"
                variant="outline"
                size="medium"
                onClick={showExpulsionModal}
              >
                Отчислить
              </LmButton>
              {(adminView || adminEdit) && (
                <LmButton
                  dataTest="changeDates"
                  type="button"
                  variant="outline"
                  size="medium"
                  onClick={showChangeModal}
                >
                  Изменить даты обучения
                </LmButton>
              )}
              <LmButton
                dataTest="transferGroup"
                type="button"
                variant="outline"
                size="medium"
                onClick={showTransferGroupModal}
              >
                Перевести в другую группу
              </LmButton>
              <LmButton
                dataTest="union"
                type="button"
                variant="outline"
                size="medium"
                onClick={showUnionModal}
              >
                Объединить в подгруппу
              </LmButton>
            </>
          ) : (
            accessRecover && (
              <LmButton
                dataTest="recover"
                type="button"
                variant="outline"
                size="medium"
                onClick={showRecoveryModal}
              >
                Восстановить
              </LmButton>
            )
          )}
        </SavePanel>
      )}
      <Expulsion
        open={openExpulsionPopup}
        close={() => setOpenExpulsionPopup(false)}
      />
      <Recovery
        open={openRecoveryPopup}
        close={() => setOpenRecoveryPopup(false)}
      />
      <Change
        open={openChangeModal}
        close={() => setOpenChangeModal(false)}
      />
      <Union
        open={openUnionPopup}
        close={() => setOpenUnionPopup(false)}
      />
      <TransferGroup
        open={openTransferGroupPopup}
        close={() => setOpenTransferGroupPopup(false)}
      />
    </>
  );
};

export default PanelButtons;
