import React from 'react';
import { useFormikContext } from 'formik';
import { Panel } from '@mosru/esz_uikit';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { Dictionary } from '../../components/fields/dictionary';
import FormikInput from '../../../../components/formik/formik-input';
import { allowOnlyNumbers } from '../../../../lib/utils/validation';
import { TemplateService } from '../../../../types/service';
import { TypeFinancingEnum } from '../../../../mock-data/type-financing-enum';
import FormikToggle from '../../../../components/formik/formik-toggle';
import { priceMask } from '../../../../lib/utils/mask';

const Financing = () => {
  const { values } = useFormikContext<TemplateService.Data>();
  const showFields = !(values.financing?.typeFinancingId === TypeFinancingEnum.Free);

  return (
    <Panel
      title={() => 'Финансирование'}
      headingControl={() => (
        <div className="table-data__control">
          <FormikToggle
            name="financing.canChangeFinancing"
            size="small"
          />
        </div>
      )}
    >
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Тип финансирования</div>
          <div className="table-data__body">
            <Dictionary
              editMode
              isNotTableMain
              parent="financing"
              name="typeFinancing"
              dictionaryFunc={() => dictionariesApi.getTypeFinancingList(true)}
            />
          </div>
        </div>
        {showFields && (
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">Периодичность оплаты</div>
            <div className="table-data__body">
              <Dictionary
                editMode
                isNotTableMain
                parent="financing"
                name="typeValueService"
                dictionaryFunc={dictionariesApi.getTypeValueServiceList}
              />
            </div>
          </div>
        )}
        {showFields && (
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Стоимость, руб <span className="table-data__required" />
            </div>
            <div className="table-data__body">
              <div className="flex items-start">
                <div className="table-data__group">
                  <div className="table-data__label" />
                  <div
                    className="table-data__body"
                    style={{
                      width: 140,
                    }}
                  >
                    <FormikInput
                      textRight
                      rightPrefix="₽"
                      maskRegex={priceMask}
                      size="small"
                      maxLength={6}
                      placeholder="0,00"
                      name="financing.periodPrice"
                      onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default Financing;
