import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmTooltip } from '@mes-ui/lemma';

type Props = {
  childName: string;
  childDateOfBirth: string;
  hasInContingent: boolean;
};

const RequestStatusName: React.FC<Props> = ({ childName, hasInContingent, childDateOfBirth }) => {
  return (
    <>
      {childName}
      <Push size={4} />
      <div className="color-gray-dark">{childDateOfBirth}</div>
      <LmTooltip
        withArrow
        placement="top"
        content={
          hasInContingent ? (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              Сведения найдены <br /> в Реестре контингента
            </div>
          ) : (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              Сведения в Реестре <br /> контингента отсутствуют
            </div>
          )
        }
      >
        <div className="flex justify-center">
          {hasInContingent ? (
            <LmIcon
              icon="outline-edit-checkmark-circle"
              size={18}
              color="var(--LM-green-200)"
            />
          ) : (
            <LmIcon
              icon="outline-edit-close-circle"
              size={20}
              color="var(--LM-red-200)"
            />
          )}
        </div>
      </LmTooltip>
    </>
  );
};

export default RequestStatusName;
