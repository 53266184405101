import React from 'react';
import { LmListItem } from '@mes-ui/lemma';
import TableOptions from '../../../../components/table-options';
import { getDayOfTheWeekFromString } from '../../../../lib/utils/time-table';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import DropDown from '../../../../components/drop-down';
import { AuthorizationData } from '../../../../types/authorization-data';
import { Organization } from '../../../../types/organization';
import { ItemsWork } from '../../../../types/items-work';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  tableData: ItemsWork[];
  setEditMode: (value: boolean) => void;
  organization: Organization;
  rerenderTableKey: number;
  userProfile: AuthorizationData;
  setRemovedId: (value: number) => void;
  setItemWork: (value: ItemsWork) => void;
};

export const TablePoint = ({
  setEditMode,
  organization,
  rerenderTableKey,
  tableData,
  userProfile,
  setRemovedId,
  setItemWork,
}: Props) => {
  const getItemWork = async (value: number) => {
    const response = await dictionariesApi.getItemWork(value);

    setItemWork(response);
  };

  return (
    <SimpleTable
      dataTest="points"
      key={rerenderTableKey}
      data={tableData || []}
      columns={[
        {
          dataIndex: 'count',
          title: '№',
          render: (item: any) => item.count,
          width: '50px',
        },
        {
          dataIndex: 'name',
          title: 'Наименование',
          render: (item: any) => <div className="word-break-all">{item.fullName ?? item.shortName}</div>,
          width: '25%',
        },
        {
          dataIndex: 'fullAddress',
          title: 'Адрес',
          render: (item: any) => item.fullAddress,
          width: '25%',
        },
        {
          dataIndex: 'time',
          title: 'Расписание',
          render: (item: any) => getDayOfTheWeekFromString(item.timetable).map((item: string) => <div>{item}</div>),
          width: '25%',
        },
        {
          dataIndex: '',
          title: '',
          render: (item: any) =>
            !organization.isArchive &&
            !item.isDefaultItemsWork &&
            hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) && (
              <div className="disciplines-table-comment right">
                <div className="disciplines-table-comment__controls">
                  <DropDown
                    dataTest="pointOptions"
                    component={() => <TableOptions />}
                  >
                    <>
                      <LmListItem
                        dataTest="editPoint"
                        text="Редактировать"
                        icon="outline-edit-edit"
                        iconSize={20}
                        onClick={async () => {
                          await getItemWork(item.id);
                          setEditMode(true);
                        }}
                      />
                      <LmListItem
                        dataTest="deletePoint"
                        text="Удалить"
                        icon="outline-edit-trash-alt"
                        iconSize={20}
                        onClick={() => setRemovedId(item.id)}
                      />
                    </>
                  </DropDown>
                </div>
              </div>
            ),
          width: '25%',
        },
      ]}
    />
  );
};
