import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmLoader } from '@mes-ui/lemma';
import requestsApi from '../../../lib/api/requests';
import { RequestData } from '../../../types/requests';

import PrintList from './print-list';
import { withErrorBoundary } from '../../../hoc/error-boundary';
import { sendReachGoal } from '../../../lib/metrica';

const RequestPrint = () => {
  const { id, type } = useParams<Record<string, string | undefined>>();
  const [request, setRequest] = useState<RequestData | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Информация о заявлении';

    const getRequest = async () => {
      if (id) {
        const response = await requestsApi.getRequest(id);

        setRequest(response);
        setTimeout(() => {
          setLoading(true);
        }, 1500);
      }
    };

    getRequest();
    sendReachGoal('print');
  }, [id]);

  return !loading ? (
    <div className="loader-container-wrap">
      <div className="loader-container">
        <LmLoader
          view="page"
          title="Загружаем форму печати"
          description="Нужно немного подождать"
        />
      </div>
    </div>
  ) : (
    <div className="container">
      <Push size={16} />
      <div className="flex justify-end btn-panel-print">
        <LmButton
          type="button"
          onClick={() => window.print()}
        >
          Печать
        </LmButton>
      </div>

      <h2 className="text-center">Карточка заявления</h2>
      <PrintList
        data={request}
        type={type}
      />
    </div>
  );
};

export default withErrorBoundary(RequestPrint);
