import { useContext, useState } from 'react';
import Dialog from '../../../../components/modals/dialog';
import { ServiceContext } from '../../index';
import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { templateApi } from '../../../../lib/api/template';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const ToArchiveModal = ({ show, onCloseHandler }: Props) => {
  const { updateService, serviceData } = useContext(ServiceContext);
  const [loading, setLoading] = useState<boolean>(false);

  const toArchiveService = async () => {
    setLoading(true);
    try {
      if (serviceData.isTemplate) {
        await templateApi.updateTemplateStatus({
          templateId: serviceData.id,
          serviceStatusId: ServiceStatusEnum.Arhive,
          educationTypeId: serviceData.educationTypeId,
        });
      } else {
        await serviceTemplateApi.updateServiceStatus({
          serviceId: serviceData.id,
          serviceStatusId: ServiceStatusEnum.Arhive,
          educationTypeId: serviceData.educationTypeId,
        });
      }
      await updateService();
      onCloseHandler();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getTitle = () => {
    switch (serviceData.educationTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return 'Отправка детского объединения в архив';
      case EducationTypeEnum.SportEducation:
      case EducationTypeEnum.ArtHouseEducation:
        return 'Отправка услуги в архив';
      case EducationTypeEnum.DayCareCentersEducation:
        return 'Отправка программы по уходу и присмотру за детьми школьного возраста в архив';
      case EducationTypeEnum.VirtualAssistantEducation:
        return 'Отправка услуги в архив';
      case EducationTypeEnum.ProfessionalEducation:
        return 'Отправка образовательной программы в архив';
      default:
        return '';
    }
  };

  const getText = () => {
    switch (serviceData.educationTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return 'Сведения о детском объединении и всех его группах будут отправлены в архив!';
      case EducationTypeEnum.SportEducation:
      case EducationTypeEnum.ArtHouseEducation:
        return 'Сведения об услуге будут отправлены в архив без возможности восстановления!';
      case EducationTypeEnum.DayCareCentersEducation:
        return 'Сведения о программе по уходу и присмотру за детьми школьного возраста и всех ее группах будут отправлены в архив.';
      case EducationTypeEnum.VirtualAssistantEducation:
        return 'Сведения об услуге и всех ее группах обучения будут отправлены в архив.';
      case EducationTypeEnum.ProfessionalEducation:
        return 'Сведения об образовательной программе и всех ее группах будут отправлены в архив!';
      default:
        return '';
    }
  };

  return (
    <Dialog
      dataTest="archivePopup"
      isOpenDialog={show}
      title={getTitle()}
      description={`Внимание! ${getText()} Продолжить?`}
      variant="alert"
      buttonPrimaryText="Да, продолжить"
      primaryButtonModifiers={{
        loading,
      }}
      secondaryButtonModifiers={{
        disabled: loading,
      }}
      onClickSeconadaryButton={onCloseHandler}
      onClickPrimaryButton={toArchiveService}
    />
  );
};

export default ToArchiveModal;
