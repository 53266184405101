import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../../config/constants';
import ServiceProgramCreateDetails from './details';
import { getHeaderLink } from '../../utils';
import { CreateServiceContext } from '../../create';

const ServiceProgramCreate = () => {
  const { currentEducationType, educationTypes, type } = useContext(CreateServiceContext);
  const history = useHistory();

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => history.push(getHeaderLink(educationTypes, type, currentEducationType))}
        title="Новая образовательная программа"
        dataTest="servicePreparationCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Образовательные программы',
            link: getHeaderLink(educationTypes, type, currentEducationType),
          },
          {
            label: 'Описание образовательной программы',
          },
        ]}
      />
      <ServiceProgramCreateDetails />
    </>
  );
};

export default ServiceProgramCreate;
