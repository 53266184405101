import React from 'react';
import classNames from 'classnames';
import { PasswordRequirementProps, PasswordRequirementType } from './types/password';

const PasswordStrength = ({ password, passwordRules }: PasswordRequirementProps) => {
  const conditionLength = passwordRules.reduce((sum: number, rule: PasswordRequirementType) => {
    return rule.condition(password) ? sum + 1 : sum;
  }, 0);

  return (
    <div className="auth-strength">
      {passwordRules.map((rule, i) => {
        const colorClass =
          conditionLength > 0 && conditionLength === passwordRules.length
            ? 'auth-strength__item--success'
            : conditionLength === 1 && i === 0
              ? 'auth-strength__item--danger'
              : conditionLength === 2 && i < 2
                ? 'auth-strength__item--warning'
                : conditionLength > 2 && i < passwordRules.length - 1
                  ? 'auth-strength__item--other'
                  : '';

        return (
          <div
            key={rule.label}
            className={classNames('auth-strength__item', colorClass)}
          />
        );
      })}
    </div>
  );
};

export default PasswordStrength;
