import React from 'react';
import { Panel } from '@mosru/esz_uikit';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LmText } from '@mes-ui/lemma';
import { useGetDataEducationTypes } from '../../../../hooks/get-education-types';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { RegistryTypeEnum } from '../../../../mock-data/registry-type-enum';
import { userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { EducationTypeEnum } from '../../../../types/education-type';
import { PersonServiceClass } from '../../../../types/teacher';
import SimpleTable from '../../../../components/table/simple-table';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { getHeaderLink } from '../../../service/utils';

type Props = {
  serviceClass: PersonServiceClass[];
};
const ServiceClass = ({ serviceClass }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const educationTypeData = useGetDataEducationTypes(userProfile, accessObject.Services, accessAction.View);
  const currentEducationTypes = educationTypeData?.map((item) => item.value) as EducationTypeEnum[];

  return (
    <Panel
      title={() => (
        <>
          Группы
          <span className="color-gray-dark">
            {' \u00A0'}
            {serviceClass?.length}
          </span>
        </>
      )}
    >
      <SimpleTable
        data={serviceClass}
        columns={[
          {
            dataIndex: 'serviceClass',
            title: 'Код/ Наименование группы ',
            render: (item: any) => (
              <>
                <Link
                  className="brand-link"
                  to={generateLink(routes.serviceClass, {
                    id: item.serviceClassId,
                  })}
                >
                  {item.serviceClassCode}
                </Link>
                <LmText
                  as="div"
                  color="note"
                  variant="md"
                >
                  {item.serviceClassName}
                </LmText>
              </>
            ),
          },
          {
            dataIndex: 'service',
            title: 'Наименование программы',
            render: (item: any) =>
              !!item.serviceId && (
                <Link
                  className="brand-link"
                  to={generateLink(
                    `${getHeaderLink(
                      currentEducationTypes,
                      RegistryTypeEnum.serviceEducationType,
                      item.educationTypeId
                    )}/:id`,
                    {
                      id: item.serviceId,
                    }
                  )}
                >
                  {item.serviceName}
                </Link>
              ),
          },
        ]}
      />
    </Panel>
  );
};

export default ServiceClass;
