import React from 'react';
import { useFormikContext } from 'formik';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import { editSex } from '../../../../../../lib/utils/learners';
import { Learner } from '../../../../../../types/learners';

type Props = { editMode: boolean };

export const Gender = ({ editMode }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const genderOptions = [
    {
      label: 'Мужской',
      value: 1,
    },
    {
      label: 'Женский',
      value: 2,
    },
  ];

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Пол {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <LmSwitcher
            dataTest="groupGender"
            size="small"
            value={values.sexId}
            onChange={(value) => setFieldValue('sexId', value)}
            variant="gray"
          >
            {genderOptions.map((item) => (
              <LmSwitcherOption
                dataTest={String(item.value)}
                key={item.value}
                value={item.value}
              >
                {item.label}
              </LmSwitcherOption>
            ))}
          </LmSwitcher>
        ) : (
          editSex(Number(values.sexId))
        )}
      </div>
    </div>
  );
};
