import React, { Key, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmInput, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { convertReportInfoToSidebarLink, SidebarLinkType } from '../../mock-data/routes';
import TreeLinkWrapper from '../../components/tree-link-wrapper';
import { reportsApi } from '../../lib/api/reports';
import { ReportInformation } from '../../types/reports';
import { routes } from '../../config/constants';
import { sendReachGoal } from '../../lib/metrica';
import { LoaderCustom } from '../../components/loader-custom';

const Reports = () => {
  const [search, setSearch] = useState('');
  const [reportList, setReportList] = useState<ReportInformation[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const filteredReportList: SidebarLinkType[] | undefined = useMemo(() => {
    const list = search
      ? reportList?.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()) || item.hasChildren)
      : reportList;

    return list?.map(convertReportInfoToSidebarLink);
  }, [search, reportList]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const reports = await reportsApi.getReportList();

        setReportList(reports);
      } finally {
        setLoading(false);
      }
    };

    fetch();
    sendReachGoal('reports');
  }, []);

  const handleChange = (value: Key) => {
    setSearch(value as string);
  };

  return (
    <>
      <LmSubHeader
        sticky
        title="Отчеты"
        dataTest="reportsSubHeader"
        description=""
        hideReturnButton
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Отчеты',
          },
        ]}
        buttonsOrAnyActions={
          [
            {
              children: (
                <div className="report-search">
                  <LmInput
                    dataTest="orderSearch"
                    inputSize="medium"
                    value={search}
                    placeholder="Поиск по отчетам..."
                    leftInsideItem={{
                      icon: 'filled-internet-code-search',
                    }}
                    classes={{
                      wrapper: 'input-inside-item',
                    }}
                    resettable
                    onChange={handleChange}
                  />
                </div>
              ),
            },
          ] as ISubHeaderTitleButton[]
        }
      />
      {loading ? (
        <LoaderCustom
          size={140}
          hasPanel
        />
      ) : (
        <>
          <Push size={12} />
          <TreeLinkWrapper
            userProfile={userProfile}
            itemList={filteredReportList ?? []}
          />
        </>
      )}
    </>
  );
};

export default Reports;
