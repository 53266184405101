import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number, object as objectYup, string as stringYup } from 'yup';
import { useSelector } from 'react-redux';
import { SelectOptionType, SelectValueType, Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmSwitcher, LmSwitcherOption, LmListItem } from '@mes-ui/lemma';
import Popup from '../../../components/modals/popup';
import TableOptions from '../../../components/table-options';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikInput from '../../../components/formik/formik-input';
import FormikSelect from '../../../components/formik/formik-select';
import { maxLengthTextError } from '../../../lib/utils/validation';
import { PlaceServices } from '../../../types/place-service';
import DropDown from '../../../components/drop-down';
import lookupApi from '../../../lib/api/lookup';
import { hasAccessObjectAny } from '../../../lib/utils';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import RemoveModal from '../../../components/remove-modal';
import { Organization } from '../../../types/organization';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import { LoaderCustom } from '../../../components/loader-custom';
import SimpleTable from '../../../components/table/simple-table';

type FormData = {
  name?: string;
  addressId?: SelectValueType | null;
  sportObjectId?: number | null;
};

type Props = {
  organization: Organization;
  setPlace: (value: PlaceServices[]) => void | undefined;
};

const OrganizationFormService = ({ organization, setPlace }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadingPlaceService, setIsLoadingPlaceService] = useState<boolean>(false);

  const [editMode, setEditMode] = useState(false);
  const [switchForm, setSwitchForm] = useState<boolean>(false); // false - городской обьект

  const [listAddress, setListAddress] = useState<SelectOptionType[]>();
  const [currentAddress, setCurrentAddress] = useState<SelectOptionType>();

  const [placeServices, setPlaceServices] = useState<PlaceServices[]>();
  const [placeService, setPlaceService] = useState<PlaceServices | null>();

  const [removedId, setRemovedId] = useState(0);
  const [rerenderTableKey, setRerenderTableKey] = useState(0);

  const tableData = placeServices?.map((item, index) => ({
    count: index + 1,
    ...item,
  }));

  const [sityObject, setSityObject] = useState<SelectOptionType & { additionalPropertiesJson?: string }>();

  const initialFormData = useMemo(() => {
    return switchForm
      ? {
          name: '',
          addressId: currentAddress?.value ?? null,
        }
      : {
          sportObjectId: null,
        };
  }, [switchForm, currentAddress]);

  const itemPlaceFormData = useMemo(() => {
    return placeService
      ? switchForm
        ? placeService.sportObjectId
          ? initialFormData
          : {
              name: placeService.name || '',
              addressId: placeService.addressId || null,
            }
        : {
            sportObjectId: placeService.sportObjectId,
          }
      : initialFormData;
  }, [placeService, switchForm, initialFormData]);

  const initialErrors = useInitialErrors(itemPlaceFormData, getValidationSchema(switchForm));

  const getPlaceService = useCallback(async () => {
    if (organization.id) {
      const placeService = await dictionariesApi.getPlaceServiceList(organization.id);

      setPlaceServices(placeService);
      setLoading(false);
      setPlace(placeService);
    }
  }, [organization.id, setPlace]);

  const submitForm = useCallback(
    async (values: FormData) => {
      if (placeService?.id) {
        const conditionSend = switchForm
          ? ({
              ...placeService,
              name: values.name,
              addressId: values.addressId,
              sportObjectId: null,
            } as PlaceServices)
          : ({
              ...placeService,
              name: '',
              sportObjectId: values.sportObjectId,
              addressId: sityObject?.additionalPropertiesJson,
              organizationId: organization.id || 0,
            } as PlaceServices);

        await dictionariesApi.updatePlaceService(conditionSend.id, conditionSend);
        setPlaceService(null);
      } else {
        const conditionNewPlace = values.sportObjectId
          ? {
              sportObjectId: values.sportObjectId,
              addressId: sityObject?.additionalPropertiesJson,
              organizationId: organization.id,
            }
          : {
              name: values.name,
              addressId: values.addressId,
              organizationId: organization.id,
            };

        await dictionariesApi.postPlaceService(conditionNewPlace);
        setPlaceService(null);
      }
      getPlaceService();
      setEditMode(false);
      setSwitchForm(false);
      setRerenderTableKey(Math.random());
    },
    [getPlaceService, organization.id, placeService, sityObject?.additionalPropertiesJson, switchForm]
  );

  const deletePlaceService = (id: number) => {
    return dictionariesApi.deletePlaceService({
      ids: [id],
      organizationId: organization.id,
    });
  };

  const getPlaceServiceResult = async (id: number) => {
    try {
      setIsLoadingPlaceService(true);
      const response = await dictionariesApi.getPlaceService(id);

      setPlaceService(response);

      // включаем активную вкладку при редактировании
      if (!response.sportObjectId) {
        setSwitchForm(true);
      }
    } finally {
      setIsLoadingPlaceService(false);
    }
  };

  useEffect(() => {
    getPlaceService();
  }, [getPlaceService]);

  // Получаем текущий адрес
  useEffect(() => {
    const currentAddress = organization.addressList?.find((item) => organization.addressId === item.id);

    if (currentAddress) {
      setCurrentAddress({
        label: currentAddress?.fullAddress,
        value: currentAddress?.id,
      });
    }
  }, [organization.addressId, organization.addressList, editMode]);

  // Получаем список адресов
  useEffect(() => {
    const addressList = organization.addressList?.map((item: { fullAddress: string; id: number }) => {
      return {
        label: item.fullAddress,
        value: item.id,
      };
    });

    setListAddress(addressList);
  }, [organization.addressList]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Места предоставления услуг
            <span className="color-gray-dark">
              {' \u00A0'}
              {placeServices?.length}
            </span>
          </>
        )}
        headingControl={() =>
          !organization.isArchive &&
          hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) ? (
                <button
                  type="button"
                  onClick={() => {
                    setEditMode(true);
                    setPlaceService(null);
                  }}
                  className="icon-group"
                >
                  <span className="icon-group__icon">
                    <LmIcon
                      icon="filled-edit-plus"
                      size={20}
                      color="var(--LM-blue-200)"
                    />
                  </span>
                  <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
                </button>
              ) : null
        }
      >
        {loading ? (
          <LoaderCustom size={20} />
        ) : tableData?.length ? (
          <SimpleTable
            dataTest="placeServices"
            data={tableData || []}
            // pageSize={Number.MAX_SAFE_INTEGER}
            key={rerenderTableKey}
            columns={[
              {
                dataIndex: 'count',
                title: '№',
                render: (item: any) => item.count,
                width: '50px',
              },
              {
                dataIndex: 'name',
                title: 'Наименование',
                render: (item: any) => <div className="word-break-all">{item.name}</div>,
                width: '25%',
              },
              {
                dataIndex: 'address',
                title: 'Адрес',
                render: (item: any) => (item.isArchive ? 'Адрес является архивным.' : item.fullAddress),
                width: '25%',
              },
              {
                dataIndex: 'station',
                title: 'Станция метро',
                render: (item: any) =>
                  item.metroStations?.length
                    ? item.metroStations?.reduce(
                      (acc: string, value: string) => `${acc ? `${acc}, ` : ''} ${value}`,
                      ''
                    )
                    : 'Не указана',
                width: '25%',
              },
              {
                dataIndex: '',
                title: '',
                render: (item: any) => (
                  <div className="disciplines-table-comment right">
                    <div className="disciplines-table-comment__controls">
                      {!organization.isArchive &&
                        !item.isDefaultPlaceService &&
                        hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) && (
                        <DropDown
                          dataTest="placeOptions"
                          component={() => <TableOptions />}
                        >
                          <>
                            <LmListItem
                              dataTest="editPlace"
                              text="Редактировать"
                              icon="outline-edit-edit"
                              iconSize={20}
                              onClick={() => {
                                setEditMode(true);
                                setSityObject({
                                  label: item.name,
                                  value: item.sportObjectId,
                                });
                                getPlaceServiceResult(item.id);
                              }}
                            />
                            <LmListItem
                              dataTest="deletePlace"
                              text="Удалить"
                              icon="outline-edit-trash-alt"
                              iconSize={20}
                              onClick={() => setRemovedId(item.id)}
                            />
                          </>
                        </DropDown>
                      )}
                    </div>
                  </div>
                ),
                width: '25%',
              },
            ]}
          />
        ) : (
          <div className="table-no-data">Нет записей в таблице</div>
        )}
      </Panel>

      <Formik
        onSubmit={submitForm}
        enableReinitialize
        initialValues={itemPlaceFormData}
        validationSchema={getValidationSchema(switchForm)}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<FormData>) => {
          const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Popup
                dataTest="formServicePopup"
                title="Место предоставления услуг"
                open={editMode}
                loading={isLoadingPlaceService}
                size="large"
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClearData={resetForm}
                onSubmit={handleSubmit}
                onClose={() => {
                  setEditMode(false);
                  setPlaceService(null);
                  setSwitchForm(false);
                }}
              >
                <div
                  style={{
                    minHeight: '282px',
                  }}
                >
                  <LmSwitcher
                    dataTest="modalForm"
                    size="small"
                    value={switchForm}
                    onChange={(value) => {
                      setSwitchForm(value);
                    }}
                    variant="gray"
                  >
                    <LmSwitcherOption
                      dataTest="switcher-modal-form-false"
                      value={false}
                    >
                      Городской объект
                    </LmSwitcherOption>
                    <LmSwitcherOption
                      dataTest="switcher-modal-form-true"
                      value
                    >
                      Адреса организации
                    </LmSwitcherOption>
                  </LmSwitcher>
                  <Push size={16} />
                  {switchForm ? (
                    <>
                      <FormikInput
                        label="Наименование"
                        required
                        size="small"
                        name="name"
                        placeholder="Введите наименование площадки..."
                      />
                      <Push size={16} />
                      <FormikSelect
                        withSearch
                        size="small"
                        name="addressId"
                        label="Адрес места предоставления услуг"
                        required
                        options={listAddress?.length ? listAddress : []}
                        placeholder="Выберите адрес места предоставления услуг из адресов организации..."
                      />
                    </>
                  ) : (
                    <FormikSelect
                      size="small"
                      label="Городской объект"
                      required
                      withSearch
                      options={[]}
                      name="sportObjectId"
                      selectedValue={setSityObject}
                      defaultValue={placeService?.sportObjectId ? sityObject : null}
                      loadOptions={async (query) => await lookupApi.getSportObject(query)}
                      placeholder="Начните вводить наименование городского объекта..."
                    />
                  )}
                </div>
              </Popup>
            </form>
          );
        }}
      </Formik>

      <RemoveModal
        title="Удаление места предоставления услуги"
        description="Вы уверены, что хотите удалить выбранную запись?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={async () => {
          await deletePlaceService(removedId);
          getPlaceService();
          setRemovedId(0);
          setRerenderTableKey(Math.random());
        }}
      />
    </>
  );
};

export default OrganizationFormService;

const getValidationSchema = (value: boolean) => {
  if (!value) {
    return objectYup().shape({
      sportObjectId: number().required('Укажите городской объект').nullable(),
    });
  } else {
    return objectYup().shape({
      name: stringYup().required('Введите наименование').nullable().max(1000, maxLengthTextError(1000)),
      addressId: number().required('Выберите адрес предоставления услуг').nullable(),
    });
  }
};
