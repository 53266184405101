import { notify } from '../../redux/ducks/notifications';
import { store } from '../../store/store';
import { OrganizationShort } from '../../types/organization';
import fileStorageApi from '../api/file-storage';
import { OperationResultEnum } from '../../mock-data/operation-result-enum';
import { downloadBase64File } from './index';

export const transformOrganizationData = (data: OrganizationShort[], pageNum: number, countPage: number) => {
  return data.map((item: OrganizationShort, index) => {
    return {
      id: item.id,
      count: index + pageNum * countPage + 1,
      selected: false,
      address: item.address,
      isArchive: item.isArchive,
      vedomstvoId: item.vedomstvoId,
      vedomstvoName: item.vedomstvoName,
      organizationName: item.organizationName,
      vedomstvoSign: item.vedomstvoSign?.signId || null,
      organizationSign: item.organizationSign?.signId || null,
    };
  });
};

export const searchAction = (data: { [key: string]: number | boolean | string | undefined }, access: boolean) => {
  return Object.values(data).filter((item) => {
    const bol = typeof item === 'boolean' && item;
    const str = typeof item === 'string' && item;
    const num = typeof item === 'number' && item;

    return access ? bol || str : bol || num || str;
  }).length;
};

export const downloadOrganizationSign = async (id: number) => {
  const response = await fileStorageApi.getSignedFile(id);

  if (response.resultCode === OperationResultEnum.Error) {
    store.dispatch(
      notify.danger({
        dataTest: 'downloadOrganizationSign',
        title: response.errorMessage,
      })
    );
  } else {
    const { data } = response;

    downloadBase64File(data.content, data.data, data.name);
  }
};

export const checkPhone = (phone: string | undefined) => {
  return phone && /\d/.test(phone) ? phone : '';
};
