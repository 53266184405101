import React, { useContext, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmIcon } from '@mes-ui/lemma';
import PublicationStatus from '../components/publication-status';
import { ServiceContext } from '../index';
import InfoPanel from './panels/info';
import TrainingGroups from './panels/training-groups';
import Photo from '../components/panels/photo';
import Stage from './panels/stage';
import Disciplines from './panels/disciplines';
import Tests from '../components/dkgm-dsit-template/panels/tests/tests';
import ServiceSchedule from '../components/panels/service-schedule';
import ContactPerson from '../components/panels/contact-person';
import AddressDocumentsTemplate from '../components/panels/address-documents';
import AddressClass from '../components/panels/address-class';
import Financing from './panels/financing';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { accessVedomst } from '../../../mock-data/access-enum';
import Limit from '../components/dkgm-dsit-template/panels/limits/limit';
import SignInfo from './panels/sign-info';

const ServiceDetails = () => {
  const { serviceData, accessPanelEdit, updateService, userProfile } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState<string | null>(null);

  return (
    <>
      {!editMode && serviceData.templateAdmissionPlansChangeWarning && (
        <>
          <Push size={12} />
          <div className="infobox infobox--danger">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <LmIcon
                    icon="outline-misc-clock"
                    color="var(--LM-red-200)"
                    size={18}
                    className="flex-none"
                  />
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  <span
                    className="color-danger-dark"
                    dangerouslySetInnerHTML={{
                      __html: serviceData.templateAdmissionPlansChangeWarning,
                    }}
                  />
                </div>
              </div>
              <div className="infobox__control">
                <LmButton
                  dataTest="apply"
                  type="button"
                  size="medium"
                  onClick={async () => {
                    await serviceTemplateApi.copyAdmissionPlansFromTeplate(serviceData.id);
                    updateService();
                  }}
                >
                  Применить
                </LmButton>
              </div>
            </div>
          </div>
        </>
      )}
      {accessPanelEdit && (
        <PublicationStatus
          serviceStatusId={serviceData.serviceStatusId}
          editMode={editMode}
          educationType={serviceData.educationTypeId}
        />
      )}
      {!editMode && (
        <SignInfo
          sign={serviceData.sign}
          serviceStatusId={serviceData.serviceStatusId}
        />
      )}
      {(!editMode || editMode === 'info') && <InfoPanel setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'limits') &&
        ![accessVedomst.Dkgm, accessVedomst.Dsit].includes(userProfile.vedomstvoId ?? 0) && (
        <Limit setEditModeParent={setEditMode} />
      )}
      {(!editMode || editMode === 'contact') && <ContactPerson setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'address-class') && (
        <AddressClass
          setEditModeParent={setEditMode}
          editCount="one"
          label="Адрес предоставления услуги"
          textForEmpty="Для публикации услуги необходимо указать адрес предоставления"
        />
      )}
      {(!editMode || editMode === 'stage') && (
        <Stage
          setEditModeParent={setEditMode}
          serviceData={serviceData}
        />
      )}
      {(!editMode || editMode === 'financing') && <Financing setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'disciplines') && <Disciplines />}
      {(!editMode || editMode === 'schedule') && (
        <ServiceSchedule
          isService
          title="Расписание предоставления услуги"
          setEditModeParent={setEditMode}
        />
      )}
      {(!editMode || editMode === 'tests') && <Tests setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'plan') && (
        <TrainingGroups
          editable
          serviceId={serviceData.id}
          isTestService={serviceData.isTestService}
          test={serviceData.test}
        />
      )}
      {(!editMode || editMode === 'address-documents') && (
        <AddressDocumentsTemplate
          setEditModeParent={setEditMode}
          textForEmpty="Для публикации услуги необходимо указать хотя бы один адрес приема документов"
        />
      )}
      {(!editMode || editMode === 'photo') && <Photo setEditModeParent={setEditMode} />}
    </>
  );
};

export default ServiceDetails;
