import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import Popup from '../../../../../components/modals/popup';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import FormikDatePicker from '../../../../../components/formik/formik-datepicker';
import FormikTextarea from '../../../../../components/formik/formik-textarea';
import serviceClassApi from '../../../../../lib/api/service-class';
import {
  ServiceClassListData,
  ServiceClassListScheduleData,
  ServiceClassPupil,
} from '../../../../../types/service-class';
import { validationCheckDate } from '../../../../../lib/utils/validation';
import FieldService from './field/service';
import FiledServiceClass from './field/service-class';
import { generateLink, redirect } from '../../../../../lib/utils';
import { ServiceClassContext } from '../../../service-class';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { ServiceClassTableContext } from '../../../utils';
import { routes } from '../../../../../config/constants';

type Props = {
  open: boolean;
  close: () => void;
};

export type SubmitValues = {
  comment: string;
  educationTypeId: number;
  serviceClassName: string;
  megaRelationIds?: number[];
  serviceClassId: number;
  dateLearnStart: string;
  dateLearnEnd: string;
};

const Recovery: React.FC<Props> = ({ open, close }) => {
  const { updateServiceClass } = useContext(ServiceClassContext);

  const { setSelected, setOpenDetails, learners, selected, fetchTable, serviceClassData } =
    useContext(ServiceClassTableContext);

  const learner = learners?.find((value: ServiceClassPupil) => selected.includes(value.id));
  const [serviceClassList, setServiceClassList] = useState<ServiceClassListData<ServiceClassListScheduleData>[]>();

  const initialValues = useMemo(() => {
    return {
      serviceClassId: serviceClassData.id,
      serviceClassName: serviceClassData.name ?? '—',
      dateLearnStart: learner && selected.length === 1 ? new Date(learner.dateLearnStart) : '',
      dateLearnEnd: learner && selected.length === 1 ? new Date(learner.dateLearnEnd) : '',
      comment: '',
    };
  }, [learner, selected.length, serviceClassData.id, serviceClassData.name]);

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const [loading, setLoading] = useState(false);

  const isDogm = serviceClassData.educationTypeId === EducationTypeEnum.ChildrenEducation;

  const submitForm = async (values: SubmitValues) => {
    try {
      if (serviceClassData.id) {
        const response = await serviceClassApi.restorationServiceClass(values.serviceClassId, {
          ...values,
          megaRelationIds: selected,
          serviceClassId: serviceClassData.id,
          serviceClassName: values.serviceClassName,
          educationTypeId: serviceClassData.educationTypeId,
        });

        if (values.serviceClassId === 0) {
          redirect(
            generateLink(routes.serviceClass, {
              id: response,
            })
          );
        }

        fetchTable();
        setOpenDetails(false);
        setSelected([]);
        close();
        updateServiceClass();
      }
    } catch {}
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const response = await serviceClassApi.getServiceClassList(serviceClassData.serviceId);

        setServiceClassList(response);
      } finally {
        setLoading(false);
      }
    };

    open && fetch();
  }, [open, serviceClassData.serviceId]);

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
      initialErrors={initialErrors}
      initialValues={initialValues as SubmitValues}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<SubmitValues>) => {
        const { handleSubmit, isSubmitting, isValid, values, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="recoveryPopup"
              open={open}
              title="Восстановление обучающегося"
              loading={loading}
              buttonPrimaryText="Восстановить"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid,
              }}
              onSubmit={handleSubmit}
              onClose={close}
              onClearData={resetForm}
            >
              <>
                <FieldService
                  serviceClassList={serviceClassList}
                  serviceClassData={serviceClassData}
                  open={open}
                />

                <Push size={16} />

                <FiledServiceClass />

                <Push size={16} />

                <FormikDatePicker
                  label="Начало обучения"
                  required
                  size="small"
                  name="dateLearnStart"
                  disablePortal={false}
                />

                <Push size={16} />

                <FormikDatePicker
                  label="Окончание обучения"
                  required
                  minDate={new Date(values.dateLearnStart)}
                  size="small"
                  name="dateLearnEnd"
                  disablePortal={false}
                />

                <Push size={16} />

                <FormikTextarea
                  label="Причина восстановления"
                  name="comment"
                  placeholder="Введите..."
                  maxRows={2}
                />

                {isDogm ? (
                  <>
                    <Push size={16} />
                    <LmInfoBox
                      dataTest="recoveryError"
                      className="infobox--full-width"
                      variant="alert"
                      description="Обратите внимание, что восстановление электронного договора при восстановлении обучающегося не предусмотрено"
                      hidenFooter
                    />
                  </>
                ) : null}
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default Recovery;

const getValidationSchema = () => {
  const depsDate: [string, string] = ['dateLearnStart', 'dateLearnEnd'];

  return objectYup().shape(
    {
      serviceClassName: stringYup().required('Введите наименование группы').nullable(),
      serviceClassId: stringYup().required('Выберите группу обучения'),
      dateLearnStart: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'dateLearnStart',
          end: 'dateLearnEnd',
        },
        'Дата окончания обучения меньше даты начала обучения',
        'start'
      ),

      dateLearnEnd: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'dateLearnStart',
          end: 'dateLearnEnd',
        },
        'Дата окончания обучения меньше даты начала обучения',
        'end'
      ),
    },
    [depsDate]
  );
};
