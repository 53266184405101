import React, { SetStateAction, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType, FormGroup } from '@mosru/esz_uikit';
import { LmRadio } from '@mes-ui/lemma';
import FilterContentItem from '../../../../components/filter/filter-content-item';
import FormikInput from '../../../../components/formik/formik-input';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import { ContractStatusEnum } from '../../../../mock-data/contract-status-enum';
import { SearchRequestsInitialFormData, SearchRequestsTableOptions } from '../../../../types/requests';
import { AuthorizationData, generalAccess } from '../../../../types/authorization-data';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { clearField } from '../../../../lib/utils/requests';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { EducationTypeEnum } from '../../../../types/education-type';

type Props = {
  setTableOptions: React.Dispatch<SetStateAction<SearchRequestsTableOptions>>;
  infoChild: { value: number | string; label: string }[];
  privilegeStatusList: SelectOptionType[];
  period: { value: boolean; label: string }[];
  isArtHouseSportEducation: boolean;
  userProfile: AuthorizationData;
  contractStatusData: SelectOptionType[];
  contractDeclineReasonOptions: SelectOptionType[];
  requestStatusData: SelectOptionType[];
  requestSourceData: SelectOptionType[];
  privilegiesData: SelectOptionType[];
  allowedEducationTypeId: number;
};

const StatementChild: React.FC<Props> = ({
  setTableOptions,
  infoChild,
  privilegeStatusList,
  period,
  isArtHouseSportEducation,
  userProfile,
  contractStatusData,
  contractDeclineReasonOptions,
  requestStatusData,
  requestSourceData,
  privilegiesData,
  allowedEducationTypeId,
}) => {
  const { values, setFieldValue, validateField } = useFormikContext<SearchRequestsInitialFormData>();

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  // показывать статус договора
  const showContractStatus = useMemo(() => {
    return (
      hasAccessObjectAny(
        userProfile,
        [accessObject.RequestDOGM, accessObject.RequestDayCare],
        accessAction.ViewRegistry
      ) || isAdmin
    );
  }, [isAdmin, userProfile]);

  // показывать преподавателя
  const canShowTeacher = useMemo(() => {
    return (
      hasAccessObjectAny(userProfile, [accessObject.RequestSport], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestArtHouse], accessAction.ViewRegistry) ||
      isAdmin
    );
  }, [isAdmin, userProfile]);

  useEffect(() => {
    clearField(values.requestDate, 'requestDate', validateField);
  }, [validateField, values.requestDate]);

  // Дефолтное значение для полей "Льготная категория" и "Статус проверки МЭШ.Льготники"
  const defaultValuePrivilegeCategoryIdIsAdmin = isAdmin &&
    values.educationTypeId === EducationTypeEnum.ArtHouseEducation && {
    label: 'Все',
    value: null,
  };

  const defaultValuePrivilegeCategoryIdOther =
    allowedEducationTypeId === EducationTypeEnum.ArtHouseEducation
      ? {
          label: 'Все',
          value: null,
        }
      : null;

  const defaultValuePrivilegeCategoryId = isAdmin
    ? defaultValuePrivilegeCategoryIdIsAdmin
    : defaultValuePrivilegeCategoryIdOther;

  return (
    <FilterContentItem
      dataTest="statement"
      title="Заявление и ребёнок"
    >
      <FormikDatePicker
        label="Дата заявления"
        maxDate={new Date()}
        isIntervalDate
        size="small"
        name="requestDate"
      />
      <FormikInput
        label="Номер заявления"
        name="requestNumber"
        placeholder="Введите..."
        size="small"
      />
      <FormikSelect
        label="Статус заявления"
        name="requestStatusId"
        size="small"
        withSearch
        showTooltip
        disabledPortalTooltip
        options={requestStatusData}
        defaultValue={{
          value: '',
          label: 'Все',
        }}
      />
      <FormikSelect
        label="Источник заявления"
        name="requestSourceId"
        size="small"
        withSearch
        options={requestSourceData}
        defaultValue={{
          value: '',
          label: 'Все',
        }}
      />
      {showContractStatus && (
        <>
          <FormikSelect
            name="contractStatusId"
            label="Статус договора"
            size="small"
            withSearch
            showTooltip
            disabledPortalTooltip
            options={contractStatusData}
            defaultValue={{
              value: '',
              label: 'Все',
            }}
            selectedValue={(option) => {
              setFieldValue(
                'contractDeclineReasonId',
                option?.value === ContractStatusEnum.Terminated ? null : undefined
              );
            }}
          />
          {values.contractStatusId === ContractStatusEnum.Terminated && (
            <FormikSelect
              label="Сторона расторжения договора"
              name="contractDeclineReasonId"
              size="small"
              withSearch
              options={contractDeclineReasonOptions}
              defaultValue={{
                value: null,
                label: 'Все',
              }}
            />
          )}
        </>
      )}
      {canShowTeacher && (
        <FormikInput
          label="Преподаватель"
          name="person"
          placeholder="Введите..."
          size="small"
        />
      )}
      <FormikFormGroup
        name=""
        label="ФИО ребёнка"
      >
        <div className="filter-content">
          <FormikInput
            name="childLastName"
            placeholder="Введите фамилию..."
            size="small"
          />
          <FormikInput
            name="childFirstName"
            placeholder="Введите имя..."
            size="small"
          />
          <FormikInput
            name="childMiddleName"
            placeholder="Введите отчество..."
            size="small"
          />
        </div>
      </FormikFormGroup>
      {!isArtHouseSportEducation && (
        <FormGroup label="Сведения о ребёнке в Контингенте">
          <>
            {infoChild.map(({ value, label }) => (
              <LmRadio
                key={label}
                boxSize="large"
                name={`contingentLinkTypeId-${value}`}
                dataTest={`contingentLinkTypeId-${value}`}
                onChange={() => {
                  setFieldValue('contingentLinkTypeId', value);
                }}
                checked={values.contingentLinkTypeId === value}
              >
                {label}
              </LmRadio>
            ))}
          </>
        </FormGroup>
      )}
      <FormGroup label="Данные за период">
        <>
          {period.map(({ value, label }) => (
            <LmRadio
              key={label}
              boxSize="large"
              name={`isActualRequestIndex-${value}`}
              dataTest={`isActualRequestIndex-${value}`}
              onChange={() => {
                setTableOptions((prev): any => {
                  return {
                    ...prev,
                    isActualIndex: value,
                  };
                });
                setFieldValue('isActualRequestIndex', value);
              }}
              checked={values.isActualRequestIndex === value}
            >
              {label}
            </LmRadio>
          ))}
        </>
      </FormGroup>
      {(hasGeneralAccess(userProfile, generalAccess.CheckPrivileges) || isAdmin) && (
        <>
          <FormikSelect
            label="Льготная категория"
            name="privilegeCategoryId"
            size="small"
            withSearch
            showTooltip
            disabledPortalTooltip
            options={privilegiesData}
            defaultValue={defaultValuePrivilegeCategoryId || null}
            placeholder="Начните вводить наименование льготной категории..."
            explainText={
              isAdmin && values.educationTypeId !== EducationTypeEnum.ArtHouseEducation
                ? 'Выберите тип услуги "Дома творчества"'
                : undefined
            }
            disabled={isAdmin && values.educationTypeId !== EducationTypeEnum.ArtHouseEducation}
          />

          <FormikSelect
            label="Статус проверки МЭШ.Льготники"
            name="privilegeStatusId"
            size="small"
            options={privilegeStatusList}
            defaultValue={defaultValuePrivilegeCategoryId || null}
            placeholder="Выберите..."
            explainText={
              isAdmin && values.educationTypeId !== EducationTypeEnum.ArtHouseEducation
                ? 'Выберите тип услуги "Дома творчества"'
                : undefined
            }
            disabled={isAdmin && values.educationTypeId !== EducationTypeEnum.ArtHouseEducation}
          />
        </>
      )}

      <FormikFormGroup
        name=""
        label="Просроченные"
      >
        <>
          <FormikCheckbox
            key="isOverdueToday"
            label="на сегодня"
            name="isOverdueToday"
            boxSize="small"
          />
          <FormikCheckbox
            key="isOverdueTomorrow"
            label="на завтра"
            name="isOverdueTomorrow"
            boxSize="small"
          />
        </>
      </FormikFormGroup>
    </FilterContentItem>
  );
};

export default StatementChild;
