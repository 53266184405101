import { useEffect, useState } from 'react';
import { serviceTemplateApi } from '../lib/api/service-template';
import { TrainingGroupSCBooking } from '../types/requests';
import { Nullable } from '../types/entities';

type Props = {
  serviceId: number;
};

export const useGetTrainingGroupSCBooking = ({ serviceId }: Props): Nullable<TrainingGroupSCBooking[]> => {
  const [trainingGroupList, setTrainingGroupList] = useState<Nullable<TrainingGroupSCBooking[]>>([]);

  useEffect(() => {
    const fetch = async () => {
      const tgList = await serviceTemplateApi.getTrainingGroupsSCBooking(serviceId);

      setTrainingGroupList(tgList);
    };

    if (serviceId) {
      fetch();
    } else {
      setTrainingGroupList(null);
    }
  }, [serviceId]);

  return trainingGroupList;
};
