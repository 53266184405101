import { LmPlaceholderImage, LmTable, LmTableColumn, LmTableContent, LmTableFooterPagination } from '@mes-ui/lemma';
import React, { useMemo } from 'react';
import { AsyncTableType } from '../../types/table';
import { pageSizeOptions } from '../../mock-data';

const AsyncTable = ({
  data,
  columns,
  pageSize,
  pageNum,
  setPageSize,
  setPageNumber,
  loading,
  itemsAll,
  rowLimit,
  stick,
  paginationRef,
  emptyString,
  loaderTitle,
  loaderDescription,
  dataTest,
}: AsyncTableType) => {
  const [items, numbersPages] = useMemo(() => {
    if (itemsAll && data && pageSize) {
      const maxRows = rowLimit ? Math.min(rowLimit, itemsAll) : itemsAll;
      const numbersPages = Math.ceil(maxRows / pageSize);
      const fakeArray = Array(itemsAll).fill({});

      if (numbersPages <= pageNum && setPageNumber) {
        setPageNumber(0);

        return [data, 1];
      }
      for (let i = 0; i < data.length; i++) {
        fakeArray[i + pageNum * pageSize] = data[i];
      }

      return [fakeArray, numbersPages];
    } else if (!data?.length) {
      return [[], 0];
    } else {
      return [data, 1];
    }
  }, [pageSize, itemsAll, data, pageNum, rowLimit, setPageNumber]);

  return (
    <LmTable
      initialState={{
        limit: pageSize,
        numberPages: numbersPages,
        dataTable: {
          data: items,
          isDataLoading: loading,
        },
      }}
    >
      <LmTableContent
        emptyDataComponent={
          <LmPlaceholderImage
            imageName="placeholder--book-2"
            title={emptyString || 'Ничего не найдено'}
          />
        }
        loadingComponent={
          <LmPlaceholderImage
            imageName="loading--page"
            title={loaderTitle || 'Загружаем данные...'}
            description={loaderDescription || 'Нужно немного подождать...'}
          />
        }
        overflow
      >
        {columns.map((column, index, array) => (
          <LmTableColumn
            key={column.dataIndex}
            sortable={column.sortable}
            hasAdditLeftPadding={index === 0}
            hasAdditRightPadding={index === array.length - 1}
            width={column.width}
            stick={stick && !index}
            dataKey={column.dataIndex}
            title={
              <span
                style={{
                  textAlign: 'left',
                }}
              >
                {column.title}
              </span>
            }
          >
            {(value, item: any) => column.render && !!Object.keys(item).length && column.render(item)}
          </LmTableColumn>
        ))}
      </LmTableContent>
      {!!data?.length && itemsAll > pageSizeOptions[0].value && (
        <LmTableFooterPagination
          dataTest={dataTest || 'asyncTable'}
          key={pageNum} // TODO: временный костыль. initialPage работает только при первоначальной установке
          ref={paginationRef}
          initialLimit={pageSize}
          initialPage={pageNum + 1} // TODO: баг в лемме
          limitList={pageSizeOptions.map((item) => item.value)}
          getCurrentPage={setPageNumber}
          getCurrentLimit={(val) => {
            setPageSize?.(val);
            setPageNumber(0);
          }}
          hasGoToPageButton
        />
      )}
    </LmTable>
  );
};

export default AsyncTable;
