import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LmListItem } from '@mes-ui/lemma';
import DropDown from '../../../../../components/drop-down';
import TableOptions from '../../../../../components/table-options';
import { userProfileSelector } from '../../../../../redux/selectors';
import { AppState } from '../../../../../redux/types/state';
import { hasAccessObjectParent, hasGeneralAccess } from '../../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../../types/authorization-data';
import { ScheduleData, TrainingGroupData } from '../../../../../types/service';
import { TrainingGroupStatusEnum } from '../../../../../mock-data/training-group-status-enum';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { apply } from '../../../../../lib/utils/service';
import { ServiceContext } from '../../..';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';

type Props = {
  itemData: TrainingGroupData;
  editPlan?: (id: number) => void;
  setIdRemoveModal?: (id: number) => void;
  isSubmitRequest?: boolean;
  udodAccessDenied: boolean;
};

const ControlPanel: React.FC<Props> = ({ itemData, editPlan, setIdRemoveModal, udodAccessDenied }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { serviceData } = useContext(ServiceContext);

  const canEdit = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
      hasAccessObjectParent(userProfile, accessObject.Services, accessAction.Edit),
    [userProfile]
  );
  const canEditButton = useMemo(
    () => canEdit && serviceData.serviceStatusId === ServiceStatusEnum.Draft,
    [canEdit, serviceData.serviceStatusId]
  );
  const canRemoveButton = useMemo(
    () =>
      canEditButton &&
      (!itemData.scheduleList?.length || itemData.scheduleList.every((s: ScheduleData) => s.canDeleteSchedule)),
    [itemData.scheduleList, canEditButton]
  );

  const canCreateRequestButton = useMemo(
    () =>
      canEdit &&
      itemData.scheduleList?.length > 0 &&
      itemData.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
      itemData.scheduleList[0].isAccessible &&
      itemData.educationTypeId !== EducationTypeEnum.VirtualAssistantEducation &&
      itemData.educationTypeId !== EducationTypeEnum.ChildrenEducation &&
      itemData.educationTypeId !== EducationTypeEnum.ChildrenNonDogmEducation &&
      itemData.educationTypeId !== EducationTypeEnum.ProfessionalEducation &&
      !udodAccessDenied,
    [canEdit, itemData, udodAccessDenied]
  );

  return canEditButton || canRemoveButton || canCreateRequestButton ? (
    <div className="flex justify-end">
      <DropDown
        dataTest="planOptions"
        component={() => <TableOptions />}
      >
        <>
          {canEditButton && (
            <LmListItem
              dataTest="editPlan"
              text="Редактировать"
              icon="outline-edit-edit"
              iconSize={20}
              onClick={() => editPlan && editPlan(itemData.id)}
            />
          )}
          {canCreateRequestButton && (
            <LmListItem
              dataTest="addRequest"
              text="Подать заявление"
              icon="outline-files-file-add"
              iconSize={20}
              onClick={async () => await apply(itemData.scheduleList[0].id, serviceData.educationTypeId)}
            />
          )}
          {canRemoveButton && (
            <LmListItem
              dataTest="deletePlan"
              text="Удалить"
              icon="outline-edit-trash-alt"
              iconSize={20}
              onClick={() => setIdRemoveModal && setIdRemoveModal(itemData.id)}
            />
          )}
        </>
      </DropDown>
    </div>
  ) : null;
};

export default ControlPanel;
