import { useCallback, useEffect } from 'react';

// Очищение состояния истории при перезагрузке страницы и смене URL
const useClearHistoryState = () => {
  const clearState = useCallback(() => {
    window.history.replaceState({}, '');
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', clearState);

    return () => window.removeEventListener('beforeunload', clearState);
  }, [clearState]);
};

export default useClearHistoryState;
