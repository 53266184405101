import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmInfoBox, LmSelectNew } from '@mes-ui/lemma';
import { RequestData, RequestEditPrivilegeData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import { formatDate, formatDateAndTime } from '../../../lib/utils/date';
import contingentApi from '../../../lib/api/contingents';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { SelectOptionType } from '../../../types/entities';

type Props = {
  requestData: RequestData;
};

const Privileges: React.FC<Props> = ({ requestData }) => {
  const [privilege, setPrivilege] = useState<RequestEditPrivilegeData | undefined>();
  const [categories, setCategories] = useState<SelectOptionType[]>([]);
  const [priviligeId, setPrivilegeId] = useState<SelectOptionType>();
  const [needGetPrivilege, setNeedPrivilege] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const p = await requestsApi.getRequestPrivileges(requestData.id);

      setPrivilege(p);
      setNeedPrivilege(false);
    };

    if (requestData?.id && needGetPrivilege) {
      fetch();
    }
  }, [requestData.id, needGetPrivilege]);

  useEffect(() => {
    const fetch = async () => {
      const c = await dictionariesApi.getPrivilegeCategories();

      setCategories(c);
    };

    fetch();
  }, []);

  const isCheckPrivilegeBtnDisabled = useMemo(
    () =>
      requestData.contingentLinkTypeId !== ContingentLinkTypeEnum.Found ||
      !requestData.requestStatusId ||
      requestData.requestStatusId === RequestStatusEnum.Included ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.ForInclude ||
      requestData.requestStatusId === RequestStatusEnum.Archive ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      requestData.requestStatusId === RequestStatusEnum.Excluded,
    [requestData]
  );

  const checkForBenefits = useCallback(async () => {
    await contingentApi.checkPrivilege(
      requestData.id,
      requestData.contingentGuid,
      requestData.entityId,
      priviligeId?.value ? Number(priviligeId.value) : 0
    );
    setNeedPrivilege(true);
  }, [priviligeId, requestData.contingentGuid, requestData.entityId, requestData.id]);

  const buildPrivilegeHeader = useCallback(() => {
    if (!privilege || !privilege.statusId || !privilege.statusDate || privilege.statusId === 117) {
      return (
        <LmInfoBox
          dataTest="checkBenefitInfo"
          className="infobox--full-width"
          variant="information"
          title="Проверка льготных категорий не запрашивалась"
          buttonPrimaryText="Проверить наличие льгот"
          onClickPrimaryButton={checkForBenefits}
          primaryButtonModifiers={{
            disabled: !priviligeId || isCheckPrivilegeBtnDisabled,
          }}
          hideSecondaryButton
        />
      );
    }

    if (privilege.statusId === 114) {
      return (
        <LmInfoBox
          dataTest="checkBenefitWarning"
          className="infobox--full-width"
          variant="warning"
          title="Проводится проверка действий льготной категории"
          description={`Начало проверки: ${formatDateAndTime(privilege.statusDate)}`}
          hidenFooter
        />
      );
    }

    return (
      <LmInfoBox
        dataTest="checkBenefitSuccess"
        className="infobox--full-width"
        variant="success"
        title="Льготная категория подтверждена"
        description={
          <>
            {privilege?.privilegeList[0]?.privilegeCategoryName ?? ''} (
            {privilege?.privilegeList[0]?.privilegeStartDate
              ? formatDate(privilege?.privilegeList[0].privilegeStartDate)
              : ''}
            —
            {privilege?.privilegeList[0]?.privilegeEndDate
              ? formatDate(privilege?.privilegeList[0].privilegeEndDate)
              : ''}
            ) Дата проверки: {formatDateAndTime(privilege.statusDate)}
          </>
        }
        hidenFooter
      />
    );
  }, [privilege, checkForBenefits, priviligeId, isCheckPrivilegeBtnDisabled]);

  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Льготные категории'}>
        <div className="container">
          {buildPrivilegeHeader()}
          <Push size={20} />
          <div className="table-data">
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Перечень льготных категорий</div>
              <div className="table-data__body">
                {privilege && privilege.statusDate && privilege.statusId !== 117 ? (
                  <div className="flex justify-between">
                    {privilege.privilegeList?.length > 0
                      ? privilege.privilegeList[0].privilegeCategoryName
                      : privilege.privilegeCategoryName
                        ? privilege.privilegeCategoryName
                        : 'Активные льготные категории отсутствуют'}
                  </div>
                ) : (
                  <LmSelectNew
                    dataTest="selectPrivilegeId"
                    name="privilegeId"
                    value={priviligeId || null}
                    onChange={(selectedOption: SelectOptionType | null) => {
                      selectedOption && setPrivilegeId(selectedOption as SelectOptionType);
                    }}
                    disabled={isCheckPrivilegeBtnDisabled}
                    options={categories}
                    grouped={false}
                    multiple={false}
                    settingDropdownAsPopover={{
                      disablePortal: true,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Privileges;
