import React from 'react';
import Dialog from '../../../../components/modals/dialog';

type Props = {
  open: boolean;
  title: string;
  onCloseHandler: () => void;
  onSaveHandler: () => void;
};

const AddOrganizationPrevYear = ({ open, onCloseHandler, title, onSaveHandler }: Props) => {
  return (
    <Dialog
      dataTest="addOrganizationPrevYear"
      isOpenDialog={open}
      title={title}
      description={
        <div>
          Обратите внимание! После подтверждения действия в план приема на текущий период обучения будут скопированы
          значения из предыдущего периода обучения. Уже введенные значения будут заменены. Продолжить?
        </div>
      }
      variant="question"
      buttonPrimaryText="Продолжить"
      onClickSeconadaryButton={onCloseHandler}
      onClickPrimaryButton={onSaveHandler}
    />
  );
};

export default AddOrganizationPrevYear;
