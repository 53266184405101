import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import { AuthorizationData } from '../../../../types/authorization-data';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { SearchGroupTrainingInitialData } from '../../../../types/service-class';
import { usePrevious } from '../../../../hooks/use-previous';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  programModules: SelectOptionType[];
};

export const FieldModule: React.FC<Props> = ({ isAdmin, userProfile, programModules }) => {
  const { values, setFieldValue } = useFormikContext<SearchGroupTrainingInitialData>();

  const prevProgrammModuleId = usePrevious(values.serviceId);

  // Модуль
  const accessModule: boolean = useMemo(() => {
    return hasAccessObjectAny(userProfile, [accessObject.ServiceOP], accessAction.View) || isAdmin;
  }, [isAdmin, userProfile]);

  useEffect(() => {
    // если мы меняем Наименование услуги, то обнуляем значение модуля
    if (values.serviceId !== prevProgrammModuleId && prevProgrammModuleId) {
      setFieldValue('programmModuleId', null);
    }
    // если в Наименование услуги нет значения, то обнуляем значение
    if (!values.serviceId) {
      setFieldValue('programmModuleId', null);
    }
  }, [prevProgrammModuleId, setFieldValue, values.programmModuleId, values.serviceId]);

  return accessModule ? (
    <FormikSelect
      label="Модуль"
      size="small"
      withSearch
      name="programmModuleId"
      options={programModules}
      showTooltip
      disabledPortalTooltip
      placeholder="Выберите..."
      explainText={!values.serviceId ? 'Сначала выберите образовательную программу' : undefined}
      disabled={!values.serviceId}
      selectedValue={(option?: SelectOptionType) => {
        setFieldValue('programmModuleId', option?.value || '');
        setFieldValue('programmModuleName', option?.label || '');
      }}
      defaultValue={
        values.programmModuleId && values.programmModuleName
          ? {
              label: values.programmModuleName,
              value: values.programmModuleId,
            }
          : undefined
      }
    />
  ) : null;
};
