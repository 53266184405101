import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';

/**
 * Фукнция для генерации Title
 * @param edType - тип образования
 * @param fieldName - название поля
 */

export const geTitleByType = (edType: string, fieldName: string): string => {
  let title = '';

  switch (edType) {
    case RegistryTypeEnum.serviceChildType:
    case RegistryTypeEnum.serviceEducationType:
    case RegistryTypeEnum.serviceTemplateType:
      title = fieldName === 'direction' ? 'Вид деятельности' : 'Уровень программы';
      break;
    case RegistryTypeEnum.serviceEducationProgramType:
      title = fieldName === 'direction' ? 'Профессия' : 'Квалификация';
      break;
    default:
      break;
  }

  return title;
};
