export enum MatchLevelEnum {
  low,
  medium,
  high,
}

export enum MatchTypeEnum {
  // найдена персона со степенью совпадения от 0 до 69%
  oneLow,

  // найдена персона со степенью совпадения от 70 до 94%
  oneMedium,

  // найдена персона со степенью совпадения >=95%
  oneHigh,

  // найдено несколько персон, все персоны имеют степень совпадения от 0 до 69%
  several,

  // найдено несколько персон, хотя бы одна имеет степень совпадения >=70%
  severalWithHigh,

  // нет совпадений
  noMatch,
}
