import { css } from 'styled-components';

export const styleVars = {
  // Colors
  brandPrimary: '#0055CB',
  brandPrimaryLight: '#4794FF',
  brandPrimaryXLight: '#E0EDFF',
  brandPrimaryDark: '#0044A2',
  brandPrimaryXDark: '#2B3858',

  brandSuccess: '#3DAF3A',
  brandSuccessLight: '#84D682',
  brandSuccessXLight: '#EBF8EA',
  brandSuccessDark: '#318C2E',
  brandSuccessXDark: '#1F581D',

  brandWarning: '#F2A657',
  brandWarningLight: '#F7CA9A',
  brandWarningXLight: '#FEF6EE',
  brandWarningDark: '#ED861A',
  brandWarningXDark: '#99540C',

  brandDanger: '#CC2A35',
  brandDangerLight: '#E47B83',
  brandDangerXLight: '#FBE9EA',
  brandDangerDark: '#A3212A',
  brandDangerXDark: '#66151A',

  brandGrayDark: '#7B819B',
  brandGray: '#B0B3C3',
  brandGrayLight: '#E5E6EB',
  brandGrayXLight: '#F4F3F8',
  brandGrayOther: '#E8E8EF',
  borderColor: '#D7D9E1',

  textColor: '#181920',
  titleColor: '#0C0D10',

  // Fonts
  fontFamilyBase: "'Inter', sans-serif",
  fontSizeXLarge: '32px',
  fontSizeLarge: '24px',
  fontSizeXMedium: '20px',
  fontSizeMedium: '18px',
  fontSizeBase: '15px',
  fontSizeSmall: '14px',
  fontSizeXSmall: '12px',
  fontWeightBase: '400',
  fontWeightBold: '700',
  fontWeightBlack: '900',
  lineHeightBase: 1.25,
  lineHeightText: 1.5,

  // Grid system
  gridGap: '24px',

  // Components
  controlLarge: '48px',
  controlMedium: '40px',
  controlSmall: '36px',
};

export const GlobalStyleVars = css`
  :root {
    --brand-primary: ${styleVars.brandPrimary};
    --brand-primary-light: ${styleVars.brandPrimaryLight};
    --brand-primary-xlight: ${styleVars.brandPrimaryXLight};
    --brand-primary-dark: ${styleVars.brandPrimaryDark};
    --brand-primary-xdark: ${styleVars.brandPrimaryXDark};

    --brand-success: ${styleVars.brandSuccess};
    --brand-success-light: ${styleVars.brandSuccessLight};
    --brand-success-xlight: ${styleVars.brandSuccessXLight};
    --brand-success-dark: ${styleVars.brandSuccessDark};
    --brand-success-xdark: ${styleVars.brandSuccessXDark};

    --brand-warning: ${styleVars.brandWarning};
    --brand-warning-light: ${styleVars.brandWarningLight};
    --brand-warning-xlight: ${styleVars.brandWarningXLight};
    --brand-warning-dark: ${styleVars.brandWarningDark};
    --brand-warning-xdark: ${styleVars.brandWarningXDark};

    --brand-danger: ${styleVars.brandDanger};
    --brand-danger-light: ${styleVars.brandDangerLight};
    --brand-danger-xlight: ${styleVars.brandDangerXLight};
    --brand-danger-dark: ${styleVars.brandDangerDark};
    --brand-danger-xdark: ${styleVars.brandDangerXDark};

    --brand-gray-dark: ${styleVars.brandGrayDark};
    --brand-gray: ${styleVars.brandGray};
    --brand-gray-light: ${styleVars.brandGrayLight};
    --brand-gray-xlight: ${styleVars.brandGrayXLight};
    --brand-gray-other: ${styleVars.brandGrayOther};
    --border-color: ${styleVars.borderColor};

    --text-color: ${styleVars.textColor};
    --title-color: ${styleVars.titleColor};

    --font-family-base: ${styleVars.fontFamilyBase};
    --font-size-xlarge: ${styleVars.fontSizeXLarge};
    --font-size-large: ${styleVars.fontSizeLarge};
    --font-size-xmedium: ${styleVars.fontSizeXMedium};
    --font-size-medium: ${styleVars.fontSizeMedium};
    --font-size-base: ${styleVars.fontSizeBase};
    --font-size-small: ${styleVars.fontSizeSmall};
    --font-size-xsmall: ${styleVars.fontSizeXSmall};
    --font-weight-base: ${styleVars.fontWeightBase};
    --font-weight-bold: ${styleVars.fontWeightBold};
    --font-weight-black: ${styleVars.fontWeightBlack};
    --line-height-base: ${styleVars.lineHeightBase};
    --line-height-text: ${styleVars.lineHeightText};

    --grid-gap: ${styleVars.gridGap};
  }
`;
