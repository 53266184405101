import React, { useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { useSelector } from 'react-redux';
import { SelectOptionType, Panel } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { ServiceClassContext } from '../service-class';
import { ServiceClassData } from '../../../types/service-class';
import serviceClassApi from '../../../lib/api/service-class';
import OrganizationField from '../components/fields/organization';
import TeacherField from '../components/fields/teacher';
import PupilInGroupField from '../components/fields/pupil-in-group';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import SimpleInput from '../../../components/fields/simple-input';
import VedomstvoField from '../components/fields/vedomstvo';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { maxCommentLength } from '../../../lib/utils/service-class';
import { serviceClassStatusEnum } from '../../../mock-data/service-class-status';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { EducationTypeEnum } from '../../../types/education-type';
import SavePanel from '../../../components/save-panel';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const ServiceClassGroup: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceClassData, updateServiceClass, adminEdit, accessEditServiceClass } = useContext(ServiceClassContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [loading, setLoading] = useState(false);

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const vedomstvoData: SelectOptionType[] = useGetDataDepartment();

  const initialData = useMemo(() => {
    return {
      ...serviceClassData,
      vedomstvoId: serviceClassData?.vedomstvoId,
      vedomstvoName: serviceClassData?.vedomstvoName,
      organizationId: serviceClassData?.organizationId || userProfile?.organizationId,
      organizationName: serviceClassData?.organizationName || userProfile?.organizationName,
    };
  }, [serviceClassData, userProfile?.organizationId, userProfile?.organizationName]);

  const initialErrors = useInitialErrors(initialData, getValidationSchema());

  const submit = async (data: ServiceClassData) => {
    setLoading(true);

    try {
      await serviceClassApi.updateServiceClass(data);
      updateServiceClass();
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const sortedlist = vedomstvoData.filter((item: SelectOptionType) =>
    adminEdit ? item.value === 4 || item.value === 3 : item
  );

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submit(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialData as ServiceClassData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceClassData>) => {
        const { values, isValid, resetForm, handleSubmit, submitForm } = formikProps;

        const handleCancel = () => {
          setEditModeParent && setEditModeParent(null);
          setEditMode(false);
          resetForm();
        };

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              title={() => <>Сведения о группе</>}
              headingControl={() => {
                return !editMode &&
                  serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                  accessEditServiceClass ? (
                      <button
                        type="button"
                        onClick={() => {
                          setEditModeParent && setEditModeParent('group');
                          setEditMode(true);
                        }}
                        className="icon-group"
                      >
                        <span className="icon-group__icon">
                          <LmIcon
                            icon="filled-edit-edit"
                            size={20}
                            color="var(--LM-blue-200)"
                          />
                        </span>
                        <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                      </button>
                    ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <VedomstvoField
                    required
                    list={sortedlist}
                    editMode={editMode}
                    name="vedomstvo"
                    defaultValue={serviceClassData.vedomstvoName || '—'}
                    disabled={
                      (!!values.id && values.id > 0 && !adminEdit) ||
                      values.educationTypeId === EducationTypeEnum.ArtHouseEducation
                    }
                  />

                  <OrganizationField
                    required
                    name="organization"
                    editMode={editMode}
                    disabled={Boolean(values?.id)}
                    educationType={values.educationTypeId}
                    defaultValue={values.organizationName || '—'}
                  />

                  <SimpleInput
                    required
                    name="name"
                    editMode={editMode}
                    label="Наименование группы"
                  />

                  <SimpleInput
                    name="code"
                    required
                    label="Код группы"
                  />

                  {setEditModeParent && (
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Этапы обучения</div>
                      <div className="table-data__body">
                        {values.serviceStageList === '' ? '—' : values.serviceStageList}
                      </div>
                    </div>
                  )}

                  <TeacherField
                    editMode={editMode}
                    label="Руководитель"
                    name="supervisorTeacher"
                  />

                  <PupilInGroupField
                    name="included"
                    editMode={editMode}
                    label="Человек в группе"
                  />

                  <SimpleTextArea
                    name="description"
                    label="Комментарий"
                    editMode={editMode}
                    placeholder="Введите..."
                    maxLength={maxCommentLength}
                  />
                </div>
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={handleCancel}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceClassGroup;

const getValidationSchema = () =>
  objectYup().shape({
    organizationName: stringYup().required('Выберите организацию'),
    name: stringYup().required('Введите наименование группы').nullable(),
    capacity: numberYup()
      .min(1, 'Предельное число обучающихся в группе не может быть отрицательным числом или нулем')
      .nullable(),
  });
