import React, { ReactChild, ReactChildren, useEffect, useState } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import { genderIdField, genderNameField } from '../../../../components/fields/gender';
import { TypeRepresentationEnum } from '../../../../mock-data/type-representation-enum';
import { TypeGenderEnum } from '../../../../mock-data/type-gender-enum';

type RepresentationTypeProps = FieldProps<string> & {
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  parent?: string;
};

export const typeRepresentationId = 'typeRepresentationId';

const RepresentationType = ({
  label = 'Тип представительства',
  defaultValue = 'Не указано',
  name,
  editMode,
  required,
  children,
  parent,
}: RepresentationTypeProps) => {
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);
  const [optionsSet, setOptionsSet] = useState<boolean>(false);
  const fieldId = buildFormFieldName(parent, `${name}Id`);
  const fieldName = buildFormFieldName(parent, `${name}Name`);

  const sexIdField = buildFormFieldName(parent, genderIdField);
  const sexNameField = buildFormFieldName(parent, genderNameField);

  useEffect(() => {
    const fetchOptions = async () => {
      setOptionsData(await dictionariesApi.getTypeRepresentations());
      setOptionsSet(true);
    };

    if (editMode && !optionsSet) {
      fetchOptions();
    }
  }, [editMode, optionsSet]);

  return (
    <FormikField name={fieldId}>
      {({ form }: FormikFieldProps) => {
        const optionValue = form.values[fieldId];
        const optionLabel = form.values[fieldName] ?? optionsData?.find((m) => m.value === optionValue)?.label;

        const setSelectedValue = (option?: SelectOptionType) => {
          if (option?.value) {
            form.setFieldValue(fieldId, option.value);
            form.setFieldValue(fieldName, option.label);
          } else {
            form.setFieldValue(fieldId, null);
            form.setFieldValue(fieldName, null);
          }

          if (option?.value === TypeRepresentationEnum.Mother) {
            form.setFieldValue(sexIdField, TypeGenderEnum.Female);
            form.setFieldValue(sexNameField, 'Женский');
          }

          if (option?.value === TypeRepresentationEnum.Father) {
            form.setFieldValue(sexIdField, TypeGenderEnum.Male);
            form.setFieldValue(sexNameField, 'Мужской');
          }
        };

        return (
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              {label} {editMode && required ? <div className="table-data__required" /> : null}
            </div>
            <div className="table-data__body">
              <div className="table-data-grid-2-1">
                {editMode ? (
                  <FormikSelect
                    name={fieldId}
                    required
                    size="small"
                    withSearch
                    options={optionsData}
                    defaultValue={
                      optionValue
                        ? {
                            value: optionValue,
                            label: optionLabel,
                          }
                        : null
                    }
                    placeholder="Выберите..."
                    selectedValue={(option?: SelectOptionType) => {
                      setSelectedValue(option);
                    }}
                  />
                ) : (
                  optionLabel || defaultValue
                )}

                <div>{children}</div>
              </div>
            </div>
          </div>
        );
      }}
    </FormikField>
  );
};

export default RepresentationType;
