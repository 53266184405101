import React, { useContext, useState } from 'react';
import { FieldArray, useField } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmListItem } from '@mes-ui/lemma';
import DropDown from '../../../../components/drop-down';
import TableOptions from '../../../../components/table-options';
import AddModule from '../../components/modals/add-module';
import { ProgrammModule } from '../../../../types/entities';
import { CreateServiceContext } from '../../create';
import SimpleTable from '../../../../components/table/simple-table';

const Module: React.FC = () => {
  const { serviceData } = useContext(CreateServiceContext);

  const [showModal, setShowModal] = useState(false);
  const [editableItem, setEditableItem] = useState<ProgrammModule | undefined>();

  const [programmModule] = useField<ProgrammModule[]>('programmModule');

  return (
    <FieldArray
      name="programmModule"
      render={(arrayHelpers) => {
        const submitForm = async (values: ProgrammModule) => {
          if (editableItem?.count) {
            arrayHelpers.replace(editableItem.count - 1, values);
          } else {
            arrayHelpers.push(values);
          }
          setShowModal(false);
          setEditableItem(undefined);
        };

        return (
          <>
            <Push size={12} />
            <Panel
              title={() => (
                <>
                  Модули <span className="table-data__required" />{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {programmModule.value?.length}
                  </span>
                </>
              )}
              headingControl={() => (
                <button
                  type="button"
                  onClick={() => setShowModal(true)}
                  className="icon-group"
                >
                  <span className="icon-group__icon">
                    <LmIcon
                      icon="filled-edit-plus"
                      size={20}
                      color="var(--LM-blue-200)"
                    />
                  </span>
                  <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
                </button>
              )}
            >
              {programmModule.value?.length ? (
                <SimpleTable
                  data={programmModule.value?.map((item, index) => ({
                    ...item,
                    count: index + 1,
                  }))}
                  columns={[
                    {
                      dataIndex: 'count',
                      title: '№',
                      render: (item: any) => item.count,
                      width: '40px',
                    },
                    {
                      dataIndex: 'name',
                      title: 'Наименование',
                      render: (item: any) => <div className="overflow-wrap-anywhere">{item.name}</div>,
                    },
                    {
                      dataIndex: '',
                      title: '',
                      render: (item: any) => (
                        <DropDown
                          dataTest="moduleOptions"
                          component={() => <TableOptions />}
                        >
                          <>
                            <LmListItem
                              dataTest="editModule"
                              text="Редактировать"
                              icon="outline-edit-edit"
                              iconSize={20}
                              onClick={() => {
                                setShowModal(true);
                                setEditableItem(item);
                              }}
                            />
                            <LmListItem
                              dataTest="deleteModule"
                              text="Удалить"
                              icon="outline-edit-trash-alt"
                              iconSize={20}
                              onClick={() => {
                                arrayHelpers.remove(item.count - 1);
                              }}
                            />
                          </>
                        </DropDown>
                      ),
                      width: '40px',
                    },
                  ]}
                />
              ) : (
                <div className="table-no-data">Нет данных</div>
              )}
            </Panel>

            <AddModule
              show={showModal}
              hideModal={() => {
                setShowModal(false);
                setEditableItem(undefined);
              }}
              module={editableItem}
              onSubmit={submitForm}
              serviceData={serviceData}
            />
          </>
        );
      }}
    />
  );
};

export default Module;
