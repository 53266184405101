import React from 'react';
import { useField } from 'formik';
import { LmToggle } from '@mes-ui/lemma';
import { ToggleInputProps } from '@mes-ui/lemma/components/LmToggle/LmToggle';

export type FormicToggleProps = Omit<ToggleInputProps, 'labelId' | 'onChange' | 'name' | 'checked'> & {
  name: string;
  showErrorImmediately?: boolean;
};

const FormikToggle: React.FC<FormicToggleProps> = ({ name, showErrorImmediately, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const error = showErrorImmediately ? meta.error : meta.touched && meta.error && meta.initialValue !== field.value;

  return (
    <LmToggle
      {...props}
      {...field}
      checked={field.value}
      dataTest={name}
      onChange={(checked) => {
        helpers.setValue(checked);
      }}
      error={!!error}
    />
  );
};

export default FormikToggle;
