import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InteractiveChevronTemplate, LmBadgeStatus, LmButton, LmSubHeader, LmListItem } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistory from '../../../components/change-history';
import { routes } from '../../../config/constants';
import { generateLink, goBack } from '../../../lib/utils';
import Favorite from '../../../components/favorite';
import ServiceChildDetails from './details';
import { ServiceContext } from '..';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { getHeaderLink } from '../utils';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import ToArchiveModal from '../components/modals/to-archive';
import RecoverModal from '../components/modals/recover';
import DropDown from '../../../components/drop-down';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { ArchiveObstacle } from '../../../types/service';
import ErrorArchiveModal from '../components/modals/error-archive';

const ServiceChild = () => {
  const { serviceData, educationTypes, type, accessPanelEdit, accessAddArchive, canRecover, routeBack } =
    useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalErrorArchive, setShowModalErrorArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);
  const [archiveObstacle, setArchiveObstacle] = useState<ArchiveObstacle>();

  const history = useHistory();

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);

  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;

  const openRecoveryModal = () => {
    setShowModalRecover(true);
  };

  const redirectToService = () => {
    history.push(
      generateLink(
        routes.serviceClasses,
        {},
        {
          serviceId: serviceData.id,
          showArchive: isArchive || undefined,
        }
      )
    );
  };

  const addServiceClass = () => {
    history.push(
      generateLink(
        routes.createServiceClass,
        {
          edType: serviceData.educationTypeId,
        },
        {
          serviceId: serviceData.id,
        }
      )
    );
  };

  const handleArchive = async () => {
    const data = await serviceTemplateApi.getArchiveObstacle(serviceData.id);

    if (data.pupilList.length || data.requestList.length) {
      setShowModalErrorArchive(true);
      setArchiveObstacle(data);
    } else {
      setShowModalArchive(true);
    }
  };

  const handlePrint = () => {
    window.open(
      generateLink(routes.printService, {
        id: serviceData.id,
      }),
      '_blank'
    );
  };

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (isArchive && canRecover) {
      buttons.push({
        dataTest: 'restore',
        icon: 'filled-arrows-upload',
        buttonText: 'Восстановить',
        rounded: 'small',
        variant: 'secondary',
        onClick: openRecoveryModal,
      });
    }
    if (isArchive || serviceData.serviceStatusId === ServiceStatusEnum.Draft) {
      buttons.push({
        dataTest: 'goToGroup',
        icon: 'filled-arrows-arrow-right',
        buttonText: 'Перейти к группам обучения',
        rounded: 'small',
        variant: 'secondary',
        onClick: redirectToService,
      });
    }
    if (!isArchive && serviceData.serviceStatusId === ServiceStatusEnum.Signed) {
      buttons.push({
        buttonText: '',
        children: (
          <DropDown
            dataTest="groupActions"
            component={(open) => (
              <LmButton
                dataTest="groupActions"
                type="button"
                variant="secondary"
              >
                Действия с группами обучения
                <InteractiveChevronTemplate open={open} />
              </LmButton>
            )}
          >
            <>
              <LmListItem
                dataTest="redirectToGroup"
                text="Перейти к группам обучения"
                onClick={redirectToService}
              />
              <LmListItem
                dataTest="addGroup"
                text="Добавить группу обучения"
                onClick={addServiceClass}
              />
            </>
          </DropDown>
        ),
      });
    }
    if (!isArchive) {
      if (checkAccessAddArchive) {
        buttons.push({
          dataTest: 'toArchive',
          icon: 'filled-hardware-hard-drive',
          buttonText: 'В архив',
          rounded: 'small',
          variant: 'secondary',
          onClick: handleArchive,
        });
      }
      buttons.push({
        dataTest: 'print',
        icon: 'filled-hardware-printer',
        buttonText: 'Печать',
        rounded: 'small',
        variant: 'secondary',
        onClick: handlePrint,
      });
    }

    return buttons;
  };

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={() => goBack(routeBack)}
        title="Детское объединение"
        dataTest="serviceChildSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Детские объединения',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          {
            label: 'Описание детского объединения',
          },
        ]}
        description={
          <div className="flex items-center justify-end">
            {isArchive ? (
              <LmBadgeStatus
                dataTest="serviceStatus"
                color="grey"
              >
                Архивная
              </LmBadgeStatus>
            ) : (
              <LmBadgeStatus
                dataTest="serviceStatus"
                color={serviceData?.serviceStatusId === ServiceStatusEnum.Signed ? 'green' : 'orange'}
              >
                {serviceData?.serviceStatusId === ServiceStatusEnum.Signed
                  ? 'Опубликовано на Mos.ru'
                  : 'Не опубликовано на Mos.ru'}
              </LmBadgeStatus>
            )}
          </div>
        }
        tabs={[
          {
            title: 'Основные сведения',
            value: 1,
            dataTest: 'basicInfo',
          },
          {
            title: 'История изменений',
            value: 2,
            dataTest: 'changeHistory',
          },
        ]}
        className="content-panel"
        panels={[
          {
            children: <ServiceChildDetails />,
            value: 1,
          },
          {
            children: (
              <ChangeHistory
                name=""
                entityTypes={entityTypesForHistory.services}
                extendEntityGuid={serviceData.extendEntityGuid}
              />
            ),
            value: 2,
            className: 'content-panel content-panel--auto',
            noPadding: true,
          },
        ]}
        buttonsOrAnyActions={getButtons()}
        titleChildren={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      <ToArchiveModal
        onCloseHandler={() => setShowModalArchive(false)}
        show={showModalArchive}
      />
      <RecoverModal
        onCloseHandler={() => setShowModalRecover(false)}
        show={showModalRecover}
      />
      <ErrorArchiveModal
        onCloseHandler={() => setShowModalErrorArchive(false)}
        show={showModalErrorArchive}
        data={archiveObstacle}
      />
    </>
  );
};

export default ServiceChild;
