import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmIcon } from '@mes-ui/lemma';
import { userProfileSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';
import history from '../../history';
import { routes } from '../../config/constants';

const UserDataNotice = () => {
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    if (userProfile) {
      const fields: string[] = [];

      if (!userProfile.fullName?.lastName) {
        fields.push('Фамилия');
      }
      if (!userProfile.fullName?.firstName) {
        fields.push('Имя');
      }
      if (!userProfile.fullName?.middleName && fields.length > 0) {
        fields.push('Отчество (при наличии)');
      }
      if (!userProfile.snils) {
        fields.push('СНИЛС');
      }
      if (fields.length > 0) {
        setMissingFields(fields);
      }
    }
  }, [userProfile]);

  return missingFields.length > 0 ? (
    <>
      <Push size={12} />
      <div className="infobox infobox--warning">
        <div className="infobox__head items-start">
          <div className="infobox__body">
            <div className="flex">
              <LmIcon
                className="flex-none"
                icon="filled-notifications-alert-triangle"
                size={24}
                color="var(--LM-yellow-200)"
              />
              <Push
                size={8}
                orientation="horizontal"
              />
              <div className="line-height-text">
                <span className="font-weight-bold">Отсутствуют данные пользователя. </span>
                Необходимо заполнить следующие поля в личном кабинете:
                <br />
                {`${missingFields.join(', ')}.`}
                <br />
                <br />
                При отсутствии данных ваша учетная запись будет заблокирована в течение 30 дней.
              </div>
            </div>
          </div>
          <div className="infobox__control">
            <LmButton
              dataTest="goToFilling"
              type="button"
              variant="outline"
              size="medium"
              onClick={() => {
                history.push(routes.room);
              }}
            >
              Перейти к заполнению
            </LmButton>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default UserDataNotice;
