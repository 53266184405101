import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { District, GetReport, ReportInformation, ReportProgress } from '../../types/reports';
import { sendReachGoal } from '../metrica';

export const postReports = async (id: number, data: any, inExcel?: boolean): Promise<any> => {
  const excel = inExcel ? `reportId=${id}&inExcel=${inExcel}` : `reportId=${id}`;

  return await fetchRequest.post(`${apiService.report}/Report?${excel}`, data, undefined, 600000);
};
export const postReportProgress = async (id: number, data: any): Promise<ReportProgress> =>
  await fetchRequest.post(`${apiService.report}/Report/ReportProgress?reportId=${id}`, data);

export const getReports = async (data: { reportId: number }): Promise<GetReport> => {
  sendReachGoal(`report-${data.reportId}`);

  return await fetchRequest.get(`${apiService.report}/Report`, data);
};

export const getDistrict = async (): Promise<District[]> =>
  (await fetchRequest.get(`${apiService.data}/Address/TerritoryEntities/0`)).data;

export const getReportList = async (): Promise<ReportInformation[]> =>
  await fetchRequest.get(`${apiService.report}/Report/UserReportList`);

export const getAviableReportList = async (): Promise<ReportInformation[]> =>
  await fetchRequest.get(`${apiService.report}/Report/AvailableReports`);

export const getSavedReport = async (savedReportId: number | string, inExcel: boolean) => {
  const query = `${apiService.report}/Report/SavedReport?savedReportId=${savedReportId}&inExcel=${inExcel}`;

  return await fetchRequest.get(query);
};

export const reportsApi = {
  postReports,
  getReports,
  getDistrict,
  getReportList,
  postReportProgress,
  getSavedReport,
  getAviableReportList,
};
