import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { schoolOrganizationIdField } from './organization';

type ClassParallelLetterProps = Omit<FieldProps<any>, 'name'> & {
  parent?: string;
  classParallelIdKey?: number;
  classLetterIdKey?: number;
  setClassLetterIdKey?: (value: number) => void;
};

export const classParallelIdField = 'classParallelId';
export const classParallelNameField = 'classParallelName';
export const classLetterlIdField = 'classLetterId';
export const classLetterlNameField = 'classLetterName';

const ClassParallelLetter = ({
  editMode,
  parent = '',
  classParallelIdKey,
  classLetterIdKey,
}: ClassParallelLetterProps) => {
  const { setFieldValue } = useFormikContext();

  const classParallelId = parent ? `${parent}.${classParallelIdField}` : classParallelIdField;
  const classParallelName = parent ? `${parent}.${classParallelNameField}` : classParallelNameField;
  const classLetterId = parent ? `${parent}.${classLetterlIdField}` : classLetterlIdField;
  const classLetterName = parent ? `${parent}.${classLetterlNameField}` : classLetterlNameField;

  const [organizationField] = useField(parent ? `${parent}.${schoolOrganizationIdField}` : schoolOrganizationIdField);

  const [classParallelIdOptions] = useField(classParallelId);
  const [classParallelNameOptions] = useField(classParallelName);

  const [classLetterIdOptions] = useField(classLetterId);
  const [classLetterNameOptions] = useField(classLetterName);

  const [classParallelOptionsData, setClassParallelOptionsData] = useState<SelectOptionType[]>([]);
  const [classLetterOptionsData, setClassLetterOptionsData] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      setClassParallelOptionsData(await dictionariesApi.getParallels(organizationField.value));
      setClassLetterOptionsData(await dictionariesApi.getLetters(organizationField.value));
    };

    if (editMode && organizationField.value) {
      fetchOptions();
    }
  }, [editMode, organizationField.value]);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__body">
        <div className="table-data-grid-2">
          <div className="table-data__group">
            <div className="table-data__label table-data__label--main">
              Параллель{editMode ? <span className="table-data__required" /> : null}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <FormikSelect
                  clearable
                  key={classParallelIdKey}
                  name={classParallelId}
                  size="small"
                  placeholder="Выберите..."
                  explainText={!organizationField.value ? 'Сначала выберите организацию' : undefined}
                  withSearch
                  options={classParallelOptionsData}
                  disabled={!organizationField.value}
                  defaultValue={
                    classParallelIdOptions.value
                      ? {
                          value: classParallelIdOptions?.value,
                          label: classParallelNameOptions?.value,
                        }
                      : null
                  }
                  selectedValue={(option?: SelectOptionType) => {
                    if (option) {
                      setFieldValue(classParallelId, option.value);
                      setFieldValue(classParallelName, option.label);
                    } else {
                      setFieldValue(classParallelId, '');
                      setFieldValue(classParallelName, '');
                    }
                  }}
                />
              ) : (
                classParallelNameOptions?.value
              )}
            </div>
          </div>
          <div className="table-data__group">
            <div className="table-data__label">Литера / буква</div>
            <div className="table-data__body">
              <div className="table-data-grid-2">
                {editMode ? (
                  <FormikSelect
                    clearable
                    key={classLetterIdKey}
                    name={classLetterId}
                    size="small"
                    placeholder="Выберите..."
                    explainText={!organizationField.value ? 'Сначала выберите организацию' : undefined}
                    disabled={!organizationField.value}
                    withSearch
                    options={classLetterOptionsData}
                    defaultValue={
                      classLetterIdOptions.value
                        ? {
                            value: classLetterIdOptions.value,
                            label: classLetterNameOptions?.value,
                          }
                        : null
                    }
                    selectedValue={(option?: SelectOptionType) => {
                      if (option) {
                        setFieldValue(classLetterId, option.value);
                        setFieldValue(classLetterName, option.label);
                      } else {
                        setFieldValue(classLetterId, '');
                        setFieldValue(classLetterName, '');
                      }
                    }}
                  />
                ) : (
                  classLetterNameOptions?.value
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassParallelLetter;
