import React, { useContext, useMemo, useState } from 'react';
import { object as objectYup, string as stringYup, array as arrayYup } from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import SavePanel from '../../../../components/save-panel';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ParallelOptionsByResponse } from '../../components/fields/parallel';
import { ServiceContext } from '../../index';
import { ServiceDataInfo } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { convertParallelInResponse, convertParallelInSelect, setGenderStartEndToBack } from '../../utils';
import TemplateInfo from './template-info';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const Info: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, organizationEdit, accessPanelEdit } = useContext(ServiceContext);

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const initData = useMemo(() => {
    if (serviceData?.info) {
      return {
        ...serviceData.info,
        parallelList: convertParallelInSelect(serviceData?.info?.parallelList as ParallelOptionsByResponse[]),
      };
    } else {
      return {} as ServiceDataInfo;
    }
  }, [serviceData?.info]);

  const initialErrors = useInitialErrors(initData, infoValidationSchema());

  const submitForm = async (values: ServiceDataInfo, formikHelpers: FormikHelpers<ServiceDataInfo>) => {
    try {
      if (values?.serviceId) {
        await serviceTemplateApi.updateService(values.serviceId, {
          ...setGenderStartEndToBack(values),
          parallelList: convertParallelInResponse(values.parallelList as SelectOptionType[]),
        });
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        updateService();
      }
    } finally {
      formikHelpers.setSubmitting(false);
      setEditMode(false);
      setEditModeParent && setEditModeParent(null);
    }
  };

  return (
    <Formik
      onSubmit={submitForm}
      enableReinitialize
      initialValues={initData}
      validationSchema={infoValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceDataInfo>) => {
        const { isSubmitting, isValid, resetForm, handleSubmit, submitForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <TemplateInfo
              setEditModeParent={setEditModeParent}
              editMode={editMode}
              setEditMode={setEditMode}
              organizationEdit={organizationEdit}
              isDraft={serviceData.serviceStatusId === ServiceStatusEnum.Draft}
              accessEditable={accessPanelEdit}
            />
            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent && setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Info;

export const infoValidationSchema = () =>
  objectYup().shape({
    organizationId: stringYup().nullable().required('Выберите организацию'),
    name: stringYup().nullable().required('Введите наименование'),
    parallelList: arrayYup()
      .nullable()
      .min(1, 'Выберите параллель обучения')
      .required('Должен состоять из списка параллелей'),
    programmService: stringYup().nullable().required('Введите описание программы'),
  });
