import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import { LmBadgeStatus, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistorySearch from '../../../components/change-history/search';
import ViewContainer from '../../../components/view-container';
import { routes } from '../../../config/constants';
import { useGetFromRoute } from '../../../hooks/get-from-route';
import { initialChangeHistoryFilterFormData } from '../../../mock-data/change-history-filter';
import { SignTypeEnum } from '../../../mock-data/sign-type-enum';
import { HistoryFilterFormData } from '../../../types/history';
import OrganizationDetails from './details';
import OrganizationPhoto from './photo/index';
import ChangeHistory from '../../../components/change-history';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import organizationApi from '../../../lib/api/organization';
import { Organization } from '../../../types/organization';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import SignModal from '../../../components/sign-modal';
import { accessVedomst } from '../../../mock-data/access-enum';
import Favorite from '../../../components/favorite';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import OrganizationServices from './services';
import SingInfo from './sing-info';
import { goBack } from '../../../lib/utils';

const OrganizationInfo = () => {
  const [signModal, showSignModal] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const [departments, setDepartments] = useState<SelectOptionType[]>();
  const [searchData, setSearchData] = useState<HistoryFilterFormData>(initialChangeHistoryFilterFormData);

  const checkSigned =
    !!organization?.organizationSign?.signId ||
    (!!organization?.vedomstvoSign?.signId && organization?.vedomstvoId !== accessVedomst.Dsit);

  const fromRoute = useGetFromRoute();

  const { id } = useParams<Record<string, string | undefined>>();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  let organizationId: number | undefined = Number(id);

  if (id === undefined || Number.isNaN(organizationId)) {
    organizationId = userProfile.organizationId;
  }

  const getOrganization = useCallback(async () => {
    if (organizationId) {
      const departments = await dictionariesApi.getVedomstvo();
      const organization = await organizationApi.getOrganization(organizationId);

      setDepartments(departments);

      setOrganization(organization);
    }
  }, [organizationId]);

  const updateOrganization = useCallback(async (value: Organization) => {
    if (value) {
      setOrganization(value);
    }
  }, []);

  useEffect(() => {
    getOrganization();
  }, [getOrganization]);

  const tabs = [
    {
      title: 'Основные сведения',
      value: 1,
      dataTest: 'basicInfo',
    },
    {
      title: 'Услуги и бюджетные места',
      value: 2,
      dataTest: 'serviceAndBudget',
    },
  ];

  if (organization?.vedomstvoId !== accessVedomst.Dogm) {
    tabs.push({
      title: 'Фотографии организации',
      value: 3,
      dataTest: 'organizationPhoto',
    });
  }
  tabs.push({
    title: 'История изменений',
    value: 4,
    dataTest: 'changeHistory',
  });

  return (
    <>
      {organization && (
        <>
          <LmSubHeader
            sticky
            title={organization.shortName || ''}
            dataTest="organizationSubHeader"
            arrowOnClick={() => goBack(fromRoute || routes.organizations)}
            buttonsOrAnyActions={
              organization.isArchive
                ? ([
                    {
                      children: (
                        <LmBadgeStatus
                          dataTest="organizationArchive"
                          color="grey"
                        >
                          Архивная запись
                        </LmBadgeStatus>
                      ),
                    },
                  ] as ISubHeaderTitleButton[])
                : undefined
            }
            tabs={tabs}
            className="content-panel"
            panels={[
              {
                children:
                  organization && departments ? (
                    <>
                      <SingInfo
                        organization={organization}
                        showSignModal={showSignModal}
                      />
                      <OrganizationDetails
                        updateOrganization={updateOrganization}
                        organization={organization}
                        departments={departments}
                      />
                    </>
                  ) : null,
                value: 1,
              },
              {
                children: organization && (
                  <>
                    <SingInfo
                      organization={organization}
                      showSignModal={showSignModal}
                    />
                    <OrganizationServices
                      organizationId={organization.id}
                      vedomstvoId={organization.vedomstvoId}
                      isArchive={organization.isArchive}
                    />
                  </>
                ),
                value: 2,
              },
              {
                children: (
                  <>
                    <SingInfo
                      organization={organization}
                      showSignModal={showSignModal}
                    />
                    <OrganizationPhoto
                      checkSigned={checkSigned}
                      organizationId={organizationId ?? 0}
                      isArchive={organization?.isArchive ?? false}
                    />
                  </>
                ),
                value: 3,
              },
              {
                children: (
                  <ViewContainer
                    filterComponent={
                      <ChangeHistorySearch
                        searchData={searchData}
                        onChangeSearch={setSearchData}
                        entityTypes={entityTypesForHistory.learners}
                      />
                    }
                  >
                    <SingInfo
                      organization={organization}
                      showSignModal={showSignModal}
                    />
                    <ChangeHistory
                      name="организаций"
                      search={searchData}
                      entityTypes={entityTypesForHistory.organizations}
                      extendEntityGuid={organization?.extendEntityGuid}
                      noFilter
                    />
                  </ViewContainer>
                ),
                value: 4,
                className: 'content-panel content-panel--auto',
                noPadding: true,
              },
            ]}
            routes={[
              {
                label: 'Главная',
                link: routes.main,
              },
              {
                label: 'Организации',
                link: routes.organizations,
              },
              {
                label: 'Сведения об организации',
              },
            ]}
            titleChildren={
              <Favorite
                entityId={organization.id}
                typeEntity="организацию"
                currentEntityType={organization?.shortName || ''}
                entityTypeId={EntityTypeEnum.Organization}
              />
            }
            description=""
          />

          <SignModal
            show={signModal}
            onCloseHandle={() => {
              showSignModal(false);
              getOrganization();
            }}
            ids={[
              {
                id: organizationId || 0,
              },
            ]}
            getDataForSignHandle={async (id) => await organizationApi.getDataForSign(id)}
            setSignedDataHandle={async (id, sign) =>
              await organizationApi.setSignedData(id, SignTypeEnum.Operator, sign)
            }
          />
        </>
      )}
    </>
  );
};

export default OrganizationInfo;
