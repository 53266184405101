import { useEffect, useState } from 'react';
import serviceClassApi, { MAX_LIST_ITEM_COUNT } from '../lib/api/service-class';
import { ScheduleDays, ServiceClassListData } from '../types/service-class';
import { transformServiceGroupList } from '../lib/utils/requests';
import { ServiceClassList } from '../containers/requests/components/fields/service-classes-enrollment';

type Props = {
  serviceId: number;
  trainingGroupId?: number;
};

export const useGetServiceClassList = ({
  serviceId,
  trainingGroupId,
}: Props): { loading: boolean; serviceClassList: ServiceClassList[] } => {
  const [loading, setLoading] = useState(false);

  const [serviceClassList, setServiceClassList] = useState<ServiceClassList[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      try {
        const response = await serviceClassApi.getServiceClassList(
          serviceId,
          trainingGroupId,
          undefined,
          undefined,
          undefined,
          MAX_LIST_ITEM_COUNT
        );

        const listGroup = response?.map((sc: ServiceClassListData<ScheduleDays.ScheduleData>) =>
          transformServiceGroupList(sc)
        );

        setServiceClassList(listGroup);

        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (serviceId) {
      fetch();
    }
  }, [serviceId, trainingGroupId]);

  return {
    loading,
    serviceClassList,
  };
};
