import React, { useContext, useEffect, useState } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInfoBox, LmLoader } from '@mes-ui/lemma';
import { Formik, FormikProps } from 'formik';
import { array as arrayYup, object as objectYup, string as stringYup } from 'yup';
import FormikInput from '../../../../components/formik/formik-input';
import SavePanel from '../../../../components/save-panel';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ServiceContext } from '../../index';
import { StageData } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { MAX_STAGE_NAME_LENGTH } from '../../utils';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  label?: string;
};

const Period: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);

  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [optionsState, setOptionsState] = useState<StageData[]>(serviceData?.stage?.list);

  useEffect(() => {
    setOptionsState(serviceData?.stage?.list);
  }, [serviceData?.stage?.list]);

  const checkEditable =
    !editMode &&
    setEditModeParent &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Arhive) &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Signed) &&
    accessPanelEdit;

  const submitHandler = async (values: StageData[]) => {
    setLoading(true);
    try {
      await serviceTemplateApi.updateStages(serviceData.id, {
        ...serviceData.stage,
        list: values,
      });
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : optionsState ? (
    <>
      <Push size={12} />
      <Formik
        initialValues={optionsState}
        validateOnChange
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, submitForm, isValid, resetForm }: FormikProps<StageData[]>) => (
          <>
            <Panel
              title={() => (
                <>
                  Учебные периоды{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {values?.length}
                  </span>
                </>
              )}
              headingControl={() => {
                return checkEditable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent && setEditModeParent('period');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              {setEditModeParent ? (
                <SimpleTable
                  key={String(editMode) + values.length}
                  data={values}
                  columns={
                    editMode
                      ? [
                          {
                            dataIndex: 'number',
                            title: '№',
                            render: (item: any) => (
                              <div className="table-row-item-height flex items-center">{item.orderNumber}</div>
                            ),
                            width: '40px',
                          },
                          {
                            dataIndex: 'name',
                            title: 'Наименование ',
                            render: (item: any) => {
                              const index = values?.findIndex((entry) => entry.id === item.id);

                              return (
                                <div className="flex">
                                  <div className="flex-auto">
                                    <FormikInput
                                      size="small"
                                      name={`[${index}].name`}
                                      value={values?.[index]?.name}
                                      showErrorImmediately
                                    />
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]
                      : [
                          {
                            dataIndex: 'number',
                            title: '№',
                            render: (item: any) => item.orderNumber,
                            width: '40px',
                          },
                          {
                            dataIndex: 'name',
                            title: 'Наименование',
                            render: (item: any) => <div className="overflow-wrap-anywhere">{item.name}</div>,
                          },
                        ]
                  }
                />
              ) : (
                <div className="container">
                  <LmInfoBox
                    dataTest="periodWarning"
                    className="infobox--full-width"
                    variant="warning"
                    description="Редактирование учебных периодов станет доступны для ввода после первого сохранения."
                    hidenFooter
                  />
                  <Push size={24} />
                </div>
              )}
            </Panel>

            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={async () => {
                  await updateService();
                  resetForm({
                    values: optionsState,
                  });
                  setEditModeParent && setEditModeParent(null);
                  setEditMode(false);
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </>
        )}
      </Formik>
    </>
  ) : null;
};

export default Period;

const validationSchema = arrayYup()
  .nullable()
  .of(
    objectYup().shape({
      name: stringYup()
        .nullable()
        .max(MAX_STAGE_NAME_LENGTH, `Должно содержать не более ${MAX_STAGE_NAME_LENGTH} символов`),
    })
  );
