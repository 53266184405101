import React from 'react';
import Dialog from '../modals/dialog';

type Props = {
  show: boolean;
  onCloseHandle: () => void;
  onRemoveHandler: () => void;
  title: string;
  description: string | JSX.Element;
  deleteBtnTitle?: string;
};

const RemoveModal = ({ show, onCloseHandle, onRemoveHandler, title, description, deleteBtnTitle }: Props) => {
  const deleteTitle = deleteBtnTitle || 'Удалить';

  return (
    <Dialog
      dataTest="removeDialog"
      isOpenDialog={show}
      title={title}
      description={description}
      variant="alert"
      buttonPrimaryText={deleteTitle}
      onClickSeconadaryButton={onCloseHandle}
      onClickPrimaryButton={onRemoveHandler}
    />
  );
};

export default RemoveModal;
