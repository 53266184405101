import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmLoader } from '@mes-ui/lemma';
import SavePanel from '../../../components/save-panel';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FIO from '../components/fields/fio';
import BirthDate from '../components/fields/birthdate';
import ContingentSearch from '../components/fields/contingent-search';
import Gender from '../../../components/fields/gender';
import Document from '../components/fields/document';
import Issued from '../components/fields/issued';
import IssuedPlace from '../components/fields/issued-place';
import Snils from '../components/fields/snils';
import MoscowRegistration from '../components/fields/moscow-registration';
import DocumentNumber from '../components/fields/document-number';
import { PersonRequestData } from '../../../types/requests';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import { submitPersonRequestData } from '../utils';
import { childrenValidationSchema } from './create';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { DocumentTypeEnum } from '../../../mock-data/type-document';
import { ChildrenSearchResult } from '../../../types/contingent';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import RegistryOffice from '../components/fields/registry-office';

type ChildFormProps = {
  data: PersonRequestData;
  setEditModeParent: (value: string | null) => void;
  contingentLinkTypeId: ContingentLinkTypeEnum;
  updateRequest: () => void;
  requestErrorMessage?: string;
  showEditBtn?: (type: string) => boolean;
  changeStatusRequest?: () => void;
  dayCareRegisterRequestMessage?: string;
};

const ChildForm: React.FC<ChildFormProps> = ({
  setEditModeParent,
  data,
  contingentLinkTypeId,
  updateRequest,
  requestErrorMessage,
  showEditBtn,
  changeStatusRequest,
  dayCareRegisterRequestMessage,
}: ChildFormProps) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(data, childrenValidationSchema);
  const [loading, setLoading] = useState(false);

  const [childrenList, setChildrenList] = useState<ChildrenSearchResult[] | null>(null);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      await submitPersonRequestData(values, true, setLoading, setEditMode, setEditModeParent, updateRequest);

      // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
      changeStatusRequest && changeStatusRequest();
      updateRequest();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditModeParent, updateRequest]
  );

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={childrenValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={data}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, submitForm, isSubmitting, resetForm, isValid, values } = formikProps;
        const isBirthRecord = values.documentTypeId === DocumentTypeEnum.BirthRecord;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о ребенке'}
              headingControl={() => {
                return !editMode && showEditBtn && showEditBtn('child') ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('child');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <FIO
                    label="ФИО учащегося"
                    contingent={contingentLinkTypeId}
                    required
                    editMode={editMode}
                    requestErrorMessage={requestErrorMessage}
                    dayCareRegisterRequestMessage={dayCareRegisterRequestMessage}
                  />

                  <BirthDate
                    name="dateOfBirth"
                    searchButton
                    required
                    editMode={editMode}
                    setChildrenList={setChildrenList}
                  />

                  <ContingentSearch
                    editMode={editMode}
                    setChildrenList={setChildrenList}
                    childrenList={childrenList}
                    hasSearchOrganization
                  />

                  <Gender
                    editMode={editMode}
                    required
                    name="sex"
                  />

                  <Document
                    name="documentType"
                    required
                    editMode={editMode}
                    dependentFields
                    formType={FormTypeEnum.EditRequestDayCare}
                  />
                  <DocumentNumber
                    editMode={editMode}
                    required
                    showErrorImmediately
                    calendarPosition="top-end"
                  />
                  {isBirthRecord ? (
                    <RegistryOffice
                      name="issued"
                      codeName="issuerCode"
                      required
                      editMode={editMode}
                    />
                  ) : (
                    <Issued
                      name="issued"
                      editMode={editMode}
                      required={
                        formikProps.values.documentTypeId === DocumentTypeEnum.Passport ||
                        formikProps.values.documentTypeId === DocumentTypeEnum.BirthCertificate ||
                        formikProps.values.documentTypeId === DocumentTypeEnum.BirthCertificateForeign
                      }
                    />
                  )}

                  <IssuedPlace
                    name="typeIssuePlace"
                    editMode={editMode}
                    required={
                      formikProps.values.documentTypeId === DocumentTypeEnum.Passport ||
                      formikProps.values.documentTypeId === DocumentTypeEnum.BirthCertificate
                    }
                    vedomstvoId={userProfile.vedomstvoId}
                  />

                  <Snils
                    name="snils"
                    editMode={editMode}
                    disabled
                  />

                  <MoscowRegistration
                    name="moscowRegistration"
                    editMode={editMode}
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ChildForm;
