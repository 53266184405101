import React from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import { ServiceDataInfo } from '../../../../types/service';

type Props = { editMode: boolean; parent?: string };

export const ServiceDuration: React.FC<Props> = ({ editMode, parent }) => {
  const { values } = useFormikContext<ServiceDataInfo>();
  const disabled = values.hasRequests || (values.hasMegaRelations && !values.hasAllMegaRelationsExcludedOrChanged);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Продолжительность услуги {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="table-data-grid-4 items-start">
            <div className="table-data__group">
              <div className="table-data__label">Лет</div>
              <div className="table-data__body">
                <FormikInputNumber
                  placeholder="0"
                  name={parent ? `${parent}.durationOfTraining` : 'durationOfTraining'}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="table-data__group">
              <div className="table-data__label">Месяцев</div>
              <div className="table-data__body">
                <FormikInputNumber
                  name={parent ? `${parent}.durationOfTrainingMonths` : 'durationOfTrainingMonths'}
                  placeholder="0"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="table-data__group">
              <div className="table-data__label">Недель</div>
              <div className="table-data__body">
                <FormikInputNumber
                  name={parent ? `${parent}.durationOfTrainingWeeks` : 'durationOfTrainingWeeks'}
                  placeholder="0"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="table-data__group">
              <div className="table-data__label">Дней</div>
              <div className="table-data__body">
                <FormikInputNumber
                  name={parent ? `${parent}.durationOfTrainingDays` : 'durationOfTrainingDays'}
                  placeholder="0"
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            {!!values.durationOfTraining && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Лет</div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 200,
                    }}
                  >
                    {values.durationOfTraining}
                  </div>
                </div>

                <Push
                  size={16}
                  orientation="horizontal"
                />
              </>
            )}
            {!!values.durationOfTrainingMonths && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Месяцев</div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 200,
                    }}
                  >
                    {values.durationOfTrainingMonths}
                  </div>
                </div>
                <Push
                  size={16}
                  orientation="horizontal"
                />
              </>
            )}
            {!!values.durationOfTrainingWeeks && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Недель</div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 200,
                    }}
                  >
                    {values.durationOfTrainingWeeks}
                  </div>
                </div>
                <Push
                  size={16}
                  orientation="horizontal"
                />
              </>
            )}
            {!!values.durationOfTrainingDays && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Дней</div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 200,
                    }}
                  >
                    {values.durationOfTrainingDays}
                  </div>
                </div>
                <Push
                  size={16}
                  orientation="horizontal"
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
