import React from 'react';
import { useField } from 'formik';
import { LmInputNumber, LmInputNumberProps } from '@mes-ui/lemma';
import { allowOnlyNumbersNoSpace } from '../../lib/utils/validation';

export type FormicInputProps = Omit<LmInputNumberProps, 'onChange' | 'size' | 'value' | 'variant'> & {
  variant?: 'horizontal' | 'vertical';
  value?: string | number;
  labelId?: string;
  showErrorImmediately?: boolean;
  excludeZero?: boolean;
};

const INPUT = 'INPUT';
const defaultVariant = 'vertical';

const FormikInputNumber: React.FC<FormicInputProps> = ({
  showErrorImmediately,
  value: valueProp,
  labelId,
  excludeZero,
  dataTest,
  ...props
}) => {
  const { name, errorMessage } = props;
  const [field, meta, helper] = useField(name);

  const value = valueProp || field.value || '';

  const error = showErrorImmediately ? meta.error : meta.touched && meta.error;
  const errorText = errorMessage || (error && (meta.error as string)) || '';

  const handleSetValue = (value: string | number | null) => {
    if (!Number.isNaN(Number(value))) {
      helper.setTouched(true, true);
      helper.setValue(value);
    }
  };

  const handleChange = (value: string | number, name?: string | null, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(Number(value))) {
      if (event?.target.nodeName === INPUT) {
        field.onChange(event);
      }
    }

    handleSetValue(excludeZero && `${value}` === '0' ? '' : value);
  };

  return (
    <LmInputNumber
      {...field}
      {...props}
      dataTest={dataTest || props.name}
      id={props.id || labelId}
      value={value}
      variant={props.variant || defaultVariant}
      min={props.min || 0}
      isError={props.isError || !!errorText}
      errorMessage={errorText}
      onChange={handleChange}
      onKeyPress={(e) => allowOnlyNumbersNoSpace(e, true)}
    />
  );
};

export default FormikInputNumber;
