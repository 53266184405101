import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LmButton, LmInput, LmIcon, LmLoader } from '@mes-ui/lemma';
import { Panel, Push } from '@mosru/esz_uikit';
import SavePanel from '../../../../components/save-panel';
import { getRandomInt } from '../../../../lib/utils';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceContext } from '../../index';
import { ServiceData, StageData, StageList } from '../../../../types/service';
import { MAX_STAGE_NAME_LENGTH } from '../../utils';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  serviceData: ServiceData;
};

const Stage: React.FC<Props> = ({ setEditModeParent, setFieldValue, serviceData }) => {
  const { updateService, accessPanelEdit } = useContext(ServiceContext);
  const [optionsState, setOptionsState] = useState(serviceData?.stage?.list ?? []);
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [loading, setLoading] = useState(false);

  const checkEditable =
    !editMode && setEditModeParent && serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const getListRequest = (list: StageData[]) =>
    list.map(({ isNew, ...item }) =>
      isNew
        ? {
            ...item,
            id: 0,
          }
        : item
    );

  const submitHandler = async () => {
    setLoading(true);
    try {
      await serviceTemplateApi.updateStages(serviceData.id, {
        ...serviceData.stage,
        list: getListRequest(optionsState),
      });
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue('stage', {
        educationTypeId: serviceData?.info?.educationTypeId,
        serviceId: serviceData?.info?.serviceId,
        list: getListRequest(optionsState),
      } as StageList);
    }
  }, [setFieldValue, optionsState, serviceData?.info?.educationTypeId, serviceData?.info?.serviceId]);

  const columns = useMemo(
    () =>
      editMode
        ? [
            {
              dataIndex: 'orderNumber',
              title: '№',
              render: (item: any) => {
                return <div className="table-row-item-height flex items-center">{item.orderNumber}</div>;
              },
              width: '40px',
            },
            {
              dataIndex: 'name',
              title: 'Наименование ',
              render: (item: any) => {
                const error =
                  item.name.length > MAX_STAGE_NAME_LENGTH
                    ? `Должно содержать не более ${MAX_STAGE_NAME_LENGTH} символов`
                    : undefined;

                const handleRemove = () => {
                  const filteredOptionsState = optionsState.filter((entry) => entry.orderNumber !== item.orderNumber);
                  const newOptionsState = filteredOptionsState.map((entry) => {
                    if (entry.orderNumber > item.orderNumber) {
                      return {
                        ...entry,
                        orderNumber: entry.orderNumber - 1,
                      };
                    } else {
                      return entry;
                    }
                  });

                  setOptionsState(newOptionsState);
                };

                const handleUp = () => {
                  let temporaryName = '';
                  let temporaryId: number | undefined = 0;
                  let temporaryIsNew: boolean | undefined;
                  const newOptionsState = optionsState.map((entry) => {
                    if (entry.orderNumber === item.orderNumber - 1) {
                      temporaryName = entry.name;
                      temporaryId = entry.id;
                      temporaryIsNew = entry.isNew;

                      return {
                        ...entry,
                        name: item.name,
                        id: item.id,
                        isNew: item.isNew,
                      };
                    } else if (entry.orderNumber === item.orderNumber) {
                      return {
                        ...entry,
                        name: temporaryName,
                        id: temporaryId,
                        isNew: temporaryIsNew,
                      };
                    } else {
                      return entry;
                    }
                  });

                  setOptionsState(newOptionsState);
                };

                const handleDown = () => {
                  const newOptionsState = optionsState.map((entry) => {
                    if (entry.orderNumber === item.orderNumber) {
                      const newEntry = optionsState.find((findEntry) => findEntry.orderNumber === item.orderNumber + 1);

                      return {
                        ...entry,
                        name: newEntry?.name,
                        id: newEntry?.id,
                        isNew: newEntry?.isNew,
                      };
                    } else if (entry.orderNumber === item.orderNumber + 1) {
                      return {
                        ...entry,
                        name: item.name,
                        id: item.id,
                        isNew: item.isNew,
                      };
                    } else {
                      return entry;
                    }
                  });

                  setOptionsState(newOptionsState);
                };

                return (
                  <div className="flex items-start">
                    <div className="flex-auto">
                      <LmInput
                        dataTest="name"
                        name="name"
                        value={item.name}
                        resettable
                        isError={!!error}
                        explainText={error}
                        onChange={(value) => {
                          const newOptionsState = optionsState.map((entry) =>
                            entry.orderNumber === item.orderNumber
                              ? {
                                  ...entry,
                                  name: value as string,
                                }
                              : entry
                          );

                          setOptionsState(newOptionsState);
                        }}
                      />
                    </div>
                    {optionsState.length > 1 && (
                      <>
                        <Push
                          size={8}
                          orientation="horizontal"
                        />
                        <LmButton
                          dataTest="deleteStage"
                          type="button"
                          variant="secondary"
                          icon="filled-edit-trash-alt"
                          iconSize={20}
                          onClick={handleRemove}
                        />
                        <Push
                          size={8}
                          orientation="horizontal"
                        />
                        <LmButton
                          dataTest="upStage"
                          type="button"
                          variant="secondary"
                          icon="filled-arrows-arrow-up"
                          iconSize={20}
                          disabled={!optionsState.find((findEntry) => findEntry.orderNumber === item.orderNumber - 1)}
                          onClick={handleUp}
                        />
                        <Push
                          size={8}
                          orientation="horizontal"
                        />
                        <LmButton
                          dataTest="downStage"
                          type="button"
                          variant="secondary"
                          icon="filled-arrows-arrow-down"
                          iconSize={20}
                          disabled={!optionsState.find((findEntry) => findEntry.orderNumber === item.orderNumber + 1)}
                          onClick={handleDown}
                        />
                      </>
                    )}
                  </div>
                );
              },
            },
          ]
        : [
            {
              dataIndex: 'orderNumber',
              title: '№',
              render: (item: any) => <div className="table-row-item-height flex items-center">{item.orderNumber}</div>,
              width: '40px',
            },
            {
              dataIndex: 'name',
              title: 'Наименование',
              render: (item: any) => (
                <div className="table-row-item-height flex items-center overflow-wrap-anywhere">{item.name}</div>
              ),
            },
          ],
    [editMode, optionsState]
  );

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Этапы обучения
            {editMode && <span className="table-data__required" />}
            <span className="color-gray-dark">
              {' \u00A0'} {optionsState.length || '0'}
            </span>
          </>
        )}
        headingControl={() => {
          return editMode || !setEditModeParent ? (
            <button
              type="button"
              onClick={() => {
                const newOptionsState = [...optionsState];

                newOptionsState.push({
                  orderNumber: (optionsState[optionsState.length - 1]?.orderNumber || 0) + 1,
                  name: '',
                  id: getRandomInt(),
                  isNew: true,
                });
                setOptionsState(newOptionsState);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-plus"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          ) : (
            checkEditable && (
              <button
                type="button"
                onClick={() => {
                  setEditMode(true);
                  setEditModeParent && setEditModeParent('stage');
                }}
                className="icon-group"
              >
                <span className="icon-group__icon">
                  <LmIcon
                    icon="filled-edit-edit"
                    size={20}
                    color="var(--LM-blue-200)"
                  />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
              </button>
            )
          );
        }}
      >
        <SimpleTable
          data={optionsState}
          columns={columns}
        />
      </Panel>

      {editMode && setEditModeParent && !setFieldValue && (
        <SavePanel
          primaryButtonModifiers={{
            disabled: optionsState.some(({ name }) => name.length > MAX_STAGE_NAME_LENGTH),
          }}
          onClickSeconadaryButton={() => {
            setEditModeParent && setEditModeParent(null);
            setEditMode(false);
            setOptionsState(serviceData?.stage?.list ?? []);
          }}
          onClickPrimaryButton={submitHandler}
        />
      )}
    </>
  );
};

export default Stage;
