import { useContext, useState } from 'react';
import Dialog from '../../../../components/modals/dialog';
import { ServiceContext } from '../../index';
import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { serviceTemplateApi } from '../../../../lib/api/service-template';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const RecoverModal = ({ show, onCloseHandler }: Props) => {
  const { updateService, serviceData } = useContext(ServiceContext);
  const [loading, setLoading] = useState<boolean>(false);

  const recoverService = async () => {
    setLoading(true);
    try {
      await serviceTemplateApi.updateServiceStatus({
        serviceId: serviceData.id,
        serviceStatusId: ServiceStatusEnum.Draft,
        educationTypeId: serviceData.educationTypeId,
      });
      await updateService();
      onCloseHandler();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getTitle = () => {
    switch (serviceData.educationTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return 'Восстановление детского объединения из архива';
      case EducationTypeEnum.SportEducation:
      case EducationTypeEnum.ArtHouseEducation:
        return 'Восстановление услуги из архива';
      case EducationTypeEnum.DayCareCentersEducation:
        return 'Восстановление программы по уходу и присмотру за детьми школьного возраста из архива';
      case EducationTypeEnum.VirtualAssistantEducation:
        return 'Восстановление услуги из архива';
      case EducationTypeEnum.ProfessionalEducation:
        return 'Восстановление образовательной программы из архива';
      default:
        return '';
    }
  };

  const getText = () => {
    switch (serviceData.educationTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return 'Внимание! Сведения о детском объединении будут переведены в статус “Не опубликовано на Mos.ru”! Продолжить?';
      case EducationTypeEnum.SportEducation:
      case EducationTypeEnum.ArtHouseEducation:
        return 'Внимание! Сведения об услуге будут переведены в статус “Не опубликовано на  Mos.ru”. Продолжить?';
      case EducationTypeEnum.DayCareCentersEducation:
        return 'Внимание! Сведения о программе по уходу и присмотру за детьми школьного возраста будут переведены в статус “Не опубликовано на  Mos.ru”. Продолжить?';
      case EducationTypeEnum.VirtualAssistantEducation:
        return 'Внимание! Сведения об услуге будут переведены в статус “Не опубликовано на  Mos.ru”. Продолжить?';
      case EducationTypeEnum.ProfessionalEducation:
        return 'Внимание! Сведения об образовательной программе будут переведены в статус “Не опубликовано на  Mos.ru”. Продолжить?';
      default:
        return '';
    }
  };

  return (
    <Dialog
      dataTest="recovery"
      isOpenDialog={show}
      title={getTitle()}
      description={getText()}
      variant="question"
      buttonPrimaryText="Да, продолжить"
      primaryButtonModifiers={{
        loading,
      }}
      secondaryButtonModifiers={{
        disabled: loading,
      }}
      onClickSeconadaryButton={onCloseHandler}
      onClickPrimaryButton={recoverService}
    />
  );
};

export default RecoverModal;
