import React, { useContext } from 'react';
import { LmDatePicker, LmRadio } from '@mes-ui/lemma';
import { FormGroup, Push } from '@mosru/esz_uikit';
import { useFormikContext } from 'formik';
import moment from 'moment';
import FilterContentItem from '../../../../../components/filter/filter-content-item';
import FormikInputNumber from '../../../../../components/formik/formik-input-number';
import FormikSelect from '../../../../../components/formik/formik-select';
import { checkRange } from '../../../../../lib/utils/validation';
import { ReportFilterTypeEnum } from '../../../../../mock-data/report-filter-type-enum';
import { ReportFilterData, TypeSwitch } from '../../../../../types/reports';
import { SavedReportsFilterContext } from '../index';

type OtherItemProps = {
  contingents: TypeSwitch[];
};

const OtherItem = ({ contingents }: OtherItemProps) => {
  const { values, setFieldValue, setValues } = useFormikContext<ReportFilterData>();
  const { getFilterName, getFilterLabel, getFilterData, getFilterAllOption, getFilterReadOnly } =
    useContext(SavedReportsFilterContext);

  return (
    <FilterContentItem
      title="Другое"
      dataTest="other"
    >
      <FormikSelect
        size="small"
        withSearch
        label={getFilterLabel(ReportFilterTypeEnum.ByAgeGroup)}
        name={getFilterName(ReportFilterTypeEnum.ByAgeGroup)}
        options={getFilterData(ReportFilterTypeEnum.ByAgeGroup)}
        defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByAgeGroup)}
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByAgeGroup)}
      />

      <FormikSelect
        size="small"
        withSearch
        label={getFilterLabel(ReportFilterTypeEnum.ByYearOfTraining)}
        name={getFilterName(ReportFilterTypeEnum.ByYearOfTraining)}
        options={getFilterData(ReportFilterTypeEnum.ByYearOfTraining)}
        defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByYearOfTraining)}
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByYearOfTraining)}
      />

      <FormGroup label="Возраст">
        <div className="report__age--wrap">
          <div className="report__age--element">
            <FormikInputNumber
              name={getFilterName(ReportFilterTypeEnum.ByMinAgeGroup)}
              placeholder="0"
              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByMinAgeGroup)}
              onBlur={() => {
                checkRange([1, 120], 'minAge', values, setFieldValue);
              }}
              showErrorImmediately
              excludeZero
            />
          </div>
          <Push
            size={8}
            orientation="horizontal"
          />
          <div className="report__age--element">
            <FormikInputNumber
              name={getFilterName(ReportFilterTypeEnum.ByMaxAgeGroup)}
              placeholder="0"
              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByMaxAgeGroup)}
              onBlur={() => {
                checkRange([1, 120], 'maxAge', values, setFieldValue);
              }}
              showErrorImmediately
              excludeZero
            />
          </div>
        </div>
      </FormGroup>

      <FormGroup label={getFilterLabel(ReportFilterTypeEnum.ByContingentLinkType)}>
        <>
          {contingents.map(({ id, name }) => (
            <LmRadio
              key={name}
              boxSize="large"
              name={`${getFilterName(ReportFilterTypeEnum.ByContingentLinkType)}-${id}`}
              dataTest={`${getFilterName(ReportFilterTypeEnum.ByContingentLinkType)}-${id}`}
              onChange={() => {
                setFieldValue(getFilterName(ReportFilterTypeEnum.ByContingentLinkType), id);
              }}
              checked={values[getFilterName(ReportFilterTypeEnum.ByContingentLinkType)] === id}
            >
              {name}
            </LmRadio>
          ))}
        </>
      </FormGroup>

      <LmDatePicker
        label="Период"
        dataTest="formData"
        name="formData"
        placeholder="ДД.ММ.ГГГГ — ДД.ММ.ГГГГ"
        placement="top-start"
        onChangeDate={(item) => {
          const from = Array.isArray(item) && item[0] ? new Date(moment(item[0]).format('yyyy-MM-DD')) : undefined;
          const to = Array.isArray(item) && item[1] ? new Date(moment(item[1]).format('yyyy-MM-DD')) : undefined;

          setValues((prev: ReportFilterData) => ({
            ...prev,
            [getFilterName(ReportFilterTypeEnum.ByPeriodFrom)]: from?.toISOString(),
            [getFilterName(ReportFilterTypeEnum.ByPeriodTo)]: to?.toISOString(),
          }));
        }}
        isIntervalDate
        readOnly
        inputSize="small"
        selectedDay={
          values[getFilterName(ReportFilterTypeEnum.ByPeriodFrom)]
            ? new Date(values[getFilterName(ReportFilterTypeEnum.ByPeriodFrom)])
            : undefined
        }
        lastSelectDay={
          values[getFilterName(ReportFilterTypeEnum.ByPeriodTo)]
            ? new Date(values[getFilterName(ReportFilterTypeEnum.ByPeriodTo)])
            : undefined
        }
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByPeriodFrom)}
        disablePortal
      />
    </FilterContentItem>
  );
};

export default OtherItem;
