import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LmButton, LmIcon, LmTooltip } from '@mes-ui/lemma';
import { Panel } from '@mosru/esz_uikit';
import { historyState } from '../../../mock-data/history-state';
import { ReactComponent as IconExcel } from '../../../assets/images/teachers/excel.svg';
import { pageSizeOptions } from '../../../mock-data';
import { generateLink, replaceHistoryState, showExcelErrorData } from '../../../lib/utils';
import { maxRegistryRows, routes } from '../../../config/constants';
import { SearchTeachersFormData, Teacher } from '../../../types/teacher';
import { Discipline } from '../../../types/discipline';
import teacherApi from '../../../lib/api/teacher';
import AsyncTable from '../../../components/table/async-table';

type Props = {
  search: SearchTeachersFormData;
};

const TeachersTable = ({ search }: Props) => {
  const [pageSize, setPageSize] = useState<number>(
    window.history.state[historyState.pageSize] || pageSizeOptions[0].value
  );
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);
  const [currentSearch, setCurrentSearch] = useState(search);
  const [tableTotal, setTableTotal] = useState(0);
  const [tableData, setTableData] = useState<Teacher[]>([]);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const data = await teacherApi.getTeachers({
        ...currentSearch,
        organizationId: currentSearch.organizationId || null,
        pageSize,
        pageNumber: pageNum + 1,
      });

      setTableData(
        data.items.map((item, index) => ({
          ...item,
          index: index + pageNum * pageSize + 1,
        }))
      );
      setTableTotal(data.total);
    } finally {
      setLoading(false);
    }
  }, [pageNum, pageSize, currentSearch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setCurrentSearch(search);
    if (initPageNum) {
      setInitPageNum(0);
    } else {
      setPageNum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    replaceHistoryState({
      [historyState.pageSize]: pageSize,
    });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({
      [historyState.pageNumber]: pageNum,
    });
  }, [pageNum]);

  const handleExportFile = async () => {
    setLoadingFile(true);
    try {
      await teacherApi.getTeachersExcel({
        ...currentSearch,
        pageSize,
        pageNumber: pageNum + 1,
      });
    } catch (e) {
      showExcelErrorData();
    } finally {
      setLoadingFile(false);
    }
  };

  return (
    <Panel
      title={() => (
        <>
          Преподаватели{' '}
          <span className="color-gray-dark">
            {' \u00A0'}
            {tableTotal}
          </span>
        </>
      )}
      headingControl={() => (
        <LmButton
          type="button"
          variant="secondary"
          loading={loadingFile}
          onClick={handleExportFile}
        >
          <span className="icon-button-right">
            Экспорт
            <IconExcel />
          </span>
        </LmButton>
      )}
    >
      <AsyncTable
        loading={loading}
        data={tableData}
        pageSize={pageSize}
        setPageSize={setPageSize}
        itemsAll={tableTotal}
        setPageNumber={setPageNum}
        pageNum={pageNum}
        loaderTitle="Загружаем реестр преподавателей"
        loaderDescription="Нужно немного подождать"
        rowLimit={maxRegistryRows}
        columns={[
          {
            dataIndex: 'id',
            title: '№',
            render: (item: any) => item.index,
            width: '70px',
          },
          {
            dataIndex: 'organizationName',
            title: 'Организация',
            render: (item: any) => (
              <div className="flex">
                <div>
                  {item.organizationName.map((name: string, index: number) => (
                    <span
                      className="teachers-link__item"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}`}
                    >
                      {item.organizationName.length === 1 ? name : <>{name};&nbsp;&nbsp;</>}
                    </span>
                  ))}
                </div>
              </div>
            ),
            width: '20%',
          },
          {
            dataIndex: 'fullName',
            title: 'ФИО',
            width: '20%',
            render: (item: any) => (
              <Link
                className="brand-link"
                to={generateLink(routes.teacher, {
                  id: item.id,
                })}
              >
                {item.fullName}
              </Link>
            ),
          },
          {
            dataIndex: 'isFromRegister',
            title: (
              <span
                style={{
                  margin: '0 auto',
                }}
              >
                Реестр
              </span>
            ),
            width: '56px',
            render: (item: any) => (
              <div className="text-center">
                <LmTooltip
                  withArrow
                  placement="top"
                  content={
                    item.isFromRegister
                      ? 'Cведения найдены в Реестре преподавателей'
                      : 'Cведения не найдены в Реестре преподавателей'
                  }
                >
                  {item.isFromRegister ? (
                    <LmIcon
                      icon="outline-edit-checkmark-circle"
                      size={18}
                      color="var(--LM-green-200)"
                      className="table__column_center"
                    />
                  ) : (
                    <LmIcon
                      icon="outline-edit-close-circle"
                      size={18}
                      color="var(--LM-red-200)"
                    />
                  )}
                </LmTooltip>
              </div>
            ),
          },
          {
            dataIndex: 'phone',
            title: 'Телефон',
            render: (item: any) => item.phone || <span className="color-gray-dark">Не указан</span>,
            width: '150px',
          },
          {
            dataIndex: 'isContactPerson',
            title: (
              <span
                style={{
                  margin: '0 auto',
                }}
              >
                Конт. лицо
              </span>
            ),
            render: (item: any) => <div className="text-center">{item.isContactPerson ? 'да' : 'нет'}</div>,
            width: '100px',
          },
          {
            dataIndex: 'discipline',
            title: 'Дисциплины',
            width: '35%',
            render: (item: any) => (
              <div className="flex">
                <div>
                  {item.discipline.length ? (
                    item.discipline.map((discipline: Discipline, index: number) => (
                      <span
                        className="teachers-link__item"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      >
                        {item.discipline.length === 1 ? discipline : <>{discipline};&nbsp;&nbsp;</>}
                      </span>
                    ))
                  ) : (
                    <span className="color-gray-dark">Не указан</span>
                  )}
                </div>
                <Link
                  className="teachers-link"
                  to={generateLink(routes.teacher, {
                    id: item.id,
                  })}
                >
                  <LmIcon
                    icon="outline-arrows-material-forward"
                    size={24}
                    color="var(--LM-neutrals-day-600)"
                  />
                </Link>
              </div>
            ),
          },
        ]}
      />
    </Panel>
  );
};

export default TeachersTable;
