import React from 'react';
import { LmButton, LmCheckbox } from '@mes-ui/lemma';
import { ServiceClassData, ServiceClassSubClass } from '../../../../../types/service-class';
import serviceClassApi from '../../../../../lib/api/service-class';

type Props = {
  id: number;
  name: string;
  updateTable: () => void;
  selectedPeople: number[];
  serviceClassData: ServiceClassData;
  listSubClass: ServiceClassSubClass[];
  setLoading: (value: boolean) => void;
  hasSelectedLearnersInSubclass: boolean;
  isAllSelectedLearnersInSubclass: boolean;
  setListSubClass: (value: ServiceClassSubClass[]) => void;
};

export const SubClassItem: React.FC<Props> = ({
  selectedPeople,
  id,
  name,
  setLoading,
  listSubClass,
  serviceClassData,
  setListSubClass,
  updateTable,
  hasSelectedLearnersInSubclass,
  isAllSelectedLearnersInSubclass,
}) => {
  const handlerDeleteSubClass = async () => {
    setLoading(true);
    try {
      if (serviceClassData?.teacher?.serviceClassId) {
        const elements = listSubClass.filter((item) => item.id !== id);

        const list = listSubClass.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              hasSelectedLearnersInSubclass: false,
            };
          } else {
            return item;
          }
        });

        const subClassList = list.map((item) => {
          const option = {
            isEnabled: item.hasSelectedLearnersInSubclass,
            subclassId: item.id,
          };

          if (item.id === id) {
            return {
              ...option,
              isSubclassDeleted: true,
            };
          } else {
            return option;
          }
        });

        setListSubClass(elements);

        await serviceClassApi.updateSubClassList(serviceClassData?.teacher?.serviceClassId, {
          megaRelationIds: selectedPeople,
          serviceClassId: serviceClassData.id,
          educationTypeId: serviceClassData.educationTypeId,
          newSubclassName: '',
          subclassList: subClassList,
        });
        updateTable();
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const onSelectedCheckboxHandler = (id: number) => {
    // @ts-ignore
    setListSubClass((prevState: ServiceClassSubClass[]) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            hasSelectedLearnersInSubclass: !item.hasSelectedLearnersInSubclass,
            isAllSelectedLearnersInSubclass: !item.hasSelectedLearnersInSubclass,
          };
        }

        return {
          ...item,
        };
      });
    });
  };

  return (
    <div
      key={id}
      className="flex justify-between items-center"
    >
      <LmCheckbox
        name={`${id}`}
        dataTest={`${id}`}
        indeterminate={selectedPeople.length > 1 && hasSelectedLearnersInSubclass && !isAllSelectedLearnersInSubclass}
        checked={hasSelectedLearnersInSubclass}
        onChange={() => onSelectedCheckboxHandler(id)}
      >
        {name}
      </LmCheckbox>

      <LmButton
        dataTest="deleteSubClass"
        type="button"
        variant="secondary"
        size="medium"
        icon="outline-edit-trash-alt"
        iconSize={20}
        onClick={handlerDeleteSubClass}
      />
    </div>
  );
};
