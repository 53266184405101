import React from 'react';
import { TableDataType } from '@mosru/esz_uikit';
import { BackgroundReportHeader, TypeSwitch } from '../../types/reports';

export const errorNoData = 'Ошибка формирования отчета: ';

export const fillTypeSwitch = (values: TypeSwitch[]) => {
  const finance = values.map((item: TypeSwitch) => {
    return {
      ...item,
      name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
    };
  });
  const all = {
    id: 0,
    name: 'Все',
    selected: true,
  };

  return [all, ...finance];
};

export const staticNameSelect = {
  sity: 'По городу',
  forAll: 'По всем',
  all: 'Все',
};

export const parameterFieldFilter = {
  typeFinansingId: 'typeFinansingId',
};

export const transformResultDataTable = (result: any) => {
  const data = [];
  let allItems: TableDataType[] = [];

  for (let i = 0; i < result.length; i++) {
    const dataTwo = [];

    for (let k = 0; k < result[i].cells.length; k++) {
      const names = `name${k}`;

      dataTwo.push({
        [names]: result[i].cells[k].value,
      });
    }
    data.push(dataTwo);
  }

  let listArray: string | any[] = [];

  for (let i = 0; i < data.length; i++) {
    const resultArray = [];

    for (let j = 0; j < data[i].length; j++) {
      const names = `name${j}`;

      resultArray.push(data[i][j][names]);
    }
    listArray = [...listArray, resultArray];
  }

  for (let i = 0; i < listArray.length; i++) {
    let merge = {};

    for (let j = 0; j < result[i].cells.length; j++) {
      const names = `name${j}`;
      const element = {
        [names]: listArray[i][j],
      };

      merge = {
        ...merge,
        ...element,
      };
    }

    if (result[i].hiddenData) {
      for (let j = 0; j < result[i].hiddenData.length; j++) {
        merge = {
          ...merge,
          ...{
            [result[i].hiddenData[j].key]: result[i].hiddenData[j].value,
          },
        };
      }
    }

    allItems.push(merge);
  }

  const total: TableDataType[] = [];
  const SUMMARY_LINE_NAME = 'итого';
  const element: TableDataType = allItems[0];

  const totalElement =
    element &&
    Object.values(element).find((name) => typeof name === 'string' && name.toLowerCase().startsWith(SUMMARY_LINE_NAME));

  if (totalElement) {
    total.push(element);
    allItems = allItems.slice(1);
  }

  return {
    data: allItems,
    total,
  };
};

const buildHeaderItem = (num: number, headerItem: BackgroundReportHeader, children: [] | undefined) => {
  return {
    dataIndex: children === undefined ? `name${num.toString()}` : undefined,
    render:
      children === undefined
        ? (item: any) =>
            headerItem.isHtml ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: item[`name${num.toString()}`],
                }}
              />
            ) : (
              item[`name${num.toString()}`]
            )
        : undefined,
    title: headerItem.title,
    width: headerItem.width ? `${headerItem.width}px` : undefined,
    children,
    hiddenSort: !headerItem.sortBy,
  };
};

const buildChildrenHeader = (
  level: number,
  parent: number | undefined,
  next: number | undefined,
  header: BackgroundReportHeader[][],
  counter: { value: number }
): any | undefined => {
  if (level >= header.length) {
    return undefined;
  }
  const reportHeader = [];

  for (let j = 0; j < header[level].length; j++) {
    if (parent && header[level][j].column < parent) {
      continue;
    }
    if (next && header[level][j].column >= next) {
      continue;
    }
    const p = header[level][j].column;
    const n = j === header[level].length - 1 ? undefined : header[level][j + 1].column;
    const childs = buildChildrenHeader(level + 1, p, n, header, counter);

    reportHeader.push(buildHeaderItem(counter.value, header[level][j], childs));
    if (childs === undefined) {
      counter.value++;
    }
  }

  return reportHeader.length === 0 ? undefined : reportHeader;
};

export const buildReportHeader = (header: BackgroundReportHeader[][]) =>
  buildChildrenHeader(0, undefined, undefined, header, {
    value: 0,
  });

export const removeEmptyDataForRequest = (requestData: { [key: string]: any }) => {
  for (const key in requestData) {
    if (
      requestData[key] === null ||
      requestData[key] === undefined ||
      requestData[key] === '' ||
      //  requestData[key] === 0 ||
      requestData[key] === staticNameSelect.all ||
      requestData[key] === staticNameSelect.sity ||
      requestData[key] === staticNameSelect.forAll ||
      key === 'excel'
    ) {
      delete requestData[key];
    }
  }

  return requestData;
};

export const blobToBase64 = async (blob: Blob) => {
  return await new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
export const getFileNameFromDisposition = (disposition: string): string => {
  try {
    const fileName = disposition.split("UTF-8''")[1];

    return fileName
      ? decodeURIComponent(fileName)
      : disposition.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
  } catch {
    return 'UnknowFileName';
  }
};
