export type ReactFilesFile = File & {
  id: string;
  extension: string;
  sizeReadable: string;
  preview: {
    fullSize?: string;
    type: string;
    url: string;
  };
  name?: string;
};

export type ReactFilesError = {
  code: ErrorCodeEnum;
  message: string;
};

export enum ErrorCodeEnum {
  invalidFileType = 1,
  fileTooLarge = 2,
  fileTooSmall = 3,
  maximumFileCount = 4,
}
