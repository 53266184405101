import React from 'react';
import { useFormikContext } from 'formik';
import Field, { FieldProps } from '../../../../../../components/fields/field';
import SimpleInput from '../../../../../../components/fields/simple-input';
import lookupApi from '../../../../../../lib/api/lookup';
import FormikSelect from '../../../../../../components/formik/formik-select';
import { Learner } from '../../../../../../types/learners';
import { DocumentTypeEnum } from '../../../../../../mock-data/type-document';
import { SelectOptionType } from '../../../../../../types/entities';

type Props = FieldProps<string> & {
  index: number;
  codeName?: string;
};

export const DocIssuer = ({ name, value, editMode, index, codeName }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const isEgrZags = values.pupilDocument[index]?.documentTypeId === DocumentTypeEnum.BirthRecord;
  const label = isEgrZags ? 'Кем внесена запись' : 'Кем выдан документ';

  return isEgrZags ? (
    <Field
      editMode={editMode}
      label={label}
      required={isEgrZags}
      value={value}
    >
      <FormikSelect
        name={name}
        showErrorImmediately
        size="small"
        withSearch
        menuPlacement="top"
        placeholder="Начните вводить..."
        selectedValue={(option?: SelectOptionType) => {
          if (option) {
            const parse = option.additionalPropertiesJson ? JSON.parse(option.additionalPropertiesJson) : undefined;

            setFieldValue(name, option.label);
            codeName && setFieldValue(codeName, parse?.Code);
          }
        }}
        defaultValue={
          value
            ? {
                value: null,
                label: value,
              }
            : null
        }
        loadOptions={async (query) => await lookupApi.getRegistryOffice(query)}
        options={[]}
      />
    </Field>
  ) : (
    <SimpleInput
      name={name}
      label={label}
      value={value}
      editMode={editMode}
      showErrorImmediately
    />
  );
};
