import React, { useCallback, useContext, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, ref } from 'yup';
import { Push } from '@mosru/esz_uikit';
import Popup from '../../../../components/modals/popup';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import serviceClassApi from '../../../../lib/api/service-class';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';

type Props = {
  open: boolean;
  close: () => void;
};

type FormData = {
  dateFrom: Date;
  dateTo: Date;
};

const Change: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, learners, selected, fetchTable, serviceClassData } =
    useContext(ServiceClassTableContext);

  const initialData: FormData = useMemo(() => {
    return {
      dateFrom: new Date(learners[0]?.dateLearnStart),
      dateTo: new Date(learners[0]?.dateLearnEnd),
    };
  }, [learners]);

  const initialErrors = useInitialErrors(initialData, getValidationSchema());

  const submitForm = useCallback(
    async (data: FormData) => {
      if (serviceClassData.id) {
        await serviceClassApi.periodServiceClass(serviceClassData.id, {
          ...data,
          megaRelationIds: selected,
          serviceClassId: serviceClassData.teacher.serviceClassId,
          educationTypeId: serviceClassData.educationTypeId,
        });
        fetchTable();
        setOpenDetails(false);
        setSelected([]);
        close();
      }
    },
    [
      setSelected,
      close,
      setOpenDetails,
      selected,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      serviceClassData.teacher.serviceClassId,
      fetchTable,
    ]
  );

  return (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={initialData}
    >
      {(formikProps: FormikProps<FormData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="changePlannedDates"
              open={open}
              title="Изменение плановых дат обучения"
              buttonPrimaryText="Сохранить изменения"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid,
              }}
              onClearData={resetForm}
              onSubmit={handleSubmit}
              onClose={close}
            >
              <>
                <FormikDatePicker
                  label="Начало обучения"
                  required
                  size="small"
                  name="dateFrom"
                />
                <Push size={16} />
                <FormikDatePicker
                  label="Окончание обучения"
                  required
                  size="small"
                  name="dateTo"
                />
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default Change;

const getValidationSchema = () =>
  objectYup().shape({
    dateFrom: docDate.nullable().required('Выберите дату начала'),
    dateTo: docDate
      .nullable()
      .required('Выберите дату окончания')
      .min(ref('dateFrom'), 'Дата окончания обучения меньше даты начала обучения'),
  });
