import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { Panel } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { ServiceClassContext } from '../service-class';
import { ServiceClassData } from '../../../types/service-class';
import SimpleInput from '../../../components/fields/simple-input';
import PupilInGroupField from '../components/fields/pupil-in-group';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import OrganizationField from '../components/fields/organization';
import TeacherField from '../components/fields/teacher';
import serviceClassApi from '../../../lib/api/service-class';
import { maxCommentLength } from '../../../lib/utils/service-class';
import { serviceClassStatusEnum } from '../../../mock-data/service-class-status';
import SavePanel from '../../../components/save-panel';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const ServiceClassGroup: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceClassData, updateServiceClass, accessEditServiceClass } = useContext(ServiceClassContext);
  const [loading, setLoading] = useState(false);

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const initialErrors = useInitialErrors(serviceClassData, getValidationSchema());

  const submit = async (data: ServiceClassData) => {
    setLoading(true);

    try {
      await serviceClassApi.updateServiceClass(data);
      updateServiceClass();
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submit(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={serviceClassData as ServiceClassData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceClassData>) => {
        const { values, isValid, resetForm, handleSubmit, submitForm } = formikProps;

        const handleCancel = () => {
          setEditModeParent && setEditModeParent(null);
          setEditMode(false);
          resetForm();
        };

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              title={() => <>Сведения о группе</>}
              headingControl={() => {
                return !editMode &&
                  setEditModeParent &&
                  serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                  accessEditServiceClass ? (
                      <button
                        type="button"
                        onClick={() => {
                          setEditModeParent('group');
                          setEditMode(true);
                        }}
                        className="icon-group"
                      >
                        <span className="icon-group__icon">
                          <LmIcon
                            icon="filled-edit-edit"
                            size={20}
                            color="var(--LM-blue-200)"
                          />
                        </span>
                        <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                      </button>
                    ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <OrganizationField
                    required
                    label="Организация"
                    name="organization"
                    editMode={editMode}
                    disabled={Boolean(values?.id)}
                    placeholder="Выберите организацию"
                    educationType={serviceClassData.educationTypeId}
                  />
                  <SimpleInput
                    required
                    name="name"
                    editMode={editMode}
                    label="Наименование группы"
                  />
                  <SimpleInput
                    name="code"
                    required
                    label="Код группы"
                  />
                  {setEditModeParent && (
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Этапы обучения</div>
                      <div className="table-data__body">{values.serviceStages || '—'}</div>
                    </div>
                  )}
                  <TeacherField
                    editMode={editMode}
                    label="Руководитель"
                    name="supervisorTeacher"
                  />
                  <PupilInGroupField
                    name="included"
                    label="Человек в группе"
                    editMode={editMode}
                  />
                  <SimpleTextArea
                    name="description"
                    label="Комментарий"
                    editMode={editMode}
                    placeholder="Введите..."
                    maxLength={maxCommentLength}
                  />
                </div>
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={handleCancel}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceClassGroup;

export const getValidationSchema = () =>
  objectYup().shape({
    organizationId: stringYup().nullable().required('Выберите организацию'),
    name: stringYup().nullable().required('Введите наименование группы').nullable(),
    capacity: numberYup()
      .min(1, 'Предельное число обучающихся в группе не может быть отрицательным числом или нулем')
      .nullable(),
  });
