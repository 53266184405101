import { useField } from 'formik';
import { LmCheckboxProps, LmCheckbox } from '@mes-ui/lemma';

export type FormicCheckboxProps = Omit<LmCheckboxProps, 'name' | 'error'> & {
  name: string;
  label: string;
  showErrorImmediately?: boolean;
};

const FormikCheckbox: React.FC<FormicCheckboxProps> = ({ name, label, showErrorImmediately, checked, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const error = showErrorImmediately ? meta.error : meta.touched && meta.error && meta.initialValue !== field.value;

  return (
    <LmCheckbox
      {...props}
      {...field}
      onChange={(checked, name, event) => {
        helpers.setValue(checked);
        props.onChange && props.onChange(checked, name, event);
      }}
      checked={checked !== undefined ? checked : field.value}
      dataTest={name}
      error={!!error}
    >
      {label}
    </LmCheckbox>
  );
};

export default FormikCheckbox;
