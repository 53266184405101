import React, { createContext } from 'react';
import { number as numberYup } from 'yup';
import { LmBadgeStatus } from '@mes-ui/lemma';
import serviceClassApi from '../../lib/api/service-class';
import { formatDate, getStartDate } from '../../lib/utils/date';
import { docDate } from '../../lib/utils/validation';
import { ContractStatusEnum } from '../../mock-data/contract-status-enum';
import { ServiceClassData, ServiceClassTableContextType, TrainingGroupItemType } from '../../types/service-class';
import { hasAccessObjectAny } from '../../lib/utils';
import { AuthorizationData } from '../../types/authorization-data';
import { accessObject } from '../../mock-data/access-enum';
import { EducationTypeEnum } from '../../types/education-type';

export const getServiceClassData = async (id: string, setServiceClass: (value: ServiceClassData) => void) => {
  if (id) {
    try {
      const response = await serviceClassApi.getServiceClass(id);

      setServiceClass(response);
    } catch {}
  }
};

export const addArchive = async (id: string) => {
  if (id) {
    try {
      await serviceClassApi.addServiceClassToArchive(id);
    } catch {}
  }
};

export const deleteServiceClass = async (id: string, setIsRemovedGroup: (value: boolean) => void): Promise<void> => {
  if (id) {
    setIsRemovedGroup(true);
    try {
      await serviceClassApi.deleteServiceClass(id);
      setIsRemovedGroup(false);
    } catch {
      setIsRemovedGroup(false);
    }
  }
};

export const ServiceClassTableContext = createContext<ServiceClassTableContextType>({} as ServiceClassTableContextType);

export const accessObjectByEducationType: { [key: number]: number } = {
  [EducationTypeEnum.DayCareCentersEducation]: accessObject.ServiceClassDayCare,
  [EducationTypeEnum.ProfessionalEducation]: accessObject.ServiceClassOP,
  [EducationTypeEnum.VirtualAssistantEducation]: accessObject.ServiceClassVA,
  [EducationTypeEnum.ArtHouseEducation]: accessObject.ServiceClassArtHouse,
  [EducationTypeEnum.ChildrenEducation]: accessObject.ServiceClassDOGM,
  [EducationTypeEnum.ChildrenNonDogmEducation]: accessObject.ServiceClassNonDOGM,
  [EducationTypeEnum.SportEducation]: accessObject.ServiceClassSport,
};

export const accessByEducationType = (
  userProfile: AuthorizationData,
  educationType: number,
  accessAction: number
): boolean => {
  return hasAccessObjectAny(userProfile, [accessObjectByEducationType[educationType]], accessAction);
};

const defaultTooltipText =
  'Для выбора доступны детские объединения в статусе "Опубликовано на Mos.ru" с корректно заполненными планами приема. Вы также можете воспользоваться поиском по коду услуги. Код отображается справа от наименования.';

export const tooltipTextForService: { [key: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]:
    'Для выбора доступны программы в статусе "Опубликовано на Mos.ru" с корректно заполненными планами приема',
  [EducationTypeEnum.VirtualAssistantEducation]: defaultTooltipText,
  [EducationTypeEnum.ArtHouseEducation]: defaultTooltipText,
  [EducationTypeEnum.ChildrenEducation]: defaultTooltipText,
  [EducationTypeEnum.SportEducation]: defaultTooltipText,
  [EducationTypeEnum.ChildrenNonDogmEducation]: defaultTooltipText,
  [EducationTypeEnum.ProfessionalEducation]:
    'Для выбора доступны образовательные программы в статусе "Опубликовано на Mos.ru" с корректно заполненными планами приема',
};

export const validationPreparationTrainDate = (currentTrainingGroup: TrainingGroupItemType | null) => ({
  trainStartDate: docDate
    .nullable()
    .required('Выберите дату начала')
    .test('trainStartDate', '', (value: Date | null | undefined, ctx: any) => {
      if (
        currentTrainingGroup?.requestStartDate &&
        value &&
        getStartDate(currentTrainingGroup.requestStartDate) > getStartDate(value)
      ) {
        return ctx.createError({
          message: 'Дата начала занятий в группе не может быть меньше, чем дата начала приема заявлений',
        });
      }

      if (value && ctx.parent.trainEndDate && value > ctx.parent.trainEndDate) {
        return ctx.createError({
          message: 'Дата начала обучения больше даты окончания обучения',
        });
      }

      return true;
    }),
  trainEndDate: docDate
    .nullable()
    .required('Выберите дату окончания')
    .test('trainEndDate', '', (value: Date | null | undefined, ctx: any) => {
      if (
        currentTrainingGroup?.educationDateEnd &&
        value &&
        getStartDate(currentTrainingGroup.educationDateEnd) < getStartDate(value)
      ) {
        return ctx.createError({
          message: `Дата окончания занятий в группе не может быть больше даты ограничения продолжительности программы - ${formatDate(
            currentTrainingGroup.educationDateEnd
          )}`,
        });
      }

      if (value && ctx.parent.trainStartDate && value < ctx.parent.trainStartDate) {
        return ctx.createError({
          message: 'Дата окончания обучения меньше даты начала обучения',
        });
      }

      return true;
    }),
});

export const capacityOPValidation = (
  currentTrainingGroup: TrainingGroupItemType | null,
  initialCapacity?: number | null,
  included?: number | null
) => {
  const DEFAULT_MAX = 30;
  const DEFAULT_MIN = 1;

  const maxVolume = currentTrainingGroup?.volume
    ? currentTrainingGroup.volume - currentTrainingGroup.groupVolume + (initialCapacity || 0)
    : undefined;
  const max = maxVolume ? Math.min(DEFAULT_MAX, maxVolume) : DEFAULT_MAX;
  const min = included ? Math.max(DEFAULT_MIN, included) : DEFAULT_MIN;

  const defaultError = `Введите число от ${min} до ${max}`;
  const maxError =
    maxVolume === max
      ? `Предельное число обучающихся превышает количество мест в плане приема. Для группы доступно ${maxVolume} мест`
      : defaultError;

  return numberYup()
    .test((value, ctx) => {
      if (maxVolume !== undefined && maxVolume <= 0) {
        return ctx.createError({
          message: 'Увеличьте количество мест в плане приема',
        });
      }

      return true;
    })
    .required(defaultError)
    .min(min, defaultError)
    .max(max, maxError)
    .nullable();
};

export const displayContractStatus = (contractStatusId?: number) => {
  switch (contractStatusId) {
    case ContractStatusEnum.Signed:
      return (
        <LmBadgeStatus
          dataTest="contractStatus"
          color="green"
        >
          подписан
        </LmBadgeStatus>
      );
    case ContractStatusEnum.Canceled:
      return (
        <LmBadgeStatus
          dataTest="contractStatus"
          color="orange"
        >
          аннулирован
        </LmBadgeStatus>
      );
    case ContractStatusEnum.Terminated:
      return (
        <LmBadgeStatus
          dataTest="contractStatus"
          color="red"
        >
          расторгнут
        </LmBadgeStatus>
      );
    default:
      return <span className="color-gray-dark">Нет информации</span>;
  }
};
