import React from 'react';
import { ModalPanel, Modal } from '@mosru/esz_uikit';
import Dialog from '../../../components/modals/dialog';
import { getEmptyFunctionToOverride } from '../../../lib/utils';

type ModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  handleSubmit: () => void;
};

export const RefuseModal = ({ showModal, setShowModal, handleSubmit }: ModalProps) => (
  <Dialog
    dataTest="refusalDialog"
    isOpenDialog={showModal}
    title="Подтверждение"
    description="Статус заявления будет изменен на “Отказ в зачислении”. Продолжить?"
    variant="question"
    buttonPrimaryText="Продолжить"
    onClickPrimaryButton={handleSubmit}
    onClickSeconadaryButton={() => setShowModal(false)}
  />
);

export const EnrollmentModal = ({ showModal, setShowModal, handleSubmit }: ModalProps) => (
  <Dialog
    dataTest="enrollmentDialog"
    isOpenDialog={showModal}
    title="Подтверждение"
    description="Статус заявления будет изменен на “Зачислен”. Продолжить?"
    variant="question"
    buttonPrimaryText="Продолжить"
    onClickPrimaryButton={handleSubmit}
    onClickSeconadaryButton={() => setShowModal(false)}
  />
);

export const LoadingModal = ({ showModal }: Omit<ModalProps, 'setShowModal' | 'handleSubmit'>) => (
  <Modal
    show={showModal}
    onClose={getEmptyFunctionToOverride()}
  >
    <ModalPanel
      alert
      onClose={getEmptyFunctionToOverride()}
      modalTitle="Идет сохранение!"
      renderComponent={() => (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          Пожалуйста, подождите...
        </div>
      )}
      hideButton
      textAlign="center"
    />
  </Modal>
);
