import { routes } from '../config/constants';

export const pageSizeOptions = [
  {
    label: 'Отображать по 10',
    value: 10,
  },
  {
    label: 'Отображать по 25',
    value: 25,
  },
  {
    label: 'Отображать по 50',
    value: 50,
  },
  {
    label: 'Отображать по 100',
    value: 100,
  },
];

/// Типы объектов ЕСЗ
export const entityTypeEnum = [
  {
    label: 'Все',
    value: '0',
  },
  {
    label: 'Заявление',
    value: '1',
    route: routes.requests,
    isFallbackRoute: true,
  },
  {
    label: 'Услуга',
    value: '2',
    route: routes.services,
    isFallbackRoute: true,
  },
  {
    label: 'Организация',
    value: '3',
    route: routes.organizations,
    isFallbackRoute: false,
  },
  {
    label: 'Группа обучения',
    value: '4',
    route: routes.serviceClasses,
    isFallbackRoute: true,
  },
  {
    label: 'Личное дело',
    value: '5',
    route: routes.learners,
    isFallbackRoute: false,
  },
];
