import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import { editTextInfobox } from '../../lib/utils/requests';
import { TypeRequest } from './components/fields/service-classes-enrollment';

type Props = {
  type?: TypeRequest;
  message: string;
  intersection: boolean;
  loadingCheck: boolean;
  contingentGuid: string;
};

export const Statuses = ({ intersection, contingentGuid, message, loadingCheck, type }: Props) => {
  const showPushNoContingentGuid = type !== 'EditOp';

  return (
    <div className="container">
      {!contingentGuid && (
        <>
          {showPushNoContingentGuid && (
            <Push
              orientation="vertical"
              size={12}
            />
          )}
          <LmInfoBox
            dataTest="notFoundError"
            className="infobox--full-width"
            variant="alert"
            description="Ребенок не найден в Реестре контингента, поиск пересечений по расписанию невозможен."
            hidenFooter
          />
        </>
      )}

      {message && contingentGuid && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <LmInfoBox
            dataTest="intersectionsWarning"
            className="infobox--full-width"
            variant="warning"
            description={
              <span
                dangerouslySetInnerHTML={{
                  __html: editTextInfobox(message),
                }}
              />
            }
            hidenFooter
          />
        </>
      )}

      {!message && loadingCheck && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <LmInfoBox
            dataTest="waitingWarning"
            className="infobox--full-width"
            variant="warning"
            description="Подождите, идет проверка пересечений по расписанию"
            hidenFooter
          />
        </>
      )}

      {!message && contingentGuid && intersection && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <LmInfoBox
            dataTest="intersectionsSuccess"
            className="infobox--full-width"
            variant="success"
            description="Пересечений по расписанию не найдено"
            hidenFooter
          />
        </>
      )}
    </div>
  );
};
