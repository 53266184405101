import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import { Link } from 'react-router-dom';
import { LmInfoBox, LmRadio } from '@mes-ui/lemma';
import { routes } from '../../../../config/constants';
import { ScheduleDays } from '../../../../types/service-class';
import { Statuses } from '../../statuses';
import { checkStatus } from '../../utils';
import { sliceText } from '../../../../lib/utils';
import { LoaderCustom } from '../../../../components/loader-custom';
import SimpleTable from '../../../../components/table/simple-table';
import { useGetServiceClassList } from '../../../../hooks/get-service-class-list';
import { Period } from '../period/Period';

export type ServiceClassList = {
  code: string;
  dates: ScheduleDays.TransformDays[];
  id: number;
  name: string;
  teacher: string;
  volumes: string;
};

export type TypeRequest = 'EditDogm' | 'EditNonDogm' | 'EditVA' | 'EditOp';

type Props = {
  serviceId: number;
  trainingGroupId?: number;
  warningMessage?: string;
  type?: TypeRequest;
  disabledRadio?: boolean;
  contingentGuid: string;
};

const ServiceClassesEnrollment = ({
  serviceId,
  trainingGroupId,
  warningMessage,
  contingentGuid,
  type,
  disabledRadio = false,
}: Props) => {
  const [loadingCheck, setLoadingCheck] = useState(false); // loading проверки расписаний
  const [intersection, setIntersection] = useState(false); // пересечение дат

  const [message, setMessage] = useState(''); // сообщение если найдены пересечения

  const { serviceClassList, loading } = useGetServiceClassList({
    serviceId,
    trainingGroupId,
  });

  const showField = type !== 'EditOp';

  return loading ? (
    <LoaderCustom size={16} />
  ) : serviceClassList?.length > 0 ? (
    <>
      {showField && (
        <Field name="serviceClass.id">
          {(fieldProps: FieldProps) => {
            const { field, form } = fieldProps;

            return (
              <SimpleTable
                data={serviceClassList}
                columns={[
                  {
                    dataIndex: 'id',
                    title: '',
                    render: (item: any) => (
                      <LmRadio
                        dataTest="serviceClass.id"
                        name="serviceClass.id"
                        onChange={() => {
                          !form.touched[field.name] && form.setFieldTouched(field.name, true);
                          form.setFieldValue(field.name, item.id);
                          checkStatus(item.id, contingentGuid, setMessage, setLoadingCheck, setIntersection);
                        }}
                        checked={item.id === field.value}
                        disabled={disabledRadio}
                        resetAllPadding
                      />
                    ),
                    width: '60px',
                  },
                  {
                    dataIndex: 'code',
                    title: 'Код группы',
                    render: (item: any) => (
                      <Link
                        className="brand-link cursor-pointer"
                        to={`${routes.serviceClasses}/${item.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.code}
                      </Link>
                    ),
                    width: '10%',
                  },
                  {
                    dataIndex: 'name',
                    title: 'Наименование группы',
                    render: (item: any) => sliceText(item.name, 80),
                  },
                  {
                    dataIndex: 'teacher',
                    title: 'Преподаватель',
                    render: (item: any) => item.teacher,
                    width: '17%',
                  },
                  {
                    dataIndex: 'dates',
                    title: 'Расписание',
                    render: (item: any) => {
                      return <Period periodsSchedule={item.dates} />;
                    },
                    width: '22%',
                  },
                  {
                    dataIndex: 'volumes',
                    title: 'Зачислено/Всего',
                    render: (item: any) => item.volumes,
                    width: '17%',
                  },
                ]}
              />
            );
          }}
        </Field>
      )}

      <Statuses
        type={type}
        message={message}
        intersection={intersection}
        loadingCheck={loadingCheck}
        contingentGuid={contingentGuid}
      />
    </>
  ) : warningMessage ? (
    <div className="container">
      <LmInfoBox
        dataTest="enrollmentWarning"
        className="infobox--full-width"
        variant="warning"
        description={warningMessage}
        hidenFooter
      />
    </div>
  ) : null;
};

export default ServiceClassesEnrollment;
