import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { RequestData } from '../../../../types/requests';
import Document from './document';
import DocumentNumber from './document-number';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import RegistryOffice from './registry-office';

/**
 * Используется только для нового заявления
 * Этот компонент нужен для того, когда мы выбрали другой Документ. удост. личность показывало сообщение валидации
 *  на Серия, номер, дата выдачи
 * */

type Props = {
  formType?: FormTypeEnum;
  showErrorImmediately?: boolean;
};

const WrapperFieldsDocument: React.FC<Props> = ({ formType, showErrorImmediately }) => {
  const { values, setFieldTouched, initialValues, errors } = useFormikContext<RequestData>();

  const initial = initialValues.child;
  const v = values.child;
  const document = initial.documentTypeId !== v.documentTypeId;

  useEffect(() => {
    if (document) {
      setFieldTouched('child.series', true);
      setFieldTouched('child.number', true);
      setFieldTouched('child.dateOfIssue', true);
      setFieldTouched('child.issued', true);
    }
  }, [document, errors?.child?.series, initial.documentTypeId, setFieldTouched, v.documentTypeId]);

  return (
    <>
      <Document
        dependentFields
        formType={formType}
        name="documentType"
        parent="child"
        editMode
        required
      />
      <DocumentNumber
        parent="child"
        editMode
        required
        calendarPosition="top-end"
        showErrorImmediately={showErrorImmediately}
      />
      {v.documentTypeId === DocumentTypeEnum.BirthRecord ? (
        <RegistryOffice
          name="issued"
          parent="child"
          codeName="issuerCode"
          required
          editMode
        />
      ) : null}
    </>
  );
};

export default WrapperFieldsDocument;
