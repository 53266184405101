export enum ServiceClassStatusEnum {
  // Идет набор.
  Complecting = 1,

  // Идет обучение.
  Learning = 2,

  // Архив.
  Archive = 3,
}
