import React, { useCallback, useContext, useState } from 'react';
import { object as objectYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmLoader } from '@mes-ui/lemma';
import FormikDatePicker from '../../../components/formik/formik-datepicker';
import FormikInput from '../../../components/formik/formik-input';
import { typePersonEnum } from '../../../mock-data/type-person-enum';
import Document from '../components/fields/document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import { RequestData, RequestEditServiceClassData, RequestEnrollmentData } from '../../../types/requests';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import ApplicantLikeModal from '../components/like/modal/applicant-modal';
import ChildLikeModal from '../components/like/modal/child-modal';
import ShortNumberInfobox from '../components/short-number-infobox';
import { submitEnrollment } from '../utils';
import { EnrollmentModal } from '../components/modals';
import { docDate, documentTypeId, enrollDocNumber } from '../../../lib/utils/validation';
import { InfoLike } from '../components/like/info';
import { RequestContext } from '../../../lib/utils/requests';

type Props = {
  enrollment: RequestEnrollmentData;
  requestId: number;
  updateRequest: () => void;
  requestData?: RequestData;
  extendEntityGuid: string;
};

const ActionsFormEnrollment: React.FC<Props> = ({
  enrollment,
  requestId,
  updateRequest,
  requestData,
  extendEntityGuid,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState<typePersonEnum>();

  const { canEditRequestOP } = useContext(RequestContext);

  const submitForm = useCallback(
    async (values: RequestEnrollmentData) => {
      await submitEnrollment(
        {
          ...values,
          serviceClass: {
            id: requestData?.trainingGroup?.serviceClass?.id || 0,
          } as RequestEditServiceClassData,
        },
        requestId,
        setLoading,
        setShowModal,
        undefined,
        updateRequest
      );
    },
    [requestData?.trainingGroup?.serviceClass?.id, requestId, updateRequest]
  );
  const initialErrors = useInitialErrors(enrollment, getValidationSchema());

  const enrollmentDisabled = !requestData?.contingentGuid || !requestData?.applicantContingentGuid;

  const updatedInitialValues = {
    ...enrollment,
    enrollDocumentTypeId: FormTypeEnum.EditRequestEnrollment,
  };

  const handleCloseLikeModal = () => {
    setOpenPopup(undefined);
  };

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={updatedInitialValues}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<RequestEnrollmentData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <>
            <form onSubmit={handleSubmit}>
              <ShortNumberInfobox child={requestData?.child} />

              <InfoLike
                requestData={requestData}
                setOpen={setOpenPopup}
                disabled={!canEditRequestOP}
              />
              <div className="container">
                <div className="table-data">
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Дата зачисления
                      <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-3">
                        <FormikDatePicker
                          placeholder={!enrollmentDisabled ? 'ДД.ММ.ГГГГ' : ''}
                          size="small"
                          name="enrollDate"
                          disabled={enrollmentDisabled}
                        />
                      </div>
                    </div>
                  </div>
                  <Document
                    name="enrollDocumentType"
                    label="Вид документа-основания"
                    editMode
                    formType={FormTypeEnum.EditRequestEnrollment}
                    required
                    size={3}
                    disabled={enrollmentDisabled}
                  />
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Номер и дата документа <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-2">
                        <FormikInput
                          size="small"
                          name="enrollDocNumber"
                          placeholder={!enrollmentDisabled ? 'Введите номер...' : ''}
                          disabled={enrollmentDisabled}
                        />
                        <div>
                          <FormikDatePicker
                            size="small"
                            name="enrollDocDate"
                            placeholder={!enrollmentDisabled ? 'ДД.ММ.ГГГГ' : ''}
                            disabled={enrollmentDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <SimpleTextArea
                    disabled={enrollmentDisabled}
                    name="enrollComment"
                    editMode
                    label="Комментарий"
                    placeholder={!enrollmentDisabled ? 'Введите...' : ''}
                  />
                </div>
              </div>
              <div className="requests-hr" />
              <div className="container">
                <div className="flex justify-end">
                  <LmButton
                    type="button"
                    variant="outline"
                    size="medium"
                    onClick={() => resetForm()}
                  >
                    Сбросить
                  </LmButton>
                  <Push
                    size={12}
                    orientation="horizontal"
                  />
                  <LmButton
                    type="button"
                    size="medium"
                    disabled={!isValid || isSubmitting}
                    onClick={() => setShowModal(true)}
                  >
                    Зачислить
                  </LmButton>
                </div>
                <Push size={16} />
              </div>
              <EnrollmentModal
                showModal={showModal}
                setShowModal={setShowModal}
                handleSubmit={handleSubmit}
              />
            </form>

            <ChildLikeModal
              show={openPopup === typePersonEnum.child}
              updateRequest={updateRequest}
              requestId={requestId}
              extendEntityGuid={extendEntityGuid}
              data={requestData?.child}
              onClose={handleCloseLikeModal}
            />
            <ApplicantLikeModal
              show={openPopup === typePersonEnum.applicant}
              updateRequest={updateRequest}
              requestId={requestId}
              extendEntityGuid={extendEntityGuid}
              data={requestData?.applicant}
              onClose={handleCloseLikeModal}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ActionsFormEnrollment;

const getValidationSchema = () =>
  objectYup().shape({
    enrollDate: docDate.nullable().required('Выберите дату зачисления'),
    enrollDocumentTypeId: documentTypeId,
    enrollDocNumber,
    enrollDocDate: docDate.nullable().required('Выберите дату документа'),
  });
