import { LmSelectNewProps } from '@mes-ui/lemma';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormikHelpers, useFormikContext } from 'formik';
import { SelectOptionType } from '../../../../types/entities';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import FieldAsyncSelect from './async-select';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { setAsyncValues } from '../../../../lib/utils/requests';

type Props = Pick<LmSelectNewProps<SelectOptionType>, 'placeholder' | 'explainText'> & {
  values: SearchRequestsInitialFormData;
  label: string;
  disabled: boolean;
};

const FieldService: React.FC<Props> = ({ values, label, placeholder, explainText, disabled }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { setFieldValue } = useFormikContext<FormikHelpers<SearchRequestsInitialFormData>>();

  const onChange = () => {
    const dataToClearValues = [
      {
        key: 'trainingGroupId',
      },
      {
        key: 'placeServiceId',
      },
    ];

    setAsyncValues(dataToClearValues, setFieldValue);
  };

  // // показывать наименование детского объединения
  const canShowChildrenAssociation = useMemo(() => {
    return (
      hasAccessObjectAny(userProfile, [accessObject.RequestNonDOGM], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestDOGM], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestDayCare], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestOP], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestVA], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestSport], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestArtHouse], accessAction.ViewRegistry)
    );
  }, [userProfile]);

  return canShowChildrenAssociation ? (
    <FieldAsyncSelect
      disabled={disabled}
      onChange={onChange}
      nameFieldId="serviceId"
      nameFieldName="serviceName"
      label={label}
      placeholder={placeholder}
      explainText={explainText}
      showTooltip
      disabledPortalTooltip
      loadOptions={async (query) =>
        await lookupApi.getService(
          query,
          values.educationTypeId,
          values.vedomstvoId,
          values.organizationId,
          undefined,
          false,
          false
        )
      }
    />
  ) : null;
};

export default FieldService;
