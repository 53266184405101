import React from 'react';
import { LmSubHeader } from '@mes-ui/lemma';

const Main = () => {
  return (
    <>
      <LmSubHeader
        sticky
        dataTest="mainSubHeader"
        title="Единый сервис записи"
        routes={[
          {
            label: 'Рабочий стол',
          },
        ]}
        description=""
      />
      <div className="container">Main</div>
    </>
  );
};

export default Main;
