/// <summary> Тип связи с контингентом </summary>
export enum ContingentLinkTypeEnum {
  // Найден
  Found = 1,
  // новое
  New = 2,
  // Не найден
  NotFound = 3,
  // в процесе поиска
  InQueue = 4,
}
