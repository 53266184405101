import React, { Fragment } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmRadio } from '@mes-ui/lemma';
import { checkMatch, editSex, hiddenSymbol, transformDate } from '../../../../../../lib/utils/like-modal';
import { Person, PersonDocument } from '../../../../../../types/like-modal';
import { MatchLevelEnum } from '../../../../../../mock-data/like-modal-enum';
import { checkIfData } from '../../../../../../lib/utils';
import { formatDate } from '../../../../../../lib/utils/date';

type Props = {
  person: Person;
  degree: number;
  checked: Person | undefined;
  documentTypeId?: number;
  documentTypeName?: string;
  setChecked: (value: Person | undefined) => void;
};

export const OtherPerson: React.FC<Props> = ({
  person,
  degree,
  checked,
  setChecked,
  documentTypeId,
  documentTypeName,
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { birthdate, id, lastname, firstname, patronymic, snils, gender_id, documents_react } = person;
  const matchLevel = checkMatch(degree);

  const documents = documents_react?.filter((item) => documentTypeId === item.local_document_type_id);

  const dataTypeDocument = (doc: PersonDocument) => {
    if (doc && doc.local_document_type_id) {
      return `${doc.series || ''} ${hiddenSymbol('number', doc.number)} от ${formatDate(doc.issued)}`;
    }
  };

  const handleSelect = () => {
    setChecked(person);
  };

  return (
    <div className="requests-check-details__item">
      <div className="requests-check-details__inner">
        <div className="flex">
          <div className="flex-none flex">
            <LmRadio
              name="check"
              dataTest={String(id)}
              checked={checked?.id === id}
              onChange={handleSelect}
            />
            <Push
              size={16}
              orientation="horizontal"
            />
          </div>
          <div className="flex-auto">
            <div className="requests-check-details__grid">
              <div>
                <div className="icon-group">
                  <div className="icon-group__icon">
                    <LmIcon
                      icon="filled-account-person"
                      size={20}
                      color="var(--LM-neutrals-day-600)"
                    />
                  </div>
                  <div className="icon-group__text font-weight-bold">{`${checkIfData(lastname)} ${checkIfData(
                    firstname
                  )} ${checkIfData(patronymic)}`}</div>
                </div>
                <Push size={4} />
                <div className="flex">
                  <Push
                    size={26}
                    orientation="horizontal"
                  />
                  <div>
                    <div className="color-gray-dark flex">
                      {transformDate(birthdate)}
                      <Push
                        size={10}
                        orientation="horizontal"
                      />
                      {editSex(gender_id)}
                    </div>
                    <Push size={4} />
                    <div
                      className={
                        matchLevel === MatchLevelEnum.low
                          ? 'color-danger'
                          : matchLevel === MatchLevelEnum.medium
                            ? 'color-warning'
                            : 'color-success'
                      }
                    >
                      {degree}% совпадение
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Push size={2} />
                {documentTypeName}
                {documents?.length ? (
                  documents.map((doc) => (
                    <Fragment key={`doc_${doc.id}`}>
                      <Push size={4} />
                      <div className="color-gray-dark">{dataTypeDocument(doc) ?? '—'}</div>
                    </Fragment>
                  ))
                ) : (
                  <>
                    <Push size={4} />
                    <div className="color-gray-dark">—</div>
                  </>
                )}
              </div>
              <div>
                <Push size={2} />
                СНИЛС
                <Push size={4} />
                <div className="color-gray-dark">{hiddenSymbol('snils', snils) ?? '—'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
