import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from '../../config/constants';
import { useGetFromRoute } from '../../hooks/get-from-route';
import { EducationTypeEnum } from '../../types/education-type';
import { ServiceClassData } from '../../types/service-class';
import ServiceClassProf from './preparation';
import DayCareServiceClass from './day-care/index';
import ServiceClassDkgm from './dkgm';
import ServiceClassDonm from './donm';
import ServiceClassDsit from './dsit';
import ServiceClassDt from './digital-tutor';
import { adminAccess, hasGeneralAccess } from '../../lib/utils';
import { generalAccess } from '../../types/authorization-data';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { accessByEducationType, addArchive, deleteServiceClass, getServiceClassData } from './utils';
import { accessAction } from '../../mock-data/access-enum';
import { ServiceClassStatusEnum } from '../../mock-data/service-class-status-enum';

export type ServiceClassContextType = {
  serviceClassData: ServiceClassData;
  updateServiceClass: () => void;
  adminView?: boolean;
  adminEdit?: boolean;
  admin?: boolean;
  addArchive?: () => Promise<void>;
  deleteServiceClass?: () => Promise<void>;
  isRemovedGroup?: boolean;
  accessAddArchive?: boolean;
  accessEditServiceClass?: boolean;
  isArchiveGroup?: boolean;
  oiv?: boolean;
  accessRecover?: boolean;
  checkServicaClassArchive?: string;
  startDateSchedulePeriod: Date | null;
  endDateSchedulePeriod: Date | null;
  setStartDateSchedulePeriod: (value: Date | null) => void;
  setEndDateSchedulePeriod: (value: Date | null) => void;
  currentValuesGroupDetails?: ServiceClassData;
  setCurrentValuesGroupDetails?: (value: ServiceClassData) => void;
  isModalSchedule?: boolean; // если true обнуляем значения в период-ах
  setIsModalSchedule?: (value: boolean) => void;
  routeBack?: string;
};

export const ServiceClassContext = createContext<ServiceClassContextType>({} as ServiceClassContextType);

const ServiceClass = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const [serviceClass, setServiceClass] = useState<ServiceClassData>();
  const [isRemovedGroup, setIsRemovedGroup] = useState<boolean>(false);

  const fromRoute = useGetFromRoute();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const getServiceClass = useCallback(async () => {
    getServiceClassData(id as string, setServiceClass);
  }, [id]);

  useEffect(() => {
    getServiceClass();
  }, [id, getServiceClass]);

  const currentServiceClass = () => {
    switch (serviceClass?.educationTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return <ServiceClassDonm />;
      case EducationTypeEnum.ArtHouseEducation:
        return <ServiceClassDkgm />;
      case EducationTypeEnum.SportEducation:
        return <ServiceClassDsit />;
      case EducationTypeEnum.DayCareCentersEducation:
        return <DayCareServiceClass />;
      case EducationTypeEnum.ProfessionalEducation:
        return <ServiceClassProf />;
      case EducationTypeEnum.VirtualAssistantEducation:
        return <ServiceClassDt />;
      default:
        return null;
    }
  };

  // const editServiceVA = useMemo(() => hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Edit), [
  //   userProfile,
  // ]);

  const [startDateSchedulePeriod, setStartDateSchedulePeriod] = useState<Date | null>(null);
  const [endDateSchedulePeriod, setEndDateSchedulePeriod] = useState<Date | null>(null);

  const [currentValuesGroupDetails, setCurrentValuesGroupDetails] = useState<ServiceClassData>();
  const [isModalSchedule, setIsModalSchedule] = useState<boolean>(false);

  const initialValues: ServiceClassContextType = useMemo(
    () => ({
      isModalSchedule,
      setIsModalSchedule,
      serviceClassData: serviceClass as ServiceClassData,
      startDateSchedulePeriod,
      endDateSchedulePeriod,
      setStartDateSchedulePeriod,
      setEndDateSchedulePeriod,
      updateServiceClass: getServiceClass,
      currentValuesGroupDetails: currentValuesGroupDetails as ServiceClassData,
      setCurrentValuesGroupDetails,
      addArchive: async () => addArchive(id as string),
      deleteServiceClass: async () => deleteServiceClass(id as string, setIsRemovedGroup),
      isRemovedGroup,
      adminView: adminAccess('view', userProfile),
      adminEdit: adminAccess('edit', userProfile),
      admin: adminAccess('all', userProfile),
      accessAddArchive: accessByEducationType(userProfile, Number(serviceClass?.educationTypeId), accessAction.Archive),
      accessEditServiceClass: accessByEducationType(
        userProfile,
        Number(serviceClass?.educationTypeId),
        accessAction.Edit
      ),
      isArchiveGroup: serviceClass?.serviceClassStatusId === ServiceClassStatusEnum.Archive,
      oiv: hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV),
      accessRecover:
        accessByEducationType(userProfile, Number(serviceClass?.educationTypeId), accessAction.Recover) ||
        adminAccess('edit-or-view', userProfile),
      routeBack: fromRoute || routes.serviceClasses,
    }),
    [
      isModalSchedule,
      setIsModalSchedule,
      serviceClass,
      startDateSchedulePeriod,
      endDateSchedulePeriod,
      setStartDateSchedulePeriod,
      setEndDateSchedulePeriod,
      getServiceClass,
      currentValuesGroupDetails,
      setCurrentValuesGroupDetails,
      isRemovedGroup,
      userProfile,
      id,
      fromRoute,
    ]
  );

  return (
    <>
      {serviceClass && (
        <ServiceClassContext.Provider value={initialValues}>{currentServiceClass()}</ServiceClassContext.Provider>
      )}
    </>
  );
};

export default ServiceClass;
