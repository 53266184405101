import React from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { ServiceRegistryContext } from '../../initial-data';
import { SelectOptionType } from '../../../../../types/entities';

const FieldStatusPlanReception: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const { trainingGroupStatuses } = React.useContext(ServiceRegistryContext);

  return (
    <FormikSelect
      label="Статус плана приема / расписания вступительных испытаний"
      name="trainingGroupStatusId"
      size="small"
      options={trainingGroupStatuses}
      withSearch
      placeholder="Выберите..."
      defaultValue={
        values.trainingGroupStatusId
          ? {
              value: values.trainingGroupStatusId,
              label: values.trainingGroupStatusName || '',
            }
          : {
              label: 'Все',
              value: 0,
            }
      }
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('trainingGroupStatusName', v.label);
        }
      }}
    />
  );
};

export default FieldStatusPlanReception;
