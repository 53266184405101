import moment from 'moment';
import React from 'react';

type CertificateLabelProps = {
  name: string;
  validTo: string;
};

const CertificateLabel = ({ name, validTo }: CertificateLabelProps) => (
  <div className="certificate-label">
    <span>{name}</span>
    <span className="certificate-label__valid-to">действует до {moment(validTo).format('DD.MM.YYYY')}</span>
  </div>
);

export default CertificateLabel;
