import React, { FC, useEffect, useRef, useState } from 'react';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import EditSchedule, { ScheduleType } from '.';
import Popup from '../modals/popup';

type Props = {
  show: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (schedule: ScheduleType[]) => void;
  scheduleData: ScheduleType[];
};

const ScheduleModal: FC<Props> = ({ show, title, onClose, scheduleData, onSubmit }) => {
  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [scheduleError, setScheduleError] = useState(false);
  const [scheduleExtended, setScheduleExtended] = useState(false);

  const extendedButtonRef = useRef<HTMLButtonElement | null>(null);
  const notExtendedButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!(extendedButtonRef.current && notExtendedButtonRef.current)) {
      return;
    }
    setScheduleExtended(extendedButtonRef.current?.classList.contains('active'));
  }, [extendedButtonRef, notExtendedButtonRef]);

  return (
    <Popup
      dataTest="schedulePopup"
      open={show}
      title={title}
      classes={{
        body: 'modal__body--full',
      }}
      primaryButtonModifiers={{
        disabled: scheduleError,
      }}
      onClose={onClose}
      onSubmit={() => {
        setScheduleSubmit(true);
      }}
    >
      <div className="schedule__switcher">
        <LmSwitcher
          widthFull
          size="medium"
          dataTest="scheduleButton"
          onChange={(value) => {
            setScheduleExtended(value);
            value ? extendedButtonRef.current?.click() : notExtendedButtonRef.current?.click();
          }}
          value={scheduleExtended}
          variant="gray"
        >
          <LmSwitcherOption
            dataTest="schedule-btn-weekend"
            value={false}
          >
            Будни/выходные
          </LmSwitcherOption>
          <LmSwitcherOption
            dataTest="schedule-btn-extended"
            value
          >
            Детально по дням
          </LmSwitcherOption>
        </LmSwitcher>
      </div>
      <EditSchedule
        title=""
        timeRoundingUp
        submit={scheduleSubmit}
        submitError={() => {
          setScheduleSubmit(false);
        }}
        submitSuccess={(s) => {
          onSubmit(s);
          setScheduleSubmit(false);
        }}
        scheduleData={scheduleData}
        extendedButtonRef={extendedButtonRef}
        notExtendedButtonRef={notExtendedButtonRef}
        setCurrentErrorSchedule={setScheduleError}
      />
    </Popup>
  );
};

export default ScheduleModal;
