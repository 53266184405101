import React from 'react';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';

type Props = {
  isArtHouseSportEducation: boolean;
  label: string;
  placeholder?: string | null;
};

const FieldTestDate: React.FC<Props> = ({ isArtHouseSportEducation, label, placeholder }) => {
  return isArtHouseSportEducation ? (
    <FormikDatePicker
      label={label}
      isIntervalDate
      placeholder={placeholder ?? undefined}
      size="small"
      name="testDate"
    />
  ) : null;
};

export default FieldTestDate;
