import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { routes } from '../../config/constants';
import Img from '../../assets/images/errors/access.svg';
import homeApi from '../../lib/api/home';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { useGetAppealLinkQuery } from '../../store/home';

const AccessError = () => {
  const [errorId, setErrorId] = useState<number>();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { data: appealLink = '' } = useGetAppealLinkQuery({
    errorId,
    formName: 'Ошибка',
  });

  useEffect(() => {
    const getLink = async () => {
      const errorId = await homeApi.saveError('Доступ закрыт', {
        userId: userProfile.id,
        userLogin: userProfile.login,
        message: 'У пользователя отсутствует доступ к запрашиваемому ресурсу, либо запрашиваемая страница отсутствует',
      });

      setErrorId(errorId);
    };

    getLink();
  }, [userProfile]);

  return (
    <div className="error-container">
      <Push size={40} />
      <div className="error-heading">Упс!</div>
      <Push size={8} />
      <div className="error-lead">Кажется, у Вас нет доступа к данной странице или такой страницы не существует</div>
      <Push size={16} />
      <Link to={routes.main}>Вернитесь на главную</Link> или{' '}
      <a
        href={appealLink}
        target="_blank"
        rel="noreferrer"
      >
        обратитесь в службу технической поддержки
      </a>{' '}
      <br />
      для получения доступа к странице.
      <Push size={34} />
      <img
        src={Img}
        alt=""
        className="error-img"
      />
    </div>
  );
};

export default AccessError;
