export enum StatusLearnerEnum {
  // Зачислен.
  Included = 1,

  // Отчислен.
  Excluded = 2,

  // Переведен.
  Changed = 3,

  // Новый.
  New = 4,
}
