import React, { useEffect, useState } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import learnerApi from '../../../lib/api/learner';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import SimpleTable from '../../../components/table/simple-table';
import { EducationFact } from '../../../types/learners';

type Props = {
  contingentGuid: string;
  contingentLinkTypeId: number;
};

const Organization: React.FC<Props> = ({ contingentGuid, contingentLinkTypeId }) => {
  const [tableData, setTableData] = useState<EducationFact[] | null>();

  useEffect(() => {
    const fetch = async () => {
      const educations = await learnerApi.getEdFacts(contingentGuid);

      if (educations) {
        setTableData(educations);
      }
    };

    if (contingentLinkTypeId === ContingentLinkTypeEnum.Found) {
      fetch();
    }
  }, [contingentGuid, contingentLinkTypeId]);

  return (
    <>
      <Push size={12} />
      <Panel title={() => <>Общеобразовательная организация</>}>
        {tableData ? (
          <SimpleTable
            data={tableData}
            pageSize={20}
            columns={[
              {
                dataIndex: 'educationLevelName',
                title: 'Уровень образования',
                render: (item: any) => item.educationLevelName,
              },
              {
                dataIndex: 'organizationName',
                title: 'Общеобразовательная организация',
                render: (item: any) => item.organizationName,
              },
              {
                dataIndex: 'className',
                title: 'Группа/класс',
                render: (item: any) => item.className,
              },
            ]}
          />
        ) : (
          <div className="requests-no-data">Сведения о получаемом ребенком образовании отсутствуют</div>
        )}
      </Panel>
    </>
  );
};

export default Organization;
