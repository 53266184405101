import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FilterContentItem from '../../../../../components/filter/filter-content-item';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { SearchRequestsInitialFormData } from '../../../../../types/requests';
import { hasGeneralAccess } from '../../../../../lib/utils';
import { AppState } from '../../../../../redux/types/state';
import { userProfileSelector } from '../../../../../redux/selectors';
import { generalAccess } from '../../../../../types/authorization-data';
import { clearField } from '../../../../../lib/utils/requests';
import { FieldsModelItem } from '../../../../../types/entities';
import ChildServiceAdmin from './child-admin/child-service-admin';
import ChildService from './child-default/child-service';

export type ChildServiceProps = {
  allowedEducationTypeId: number;
  canChangeOrganization: boolean;
  isArtHouseSportEducation: boolean;
  educationTypeData: SelectOptionType[];
  fieldshModelItem: FieldsModelItem | null;
  onChangeEducationType: (value: number) => void;
};

const ChildServiceView: React.FC<ChildServiceProps> = ({
  educationTypeData,
  allowedEducationTypeId,
  isArtHouseSportEducation,
  canChangeOrganization,
  fieldshModelItem,
  onChangeEducationType,
}) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { values, validateField, setFieldValue } = useFormikContext<SearchRequestsInitialFormData>();

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  const childServiceProps = {
    educationTypeData,
    canChangeOrganization,
    allowedEducationTypeId,
    isArtHouseSportEducation,
    isAdmin,
    values,
    userProfile,
  };

  const getServiceName = useCallback(
    async (id: number) => {
      const service = await serviceTemplateApi.getServiceName(id);

      setFieldValue('serviceName', service);
    },
    [setFieldValue]
  );

  useEffect(() => {
    clearField(values.testDate, 'testDate', validateField);
  }, [validateField, values.testDate]);

  useEffect(() => {
    if (values.serviceId && !values.serviceName) {
      getServiceName(values.serviceId);
    }
  }, [getServiceName, values.serviceId, values.serviceName]);

  return (
    <FilterContentItem
      dataTest="childService"
      title="Детское объединение или услуга"
    >
      {educationTypeData.length === 1 ? (
        <ChildService {...childServiceProps} />
      ) : (
        <ChildServiceAdmin
          {...childServiceProps}
          onChangeEducationType={onChangeEducationType}
          fieldshModelItem={fieldshModelItem}
        />
      )}
      <Push size={2} />
    </FilterContentItem>
  );
};

export default ChildServiceView;
