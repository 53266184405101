import React from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import Field, { FieldProps } from './field';
import FormikInput from '../formik/formik-input';

type EmailProps = FieldProps<string>;

const Email = ({
  label = 'E-mail',
  defaultValue = 'Не представлен',
  name,
  editMode,
  required,
  disabled,
}: EmailProps) => {
  return (
    <FormikField name={name}>
      {({ field }: FormikFieldProps) => {
        const { value } = field;

        return (
          <Field
            label={label}
            editMode={editMode}
            required={required}
            value={value}
            defaultValue={defaultValue}
          >
            <FormikInput
              name={name}
              placeholder="Введите (если есть)..."
              size="small"
              disabled={disabled}
            />
          </Field>
        );
      }}
    </FormikField>
  );
};

export default Email;
