import React, { useEffect, useState } from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import ViewContainer from '../../components/view-container';
import useClearHistoryState from '../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../lib/utils';
import { initialFavoriteFilterFormData } from '../../mock-data/favorites';
import { historyState } from '../../mock-data/history-state';
import FavoriteSearch from './search';
import { SearchFavoriteFormData } from '../../types/favorite';
import FavoriteTable from './table';
import { routes } from '../../config/constants';
import { sendReachGoal } from '../../lib/metrica';

const Favorite = () => {
  const [search, setSearch] = useState<SearchFavoriteFormData>(
    window.history.state[historyState.search] || initialFavoriteFilterFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchFavoriteFormData) => {
    setSearch(values);
    replaceHistoryState({
      [historyState.search]: values,
    });
  };

  useEffect(() => {
    sendReachGoal('favorites');
  }, []);

  return (
    <ViewContainer
      subHeader={
        <LmSubHeader
          sticky
          title="Избранное"
          description=""
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Избранное',
            },
          ]}
          hideReturnButton
          dataTest="favoritesSubHeader"
        />
      }
      filterComponent={
        <FavoriteSearch
          submitForm={handleSubmitSearch}
          initialForm={search}
        />
      }
    >
      <FavoriteTable search={search} />
    </ViewContainer>
  );
};

export default Favorite;
