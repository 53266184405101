import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import { classificatorEKULimitApi } from '../../../../lib/api/classificator-EKU-limit';
import { BudgetPlacesData, ServiceData } from '../../../../types/service';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { ServiceDuration } from '../../components/fields/service-duration';
import { Dictionary } from '../../components/fields/dictionary';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { GroupMembersField } from '../../components/fields/group-members';
import OrganizationField from '../../components/fields/organization';
import SimpleInput from '../../../../components/fields/simple-input';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import { hasGeneralAccess } from '../../../../lib/utils';
import { generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import BudgetPlacesField from '../components/budget-places-field';
import ParallelField from '../../components/fields/parallel';

type CreateInfoProps = {
  budgetPlaces?: BudgetPlacesData;
  updateBudgetPlaces: (values?: BudgetPlacesData) => void;
};

export function CreateInfo({ budgetPlaces, updateBudgetPlaces }: CreateInfoProps) {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { values } = useFormikContext<ServiceData>();
  const [currentYearOfTrainings, setCurrentYearOfTrainings] = useState<number>();

  const getCurrentYear = useCallback(async () => {
    try {
      const currentYear = await dictionariesApi.getCurrentYearOfTrainings();

      setCurrentYearOfTrainings(currentYear.id);
    } catch (error) {
      updateBudgetPlaces(undefined);
    }
  }, [updateBudgetPlaces]);

  const getBudgetPlaces = useCallback(async () => {
    try {
      const data = await classificatorEKULimitApi.getBudgetPlaces({
        educationTypeId: values.educationTypeId,
        organizationId: values.info.organizationId,
        classificatorEKUId: values.info.classificatorEKUId,
        yearOfTrainingId: currentYearOfTrainings,
      });
      const index = currentYearOfTrainings ? 0 : data.length - 1;

      updateBudgetPlaces({
        limitVolume: data[index].limitVolume,
        volume: data[index].volume,
      });
    } catch (error) {
      updateBudgetPlaces(undefined);
    }
  }, [values, currentYearOfTrainings, updateBudgetPlaces]);

  useEffect(() => {
    getCurrentYear();
  }, [getCurrentYear]);

  useEffect(() => {
    values?.info?.classificatorEKUId ? getBudgetPlaces() : updateBudgetPlaces(undefined);
  }, [values?.info?.classificatorEKUId, currentYearOfTrainings, getBudgetPlaces, updateBudgetPlaces]);

  return (
    <Panel title={() => <>Сведения об образовательной программе</>}>
      <div className="container">
        <OrganizationField
          disabled={
            !(
              hasGeneralAccess(userProfile, generalAccess.AdminView) ||
              hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV)
            )
          }
          label={
            <>
              Образовательная <br /> организация
            </>
          }
          isCreate
          editMode
          required
          parent="info"
          name="organization"
        />
        <SimpleInput
          name="info.name"
          label="Наименование программы"
          editMode
          required
        />
        <ParallelField
          parent="info"
          name="parallel"
          required
          editMode
        />

        <ClassificatorNameField
          editMode
          showAmount
          label="Профессия"
          name="classificator"
          parent="info"
          modalTitle="Реестр базовых направлений"
        />

        <BudgetPlacesField budgetPlaces={budgetPlaces} />

        <LessonLevelField
          isNotTableMain={false}
          label="Квалификация"
          editMode
          required
          name="programmLevel"
          parent="info"
        />

        <ServiceDuration
          editMode
          parent="info"
        />

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Занятия</div>
          <div className="table-data__body">
            <div className="flex items-center">
              <div className="table-data__group">
                <div className="table-data__label">
                  Форма <span className="table-data__required" />
                </div>
                <div
                  className="table-data__body"
                  style={{
                    width: 200,
                  }}
                >
                  <Dictionary
                    name="serviceForm"
                    parent="info"
                    editMode
                    isNotTableMain
                    dictionaryFunc={dictionariesApi.getServiceForms}
                  />
                </div>
              </div>

              <Push
                size={16}
                orientation="horizontal"
              />

              <div className="table-data__group">
                <div className="table-data__label">
                  Вид посещения <span className="table-data__required" />
                </div>
                <div
                  className="table-data__body"
                  style={{
                    width: 200,
                  }}
                >
                  <Dictionary
                    editMode
                    isNotTableMain
                    name="typeOfAttendance"
                    parent="info"
                    dictionaryFunc={() => dictionariesApi.getVisitTypes(true)}
                  />
                </div>
              </div>

              <Push
                size={16}
                orientation="horizontal"
              />
              <div className="table-data__group">
                <div className="table-data__label">Часов в неделю</div>
                <div className="table-data__body">
                  <FormikInputNumber
                    name="info.hoursPerWeek"
                    placeholder="0"
                    maxLength={2}
                    max={99}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <GroupMembersField
          editMode
          parent="info"
        />

        <SimpleTextArea
          name="info.programmService"
          label="Описание программы"
          editMode
          required
          placeholder="Введите..."
          maxLength={1000}
        />
      </div>
    </Panel>
  );
}
