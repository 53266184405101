import React, { useContext } from 'react';
import Dialog from '../../../../components/modals/dialog';
import { ServiceClassContext } from '../../service-class';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
  setEditModeParent: React.Dispatch<React.SetStateAction<string | null>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const WarningScheduleModal = ({ show, onCloseHandler, setEditModeParent, setEditMode }: Props) => {
  const { setIsModalSchedule } = useContext(ServiceClassContext);

  const changeSchedule = () => {
    setEditMode(true);
    if (setIsModalSchedule) {
      setIsModalSchedule(true);
    }
    setEditModeParent && setEditModeParent('schedule');
    document.documentElement.style.overflowY = 'auto';
  };

  return (
    <Dialog
      dataTest="checkSchedule"
      isOpenDialog={show}
      title="Проверьте время проведения занятий"
      description={
        'Обратите внимание, что даты начала и окончания периодов, созданных в блоке "Время проведения занятий", выходят за пределы дат начала и окончания занятий. Проверьте и измените даты периодов в блоке "Время проведения занятий".'
      }
      variant="question"
      buttonPrimaryText="Изменить время проведения занятий"
      onClickSeconadaryButton={onCloseHandler}
      onClickPrimaryButton={changeSchedule}
    />
  );
};

export default WarningScheduleModal;
