import { accessAction, accessObject, ObjectAccessAction } from '../../types/authorization-data';
import { EducationTypeEnum, EducationTypeEnumDescription } from '../../types/education-type';
import { EducationType } from '../../types/entities';
import { routes } from '../../config/constants';
import { generateLink } from '.';

export const getRequestEducationLinks = (
  value: ObjectAccessAction[],
  educationTypes: EducationType[],
  pupilId?: number
) => {
  return filterEducations(value, educationTypes, undefined, accessObject.Requests, accessAction.Create, true)
    .filter(
      (item) => item.value !== EducationTypeEnum.All && item.value !== EducationTypeEnum.VirtualAssistantEducation
    )
    .map((item) => {
      return {
        ...item,
        link: pupilId
          ? generateLink(routes.createRequestByPupil, {
            edType: item.value,
            pupilId,
          })
          : generateLink(routes.createRequest, {
            edType: item.value,
          }),
      };
    });
};

export const getEducationTypeForCreateLinks = (data: EducationType[]): any => {
  return data.map((item: EducationType) => {
    return {
      value: item.id,
      link: generateLink(routes.createRequest, {
        edType: item.id,
      }),
      label: item.name,
      type: EducationTypeEnum[item.id],
    };
  });
};

/* Получить все элементы EducationTypeEnum кроме None и All */
export const getAllEducationTypeValues = () => {
  // все ключи енума
  const enumVals = Object.keys(EducationTypeEnum);
  // названия по-умолчанию
  const enumOptions = [
    {
      value: EducationTypeEnum.ChildrenEducation,
      label: 'Детские объединения департамента образования',
    },
    {
      value: EducationTypeEnum.ProfessionalEducation,
      label: 'Профессиональное образование',
    },
    {
      value: EducationTypeEnum.DayCareCentersEducation,
      label: 'Группы по уходу и присмотру за детьми школьного возраста',
    },
    {
      value: EducationTypeEnum.SportEducation,
      label: 'Спортивные секции',
    },
    {
      value: EducationTypeEnum.ArtHouseEducation,
      label: 'Дома творчества',
    },
    {
      value: EducationTypeEnum.ChildrenNonDogmEducation,
      label: 'Детские объединения',
    },
    {
      value: EducationTypeEnum.VirtualAssistantEducation,
      label: 'Цифровой репетитор',
    },
  ];

  return enumVals
    .filter((enumVal) => Number.isNaN(Number(enumVal))) // отсеиваем ключи вида '0', '1', '2' и пр.
    .map((val) => {
      return {
        value: EducationTypeEnum[val as keyof typeof EducationTypeEnum],
        label: val.toString(),
      };
    }) // конвертируем в массив вида value/label
    .filter((enumVal) => enumVal.value !== EducationTypeEnum.All && enumVal.value !== EducationTypeEnum.None) // убираем None & All
    .map<{ value: number; label: string; type: string }>((val) => {
      const option = enumOptions.find((item) => item.value === val.value) ?? val;

      return {
        ...option,
        type: EducationTypeEnum[option.value],
      };
    }); // миксуем с названиями по-умолчанию
};

export const filterEducations = (
  value: ObjectAccessAction[],
  educationTypes: EducationType[],
  accessObjectId?: number,
  parentAccessObjectId?: number,
  accessActionId?: number,
  excludeTotalValue?: boolean
) => {
  const filtered = value.filter((item: ObjectAccessAction) => {
    return (
      (!accessActionId || item.accessAction === accessActionId) &&
      (!accessObjectId || item.accessObject === accessObjectId) &&
      (!parentAccessObjectId || item.parentAccessObject === parentAccessObjectId)
    );
  });

  const educationTypeEnumItems = getAllEducationTypeValues();

  const result = filtered.reduce((acc: { [key: string]: { label: string; value: number } }, el: ObjectAccessAction) => {
    const elName = educationTypes?.find((eType) => eType.id === el.educationType)?.name;

    educationTypeEnumItems.forEach((educationTypeItem) => {
      if (((el.educationType ?? 0) & educationTypeItem.value) !== 0) {
        educationTypeItem.label = elName || educationTypeItem.label;
        acc[educationTypeItem.type] = educationTypeItem;
      }
    });

    return acc;
  }, {});

  const items = Object.values(result);

  // Это проверка нужна для раздела "Обучающиеся"
  if (items.length > 1 && !excludeTotalValue) {
    const allValue = items.reduce((acc, { value }) => acc + value, 0);

    items.splice(0, 0, {
      label: EducationTypeEnumDescription.get(EducationTypeEnum.All) || 'Все',
      value: allValue,
    });
  }

  return items;
};
