import { useField } from 'formik';
import moment from 'moment';
import * as Popper from 'popper.js';
import { useContext, useEffect } from 'react';
import { FieldProps } from '../../../../components/fields/field';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import { formatDate } from '../../../../lib/utils/date';
import { EducationTypeEnum } from '../../../../types/education-type';
import { TrainingGroupItemType } from '../../../../types/service-class';
import { ServiceClassContext } from '../../service-class';

type DateFieldProps = FieldProps<string> & {
  calendarPosition?: Popper.Placement;
  currentTrainingGroup?: TrainingGroupItemType | null;
  isNewServiceClass?: boolean;
  showErrorImmediately?: boolean;
};

const DateField = ({
  label = 'Даты занятий',
  name,
  editMode,
  required,
  defaultValue = '—',
  calendarPosition,
  currentTrainingGroup,
  isNewServiceClass,
  disabled,
  showErrorImmediately,
}: DateFieldProps) => {
  const { serviceClassData, setStartDateSchedulePeriod, setEndDateSchedulePeriod } = useContext(ServiceClassContext);

  const fieldStartName = `${name}StartDate`;
  const fieldEndName = `${name}EndDate`;

  const [fieldStart, fieldStartMeta] = useField(fieldStartName);
  const [fieldEnd, fieldEndMeta] = useField(fieldEndName);

  const errorsEndStartDate = Boolean(fieldStartMeta?.error) || Boolean(fieldEndMeta?.error);
  const checkMinDateByEducationType = (): Date | undefined => {
    const defaultMinDate = '01.01.1910';

    const minDate = currentTrainingGroup?.educationDateStart
      ? moment(currentTrainingGroup?.educationDateStart).format('DD.MM.YYYY')
      : defaultMinDate;

    if (EducationTypeEnum.DayCareCentersEducation === serviceClassData.educationTypeId) {
      return moment(minDate, 'DD/MM/YYYY').toDate();
    } else {
      return undefined;
    }
  };

  const checkMaxDateByEducationType = (): Date | undefined => {
    const defaultMaxDate = '01.01.2050';

    const maxDateGpd = currentTrainingGroup?.educationDateStart
      ? moment(currentTrainingGroup?.educationDateStart).add(1, 'years').add(-1, 'day').format('DD.MM.YYYY')
      : defaultMaxDate;

    const maxDateDo = currentTrainingGroup?.educationDateEnd
      ? moment(currentTrainingGroup?.educationDateEnd).format('DD.MM.YYYY')
      : defaultMaxDate;

    if (EducationTypeEnum.DayCareCentersEducation === serviceClassData.educationTypeId) {
      return moment(maxDateGpd, 'DD/MM/YYYY').toDate();
    } else if (
      EducationTypeEnum.ChildrenEducation === serviceClassData.educationTypeId ||
      EducationTypeEnum.ChildrenNonDogmEducation === serviceClassData.educationTypeId
    ) {
      return moment(maxDateDo, 'DD/MM/YYYY').toDate();
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    setStartDateSchedulePeriod(fieldStart.value);
  }, [fieldStart.value, setStartDateSchedulePeriod]);

  useEffect(() => {
    setEndDateSchedulePeriod(fieldEnd.value);
  }, [fieldEnd.value, setEndDateSchedulePeriod]);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        {label} {editMode && required && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        <div className="table-data-grid-2">
          <div className={`table-data flex ${errorsEndStartDate ? 'items-baseline' : 'items-center'}`}>
            <div className="table-data__label flex">Начало</div>
            <div className="table-data__body">
              {editMode ? (
                <FormikDatePicker
                  size="small"
                  name={fieldStartName}
                  placement={calendarPosition}
                  minDate={!isNewServiceClass ? checkMinDateByEducationType() : undefined}
                  maxDate={!isNewServiceClass ? checkMaxDateByEducationType() : undefined}
                  openToDate={!isNewServiceClass ? checkMinDateByEducationType() : undefined}
                  disabled={disabled}
                  showErrorImmediately={showErrorImmediately}
                />
              ) : fieldStart.value ? (
                formatDate(fieldStart.value)
              ) : (
                defaultValue
              )}
            </div>
          </div>
          <div className={`table-data flex ${errorsEndStartDate ? 'items-baseline' : 'items-center'}`}>
            <div className="table-data__label flex">Окончание</div>
            <div className="table-data__body">
              {editMode ? (
                <FormikDatePicker
                  size="small"
                  name={fieldEndName}
                  placement={calendarPosition || 'bottom-end'}
                  minDate={!isNewServiceClass ? checkMinDateByEducationType() : undefined}
                  maxDate={!isNewServiceClass ? checkMaxDateByEducationType() : undefined}
                  openToDate={!isNewServiceClass ? checkMinDateByEducationType() : undefined}
                  disabled={disabled}
                  showErrorImmediately={showErrorImmediately}
                />
              ) : fieldEnd.value ? (
                formatDate(fieldEnd.value)
              ) : (
                defaultValue
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateField;
