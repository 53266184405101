import { FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import { memo, useContext, useRef } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import { LmButton, LmRadio } from '@mes-ui/lemma';
import FormikSelect from '../../../../../components/formik/formik-select';
import { TeacherType } from '../../../../../types/service-class';
import lookupApi from '../../../../../lib/api/lookup';
import { ServiceClassContext } from '../../../service-class';

type SelectTeacherProps = {
  label: string;
  data: TeacherType;
  child?: string;
  arrayHelpers: FieldArrayRenderProps;
  itemIndex: number;
  hasCreateServiceClass?: boolean;
};

export const personIdField = 'id';
export const personNameField = 'name';
export const isSupervisorField = 'isSupervisor';

const SelectTeacher: React.FC<SelectTeacherProps> = ({
  label = 'ФИО',
  data,
  child,
  arrayHelpers,
  itemIndex,
  hasCreateServiceClass,
}) => {
  const { setValues, values } = useFormikContext<any>();
  const { serviceClassData } = useContext(ServiceClassContext);
  const includedData = useRef<number[]>(values?.teachers?.map((item: TeacherType) => item.id));

  const fieldPersonId = child ? `${child}.[${itemIndex}].${personIdField}` : personIdField;
  const fieldPersonName = child ? `${child}.[${itemIndex}].${personNameField}` : personNameField;
  const fieldIsSupervisor = child ? `${child}.[${itemIndex}].${isSupervisorField}` : isSupervisorField;

  const [personIdOptions, , helpersValue] = useField(fieldPersonId);
  const [personNameOptions, , helpersLabel] = useField(fieldPersonName);
  const [isSupervisorOptions] = useField(fieldIsSupervisor);

  const getOptions = async (query: string): Promise<SelectOptionType[]> => {
    const currentOptions = await lookupApi.getTeachers(query, serviceClassData?.organizationId);

    const filterOptions = currentOptions.filter((item) => !includedData.current?.includes(item.value as number));

    return includedData.current?.length > 0 ? filterOptions : currentOptions;
  };

  const setCurrentValue = async (option?: SelectOptionType): Promise<void> => {
    helpersValue?.setValue(option?.value || '');
    helpersLabel?.setValue(option?.label || '');

    if (option?.value) {
      includedData.current = includedData.current
        ? [...includedData.current, Number(option?.value)]
        : [Number(option?.value)];
    } else {
      includedData.current = includedData.current?.filter((item: number) => item !== option?.value);
    }
  };

  return (
    <div className="table-data__item flex flex-column">
      <div className="table-data__group">
        <div className="table-data__label table-data__label--main">{label}</div>
        <div className="table-data__body table-data-grid-2-1 items-start">
          <div>
            <FormikSelect
              menuPlacement={hasCreateServiceClass || itemIndex > 2 ? 'top' : 'bottom'}
              name={fieldPersonName}
              size="small"
              withSearch
              loadOptions={getOptions}
              options={[]}
              placeholder="Начните вводить..."
              maxMenuHeight={150}
              defaultValue={
                personNameOptions?.value
                  ? {
                      value: personIdOptions?.value,
                      label: personNameOptions?.value,
                    }
                  : null
              }
              selectedValue={async (option?: SelectOptionType) => {
                await setCurrentValue(option);
              }}
            />
          </div>

          <div className="flex justify-between">
            <LmRadio
              name={fieldIsSupervisor}
              dataTest={fieldIsSupervisor}
              onChange={() => {
                setValues({
                  ...values,
                  teachers: values?.teachers?.map((item: TeacherType) =>
                    item.id === data?.id
                      ? {
                          ...item,
                          isSupervisor: true,
                        }
                      : {
                          ...item,
                          isSupervisor: false,
                        }
                  ),
                });
              }}
              checked={Boolean(isSupervisorOptions.value)}
            >
              Руководитель
            </LmRadio>
            <LmButton
              dataTest="closeTeacher"
              type="button"
              variant="secondary"
              icon="filled-edit-close"
              iconSize={20}
              onClick={() => arrayHelpers.remove(itemIndex)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SelectTeacher);
