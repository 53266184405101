import React, { useContext, useMemo, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmIcon } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import SignModal from '../../../../components/sign-modal';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { templateApi } from '../../../../lib/api/template';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { notify } from '../../../../redux/ducks/notifications';
import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceContext } from '../../index';
import RemovalFromPublicationModal from '../modals/removal-from-publication';
import {
  altEndValidationFunc,
  altStartValidationFunc,
  durationOfTrainingFunc,
  hoursPerWeekFunc,
} from '../../../../lib/utils/validation';
import { generalAccess } from '../../../../types/authorization-data';
import { hasGeneralAccess } from '../../../../lib/utils';
import { TrainingGroupData } from '../../../../types/service';

type Props = {
  serviceStatusId: ServiceStatusEnum;
  onPublishService?: () => void;
  editMode: string | null;
  educationType: EducationTypeEnum;
  isCreate?: boolean;
  isTemplate?: boolean;
  showPublicateButton?: boolean;
  plans?: TrainingGroupData[];
};

type TextType = {
  [key: number]: string | JSX.Element;
};

const descriptionCreate: TextType = {
  [EducationTypeEnum.ChildrenEducation]: `Для публикации детского объединения на Mos.ru необходимо сохранить детское объединение, затем указать адрес 
  предоставления услуги и место приема документов. После этого действие "Опубликовать" станет доступным.`,
  [EducationTypeEnum.DayCareCentersEducation]: `Для публикации программы на Mos.ru необходимо сохранить программу, затем указать адрес 
  предоставления услуги и место приема документов. После этого действие "Опубликовать" станет доступным.`,
};

function joinWithLastSeparator(arr: (string | null)[]): string {
  const separator = '; ';

  if (arr.length <= 1) {
    return arr.join('');
  }

  return `${arr.join(separator)}${separator}`;
}

const PublicationStatus: React.FC<Props> = ({
  plans,
  serviceStatusId,
  editMode,
  educationType,
  isCreate,
  isTemplate,
  showPublicateButton = true,
}) => {
  const { serviceData, updateService, userProfile } = useContext(ServiceContext);
  const [showUnPublishingModal, setShowUnPublicationModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);

  const dispatch = useDispatch();

  const handleOpenSignModal = () => {
    setShowSignModal(true);
  };

  const canSign = useMemo(() => hasGeneralAccess(userProfile, generalAccess.UseSign), [userProfile]);

  const textByEducationType = useMemo(() => {
    switch (educationType) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return 'Детское объединение не опубликовано на Mos.ru. ';
      case EducationTypeEnum.DayCareCentersEducation:
        return (
          <>
            Программа по уходу и присмотру за детьми школьного возраста не опубликована на Mos.ru.
            <br />
          </>
        );
      case EducationTypeEnum.ProfessionalEducation:
        return 'Образовательная программа не опубликована на Mos.ru. ';
      case EducationTypeEnum.ArtHouseEducation:
        return isTemplate ? 'Шаблон услуги не подписан. ' : 'Услуга не подписана. ';
      case EducationTypeEnum.SportEducation:
        return isTemplate ? 'Внимание! Шаблон услуги не опубликован. ' : 'Внимание! Услуга не опубликована. ';
      case EducationTypeEnum.VirtualAssistantEducation:
        return 'Услуга не опубликована. ';
      default:
        return '';
    }
  }, [educationType, isTemplate]);

  const descriptionDefault = useMemo(() => {
    switch (educationType) {
      case EducationTypeEnum.ArtHouseEducation:
        return isTemplate
          ? 'Для публикации шаблона необходимо подписать шаблон ЭП'
          : 'Для публикации услуги на Mos.ru необходимо подписать услугу ЭП.';
      case EducationTypeEnum.SportEducation:
        return isTemplate
          ? 'Для публикации шаблона необходимо нажать кнопку "Подтвердить".'
          : 'Для публикации услуги на Mos.ru необходимо нажать кнопку "Подтвердить".';
      default:
        return 'Завершите публикацию, выполнив действие.';
    }
  }, [educationType, isTemplate]);

  const getControls = () => {
    if (!showPublicateButton) {
      return null;
    }

    switch (educationType) {
      case EducationTypeEnum.ArtHouseEducation:
      case EducationTypeEnum.SportEducation:
        return canSign ? (
          <LmButton
            dataTest="sign"
            type="button"
            size="medium"
            onClick={handleOpenSignModal}
          >
            Подписать
          </LmButton>
        ) : (
          <LmButton
            dataTest="confirm"
            type="button"
            size="medium"
            onClick={() => changeStatus(ServiceStatusEnum.Signed)}
          >
            Подтвердить
          </LmButton>
        );
      default:
        return (
          <LmButton
            dataTest="publish"
            type="button"
            size="medium"
            onClick={() => changeStatus(ServiceStatusEnum.Signed)}
          >
            Опубликовать
          </LmButton>
        );
    }
  };

  const changeStatus = async (serviceStatusId: ServiceStatusEnum) => {
    if (serviceStatusId === ServiceStatusEnum.Signed) {
      let errors: (string | null)[] = [];
      const planError = !plans?.length ? 'Для публикации необходимо добавить план приема' : null;

      if (serviceData.educationTypeId === EducationTypeEnum.ChildrenEducation) {
        const hoursPerWeek = hoursPerWeekFunc(serviceData.info, serviceData.info.hoursPerWeek?.toString(), serviceData);
        const durationOfTraining = durationOfTrainingFunc(serviceData.info, serviceData);
        const altStartValidation = altStartValidationFunc(
          serviceData.info,
          serviceData.info.altStart?.toString(),
          serviceData
        );
        const altEndValidation = altEndValidationFunc(
          serviceData.info,
          serviceData.info.altEnd?.toString(),
          serviceData
        );

        errors = [
          !serviceData.placeService.list.find((placeService) => placeService.isSelected) &&
          serviceData.info.typeOfAttendanceId === 1
            ? 'Необходимо указать адрес проведения занятий'
            : null,
          !serviceData.itemWork.list.find((itemWork) => itemWork.isSelected)
            ? 'Необходимо указать адрес приема документов'
            : null,
          planError,
          hoursPerWeek ? `Часов в неделю: ${hoursPerWeek}` : null,
          durationOfTraining ? `Продолжительность: ${durationOfTraining}` : null,
          altStartValidation ? `Возраст: ${altStartValidation}` : null,
          altEndValidation ? `Возраст: ${altEndValidation}` : null,
        ].filter((a) => a);
      } else if (serviceData.educationTypeId === EducationTypeEnum.DayCareCentersEducation) {
        errors = [
          !serviceData.placeService.list.find((placeService) => placeService.isSelected)
            ? 'Адрес проведения занятий не выбран'
            : null,
          !serviceData.itemWork.list.find((placeService) => placeService.isSelected)
            ? 'Адрес приема документов не выбран'
            : null,
          planError,
        ].filter((a) => a);
      }

      if (errors.length) {
        dispatch(
          notify.danger({
            dataTest: 'changeStatus',
            title: joinWithLastSeparator(errors),
          })
        );

        return;
      }
    }

    if (!isTemplate) {
      await serviceTemplateApi.updateServiceStatus({
        serviceId: serviceData.info.serviceId,
        serviceStatusId,
        educationTypeId: serviceData.info.educationTypeId,
      });
    } else {
      await templateApi.updateTemplateStatus({
        templateId: serviceData.id,
        serviceStatusId,
        educationTypeId: serviceData.info.educationTypeId,
      });
    }

    await updateService();
  };

  const handleSign = async (id: number, sign: string) => {
    const requestData = {
      educationTypeId: serviceData.info.educationTypeId,
      signedDocument: sign,
    };

    try {
      isTemplate
        ? await templateApi.signTemplate(id, {
          ...requestData,
          templateId: id,
        })
        : await serviceTemplateApi.setSignedData(id, {
          ...requestData,
          serviceId: id,
        });
      updateService();
    } catch {}
  };

  const handleOpenUnPublishingModal = () => {
    setShowUnPublicationModal(true);
  };

  const handleCloseUnPublishingModal = () => {
    setShowUnPublicationModal(false);
    changeStatus(ServiceStatusEnum.Draft);
  };

  const handleCloseSignModal = () => {
    setShowSignModal(false);
  };

  return (
    <>
      {serviceStatusId === ServiceStatusEnum.Draft && !editMode && (
        <>
          <Push size={12} />
          <div className="infobox infobox--danger">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <LmIcon
                    icon="outline-misc-clock"
                    color="var(--LM-red-200)"
                    size={18}
                    className="flex-none"
                  />
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  <span className="color-danger-dark">
                    <span className="font-weight-bold">{textByEducationType}</span>
                    {isCreate ? descriptionCreate[educationType] : descriptionDefault}
                  </span>
                </div>
              </div>
              {!isCreate && <div className="infobox__control">{getControls()}</div>}
            </div>
          </div>
        </>
      )}

      {serviceStatusId === ServiceStatusEnum.Signed && !editMode && (
        <>
          <Push size={12} />
          <div className="infobox infobox--primary">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <LmIcon
                    icon="filled-notifications-info"
                    size={18}
                    color="var(--LM-blue-64)"
                    className="flex-none"
                  />
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  <span className="color-text">Для редактирования услуги необходимо снять ее с публикации</span>
                </div>
              </div>
              {!isCreate && (
                <div className="infobox__control">
                  <LmButton
                    dataTest="withdraw"
                    type="button"
                    variant="outline"
                    size="medium"
                    onClick={handleOpenUnPublishingModal}
                  >
                    Снять с публикации
                  </LmButton>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <RemovalFromPublicationModal
        show={showUnPublishingModal}
        onClose={() => setShowUnPublicationModal(false)}
        handleRemoval={handleCloseUnPublishingModal}
      />
      <SignModal
        show={showSignModal}
        onCloseHandle={handleCloseSignModal}
        ids={[
          {
            id: serviceData.id,
          },
        ]}
        getDataForSignHandle={async () => JSON.stringify(serviceData)}
        setSignedDataHandle={handleSign}
      />
    </>
  );
};

export default PublicationStatus;
