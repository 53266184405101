import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import { SearchGroupTrainingInitialData } from '../../../../types/service-class';

type Props = {
  educationTypeData: SelectOptionType[];
};

const DEFAULT_VALUE = {
  label: 'Все',
  value: null,
};

export const FieldEducationType: React.FC<Props> = ({ educationTypeData }) => {
  const { setFieldValue, values } = useFormikContext<SearchGroupTrainingInitialData>();

  const hasOneEducationType = educationTypeData.length <= 1;

  const educationValue = useMemo(() => {
    if (values.educationTypeId) {
      return {
        label: values.educationTypeName || '',
        value: values.educationTypeId,
      };
    } else if (hasOneEducationType && values.educationTypeName !== DEFAULT_VALUE.label) {
      return {
        label: educationTypeData[0]?.label ?? DEFAULT_VALUE.label,
        value: educationTypeData[0]?.value ?? DEFAULT_VALUE.value,
      };
    } else {
      return DEFAULT_VALUE;
    }
  }, [educationTypeData, hasOneEducationType, values.educationTypeId, values.educationTypeName]);

  return (
    <FormikSelect
      label="Тип образования"
      size="small"
      withSearch
      name="educationTypeId"
      options={educationTypeData}
      showTooltip
      disabledPortalTooltip
      disabled={hasOneEducationType && !!educationTypeData.length}
      defaultValue={educationValue}
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('educationTypeName', v.label);
        }
      }}
    />
  );
};
