import React, { useEffect, useState, Fragment } from 'react';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import { object as objectYup } from 'yup';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { ScheduleData, ServiceData, TrainingGroupData } from '../../../../../types/service';
import { getValidationSchemaChildPlan, VOLUME_INFO_MESSAGE } from '../../../utils';
import DateField from '../../fields/add-plan/date';
import TimeField from '../../fields/add-plan/time';
import Separator from './separator';
import { SelectOptionType } from '../../../../../types/entities';
import Popup from '../../../../../components/modals/popup';
import FormikInputNumber from '../../../../../components/formik/formik-input-number';

type ChildPlanModalProps = {
  show: boolean;
  planData: TrainingGroupData | undefined;
  serviceData: ServiceData;
  onClose: () => void;
  postSubmit: (plan?: TrainingGroupData) => void;
};

const ChildPlanModal = ({ show, planData, serviceData, onClose, postSubmit }: ChildPlanModalProps) => {
  const [initialValues, setInitialValues] = useState<TrainingGroupData>({} as TrainingGroupData);
  const initialErrors = useInitialErrors(initialValues, getValidationSchema(serviceData));
  const [formKey, setFormKey] = useState<number>(0);
  const [periodOptions, setPeriodOptions] = useState<SelectOptionType[]>([]);

  const submitTrainingGroup = async (plan: TrainingGroupData | undefined) => {
    if (plan) {
      if (!plan.id || plan.id === 0) {
        if (plan.scheduleList) {
          plan.scheduleList[0].trainingGroupId = 0;
        }

        await serviceTemplateApi.createTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      } else {
        await serviceTemplateApi.updateTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      }
    }
    postSubmit(plan);
  };

  useEffect(() => {
    if (serviceData.stage?.list?.length > 0) {
      setPeriodOptions(
        serviceData.stage.list.map((s) => {
          return {
            label: s.name,
            value: s.id || 0,
          };
        })
      );
    }
  }, [serviceData.stage]);

  useEffect(() => {
    if (planData) {
      setInitialValues(planData);
      setFormKey(Math.random());
    } else {
      const defaultServerDateFormat = (date: Date): string =>
        moment(date).set('hour', 8).set('minute', 0).set('seconds', 0).format('YYYY-MM-DDTHH:mm:ss');

      setInitialValues({
        scheduleList: [
          {
            requestStart: defaultServerDateFormat(new Date()),
            requestTimeStart: '08:00',
            requestTimeEnd: '22:00',
          } as ScheduleData,
        ],
      } as TrainingGroupData);
    }
  }, [planData]);

  const modalTitle = planData?.id ? 'Изменить план приема' : 'Добавить план приема';
  const submitLabel = planData?.id ? 'Сохранить' : 'Добавить';
  const showErrorImmediately = (planData?.id ?? 0) > 0;

  return (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={() => getValidationSchema(serviceData)}
      onSubmit={submitTrainingGroup}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<TrainingGroupData>) => {
        const { handleSubmit, isSubmitting, isValid, values, setFieldValue, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="planPopup"
              open={show}
              title={modalTitle}
              size="large"
              buttonPrimaryText={submitLabel}
              primaryButtonModifiers={{
                disabled: !isValid || isSubmitting,
              }}
              onClearData={resetForm}
              onClose={onClose}
              onSubmit={handleSubmit}
            >
              {periodOptions?.map((e, i) => {
                const max = Math.max(
                  ...serviceData.stage.list.map((e) => {
                    return e.orderNumber;
                  })
                );
                const stageOrder =
                  serviceData.stage.list.find((s) => s.id === planData?.serviceStageId)?.orderNumber ?? max;

                return (
                  <Fragment key={`period_${e.value}`}>
                    {i > 0 ? <Push size={16} /> : ''}
                    <FormikFormGroup
                      name=""
                      label={`Дата начала занятий / Учебный период ${periodOptions.length > 1 ? i + 1 : ''}`}
                      required
                    >
                      <div className="flex items-start">
                        <div
                          className="flex flex-auto"
                          style={{
                            width: '50%',
                          }}
                        >
                          <DateField
                            name={`stageList[${i}].dateStart`}
                            disabled={
                              values.scheduleList?.length > 0 &&
                              values.scheduleList[0].requestTotalCount > 0 &&
                              i < stageOrder
                            }
                            showErrorImmediately={showErrorImmediately}
                          />
                        </div>
                        <Separator />
                        <div
                          className="flex flex-auto"
                          style={{
                            width: '50%',
                          }}
                        >
                          <DateField
                            name={`stageList[${i}].dateEnd`}
                            disabled={
                              values.scheduleList?.length > 0 &&
                              values.scheduleList[0].requestTotalCount > 0 &&
                              i < stageOrder - 1
                            }
                            showErrorImmediately={showErrorImmediately}
                          />
                        </div>
                      </div>
                    </FormikFormGroup>
                  </Fragment>
                );
              })}

              <Push size={16} />
              <FormikSelect
                label="Учебный период, на который ведется прием заявлений"
                name="serviceStageId"
                size="small"
                required
                withSearch
                options={periodOptions}
                placeholder="Выберите..."
                disabled={
                  values.scheduleList?.length > 0 &&
                  values.scheduleList[0].requestTotalCount > 0 &&
                  periodOptions.some((p) => p.value === initialValues.serviceStageId)
                }
                selectedValue={(v) => {
                  v && setFieldValue('serviceStageName', v.label);
                }}
                showErrorImmediately={showErrorImmediately}
              />

              <Push size={16} />
              <FormikFormGroup
                name="volume"
                label="Количество мест"
              >
                <div className="flex items-start">
                  <div
                    style={{
                      width: 88,
                    }}
                  >
                    <FormikInputNumber
                      name="volume"
                      disabled
                      placeholder="0"
                    />
                  </div>
                </div>
              </FormikFormGroup>

              <Push size={8} />
              <LmInfoBox
                dataTest="recalculateInfo"
                className="infobox--full-width"
                variant="warning"
                description={VOLUME_INFO_MESSAGE}
                hidenFooter
              />
              <Push size={16} />
              <FormikFormGroup
                name=""
                label={`Период приема заявлений${
                  serviceData.educationTypeId !== EducationTypeEnum.VirtualAssistantEducation ? ' на Mos.ru' : ''
                }`}
                required
              >
                <div className="flex items-start">
                  <div
                    className="flex flex-auto"
                    style={{
                      width: '50%',
                    }}
                  >
                    <DateField
                      name="scheduleList[0].requestStart"
                      dependentTimeName="scheduleList[0].requestTimeStart"
                      showErrorImmediately={showErrorImmediately}
                      placement="top-start"
                    />
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    <div className="flex-none input-time">
                      <TimeField
                        name="scheduleList[0].requestTimeStart"
                        dependentDateName="scheduleList[0].requestStart"
                      />
                    </div>
                  </div>
                  <Separator />
                  <div
                    className="flex flex-auto"
                    style={{
                      width: '50%',
                    }}
                  >
                    <DateField
                      name="scheduleList[0].requestEnd"
                      dependentTimeName="scheduleList[0].requestTimeEnd"
                      showErrorImmediately={showErrorImmediately}
                      placement="top-start"
                    />
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    <div className="flex-none input-time">
                      <TimeField
                        name="scheduleList[0].requestTimeEnd"
                        dependentDateName="scheduleList[0].requestEnd"
                      />
                    </div>
                  </div>
                </div>
              </FormikFormGroup>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChildPlanModal;

const getValidationSchema = (serviceData: ServiceData) => objectYup().shape(getValidationSchemaChildPlan(serviceData));
