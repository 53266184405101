import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';

type Props = {
  title: string;
  shortDescription: string;
  fullDescription?: string;
};

const Panel: React.FC<Props> = ({ title, shortDescription, fullDescription }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="auth-panel">
      <div className="font-weight-bold">{title}</div>
      <Push size={4} />
      {fullDescription ? (
        <>
          {open ? (
            <span
              dangerouslySetInnerHTML={{
                __html: fullDescription,
              }}
            />
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: shortDescription,
              }}
            />
          )}
          <Push size={8} />
          <button
            type="button"
            onClick={() => setOpen(!open)}
            className="auth-panel__btn"
          >
            {open ? 'Скрыть подробности' : 'Подробнее'}
          </button>
        </>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: shortDescription,
          }}
        />
      )}
    </div>
  );
};

export default Panel;
