import React, { useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Heading, Push } from '@mosru/esz_uikit';
import { LmButton, LmSubHeader, LmToggle } from '@mes-ui/lemma';
import SavePanel from '../../components/save-panel';
import { snilsMask } from '../../lib/utils/mask';
import { emailRegexp } from '../../lib/utils/validation';
import useInitialErrors from '../../hooks/formik-initial-errors';
import FormikFormGroup from '../../components/formik/formik-form-group';
import FormikInput from '../../components/formik/formik-input';
import { useChangeSubscribeMutation, useGetUserEventTypesQuery } from '../../store/event';
import { RoomUserType } from '../password-rules/types/room';
import { PasswordRequirementType } from '../password-rules/types/password';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { authApi } from '../../lib/api';
import { getUserProfile } from '../../redux/ducks/user-profile';
import ChangePasswordModal from './change-password-modal';
import { goBack } from '../../lib/utils';

type Props = {
  requirements?: PasswordRequirementType[];
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUserProfile,
    },
    dispatch
  );

const enhance = connect(mapStateToProps, mapDispatchToProps);

const Room = ({ requirements, getUserProfile }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { data: notifyValues = [], isFetching } = useGetUserEventTypesQuery();
  const [changeSubscribe, { isLoading }] = useChangeSubscribeMutation();

  const userData = useMemo(
    () => ({
      login: userProfile.login,
      email: userProfile.email,
      fullName: userProfile.fullName,
      snils: userProfile.snils,
    }),
    [userProfile]
  );

  const initialErrors = useInitialErrors(userData, getUserValidationSchema());
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={goBack}
        description=""
        title="Управление аккаунтом"
        dataTest="roomSubHeader"
        routes={[]}
      />

      <Formik
        onSubmit={async (v, { resetForm, setSubmitting }) => {
          await authApi.updateProfile(v);
          setSubmitting(false);
          resetForm({
            values: v,
          });
          await getUserProfile();
        }}
        enableReinitialize
        initialValues={userData}
        validationSchema={getUserValidationSchema()}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<RoomUserType>) => {
          const { submitForm, values, isSubmitting, isValid, dirty } = formikProps;

          return (
            <form>
              <Push size={12} />
              <div className="room-panel">
                <div className="container">
                  <Heading label="Данные пользователя и пароль" />
                  <Push size={16} />
                  <div className="table-data">
                    <div className="table-data__item">
                      <FormikFormGroup
                        name=""
                        label="Фамилия"
                        horizontal
                        required
                      >
                        <FormikInput
                          name="fullName.lastName"
                          placeholder="Введите..."
                          size="small"
                        />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup
                        name=""
                        label="Имя"
                        horizontal
                        required
                      >
                        <FormikInput
                          name="fullName.firstName"
                          placeholder="Введите..."
                          size="small"
                        />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup
                        name=""
                        label="Отчество"
                        horizontal
                      >
                        <FormikInput
                          name="fullName.middleName"
                          placeholder="Введите..."
                          size="small"
                        />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup
                        name=""
                        label="СНИЛС"
                        horizontal
                        required
                      >
                        <FormikInput
                          name="snils"
                          maskRegex={snilsMask}
                          placeholder="XXX-XXX-XXX XX"
                          size="small"
                          disabled={!!userData?.snils}
                        />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup
                        name=""
                        label="Email"
                        horizontal
                        required
                      >
                        <FormikInput
                          name="email"
                          placeholder="Введите..."
                          value={values.email}
                          size="small"
                        />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup
                        name=""
                        label="Логин"
                        horizontal
                        required
                      >
                        <FormikInput
                          name="login"
                          placeholder="Введите..."
                          value={values.login}
                          size="small"
                          disabled
                        />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Пароль</div>
                      <div className="table-data__body">
                        <LmButton
                          type="button"
                          variant="outline"
                          size="medium"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Изменить пароль
                        </LmButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Push size={12} />
              <div className="room-panel">
                <div className="container">
                  <Heading label="Настройка уведомлений" />
                </div>

                <Push size={4} />
                <table className="room-notify-table">
                  <tbody>
                    {notifyValues?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <label
                              htmlFor={item.id.toString()}
                              className="room-notify-table__label"
                            >
                              {item.shortName}
                            </label>
                          </td>
                          <td>
                            <LmToggle
                              name={item.id.toString()}
                              dataTest={item.id.toString()}
                              disabled={isLoading || isFetching}
                              onChange={async () => {
                                await changeSubscribe(
                                  notifyValues.reduce((acc: number[], notifyValue) => {
                                    if (
                                      (notifyValue.id === item.id && notifyValue.isSubscribeTo) ||
                                      (notifyValue.id !== item.id && !notifyValue.isSubscribeTo)
                                    ) {
                                      acc.push(notifyValue.id);
                                    }

                                    return acc;
                                  }, [])
                                );
                              }}
                              checked={item.isSubscribeTo}
                            />
                          </td>
                          <td className="room-notify-table__checkbox">
                            <div>{item.isSubscribeTo ? 'вкл' : 'выкл'}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <SavePanel
                hideSecondaryButton
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !dirty || !isValid,
                }}
                onClickPrimaryButton={submitForm}
              />
            </form>
          );
        }}
      </Formik>
      <ChangePasswordModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        requirements={requirements}
      />
    </>
  );
};

const getUserValidationSchema = () =>
  objectYup().shape({
    fullName: objectYup().shape({
      firstName: stringYup().nullable().required('Введите имя'),
      lastName: stringYup().nullable().required('Введите фамилию'),
    }),
    login: stringYup().required('Введите логин'),
    email: stringYup()
      .matches(emailRegexp, {
        message: 'Указан неверный адрес электронной почты',
      })
      .required('Введите адрес электронной почты')
      .nullable(),
    snils: stringYup()
      .matches(/^[0-9]{3,3}-[0-9]{3,3}-[0-9]{3,3} [0-9]{2,2}$/, 'СНИЛС должен содержать 11 цифр')
      .nullable()
      .test('test_snils', 'СНИЛС указан некорректно', (snils: string | null | undefined) => {
        return validateSnils(snils);
      })
      .required('СНИЛС должен содержать 11 цифр'),
  });

function validateSnils(snilsVal: string | null | undefined): boolean {
  if (!snilsVal) {
    return true;
  } // Можно оставлять пустым

  snilsVal = snilsVal.replace(/[^\d]/g, '');
  if (snilsVal.length !== 11) {
    return false;
  }

  if (!checkRepeatedDigits(snilsVal)) {
    return false;
  }

  const crc = parseInt(snilsVal.slice(9), 10);

  snilsVal = `${snilsVal}`;
  let sum = 0;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(snilsVal[i]) * (9 - i);
  }
  if (sum > 101) {
    sum %= 101;
  }
  if (sum === 100 || sum === 101) {
    sum = 0;
  }

  return sum === crc;
}

function checkRepeatedDigits(snilsVal: string): boolean {
  let count = 1;

  for (let i = 0; i < 9; i++) {
    if (i === 0) {
      continue;
    }
    count = snilsVal[i] === snilsVal[i - 1] ? count + 1 : 1;
    if (count >= 3) {
      return false;
    }
  }

  return true;
}

export default enhance(Room);
