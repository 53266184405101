import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import SavePanel from '../../../../components/save-panel';
import { CreateContactPerson } from '../../components/panels/contact-person/create-contact-person';
import { ServiceData } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import history from '../../../../history';
import { routes } from '../../../../config/constants';
import { CreateServiceContext } from '../../create';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import CreateInfo from './create-info';
import CreateFinancing from '../../components/panels/financing/create';
import { getHeaderLink, setGenderStartEndToBack } from '../../utils';
import { getInfoDoValidationSchema } from '../panels/info';
import { financingValidationSchema } from '../../components/panels/financing';

const ServiceChildCreateDetails = () => {
  const { serviceData, educationTypes, type } = useContext(CreateServiceContext);
  const initialErrors = useInitialErrors(serviceData, getValidationSchema());

  const [load, setLoad] = useState<boolean>(false);

  const submitForm = async (values: ServiceData) => {
    setLoad(true);
    try {
      if (values.contactPerson) {
        values.contactPerson.phone = addPhonePrefix(values.contactPerson.phone);
      }

      const serviceId = await serviceTemplateApi.createService({
        ...values,
        info: setGenderStartEndToBack(values.info),
      });

      history.push(`${routes.registerChildEducations}/${serviceId}`);
      setLoad(false);
    } catch (e) {
      setLoad(false);
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={serviceData}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, isValid, values } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />

            <CreateInfo />
            <CreateContactPerson parent="contactPerson" />
            <Push size={12} />
            {values.info?.typeOfAttendanceId === 1 ? (
              <Panel title={() => 'Адрес проведения занятий'}>
                <div className="container">
                  <LmInfoBox
                    dataTest="addressClassWarning"
                    className="infobox--full-width"
                    variant="warning"
                    description="Адрес проведения занятий станет доступным для ввода после первого сохранения."
                    hidenFooter
                  />
                </div>

                <Push size={24} />
              </Panel>
            ) : null}

            <Push size={12} />
            <Panel title={() => 'Учебные периоды'}>
              <div className="container">
                <LmInfoBox
                  dataTest="scheduleWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Редактирование учебных периодов станет доступно после первого сохранения."
                  hidenFooter
                />
              </div>

              <Push size={24} />
            </Panel>

            <Push size={12} />
            <CreateFinancing />

            <Push size={12} />
            <Panel title={() => 'Адреса приема документов'}>
              <div className="container">
                <LmInfoBox
                  dataTest="addressDocumentsWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Адреса приема документов станут доступными для ввода после первого сохранения."
                  hidenFooter
                />
              </div>

              <Push size={24} />
            </Panel>

            <Push size={12} />
            <Panel title={() => 'Планы приема'}>
              <div className="container">
                <LmInfoBox
                  dataTest="planWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Планы приема станут доступными для ввода после первого сохранения."
                  hidenFooter
                />
              </div>
              <Push size={24} />
            </Panel>

            <Push size={12} />
            <Panel title={() => 'Фотографии услуги'}>
              <div className="container">
                <LmInfoBox
                  dataTest="photoWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Возможность добавления фотографий услуги станет доступна после первого сохранения."
                  hidenFooter
                />
              </div>
              <Push size={24} />
            </Panel>

            <SavePanel
              buttonPrimaryText="Создать детское объединение"
              primaryButtonModifiers={{
                loading: load,
                disabled: !isValid,
              }}
              onClickSeconadaryButton={() =>
                history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))
              }
              onClickPrimaryButton={handleSubmit}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceChildCreateDetails;

export const getValidationSchema = () =>
  objectYup().shape({
    info: getInfoDoValidationSchema(),
    contactPerson: contactPersonValidationSchema(),
    financing: financingValidationSchema(),
  });
