import { LmSelectNewProps } from '@mes-ui/lemma';
import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import FormikSelect from '../../../../components/formik/formik-select';
import { SelectOptionType } from '../../../../types/entities';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { serviceTemplateApi } from '../../../../lib/api/service-template';

type Props = Pick<LmSelectNewProps<SelectOptionType>, 'placeholder' | 'explainText'> & {
  values: SearchRequestsInitialFormData;
  label: string;
};

const FieldTrainingGroup: React.FC<Props> = ({ values, label, placeholder, explainText }) => {
  const [trainingGroupData, setTrainingGroupData] = useState<SelectOptionType[]>([]);

  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      setTrainingGroupData(
        await serviceTemplateApi.getTrainingGroups({
          serviceId,
        })
      );
    };

    if (values.serviceId) {
      fetchTrainGroup(values.serviceId);
    } else {
      setTrainingGroupData([]);
    }
  }, [values.serviceId]);

  const [, , helpers] = useField('trainingGroupId');
  const [, , helpersName] = useField('trainingGroupName');

  const onChange = (option?: SelectOptionType) => {
    helpers.setValue(option?.value);
    helpersName.setValue(option?.label);
  };

  return (
    <FormikSelect
      disabled={!values.serviceId}
      name="trainingGroupId"
      label={label}
      size="small"
      options={trainingGroupData}
      defaultValue={
        values.trainingGroupId
          ? {
              label: values.trainingGroupName ?? '',
              value: values.trainingGroupId,
            }
          : null
      }
      placeholder={placeholder}
      explainText={explainText}
      showTooltip
      disabledPortalTooltip
      selectedValue={onChange}
      clearable
    />
  );
};

export default FieldTrainingGroup;
