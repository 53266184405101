import React from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import { Push } from '@mosru/esz_uikit';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import Popup from '../../../../components/modals/popup';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikInput from '../../../../components/formik/formik-input';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ProgrammModule } from '../../../../types/entities';
import lookupApi from '../../../../lib/api/lookup';
import { ServiceData } from '../../../../types/service';

type Props = {
  show: boolean;
  hideModal: () => void;
  module?: ProgrammModule;
  onSubmit: (values: ProgrammModule) => void;
  serviceData: ServiceData;
};

const AddModule: React.FC<Props> = ({ show, hideModal, module, onSubmit, serviceData }) => {
  const initialErrors = useInitialErrors(module, getValidationSchema());

  return (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={
        module ||
        ({
          serviceId: serviceData.id,
          educationTypeId: serviceData.educationTypeId,
        } as unknown as ProgrammModule)
      }
    >
      {(formikProps: FormikProps<any>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="modulePopup"
              open={show}
              title="Модуль образовательной программы"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid,
              }}
              onClearData={resetForm}
              onClose={hideModal}
              onSubmit={handleSubmit}
            >
              <>
                <FormikInput
                  label="Название модуля"
                  required
                  name="name"
                  size="small"
                  placeholder="Введите..."
                />

                <Push size={16} />
                <FormikTextarea
                  label="Описание модуля"
                  maxRows={3}
                  name="description"
                  placeholder="Введите..."
                />

                <Push size={16} />
                <FormikInputNumber
                  label="Количество часов в модуле"
                  required
                  name="learnHours"
                  placeholder="0"
                />

                <Push size={16} />
                <FormikSelect
                  label="Адрес предоставления услуги"
                  required
                  name="addressId"
                  size="small"
                  withSearch
                  options={serviceData.placeService.list.map((address) => ({
                    label: address.fullAddress,
                    value: address.addressId,
                  }))}
                  placeholder="Выберите..."
                />

                <Push size={16} />
                <FormikSelect
                  label="Преподаватель"
                  required
                  name="teacherId"
                  size="small"
                  withSearch
                  loadOptions={async (query) => await lookupApi.getTeachers(query, serviceData.info.organizationId)}
                  options={
                    module?.teacherId && module?.teacherName
                      ? [
                          {
                            value: module.teacherId,
                            label: module.teacherName,
                          },
                        ]
                      : []
                  }
                  placeholder="Начните вводить..."
                  enablePortal
                />
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddModule;

const getValidationSchema = () =>
  objectYup().shape({
    name: stringYup().required('Введите наименование').nullable(),
    learnHours: numberYup().nullable().required('Введите количество часов').min(1, 'Введите количество часов'),
    addressId: stringYup().nullable().required('Выберите адрес предоставления услуги'),
    teacherId: stringYup().nullable().required('Выберите преподавателя'),
  });
