import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { LmActionBarProps, LmLoader, LmPopup, LmPopupBody, LmPopupFooter, LmPopupHeader } from '@mes-ui/lemma';
import { PopupSize as LmPopupSize } from '@mes-ui/lemma/models/modalSize.model';

type PopupClasses = {
  container?: string;
  header?: string;
  body?: string;
};

type PopupSize = 'medium' | 'large' | 'extraLarge';

type PopupProps = Pick<
  LmActionBarProps,
  | 'buttonSecondaryText'
  | 'buttonPrimaryText'
  | 'primaryButtonModifiers'
  | 'secondaryButtonModifiers'
  | 'hideSecondaryButton'
  | 'hidePrimaryButton'
> & {
  dataTest: string;
  open: boolean;
  title?: string;
  description?: string;
  classes?: PopupClasses;
  size?: PopupSize;
  loading?: boolean;
  onClose: (show: boolean) => void;
  onSubmit?: () => void;
  additionalHeader?: ReactNode;
  additionalButtons?: ReactNode;
  hideReturnButton?: boolean;
  onClearData?: () => void;
};

enum WindowWidth {
  Small = 1280,
  Medium = 1512,
  Large = 1920,
}

const Popup: React.FC<PopupProps> = ({
  dataTest,
  open,
  title,
  description,
  children,
  classes,
  size,
  loading,
  onClose,
  onSubmit,
  additionalHeader,
  additionalButtons,
  buttonSecondaryText = 'Отмена',
  buttonPrimaryText = 'Сохранить',
  hideReturnButton = true,
  primaryButtonModifiers,
  secondaryButtonModifiers,
  hidePrimaryButton,
  hideSecondaryButton,
  onClearData,
}) => {
  const [sizePopup, setSizePopup] = useState<string>();

  const handleClose = () => {
    onClose(false);
  };

  const updateSizePopup = useCallback(() => {
    const { clientWidth } = document.documentElement;

    if (size) {
      setSizePopup(`${size}-${clientWidth < WindowWidth.Medium ? 'S' : clientWidth < WindowWidth.Large ? 'M' : 'L'}`);
    } else {
      setSizePopup(undefined);
    }
  }, [size]);

  useEffect(() => {
    !open && onClearData?.();
  }, [open, onClearData]);

  useEffect(() => {
    updateSizePopup();
    window.addEventListener('resize', updateSizePopup);

    return () => {
      window.removeEventListener('resize', updateSizePopup);
    };
  }, [updateSizePopup]);

  return (
    <LmPopup
      dataTest={dataTest}
      className={classes?.container}
      isOpenModal={open}
      onCloseModal={handleClose}
      isOpenOverlay
      size={sizePopup as LmPopupSize}
      popperProps
    >
      <LmPopupHeader
        dataTest={dataTest}
        className={classes?.header}
        title={title}
        description={description}
        hideReturnButton={hideReturnButton}
        onCloseModal={handleClose}
      >
        {additionalHeader}
      </LmPopupHeader>
      <LmPopupBody className={classNames('modal__body', loading && 'flex', classes?.body)}>
        {loading ? (
          <div className="loader-auto">
            <LmLoader view="block" />
          </div>
        ) : (
          children
        )}
      </LmPopupBody>
      {!loading && (
        <LmPopupFooter
          dataTest={dataTest}
          buttonSecondaryText={buttonSecondaryText}
          buttonPrimaryText={buttonPrimaryText}
          primaryButtonModifiers={{
            size: 'medium',
            ...primaryButtonModifiers,
          }}
          secondaryButtonModifiers={{
            size: 'medium',
            ...secondaryButtonModifiers,
          }}
          hideSecondaryButton={hideSecondaryButton}
          hidePrimaryButton={hidePrimaryButton}
          onClickSeconadaryButton={handleClose}
          onClickPrimaryButton={onSubmit}
        >
          {additionalButtons}
        </LmPopupFooter>
      )}
    </LmPopup>
  );
};

Popup.defaultProps = {
  size: 'medium',
};

export default Popup;
