import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { formatDateAndTime } from '../../../../lib/utils/date';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { SignInfoData } from '../../../../types/service';

type Props = {
  sign: SignInfoData;
  serviceStatusId: ServiceStatusEnum;
};

const SignInfo: React.FC<Props> = ({ sign, serviceStatusId }) => {
  const downloadSign = () => {
    const linkSource = `data:text/plain;charset=utf-8,${sign.description}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = sign.fileName;
    downloadLink.click();
  };

  return serviceStatusId === ServiceStatusEnum.Signed && sign ? (
    <>
      <Push size={12} />
      <div className="infobox infobox--success">
        <div className="infobox__head">
          <div className="infobox__body">
            <div className="flex">
              <LmIcon
                icon="filled-edit-checkmark-circle"
                size={18}
                color="var(--LM-green-100)"
                className="flex-none"
              />
              <Push
                size={8}
                orientation="horizontal"
              />
              Пользователь: {sign.signer}. Дата: {formatDateAndTime(sign.date)}. Файл:{' '}
              <button
                type="button"
                className="icon-group__text color-primary"
                onClick={downloadSign}
              >
                Подписание услуги
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default SignInfo;
