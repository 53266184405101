import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmBadgeStatus, LmTooltip } from '@mes-ui/lemma';
import { deadlineExpired, formattingDocumentNumber } from '../../../../../lib/utils/requests';
import { getRequestStatusColor } from '../../../../../lib/utils/dictionary';
import { RequestStatusEnum } from '../../../../../mock-data/request-status-enum';
import { generateLink } from '../../../../../lib/utils';
import { routes } from '../../../../../config/constants';

type Props = {
  id: number;
  documentDate: string;
  documentNumber: string;
  enrollmentDate: string;
  actionDeadline: string;
  requestStatusId: number;
  requestStatusName: string;
};

const RequestStatusName: React.FC<Props> = ({
  id,
  enrollmentDate,
  actionDeadline,
  requestStatusName,
  requestStatusId,
  documentNumber,
  documentDate,
}) => {
  const [second, setSecond] = useState(0);

  useEffect(() => {
    // Хак для пересчета позиции тултипа
    setTimeout(() => setSecond(1), 150);
  }, []);

  const addTagsExpired = () => (
    <span>
      Ожидание прихода заявителя <br /> для заключения договора
    </span>
  );

  return (
    <div
      key={second}
      className="flex items-start justify-between"
    >
      <div className="flex flex-column">
        {actionDeadline ? (
          <LmTooltip
            withArrow
            placement="left"
            content={addTagsExpired()}
          >
            {!!second && (
              <div>
                <LmBadgeStatus
                  dataTest="requestStatus"
                  {...getRequestStatusColor(requestStatusId)}
                >
                  {deadlineExpired(requestStatusName)}
                </LmBadgeStatus>
              </div>
            )}
          </LmTooltip>
        ) : (
          <div>
            <LmBadgeStatus
              dataTest="requestStatus"
              {...getRequestStatusColor(requestStatusId)}
            >
              {requestStatusName}
            </LmBadgeStatus>
          </div>
        )}

        {actionDeadline && (
          <>
            <Push
              orientation="vertical"
              size={6}
            />
            {second ? (
              <div>
                <LmTooltip
                  withArrow
                  key={second}
                  placement="top"
                  content={actionDeadline}
                >
                  <LmIcon
                    icon="outline-notifications-alert-triangle"
                    size={20}
                    color="var(--LM-red-200)"
                    className="flex-none"
                  />
                </LmTooltip>
              </div>
            ) : (
              ''
            )}
          </>
        )}
        <Push size={4} />
        {requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded ? (
          <div className="color-gray-dark">{enrollmentDate}</div>
        ) : (
          ''
        )}
        <div className="color-gray-dark">{formattingDocumentNumber(documentNumber, documentDate, requestStatusId)}</div>
      </div>
      <Link
        to={generateLink(routes.request, {
          id,
        })}
      >
        <LmIcon
          icon="outline-arrows-material-forward"
          size={24}
          color="var(--LM-neutrals-day-700)"
        />
      </Link>
    </div>
  );
};

export default RequestStatusName;
