import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ChildForm from './child';
import Organization from './organization';
import ApplicantForm from './applicant';
import Info from './info';
import ActionsForm from './actions';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';

type DetailsProps = {
  requestData: RequestData;
  updateRequest: () => void;
};

const Details = ({ requestData, updateRequest }: DetailsProps) => {
  const [editMode, setEditMode] = useState<string | null>(null);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const editable = useMemo(
    () =>
      (requestData.requestStatusId === RequestStatusEnum.UnderConsideration ||
        requestData.requestStatusId === RequestStatusEnum.ApplicantRefused) &&
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasAccessObjectAny(userProfile, [accessObject.RequestVA], accessAction.Edit)),
    [userProfile, requestData.requestStatusId]
  );

  return (
    <>
      {(!editMode || editMode === 'child') && (
        <ChildForm
          requestChild={requestData.child}
          contingentLinkTypeId={requestData.contingentLinkTypeId}
          setEditModeParent={setEditMode}
          editable={editable}
          updateRequest={updateRequest}
        />
      )}
      {!editMode && <Organization data={requestData} />}
      {(!editMode || editMode === 'applicant') && (
        <ApplicantForm
          requestApplicant={requestData.applicant}
          setEditModeParent={setEditMode}
          editable={editable}
          updateRequest={updateRequest}
        />
      )}
      {!editMode && <Info data={requestData} />}
      {!editMode && requestData.requestStatusId !== RequestStatusEnum.New && (
        <ActionsForm
          contingentGuid={requestData.contingentGuid}
          enrollment={requestData.enrollment}
          decline={requestData.decline}
          requestStatusId={requestData.requestStatusId}
          serviceId={requestData.trainingGroup?.serviceId}
          requestId={requestData.id}
          child={requestData.child}
          updateRequest={updateRequest}
        />
      )}
    </>
  );
};

export default Details;
