import React from 'react';
import { useFormikContext } from 'formik';
import FormikInput from '../../../../../../components/formik/formik-input';
import { checkMaskDocNumber } from '../../../../../../lib/utils/validation';
import FormikDatePicker from '../../../../../../components/formik/formik-datepicker';
import { Learner } from '../../../../../../types/learners';
import { DocumentTypeEnum } from '../../../../../../mock-data/type-document';
import { getSelectedDate } from '../../../../../../lib/utils/date';

type Props = {
  index: number;
  editMode: boolean;
};

export const DocSeriesNumberDate = ({ index, editMode }: Props) => {
  const { values } = useFormikContext<Learner.Info>();
  const fieldName = `pupilDocument[${index}]`;
  const pupilDocument = values.pupilDocument[index];

  const isBirthRecord = pupilDocument?.documentTypeId === DocumentTypeEnum.BirthRecord;

  const label = isBirthRecord ? 'Номер, дата выдачи' : 'Серия, номер, дата выдачи';

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        {label} {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        <div className="table-data-grid-3">
          {editMode ? (
            <>
              {!isBirthRecord && (
                <FormikInput
                  size="small"
                  placeholder="Введите серию"
                  name={`${fieldName}.docSeries`}
                  showErrorImmediately
                />
              )}
              <FormikInput
                maskRegex={!isBirthRecord && checkMaskDocNumber(pupilDocument?.documentTypeId as number)}
                size="small"
                placeholder="Введите номер..."
                name={`${fieldName}.docNumber`}
                showErrorImmediately
              />
              <FormikDatePicker
                size="small"
                name={`${fieldName}.docDate`}
                selectedDay={getSelectedDate(pupilDocument.docDate)}
                showErrorImmediately
              />
            </>
          ) : (
            <>
              {!isBirthRecord && (
                <div className="table-data__group">
                  <div className="table-data__label">Серия</div>
                  <div className="table-data__body">{pupilDocument?.docSeries || '—'}</div>
                </div>
              )}
              <div className="table-data__group">
                <div className="table-data__label">Номер</div>
                <div className="table-data__body">{pupilDocument?.docNumber || '—'}</div>
              </div>
              <div className="table-data__group">
                <div className="table-data__label">Дата выдачи</div>
                <div className="table-data__body">
                  {pupilDocument?.docDate ? (
                    pupilDocument.docDate.toLocaleDateString('ru', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                    })
                  ) : (
                    <span className="color-gray">Не указано</span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
