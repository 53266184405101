import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { LmSelectNew } from '@mes-ui/lemma';
import Statement from './statement';
import { Learner } from '../../../../types/learners';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { filterEducations } from '../../../../lib/utils/education';
import learnerApi from '../../../../lib/api/learner';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { LoaderCustom } from '../../../../components/loader-custom';
import { EducationTypeEnum, EducationTypeEnumDescription } from '../../../../types/education-type';

type Props = {
  pupilId: string;
};

const ListStatements: React.FC<Props> = ({ pupilId }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [loading, setLoading] = useState<boolean>(true);
  const [filterStatements, setFilterStatements] = useState<Learner.ListStatementsProps[]>([]);

  const [listStatementsAll, setListStatementsAll] = useState<Learner.ListStatementsProps[]>([]);
  const [statusFilterEducation, setStatusFilterEducation] = useState<SelectOptionType>();

  const filterStatus = useMemo(
    () => filterEducations(userProfile.objectAccessActionList, [], undefined, accessObject.Pupils, accessAction.View),
    [userProfile.objectAccessActionList]
  );

  useEffect(() => {
    (async () => {
      const list = await learnerApi.getListRequest({
        id: pupilId,
      });

      setListStatementsAll(list);
      setFilterStatements(list);
      setLoading(false);
    })();
  }, [pupilId]);

  const filterEducation = useCallback(
    (val: SelectOptionType) => {
      if (
        val.value !== EducationTypeEnum.All &&
        val.label !== EducationTypeEnumDescription.get(EducationTypeEnum.All)
      ) {
        const items = listStatementsAll.filter((item) => item.educationTypeId === val.value);

        setFilterStatements(items);
      } else {
        setFilterStatements(listStatementsAll);
      }
    },
    [listStatementsAll]
  );

  useEffect(() => {
    filterEducation(filterStatus[0]);
  }, [filterEducation, filterStatus]);

  useEffect(() => {
    if (filterStatus.length) {
      setStatusFilterEducation(filterStatus[0]);
    }
  }, [filterStatus]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            История обучения
            <span className="color-gray-dark">
              {' \u00A0'}
              {filterStatements.length > 0 && filterStatements.length}
            </span>
          </>
        )}
        headingControl={() => (
          <div className="learner-history-select-types">
            <LmSelectNew
              dataTest="selectFilterEducation"
              name="filterEducation"
              onChange={(selectedOption: SelectOptionType | null) => {
                const option = selectedOption as SelectOptionType;

                setStatusFilterEducation(option);
                filterEducation(option);
              }}
              disabled={filterStatus.length === 1}
              options={filterStatus}
              value={statusFilterEducation || null}
              withSearch
              size="small"
              placeholder="Выберите..."
              grouped={false}
              multiple={false}
              settingDropdownAsPopover={{
                disablePortal: true,
              }}
            />
          </div>
        )}
      >
        {loading ? (
          <LoaderCustom size={20} />
        ) : filterStatements.length > 0 ? (
          <ul className="learner-history-list">
            {filterStatements.map((item: Learner.ListStatementsProps) => {
              return (
                <Statement
                  key={item.serviceId + Math.random()}
                  history={item.history}
                  requestId={item.requestId}
                  dateRequest={item.dateRequest}
                  serviceName={item.serviceName}
                  serviceId={item.serviceId}
                  startDate={item.startDate}
                  contractId={item.contractId}
                  contractNumber={item.contractNumber}
                  requestSource={item.requestSource}
                  requestNumber={item.requestNumber}
                  requestStatus={item.requestStatus}
                  organizationName={item.organizationName}
                  educationTypeId={item.educationTypeId}
                  educationTypeName={item.educationTypeName}
                />
              );
            })}
          </ul>
        ) : (
          <div className="learner-no-data">Сведения по истории заявлений обучающегося отсутствуют</div>
        )}
      </Panel>
    </>
  );
};

export default ListStatements;
