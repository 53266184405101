import { useContext, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import Dialog from '../../../../components/modals/dialog';
import { ServiceClassContext } from '../../service-class';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const AddArchiveModal = ({ show, onCloseHandler }: Props) => {
  const { updateServiceClass, addArchive } = useContext(ServiceClassContext);
  const [loading, setLoading] = useState<boolean>(false);

  const addArchiveServiceClass = async () => {
    setLoading(true);
    try {
      addArchive && (await addArchive());
      onCloseHandler();
      updateServiceClass();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <Dialog
      dataTest="archiveServiceClass"
      isOpenDialog={show}
      title="Отправка группы обучения в архив"
      description={
        <>
          <Push
            orientation="vertical"
            size={15}
          />
          <div>Сведениям о группе обучения будет присвоен статус Архив. Продолжить?</div>
          <Push
            orientation="vertical"
            size={15}
          />
        </>
      }
      variant="question"
      buttonSecondaryText="Нет"
      buttonPrimaryText="Да"
      primaryButtonModifiers={{
        loading,
      }}
      secondaryButtonModifiers={{
        disabled: loading,
      }}
      onClickSeconadaryButton={onCloseHandler}
      onClickPrimaryButton={addArchiveServiceClass}
    />
  );
};

export default AddArchiveModal;
