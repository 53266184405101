import { LmIcon } from '@mes-ui/lemma';
import React from 'react';

type InfoboxColor = 'success' | 'warning' | 'danger' | 'primary';

type InfoboxProps = {
  dataTest: string;
  color: InfoboxColor;
  title?: JSX.Element | string;
  description?: JSX.Element | string;
  controls?: JSX.Element;
  body?: JSX.Element;
};

const Informer = ({ color, title, body, description, controls, dataTest }: InfoboxProps) => {
  const getIcon = () => {
    switch (color) {
      case 'danger':
        return (
          <LmIcon
            icon="filled-notifications-alert-triangle"
            size={20}
            color="var(--LM-red-100)"
            className="flex-none"
          />
        );
      case 'success':
        return (
          <LmIcon
            icon="filled-edit-checkmark-circle"
            size={20}
            color="var(--LM-green-100)"
            className="flex-none"
          />
        );
      case 'warning':
        return (
          <LmIcon
            icon="filled-notifications-alert-circle"
            size={20}
            color="var(--LM-orange-100)"
            className="flex-none"
          />
        );
      default:
        return (
          <LmIcon
            icon="filled-notifications-info"
            size={20}
            color="var(--LM-indigo-100)"
            className="flex-none"
          />
        );
    }
  };

  return (
    <div
      className={`informer informer--${color}`}
      data-test={`${dataTest}-informer`}
    >
      <div className="informer__head">
        <div
          className="informer__title"
          data-test={`${dataTest}-informerTitle`}
        >
          <div
            className="informer__icon"
            data-test={`${dataTest}-informerIcon`}
          >
            {getIcon()}
          </div>
          <div className="informer__text-wrap">
            {title && (
              <div
                className="informer__text informer__text--bold"
                data-test={`${dataTest}-informerText`}
              >
                {title}
              </div>
            )}
            {description && (
              <div
                className="informer__text"
                data-test={`${dataTest}-informerDescription`}
              >
                {description}
              </div>
            )}
          </div>
        </div>
        {controls && (
          <div
            className="informer__controls"
            data-test={`${dataTest}-informerControls`}
          >
            {controls}
          </div>
        )}
      </div>
      {body && (
        <div
          className="informer__body"
          data-test={`${dataTest}-informerBody`}
        >
          {body}
        </div>
      )}
    </div>
  );
};

export default Informer;
