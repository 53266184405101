import React, { useEffect, useState } from 'react';
import { LmButton } from '@mes-ui/lemma';
import { ProgrammModule } from '../../types/entities';
import { serviceTemplateApi } from '../../lib/api/service-template';
import Field from '../fields/field';
import { EducationTypeEnum } from '../../types/education-type';
import { getServiceEditLink } from '../../lib/utils';
import Popup from '../modals/popup';

type Props = {
  show: boolean;
  hideModal: () => void;
  moduleId?: number;
  serviceId?: number;
  serviceName: string;
};

const Module: React.FC<Props> = ({ show, hideModal, moduleId, serviceId, serviceName }) => {
  const [module, setModule] = useState<ProgrammModule>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        if (moduleId && serviceId) {
          const data = await serviceTemplateApi.getProgrammModule(moduleId, serviceId);

          setModule(data);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, [moduleId, serviceId]);

  const handleClearData = () => {
    setModule(undefined);
  };

  return (
    <Popup
      dataTest="modulePopup"
      open={show}
      title="Модуль образовательной программы"
      loading={isLoading}
      onClearData={handleClearData}
      onClose={hideModal}
      onSubmit={hideModal}
      hideSecondaryButton
      hidePrimaryButton
      additionalButtons={
        <LmButton
          type="button"
          variant="outline"
          onClick={hideModal}
        >
          Закрыть
        </LmButton>
      }
    >
      <Field
        label="Образовательная программа"
        value={
          <span
            className="text-decoration-underline cursor-pointer brand-link"
            onClick={() =>
              window.open(getServiceEditLink(EducationTypeEnum.ProfessionalEducation, module?.serviceId), '_blank')
            }
          >
            {serviceName}
          </span>
        }
      />
      <Field
        label="Название модуля"
        value={module?.name}
      />
      <Field
        label="Описание модуля"
        value={module?.description}
      />
      <Field
        label="Количество часов в модуле"
        value={module?.learnHours}
      />
      <Field
        label="Адрес предоставления услуги"
        value={module?.fullAddress}
      />
      <Field
        label="Преподаватель"
        value={module?.teacherName}
      />
    </Popup>
  );
};

export default Module;
