import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

type Props = {
  values: SearchRequestsInitialFormData;
  isArtHouseSportEducation: boolean;
  label: string;
  placeholder: string;
};

const FieldYearOfTraining: React.FC<Props> = ({ values, isArtHouseSportEducation, label, placeholder }) => {
  const [yearOfTrainingData, setYearOfTrainingData] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setYearOfTrainingData([
        {
          value: '',
          label: 'Все',
        },
        ...(await dictionariesApi.getYearOfTrainings()),
      ]);
    };

    fetch();
  }, []);

  const [, , helpers] = useField('yearOfTrainingId');
  const [, , helpersName] = useField('yearOfTrainingName');

  const onChange = (option?: SelectOptionType) => {
    helpers.setValue(option?.value);
    helpersName.setValue(option?.label);
  };

  return isArtHouseSportEducation ? (
    <FormikSelect
      name="yearOfTrainingId"
      label={label}
      size="small"
      options={yearOfTrainingData}
      defaultValue={
        values.yearOfTrainingId
          ? {
              label: values.yearOfTrainingName ?? '',
              value: values.yearOfTrainingId,
            }
          : null
      }
      placeholder={placeholder}
      selectedValue={onChange}
    />
  ) : null;
};

export default FieldYearOfTraining;
