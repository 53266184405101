import moment from 'moment';
import React, { Reducer, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, Panel, Push } from '@mosru/esz_uikit';
import { LmButton, LmListItem, LmSelectNew, LmDatePicker, LmIcon } from '@mes-ui/lemma';
import DropDown from '../../../../components/drop-down';
import TableOptions from '../../../../components/table-options';
import { getSelectedDate } from '../../../../lib/utils/date';
import { PlaceOfWork, Teacher } from '../../../../types/teacher';
import teacherApi from '../../../../lib/api/teacher';
import lookupApi from '../../../../lib/api/lookup';
import { TeacherContext } from '..';
import { isError } from '../helpers';
import { hasGeneralAccess } from '../../../../lib/utils';
import { generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import organizationApi from '../../../../lib/api/organization';
import { LoaderCustom } from '../../../../components/loader-custom';
import SimpleTable from '../../../../components/table/simple-table';
import { SelectOptionType } from '../../../../types/entities';

const emptyPlaceOfWorks: PlaceOfWorkId = {
  id: '0',
  finishDate: '',
  isArchive: false,
  organizationId: 0,
  organizationName: '',
  personId: null,
  personPositionId: 0,
  personPositionName: '',
  startDate: '',
};

type Props = {
  values: Teacher;
  placeOfWork: PlaceOfWork[] | undefined;
  setPlaceOfWork: (value: PlaceOfWork[]) => void;
  isAcceptedEdit: boolean;
};

type PlaceOfWorkId = PlaceOfWork & { id: string };
type TypeDate = Date | Date[] | undefined;

type ValidationDate = {
  startDate: string;
  finishDate: string;
  compareOrganization: boolean;
};

function filterTableData(placeOfWork: PlaceOfWork[] | undefined) {
  if (!placeOfWork) {
    return [];
  }

  return placeOfWork.map((item) => {
    return {
      id: `${item.organizationId}${item.personId}${Math.random()}`,
      ...item,
    };
  });
}

const textError = {
  minDate: 'Введённая дата является слишком маленькой',
  maxDate: 'Введённая дата является слишком большой',
  startDate: 'Дата начала не может быть позже даты окончания',
  finishDate: 'Дата окончания не может быть раньше даты начала',
};

const PlaceOfWorks = ({ values, isAcceptedEdit, placeOfWork, setPlaceOfWork }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { loading } = useContext(TeacherContext);

  const [editObj, setEditObj] = useState<PlaceOfWorkId | null>(null);

  const [tableData, setTableData] = useState<PlaceOfWorkId[]>(filterTableData(placeOfWork));

  const [organization, setOrganization] = useState<SelectOptionType | null>({
    label: '',
    value: 0,
  });

  const [validate, dispatch] = useReducer<Reducer<ValidationDate, Partial<ValidationDate>>>(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      startDate: '',
      finishDate: '',
      compareOrganization: true,
    }
  );
  const disabledNewPlaceWork = tableData.every((item) => Number(item.id) > 0);

  const isNameEmpty = !(values.firstName && values.lastName);

  const filterTable = () =>
    setTableData(filterTableData(tableData.filter((item: PlaceOfWorkId) => Number(item.id) > 0)));

  // При отсутствии условий AdminEdit, OIV_dep предзаполняется наименованием организации, к которой прикреплен пользователь без возможности редактирования.
  const access =
    !hasGeneralAccess(userProfile, generalAccess.AdminEdit) &&
    !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);

  useEffect(() => {
    const fetch = async () => {
      if (userProfile.organizationId) {
        const response = await organizationApi.getShortOrganization(userProfile.organizationId);

        if (isAcceptedEdit) {
          setOrganization({
            label: response?.organizationName,
            value: userProfile.organizationId,
          });
        } else {
          setOrganization(null);
        }
      }
    };

    fetch();
  }, [isAcceptedEdit, userProfile.organizationId]);

  const getError = () => {
    isError();
    filterTable();
  };

  const newPlaceOfWork = async (value: any) => {
    if (placeOfWork) {
      const allOrganization = [...placeOfWork, value];

      if (isNameEmpty) {
        getError();
      } else {
        await teacherApi.addTeacherPosition(values.id, value);
        setTableData(filterTableData(allOrganization));
        setPlaceOfWork(allOrganization);
      }
    }
  };

  const updatePlaceOfWork = async (delPlaceWork: PlaceOfWork, value: PlaceOfWork) => {
    const searchOrganization = tableData.find((item: PlaceOfWork) => {
      if (delPlaceWork.personPositionId === 0) {
        return null;
      } else {
        return (
          item.personPositionId === delPlaceWork.personPositionId && item.organizationId === delPlaceWork.organizationId
        );
      }
    });

    if (searchOrganization) {
      const restPlaces =
        placeOfWork?.filter(
          (item: PlaceOfWork) =>
            item.personPositionId !== delPlaceWork.personPositionId ||
            item.organizationId !== delPlaceWork.organizationId
        ) ?? [];
      const allOrganization = [...restPlaces, value];

      if (isNameEmpty) {
        getError();
      } else {
        await teacherApi.deleteTeacherPosition(values.id, delPlaceWork);
        await teacherApi.addTeacherPosition(values.id, value);
        setTableData(filterTableData(allOrganization));
        setPlaceOfWork(allOrganization);
      }
    } else {
      newPlaceOfWork(value);
    }
  };

  const deletePlaceWork = async (delPlaceWork: PlaceOfWork) => {
    if (isNameEmpty) {
      getError();
    } else {
      await teacherApi.deleteTeacherPosition(values.id, delPlaceWork);
      const restPlaces =
        placeOfWork?.filter(
          (item: PlaceOfWork) =>
            item.personPositionId !== delPlaceWork.personPositionId ||
            item.organizationId !== delPlaceWork.organizationId
        ) ?? [];

      setTableData(filterTableData(restPlaces));
      setPlaceOfWork(restPlaces);
    }
  };

  const onChangeDate = (value: TypeDate, name: 'startDate' | 'finishDate') => {
    if (!value) {
      dispatch({
        [name]: '',
      });
      name === 'startDate'
        ? dispatch({
          finishDate: '',
        })
        : dispatch({
          startDate: '',
        });

      setEditObj((prevState: PlaceOfWorkId | null) =>
        prevState
          ? {
              ...prevState,
              [name]: value ?? null,
            }
          : prevState
      );
    }

    if (value && !Array.isArray(value)) {
      const minDate = moment(value).isAfter('1910-01-01');
      const maxDate = moment('2050-01-02').isAfter(value);

      const condition = minDate && maxDate;

      const date = moment(value).format();

      setEditObj((prevState: PlaceOfWorkId | null) =>
        prevState
          ? {
              ...prevState,
              [name]: date,
            }
          : {
              ...emptyPlaceOfWorks,
              [name]: date,
            }
      );

      const startDate = moment(editObj?.startDate);
      const finishDate = moment(editObj?.finishDate);

      const val = moment(value);

      if (name === 'startDate') {
        if (!condition) {
          if (!minDate) {
            dispatch({
              startDate: textError.minDate,
            });
          } else if (!maxDate) {
            dispatch({
              startDate: textError.maxDate,
            });
          }
        } else {
          const check = !editObj?.finishDate || (moment(finishDate).isAfter(val) && !moment(finishDate).isSame(val));

          dispatch({
            startDate: check ? '' : textError.startDate,
          });

          if (moment(val).isBefore(finishDate)) {
            dispatch({
              finishDate: '',
            });
          }
        }
      }

      if (name === 'finishDate') {
        if (!condition) {
          if (!minDate) {
            dispatch({
              finishDate: textError.minDate,
            });
          } else if (!maxDate) {
            dispatch({
              finishDate: textError.maxDate,
            });
          }
        } else {
          const check = !editObj?.startDate || (moment(startDate).isBefore(val) && !moment(startDate).isSame(val));

          dispatch({
            finishDate: check ? '' : textError.finishDate,
          });

          if (moment(val).isAfter(startDate)) {
            dispatch({
              startDate: '',
            });
          }
        }
      }
    }
  };

  const submit = (item: PlaceOfWorkId) => {
    if (editObj) {
      if ((editObj.organizationId || organization?.value) && editObj.personPositionId) {
        const send = {
          ...editObj,
          id: item.id,
          organizationId: access ? organization?.value : editObj.organizationId,
          organizationName: access ? organization?.label : editObj.organizationName,
          isEditable: false,
        };

        // @ts-ignore
        updatePlaceOfWork(item, send);
      } else {
        filterTable();
      }
      setEditObj(null);
    }
  };

  const isDisabledSubmit = () => {
    const org = access ? organization?.value : editObj?.organizationId;

    return !(
      org &&
      editObj?.personPositionId &&
      !validate.startDate &&
      !validate.finishDate &&
      validate.compareOrganization
    );
  };

  const selectOrganization = (item: PlaceOfWork) => {
    if (access && organization) {
      return {
        label: organization.label,
        value: organization.value,
      };
    } else {
      if (!editObj?.organizationName) {
        return null;
      }

      if (editObj?.organizationId) {
        return {
          label: editObj?.organizationName,
          value: editObj?.organizationId || 0,
        };
      } else {
        return {
          label: item?.organizationName,
          value: item?.organizationId,
        };
      }
    }
  };

  const selectPosition = (item: PlaceOfWork) => {
    const personPositionId = editObj?.personPositionId;

    const labelValue = (element: PlaceOfWork) => ({
      label: element?.personPositionName,
      value: element?.personPositionId || 0,
    });

    if (!personPositionId) {
      return null;
    }

    if (editObj && personPositionId) {
      return labelValue(editObj);
    }

    return labelValue(item);
  };

  const newPlace = () => {
    const newData: PlaceOfWorkId = {
      id: '0',
      isArchive: false,
      organizationId: 0,
      personPositionId: 0,
      organizationName: '',
      personPositionName: '',
      isEditable: true,
    };

    dispatch({
      finishDate: '',
      startDate: '',
    });
    const newTableData = new Array(newData).concat(tableData);

    setTableData(newTableData);
    setEditObj(newData);
  };

  const handleEdit = (value?: PlaceOfWorkId) => {
    setEditObj(value || null);
    filterTable();
    dispatch({
      finishDate: '',
      startDate: '',
    });
    dispatch({
      compareOrganization: true,
    });
  };

  const validateOrganization = useCallback(
    (positionId, organizationId) => {
      // нельзя выбрать одинаковую должность в одной организации
      const data = editObj === null ? tableData : [...tableData, editObj];
      const checkMatchesPerson = data.filter(
        (item: PlaceOfWork) => item.organizationId === organizationId && item.personPositionId === positionId
      );

      if (checkMatchesPerson.length) {
        dispatch({
          compareOrganization: false,
        });
      } else {
        dispatch({
          compareOrganization: true,
        });
      }
    },
    [editObj, tableData]
  );

  return (
    <Panel
      title={() => (
        <>
          Места работы
          <span className="color-gray-dark">
            {' \u00A0'}
            {tableData.length}
          </span>
        </>
      )}
      headingControl={() =>
        isAcceptedEdit ? (
          <button
            type="button"
            disabled={!disabledNewPlaceWork}
            onClick={() => newPlace()}
            className="icon-group"
          >
            <span className="icon-group__icon">
              <LmIcon
                icon="filled-edit-plus"
                size={20}
                color="var(--LM-blue-200)"
              />
            </span>
            <span className="icon-group__text color-primary">
              <b>Добавить место работы</b>
            </span>
          </button>
        ) : null
      }
    >
      {loading ? (
        <LoaderCustom size={20} />
      ) : tableData.length ? (
        <SimpleTable
          dataTest="placeOfWorks"
          data={tableData}
          columns={[
            {
              dataIndex: 'organizationName',

              title: 'Организация',
              width: '30%',
              render: (item: any) =>
                editObj?.id === item.id ? (
                  <LmSelectNew
                    dataTest="selectOrganization"
                    name="organization"
                    size="small"
                    withSearch
                    disabled={access}
                    placeholder="Организация..."
                    getFilterOptions={async (query) => await lookupApi.getOrganization(query)}
                    options={[]}
                    value={selectOrganization(item)}
                    onChange={(option: SelectOptionType | null) => {
                      const value = (option && option.value) || null;
                      const label = (option && option.label) || null;

                      setEditObj((prev: any) => {
                        const next = prev
                          ? {
                              ...prev,
                            }
                          : emptyPlaceOfWorks;

                        next.organizationId = value;
                        next.organizationName = label;

                        return next;
                      });
                      validateOrganization(editObj?.personPositionId, value);
                    }}
                    grouped={false}
                    multiple={false}
                    settingDropdownAsPopover={{
                      disablePortal: true,
                    }}
                  />
                ) : (
                  item.organizationName
                ),
            },
            {
              dataIndex: 'personPositionName',
              title: 'Должность',
              width: '20%',
              render: (item: any) =>
                editObj?.id === item.id ? (
                  <FormGroup
                    error={
                      !validate.compareOrganization
                        ? 'Данное рабочее место уже есть в списке рабочих мест преподавателя'
                        : ''
                    }
                    label=""
                  >
                    <LmSelectNew
                      dataTest="selectPersonPosition"
                      name="personPosition"
                      size="small"
                      withSearch
                      placeholder="Должность..."
                      getFilterOptions={async (query) => await lookupApi.getPersonPosition(query)}
                      options={[]}
                      value={selectPosition(item)}
                      onChange={(option: SelectOptionType | null) => {
                        const value = (option && option.value) || null;
                        const label = (option && option.label) || null;

                        setEditObj((prev: any) => {
                          const next = prev
                            ? {
                                ...prev,
                              }
                            : emptyPlaceOfWorks;

                          next.personPositionId = value;
                          next.personPositionName = label;

                          return next;
                        });
                        validateOrganization(value, editObj?.organizationId);
                      }}
                      grouped={false}
                      multiple={false}
                      settingDropdownAsPopover={{
                        disablePortal: true,
                      }}
                    />
                  </FormGroup>
                ) : (
                  item.personPositionName
                ),
            },
            {
              dataIndex: 'startDate',
              title: 'Дата начала ',

              width: '20%',
              render: (item: any) =>
                editObj && editObj?.id === item.id ? (
                  <LmDatePicker
                    dataTest="startDate"
                    name="startDate"
                    inputSize="small"
                    fullWidth
                    isError={!!validate.startDate}
                    explainText={validate.startDate || undefined}
                    placeholder="ДД.ММ.ГГГГ"
                    selectedDay={getSelectedDate(editObj.startDate)}
                    onChangeDate={(value) => onChangeDate(value, 'startDate')}
                    disablePortal
                  />
                ) : item.startDate ? (
                  moment(item.startDate).format('DD.MM.yyyy')
                ) : (
                  ''
                ),
            },
            {
              dataIndex: 'finishDate',
              title: 'Дата окончания ',

              width: '20%',
              render: (item: any) =>
                editObj && editObj?.id === item.id ? (
                  <LmDatePicker
                    dataTest="finishDate"
                    name="finishDate"
                    fullWidth
                    inputSize="small"
                    placeholder="ДД.ММ.ГГГГ"
                    isError={!!validate.finishDate}
                    explainText={validate.finishDate || undefined}
                    selectedDay={getSelectedDate(editObj.finishDate)}
                    onChangeDate={(value) => onChangeDate(value, 'finishDate')}
                    disablePortal
                  />
                ) : item.finishDate ? (
                  moment(item.finishDate).format('DD.MM.yyyy')
                ) : (
                  ''
                ),
            },
            {
              title: null,
              width: '10%',
              render: (item: any) =>
                isAcceptedEdit ? (
                  <div className="teacher-table-comment">
                    <div className="teacher-table-comment__controls">
                      {editObj?.id === item.id ? (
                        <>
                          <LmButton
                            dataTest="closePlaceOfWork"
                            type="button"
                            variant="secondary"
                            icon="filled-edit-close"
                            iconSize={20}
                            onClick={() => handleEdit()}
                          />
                          <Push
                            size={12}
                            orientation="horizontal"
                          />
                          <LmButton
                            dataTest="submitPlaceOfWork"
                            type="button"
                            color="success"
                            variant="outline"
                            icon="filled-edit-checkmark"
                            iconSize={20}
                            disabled={isDisabledSubmit()}
                            onClick={() =>
                              submit({
                                ...item,
                                id: `${item.organizationId}${Math.random()}`,
                              })
                            }
                          />
                        </>
                      ) : (
                        <>
                          <DropDown
                            dataTest="placeOptions"
                            component={() => <TableOptions />}
                          >
                            <>
                              <LmListItem
                                dataTest="editPlace"
                                text="Редактировать"
                                icon="outline-edit-edit"
                                iconSize={20}
                                onClick={() => handleEdit(item)}
                              />
                              <LmListItem
                                dataTest="deletePlace"
                                text="Удалить"
                                icon="outline-edit-trash-alt"
                                iconSize={20}
                                onClick={() => deletePlaceWork(item)}
                              />
                            </>
                          </DropDown>
                          <Push
                            size={2}
                            orientation="horizontal"
                          />
                        </>
                      )}
                    </div>
                  </div>
                ) : null,
            },
          ]}
        />
      ) : (
        <div className="teacher-no-data">Не добавлено ни одного места работы...</div>
      )}
      <Push size={12} />
    </Panel>
  );
};

export default PlaceOfWorks;
