import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { /* array as arrayYup, */ object as objectYup, string as stringYup } from 'yup';
import classNames from 'classnames';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmToggle } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../../../components/formik/formik-input-number';
import { AdmissionPlanData, ServiceData } from '../../../../../../types/service';
import FormikToggle from '../../../../../../components/formik/formik-toggle';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  parent?: string;
  isTemplate?: boolean;
  checkEditable?: boolean;
};

const TestsEdit: React.FC<Props> = ({
  isTemplate,
  setEditModeParent,
  parent,
  editMode,
  setEditMode,
  checkEditable,
}) => {
  const { values, setFieldValue } = useFormikContext<ServiceData>();

  useEffect(() => {
    if (values.test === null) {
      setFieldValue('test', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldFullName = useCallback((name: string) => (parent ? `${parent}.${name}` : name), [parent]);

  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;

      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <div className="flex">
            Вступительные испытания
            <div className="flex font-weight-base">
              <Push
                orientation="horizontal"
                size={16}
              />
              <LmToggle
                size="small"
                label="Проводятся"
                name="tests"
                dataTest="tests"
                onChange={(value) => {
                  setFieldValue(getFieldFullName('isRequired'), value);
                }}
                disabled={
                  isTemplate
                    ? !isTemplate || !editMode
                    : !editMode || !getFieldValue('canChangeTestAndToursNumber') || getFieldValue('hasTrainingGroups')
                }
                checked={getFieldValue('isRequired')}
              />
            </div>
          </div>
        )}
        headingControl={() => {
          return checkEditable && !editMode && setEditModeParent && (!parent || isTemplate) ? (
            <button
              type="button"
              onClick={() => {
                setEditModeParent && setEditModeParent('tests');
                setEditMode && setEditMode(true);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-edit"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        <div className="container">
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Количество туров {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <FormikInputNumber
                  name={getFieldFullName('toursNumber')}
                  placeholder="0"
                  disabled={
                    isTemplate
                      ? !isTemplate || !getFieldValue('isRequired')
                      : !getFieldValue('isRequired') || !getFieldValue('canChangeTestAndToursNumber')
                  }
                />
              ) : (
                getFieldValue('toursNumber') || '—'
              )}
            </div>
            {isTemplate && (
              <div className="table-data__control">
                <FormikToggle
                  disabled={!editMode}
                  name={getFieldFullName('canChangeTestAndToursNumber')}
                  size="small"
                />
              </div>
            )}
          </div>
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              <div>
                Срок предоставления док-ов / выкупа брони, дней {editMode && <span className="table-data__required" />}
              </div>
            </div>
            <div className="table-data__body">
              {editMode ? (
                <FormikInputNumber
                  name={getFieldFullName('periodGettingDocument')}
                  placeholder="0"
                  disabled={!getFieldValue('canChangePeriodGettingDocument') && !isTemplate}
                />
              ) : (
                getFieldValue('periodGettingDocument') || '—'
              )}
            </div>
            {isTemplate && (
              <div className="table-data__control">
                <FormikToggle
                  disabled={!editMode}
                  name="canChangePeriodGettingDocument"
                  size="small"
                />
              </div>
            )}
          </div>
          {getFieldValue('admissionPlanList')?.map((admissionPlan: AdmissionPlanData, index: number) => (
            <div
              key={`year_${admissionPlan.yearOfTrainingId}`}
              className={classNames(
                'table-data__group',
                true,
                (Number(admissionPlan.volume) > 0 || editMode) && 'table-data__item'
              )}
            >
              {(Number(admissionPlan.volume) > 0 || editMode) && (
                <div className="table-data__label table-data__label--main">
                  {`План приема на ${admissionPlan.yearOfTrainingName}`}
                </div>
              )}
              <div className="table-data__body">
                {editMode ? (
                  <div className="flex">
                    <FormikInputNumber
                      name={getFieldFullName(`admissionPlanList[${index}].volume`)}
                      placeholder="0"
                      disabled={!getFieldValue('canChangeAdmissionPlan')}
                    />
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    <div className="table-row-item-height flex items-center color-gray-dark">человек</div>
                  </div>
                ) : (
                  Number(admissionPlan.volume) > 0 && `${admissionPlan.volume} человек`
                )}
              </div>
            </div>
          ))}
        </div>
      </Panel>
    </>
  );
};

export default TestsEdit;

export const testValidationSchema = () =>
  objectYup().shape({
    toursNumber: stringYup()
      .nullable()
      .test((value: string | null | undefined, ctx: any) => {
        if (ctx.parent?.isRequired) {
          if (!value) {
            return ctx.createError({
              message: 'Необходимо указать количество туров ',
            });
          }
        }

        return true;
      }),
    periodGettingDocument: stringYup()
      .nullable()
      .required('Необходимо указать срок предоставления док-ов / выкупа брони, дней'),
  });
