import React, { useState } from 'react';
import { Heading, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import SignInForm from './forms/signin';
import SwitcherForm from '../switcher';

const Login = () => {
  const [errorBox, setErrorBox] = useState<{ isShow: boolean; text: string }>({
    isShow: false,
    text: '',
  });

  return (
    <>
      <Push size={8} />
      <SwitcherForm />
      <Push size={80} />
      <Heading
        label="Вход в систему"
        as="h3"
      />
      <Push size={16} />
      <SignInForm
        setErrorBox={(text, isShow) =>
          setErrorBox({
            text,
            isShow,
          })
        }
      />
      {errorBox.isShow && (
        <>
          <Push size={20} />
          <LmInfoBox
            dataTest="loginError"
            className="infobox--full-width"
            variant="alert"
            description={errorBox.text}
            hidenFooter
          />
        </>
      )}
    </>
  );
};

export default Login;
