import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { LmLoader } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import DeclineReason from '../components/fields/decline-reason';
import { RequestDeclineData } from '../../../types/requests';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import { submitRefusal } from '../utils';
import { RefuseModal } from '../components/modals';
import { requestDeclineReasonId } from '../../../lib/utils/validation';
import SavePanel from '../../../components/save-panel';

type Props = {
  editMode: boolean;
  decline: RequestDeclineData;
  serviceId: number;
  requestId: number;
  updateRequest: () => void;
};

const ActionsFormRefusal: React.FC<Props> = ({ editMode, decline, serviceId, requestId, updateRequest }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formKey, setFormKey] = useState<number>(0);

  const initialErrors = useInitialErrors(decline, getValidationSchema());
  const submitForm = useCallback(
    async (values: RequestDeclineData) => {
      await submitRefusal(values, requestId, setLoading, setShowModal, undefined, updateRequest);
    },
    [requestId, updateRequest]
  );

  const resetFormValues = (resetFn: () => void) => {
    resetFn();
    setFormKey(Math.random());
  };

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={decline}
      key={formKey}
    >
      {(formikProps: FormikProps<RequestDeclineData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="table-data">
                <DeclineReason
                  name="requestDeclineReason"
                  editMode={editMode}
                  serviceId={serviceId}
                  requestId={requestId}
                  required
                />
                <SimpleTextArea
                  name="declineComment"
                  editMode={editMode}
                  label="Комментарий"
                  placeholder="Введите..."
                />
              </div>
            </div>
            {editMode && (
              <SavePanel
                buttonPrimaryText="Отправить отказ"
                buttonSecondaryText="Сбросить"
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => resetFormValues(resetForm)}
                onClickPrimaryButton={() => setShowModal(true)}
              />
            )}
            <RefuseModal
              showModal={showModal}
              setShowModal={setShowModal}
              handleSubmit={handleSubmit}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionsFormRefusal;

const getValidationSchema = () =>
  objectYup().shape({
    requestDeclineReasonId,
  });
