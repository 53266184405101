import { LmSelectNewProps } from '@mes-ui/lemma';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '../../../../types/entities';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import FieldAsyncSelect from './async-select';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { PlaceServiceData } from '../../../../types/service';

type Props = Pick<LmSelectNewProps<SelectOptionType>, 'placeholder' | 'explainText'> & {
  values: SearchRequestsInitialFormData;
  isAdmin: boolean;
  label: string;
};

const FieldPlaceService: React.FC<Props> = ({ values, isAdmin, label, placeholder, explainText }) => {
  const [placeServiceData, setPlaceServiceData] = useState<PlaceServiceData[]>([]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    const fetchPlaceService = async (serviceId: number) => {
      setPlaceServiceData(
        await serviceTemplateApi.getPlaceServices({
          serviceId,
        })
      );
    };

    if (values.serviceId) {
      fetchPlaceService(values.serviceId);
    } else {
      setPlaceServiceData([]);
    }
  }, [values.serviceId]);

  return !hasAccessObjectAny(userProfile, [accessObject.RequestVA], accessAction.View) || isAdmin ? (
    <FieldAsyncSelect
      disabled={!values.organizationId}
      nameFieldId="placeServiceId"
      nameFieldName="placeServiceName"
      label={label}
      placeholder={placeholder}
      explainText={explainText}
      showTooltip
      disabledPortalTooltip
      option={placeServiceData?.map((i) => {
        return {
          value: i.id,
          label: i.fullAddress,
        };
      })}
      loadOptions={async (query) => {
        return await lookupApi.getPlaceService(
          query,
          values.vedomstvoId,
          values.organizationId,
          values.educationTypeId
        );
      }}
    />
  ) : null;
};

export default FieldPlaceService;
