export enum TrainingGroupStatusEnum {
  // Редактирование
  Draft = 1,
  // Подписано
  Signed = 2,
  // Архив
  Archive = 3,
  // Приема нет
  Unavailable = 4,
}
