import React from 'react';
import { Formik, FormikProps } from 'formik';
import FormikInput from '../../../components/formik/formik-input';
import { SearchOrganizationInitialFormData } from '../../../types/organization';

type SearchInputProps = {
  initialForm: SearchOrganizationInitialFormData;
  submitForm: (values: SearchOrganizationInitialFormData) => void;
};

const SearchInput = ({ initialForm, submitForm }: SearchInputProps) => {
  return (
    <div className="filter-search">
      <Formik
        initialValues={initialForm}
        enableReinitialize
        onSubmit={submitForm}
      >
        {({ handleSubmit }: FormikProps<SearchOrganizationInitialFormData>) => (
          <FormikInput
            dataTest="searchOrganization"
            name="query"
            searchButton="icon"
            resettable
            placeholder="Поиск по организациям..."
            onSubmitSearch={handleSubmit}
          />
        )}
      </Formik>
    </div>
  );
};

export default SearchInput;
