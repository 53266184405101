import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmButton, LmSubHeader } from '@mes-ui/lemma';
import { useSelector } from 'react-redux';
import { routes } from '../../config/constants';
import { ReactComponent as IconInfo } from '../../assets/images/icons/desktop-info.svg';
import { ReactComponent as IconQuestions } from '../../assets/images/icons/desktop-questions.svg';
import { ReactComponent as IconDocs } from '../../assets/images/icons/desktop-docs.svg';
import { ReactComponent as IconReports } from '../../assets/images/icons/desktop-reports.svg';
import { useGetEventsQuery } from '../../store/event';
import PanelNotifications from './panel-notifications';
import DesktopInfo from './info';
import { generateLink } from '../../lib/utils';
import UserDataNotice from './user-data-notice';
import history from '../../history';
import { getEventColor, getEventIcon, handleRead } from '../events/utils';
import { formatDateAndTime } from '../../lib/utils/date';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { LoaderCustom } from '../../components/loader-custom';

const Desktop = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { data: events = [], isFetching } = useGetEventsQuery({
    startDate: undefined,
    endDate: undefined,
    selectedTypeId: 0,
  });

  return (
    <>
      <LmSubHeader
        sticky
        hideReturnButton
        title="Рабочий стол"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Рабочий стол',
          },
        ]}
        description=""
        dataTest="desktopSubHeader"
      />
      <UserDataNotice />
      <Push size={12} />
      <div className="container">
        <div className="desktop-container">
          <div className="desktop-events">
            <Panel
              borderRadius
              title={() => <div className="desktop-events__title">Уведомления</div>}
              headingControl={() =>
                events.length ? (
                  <div className="desktop-events__controls">
                    <LmButton
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        history.push(routes.events);
                      }}
                    >
                      Реестр уведомлений
                    </LmButton>
                  </div>
                ) : null
              }
            >
              <div className="desktop-events__container">
                {isFetching ? (
                  <LoaderCustom size={120} />
                ) : events.length ? (
                  events.slice(0, 10).map((item) => (
                    <div
                      key={item.id}
                      className="event-item"
                    >
                      <div
                        onClick={() => handleRead(item, userProfile)}
                        className={`event-item__media cursor-pointer ${getEventColor(item.eventTypeId)}`}
                      >
                        {getEventIcon(item.eventTypeId)}
                      </div>
                      <div className="event-item__body">
                        <div>
                          <div
                            onClick={() => handleRead(item, userProfile)}
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: item.eventText || '',
                            }}
                          />
                          <Push size={4} />
                          <div className="color-gray-dark">{item.dateCreate && formatDateAndTime(item.dateCreate)}</div>
                        </div>
                        {!item.isRead && (
                          <div className="flex items-center">
                            <Push
                              size={8}
                              orientation="horizontal"
                            />
                            <div className="event-new-badge" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="desktop-events__no-data">Нет данных</div>
                )}
              </div>
            </Panel>
          </div>
          <div className="desktop-links-grid">
            <Panel borderRadius>
              <div className="desktop-media">
                <Link
                  to={generateLink(routes.userManual, {})}
                  className="desktop-media__link"
                />
                <div className="desktop-media__body">Руководство пользователя</div>
                <div className="desktop-media__object">
                  <IconInfo />
                </div>
              </div>
            </Panel>
            <Panel borderRadius>
              <div className="desktop-media">
                <Link
                  to={generateLink(routes.faq, {})}
                  className="desktop-media__link"
                />
                <div className="desktop-media__body">Ответы на вопросы</div>
                <div className="desktop-media__object">
                  <IconQuestions />
                </div>
              </div>
            </Panel>
            <Panel borderRadius>
              <div className="desktop-media">
                <Link
                  to={generateLink(routes.normativeDoc, {})}
                  className="desktop-media__link"
                />
                <div className="desktop-media__body">Нормативные документы</div>
                <div className="desktop-media__object">
                  <IconDocs />
                </div>
              </div>
            </Panel>
            <Panel borderRadius>
              <div className="desktop-media">
                <Link
                  to={generateLink(routes.reports, {})}
                  className="desktop-media__link"
                />
                <div className="desktop-media__body">Отчеты</div>
                <div className="desktop-media__object">
                  <IconReports />
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>

      <Push size={12} />
      <PanelNotifications />

      <Push size={12} />
      <DesktopInfo />
    </>
  );
};

export default Desktop;
