import React from 'react';
import { LmIcon } from '@mes-ui/lemma';
import { Push } from '@mosru/esz_uikit';
import { formatBytes } from '../../../../lib/utils/format-bytes';
import { ArrImageType } from '../../../../types/organization';

type PhotoItemProps = {
  item: ArrImageType;
  currentId: number | null;
  checkSigned: boolean;
  isArchive: boolean;
  onSelect: (item: ArrImageType) => void;
  onDelete: (item: ArrImageType) => void;
};

const PhotoItem = React.memo(({ item, currentId, checkSigned, isArchive, onSelect, onDelete }: PhotoItemProps) => {
  return (
    <li className="org-photo-ul__item">
      <div className={`org-photo-media ${currentId === item.id && 'org-photo-media--active'}`}>
        {currentId !== item.id && (
          <button
            type="button"
            aria-label="photo_select"
            onClick={() => {
              onSelect(item);
            }}
            className="org-photo-media__btn-select"
          />
        )}
        {!checkSigned && !isArchive && (
          <button
            type="button"
            onClick={() => onDelete(item)}
            className="org-photo-media__btn-delete"
          >
            <LmIcon
              icon="filled-edit-close"
              size={20}
              color="var(--LM-neutrals-day-700)"
            />
          </button>
        )}
        <img
          alt={item.name}
          src={URL.createObjectURL(item.cropImage)}
          className="org-photo-media__object"
        />
        <div className="org-photo-media__body">
          {item.name}
          <Push size={4} />
          <div className="color-gray-dark">{formatBytes(item.cropImage.size)}</div>
        </div>
      </div>
    </li>
  );
});

export default PhotoItem;
