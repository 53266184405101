import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { date as dateYup, number, object as objectYup, string as stringYup } from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmIcon } from '@mes-ui/lemma';
import Dialog from '../../../components/modals/dialog';
import SavePanel from '../../../components/save-panel';
import ScheduleTests from './panels/schedule-tests';
import PlaceTests from './panels/place-tests';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { generalAccess } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import history from '../../../history';
import { generateLink, hasGeneralAccess } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import { TypesOfRepetitionEnum } from '../../../mock-data/type-of-repetition';
import { ScheduleDataEx } from './tests';
import { TrainingGroupStatusEnum } from '../../../mock-data/training-group-status-enum';
import SignModal from '../../../components/sign-modal';

type ServiceProps = {
  scheduleData: ScheduleDataEx;
  getSchedule: () => Promise<void>;
  serviceId: number;
  educationType: number;
  isRetro: boolean;
};

const TestsDetails = ({ scheduleData, getSchedule, serviceId, educationType, isRetro }: ServiceProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showEditButton, setShowEditButton] = useState<boolean>(scheduleData.id > 0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [signModal, showSignModal] = useState(false);
  const params = useParams<{ serviceId: string; tgId: string; id: string }>();
  const initialErrors = useInitialErrors(scheduleData, getValidationSchema(isRetro));

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const canSign = useMemo(
    (): boolean => hasGeneralAccess(userProfile, generalAccess.UseSign) && !scheduleData.isRetro,
    [userProfile, scheduleData]
  );

  const canEdit = useMemo(
    () =>
      [TrainingGroupStatusEnum.Draft, TrainingGroupStatusEnum.Unavailable].includes(
        scheduleData.trainingGroupStatusId
      ) ||
      (scheduleData.trainingGroupStatusId === TrainingGroupStatusEnum.Signed && !scheduleData.requestTotalCount),
    [scheduleData.trainingGroupStatusId, scheduleData.requestTotalCount]
  );

  useEffect(() => {
    setShowEditButton(canEdit && !editMode);
  }, [canEdit, editMode]);

  useEffect(() => {
    setEditMode(scheduleData.id === 0);
  }, [scheduleData]);

  const setTimeInDate = (date: string, time: string) => {
    const [hours, minutes] = (time ?? '00:00').split(':');
    const initialHourMinutesForDate = moment(date).set('hour', 0).set('minute', 0).toDate();

    return moment(initialHourMinutesForDate).add(hours, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
  };

  const handleCancel = () => {
    if (!scheduleData?.id) {
      history.push(
        `${generateLink(routes.trainingGroup, {
          serviceId,
          id: params.tgId ?? 0,
        })}?isRetro=${isRetro}`
      );
    } else {
      setEditMode(false);
    }
  };

  const submit = async (values: ScheduleDataEx) => {
    if (values.requestStart) {
      values.requestStart = setTimeInDate(values.requestStart, values.requestTimeStart);
    }
    if (values.requestEnd) {
      values.requestEnd = setTimeInDate(values.requestEnd, values.requestTimeEnd);
    }

    switch (values.typesOfRepetitionId) {
      case TypesOfRepetitionEnum.RepeatDaily:
        values = {
          ...values,
          repeatNumber1: values.everyday ? undefined : values.repeatNumber1,
          repeatNumber2: undefined,
        };
        break;
      case TypesOfRepetitionEnum.RepeatWeekly:
        values = {
          ...values,
          repeatNumber1: values.everyWeekRepeatNumber1,
          repeatNumber2: undefined,
        };
        break;
      case TypesOfRepetitionEnum.RepeatMonthly:
        values = {
          ...values,
          repeatNumber1: !values.everymonth ? values.everymonthRepeatNumber1 : values.everymonthRepeatNumber3,
          repeatNumber2: !values.everymonth ? values.everymonthRepeatNumber2 : values.everymonthRepeatNumber4,
          dayOfWeek: !values.everymonth ? undefined : values.dayOfWeek,
        };
        break;
      case TypesOfRepetitionEnum.NoRepeat:
        values = {
          ...values,
          repeatNumber1: 0,
          repeatNumber2: 0,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        };
        break;
      default:
        break;
    }

    if (values.id > 0) {
      await serviceTemplateApi.updateTrainingSchedule(params.serviceId, values);
    } else {
      values = {
        ...values,
        trainingGroupStatusId: 1,
        trainingGroupId: parseInt(params.tgId),
        educationTypeId: educationType,
        isRetro: !!isRetro,
        isTestPresent: true,
      };

      const scheduleId = await serviceTemplateApi.saveTrainingSchedule(params.serviceId, values);

      history.push(
        `${generateLink(routes.entranceTests, {
          serviceId: params.serviceId,
          tgId: params.tgId,
          id: scheduleId,
        })}?isRetro=${isRetro}`
      );
    }
    setEditMode(false);
    getSchedule();
  };

  const editCallBack = useCallback(() => {
    if (scheduleData.trainingGroupStatusId === TrainingGroupStatusEnum.Signed) {
      setShowEditModal(true);
    } else {
      setEditMode(true);
    }
  }, [scheduleData]);

  const handleEditModal = async () => {
    await serviceTemplateApi.updateTrainingScheduleStatus({
      serviceId: params.serviceId,
      educationTypeId: scheduleData.educationTypeId,
      scheduleOfTimetableId: scheduleData.id,
      trainingGroupStatusId: TrainingGroupStatusEnum.Draft,
    });
    getSchedule();
    setEditMode(true);
  };

  return (
    <>
      {!editMode && (
        <>
          {scheduleData.trainingGroupStatusId === TrainingGroupStatusEnum.Draft && (
            <>
              <Push size={12} />
              <div className="infobox infobox--danger">
                <div className="infobox__head">
                  <div className="infobox__body">
                    <div className="flex">
                      <LmIcon
                        icon="outline-misc-clock"
                        color="var(--LM-red-200)"
                        size={18}
                        className="flex-none"
                      />
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <span className="color-danger-dark">Не опубликовано</span>
                    </div>
                  </div>
                  <div className="infobox__control">
                    {canSign ? (
                      <LmButton
                        dataTest="publish"
                        type="button"
                        size="medium"
                        onClick={() => {
                          showSignModal(true);
                        }}
                      >
                        Опубликовать
                      </LmButton>
                    ) : (
                      <LmButton
                        dataTest={scheduleData.isRetro ? 'confirm' : 'publish'}
                        type="button"
                        size="medium"
                        onClick={async () => {
                          await serviceTemplateApi.updateTrainingScheduleStatus({
                            serviceId: params.serviceId,
                            educationTypeId: scheduleData.educationTypeId,
                            scheduleOfTimetableId: scheduleData.id,
                            trainingGroupStatusId: TrainingGroupStatusEnum.Signed,
                          });
                          getSchedule();
                        }}
                      >
                        {scheduleData.isRetro ? 'Подтвердить' : 'Опубликовать'}
                      </LmButton>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {!scheduleData?.isAccessible && scheduleData.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive && (
            <>
              <Push size={12} />
              <div className="infobox infobox--danger">
                <div className="infobox__head">
                  <div className="infobox__body">
                    <div className="flex">
                      <LmIcon
                        icon="outline-misc-clock"
                        color="var(--LM-red-200)"
                        size={18}
                        className="flex-none"
                      />
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <span className="color-danger-dark">
                        Для подачи заявлений необходимо, чтобы данная услуга была в статусе “Опубликовано на Mos.ru”,
                        план приема / расписание вступительных испытаний “Подписан”/ “Подписано”, количество зачисленных
                        заявлений не превышало количество мест и дата приема заявлений на Mos.ru была не позже
                        сегодняшней даты и текущего времени.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <Formik
        onSubmit={submit}
        enableReinitialize
        initialValues={scheduleData}
        validationSchema={getValidationSchema(isRetro)}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<ScheduleDataEx>) => {
          const { submitForm, isValid, resetForm } = formikProps;

          return (
            <form>
              <ScheduleTests
                editMode={editMode}
                editCallBack={editCallBack}
                isRetro={isRetro}
                showEditButton={showEditButton}
              />
              <PlaceTests
                editMode={editMode}
                editCallBack={editCallBack}
                serviceId={params.serviceId}
                showEditButton={showEditButton}
                scheduleId={scheduleData.id}
              />
              {editMode && (
                <SavePanel
                  primaryButtonModifiers={{
                    disabled: !isValid,
                  }}
                  onClickSeconadaryButton={() => {
                    resetForm();
                    handleCancel();
                  }}
                  onClickPrimaryButton={submitForm}
                />
              )}
            </form>
          );
        }}
      </Formik>
      <Dialog
        dataTest="unpublishedSchedule"
        isOpenDialog={showEditModal}
        title="Снятие с публикации на Mos.ru"
        description="Внимание! После подтверждения расписание вступительных испытаний будет снято с публикации."
        variant="alert"
        callCloseAfterSubmit
        onClickPrimaryButton={handleEditModal}
        onClickSeconadaryButton={() => setShowEditModal(false)}
      />
      <SignModal
        show={signModal}
        onCloseHandle={() => {
          showSignModal(false);
        }}
        ids={[
          {
            id: 0,
          },
        ]}
        getDataForSignHandle={async () => JSON.stringify(scheduleData)}
        setSignedDataHandle={async (id, sign) => {
          if (scheduleData) {
            try {
              await serviceTemplateApi.signTrainingSchedule({
                serviceId,
                educationTypeId: scheduleData.educationTypeId,
                signedDocument: sign,
                scheduleOfTimetableId: scheduleData.id,
              });
              showSignModal(false);
              setEditMode(false);
              getSchedule();
            } catch {}
          }
        }}
      />
    </>
  );
};

export default TestsDetails;

const getValidationSchema = (isRetroFlag: boolean) => {
  const isRetro = isRetroFlag;

  return objectYup().shape({
    periodFrom: dateYup()
      .nullable()
      .test('comapre', 'Дата и время начала проведения испытаний не может быть меньше текущей даты', function (value) {
        // const parent = this.parent as ScheduleDataEx;

        if (value) {
          const dt = new Date();

          dt.setHours(0, 0, 0, 0);

          return value >= dt;
        }

        return true;
      })
      .required('Выберите дату'),
    timeStart: stringYup().nullable().required('Введите время начала'),
    timeEnd: stringYup().nullable().required('Введите время окончания'),
    volume: number()
      .nullable()
      .required('Введите количество мест')
      .test('volume', 'Введите количество мест', (value) => value !== 0),
    requestStart: isRetro ? stringYup().nullable() : dateYup().nullable().required('Выберите дату начала'),
    requestEnd: isRetro
      ? stringYup().nullable()
      : dateYup()
        .nullable()
        .test('comapre', 'Дата окончания приема заявлений меньше даты начала приема заявлений', function (value) {
          const parent = this.parent as ScheduleDataEx;

          if (parent && parent.requestStart && value) {
            return new Date(value) > new Date(parent.requestStart);
          }

          return true;
        })
        .test(
          'comapre',
          'Дата и время окончания подачи заявлений не может быть меньше текущей даты и времени',
          function (value) {
            if (value) {
              return new Date(value) > new Date();
            }

            return true;
          }
        )
        .required('Выберите дату окончания'),
    requestTimeStart: isRetro ? stringYup().nullable() : stringYup().nullable().required('Введите время начала'),
    requestTimeEnd: isRetro ? stringYup().nullable() : stringYup().nullable().required('Введите время окончания'),
  });
};
