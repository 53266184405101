// Получение данных только для г.Москва
import React from 'react';
import lookupApi from '../../../../../lib/api/lookup';

export const downloadMoscowAddresses = async (query: string) => {
  const data = await lookupApi.getAddress(query);

  return data?.filter((item) => {
    return item.additionalPropertiesJson && JSON.parse(item.additionalPropertiesJson).IsMoskow;
  });
};

export const checkAddress = (selectedAddressRegistration: string, regAddress: string | null): string | JSX.Element => {
  if (selectedAddressRegistration) {
    return selectedAddressRegistration;
  } else if (regAddress) {
    return regAddress;
  } else {
    return <span className="color-gray">Не указано</span>;
  }
};
