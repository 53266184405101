import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmBadgeStatus } from '@mes-ui/lemma';
import { SplitModalListElement } from '../../../../types/learners';

type Props = {
  data: SplitModalListElement[];
  deleteElement: (value: number) => void;
};

const List: React.FC<Props> = ({ data, deleteElement }) => {
  return (
    <ul className="learners-modal-ul">
      {data.map((item) => (
        <div
          key={item.id}
          className="learners-modal-ul__item"
        >
          <div className="color-gray-dark flex items-center">
            #{item.id}
            <button
              onClick={() => deleteElement(item.id)}
              type="button"
              className="learners-modal-ul__btn"
            >
              <LmIcon
                icon="filled-edit-close"
                size={20}
                color="var(--LM-neutrals-day-600)"
              />
            </button>
          </div>
          <Push size={4} />
          <div className="font-weight-bold">{item.name}</div>
          <Push size={4} />
          {item.date}
          <Push size={8} />
          <div className="flex">
            <LmBadgeStatus
              dataTest="learnerActive"
              color="green"
            >
              Активное
            </LmBadgeStatus>
            <Push
              orientation="horizontal"
              size={8}
            />
          </div>
        </div>
      ))}
    </ul>
  );
};

export default List;
