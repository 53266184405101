import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmToggle } from '@mes-ui/lemma';
import { Learner } from '../../../types/learners';
import { privilegiesApi } from '../../../lib/api/privilege';
import { hasGeneralAccess } from '../../../lib/utils';
import { generalAccess } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { formatDate } from '../../../lib/utils/date';
import { LoaderCustom } from '../../../components/loader-custom';
import SimpleTable from '../../../components/table/simple-table';

type Props = {
  pupilId: string;
};
const LearnerPrivilegies: React.FC<Props> = ({ pupilId }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdminView = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminView), [userProfile]);
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const checkPrivileges = useMemo(() => hasGeneralAccess(userProfile, generalAccess.CheckPrivileges), [userProfile]);

  const [filterData, setFilterData] = useState<Learner.Privileges[]>([]); // список привелегий который не меняем от toggle
  const [privileges, setPrivileges] = useState<Learner.Privileges[]>([]);

  const [on, setOn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const filterActive = useCallback(() => {
    if (on) {
      const filter = filterData.filter((item: Learner.Privileges) => item.isActual);

      setPrivileges(filter);
    } else {
      setPrivileges(filterData);
    }
  }, [filterData, on]);

  useEffect(() => {
    (async () => {
      const privileges = await privilegiesApi.getPrivilegiesByPupil({
        pupilId,
      });

      setPrivileges(privileges);
      setFilterData(privileges);
      setLoading(false);
    })();
  }, [pupilId]);

  useEffect(() => {
    filterActive();
  }, [filterActive, on]);

  const access =
    !!filterData.map((item: Learner.Privileges) => item).length && (checkPrivileges || isAdminEdit || isAdminView);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <div className="flex learner-document-header">
            <div>
              Информация о льготах
              <span className="color-gray-dark">{' \u00A0'}</span>
            </div>
            <Push
              orientation="horizontal"
              size={25}
            />
            {access ? (
              <div className="learner-document-toggle-table">
                <LmToggle
                  size="small"
                  name="id"
                  dataTest="id"
                  label="Показывать только действующие льготные категории"
                  onChange={(value) => setOn(value)}
                  checked={on}
                />
              </div>
            ) : null}
          </div>
        )}
      >
        {loading ? (
          <LoaderCustom size={20} />
        ) : privileges.length ? (
          <SimpleTable
            dataTest="privileges"
            data={privileges}
            // pageSize={Number.MAX_SAFE_INTEGER}
            columns={[
              {
                dataIndex: 'code',
                title: 'Код ',
                render: (item: any) => (
                  <span className={!item.isActual ? 'table-bad-visible-field' : ''}>{item.privilegeCategoryCode}</span>
                ),
              },
              {
                dataIndex: 'name',
                title: 'Тип',
                render: (item: any) => (
                  <span className={!item.isActual ? 'table-bad-visible-field' : ''}>{item.privilegeCategoryName}</span>
                ),
              },
              {
                dataIndex: 'privilegeStartDate',
                title: 'Начало и окончание действия',
                render: (item: any) => (
                  <span className={!item.isActual ? 'table-bad-visible-field' : ''}>{`${formatDate(
                    item.privilegeStartDate
                  )} - ${item.privilegeEndDate ? formatDate(item.privilegeEndDate) : 'бессрочно'} `}</span>
                ),
              },
              {
                dataIndex: 'verificationDate',
                title: 'Дата последней проверки',
                render: (item: any) => (
                  <span className={!item.isActual ? 'table-bad-visible-field' : ''}>
                    {formatDate(item.verificationDate)}
                  </span>
                ),
              },
            ]}
          />
        ) : (
          <div className="learner-no-data">Сведения о льготах отсутствуют</div>
        )}
      </Panel>
    </>
  );
};

export default LearnerPrivilegies;
