import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InteractiveChevronTemplate, LmButton, LmSubHeader, LmListItem } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import Dialog from '../../../components/modals/dialog';
import ViewContainer from '../../../components/view-container';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { EventUrl } from '../../../mock-data/event';
import { historyState } from '../../../mock-data/history-state';
import { accessAction, accessObject, AuthorizationData } from '../../../types/authorization-data';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { hasAccessObjectAny, redirect, replaceHistoryState } from '../../../lib/utils';
import Search from './search';
import { ListMenuAddGroup, SearchInitialFormData } from '../../../types/service';
import ServiceChildList from './components/service-child-list/list';
import { RegistryTypeEnum } from '../../../mock-data/registry-type-enum';
import { installInitialData } from './helpers';
import { dictionaryService, listMenuAddGroup, selectServiceItemCallback } from '../../../lib/utils/service';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import DropDown from '../../../components/drop-down';
import { routes } from '../../../config/constants';
import { EducationTypeEnum } from '../../../types/education-type';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import useQuery from '../../../hooks/use-query';
import { sendReachGoal } from '../../../lib/metrica';
import { SelectOptionType } from '../../../types/entities';

export type ServiceRegistryContextType = {
  visitTypeData: SelectOptionType[];
  educationTypeData: SelectOptionType[];
  vedomstvoData: SelectOptionType[];
  statusesData: SelectOptionType[];
  isAdmin: boolean;
  userProfile: AuthorizationData;
  type: RegistryTypeEnum;
  periodOfStudyData: SelectOptionType[];
  educationSumm: number;
  trainingGroupStatuses: SelectOptionType[];
  initialData: SearchInitialFormData;
  currentEducationType: EducationTypeEnum[];
  accessVa: boolean;
  edTypeByField: EducationTypeEnum;
  setEdTypeByField: (edType: EducationTypeEnum) => void;
};

export const ServiceRegistryContext = React.createContext<ServiceRegistryContextType>({} as ServiceRegistryContextType);

type Props = {
  type: RegistryTypeEnum;
  isAdmin: boolean;
  educationSumm: number;
  userProfile: AuthorizationData;
  educationTypeData: SelectOptionType[];
};

export const InitialData: React.FC<Props> = ({ type, educationTypeData, educationSumm, userProfile, isAdmin }) => {
  const currentEducationType = educationTypeData.map((item) => item.value) as EducationTypeEnum[];

  const [visitTypeData, setVisitTypeData] = useState<SelectOptionType[]>([]);
  const [statusesData, setStatusesData] = useState<SelectOptionType[]>([]);
  const [periodOfStudyData, setPeriodOfStudyData] = useState<SelectOptionType[]>([]);
  const [trainingGroupStatuses, setTrainingGroupStatuses] = useState<SelectOptionType[]>([]);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const [dayCarePublicatedStatus, setDayCarePublicatedStatus] = useState<boolean>(false);

  const [edTypeByField, setEdTypeByField] = useState<EducationTypeEnum>(EducationTypeEnum.None);

  const eventId = Number(useQuery().get(EventUrl.EventId));

  const initialData = useMemo(() => {
    return {
      ...installInitialData(
        isAdmin,
        userProfile,
        educationTypeData,
        visitTypeData,
        statusesData,
        type,
        educationSumm,
        eventId
      ),
    };
  }, [educationSumm, educationTypeData, eventId, isAdmin, statusesData, type, userProfile, visitTypeData]);

  const [search, setSearch] = useState<SearchInitialFormData>(window.history.state[historyState.search] || initialData);
  const dictionary = dictionaryService(type, currentEducationType);

  const vedomstvoData: SelectOptionType[] = useGetDataDepartment(false);

  const [currentSearchData, setCurrentSearchData] = useState<SearchInitialFormData>(
    window.history.state[historyState.search] || initialData
  );

  const searchPlaceholder = isAdmin ? 'Поиск...' : dictionary.placeholder;

  const listMenu = listMenuAddGroup(userProfile, isAdmin, type);

  useClearHistoryState();

  // Загрузка "Видов посещения"
  useEffect(() => {
    const fetchVisitTypes = async () => {
      const isVirtualAssistantEducation = educationSumm === EducationTypeEnum.VirtualAssistantEducation;
      const visitTypeData = await dictionariesApi.getVisitTypes(
        false,
        isVirtualAssistantEducation ? EducationTypeEnum.VirtualAssistantEducation : undefined
      );

      setVisitTypeData(
        isVirtualAssistantEducation
          ? [...visitTypeData]
          : [
              {
                label: 'Все',
                value: 0,
              },
              ...visitTypeData,
            ]
      );
    };

    fetchVisitTypes();
  }, [educationSumm]);

  // Загрузка "Статусов детских объединений"
  useEffect(() => {
    const fetchStatuses = async () => {
      const statusesData = await dictionariesApi.getStatuses(educationSumm);

      setStatusesData([
        selectServiceItemCallback
          ? {
              label: 'Опубликовано на Mos.ru',
              value: 2,
            }
          : {
              label: 'Актуальные',
              value: 0,
            },
        ...statusesData,
      ]);
    };

    fetchStatuses();
  }, [educationSumm]);

  // Загрузка "Статусов плана приема"
  useEffect(() => {
    const fetchTrainingGroupStatuses = async () => {
      const trainingGroupStatusData = await dictionariesApi.getTrainingGroupStatus();

      setTrainingGroupStatuses([
        {
          label: 'Все',
          value: 0,
        },
        ...trainingGroupStatusData,
      ]);
    };

    fetchTrainingGroupStatuses();
  }, []);

  // Загрузка "Периодов обучения"
  useEffect(() => {
    const fetchPeriodOfStudy = async () => {
      const periodOfStudyData = await dictionariesApi.getYearOfTrainings();

      setPeriodOfStudyData([
        {
          label: 'Все',
          value: null,
        },
        ...periodOfStudyData,
      ]);
    };

    fetchPeriodOfStudy();
  }, []);

  // Проверка возможности пользователя создавать услуги ГПД
  useEffect(() => {
    const isDayCare = hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.Create);

    const fetchGetDayCareAbility = async () => {
      const res = await serviceTemplateApi.getDayCareAbility();

      if (!res) {
        setDayCarePublicatedStatus(true);
      } else {
        setDayCarePublicatedStatus(false);
      }
    };

    if (isDayCare) {
      fetchGetDayCareAbility();
    }
  }, [userProfile]);

  const decoratorDictionary = useCallback(
    (value: any, key: string): string => {
      const data = {
        [EducationTypeEnum.ChildrenEducation]: RegistryTypeEnum.serviceChildType,
        [EducationTypeEnum.ChildrenNonDogmEducation]: RegistryTypeEnum.serviceChildType,
        [EducationTypeEnum.DayCareCentersEducation]: RegistryTypeEnum.serviceGpdType,
        [EducationTypeEnum.VirtualAssistantEducation]: RegistryTypeEnum.serviceEducationType,
      };

      if (isAdmin && type === RegistryTypeEnum.serviceChildType && edTypeByField && edTypeByField !== educationSumm) {
        const dictionary = dictionaryService(data[edTypeByField], [edTypeByField]);

        return dictionary[key];
      } else {
        return value;
      }
    },
    [edTypeByField, educationSumm, isAdmin, type]
  );

  const serviceRegistryData: ServiceRegistryContextType = useMemo(
    () => ({
      visitTypeData,
      educationTypeData,
      vedomstvoData,
      statusesData,
      periodOfStudyData,
      isAdmin,
      accessVa: hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.ViewRegistry), // цифровой репетитор
      userProfile,
      type,
      educationSumm,
      trainingGroupStatuses,
      initialData,
      currentEducationType,
      edTypeByField,
      setEdTypeByField,
    }),
    [
      visitTypeData,
      educationTypeData,
      vedomstvoData,
      statusesData,
      periodOfStudyData,
      isAdmin,
      userProfile,
      type,
      educationSumm,
      trainingGroupStatuses,
      initialData,
      currentEducationType,
      edTypeByField,
      setEdTypeByField,
    ]
  );

  const handleChangeSearch = (newValue: SearchInitialFormData) => {
    const newSearch = {
      ...newValue,
      educationTypeId: newValue.educationTypeId ?? educationSumm,
    };

    setSearch(newSearch);
    replaceHistoryState({
      [historyState.search]: newSearch,
    });
  };

  const handleCreateService = (educationType?: EducationTypeEnum, link?: string) => {
    sendReachGoal(`onClick-service-create-${educationType}`);
    if (educationType) {
      if (educationType === EducationTypeEnum.DayCareCentersEducation && !dayCarePublicatedStatus) {
        setShowWarningModal(true);
      } else {
        link && redirect(link);
      }
    } else if (!dayCarePublicatedStatus) {
      setShowWarningModal(true);
    } else {
      listMenu && redirect(listMenu[0].link);
    }
  };
  const getButtons = (): ISubHeaderTitleButton[] => {
    if (selectServiceItemCallback || !listMenu.length) {
      return [] as ISubHeaderTitleButton[];
    }
    const buttons: ISubHeaderTitleButton[] = [];

    if (listMenu.length > 1) {
      buttons.push({
        buttonText: '',
        children: (
          <DropDown
            dataTest="addService"
            component={(open) => (
              <LmButton
                dataTest="addService"
                type="button"
              >
                Добавить
                <InteractiveChevronTemplate open={open} />
              </LmButton>
            )}
          >
            <>
              {listMenu.map((item: ListMenuAddGroup) => {
                return (
                  <LmListItem
                    key={item.label}
                    dataTest={item.label}
                    text={item.label}
                    onClick={() => handleCreateService(item.educationType, item.link)}
                  />
                );
              })}
            </>
          </DropDown>
        ),
      });
    } else if (dictionary.addBtn) {
      buttons.push({
        dataTest: 'addService',
        buttonText: dictionary.addBtn,
        rounded: 'small',
        onClick: () => handleCreateService(listMenu[0].educationType, listMenu[0].link),
      });
    }

    return buttons;
  };

  return (
    <ServiceRegistryContext.Provider value={serviceRegistryData}>
      <ViewContainer
        subHeader={
          <LmSubHeader
            sticky
            hideReturnButton
            description=""
            title={selectServiceItemCallback ? dictionary.subTitle : decoratorDictionary(dictionary.title, 'title')}
            dataTest="servicesSubHeader"
            routes={
              selectServiceItemCallback
                ? []
                : [
                    {
                      label: 'Главная',
                      link: routes.main,
                    },

                    {
                      label: decoratorDictionary(dictionary.title, 'title'),
                    },
                  ]
            }
            buttonsOrAnyActions={getButtons()}
          />
        }
        filterComponent={
          <Search
            submitForm={handleChangeSearch}
            searchPlaceholder={searchPlaceholder}
            setCurrentSearchData={setCurrentSearchData}
            initialValues={search}
          />
        }
      >
        <ServiceChildList
          search={search}
          title={decoratorDictionary(dictionary.title, 'title')}
          loadingTitle={dictionary.loading}
          enable={
            RegistryTypeEnum.serviceEducationType === type || RegistryTypeEnum.serviceEducationProgramType === type
          }
          currentSearchData={currentSearchData}
        />
      </ViewContainer>
      <Dialog
        dataTest="addDayCareInfo"
        isOpenDialog={showWarningModal}
        title="Создание программы по уходу и присмотру"
        description={
          <div>
            Внимание! Для Вашей организации не предусмотрено создание программы по уходу и присмотру за детьми школьного
            возраста согласно сведениям, полученным из АИС “Зачисление в ОУ”.
          </div>
        }
        variant="information"
        buttonSecondaryText="Закрыть"
        onClickSeconadaryButton={() => setShowWarningModal(false)}
      />
    </ServiceRegistryContext.Provider>
  );
};
