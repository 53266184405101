import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInfoBox } from '@mes-ui/lemma';
import { ServiceContext } from '../../..';
import Dialog from '../../../../../components/modals/dialog';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import PreparationPlanModal from '../../modals/plan/preparation-plan-modal';
import ChildPlanModal from '../../modals/plan/child-plan-modal';
import TemplatePlanModal from '../../modals/plan/template-plan-modal';
import EditPlan from './edit';
import { EducationTypeEnum } from '../../../../../types/education-type';
import DayCarePlanModal from '../../modals/plan/daycare-plan-modal';
import { TrainingGroupData } from '../../../../../types/service';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { TypeFinancingEnum } from '../../../../../mock-data/type-financing-enum';

type Props = {
  editable?: boolean;
  isCreate?: boolean;
  setPlans?: (value: TrainingGroupData[]) => void;
};

const PlanTemplate: React.FC<Props> = ({ editable, isCreate, setPlans }) => {
  const { serviceData, accessPanelEdit, updateService, updateBudgetPlaces, setNumberSeatsAdmissionPlan } =
    useContext(ServiceContext);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [idRemovedTg, setIdRemovedTg] = useState<number | null>(null);
  const [costTg, setCostTg] = useState<TrainingGroupData | null>();
  const [tgList, setTgList] = useState<TrainingGroupData[]>([]);
  const [currentIdPlan, setCurrentIdPlan] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const editPlan = (id: number) => {
    setCurrentIdPlan(id);
    setShowAddModal(true);
  };

  const updateData = () => {
    getTrainingGroups();

    if (serviceData.educationTypeId === EducationTypeEnum.ProfessionalEducation) {
      updateBudgetPlaces();
    }
  };

  const postSubmit = (plan?: TrainingGroupData) => {
    updateData();

    setShowAddModal(false);
    if (serviceData.financing.typeFinancingId === TypeFinancingEnum.Payment && plan) {
      setCostTg(plan);
    }
    setCurrentIdPlan(0);
  };

  const handleDeleteModal = async () => {
    if (idRemovedTg) {
      try {
        await serviceTemplateApi.deleteTrainingGroup(serviceData.id, idRemovedTg);
        updateData();
      } catch {}
    }
  };

  const getDurationInMonths = (costTg: TrainingGroupData): number => {
    let monthCount = 0;

    for (let i = 0; i < costTg.stageList?.length; i++) {
      const startDate = moment(costTg.stageList[i].dateStart, 'YYYY-MM-DDTHH:mm:ss');
      const timeEnd = moment(costTg.stageList[i].dateEnd, 'YYYY-MM-DDTHH:mm:ss');
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      // monthCount += diffDuration.months() + (diffDuration.days() > 0 ? 1 : 0);
      monthCount += diffDuration.months() > 0 ? diffDuration.months() : 1; // берем кривую логику из монолита
    }

    return monthCount;
  };

  const handleRecalculateCost = async () => {
    if (!costTg) {
      return;
    }
    const { financing } = serviceData;
    const fullPrice = typeof financing.fullPrice === 'string' ? parseInt(financing.fullPrice) : financing.fullPrice;

    financing.typeValueServiceId = 1;
    financing.periodPrice = fullPrice / getDurationInMonths(costTg);

    await serviceTemplateApi.updateFinancing(serviceData.id, financing);
    updateService();
  };

  const getTrainingGroups = useCallback(async (): Promise<void> => {
    const fetch = async (id: number) => {
      try {
        setLoading(true);
        const response = await serviceTemplateApi.getServiceTrainingGroupList(id);

        setTgList(response);
        if (setPlans) {
          setPlans(response);
        }

        setNumberSeatsAdmissionPlan(
          response.map((item) => ({
            yearOfTrainingName: item.yearOfTrainingName,
            yearOfTrainingId: item.yearOfTrainingId,
            volume: item.volume,
          }))
        );
      } finally {
        setLoading(false);
      }
    };

    fetch(serviceData?.id);
  }, [serviceData?.id, setPlans, setNumberSeatsAdmissionPlan]);

  useEffect(() => {
    if (!isCreate) {
      getTrainingGroups();
    }
  }, [serviceData?.id, getTrainingGroups, isCreate, serviceData?.serviceStatusId]);

  // При статусе Опубликовано на Mos.ru должна быть активная кнопка подать заявление
  const checkEditable = editable && serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Планы приема{' '}
            <span className="color-gray-dark">
              {' \u00A0'} {tgList?.length || ''}
            </span>
          </>
        )}
        headingControl={() => {
          return checkEditable ? (
            <button
              type="button"
              onClick={() => {
                setShowAddModal(true);
                setCurrentIdPlan(0);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-plus"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          ) : null;
        }}
      >
        {!isCreate ? (
          editable && (
            <EditPlan
              loading={loading}
              planData={tgList}
              editPlan={editPlan}
              setIdRemoveModal={setIdRemovedTg}
              educationType={serviceData?.educationTypeId}
            />
          )
        ) : (
          <div className="container">
            <LmInfoBox
              dataTest="planWarning"
              className="infobox--full-width"
              variant="warning"
              description="Планы приема станут доступными для ввода после первого сохранения."
              hidenFooter
            />
            <Push size={24} />
          </div>
        )}
      </Panel>
      {!isCreate && (
        <>
          {serviceData?.educationTypeId === EducationTypeEnum.DayCareCentersEducation ? (
            <DayCarePlanModal
              show={showAddModal}
              setShowAddModal={setShowAddModal}
              planList={tgList}
              educationType={serviceData?.educationTypeId}
              serviceId={serviceData.id}
              postSubmit={postSubmit}
              currentIdPlan={currentIdPlan}
            />
          ) : serviceData?.educationTypeId === EducationTypeEnum.ProfessionalEducation ? (
            <PreparationPlanModal
              show={showAddModal}
              onClose={() => {
                setShowAddModal(false);
                setCurrentIdPlan(0);
              }}
              planData={tgList?.find(({ id }) => id === currentIdPlan)}
              postSubmit={postSubmit}
              serviceData={serviceData}
            />
          ) : serviceData?.educationTypeId === EducationTypeEnum.ChildrenEducation ||
            serviceData?.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ? (
                <ChildPlanModal
                  show={showAddModal}
                  onClose={() => {
                    setShowAddModal(false);
                    setCurrentIdPlan(0);
                  }}
                  planData={tgList?.find((p) => p.id === currentIdPlan)}
                  postSubmit={postSubmit}
                  serviceData={serviceData}
                />
              ) : (
                <TemplatePlanModal
                  show={showAddModal}
                  onClose={() => {
                    setShowAddModal(false);
                    setCurrentIdPlan(0);
                  }}
                  planData={tgList?.find((p) => p.id === currentIdPlan)}
                  postSubmit={postSubmit}
                  serviceData={serviceData}
                />
              )}
          <Dialog
            dataTest="deletePlan"
            isOpenDialog={!!idRemovedTg}
            title="Удаление плана приема"
            description="Вы уверены, что хотите удалить выбранный план приема? Обратите внимание, что вся информация о выбранном
            плане приема будет утеряна."
            variant="alert"
            buttonPrimaryText="Да, удалить"
            callCloseAfterSubmit
            onClickSeconadaryButton={() => {
              setIdRemovedTg(null);
            }}
            onClickPrimaryButton={handleDeleteModal}
          />
          <Dialog
            dataTest="recalculateCost"
            isOpenDialog={!!costTg}
            title="Изменение длительности учебного периода"
            description="Пересчитать стоимость обучения?"
            variant="alert"
            buttonPrimaryText="Да, продолжить"
            callCloseAfterSubmit
            onClickSeconadaryButton={() => {
              setCostTg(null);
            }}
            onClickPrimaryButton={handleRecalculateCost}
          />
        </>
      )}
    </>
  );
};

export default PlanTemplate;
