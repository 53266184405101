import { LmInput, LmInputProps } from '@mes-ui/lemma';

const MaskedInput = (props: LmInputProps) => {
  return (
    <LmInput
      guide={false}
      placeholderChar={'\u2000'}
      {...props}
    />
  );
};

export default MaskedInput;
