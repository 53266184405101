import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InteractiveChevronTemplate, LmButton, LmSubHeader, LmListItem } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ViewContainer from '../../../components/view-container';
import history from '../../../history';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { EventUrl } from '../../../mock-data/event';
import { historyState } from '../../../mock-data/history-state';
import DropDown from '../../../components/drop-down';
import ServiceClassRegistrySearch from './search';
import ServiceClassRegistryTable from './table';
import { routes } from '../../../config/constants';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { hasAccessObjectAny, hasGeneralAccess, redirect, replaceHistoryState } from '../../../lib/utils';
import { generalAccess } from '../../../types/authorization-data';
import { accessInitialForm, listMenuAddGroup } from '../../../lib/utils/service-class';
import { ListMenuAddGroup, SearchGroupTrainingInitialData } from '../../../types/service-class';
import { useGetDataEducationTypes } from '../../../hooks/get-education-types';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import useQuery from '../../../hooks/use-query';

export const searchGroupTrainingInitialFormData: Partial<SearchGroupTrainingInitialData> = {
  query: '',
  scheduleOfService: '',
  scheduleOfServiceText: '',
  usedCapacityFilter: 0,
  showArchive: false,
  educationTypeId: undefined,
};

const ServiceClassRegistry = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const accessMain = hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  const educationTypeData = useGetDataEducationTypes(
    userProfile,
    accessObject.ServiceClasses,
    accessAction.ViewRegistry
  );

  const queryServiceId = useQuery().get('serviceId');
  const queryShowArchive = useQuery().get('showArchive');
  const eventId = Number(useQuery().get(EventUrl.EventId));

  const [search, setSearch] = useState<SearchGroupTrainingInitialData>();

  useClearHistoryState();

  useEffect(() => {
    if (window.history.state[historyState.search]) {
      setSearch(window.history.state[historyState.search]);
    } else if (educationTypeData.length) {
      const params = {
        serviceId: queryServiceId ? parseInt(queryServiceId) : undefined,
        showArchive: !!queryShowArchive,
        eventId: eventId || undefined,
      };

      if (!accessMain && !isAdmin && educationTypeData[0]) {
        setSearch({
          ...searchGroupTrainingInitialFormData,
          ...accessInitialForm(userProfile, educationTypeData[0].value as number, educationTypeData[0].label),
          ...params,
        });
      } else if (educationTypeData.length === 1) {
        setSearch({
          ...searchGroupTrainingInitialFormData,
          ...params,
          educationTypeId: educationTypeData[0].value as number,
          educationTypeName: educationTypeData[0].label,
        });
      } else {
        setSearch(
          queryServiceId
            ? {
                ...searchGroupTrainingInitialFormData,
                serviceId: parseInt(queryServiceId) || 0,
                showArchive: !!queryShowArchive,
              }
            : {
                ...searchGroupTrainingInitialFormData,
                eventId: eventId || undefined,
              }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessMain, educationTypeData, isAdmin, userProfile]);

  const propsTable = {
    isAdmin,
    userProfile,
    search,
  };

  const menuList = listMenuAddGroup(userProfile, isAdmin);

  const accessCreateServiceClass = hasAccessObjectAny(
    userProfile,
    [
      accessObject.ServiceClassDayCare,
      accessObject.ServiceClassOP,
      accessObject.ServiceClassVA,
      accessObject.ServiceClassArtHouse,
      accessObject.ServiceClassDOGM,
      accessObject.ServiceClassNonDOGM,
      accessObject.ServiceClassSport,
    ],
    accessAction.Create
  );

  const handleSubmitSearch = useCallback(
    (values: SearchGroupTrainingInitialData) => {
      setSearch((prevState) => {
        if (prevState && history.location.search) {
          const { state } = window.history;

          if (!eventId) {
            history.replace({
              pathname: history.location.pathname,
              search: undefined,
            });
          }

          replaceHistoryState({
            ...state,
            [historyState.search]: values,
          });
        } else {
          replaceHistoryState({
            [historyState.search]: values,
          });
        }

        return values;
      });
    },
    [eventId]
  );

  return (
    <ViewContainer
      subHeader={
        <LmSubHeader
          sticky
          hideReturnButton
          description=""
          title="Группы обучения"
          dataTest="serviceClassesCreateSubHeader"
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Группы обучения',
            },
          ]}
          buttonsOrAnyActions={
            [
              {
                children: (
                  <DropDown
                    dataTest="addGroup"
                    component={(open) =>
                      accessCreateServiceClass && (
                        <LmButton
                          dataTest="addGroup"
                          type="button"
                        >
                          Добавить группу обучения
                          <InteractiveChevronTemplate open={open} />
                        </LmButton>
                      )
                    }
                  >
                    <>
                      {menuList.map((item: ListMenuAddGroup) => {
                        return (
                          <LmListItem
                            key={item.label}
                            dataTest={item.label}
                            text={item.label}
                            onClick={() => redirect(item.link)}
                          />
                        );
                      })}
                    </>
                  </DropDown>
                ),
              },
            ] as ISubHeaderTitleButton[]
          }
        />
      }
      filterComponent={
        <ServiceClassRegistrySearch
          isAdmin={isAdmin}
          initialForm={search}
          submitForm={handleSubmitSearch}
          userProfile={userProfile}
          educationTypeData={educationTypeData}
        />
      }
    >
      <ServiceClassRegistryTable {...propsTable} />
    </ViewContainer>
  );
};

export default ServiceClassRegistry;
