import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, array as arrayYup, string as stringYup } from 'yup';
import { useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import SavePanel from '../../../../components/save-panel';
import { EducationTypeEnum } from '../../../../types/education-type';
import { MAX_STAGE_NAME_LENGTH } from '../../utils';
import Photo from '../../components/panels/photo';
import Stage from '../panels/stage';
import AddressClass from '../../components/panels/address-class';
import { ServiceData, ServiceEditTextFileData, ServiceFileTextType, StageData } from '../../../../types/service';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import history from '../../../../history';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { CreateContactPerson } from '../../components/panels/contact-person/create-contact-person';
import EditServiceSchedule from '../../components/panels/service-schedule/edit';
import { convertScheduleToBack } from '../../../../lib/utils/time-table';
import Financing from '../components/financing';
import CreateDisciplines from './create-disciplines';
import Tests, { testValidationSchema } from '../../components/dkgm-dsit-template/panels/tests/tests-edit';
import Info from '../components/info';
import { userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { accessVedomst } from '../../../../mock-data/access-enum';
import LimitEdit from '../../components/dkgm-dsit-template/panels/limits/limit-edit';
import { infoValidationSchema } from '../panels/info';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import { financingValidationSchema } from '../panels/financing';
import { CreateServiceContext } from '../../create';

type Props = {
  cancelHandler: () => void;
};

const CreateDetails: React.FC<Props> = ({ cancelHandler }) => {
  const { serviceData, currentEducationType } = useContext(CreateServiceContext);
  const [programmFile, setProgrammFile] = useState<ServiceFileTextType>();
  const [ruleFile, setRuleFile] = useState<ServiceFileTextType>();
  const [load, setLoad] = useState<boolean>(false);
  const [scheduleError, setScheduleError] = useState<boolean>(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const vedomstvoId = serviceData?.info?.vedomstvoId || userProfile.vedomstvoId;

  const hasHoursPerWeek =
    vedomstvoId === accessVedomst.Dkgm && currentEducationType === EducationTypeEnum.ArtHouseEducation;

  const initialErrors = useInitialErrors(serviceData, getValidationSchema(hasHoursPerWeek));

  const canChangeCandidateRestriction =
    !serviceData?.candidateRestrictionType || serviceData.candidateRestrictionType.canChangeCandidateRestriction;

  const submitProgrammAndRules = async (serviceId: number, educationTypeId: number) => {
    const data: ServiceEditTextFileData = {
      serviceId,
      educationTypeId,
      isRuleServiceDeleted: typeof ruleFile === 'boolean' ? ruleFile : false,
      isProgrammServiceDeleted: typeof programmFile === 'boolean' ? programmFile : false,
      programmService:
        typeof programmFile !== 'boolean' && programmFile?.id && programmFile.id.startsWith('file')
          ? programmFile
          : undefined,
      ruleService:
        typeof ruleFile !== 'boolean' && ruleFile?.id && ruleFile.id.startsWith('file') ? ruleFile : undefined,
    };

    if (data.isRuleServiceDeleted || data.isProgrammServiceDeleted || data.ruleService || data.programmService) {
      await serviceTemplateApi.setTextFiles(data);
    }
  };

  const submitForm = async (values: ServiceData) => {
    setLoad(true);

    try {
      if (values.contactPerson) {
        values.contactPerson = {
          ...values.contactPerson,
          educationTypeId: values.educationTypeId,
          phone: addPhonePrefix(values.contactPerson.phone),
        };
      }
      const serviceId = await serviceTemplateApi.createService(values);

      await submitProgrammAndRules(serviceId, values.educationTypeId);
      history.push(
        generateLink(routes.service, {
          id: serviceId,
        })
      );
      setLoad(false);
    } catch (e) {
      setLoad(false);
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={serviceData}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema(hasHoursPerWeek)}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, isValid, setFieldValue } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Info
              parent="info"
              editMode
              programmFile={programmFile}
              setProgrammFile={setProgrammFile}
              ruleFile={ruleFile}
              setRuleFile={setRuleFile}
              isVisibleHoursPerWeek={hasHoursPerWeek}
            />

            {userProfile.vedomstvoId === accessVedomst.Kdc && (
              <>
                <Push size={12} />
                <LimitEdit
                  editMode={canChangeCandidateRestriction}
                  parent="candidateRestrictionType"
                  checkEditable={canChangeCandidateRestriction}
                />
              </>
            )}

            <CreateContactPerson parent="contactPerson" />

            <AddressClass
              editCount="one"
              label="Адрес предоставления услуги"
            />

            <Stage
              setFieldValue={setFieldValue}
              serviceData={serviceData}
            />

            <Push size={12} />

            <Financing
              parent="financing"
              editMode
            />

            <CreateDisciplines />

            <Push size={12} />

            <EditServiceSchedule
              title="Расписание предоставления услуги"
              submit={(schedule, selectedSchedule) => {
                setFieldValue('schedule.list', convertScheduleToBack(schedule));
                setFieldValue('schedule.scheduleTypeOfServiceName', selectedSchedule.label);
                setFieldValue('schedule.scheduleTypeOfServiceId', selectedSchedule.value);
                handleSubmit();
              }}
              scheduleSubmit={scheduleSubmit}
              setScheduleSubmit={setScheduleSubmit}
              serviceData={serviceData}
              setScheduleError={setScheduleError}
              canChangeSchedule={!!serviceData?.schedule?.canChangeSchedule}
            />

            <Tests
              parent="test"
              editMode
            />

            <Push size={12} />
            <Panel title={() => 'Планы приема'}>
              <div className="container">
                <LmInfoBox
                  dataTest="planWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Планы приема станут доступными для ввода после первого сохранения."
                  hidenFooter
                />
              </div>
              <Push size={24} />
            </Panel>

            <Push size={12} />
            <Panel title={() => 'Адрес приема документов'}>
              <div className="container">
                <LmInfoBox
                  dataTest="addressDocumentsWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Адреса приема документов станут доступными для ввода после первого сохранения."
                  hidenFooter
                />
              </div>
              <Push size={24} />
            </Panel>

            <Photo />

            <SavePanel
              buttonPrimaryText="Создать образовательную услугу"
              primaryButtonModifiers={{
                loading: load,
                disabled: !isValid || scheduleError,
              }}
              onClickSeconadaryButton={cancelHandler}
              onClickPrimaryButton={() => setScheduleSubmit(true)}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateDetails;

export const getValidationSchema = (hourPerWeek: boolean) =>
  objectYup().shape({
    info: infoValidationSchema(hourPerWeek),
    contactPerson: contactPersonValidationSchema(),
    financing: financingValidationSchema(),
    test: testValidationSchema(),
    stage: objectYup().shape({
      list: arrayYup()
        .test('name', 'укажите наименование этапа', function (val) {
          const stages = val as StageData[];

          if ((val?.length ?? 0) > 0) {
            for (let i = 0; i < stages.length; i++) {
              if (!stages[i].name) {
                return false;
              }
            }
          }

          return true;
        })
        .min(1, 'Необходимо добавить этапы обучения')
        .of(
          objectYup().shape({
            name: stringYup()
              .nullable()
              .max(MAX_STAGE_NAME_LENGTH, `Должно содержать не более ${MAX_STAGE_NAME_LENGTH} символов`),
          })
        ),
    }),
  });
