import React from 'react';
import { Heading, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import ChangePasswordForm from './forms/change-password';

const ChangePassword = () => {
  return (
    <>
      <LmInfoBox
        dataTest="newPasswordInfo"
        description="При первом входе в систему вам необходимо придумать новый пароль"
        variant="information"
        hidenFooter
      />
      <Push size={24} />
      <Heading
        label="Создание пароля"
        as="h3"
      />
      <Push size={16} />
      <ChangePasswordForm />
    </>
  );
};

export default ChangePassword;
