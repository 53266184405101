import React from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikInput from '../../../../components/formik/formik-input';
import { snilsMask } from '../../../../lib/utils/mask';

type SnilsProps = FieldProps<string> & {
  disabled?: boolean;
};
export const snilsField = 'snils';

const Snils = ({ label = 'СНИЛС', name, editMode, required, disabled }: SnilsProps) => {
  return (
    <FormikField name={name}>
      {({ field }: FormikFieldProps) => {
        const { value } = field;

        return (
          <Field
            label={label}
            editMode={editMode}
            required={required}
            value={value}
            size={3}
            defaultValue="—"
          >
            <FormikInput
              size="small"
              maskRegex={snilsMask}
              placeholder="XXX-XXX-XXX XX"
              name={name}
              disabled={disabled}
            />
          </Field>
        );
      }}
    </FormikField>
  );
};

export default Snils;
