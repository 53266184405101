import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LmIcon, LmTooltip, LmListItem } from '@mes-ui/lemma';
import TableOptions from '../../../../components/table-options';
import { routes } from '../../../../config/constants';
import history from '../../../../history';
import { generateLink, generateUrlSearchString, hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { formatDate, formatTime } from '../../../../lib/utils/date';
import { ScheduleData, TrainingGroupData } from '../../../../types/service';
import DropDown from '../../../../components/drop-down';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import { userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { accessVedomst, generalAccess } from '../../../../mock-data/access-enum';
import { accessAction, accessObject } from '../../../../types/authorization-data';
import { ServiceContext } from '../..';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { apply } from '../../../../lib/utils/service';
import SignModal from '../../../../components/sign-modal';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  list: TrainingGroupData[];
  getTGList: () => Promise<void>;
};

const TgList: React.FC<Props> = ({ list, getTGList }) => {
  const { serviceData } = useContext(ServiceContext);
  const [signTg, setSignTg] = useState<TrainingGroupData | undefined>();
  const handleEdit = (item: TrainingGroupData) => () => {
    const isRetro = item.scheduleList.length && item.scheduleList[0].isRetro ? 1 : 0;

    history.push(
      `${generateLink(routes.trainingGroup, {
        serviceId: item.serviceId ?? 0,
        id: item.id,
      })}?isRetro=${isRetro}&yearId=${item.yearOfTrainingId}`
    );
  };

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const canEdit = useCallback(
    (tg: TrainingGroupData) =>
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasAccessObjectAny(
          userProfile,
          [accessObject.ServiceArtHouse, accessObject.ServiceSport],
          accessAction.Edit
        )) &&
      tg?.scheduleList[0]?.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive,
    [userProfile]
  );

  const canEditButton = useCallback(
    (tg: TrainingGroupData) => canEdit(tg) && serviceData.serviceStatusId === ServiceStatusEnum.Draft,
    [canEdit, serviceData.serviceStatusId]
  );

  const canCreateRequest = useCallback(
    (tg: TrainingGroupData): boolean => {
      return (
        (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
          hasAccessObjectAny(
            userProfile,
            [accessObject.RequestArtHouse, accessObject.RequestSport],
            accessAction.Edit
          )) &&
        tg.scheduleList?.length > 0 &&
        tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
        tg.scheduleList[0].isAccessible &&
        (userProfile.vedomstvoId !== accessVedomst.Dsit || new Date().getMonth() > 9)
      );
    },
    [userProfile]
  );

  const canRemoveButton = useCallback(
    (tg: TrainingGroupData): boolean =>
      canEditButton(tg) &&
      (!tg.scheduleList?.length || tg.scheduleList.every((s: ScheduleData) => s.canDeleteSchedule)),
    [canEditButton]
  );

  const canPublicate = useCallback(
    (tg: TrainingGroupData): boolean =>
      canEdit(tg) &&
      tg.scheduleList?.length > 0 &&
      tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      (!hasGeneralAccess(userProfile, generalAccess.UseSign) || tg.scheduleList[0].isRetro),
    [canEdit, userProfile]
  );

  const canSign = useCallback(
    (tg: TrainingGroupData): boolean =>
      canEdit(tg) &&
      tg.scheduleList?.length > 0 &&
      tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      !tg.scheduleList[0].isRetro &&
      hasGeneralAccess(userProfile, generalAccess.UseSign),
    [canEdit, userProfile]
  );

  const canOnlyView = useCallback(
    (tg: TrainingGroupData): boolean =>
      tg.scheduleList?.length > 0 && tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Archive,
    []
  );

  const showDropdownMenu = useCallback(
    (tg: TrainingGroupData) =>
      canEditButton(tg) ||
      canCreateRequest(tg) ||
      canRemoveButton(tg) ||
      canPublicate(tg) ||
      canSign(tg) ||
      canOnlyView(tg),
    [canEditButton, canCreateRequest, canRemoveButton, canPublicate, canSign, canOnlyView]
  );

  const updateStatus = async (tgId: number, status: TrainingGroupStatusEnum) => {
    await serviceTemplateApi.updateTrainingGroupStatus({
      serviceId: serviceData.id,
      educationTypeId: serviceData.educationTypeId,
      trainingGroupId: tgId,
      trainingGroupStatusId: status,
    });
    getTGList();
  };

  const getStatusIcon = (status: TrainingGroupStatusEnum) => {
    return status === TrainingGroupStatusEnum.Signed ? (
      <LmIcon
        icon="filled-edit-checkmark-circle-outline"
        size={18}
        color="var(--LM-neutrals-day-700)"
      />
    ) : (
      <LmIcon
        icon="outline-notifications-info"
        size={18}
        color="var(--LM-neutrals-day-700)"
      />
    );
  };

  const getStatusText = (status: TrainingGroupStatusEnum) => {
    return (
      <div className="text-center">
        {status === TrainingGroupStatusEnum.Signed
          ? 'Подтверждено'
          : 'Для подачи заявлений необходимо, чтобы данная услуга была в статусе “Опубликовано на Mos.ru”, количество зачисленных заявлений не превышало количество мест и дата приема заявлений на  Mos.ru была не позже сегодняшней даты и текущего времени'}
      </div>
    );
  };

  const handleDelete = (id: number) => async () => {
    await serviceTemplateApi.deleteTrainingGroup(serviceData.id, id);
    getTGList();
  };

  return (
    <>
      <SimpleTable
        data={list}
        columns={[
          {
            dataIndex: 'date',
            title: 'Дата начала занятий',
            render: (item: any) =>
              item.educationStartDate && <span className="brand-link">{formatDate(item.educationStartDate)}</span>,
            width: '16%',
          },
          {
            dataIndex: 'stage',
            title: 'Этап обучения',
            render: (item: any) => <div className="overflow-wrap-anywhere">{item.serviceStageName}</div>,
            width: '16%',
          },
          {
            dataIndex: 'amount',
            title: 'План / Подано / Черновиков / Зачислено / Отклонено',
            render: (item: any) => {
              return (
                !!item?.scheduleList?.length && (
                  <>
                    {item.volume}/
                    {item.scheduleList[0].requestTotalCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestTotalCount}
                      </Link>
                    )}
                    /
                    {item.scheduleList[0].requestDraftCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            requestStatusId: RequestStatusEnum.Draft,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestDraftCount}
                      </Link>
                    )}
                    /
                    {item.scheduleList[0].requestIncludedCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            requestStatusId: RequestStatusEnum.Included,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestIncludedCount}
                      </Link>
                    )}
                    /
                    {item.scheduleList[0].requestDeclineCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            requestStatusId: RequestStatusEnum.IncludeDeclined,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestDeclineCount}
                      </Link>
                    )}
                  </>
                )
              );
            },
            width: '16%',
          },
          {
            dataIndex: 'teacher',
            title: 'Преподаватель',
            render: (item: any) => item.teacher,
          },
          {
            dataIndex: 'periodMosru',
            title: 'Период приема заявлений на mos.ru',
            render: (item: any) => {
              return !item.scheduleList[0]
                ? ''
                : item.scheduleList[0].isRetro
                  ? 'Без публикации на Mos.ru'
                  : `${formatDate(item.scheduleList[0].requestStart)} ${formatTime(
                    item.scheduleList[0].requestTimeStart
                  )} - ${formatDate(item.scheduleList[0].requestEnd)} ${formatTime(
                    item.scheduleList[0].requestTimeEnd
                  )}`;
            },
          },
          {
            dataIndex: 'status',
            title: 'Статус плана приема',
            render: (item: any) => (
              <div className="icon-group">
                <div className="icon-group__text">{item.scheduleList[0]?.trainingGroupStatusName}</div>
                <div className="icon-group__icon">
                  {item.scheduleList[0]?.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive && (
                    <LmTooltip
                      withArrow
                      placement="bottom"
                      content={getStatusText(item.scheduleList[0]?.trainingGroupStatusId)}
                    >
                      {getStatusIcon(item.scheduleList[0]?.trainingGroupStatusId)}
                    </LmTooltip>
                  )}
                </div>
              </div>
            ),
            width: '13%',
          },
          {
            dataIndex: '',
            title: '',
            render: (item: any) =>
              showDropdownMenu(item) && (
                <DropDown
                  dataTest="planOptions"
                  component={() => <TableOptions />}
                >
                  <>
                    {canCreateRequest(item) && (
                      <LmListItem
                        dataTest="addRequest"
                        text="Подать заявление"
                        icon="outline-files-file"
                        iconSize={20}
                        onClick={async () => {
                          item.scheduleList[0] && (await apply(item.scheduleList[0].id));
                        }}
                      />
                    )}
                    {canEditButton(item) && (
                      <LmListItem
                        dataTest="editPlan"
                        text="Редактировать"
                        icon="outline-edit-edit"
                        iconSize={20}
                        onClick={handleEdit(item)}
                      />
                    )}
                    {canOnlyView(item) && (
                      <LmListItem
                        dataTest="viewPlan"
                        text="Просмотреть"
                        icon="outline-view-eye"
                        iconSize={20}
                        onClick={handleEdit(item)}
                      />
                    )}
                    {canSign(item) && (
                      <LmListItem
                        dataTest="signPlan"
                        text="Подписать"
                        icon="outline-edit-edit"
                        iconSize={20}
                        onClick={() => {
                          setSignTg(item);
                        }}
                      />
                    )}
                    {canPublicate(item) && (
                      <LmListItem
                        dataTest="publishPlan"
                        text={item.scheduleList[0]?.isRetro ? 'Подтвердить' : 'Опубликовать'}
                        icon="outline-edit-checkmark-circle"
                        iconSize={20}
                        onClick={async () => {
                          updateStatus(item.id, TrainingGroupStatusEnum.Signed);
                        }}
                      />
                    )}
                    {canRemoveButton(item) && (
                      <LmListItem
                        dataTest="deletePlan"
                        text="Удалить"
                        icon="outline-edit-trash-alt"
                        iconSize={20}
                        onClick={handleDelete(item.id)}
                      />
                    )}
                  </>
                </DropDown>
              ),
            width: '40px',
          },
        ]}
      />
      <SignModal
        show={!!signTg}
        onCloseHandle={() => {
          setSignTg(undefined);
        }}
        ids={[
          {
            id: 0,
          },
        ]}
        getDataForSignHandle={async () => JSON.stringify(signTg)}
        setSignedDataHandle={async (id, sign) => {
          if (signTg) {
            try {
              await serviceTemplateApi.signTrainingGroup({
                serviceId: serviceData.id,
                educationTypeId: serviceData.educationTypeId,
                signedDocument: sign,
                trainingGroupId: signTg.id,
              });
              setSignTg(undefined);
              getTGList();
            } catch {}
          }
        }}
      />
    </>
  );
};

export default TgList;
