export enum TypesOfRepetitionEnum {
  // Без повторов
  NoRepeat = 3,
  // Еженедельно </summary>
  RepeatWeekly = 1,
  // Ежемесячно
  RepeatMonthly = 2,
  // Ежедневно
  RepeatDaily = 4,
}
