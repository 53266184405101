import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AuthOrganizationsType } from '../../types/auth';

import { AppState } from '../types/state';
import { AppRecord, CurrentProfileType, ProfilesType } from '../types/entities';

export const moduleName = 'app';

export const SET_LOADING = `${moduleName}/SET_LOADING`;
export const SET_AUTHORIZED = `${moduleName}/SET_AUTHORIZED`;
export const SET_LAST_ROUTE = `${moduleName}/SET_LAST_ROUTE`;
export const SET_PROFILES = `${moduleName}/SET_PROFILES`;
export const SET_CURRENT_PROFILE = `${moduleName}/SET_CURRENT_PROFILE`;

const reducerRecord = Object.freeze({
  loading: true,
  authorized: false,
  profiles: undefined,
  currentProfile: undefined,
});

// Reducer
export default function reducer(state: AppRecord = reducerRecord, action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case SET_AUTHORIZED:
      return {
        ...state,
        authorized: payload.authorized,
      };
    case SET_LAST_ROUTE:
      return {
        ...state,
        lastRoute: payload.lastRoute,
      };
    case SET_PROFILES:
      return {
        ...state,
        profiles: payload.profiles,
        currentProfile: payload.profiles?.length
          ? (payload.profiles as ProfilesType)?.find(({ id }) => state.currentProfile?.id === id) || payload.profiles[0]
          : undefined,
      };
    case SET_CURRENT_PROFILE:
      return {
        ...state,
        currentProfile: payload.currentProfile,
      };
    default:
      return state;
  }
}

// AC
export const setAuthorized = (authorized: boolean) => ({
  type: SET_AUTHORIZED,
  payload: {
    authorized,
  },
});

export const setLastRoute = (path?: string) => ({
  type: SET_LAST_ROUTE,
  payload: {
    lastRoute: path
      ? {
          path,
          activeUntil: Date.now() + 10 * 60 * 1000, // текущее время + 10 минут
        }
      : undefined,
  },
});

export const setProfilesFromOrganizations = (organizations: AuthOrganizationsType[]) => ({
  type: SET_PROFILES,
  payload: {
    profiles: organizations?.map(({ organizationName, login, roleList }) => ({
      id: login,
      title: organizationName,
      icon: 'filled-misc-organization',
      subtitle: roleList.filter((role) => role).join(', '),
    })),
  },
});

export const setProfiles = (profiles: ProfilesType) => ({
  type: SET_PROFILES,
  payload: {
    profiles,
  },
});

export const setCurrentProfiles = (currentProfile: CurrentProfileType) => ({
  type: SET_CURRENT_PROFILE,
  payload: {
    currentProfile,
  },
});

// Thunks
export const setLoading = (loading: boolean) => async (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => {
  dispatch({
    type: SET_LOADING,
    payload: {
      loading,
    },
  });
};

export const clearProfiles = () => async (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => {
  dispatch(setCurrentProfiles(undefined));
  dispatch(setProfiles(undefined));
};
