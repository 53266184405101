import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { pageSizeOptions } from '../../mock-data';
import { MegaRelationHistoryTypeEnum } from '../../mock-data/mega-relation-history-type-enum';
import { ListPage, ServiceClassHistoryData } from '../../types/service-class';
import { formatDate } from '../../lib/utils/date';
import { routes } from '../../config/constants';
import { generateLink } from '../../lib/utils';
import learnerApi from '../../lib/api/learner';
import serviceClassApi from '../../lib/api/service-class';
import AsyncTable from '../table/async-table';

type Props = {
  id: number;
  type: 'group' | 'learner';
};

const TransfersGroup: React.FC<Props> = ({ id, type }) => {
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0].value);
  const [pageNum, setPageNum] = useState(0);
  const [history, setHistory] = useState<ServiceClassHistoryData[]>([]);
  const [total, setTotal] = useState(0);

  const paginationRef = useRef<any>();

  useEffect(() => {
    const fetch = async () => {
      let data: ListPage<ServiceClassHistoryData>;

      switch (type) {
        case 'group':
          data = await serviceClassApi.getServiceClassHistory(
            id,
            MegaRelationHistoryTypeEnum.ToOtherClass,
            pageNum + 1,
            pageSize
          );
          break;
        case 'learner':
          data = await learnerApi.getHistory(id, MegaRelationHistoryTypeEnum.ToOtherClass, pageNum + 1, pageSize);
          break;
        default:
          data = {
            total: 0,
            items: [],
          };
          break;
      }

      setHistory(data.items);
      setTotal(data.total);

      if (data.total && pageNum) {
        paginationRef?.current?.scrollIntoView({
          block: 'end',
          behavior: 'auto',
        });
      }
    };

    if (id) {
      fetch();
    }
  }, [pageNum, pageSize, id, type]);

  return total ? (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            История переводов в другие группы
            <span className="color-gray-dark">
              {' \u00A0'}
              {total}
            </span>
          </>
        )}
      >
        <AsyncTable
          dataTest="transfersGroup"
          paginationRef={paginationRef}
          pageNum={pageNum}
          setPageNumber={setPageNum}
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={history}
          itemsAll={total}
          columns={[
            {
              width: '15%',
              dataIndex: 'eventDate',
              title: 'Дата изменения',
              render: (item: any) => formatDate(item.eventDate),
            },
            {
              width: '20%',
              dataIndex: 'pupilName',
              title: 'Ученик',
              render: (item: any) => (
                <Link
                  className="organization-table-name"
                  to={generateLink(routes.learner, {
                    id: item.pupilId,
                  })}
                  type="button"
                  target="_blank"
                >
                  {item.pupilName}
                </Link>
              ),
            },
            {
              width: '25%',
              dataIndex: 'eventTypeName',
              title: 'Действие',
              render: (item: any) => (
                <>
                  Перевод из группы{' '}
                  <Link
                    className="organization-table-name"
                    to={generateLink(routes.serviceClass, {
                      id: item.oldObject.id,
                    })}
                    type="button"
                    target="_blank"
                  >
                    {item.oldObject.value}
                  </Link>{' '}
                  ({item.oldObject.name}) в группу{' '}
                  <Link
                    className="organization-table-name"
                    to={generateLink(routes.serviceClass, {
                      id: item.newObject.id,
                    })}
                    type="button"
                    target="_blank"
                  >
                    {item.newObject.value}
                  </Link>{' '}
                  ({item.newObject.name})
                </>
              ),
            },
            {
              width: '20%',
              dataIndex: 'baseDocument',
              title: 'Основание',
              render: (item: any) => item.baseDocument,
            },
            {
              width: '20%',
              dataIndex: 'userName',
              title: 'Оператор',
              render: (item: any) => item.userName,
            },
          ]}
        />
      </Panel>
    </>
  ) : null;
};

export default TransfersGroup;
