// Результат выполнения запроса
export enum OperationResultEnum {
  // Успешно
  Success = 1,

  // Неуспешно
  Error = 2,

  // Всё еще выполняется
  Pending = 3,
}
