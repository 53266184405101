// Функция возвращает массив с требованиями к паролю
import { PasswordSettings } from '../../../types/auth';
import { PasswordRequirementType } from '../types/password';

export function getRequirements(passwordSettings: PasswordSettings): PasswordRequirementType[] {
  const result = [
    {
      label: `Должен содержать не менее ${passwordSettings.minLength} символов`,
      condition: (p: string) => p?.length >= passwordSettings.minLength,
    },
  ];

  switch (passwordSettings.layout) {
    case 1:
      result.push({
        label: 'Должен содержать только буквы латинского алфавита',
        condition: (password: string) => /[a-zA-Z]/.test(password) && !/[а-яА-Я]/.test(password) && password !== null,
      });
      break;
    case 2:
      result.push({
        label: 'Должен содержать только буквы русского алфавита',
        condition: (password: string) => !/[a-zA-Z]/.test(password) && /[а-яА-Я]/.test(password),
      });
      break;
    case 3:
      result.push({
        label: 'Должен содержать одновременно буквы латинского и русского алфавитов',
        condition: (password: string) => /[a-zA-Z]/.test(password) && /[а-яА-Я]/.test(password),
      });
      break;
    default:
      result.push({
        label: 'Может содержать буквы латинского и/или русского алфавитов',
        condition: (password: string) => /[a-zA-Z]/.test(password) || /[а-яА-Я]/.test(password),
      });
      break;
  }
  switch (passwordSettings.passwordCase) {
    case 1:
      result.push({
        label: 'Должен содержать буквы верхнего и нижнего регистров',
        condition: (password: string) => /[a-zа-я]/.test(password) && /[A-ZА-Я]/.test(password),
      });
      break;
    case 2:
      result.push({
        label: 'Должен содержать буквы только верхнего регистра',
        condition: (password: string) => !/[a-zа-я]/.test(password) && /[A-ZА-Я]/.test(password),
      });
      break;
    case 3:
      result.push({
        label: 'Должен содержать буквы только нижнего регистра',
        condition: (password: string) => /[a-zа-я]/.test(password) && !/[A-ZА-Я]/.test(password),
      });
      break;
    default: {
      result.push({
        label: 'Может содержать буквы верхнего и/или нижнего регистра',
        condition: (password: string) => /[a-zа-я]/.test(password) || /[A-ZА-Я]/.test(password),
      });
      break;
    }
  }
  switch (passwordSettings.specialSymbols) {
    case 2:
      result.push({
        label: 'Должен содержать хотя бы одну цифру',
        condition: (password: string) =>
          /[0-9]/.test(password) && // eslint-disable-next-line no-useless-escape
          !/[~`'!@#$%\\\^&\*\(\)\-_\+=\/\{\}\[\]\.,\?|]/.test(password),
      });
      break;
    case 3:
      result.push({
        label: 'Должен содержать хотя бы одну цифру и хотя бы один спец. символ',
        condition: (password: string) =>
          /[0-9]/.test(password) && // eslint-disable-next-line no-useless-escape
          /[~`'!@#$%\\\^&\*\(\)\-_\+=\/\{\}\[\]\.,\?|]/.test(password),
      });
      break;
    default: {
      result.push({
        label: 'Может (не обязательно) содержать спец. символы и/или цифры',
        condition: (password: string) => !!password,
      });
    }
  }

  return result;
}

// Функция для проверки пароля на валидность. Пароль валидный, если выполняются все требования
export const validatePassword = (password: string | null = '', passwordSettings: PasswordSettings) =>
  getRequirements(passwordSettings).every((item) => item.condition(password || ''));
