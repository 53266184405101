import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Panel, OrderByType } from '@mosru/esz_uikit';
import { pageSizeOptions } from '../../mock-data';
import { initialChangeHistoryFilterFormData } from '../../mock-data/change-history-filter';
import { HistoryData, HistoryFilterFormData } from '../../types/history';
import { EntityTypeEnum } from '../../mock-data/entity-type-enum';
import securityApi from '../../lib/api/security';
import { maxRegistryRows } from '../../config/constants';
import { sliceText } from '../../lib/utils';
import AsyncTable from '../table/async-table';
import ViewContainer from '../view-container';
import ChangeHistorySearch from './search';

type Props = {
  name: string;
  extendEntityGuid?: string;
  entityTypes: EntityTypeEnum[];
  title?: string;
  defaultLoading?: boolean;
  noFilter?: boolean;
  search?: HistoryFilterFormData;
};

const ChangeHistory = ({
  extendEntityGuid,
  entityTypes,
  name,
  title,
  defaultLoading = false,
  noFilter,
  search,
}: Props) => {
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0].value);
  const [pageNum, setPageNum] = useState(0);
  const [searchData, setSearchData] = useState<HistoryFilterFormData>(search || initialChangeHistoryFilterFormData);
  const [tableData, setTableData] = useState<HistoryData[]>([]);
  const [tableTotal, setTableTotal] = useState(0);
  const [orderBy, setOrderBy] = useState<OrderByType>({
    field: null,
    direction: 'asc',
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (search) {
      setSearchData(search);
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      if (extendEntityGuid) {
        const data = await securityApi.getHistory({
          actionType: searchData.action || undefined,
          dateFrom: new Date(
            moment(Array.isArray(searchData.date) ? searchData.date[0] : searchData.date).format('yyyy-MM-DD')
          ),
          dateTo: new Date(
            moment(Array.isArray(searchData.date) ? searchData.date[1] : searchData.date).format('yyyy-MM-DD')
          ),
          extendEntityGuids: [extendEntityGuid],
          fieldName: searchData.field || undefined,
          pageSize,
          pageNumber: pageNum + 1,
          orderBy: orderBy.field,
        });

        setTableTotal(data.total);
        setTableData(data.items);
        setLoading(false);
      }
    };

    fetch();
  }, [searchData, pageNum, pageSize, extendEntityGuid, orderBy]);

  const editDescription = (item: string) => {
    const str = item
      .split('\r\n')
      .filter((s) => s)
      .map((s) => `<div>${s.trim()}</div>`)
      .join(' ');

    return {
      __html: `${str}`,
    };
  };

  return (
    <ViewContainer
      noContainer={noFilter}
      filterComponent={
        <ChangeHistorySearch
          searchData={searchData}
          onChangeSearch={setSearchData}
          entityTypes={entityTypes}
        />
      }
    >
      <Panel
        title={() => (
          <>
            {title || 'Записей'}
            <span className="color-gray-dark">
              {' \u00A0'}
              {tableTotal}
            </span>
          </>
        )}
      >
        {/*
        defaultLoading - нужен чтобы в группах обучения, в истории изменений
        скролл не багался, когда мы изменяем pageNumber
        */}
        <AsyncTable
          dataTest="changeHistory"
          loading={loading && !defaultLoading}
          scrollUp
          pageNum={pageNum}
          setPageNumber={setPageNum}
          rowLimit={maxRegistryRows}
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={tableData}
          itemsAll={tableTotal}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          loaderTitle={`Загружаем ${name}`}
          loaderDescription="Нужно немного подождать"
          columns={[
            {
              width: '220px',
              dataIndex: 'dateTime',
              title: 'Дата и время',
              render: (item: any) => moment(item.dateTime).format('DD.MM.yyyy HH:mm'),
            },
            {
              width: '220px',
              dataIndex: 'user',
              title: 'Пользователь',
              render: (item: any) => item.user,
            },
            {
              width: '160px',
              dataIndex: 'action',
              title: 'Действие',
              render: (item: any) => item.action,
            },
            {
              dataIndex: 'description',
              title: 'Комментарий',
              render: (item: any) => <div dangerouslySetInnerHTML={editDescription(sliceText(item.description))} />,
            },
          ]}
        />
      </Panel>
    </ViewContainer>
  );
};

export default ChangeHistory;
