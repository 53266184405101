import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import {
  EducationType,
  RequestStatus,
  ContractStatus,
  RequestSource,
  YearOfTraining,
  MoscowRegistration,
  PrivilegeCategory,
  Vedomstvo,
  PagingList,
  SelectOptionType,
  ContractDeclineReason,
} from '../../types/entities';
import { FormTypeEnum } from '../../mock-data/form-type-enum';
import { PlaceServices } from '../../types/place-service';
import { ItemsWork } from '../../types/items-work';

export const getEducationTypes = async (): Promise<EducationType[]> => {
  const response: PagingList<EducationType> = await fetchRequest.get(
    `${apiService.data}/Dictionary/EducationType/List`
  );

  return response.items;
};

export const getRequestStatuses = async (): Promise<SelectOptionType[]> => {
  const reqStatList: PagingList<RequestStatus> = await fetchRequest.get(
    `${apiService.data}/Dictionary/RequestStatus/List`,
    {
      pageNumber: 1,
      pageSize: 1000,
      isOnlyActual: true,
    }
  );

  return reqStatList.items.map((item: RequestStatus) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getRequestSources = async (): Promise<SelectOptionType[]> => {
  const reqSrcList: PagingList<RequestSource> = await fetchRequest.get(
    `${apiService.data}/Dictionary/RequestSource/List`,
    {}
  );

  return reqSrcList.items.map((item: RequestSource) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getContractStatuses = async (): Promise<SelectOptionType[]> => {
  const contractStatList: PagingList<ContractStatus> = await fetchRequest.get(
    `${apiService.data}/Dictionary/ContractStatus/List`,
    {}
  );

  return contractStatList.items.map((item) => ({
    value: item.id,
    label: item.contractStatusName,
  }));
};

export const getContractDeclineReasonOptions = async (educationTypeId?: number): Promise<SelectOptionType[]> => {
  const contractStatList: PagingList<ContractDeclineReason> = await fetchRequest.get(
    `${apiService.data}/Dictionary/ContractDeclineReason/List`,
    {
      educationTypeId,
    }
  );

  return contractStatList.items.map((item) => ({
    value: item.id,
    label: item.declineReasonName,
  }));
};

export const getPrivilegeCategories = async (): Promise<SelectOptionType[]> => {
  const privilegies: PagingList<PrivilegeCategory> = await fetchRequest.get(
    `${apiService.data}/Dictionary/PrivilegeCategory/List`,
    {}
  );

  return privilegies.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getYearOfTrainings = async (): Promise<SelectOptionType[]> => {
  const yearOfTrainingList: PagingList<YearOfTraining> = await fetchRequest.get(
    `${apiService.data}/Dictionary/YearOfTraining/List`,
    {}
  );

  return yearOfTrainingList.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getCurrentYearOfTrainings = async (): Promise<YearOfTraining> => {
  return await fetchRequest.get(`${apiService.data}/Dictionary/YearOfTraining/Current`);
};

export const getMoscowRegistrationTypes = async (): Promise<SelectOptionType[]> => {
  const moscowRegistrationList: PagingList<MoscowRegistration> = await fetchRequest.get(
    `${apiService.data}/Dictionary/MoscowRegistration/List`,
    {}
  );

  return moscowRegistrationList.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getDocumentTypes = async (formTypeId?: FormTypeEnum): Promise<SelectOptionType[]> => {
  const documentTypeList: PagingList<{ id: number; name: string; isArchive: boolean; forPersonalData: boolean }> =
    await fetchRequest.get(`${apiService.data}/Dictionary/DocumentType/List`, {
      formTypeId,
    });

  return documentTypeList.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getTypeRepresentations = async (): Promise<SelectOptionType[]> => {
  const typeRepresentationList: PagingList<{ id: number; name: string; isArchive: boolean }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/TypeRepresentation/List`,
    {}
  );

  return typeRepresentationList.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getLetters = async (orgId: number): Promise<SelectOptionType[]> => {
  const letters: PagingList<{ id: number; letterName: string }> = await fetchRequest.get(
    `${apiService.data}/DictionaryRead/ClassLetter/List/${orgId}`,
    {}
  );

  return letters.items.map((item) => ({
    value: item.id,
    label: item.letterName,
  }));
};

export const getParallels = async (orgId: number): Promise<SelectOptionType[]> => {
  const letters: PagingList<{ id: number; parallelName: string }> = await fetchRequest.get(
    `${apiService.data}/DictionaryRead/ClassParallel/List/${orgId}`,
    {}
  );

  return letters.items.map((item) => ({
    value: item.id,
    label: item.parallelName,
  }));
};

export const getGenders = async (): Promise<SelectOptionType[]> => {
  const genders: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/Sex/List`,
    {}
  );

  return genders.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getDistricts = async (): Promise<SelectOptionType[]> => {
  const districts: PagingList<{ id: number; terEntityName: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/District/List`,
    {}
  );

  return districts.items.map((item) => ({
    value: item.id,
    label: item.terEntityName,
  }));
};

export const getVedomstvo = async (fullName?: boolean): Promise<SelectOptionType[]> => {
  const data: PagingList<Vedomstvo> = await fetchRequest.get(`${apiService.data}/Dictionary/Vedomstvo/List`, {});

  return data.items?.map((item) => {
    return {
      value: item.id,
      label: fullName ? item.exBodyFullName : item.exBodyShortName,
    };
  });
};

export const getVisitTypes = async (withCheck = false, educationTypeId?: number): Promise<SelectOptionType[]> => {
  const visitTypes: PagingList<{ id: number; attendanceName: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/TypeOfAttendance/List?withCheck=${withCheck}${
      educationTypeId ? `&educationTypeId=${educationTypeId}` : ''
    }`
  );

  return visitTypes?.items.map((item) => ({
    value: item.id,
    label: item.attendanceName,
  }));
};

export const getStatuses = async (educarionTypeId: number): Promise<SelectOptionType[]> => {
  const statuses: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/ServiceStatus/List?educationTypeId=${educarionTypeId}`
  );

  return statuses?.items.map((item: { id: number; name: string }) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getTrainingGroupStatus = async (): Promise<SelectOptionType[]> => {
  const trainingGroupStatuses: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/TrainingGroupStatus/List`,
    {}
  );

  return trainingGroupStatuses?.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getTypeIssuePlace = async (): Promise<SelectOptionType[]> => {
  const districts: PagingList<{ id: number; issuePlaceName: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/TypeIssuePlace/List`,
    {}
  );

  return districts.items.map((item) => ({
    value: item.id,
    label: item.issuePlaceName,
  }));
};

export const getPupilDeclineReason = async (id: number): Promise<SelectOptionType> => {
  const declineReason = await fetchRequest.get(`${apiService.data}/Dictionary/PupilDeclineReason/${id}`);

  return {
    value: declineReason.id,
    label: declineReason.name,
  };
};

export const postListPupilDeclineReason = async (
  educationTypeId: number,
  vedomstvoId: number
): Promise<SelectOptionType[]> => {
  const reasons: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/PupilDeclineReason/List/${educationTypeId}/${vedomstvoId}`,
    {
      pageNumber: 0,
      pageSize: 1000,
      isOnlyActual: true,
      formTypeId: 1,
    }
  );

  return reasons.items.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

export const getParallelsByService = async (serviceId: number): Promise<SelectOptionType[]> => {
  const letters: PagingList<{ id: number; parallelName: string }> = await fetchRequest.post(
    `${apiService.data}/DictionaryRead/Service/ClassParallel/List/${serviceId}`,
    {
      serviceId,
    }
  );

  return letters.items.map((item) => ({
    value: item.id,
    label: item.parallelName,
  }));
};

export const getPlaceServiceList = async (organizationId: string | number): Promise<PlaceServices[]> => {
  const response: PagingList<PlaceServices> = await fetchRequest.get(
    `${apiService.data}/Dictionary/PlaceService/List/${organizationId}`,
    {}
  );

  return response.items;
};

export const getPlaceService = async (id: number): Promise<any> => {
  return await fetchRequest.get(`${apiService.data}/Dictionary/PlaceService/${id}`);
};

export const postPlaceService = async (data: any): Promise<PlaceServices> => {
  return await fetchRequest.post(`${apiService.data}/Dictionary/PlaceService`, data);
};

export const updatePlaceService = async (id: number, obj: PlaceServices) => {
  return await fetchRequest.put(`${apiService.data}/Dictionary/PlaceService/${id}`, obj);
};

export const deletePlaceService = async (id: { ids: number[]; organizationId: number }) => {
  return await fetchRequest.delete(
    `${apiService.data}/Dictionary/PlaceService?organizationId=${id.organizationId}`,
    undefined,
    {},
    {
      arrayFormat: 'bracket',
    },
    id.ids
  );
};

export const getItemsWorkList = async (organizationId?: number | string): Promise<ItemsWork[]> => {
  const response: PagingList<ItemsWork> = await fetchRequest.get(
    `${apiService.data}/Dictionary/ItemWork/List/${organizationId}`,
    {}
  );

  return response.items;
};

export const getItemWork = async (id: number): Promise<ItemsWork> => {
  return await fetchRequest.get(`${apiService.data}/Dictionary/ItemWork/${id}`);
};

export const deleteItemWork = async (id: { ids: number[]; organizationId: number }) => {
  return await fetchRequest.delete(
    `${apiService.data}/Dictionary/ItemWork?organizationId=${id.organizationId}`,
    undefined,
    {},
    {
      arrayFormat: 'bracket',
    },
    id.ids
  );
};

export const updateItemWork = async (id: number, obj: ItemsWork) =>
  await fetchRequest.put(`${apiService.data}/Dictionary/ItemWork/${id}`, obj);

export const saveItemWork = async (obj: ItemsWork) =>
  await fetchRequest.post(`${apiService.data}/Dictionary/ItemWork`, obj);

export const getServiceForms = async (): Promise<SelectOptionType[]> => {
  const visitTypes: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/ServiceForm/List`,
    {}
  );

  return visitTypes?.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getTypeValueServiceList = async (): Promise<SelectOptionType[]> => {
  const visitTypes: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/TypeValueService/List`,
    {}
  );

  return visitTypes?.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getTypeFinancingList = async (withCheck = false): Promise<SelectOptionType[]> => {
  const visitTypes: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/TypeFinancing/List?withCheck=${withCheck}`
  );

  return visitTypes?.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getScheduleTypes = async (): Promise<SelectOptionType[]> => {
  const genders: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/ScheduleTypeOfService/List`,
    {}
  );

  return genders.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getDurationOfTrainingUnitList = async (): Promise<SelectOptionType[]> => {
  const genders: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/DurationOfTrainingUnit/List`,
    {}
  );

  return genders.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const getCandidateRestrictionTypeList = async (): Promise<SelectOptionType[]> => {
  const genders: PagingList<{ id: number; name: string }> = await fetchRequest.get(
    `${apiService.data}/Dictionary/CandidateRestrictionType/List`,
    {}
  );

  return genders.items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const dictionariesApi = {
  getEducationTypes,
  getRequestStatuses,
  getRequestSources,
  getContractStatuses,
  getYearOfTrainings,
  getCurrentYearOfTrainings,
  getMoscowRegistrationTypes,
  getDocumentTypes,
  getPrivilegeCategories,
  getTypeRepresentations,
  getLetters,
  getParallels,
  getGenders,
  getDistricts,
  getVedomstvo,
  getVisitTypes,
  getStatuses,
  getTrainingGroupStatus,
  getTypeIssuePlace,
  getPupilDeclineReason,
  postListPupilDeclineReason,
  getParallelsByService,
  getPlaceServiceList,
  getPlaceService,
  postPlaceService,
  updatePlaceService,
  deletePlaceService,
  getItemsWorkList,
  getItemWork,
  deleteItemWork,
  updateItemWork,
  saveItemWork,
  getServiceForms,
  getTypeValueServiceList,
  getTypeFinancingList,
  getScheduleTypes,
  getDurationOfTrainingUnitList,
  getCandidateRestrictionTypeList,
  getContractDeclineReasonOptions,
};
