import React, { useCallback } from 'react';
import { useField } from 'formik';
import moment, { MomentInput } from 'moment';
import FormikDatePicker, { FormicDatePickerProps } from '../../../../../components/formik/formik-datepicker';

type Props = Pick<FormicDatePickerProps, 'disabled' | 'showErrorImmediately' | 'placeholder' | 'placement'> & {
  name: string;
  maxDate?: boolean;
  minDate?: boolean;
  dependentDateName?: string;
  dependentTimeName?: string;
  disablePortal?: boolean;
};

const DateField: React.FC<Props> = ({
  name,
  dependentTimeName,
  disabled,
  showErrorImmediately,
  placeholder = 'ДД.ММ.ГГГГ',
  placement,
  disablePortal = false,
}) => {
  const [, , fieldHelper] = useField(name);
  const [dependentTimeFieldName] = useField(dependentTimeName || '');

  // Обработчик для установки даты с текущим временем.
  const onChange = useCallback(
    (date: Date | Date[] | null) => {
      if (dependentTimeName && date) {
        const timeValue = dependentTimeFieldName.value ? dependentTimeFieldName.value : '00:00';

        const [hours, minutes] = timeValue.split(':');
        const newDateWithTime = moment(date as MomentInput)
          .set('hour', hours)
          .set('minute', minutes)
          .toDate();

        fieldHelper.setValue(newDateWithTime);
      }
    },
    [dependentTimeFieldName.value, dependentTimeName, fieldHelper]
  );

  return (
    <div className="flex-auto">
      <FormikDatePicker
        placeholder={placeholder}
        size="small"
        name={name}
        onChangeValue={onChange}
        disabled={disabled}
        showErrorImmediately={showErrorImmediately}
        placement={placement}
        disablePortal={disablePortal}
      />
    </div>
  );
};

export default DateField;
