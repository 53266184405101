import React, { useContext, useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import SavePanel from '../../../../components/save-panel';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { TypeFinancingEnum } from '../../../../mock-data/type-financing-enum';
import { EducationTypeEnum } from '../../../../types/education-type';
import { FinancingData, TemplateService } from '../../../../types/service';
import { ServiceContext } from '../..';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import FormFinancing from './form';
import { MAX_VALUE } from '../../../../lib/utils/validation';
import { replaceDotsWithCommas } from '../../../../lib/utils';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

export type Finance = TemplateService.Financing | FinancingData;

const Financing: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const initialValues = serviceData?.financing ?? {};
  const initialErrors = useInitialErrors(initialValues, financingValidationSchema);

  const onlyView =
    serviceData?.educationTypeId === EducationTypeEnum.ProfessionalEducation &&
    initialValues?.typeFinancingId === TypeFinancingEnum.Free;

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const checkEditable =
    !onlyView &&
    !editMode &&
    setEditModeParent &&
    serviceData.serviceStatusId === ServiceStatusEnum.Draft &&
    accessPanelEdit;

  const handleEdit = () => {
    setEditModeParent?.('financing');
    setEditMode(true);
  };

  const handleCancel = (resetForm: () => void) => {
    setEditModeParent?.(null);
    setEditMode(false);
    resetForm();
  };

  const submitForm = async (values: Finance) => {
    setLoadBtn(true);

    try {
      await serviceTemplateApi.updateFinancing(serviceData.id, values);
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
      setLoadBtn(false);
    } finally {
      setLoadBtn(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      validationSchema={financingValidationSchema}
      onSubmit={(values) => submitForm(values)}
    >
      {({ values, isValid, resetForm, handleSubmit, submitForm }: FormikProps<Finance>) => (
        <form onSubmit={handleSubmit}>
          <Push size={12} />
          <Panel
            title={() => 'Финансирование'}
            headingControl={() => (
              <>
                {checkEditable && (
                  <button
                    type="button"
                    onClick={handleEdit}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                )}
              </>
            )}
          >
            <FormFinancing
              values={values}
              editMode={editMode}
            />
          </Panel>

          {editMode && setEditModeParent && (
            <SavePanel
              primaryButtonModifiers={{
                loading: loadBtn,
                disabled: !isValid,
              }}
              onClickSeconadaryButton={() => handleCancel(resetForm)}
              onClickPrimaryButton={submitForm}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default Financing;

export const financingValidationSchema = objectYup().shape({
  periodPrice: stringYup()
    .nullable()
    .test((value: string | null | undefined, ctx: any) => {
      const parent = ctx.from[0];

      if (parent?.value?.typeFinancingId === 2) {
        if (!value) {
          return ctx.createError({
            message: 'Необходимо указать стоимость за месяц',
          });
        } else if (parseFloat(value) > MAX_VALUE) {
          return ctx.createError({
            message: `Значение не может быть больше ${replaceDotsWithCommas(MAX_VALUE)}`,
          });
        } else if (parseFloat(value) < 1) {
          return ctx.createError({
            message: 'Необходимо указать стоимость за месяц',
          });
        } else if (parseFloat(value) < parseFloat(parent?.value?.lessonPrice)) {
          return ctx.createError({
            message: ' ',
          });
        }
      }

      return true;
    }),
  lessonPrice: stringYup()
    .nullable()
    .test((value: string | null | undefined, ctx: any) => {
      const parent = ctx.from[0];

      if (parent?.value?.typeFinancingId === 2) {
        if (!value) {
          return ctx.createError({
            message: 'Необходимо указать стоимость за занятие',
          });
        } else if (parseFloat(value) > MAX_VALUE) {
          return ctx.createError({
            message: `Значение не может быть больше ${replaceDotsWithCommas(MAX_VALUE)}`,
          });
        } else if (parseFloat(value) < 1) {
          return ctx.createError({
            message: 'Необходимо указать стоимость за занятие',
          });
        } else if (parseFloat(value) > parseFloat(parent?.value?.periodPrice)) {
          return ctx.createError({
            message: ' ',
          });
        }
      }

      return true;
    }),
});
