import { useEffect, useState } from 'react';
import { dictionariesApi } from '../lib/api/dictionaries';
import { filterEducations } from '../lib/utils/education';
import { AuthorizationData } from '../types/authorization-data';
import { SelectOptionType } from '../types/entities';

export const useGetDataEducationTypes = (
  userProfile: AuthorizationData,
  show: number,
  action: number
): SelectOptionType[] => {
  const [educationTypeData, setEducationTypeData] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    const fetchEducationTypes = async () => {
      const educationTypes = await dictionariesApi.getEducationTypes();

      const edTypes = filterEducations(
        userProfile.objectAccessActionList,
        educationTypes,
        undefined,
        show,
        action,
        true
      );

      if (edTypes.length > 1) {
        setEducationTypeData([
          {
            label: 'Все',
            value: null,
          },
          ...edTypes,
        ]);
      } else {
        setEducationTypeData(edTypes);
      }
    };

    fetchEducationTypes();
  }, [action, setEducationTypeData, show, userProfile.objectAccessActionList]);

  return educationTypeData;
};
