import React, { useContext } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmTab, LmTabs, LmTabsList } from '@mes-ui/lemma';
import { ServiceClassTableContext } from '../../utils';

const TabsGroup: React.FC = () => {
  const { setActive, setSelected, setOpenDetails, setPageNum, active } = useContext(ServiceClassTableContext);

  const onChangeActiveTab = (active: boolean) => {
    setActive(active);
    setPageNum(0);
    setSelected([]);
    setOpenDetails(false);
  };

  return (
    <div className="flex items-center">
      Состав группы
      <Push
        orientation="horizontal"
        size={16}
      />
      <div className="font-weight-base">
        <LmTabs
          dataTest="serviceClassPillTabs"
          initialState={{
            tabsColor: 'accent',
            tabsSize: 'medium',
            tabsType: 'Button',
          }}
        >
          <LmTabsList dataTest="serviceClassPillTabs">
            <LmTab
              dataTest={`serviceClassPillTab${active ? '-activeEnrolled' : ''}`}
              title="Зачислены"
              value={1}
              onClick={() => onChangeActiveTab(true)}
            />
            <LmTab
              dataTest={`serviceClassPillTab${!active ? '-activeDeducedOrTransferred' : ''}`}
              title="Отчислены / Переведены"
              value={2}
              onClick={() => onChangeActiveTab(false)}
            />
          </LmTabsList>
        </LmTabs>
      </div>
      <Push
        orientation="horizontal"
        size={16}
      />
    </div>
  );
};

export default TabsGroup;
