import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmBadgeStatus } from '@mes-ui/lemma';
import { generateLink, getServiceEditLink, hasAccessObjectAny } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { textColorByStatusId } from '../../../../lib/utils/service-class';
import { formatDate } from '../../../../lib/utils/date';
import { GetCheckboxColumn } from '../../components/table/chekbox-column';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { EducationTypeEnum } from '../../../../types/education-type';
import { LoaderCustom } from '../../../../components/loader-custom';
import AsyncTable from '../../../../components/table/async-table';

const TableGroupComposition = () => {
  const {
    loading,
    setSelected,
    setOpenDetails,
    pageNum,
    pageSize,
    totalCount,
    setPageNum,
    setPageSize,
    learners,
    selected,
  } = useContext(ServiceClassTableContext);

  const { isArchiveGroup } = useContext(ServiceClassContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const hasEditAccess = hasAccessObjectAny(userProfile, [accessObject.ServiceClassSport], accessAction.Edit);

  return !loading ? (
    <AsyncTable
      dataTest="pupils"
      itemsAll={totalCount}
      pageNum={pageNum}
      setPageNumber={setPageNum}
      data={learners}
      pageSize={pageSize}
      setPageSize={setPageSize}
      columns={[
        GetCheckboxColumn(selected, learners, setSelected, setOpenDetails, isArchiveGroup || !hasEditAccess),
        {
          dataIndex: 'count',
          title: '№',
          render: (item: any) => item.count,
          width: '40px',
        },
        {
          dataIndex: 'name',
          title: 'ФИО',
          width: '22%',
          render: (item: any) => (
            <>
              <div className="icon-group">
                <div
                  className="icon-group__text cursor-pointer text-decoration-underline"
                  onClick={() => {
                    window.open(
                      generateLink(routes.learner, {
                        id: item.pupilId,
                      }),
                      '_blank'
                    );
                  }}
                >
                  {item.pupilName}
                </div>
              </div>
              <Push size={4} />
              <span className={textColorByStatusId[item.statusId]}>{item.statusName}</span>
              {item?.subclassList.length > 0 && (
                <>
                  <Push size={17} />
                  <div className="flex wrap">
                    {item?.subclassList?.map((subItem: any) => {
                      return (
                        <div
                          style={{
                            marginBottom: '4px',
                            marginRight: '4px',
                          }}
                        >
                          <LmBadgeStatus dataTest="subclassStatus">{subItem.name}</LmBadgeStatus>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          ),
        },
        {
          dataIndex: 'specialization',
          title: 'Специализация',
          width: '30%',
          render: (item: any) => (
            <div
              className="text-decoration-underline cursor-pointer"
              onClick={() =>
                window.open(getServiceEditLink(EducationTypeEnum.SportEducation, item.serviceId), '_blank')
              }
            >
              {item.classificatorEKUName}, {item.servicePeriod}
            </div>
          ),
        },
        {
          dataIndex: 'level',
          title: 'Уровень программы / этап',
          render: (item: any) => (
            <>
              {item.programmLevelName}
              <Push size={4} />
              <span className="color-gray-dark overflow-wrap-anywhere">{item.serviceStageName}</span>
            </>
          ),
        },
        {
          dataIndex: 'dates',
          title: 'Дата начала занятий',
          render: (item: any) => {
            return (
              <div>
                <span>{item.dateLearnStart ? formatDate(item.dateLearnStart) : ''}</span>
              </div>
            );
          },
          width: '16%',
        },
      ]}
    />
  ) : (
    <LoaderCustom size={180} />
  );
};

export default TableGroupComposition;
