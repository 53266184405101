import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import { routes } from '../../config/constants';
import history from '../../history';

const SwitcherForm = () => {
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  const handleClick = (value: any) => {
    setValue(value);
    history.push(`${value}${location.search}`);
  };

  return (
    <LmSwitcher
      dataTest="variantLogin"
      value={value}
      onChange={handleClick}
      variant="gray"
    >
      <LmSwitcherOption
        dataTest={`switcher-${routes.login}`}
        value={routes.login}
      >
        Логин и пароль
      </LmSwitcherOption>
      <LmSwitcherOption
        dataTest={`switcher-${routes.sign}`}
        value={routes.sign}
      >
        Электронная подпись
      </LmSwitcherOption>
    </LmSwitcher>
  );
};

export default SwitcherForm;
