import React, { useEffect, useMemo, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object as objectYup, string as stringYup } from 'yup';
import FormikInput from '../../components/formik/formik-input';
import Popup from '../../components/modals/popup';
import { authApi } from '../../lib/api';
import { copyTextToClipboard } from '../../lib/utils';
import { notify } from '../../redux/ducks/notifications';
import { userProfileSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';
import { defaultPasswordSettings, PasswordSettings } from '../../types/auth';
import PasswordRequirement from '../password-rules/requirement';
import PasswordStrength from '../password-rules/strength';
import { PasswordRequirementType } from '../password-rules/types/password';
import { RoomPasswordType } from '../password-rules/types/room';
import { getRequirements, validatePassword } from '../password-rules/utils';

type ChangePasswordModalProps = {
  show: boolean;
  requirements?: PasswordRequirementType[];
  onClose: () => void;
};

const initialPasswordData: RoomPasswordType = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const ChangePasswordModal = ({ show, requirements, onClose }: ChangePasswordModalProps) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const dispatch = useDispatch();

  const [passwordSettings, setPasswordSettings] = useState<PasswordSettings>(defaultPasswordSettings);

  useEffect(() => {
    const fetchData = async () => {
      const passwordSettings = await authApi.getSettings();

      setPasswordSettings(passwordSettings);
    };

    fetchData();
  }, []);

  const passwordRules = useMemo(() => {
    return requirements ?? getRequirements(passwordSettings);
  }, [requirements, passwordSettings]);

  const handleSubmit = async (values: RoomPasswordType) => {
    try {
      const response = await authApi.changePassword({
        ...values,
        login: userProfile.login,
        changeByExpired: true,
        remember: true,
      });

      if (response.errors?.length) {
        dispatch(
          notify.danger({
            dataTest: 'changePassword',
            title: response.errors.join(' '),
          })
        );
      } else {
        onClose();
      }
    } catch (err: any) {
      console.error('error authApi changePassword', err.response);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={initialPasswordData}
      validationSchema={getPasswordValidationSchema(passwordSettings)}
    >
      {(formikProps: FormikProps<RoomPasswordType>) => {
        const { submitForm, values, isSubmitting, isValid, setValues, dirty, resetForm } = formikProps;

        return (
          <form>
            <Popup
              dataTest="changePassword"
              open={show}
              title="Изменение пароля"
              buttonPrimaryText="Изменить пароль"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !dirty || !isValid,
              }}
              onClearData={resetForm}
              onClose={onClose}
              onSubmit={submitForm}
            >
              <>
                <FormikInput
                  label="Текущий пароль"
                  type="password"
                  name="oldPassword"
                  placeholder="Введите пароль..."
                  size="small"
                  showErrorImmediately
                />
                <Push size={20} />
                <div className="room-form-group relative">
                  <button
                    type="button"
                    onClick={async () => {
                      const generatedString = await authApi.generatePassword();

                      copyTextToClipboard(generatedString);
                      dispatch(
                        notify.success({
                          dataTest: 'generatePassword',
                          title: 'Надежный пароль успешно сгенерирован и скопирован в буфер обмена',
                        })
                      );
                      setValues({
                        ...values,
                        newPassword: generatedString,
                        confirmNewPassword: generatedString,
                      });
                    }}
                    className="room-form-group__btn"
                  >
                    Сгенерировать надежный пароль
                  </button>
                  <FormikInput
                    label="Новый пароль"
                    type="password"
                    name="newPassword"
                    resettable={!!values.newPassword}
                    placeholder="Введите пароль..."
                    size="small"
                  />
                </div>
                <Push size={8} />
                <PasswordStrength
                  password={values.newPassword}
                  passwordRules={passwordRules}
                />
                <Push size={12} />
                <PasswordRequirement
                  password={values.newPassword}
                  passwordRules={passwordRules}
                />
                <Push size={20} />
                <FormikInput
                  label="Повторите пароль"
                  type="password"
                  name="confirmNewPassword"
                  placeholder="Введите пароль еще раз..."
                  size="small"
                  resettable={!!values.confirmNewPassword}
                />
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordModal;

const getPasswordValidationSchema = (settings: PasswordSettings) =>
  objectYup().shape({
    oldPassword: stringYup()
      .when('newPassword', {
        is: (newPassword: string) => !!newPassword,
        then: stringYup().required('Введите текущий пароль').nullable(),
      })
      .nullable(),
    newPassword: stringYup()
      .nullable()
      .test('password-requirement', '', (value) => validatePassword(value, settings)),
    confirmNewPassword: stringYup()
      .nullable()
      .test('passwords-match', 'Пароль и подтверждение пароля не совпадают', function (value) {
        return this.parent.newPassword === value;
      }),
  });
