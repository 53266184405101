import { LmPlaceholderImage, LmTable, LmTableColumn, LmTableContent, LmTableFooterPagination } from '@mes-ui/lemma';
import React, { useEffect, useState } from 'react';
import { SimpleTableType } from '../../types/table';
import { pageSizeOptions } from '../../mock-data';
import { usePrevious } from '../../hooks/use-previous';

const SimpleTable = ({
  data,
  columns,
  pageSize,
  emptyString,
  loaderDescription,
  loaderTitle,
  loading,
  dataTest,
}: SimpleTableType) => {
  const [pageNum, setPageNum] = useState(0);
  const dataLength = usePrevious(data?.length || 0);

  useEffect(() => {
    if (data?.length && data.length <= pageSizeOptions[0].value && pageNum > 0) {
      setPageNum(0);
    }
  }, [data?.length, pageNum]);

  useEffect(() => {
    if (dataLength && data?.length > dataLength) {
      setPageNum(0);
    }
  }, [data?.length, dataLength]);

  return (
    <LmTable
      initialState={{
        dataTable: {
          data,
          isDataLoading: loading,
        },
      }}
    >
      <LmTableContent
        emptyDataComponent={
          <LmPlaceholderImage
            imageName="placeholder--book-2"
            title={emptyString || 'Ничего не найдено'}
          />
        }
        loadingComponent={
          <LmPlaceholderImage
            imageName="loading--page"
            title={loaderTitle || 'Загружаем данные...'}
            description={loaderDescription || 'Нужно немного подождать...'}
          />
        }
        overflow
      >
        {columns.map((column, index, array) => (
          <LmTableColumn
            key={column.dataIndex}
            sortable={column.sortable}
            hasAdditLeftPadding={index === 0}
            hasAdditRightPadding={index === array.length - 1}
            width={column.width}
            dataKey={column.dataIndex}
            title={
              <span
                style={{
                  textAlign: 'left',
                }}
              >
                {column.title}
              </span>
            }
          >
            {(value, item: any) => column.render && column.render(item)}
          </LmTableColumn>
        ))}
      </LmTableContent>
      <div
        style={{
          display: !!data?.length && data.length > pageSizeOptions[0].value ? 'block' : 'none',
        }}
      >
        <LmTableFooterPagination
          dataTest={dataTest || 'simpleTable'}
          key={pageNum}
          initialLimit={pageSize}
          limitList={pageSizeOptions.map((item) => item.value)}
          hasGoToPageButton
          initialPage={pageNum + 1} // TODO: баг в лемме
          getCurrentPage={setPageNum}
          getCurrentLimit={() => setPageNum(0)}
          goBackFromEmptyPage
        />
      </div>
    </LmTable>
  );
};

export default SimpleTable;
