import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import FormikInput from '../../../../components/formik/formik-input';
import { FieldProps } from '../../../../components/fields/field';

type DocumentNumberProps = FieldProps<any> & {
  disabled?: boolean;
  parent?: string;
};

const BuildingInfo = ({ label = 'Дом', editMode, required, disabled = false, parent }: DocumentNumberProps) => {
  return (
    <FormikField>
      {({ form }: FormikFieldProps) => {
        const home = parent ? `${parent}.houseNumber` : 'houseNumber';
        const building = parent ? `${parent}.housingNumber` : 'housingNumber';
        const ownership = parent ? `${parent}.ownershipNumber` : 'ownershipNumber';
        const structure = parent ? `${parent}.buildingNumber` : 'buildingNumber';

        return (
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              {label} {editMode && required ? <span className="table-data__required" /> : null}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <div className="table-data-grid-4">
                  <FormikInput
                    size="small"
                    placeholder="Введите дом..."
                    name={home}
                    disabled={disabled}
                  />
                  <FormikInput
                    size="small"
                    placeholder="Введите корпус..."
                    name={building}
                    disabled={disabled}
                  />
                  <FormikInput
                    size="small"
                    placeholder="Введите владение..."
                    name={ownership}
                    disabled={disabled}
                  />
                  <FormikInput
                    size="small"
                    placeholder="Введите строение..."
                    name={structure}
                    disabled={disabled}
                  />
                </div>
              ) : (
                <div className="table-data-grid-4">
                  <div className="table-data__group">
                    <div className="table-data__label">Дом</div>
                    <div className="table-data__body">{form.values[home] || '—'}</div>
                  </div>
                  <div className="table-data__group">
                    <div className="table-data__label">Корпус</div>
                    <div className="table-data__body">{form.values[building] || '—'}</div>
                  </div>
                  <div className="table-data__group">
                    <div className="table-data__label">Владение</div>
                    <div className="table-data__body">{form.values[ownership] || '—'}</div>
                  </div>
                  <div className="table-data__group">
                    <div className="table-data__label">Строение</div>
                    <div className="table-data__body">{form.values[structure] || '—'}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      }}
    </FormikField>
  );
};

export default BuildingInfo;
