import { LmBadgeColors } from '@mes-ui/lemma/components/LmBadge/model';
import React from 'react';
import { TreeNodeAmount, TreeNodeType } from '@mosru/esz_uikit';
import { notify } from '../../redux/ducks/notifications';
import { store } from '../../store/store';
import { ClassificatorChildData, ClassificatorSearchData } from '../../types/classificator';
import { RegistryLearner, PropsDocumentType, Learner } from '../../types/learners';
import { TypeRepresentationEnum } from '../../mock-data/type-representation-enum';
import { StatusLearnerEnum } from '../../mock-data/status-learner-enum';
import { DocumentTypeEnum } from '../../mock-data/type-document';
import { OperationResultEnum } from '../../mock-data/operation-result-enum';
import { formatDate } from './date';
import { messageResponse } from './dictionary';
import { SelectOptionType } from '../../types/entities';

export const editSex = (value: number | null) => (value === 1 ? 'Мужской' : value === 2 ? 'Женский' : '');

export const editName = (lastName: string, firstName: string, middleName: string) => {
  return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
};

export const transformDataLearners = (data: RegistryLearner[], pageSize: number, pageNumber: number) => {
  return data?.map((item, index) => {
    return {
      row: pageSize * (pageNumber - 1) + index + 1,
      id: item.id,
      name: editName(item.lastName, item.firstName, item.middleName),
      hasInContingent: item.hasInContingent,
      birthDate: formatDate(item.birthDate),
      sexId: editSex(item.sexId),
      statuses: item.educationStatuses,
    };
  });
};

export const transformPrivelegiesData = (data: SelectOptionType[]) => {
  data.splice(0, 0, {
    label: 'Все',
    value: null,
  });
  data.push({
    label: 'Льготная категория отсутствует',
    value: 0,
  });

  return data;
};

export const transformDataForPopup = (data: Learner.Data) => {
  return {
    id: data.id,
    name: ` ${data.lastName} ${data.firstName} ${data.middleName || ''}`,
    date: formatDate(data.birthDate),
  };
};

export function numberWithCommas(str: number) {
  return Number(str.toFixed(2)).toLocaleString('en-US').replaceAll(',', ' ');
}

export const checkEmptyData = (data: { [key: string]: any }) => {
  for (const key in data) {
    if (data[key] === undefined || data[key] === '' || data[key] === 0) {
      delete data[key];
    }
  }

  return data;
};

export const checkStatuses = (result: Learner.EducationStatuses[]) => {
  return {
    included: result?.find((r) => r.statusId === StatusLearnerEnum.Included)?.count,
    excluded: result?.find((r) => r.statusId === StatusLearnerEnum.Excluded)?.count,
  };
};

export const genderOptions = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Мужской',
    value: 1,
  },
  {
    label: 'Женский',
    value: 2,
  },
];

export const informationOptions = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Присутствуют',
    value: 1,
  },
  {
    label: 'Отсутствуют',
    value: 2,
  },
];

export const statusOptions = [
  {
    label: 'Любой',
    value: '',
  },
  {
    label: 'Зачислен',
    value: StatusLearnerEnum.Included,
  },
  {
    label: 'Отчислен',
    value: StatusLearnerEnum.Excluded,
  },
];

export const notificationMessages = {
  noPersonalData: 'Невозможно выбрать личное дело, так как оно находится в архиве',
  mergeSuccess: 'Объединение прошло успешно',
  mergeError: 'Переносимое личное дело и актуальное личное дело не должны совпадать',
  saveSuccess: messageResponse.success,
};

export const selectTypeRepresentation = (value: number) => {
  switch (value) {
    case TypeRepresentationEnum.Mother:
      return 'Мать';

    case TypeRepresentationEnum.Father:
      return 'Отец';

    case TypeRepresentationEnum.LegalRepresentative:
      return 'Законный представитель';

    case TypeRepresentationEnum.Declarant:
      return 'Заявитель';

    case TypeRepresentationEnum.Trustee:
      return 'Попечитель';

    case TypeRepresentationEnum.Contact:
      return 'Контактное лицо';

    case TypeRepresentationEnum.Child:
      return 'Ребенок';

    case TypeRepresentationEnum.Spouse:
      return 'Супруг';

    case TypeRepresentationEnum.Relative:
      return 'Родственник';

    case TypeRepresentationEnum.Other:
      return 'Другой';

    case TypeRepresentationEnum.Teacher:
      return 'Учитель';

    default:
      break;
  }
};

export const transformRepresentatives = (data: Learner.Representative[]) => {
  return data.map((item) => {
    return {
      id: item.id ? item.id : null,
      type: selectTypeRepresentation(item.typeRepresentationId),
      typeRepresentationId: item.typeRepresentationId,
      name: {
        firstName: item.firstName,
        middleName: item.middleName,
        lastName: item.lastName,
      },
      email: item.email ? item.email : '',
      phone: item.phone ? item.phone : '',
    };
  });
};

export const typeRepresentation = [
  {
    label: 'Мать',
    value: TypeRepresentationEnum.Mother,
  },
  {
    label: 'Отец',
    value: TypeRepresentationEnum.Father,
  },
  {
    label: 'Законный представитель',
    value: TypeRepresentationEnum.LegalRepresentative,
  },
  {
    label: 'Заявитель',
    value: TypeRepresentationEnum.Declarant,
  },
  {
    label: 'Попечитель',
    value: TypeRepresentationEnum.Trustee,
  },
  {
    label: 'Контактное лицо',
    value: TypeRepresentationEnum.Contact,
  },
  {
    label: 'Ребенок',
    value: TypeRepresentationEnum.Child,
  },
  {
    label: 'Супруг',
    value: TypeRepresentationEnum.Spouse,
  },
  {
    label: 'Родственник',
    value: TypeRepresentationEnum.Relative,
  },
  {
    label: 'Другой',
    value: TypeRepresentationEnum.Other,
  },
  {
    label: 'Учитель',
    value: TypeRepresentationEnum.Teacher,
  },
];

export const defaultRepresentation = {
  label: 'Мать',
  value: TypeRepresentationEnum.Mother,
};

export const listTypeDocuments = [
  {
    label: 'Свидетельство о рождении',
    value: DocumentTypeEnum.BirthCertificate,
  },
  {
    label: 'Запись о рождении в ЕГР ЗАГС',
    value: DocumentTypeEnum.BirthRecord,
  },
  {
    label: 'Паспорт гражданина РФ',
    value: DocumentTypeEnum.Passport,
  },
  {
    label: 'Свидетельство иностранного образца',
    value: DocumentTypeEnum.BirthCertificateForeign,
  },
  {
    label: 'Паспорт иностранного образца',
    value: DocumentTypeEnum.ForeignPassport,
  },
  {
    label: 'Вид на жительство иностранного гражданина',
    value: DocumentTypeEnum.ResidencePermit,
  },
];

export const convertTypeDocument = (value: number | undefined) => {
  const typeDocument = activeSearch(value)?.label;

  switch (value) {
    case DocumentTypeEnum.BirthCertificate:
      return typeDocument;

    case DocumentTypeEnum.Passport:
      return typeDocument;

    case DocumentTypeEnum.BirthCertificateForeign:
      return typeDocument;

    case DocumentTypeEnum.ForeignPassport:
      return typeDocument;

    case DocumentTypeEnum.ResidencePermit:
      return typeDocument;

    default:
      break;
  }
};

// найти документ удост. личность
export const activeSearch = (value: number | undefined) =>
  listTypeDocuments.find((item: SelectOptionType) => item.value === value);

// выбрать документ удоств.личн
export const checkActiveElement = (value: number | undefined) => {
  if (value === null) {
    return activeSearch(1);
  } else {
    return activeSearch(value);
  }
};

export const regCityOptions = [
  {
    label: 'г.Москва',
    value: 'moscow',
  },
  {
    label: 'Другой город',
    value: 'other',
  },
];

// Используется в Сведения об обучающемся выбор цвета статуса
export const chooseColorForStatusRequest = (color: string): LmBadgeColors | undefined => {
  if (color) {
    switch (color) {
      case 'Ожидание прихода Заявителя на вступительные испытания':
      case 'Ожидание прихода Заявителя для заключения договора':
      case 'Поданы документы':
      case 'Направлен на зачисление':
      case 'Вступительные испытания пройдены':
        return 'cian';

      case 'Зачислен':
        return 'green';

      case 'Отказ в зачислении':
      case 'Отозвано':
        return 'red';

      case 'Архив':
      case 'Зачислен/Отчислен':
      case 'Черновик':
        return 'grey';

      case 'Приостановлено':
        return 'orange';

      case 'Новое':
      case 'На рассмотрении':
        return 'indigo';

      case 'Ожидание подписания электронного договора':
      case 'Договор подписан электронно':
        return 'grape';

      default:
        break;
    }
  }
};

export const NewRepresentatives: Learner.TransformRepresentative = {
  id: 0,
  type: 0,
  name: {
    firstName: '',
    middleName: '',
    lastName: '',
  },
  email: '',
  phone: '',
  typeRepresentationId: 0,
};

export const notificationRequest = (result: number, message: string) => {
  if (result === OperationResultEnum.Error) {
    store.dispatch(
      notify.danger({
        dataTest: 'operationResult',
        title: message,
      })
    );
  } else if (result === OperationResultEnum.Success) {
    store.dispatch(
      notify.success({
        dataTest: 'operationResult',
        title: message,
      })
    );
  }
};

export const showSuccessNotification = () => {
  store.dispatch(
    notify.success({
      dataTest: 'action',
      title: messageResponse.success,
    })
  );
};

export const selectedDefaultDocument = (
  findTypeElement: { name: string; id: number } | undefined,
  defaultTypeDocument: SelectOptionType
) => {
  return {
    label: findTypeElement ? findTypeElement.name : defaultTypeDocument.label,
    value: findTypeElement ? findTypeElement.id : defaultTypeDocument.value,
  };
};

export const findDocument = (data: PropsDocumentType[], docID: number) => {
  return data.find((item: { id: number }) => {
    return item.id === docID;
  });
};

export const transformTreePopup = (data: ClassificatorChildData[], showAmount = false): TreeNodeType[] => {
  return data
    ? data.map((item: ClassificatorChildData) => {
      return {
        key: item.id,
        title: showAmount ? (
          <TreeNodeAmount
            title={item.name}
            amount={item.freeVolume ?? 0}
          />
        ) : (
          item.name
        ),
        isLeaf: !item.hasChildren,
        children: [],
      };
    })
    : [];
};

export const transformClassificatorSearch = (data: ClassificatorSearchData[], showAmount = false): TreeNodeType[] => {
  return data.map((item) => deepTransformClassificatorItem(item, showAmount));
};

function deepTransformClassificatorItem(item: ClassificatorSearchData, showAmount = false): TreeNodeType {
  return {
    key: item.data.id,
    title:
      showAmount && !item.data.hasChildren ? (
        <TreeNodeAmount
          title={item.data.name}
          amount={item.data.freeVolume ?? 0}
        />
      ) : (
        item.data.name
      ),
    isLeaf: !item.data.hasChildren,
    children: item.child.length ? transformClassificatorSearch(item.child, showAmount) : [],
  };
}
