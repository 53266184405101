import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import serviceClassApi from '../../../lib/api/service-class';
import { editTextInfobox } from '../../../lib/utils/requests';

type Props = {
  contingentGuid?: string;
  serviceClassId?: number;
  cheked: () => void;
};

export const Statuses: React.FC<Props> = ({ contingentGuid, serviceClassId, cheked }) => {
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [message, setMessage] = useState('');
  const [checkedIntersection, setCheckedIntersection] = useState<boolean>(false);

  const handlerCheck = async () => {
    if (serviceClassId && contingentGuid) {
      setLoadingCheck(true);
      cheked();
      try {
        const response = await serviceClassApi.checkScheduleIntersection(serviceClassId, contingentGuid);

        if (!response) {
          setMessage('');
        } else {
          setMessage(response.message);
        }
      } finally {
        setLoadingCheck(false);
        setCheckedIntersection(true);
      }
    }
  };

  return contingentGuid ? (
    <div className="container">
      {!message && !checkedIntersection && !loadingCheck && (
        <LmInfoBox
          dataTest="infoLikeWarning"
          className="infobox--full-width"
          variant="warning"
          description="Для продолжения зачисления проверьте пересечения расписаний обучающегося"
          buttonPrimaryText="Проверить"
          onClickPrimaryButton={handlerCheck}
          hideSecondaryButton
        />
      )}
      {message && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <LmInfoBox
            dataTest="intersectionsWarning"
            className="infobox--full-width"
            variant="warning"
            description={
              <span
                dangerouslySetInnerHTML={{
                  __html: editTextInfobox(message),
                }}
              />
            }
            hidenFooter
          />
        </>
      )}

      {!message && loadingCheck && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <LmInfoBox
            dataTest="waitingWarning"
            className="infobox--full-width"
            variant="warning"
            description="Подождите, идет проверка пересечений расписаний обучающегося"
            hidenFooter
          />
        </>
      )}

      {!message && checkedIntersection && (
        <>
          <Push
            orientation="vertical"
            size={12}
          />
          <LmInfoBox
            dataTest="intersectionsSuccess"
            className="infobox--full-width"
            variant="success"
            description="Пересечения расписаний не найдены"
            hidenFooter
          />
        </>
      )}
    </div>
  ) : null;
};
