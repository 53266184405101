import queryString from 'query-string';
import { notify } from '../../redux/ducks/notifications';
import { store } from '../../store/store';
import tokenManager from '../token-manager';
import { debugMode, routes } from '../../config/constants';
import CustomError from '../custom-error';
import { signOut } from '../../redux/utils';
import history from '../../history';
import { deepReplaceDateToString } from '../utils/date';
import { redirectToLogin } from '../utils';

/**
 * Основной метод, который отсылает запросы
 * @param url - ручка
 * @param paramsData - данные запроса
 * @param timeout - таймаут
 */
export async function fetchWithTokenRequest(url: string, paramsData: any, timeout: number | null = null): Promise<any> {
  const { isProtected = true, showAccessDeny = true, ...params } = paramsData;

  /**
   * Делаем проверку на наличие и валидность токена
   * Если токен отсутствует, то кидаем на логинку
   * В случае просроченности токена пытаемся сразу получить новый
   *  */
  if (isProtected && !(await tokenManager.isTokenValid())) {
    // alert('Не авторизован');
    if (debugMode) {
      store.dispatch(
        notify.danger({
          dataTest: 'authorization',
          title: 'Для совершения запроса необходима авторизация, пожалуйста, авторизуйтесь.',
        })
      );
      // eslint-disable-next-line no-console
      console.warn('redirected to login from src/lib/api/index.ts token is invalid');
    }

    return signOut();
  }
  const token = tokenManager.getToken();
  let headers = {
    ...params.headers,
  };

  if (isProtected) {
    headers = {
      ...params.headers,
      ESZToken: `${token || ''}`,
    };
  }

  let response: Response;

  if (timeout !== null) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    response = await window.fetch(url, {
      ...params,
      headers,
      signal: controller.signal,
    });
    clearTimeout(id);
  } else {
    response = await window.fetch(url, {
      ...params,
      headers,
    });
  }

  if (response.ok) {
    if (response.status === 204) {
      return null;
    }
    try {
      // Если в body лежит не json, то при response.text() мы получим ошибку, поэтому на свякий случай клонируем ответ сервера
      return await response.clone().json();
    } catch {
      console.error('Cannot parse response as json');
      const contentDisposition = response.headers.get('content-disposition');

      if (contentDisposition) {
        const blob = await response.blob();

        return {
          contentDisposition,
          blob,
        };
      }

      return await response.text();
    }
  } else if (response.status === 500 || response.status === 502) {
    try {
      // Если в body лежит не json, то при response.text() мы получим ошибку, поэтому на свякий случай клонируем ответ сервера
      const error = await response.clone().json();

      // перекинуть на страницу unknownError с параметром ссылки, если она еще не открыта
      history.location.pathname !== routes.unknownError &&
        history.push(routes.unknownError, {
          error,
        });
    } catch {
      console.error('Cannot parse response as json');

      return await response.text();
    }
  } else if (response.status === 503) {
    throw new Error('Server is under maintenance');
  } else if (response.status >= 400) {
    if (response.status === 401) {
      tokenManager.clearToken();

      if (!isProtected) {
        debugMode &&
          store.dispatch(
            notify.danger({
              dataTest: 'authorization',
              title: 'Для совершения запроса необходима авторизация, пожалуйста, авторизуйтесь.',
            })
          );
      }

      return await redirectToLogin({
        redirectUrl: window.location.pathname,
      });
    }
    if (response.status === 403 || response.status === 404) {
      showAccessDeny && history.push(routes.accessDeny);
    }
    if (
      response.status === 400 ||
      response.status === 432 ||
      response.status === 460 ||
      response.status === 422 ||
      response.status === 404
    ) {
      try {
        let resp: any = await response.text();

        resp = JSON.parse(resp);
        if (resp.messages instanceof Array) {
          store.dispatch(
            notify.danger({
              dataTest: `errorCode${response.status}`,
              title: resp.messages.join('; '),
            })
          );
        }

        if (resp.errors) {
          store.dispatch(
            notify.danger({
              dataTest: `errorCode${response.status}`,
              title: Object.keys(resp.errors)
                .map((i) => resp.errors[i])
                .join('; '),
            })
          );
        }

        if (resp.message) {
          store.dispatch(
            notify.danger({
              dataTest: `errorCode${response.status}`,
              title: resp.message,
            })
          );
        }
        throw new CustomError(
          // @ts-ignore
          resp
        );
      } catch (ex) {}
    }
    if (response.status === 433) {
      const resp = await response.text();

      return JSON.parse(resp);
    }
    let resp;

    try {
      resp = await response.text();
      // строго говоря вообще сервер не обязан отдавать какие-то описания в json
      // поэтому сильно и не расчитываем на это
      resp = JSON.parse(resp);
      resp.status = response.status;
    } finally {
      if (typeof resp === 'string') {
        throw new CustomError({
          message: resp || `Unhandled error. Server status code ${response.status}`,
          status: response.status,
        });
      }
      throw new CustomError(resp);
    }
  }
}

export class fetchRequest {
  static async get(
    path: string,
    data?: any,
    options: any = {},
    seriailizeOptions: queryString.StringifyOptions = {
      arrayFormat: 'bracket',
    }
  ) {
    return await fetchWithTokenRequest(
      `${path}${data ? `?${queryString.stringify(data, seriailizeOptions)}` : ''}`,
      options
    );
  }

  static async post(path: string, data?: any, options: any = {}, timeout: number | null = null) {
    return await fetchWithTokenRequest(
      path,
      {
        method: 'POST',
        body: JSON.stringify(deepReplaceDateToString(data)),
        ...options,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...options.headers,
        },
      },
      timeout
    );
  }

  static async file(path: string, data?: any, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'POST',
      body: data,
      ...options,
    });
  }

  static async put(path: string, data: any, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'PUT',
      body: JSON.stringify(deepReplaceDateToString(data)),
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
  }

  static async putFormData(path: string, data: any, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'PUT',
      body: data,
      ...options,
      headers: {
        Accept: 'application/json',
        ...options.headers,
      },
    });
  }

  static async patch(path: string, data?: any, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'PATCH',
      body: JSON.stringify(deepReplaceDateToString(data)),
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
  }

  static async delete(
    path: string,
    data?: any,
    options: any = {},
    seriailizeOptions: queryString.StringifyOptions = {
      arrayFormat: 'bracket',
    },
    body?: any
  ) {
    return await fetchWithTokenRequest(`${path}${data ? `?${queryString.stringify(data, seriailizeOptions)}` : ''}`, {
      method: 'DELETE',
      body: JSON.stringify(deepReplaceDateToString(body)),
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
  }
}

export { default as authApi } from './auth';
