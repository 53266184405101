import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { InteractiveChevronTemplate, LmButton, LmSubHeader, LmListItem } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ViewContainer from '../../../components/view-container';
import history from '../../../history';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { EventUrl } from '../../../mock-data/event';
import { requestHistoryState } from '../utils';
import RequestsSearch from './search';
import RequestsTable from './table';
import { SearchRequestsInitialFormData, SearchRequestsTableOptions } from '../../../types/requests';
import DropDown from '../../../components/drop-down';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { hasAccessObjectAny, hasGeneralAccess, redirect, replaceHistoryState } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import useQuery from '../../../hooks/use-query';

export const searchRequestsInitialFormData: SearchRequestsInitialFormData = {
  isActualRequestIndex: true,
  isOverdueToday: false,
  isOverdueTomorrow: false,
  contingentLinkTypeId: '',
  query: '',
};

const Requests = () => {
  const [search, setSearch] = useState<SearchRequestsInitialFormData>();
  const [requestOptions, setRequestOptions] = useState<{ link: string; label: string; value: number }[]>([]);
  const [tableOptions, setTableOptions] = useState<SearchRequestsTableOptions>({
    loadEnable: false,
    isActualIndex: searchRequestsInitialFormData.isActualRequestIndex ?? false,
    isArtHouseSportEducation: false,
  });

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const eventId = Number(useQuery().get(EventUrl.EventId));

  useClearHistoryState();

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  const showNewStatement =
    (hasAccessObjectAny(
      userProfile,
      [accessObject.RequestDOGM, accessObject.RequestNonDOGM, accessObject.RequestDayCare, accessObject.RequestOP],
      accessAction.Create
    ) &&
      !isAdmin) ||
    isAdmin;

  const handleSubmitSearch = useCallback(
    (values: SearchRequestsInitialFormData) => {
      setSearch((prevState) => {
        if (prevState && history.location.search) {
          const { state } = window.history;

          if (!eventId) {
            history.replace({
              pathname: history.location.pathname,
              search: undefined,
            });
          }
          replaceHistoryState({
            ...state,
            [requestHistoryState.search]: values,
          });
        } else {
          replaceHistoryState({
            [requestHistoryState.search]: values,
          });
        }

        return values;
      });
    },
    [eventId]
  );

  return (
    <ViewContainer
      subHeader={
        <LmSubHeader
          sticky
          title="Заявления"
          dataTest="requestsSubHeader"
          description=""
          hideReturnButton
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Заявления',
            },
          ]}
          buttonsOrAnyActions={
            requestOptions?.length > 0 && showNewStatement && !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV)
              ? ([
                  {
                    children: (
                      <DropDown
                        dataTest="newRequest"
                        component={(open) => (
                          <LmButton
                            dataTest="newRequest"
                            type="button"
                          >
                            Новое заявление
                            <InteractiveChevronTemplate open={open} />
                          </LmButton>
                        )}
                      >
                        <>
                          {requestOptions.map(({ link, label }) => {
                            return (
                              <LmListItem
                                key={label}
                                dataTest={label}
                                text={label}
                                onClick={() => redirect(link)}
                              />
                            );
                          })}
                        </>
                      </DropDown>
                    ),
                  },
                ] as ISubHeaderTitleButton[])
              : undefined
          }
        />
      }
      filterComponent={
        <RequestsSearch
          submitForm={handleSubmitSearch}
          setTableOptions={setTableOptions}
          setRequestOptions={setRequestOptions}
          initialSearchFilters={search}
        />
      }
    >
      <RequestsTable
        search={search}
        tableOptions={tableOptions}
      />
    </ViewContainer>
  );
};

export default Requests;
