import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import Popup from '../../../../components/modals/popup';
import { NextStage, ServiceClassTransferNextStage } from '../../../../types/service-class';
import serviceClassApi from '../../../../lib/api/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  open: boolean;
  close: () => void;
};

const TransferNextStage: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const [people, setPeople] = useState<NextStage[]>([]);
  const [documents, setDocuments] = useState<SelectOptionType[]>([]);

  const initialValues: ServiceClassTransferNextStage = {
    documentTypeId: 3,
    docNumber: '',
    docDate: '',
    comment: '',
    educationTypeId: serviceClassData.educationTypeId,
    megaRelationIds: [],
  };

  const submitForm = useCallback(
    async (values: ServiceClassTransferNextStage) => {
      values.megaRelationIds = people;
      values.educationTypeId = serviceClassData.educationTypeId;
      await serviceClassApi.transferNextStage(serviceClassData.id || 0, values);
      fetchTable();
      setSelected([]);
      setOpenDetails(false);
      close();
    },
    [serviceClassData.educationTypeId, serviceClassData.id, people, close, fetchTable, setSelected, setOpenDetails]
  );

  useEffect(() => {
    const fetch = async () => {
      if (serviceClassData.id) {
        const data = await serviceClassApi.nextStageSearch(
          serviceClassData.id,
          selected,
          serviceClassData.educationTypeId
        );

        setPeople(data);
      }
    };

    open && fetch();
  }, [serviceClassData.id, selected, serviceClassData.educationTypeId, open]);

  useEffect(() => {
    const fetch = async () => {
      const documentTypes = await dictionariesApi.getDocumentTypes(3);

      setDocuments(documentTypes);
    };

    open && fetch();
  }, [open]);

  const peopleHasNotNextStage = useMemo(
    () => people.filter((value) => !value.nextStageId).map((value) => value.pupilName),
    [people]
  );

  return (
    <Formik
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<ServiceClassTransferNextStage>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="transferNextStage"
              open={open}
              size="large"
              title="Перевод на следующий этап обучения"
              buttonPrimaryText="Перевести"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid || !!peopleHasNotNextStage.length,
              }}
              onSubmit={handleSubmit}
              onClose={close}
              onClearData={resetForm}
            >
              <>
                {peopleHasNotNextStage.length ? (
                  <LmInfoBox
                    dataTest="transferNextStageError"
                    className="infobox--full-width"
                    variant="alert"
                    hidenFooter
                    description={`По ученикам: ${peopleHasNotNextStage.join(
                      ', '
                    )} невозможно произвести перевод. Пожалуйста, исключите этих учеников из списка и повторите процедуру`}
                  />
                ) : null}
                <Push size={16} />
                <SimpleTable
                  data={people}
                  columns={[
                    {
                      dataIndex: 'pupilName',
                      title: 'ФИО',
                      render: (item: any) => item.pupilName,
                      width: '30%',
                    },
                    {
                      dataIndex: 'serviceName',
                      title: 'Специализация',
                      render: (item: any) => item.serviceName,
                      width: '30%',
                    },
                    {
                      dataIndex: 'currentStage',
                      title: 'Этап до',
                      render: (item: any) => <span className="color-gray-dark">{item.currentStage}</span>,
                      width: '20%',
                    },
                    {
                      dataIndex: 'nextStage',
                      title: 'Этап после',
                      render: (item: any) => <span className="color-gray-dark">{item.nextStage}</span>,
                      width: '20%',
                    },
                  ]}
                />
                <Push size={32} />
                <FormikSelect
                  name="documentTypeId"
                  label="Тип документа"
                  required
                  size="small"
                  withSearch
                  options={documents}
                  placeholder="Выберите..."
                />
                <Push size={16} />
                <FormikInput
                  label="Номер документа"
                  size="small"
                  name="docNumber"
                  placeholder="Введите..."
                />
                <Push size={16} />
                <FormikDatePicker
                  label="Дата"
                  size="small"
                  name="docDate"
                  disablePortal={false}
                  placement="top-start"
                />
                <Push size={16} />
                <FormikTextarea
                  label="Причина перевода"
                  name="comment"
                  placeholder="Введите..."
                  maxRows={2}
                />
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default TransferNextStage;

const getValidationSchema = () =>
  objectYup().shape({
    documentTypeId: stringYup().required('Выберите тип документа'),
    docDate: docDate.nullable(),
  });
