import { Formik, FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { number, object as objectYup, string as stringYup } from 'yup';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import FormikSelect from '../../../../components/formik/formik-select';
import Popup from '../../../../components/modals/popup';
import { getStringFromTimeTable, getTimeTableFromString, scheduleMockData } from '../../../../lib/utils/time-table';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { maxLengthTextError } from '../../../../lib/utils/validation';
import { ItemsWork } from '../../../../types/items-work';
import { Organization } from '../../../../types/organization';
import EditSchedule, { ScheduleType } from '../../../../components/schedule';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

type FormData = {
  schedule: ScheduleType[];
  saveOn: boolean;
} & ItemsWork;

type Props = {
  editMode: boolean;
  scheduleSubmit: boolean;
  organization: Organization;
  addressOptions: SelectOptionType[];
  setEditMode: (value: boolean) => void;
  itemWork: ItemsWork | null | undefined;
  setScheduleSubmit: (value: boolean) => void;
  setRerenderTableKey: (value: number) => void;
  setItemWork: (value: ItemsWork | null) => void;
};

export const ModalForm: React.FC<Props> = ({
  setEditMode,
  scheduleSubmit,
  itemWork,
  organization,
  addressOptions,
  setRerenderTableKey,
  setScheduleSubmit,
  setItemWork,
  editMode,
}) => {
  const itemWorkFormData = useMemo(
    () =>
      itemWork
        ? ({
            ...itemWork,
            schedule: getTimeTableFromString(itemWork?.timetable),
            saveOn: false,
          } as FormData)
        : ({} as FormData),
    [itemWork]
  );

  const [errorSchedule, setErrorSchedule] = useState<boolean>(false);
  const initialErrors = useInitialErrors(itemWorkFormData, getValidationSchema());

  const submitForm = useCallback(
    async (values: FormData) => {
      const data = {
        addressId: values.addressId,
        shortName: values.shortName,
        fullName: values.fullName,
        timetable: getStringFromTimeTable(values.schedule),
        fullAddress: values.fullAddress,
      };

      if (itemWork?.id) {
        if (organization?.id) {
          await dictionariesApi.updateItemWork(itemWork.id, {
            ...itemWork,
            ...data,
            organizationId: organization?.id,
          });
        }
      } else if (organization?.id) {
        await dictionariesApi.saveItemWork({
          ...data,
          organizationId: organization?.id,
        } as ItemsWork);
      }

      setEditMode(false);
      setRerenderTableKey(Math.random());
    },
    [itemWork, organization?.id, setEditMode, setRerenderTableKey]
  );

  return (
    <Formik
      onSubmit={submitForm}
      initialValues={itemWorkFormData}
      validationSchema={getValidationSchema()}
      enableReinitialize
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<FormData>) => {
        const { handleSubmit, isSubmitting, isValid, setFieldValue, values } = formikProps;

        return (
          <form>
            <Popup
              dataTest="formPointPopup"
              title="Пункт работы с заявителями"
              open={editMode}
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !(errorSchedule && isValid),
              }}
              onSubmit={() => {
                setScheduleSubmit(true);
              }}
              onClose={() => {
                setEditMode(false);
                setItemWork(null);
              }}
            >
              <FormikTextarea
                label="Полное наименование"
                required
                placeholder="Введите полное наименование пункта работы с заявителями..."
                name="fullName"
              />

              <Push size={16} />
              <FormikInput
                label="Краткое наименование"
                placeholder="Введите краткое наименование пункта работы с заявителями..."
                name="shortName"
                size="small"
              />
              <Push size={16} />
              <FormikSelect
                label="Адрес пункта"
                required
                name="addressId"
                size="small"
                maxMenuHeight={205}
                placeholder="Выберите адрес пункта работы с заявителями из адресов организации..."
                withSearch
                defaultValue={
                  values.addressId && values.fullAddress
                    ? {
                        value: values.addressId,
                        label: values.fullAddress,
                      }
                    : null
                }
                options={addressOptions}
              />
              <div className="org-schedule-panel">
                <EditSchedule
                  errorSchedule={setErrorSchedule}
                  submit={scheduleSubmit}
                  title={<span className="font-size-small">Расписание работы пункта</span>}
                  submitError={() => setScheduleSubmit(false)}
                  submitSuccess={(schedule) => {
                    if (scheduleSubmit) {
                      setFieldValue('schedule', schedule);
                      handleSubmit();
                    }
                    setScheduleSubmit(false);
                  }}
                  scheduleData={values.schedule?.length ? values.schedule : scheduleMockData()}
                  timeRoundingUp
                  hours24
                />
              </div>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

const getValidationSchema = () =>
  objectYup().shape({
    fullName: stringYup().required('Введите полное наименование').max(65535, maxLengthTextError(65535)),
    shortName: stringYup().nullable().max(500, maxLengthTextError(500)),
    addressId: number().required('Выберите адрес'),
  });
