import { fetchRequest } from './index';
import { apiService } from '../../config/constants';

export const getSignedFile = async (id: number) =>
  await fetchRequest.get(`${apiService.files}/SignedFile?signId=${id}`);

const fileStorageApi = {
  getSignedFile,
};

export default fileStorageApi;
