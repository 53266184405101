import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetScrollElement } from '../../hooks/use-get-scroll-element';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const scrollElem = useGetScrollElement();

  useEffect(() => {
    if (scrollElem && scrollElem.clientHeight !== scrollElem.scrollHeight) {
      scrollElem.style.scrollBehavior = 'auto';
      setTimeout(() => {
        scrollElem.scrollTo(0, 0);
        scrollElem.style.removeProperty('scroll-behavior');
      }, 20);
    }
  }, [pathname, scrollElem]);

  return null;
}
