import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Push } from '@mosru/esz_uikit';
import SavePanel from '../../../../../../components/save-panel';
import { ServiceStatusEnum } from '../../../../../../mock-data/service-status-enum';
import { CandidateRestrictionTypeListData } from '../../../../../../types/service';
import { serviceTemplateApi } from '../../../../../../lib/api/service-template';
import { ServiceContext } from '../../../../index';
import LimitEdit from './limit-edit';
import { templateApi } from '../../../../../../lib/api/template';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
  parent?: string;
};

const Limit: React.FC<Props> = ({ parent, isTemplate, setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const initialValues = serviceData.candidateRestrictionType ?? ({} as CandidateRestrictionTypeListData);
  const [editMode, setEditMode] = useState(!setEditModeParent);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;
  const submitLimit = async (values: CandidateRestrictionTypeListData) => {
    try {
      if (isTemplate) {
        await templateApi.setTemplateCandidateRestriction({
          ...values,
          serviceId: serviceData.id,
          educationTypeId: serviceData.educationTypeId,
        });
      } else {
        await serviceTemplateApi.setCandidateRestriction({
          ...values,
          serviceId: serviceData.id,
          educationTypeId: serviceData.educationTypeId,
        });
      }

      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } catch {}
  };

  return (
    <Formik
      onSubmit={(value) => submitLimit(value)}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<CandidateRestrictionTypeListData>) => {
        const { isSubmitting, isValid, resetForm, handleSubmit, submitForm, values } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <LimitEdit
              parent={parent}
              editMode={editMode}
              isTemplate={isTemplate}
              setEditMode={setEditMode}
              setEditModeParent={setEditModeParent}
              checkEditable={checkEditable && (isTemplate || values.canChangeCandidateRestriction)}
            />
            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent && setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Limit;
