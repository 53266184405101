import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LmButton, LmRadio } from '@mes-ui/lemma';
import { FormGroup, SelectOptionType } from '@mosru/esz_uikit';
import { useFormikContext } from 'formik';
import FilterContentItem from '../../../../../components/filter/filter-content-item';
import FormikInput from '../../../../../components/formik/formik-input';
import FormikSelect from '../../../../../components/formik/formik-select';
import SheduleModal, { Weekday } from '../../../../../components/shedule-modal';
import { weekday } from '../../../../../components/shedule-modal/helpers';
import lookupApi from '../../../../../lib/api/lookup';
import { ReportFilterTypeEnum } from '../../../../../mock-data/report-filter-type-enum';
import { ReportFilterData, ReportSchedule, TypeSwitch } from '../../../../../types/reports';
import { disableFilterPlaceholder, SavedReportsFilterContext } from '../index';

type ServiceItemProps = {
  parallels: SelectOptionType[];
  letters: SelectOptionType[];
  typesFinancing: TypeSwitch[];
  resetFlag: number;
};

const ServiceItem = ({ parallels, letters, typesFinancing, resetFlag }: ServiceItemProps) => {
  const { values, setFieldValue } = useFormikContext<ReportFilterData>();
  const {
    getFilterName,
    getFilterLabel,
    getFilterData,
    getFilterAllOption,
    getFilterReadOnly,
    getFilterTypeAheadPlaceholder,
  } = useContext(SavedReportsFilterContext);

  const [resetScheduleModal, setResetScheduleModal] = useState<boolean>(false);
  const [scheduleModal, setScheduleModal] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<ReportSchedule>({
    selectDaysForRequest: '',
    selectShortDay: '',
  });
  const [currentSelectDay, setCurrentSelectDay] = useState<Weekday[]>([]);

  const resetScheduleModalHandler = useCallback(
    (value: boolean) => {
      setResetScheduleModal(value);
      setCurrentSelectDay(weekday);
      if (value) {
        setSchedule({
          selectDaysForRequest: '',
          selectShortDay: '',
        });
        setFieldValue(getFilterName(ReportFilterTypeEnum.BySchedule), '');
      }
    },
    [getFilterName, setFieldValue]
  );

  useEffect(() => {
    resetScheduleModalHandler(true);
  }, [resetFlag, resetScheduleModalHandler]);

  const openScheduleModal = () => {
    setScheduleModal(true);
  };

  const setScheduleValue = (value: ReportSchedule) => {
    setSchedule(value);
    setFieldValue(getFilterName(ReportFilterTypeEnum.BySchedule), value.selectDaysForRequest);
  };

  return (
    <>
      <FilterContentItem
        title="Детское объединение и услуга"
        dataTest="service"
      >
        <FormikSelect
          size="small"
          withSearch
          showTooltip
          disabledPortalTooltip
          label={getFilterLabel(ReportFilterTypeEnum.ByProgrammLevel)}
          name={getFilterName(ReportFilterTypeEnum.ByProgrammLevel)}
          options={getFilterData(ReportFilterTypeEnum.ByProgrammLevel)}
          defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByProgrammLevel)}
          disabled={getFilterReadOnly(ReportFilterTypeEnum.ByProgrammLevel)}
        />

        <FormikSelect
          label={getFilterLabel(ReportFilterTypeEnum.ByService)}
          name={getFilterName(ReportFilterTypeEnum.ByService)}
          size="small"
          withSearch
          showTooltip
          disabledPortalTooltip
          options={[]}
          loadOptions={async (query) => await lookupApi.getService(query)}
          placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByService).placeholder}
          explainText={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByService).explainText}
          disabled={getFilterReadOnly(ReportFilterTypeEnum.ByService)}
        />

        <FormikSelect
          loadOptions={async (query) => {
            return await lookupApi.getServiceClass(query);
          }}
          showTooltip
          disabledPortalTooltip
          placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByServiceClass).placeholder}
          explainText={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByServiceClass).explainText}
          label={getFilterLabel(ReportFilterTypeEnum.ByServiceClass)}
          name={getFilterName(ReportFilterTypeEnum.ByServiceClass)}
          size="small"
          withSearch
          options={[]}
          disabled={getFilterReadOnly(ReportFilterTypeEnum.ByServiceClass)}
        />

        <FormikSelect
          explainText={
            getFilterReadOnly(ReportFilterTypeEnum.ByClassParallel)
              ? disableFilterPlaceholder
              : !values.organizationId
                  ? 'Сначала выберите организацию'
                  : undefined
          }
          placeholder="Выберите..."
          label={getFilterLabel(ReportFilterTypeEnum.ByClassParallel)}
          name={getFilterName(ReportFilterTypeEnum.ByClassParallel)}
          size="small"
          withSearch
          options={parallels}
          disabled={getFilterReadOnly(ReportFilterTypeEnum.ByClassParallel) || !values.organizationId}
          defaultValue={parallels ? parallels[0] : undefined}
        />

        <FormikSelect
          explainText={
            getFilterReadOnly(ReportFilterTypeEnum.ByClassLetter)
              ? disableFilterPlaceholder
              : !values.organizationId
                  ? 'Сначала выберите организацию'
                  : undefined
          }
          placeholder="Выберите..."
          label={getFilterLabel(ReportFilterTypeEnum.ByClassLetter)}
          name={getFilterName(ReportFilterTypeEnum.ByClassLetter)}
          size="small"
          withSearch
          options={letters}
          disabled={getFilterReadOnly(ReportFilterTypeEnum.ByClassParallel) || !values.organizationId}
          defaultValue={letters ? letters[0] : undefined}
        />

        <div className="report__search--wrap">
          <div className="search__wrap--input">
            <FormikInput
              label={getFilterLabel(ReportFilterTypeEnum.BySchedule)}
              size="small"
              name={getFilterName(ReportFilterTypeEnum.BySchedule)}
              value={schedule.selectShortDay}
              placeholder="Не указано"
              explainText={getFilterReadOnly(ReportFilterTypeEnum.BySchedule) ? disableFilterPlaceholder : undefined}
              disabled
            />
          </div>
          <div className="search__wrap--btn">
            <div className="btn__group">
              <LmButton
                dataTest="resetSchedule"
                type="button"
                variant="secondary"
                icon="filled-edit-close"
                iconSize={20}
                disabled={getFilterReadOnly(ReportFilterTypeEnum.BySchedule)}
                onClick={() => {
                  resetScheduleModalHandler(true);
                }}
              />
              <LmButton
                type="button"
                variant="outline"
                icon="filled-controls-options-horizontal"
                disabled={getFilterReadOnly(ReportFilterTypeEnum.BySchedule)}
                onClick={openScheduleModal}
              />
            </div>
          </div>
        </div>

        <FormikSelect
          loadOptions={async (query) => {
            return await lookupApi.getTeachers(query, values.organizationId);
          }}
          showTooltip
          disabledPortalTooltip
          label={getFilterLabel(ReportFilterTypeEnum.ByTeacher)}
          name={getFilterName(ReportFilterTypeEnum.ByTeacher)}
          size="small"
          withSearch
          placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByTeacher).placeholder}
          explainText={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByTeacher).explainText}
          options={[]}
          disabled={getFilterReadOnly(ReportFilterTypeEnum.ByTeacher)}
        />

        <FormGroup label={getFilterLabel(ReportFilterTypeEnum.ByTypeFinansing)}>
          <>
            {typesFinancing.map(({ id, name }) => (
              <LmRadio
                key={name}
                boxSize="large"
                name={`${getFilterName(ReportFilterTypeEnum.ByTypeFinansing)}-${id}`}
                dataTest={`${getFilterName(ReportFilterTypeEnum.ByTypeFinansing)}-${id}`}
                onChange={() => {
                  setFieldValue(getFilterName(ReportFilterTypeEnum.ByTypeFinansing), id);
                }}
                checked={values[getFilterName(ReportFilterTypeEnum.ByTypeFinansing)] === id}
              >
                {name}
              </LmRadio>
            ))}
          </>
        </FormGroup>
      </FilterContentItem>
      <SheduleModal
        show={scheduleModal}
        title="Время работы"
        reset={resetScheduleModal}
        saveSchedule={setScheduleValue}
        currentSelectDay={currentSelectDay}
        resetModal={resetScheduleModalHandler}
        setCurrentSelectDay={setCurrentSelectDay}
        currentShortDay={schedule.selectShortDay}
        onCloseHandler={() => setScheduleModal(false)}
      />
    </>
  );
};

export default ServiceItem;
