// Типы фильтров отчтетов
export enum ReportFilterTypeEnum {
  ByVedomstvo = 1,
  ByMrsd = 2,
  ByOrganization = 3,
  ByTerritory = 4,
  ByService = 5,
  ByReportDate = 6,
  ByPeriodFrom = 7,
  ByPeriodTo = 8,
  ByTypeFinansing = 9,
  ByServiceStatus = 10,
  ByTypeProvServ = 11,
  ByContingentLinkType = 12,
  ByClassificatorEKU = 13,
  ByContingentOnly = 14,
  ByAgeGroup = 15,
  ByEducationType = 16,
  ByPageNumber = 17,
  ByMinAgeGroup = 18,
  ByMaxAgeGroup = 19,
  ByProgrammLevel = 20,
  ByYearOfTraining = 21,
  BySearchText = 22,
  ByClassParallel = 23,
  ByClassLetter = 24,
  ByServiceClass = 25,
  BySchedule = 26,
  ByTeacher = 27,
  ByCssOrganization = 28,
  ByCssBranchOrganization = 29,
  ByExcludeTestOrganization = 30,
}
