import { LmNavigationItem } from '@mes-ui/lemma';
import { LmIconNames } from '@mes-ui/lemma/components/LmIcon/lmIconGenerateComponents';
import { LmNavigationSidebarProps } from '@mes-ui/lemma/organisms/LmNavigationSidebar/LmNavigationSidebar';
import { matchPath } from 'react-router-dom';
import { routes } from '../../config/constants';
import history from '../../history';
import { sidebarList } from '../../mock-data/routes';
import { AuthorizationData } from '../../types/authorization-data';
import { EducationTypeEnum } from '../../types/education-type';
import { MenuSettings } from '../../types/entities';
import { generateLink } from './index';

export const convertToHeaderList = (
  menu: MenuSettings[],
  parentId: number | null = null,
  userProfile: AuthorizationData
): LmNavigationItem[] => {
  const children = menu?.filter((child) => child.parentId === parentId);

  return children.reduce((result: LmNavigationItem[], child) => {
    const item = sidebarList.find((x) => x.id === child.id);
    const list = convertToHeaderList(menu, child.id, userProfile);

    if (item) {
      if (item.checkAccess(userProfile)) {
        return [
          ...result,
          {
            id: child.id,
            title: child.menuName,
            link: item.link,
            icon: item.icon,
            selected: false,
            onClick: () => (item.redirect ? item.redirect() : item.link && history.push(item.link)),
            list: list.length ? (list as LmNavigationItem['list']) : undefined,
          },
        ];
      }
    } else {
      console.error(`Error: menuSetting id:${child.id} not found in SidebarLink`);
    }

    return result;
  }, []);
};

export const checkServiceLink = (pathname: string, navLink: string | undefined) => {
  const serviceRoutes = {
    [routes.programs]: [
      generateLink(routes.createService, {
        edType: EducationTypeEnum.ChildrenEducation,
      }),
      generateLink(routes.createService, {
        edType: EducationTypeEnum.ChildrenNonDogmEducation,
      }),
      generateLink(routes.createService, {
        edType: EducationTypeEnum.VirtualAssistantEducation,
      }),
      generateLink(routes.createService, {
        edType: EducationTypeEnum.DayCareCentersEducation,
      }),
    ],
    [routes.registerChildEducations]: [
      generateLink(routes.createService, {
        edType: EducationTypeEnum.ChildrenEducation,
      }),
      generateLink(routes.createService, {
        edType: EducationTypeEnum.ChildrenNonDogmEducation,
      }),
    ],
    [routes.dayCarePrograms]: [
      generateLink(routes.createService, {
        edType: EducationTypeEnum.DayCareCentersEducation,
      }),
    ],
    [routes.registerEducationPrograms]: [
      generateLink(routes.createService, {
        edType: EducationTypeEnum.ProfessionalEducation,
      }),
    ],
    [routes.services]: [
      generateLink(routes.createService, {
        edType: EducationTypeEnum.ArtHouseEducation,
      }),
      generateLink(routes.createService, {
        edType: EducationTypeEnum.SportEducation,
      }),
    ],
  };

  return (
    !!navLink &&
    !!serviceRoutes[navLink]?.some(
      (link) =>
        !!matchPath(pathname, {
          path: link,
        })
    )
  );
};

export const selectNavigationItem = (list: LmNavigationItem[], pathname: string): LmNavigationItem[] =>
  list.map((item) => {
    const list = item.list && selectNavigationItem(item.list as LmNavigationItem[], pathname);

    return {
      ...item,
      selected:
        (matchPath(pathname, {
          path: routes.createService,
        })
          ? checkServiceLink(pathname, item.link)
          : !!matchPath(pathname, {
              path: item.link,
              exact: item.link === routes.main,
            })) || !!list?.some(({ selected }) => selected),
      list: list as LmNavigationItem['list'],
    };
  });

export const convertToNavigationList = (
  navItem: LmNavigationItem | undefined
): LmNavigationSidebarProps['navigationList'] =>
  navItem
    ? [
        {
          id: navItem.id as number,
          navigationItemsList:
            navItem.list?.map((item) => ({
              text: item.title,
              icon: item.icon as LmIconNames,
              id: item.id as number,
              link: item.link,
              selected: item.selected,
              onClick: item.onClick,
            })) ?? [],
        },
      ]
    : [];
