import React, { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import { DocumentDataFinishedAndTypes, Learner } from '../../../../types/learners';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../mock-data/access-enum';
import FormMoscow from './form-moskow';
import FormOther from './form-other';
import { DocumentModalOtherCity } from '../../../../types/document-modal-other';

type Props = {
  pupilId: string;
  isMoscow: boolean;
  newDocument: boolean;
  accessDisabled: boolean;
  documentDataOther: DocumentModalOtherCity.InitialDataDocumentOtherCity;
  onCloseHandler: () => void;
  dataDocument: Learner.Document;
  setIsMoscow: (value: boolean) => void;
  setValidateFormMoscow: (value: boolean) => void;
  setValidateFormOtherCity: (value: boolean) => void;
  documentDataFinishedAndTypes: DocumentDataFinishedAndTypes;
};

const AddLearnerDocumentModal = forwardRef<any, Props>((props, ref) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdminView = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminView), [userProfile]);
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const hasCreateDocument = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.CompletionDocument], accessAction.Create),
    [userProfile]
  );

  return (
    <>
      {(isAdminView || isAdminEdit) && props.newDocument && (
        <LmSwitcher
          dataTest="groupIssued"
          size="small"
          onChange={props.setIsMoscow}
          value={props.isMoscow}
          variant="gray"
        >
          <LmSwitcherOption
            dataTest="switcher-isMoscow-true"
            value
          >
            Выдан в Москве
          </LmSwitcherOption>
          <LmSwitcherOption
            dataTest="switcher-isMoscow-false"
            value={false}
          >
            Выдан в другом городе
          </LmSwitcherOption>
        </LmSwitcher>
      )}

      {props.isMoscow ? (
        <FormMoscow
          ref={ref}
          pupilId={props.pupilId}
          data={props.dataDocument}
          newDocument={props.newDocument}
          onCloseHandler={props.onCloseHandler}
          accessDisabled={props.accessDisabled}
          setValidateFormMoscow={props.setValidateFormMoscow}
          documentDataFinishedAndTypes={props.documentDataFinishedAndTypes}
          hasCreateDocument={hasCreateDocument}
        />
      ) : (
        <FormOther
          ref={ref}
          pupilId={props.pupilId}
          data={props.documentDataOther}
          newDocument={props.newDocument}
          onCloseHandler={props.onCloseHandler}
          setValidateFormOtherCity={props.setValidateFormOtherCity}
          documentDataFinishedAndTypes={props.documentDataFinishedAndTypes}
        />
      )}
    </>
  );
});

export default AddLearnerDocumentModal;
