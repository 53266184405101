import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import { LmFilterVertical } from '@mes-ui/lemma';
import FormikCheckbox from '../../../components/formik/formik-checkbox';
import FilterLoader from '../../../components/loader-custom/filter-loader';
import { searchAction } from '../../../lib/utils/organization';
import { historyState } from '../../../mock-data/history-state';
import { SearchOrganizationInitialFormData } from '../../../types/organization';
import FormikSelect from '../../../components/formik/formik-select';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { accessAction, accessObject, accessVedomst, generalAccess } from '../../../mock-data/access-enum';
import { hasAccessObjectAny, hasGeneralAccess, replaceHistoryState } from '../../../lib/utils';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import { searchOrganizationInitialFormData } from '../index';

type Props = {
  isAdminView: boolean;
  isAdminEdit: boolean;
  showNotAcceptedToggle: boolean;
  initialForm: SearchOrganizationInitialFormData;
  submitForm: (values: SearchOrganizationInitialFormData) => void;
};

const OrganizationSearch: React.FC<Props> = ({
  initialForm,
  submitForm,
  isAdminView,
  isAdminEdit,
  showNotAcceptedToggle,
}) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [isLoadingVedomstvoList, setIsLoadingVedomstvoList] = useState(false);

  const accessToggleArchiveAndTest =
    (hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.ViewRegistry) &&
      userProfile.vedomstvoId !== accessVedomst.Dogm) ||
    isAdminView;

  // Тип организации не определен
  const accessOrganizationNotDefined =
    isAdminEdit &&
    userProfile.vedomstvoId !== accessVedomst.Dkgm &&
    userProfile.vedomstvoId !== accessVedomst.Dogm &&
    userProfile.vedomstvoId !== accessVedomst.Dsit;

  const accessOrgDkgm = isAdminEdit && userProfile.vedomstvoId === accessVedomst.Dkgm;

  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const [departmentData, setDepartmentData] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    if (isAdminView) {
      (async () => {
        try {
          setIsLoadingVedomstvoList(true);
          const departments = await dictionariesApi.getVedomstvo();

          setDepartmentData(departments);
        } finally {
          setIsLoadingVedomstvoList(false);
        }
      })();
    }
  }, [isAdminView]);

  const handleResetForm = () => {
    submitForm(searchOrganizationInitialFormData);
  };

  const organizationNotDefined = () => {
    const element = (value: string) => (
      <FormikCheckbox
        name={value}
        label="Тип организации не определен"
      />
    );

    if (accessOrganizationNotDefined) {
      return element('excludeOrganizationType');
    }
    if (accessOrgDkgm) {
      return element('excludeOrganizationTypeDkgm');
    }
  };

  const handleClickAdvancedSearch = (isOpen: boolean) => {
    replaceHistoryState({
      [historyState.openAdvanced]: isOpen,
    });
    setOpen(isOpen);
  };

  const handleSearch = (
    values: SearchOrganizationInitialFormData,
    formikHelpers: FormikHelpers<SearchOrganizationInitialFormData>
  ) => {
    submitForm(values);
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSearch}
      enableReinitialize
      initialValues={initialForm}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        resetForm,
      }: FormikProps<SearchOrganizationInitialFormData>) => {
        const copyObject: Pick<
          SearchOrganizationInitialFormData,
          'excludeWoVedomstvo' | 'organizationNotDefined' | 'query' | 'showArchive' | 'showNotAccepted'
        > = {
          query: values.query,
          showArchive: values.showArchive,
          showNotAccepted: values.showNotAccepted,
          excludeWoVedomstvo: values.excludeWoVedomstvo,
          organizationNotDefined: values.organizationNotDefined,
        };

        // если пользователь(не админ) имеет право на просмотр реестра организаций и организация пользователя ДОНМ + право ЭП тогда убираем excludeTest,
        const value =
          !accessToggleArchiveAndTest &&
          hasGeneralAccess(userProfile, generalAccess.UseSign) &&
          userProfile.vedomstvoId === accessVedomst.Dogm &&
          hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.ViewRegistry)
            ? copyObject
            : values;

        const count = searchAction(value, !accessToggleArchiveAndTest);

        return (
          <form onSubmit={handleSubmit}>
            <LmFilterVertical
              dataTest="organizationFilter"
              classNameContent="filter-content"
              open={open}
              searchValue={values.query}
              searchPlaceholder="Поиск по организациям..."
              onChange={(value) => setFieldValue('query', value)}
              toggleOpen={handleClickAdvancedSearch}
              onClear={() => {
                resetForm({
                  values: searchOrganizationInitialFormData,
                });
                handleResetForm();
              }}
              buttonSecondaryText={count ? `Сбросить фильтры (${count})` : 'Сбросить фильтры'}
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: isLoadingVedomstvoList,
                type: 'submit',
              }}
              secondaryButtonModifiers={{
                disabled: isLoadingVedomstvoList,
              }}
            >
              {isLoadingVedomstvoList ? (
                <FilterLoader />
              ) : (
                <>
                  {isAdminView && (
                    <FormikSelect
                      label="Департамент"
                      size="medium"
                      withSearch
                      name="vedomstvoId"
                      showTooltip
                      disabledPortalTooltip
                      clearable
                      options={departmentData}
                      placeholder="Начните вводить или выберите..."
                    />
                  )}
                  <div>
                    {accessToggleArchiveAndTest && (
                      <>
                        <FormikCheckbox
                          name="showArchive"
                          label="Отображать архивные"
                        />
                        <FormikCheckbox
                          name="excludeTest"
                          label="Исключить тестовые"
                        />
                      </>
                    )}
                    {showNotAcceptedToggle && (
                      <FormikCheckbox
                        name="showNotAccepted"
                        label="Ожидающие подтверждения"
                      />
                    )}
                    {isAdminEdit && (
                      <FormikCheckbox
                        name="excludeWoVedomstvo"
                        label="Департамент не определен"
                      />
                    )}
                    {organizationNotDefined()}
                  </div>
                </>
              )}
            </LmFilterVertical>
          </form>
        );
      }}
    </Formik>
  );
};

export default OrganizationSearch;
