import { LmButton, LmIcon } from '@mes-ui/lemma';
import { Push } from '@mosru/esz_uikit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Informer from '../../../../components/informer';
import { externalSystemApi } from '../../../../lib/api/external-system';
import requestsApi from '../../../../lib/api/requests';
import { formatDateAndTime } from '../../../../lib/utils/date';
import { ExternalSystemResponseTypeEnum } from '../../../../mock-data/external-system-response-type-enum';
import { RequestSourceEnum } from '../../../../mock-data/request-source-enum';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { ExternalSystemResponseData } from '../../../../types/external-system';
import { RequestData } from '../../../../types/requests';

type Props = {
  requestData: RequestData;
};

enum CheckStatusTypeEnum {
  NotRequired = 1,
  Successful = 2,
  NotConfirmed = 3,
  Error = 4,
  Pending = 6,
}

const InterdepartmentalChecks: React.FC<Props> = ({ requestData }) => {
  const [checksDetails, setChecksDetails] = useState(false);
  const [statuses, setStatuses] = useState<ExternalSystemResponseData[] | undefined>();
  const [statusType, setStatusType] = useState<CheckStatusTypeEnum>();

  const buttonVisible = useMemo(
    () =>
      requestData.requestSourceId === RequestSourceEnum.Operator &&
      (requestData.applicant?.documentTypeId === DocumentTypeEnum.Passport ||
        requestData.child?.documentTypeId === DocumentTypeEnum.Passport ||
        requestData.child?.documentTypeId === DocumentTypeEnum.BirthCertificate),
    [requestData]
  );

  const buttonDisabledTimeOut = useMemo(
    () =>
      statuses && statuses.length > 0
        ? statuses.every((s) => s.dateCreate && Date.now() - new Date(s.dateCreate).getTime() < 600000)
        : false,
    [statuses]
  );

  const buttonDisabled = useMemo(
    () =>
      requestData.requestStatusId === RequestStatusEnum.Included ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      (statuses && statuses.some((s) => s.externalSystemResponseStatus.statusCode === 1)),
    [requestData.requestStatusId, statuses]
  );

  const fetchStatuses = useCallback(() => {
    const fetch = async () => {
      const statuses = await externalSystemApi.getCheckStatuses(requestData.extendEntityGuid, [
        ExternalSystemResponseTypeEnum.ApplicantPassportValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.BirthCertificateValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.PupilPassportValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.RegistrationAddressValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.RelatednessCheckingRequest,
        ExternalSystemResponseTypeEnum.BirthRecordCheck,
      ]);

      setStatuses(statuses);
    };

    return fetch();
  }, [requestData.extendEntityGuid]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses, requestData.extendEntityGuid]);

  useEffect(() => {
    if (statuses?.length === 0) {
      setStatusType(CheckStatusTypeEnum.NotRequired);
    } else if (
      statuses?.every(
        (e) => e.externalSystemResponseStatus.statusCode === 2 || e.externalSystemResponseStatus.statusCode === 5
      )
    ) {
      setStatusType(CheckStatusTypeEnum.Successful);
    } else if (statuses?.every((e) => e.externalSystemResponseStatus.statusCode === 3)) {
      setStatusType(CheckStatusTypeEnum.NotConfirmed);
    } else if (statuses?.some((e) => e.externalSystemResponseStatus.isErrorStatus)) {
      setStatusType(CheckStatusTypeEnum.Error);
    } else if (statuses?.some((e) => e.externalSystemResponseStatus.statusCode === 1)) {
      setStatusType(CheckStatusTypeEnum.Pending);
    }
  }, [statuses]);

  const getStatusColor = useCallback(() => {
    switch (statusType) {
      case CheckStatusTypeEnum.NotRequired:
        return 'primary';
      case CheckStatusTypeEnum.Successful:
        return 'success';
      case CheckStatusTypeEnum.NotConfirmed:
      case CheckStatusTypeEnum.Error:
        return 'danger';
      case CheckStatusTypeEnum.Pending:
        return 'warning';
      default:
        return 'primary';
    }
  }, [statusType]);

  const getButtonColor = useCallback(() => {
    switch (statusType) {
      case CheckStatusTypeEnum.NotRequired:
        return 'indigo';
      case CheckStatusTypeEnum.Successful:
        return 'success';
      case CheckStatusTypeEnum.NotConfirmed:
      case CheckStatusTypeEnum.Error:
        return 'danger';
      case CheckStatusTypeEnum.Pending:
        return 'warning';
      default:
        return 'indigo';
    }
  }, [statusType]);

  const buildHead = useCallback(() => {
    switch (statusType) {
      case CheckStatusTypeEnum.NotRequired:
        return 'Межведомственные проверки не требуются';
      case CheckStatusTypeEnum.Successful:
        return 'Межведомственные проверки пройдены успешно';
      case CheckStatusTypeEnum.NotConfirmed:
        return 'Межведомственные проверки пройдены. Данные не подтверждены';
      case CheckStatusTypeEnum.Error:
        return 'Межведомственные проверки не пройдены';
      case CheckStatusTypeEnum.Pending:
        return 'Межведомственные проверки запрошены';
      default:
        return '';
    }
  }, [statusType]);

  const requestForInfo = async () => {
    await requestsApi.sendIrsRequests(requestData.extendEntityGuid);
    fetchStatuses();
  };

  return statuses ? (
    <>
      <Push size={12} />
      <Informer
        dataTest="interdepartmentalInspections"
        color={getStatusColor()}
        title={buildHead()}
        controls={
          <>
            {!!statuses?.length && (
              <button
                data-test="collapsibleButton"
                type="button"
                onClick={() => setChecksDetails(!checksDetails)}
                className={`informer__collapsible informer__collapsible--${getStatusColor()}`}
              >
                <span>{checksDetails ? 'Свернуть' : 'Подробнее'}</span>
                <span className="informer__collapsible-icon">
                  {checksDetails ? (
                    <LmIcon
                      icon="filled-chevrons-small-up"
                      size={20}
                    />
                  ) : (
                    <LmIcon
                      icon="filled-chevrons-small-down"
                      size={20}
                    />
                  )}
                </span>
              </button>
            )}
            {buttonVisible && (
              <LmButton
                dataTest="requestInfo"
                type="button"
                variant="primary"
                color={getButtonColor()}
                disabled={buttonDisabledTimeOut || buttonDisabled}
                onClick={requestForInfo}
              >
                Запросить сведения
              </LmButton>
            )}
          </>
        }
        body={
          checksDetails && statuses ? (
            <div className="requests-infobox-table">
              <div className="requests-infobox-table__row">
                <div className="requests-infobox-table__type informer__text--bold">Тип проверки</div>
                <div className="requests-infobox-table__dates informer__text--bold">Начало и окончание проверки</div>
                <div className="requests-infobox-table__result informer__text--bold">Результат</div>
              </div>
              {statuses.map((s) => (
                <div className="requests-infobox-table__row">
                  <div className="requests-infobox-table__type">
                    {s.externalSystemResponseStatus.externalSystemResponseType.responseTypeName}
                  </div>
                  <div className="requests-infobox-table__dates">
                    {s.externalSystemResponseStatus.statusCode === 5 ||
                    s.externalSystemResponseStatus.statusCode === 0 ? (
                          <>—</>
                        ) : s.externalSystemResponseStatus.statusCode === 1 ? (
                          formatDateAndTime(s.dateCreate)
                        ) : (
                          formatDateAndTime(s.dateCreate) + (s.dateChange ? `—${formatDateAndTime(s.dateChange)}` : '')
                        )}
                  </div>
                  <div className="requests-infobox-table__result">{s.externalSystemResponseStatus.statusName}</div>
                </div>
              ))}
            </div>
          ) : undefined
        }
      />
      {requestData.scanDocsApproved && (
        <>
          <Push size={12} />
          <Informer
            dataTest="copyDocumentsSuccess"
            color="success"
            title="Скан-копии документов Заявителя были приняты"
          />
        </>
      )}
    </>
  ) : null;
};

export default InterdepartmentalChecks;
