import React, { useMemo } from 'react';
import { ChildServiceProps } from '..';
import { SearchRequestsInitialFormData } from '../../../../../../types/requests';
import { accessAction, accessObject, AuthorizationData } from '../../../../../../types/authorization-data';
import FieldTestDate from '../../../fields/test-date';
import FieldEducationType from '../../../fields/education-type';
import FieldVedomstvo from '../../../fields/vedomstvo';
import FieldClassificatorEku from '../../../fields/classificator-eku';
import { langApi } from '../../../../../../types/lang';
import FieldProgrammLevel from '../../../fields/programmLevel';
import { EducationTypeEnum } from '../../../../../../types/education-type';
import FieldOrganization from '../../../fields/organization';
import FieldService from '../../../fields/service';
import FieldServiceClass from '../../../fields/service-class';
import FieldPlaceService from '../../../fields/place-service';
import FieldTrainingGroup from '../../../fields/training-group';
import { hasAccessObjectAny } from '../../../../../../lib/utils';
import FieldYearOfTraining from '../../../fields/year-of-training';
import { NotificationField } from '../../../../../../components/fields/notification';

type Props = Omit<ChildServiceProps, 'fieldshModelItem' | 'onChangeEducationType'> & {
  isAdmin: boolean;
  values: SearchRequestsInitialFormData;
  userProfile: AuthorizationData;
};

const ChildService: React.FC<Props> = ({
  allowedEducationTypeId,
  isArtHouseSportEducation,
  canChangeOrganization,
  isAdmin,
  values,
  userProfile,
}) => {
  // показывать "Вид деятельности" или "Профессия"
  const isDsitDkgm = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.RequestArtHouse, accessObject.RequestSport], accessAction.View),
    [userProfile]
  );

  return (
    <>
      {/* Тип услуги */}
      <FieldEducationType />

      {/* "Департамент" */}
      <FieldVedomstvo
        isAdmin={isAdmin}
        label="Департамент"
        placeholder='Выберите департамент..."'
      />

      {/* "Вид деятельности", "Профессия",  "Вид деятельности"  */}
      <FieldClassificatorEku
        label={langApi.get(allowedEducationTypeId, 'Classificator')}
        placeholder="Начните вводить или выберите..."
        isAdmin={isAdmin}
      />

      {/* "Квалификация",  "Уровень программы"  */}
      <FieldProgrammLevel
        allowedEducationTypeId={allowedEducationTypeId}
        values={values}
        isArtHouseSportEducation={isArtHouseSportEducation}
        label={langApi.get(allowedEducationTypeId, 'ProgrammLevel')}
        placeholder="Выберите..."
        explainText={
          !values.classificatorEKUId && !isArtHouseSportEducation
            ? allowedEducationTypeId === EducationTypeEnum.ProfessionalEducation
              ? 'Сначала выберите профессию'
              : 'Сначала выберите вид деятельности'
            : undefined
        }
      />

      {/* По уведомлению */}
      <NotificationField />

      {/* "Дата испытаний / явки" */}
      <FieldTestDate
        isArtHouseSportEducation={isArtHouseSportEducation}
        label="Дата испытаний / явки"
      />

      {/* "Образовательная организация" */}
      <FieldOrganization
        values={values}
        label={langApi.get(allowedEducationTypeId, 'OrganizationIm')}
        placeholder="Начните вводить..."
        explainText={!values.vedomstvoId ? 'Сначала выберите департамент' : undefined}
        disabled={!canChangeOrganization && values.vedomstvoId !== undefined}
      />

      {/* "Наименование детского объединения", "Наименование образовательной программы",
            "Наименование программы по присмотру и уходу за детьми школьного возраста",
            "Наименование услуги"
         */}
      <FieldService
        values={values}
        label={`Наименование ${langApi.get(allowedEducationTypeId, 'ServiceRod', true)}`}
        placeholder="Начните вводить..."
        explainText={
          !values.organizationId
            ? `Сначала выберите ${langApi.get(allowedEducationTypeId, 'OrganizationVin', true)}`
            : undefined
        }
        disabled={!values.organizationId}
      />

      {/* "Наименование группы" */}
      <FieldServiceClass
        values={values}
        allowedEducationTypeId={allowedEducationTypeId}
        label="Наименование группы"
        placeholder="Начните вводить..."
      />

      {/* 'Место предоставления', 'Адрес предоставления' */}
      <FieldPlaceService
        values={values}
        label={isArtHouseSportEducation ? 'Место предоставления' : 'Адрес предоставления'}
        placeholder="Начните вводить..."
        explainText={
          !values.organizationId
            ? isArtHouseSportEducation
              ? 'Сначала выберите образовательную организацию'
              : `Сначала выберите ${langApi.get(allowedEducationTypeId, 'OrganizationVin', true)}`
            : undefined
        }
        isAdmin={isAdmin}
      />

      {/* "План приема" */}
      <FieldTrainingGroup
        values={values}
        label={isDsitDkgm ? 'План приёма, вступительные испытания' : 'План приема'}
        placeholder="Выберите..."
        explainText={
          !values.serviceId ? `Сначала выберите ${langApi.get(allowedEducationTypeId, 'ServiceVin', true)}` : undefined
        }
      />

      {/* "Период обучения" */}
      <FieldYearOfTraining
        values={values}
        isArtHouseSportEducation={isArtHouseSportEducation}
        label="Период обучения"
        placeholder="Выберите..."
      />
    </>
  );
};

export default ChildService;
