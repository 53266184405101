import React, { useContext } from 'react';
import { LmIcon, LmTooltip } from '@mes-ui/lemma';
import { ServiceContext } from '../../../index';
import { getDayOfTheWeekFromString } from '../../../../../lib/utils/time-table';
import SimpleTable from '../../../../../components/table/simple-table';

const ViewAddressDocuments: React.FC = () => {
  const { serviceData } = useContext(ServiceContext);

  return (
    <SimpleTable
      dataTest="addressDocuments"
      data={serviceData.itemWork.list
        .filter((i) => i.isSelected)
        .map((i, number) => {
          return {
            ...i,
            number: number + 1,
          };
        })}
      columns={[
        {
          dataIndex: 'number',
          title: '№',
          render: (item: any) => item.number,
          width: '40px ',
        },
        {
          dataIndex: 'isAddressArchive',
          title: '',
          render: (item: any) =>
            item.isAddressArchive && (
              <LmTooltip
                withArrow
                placement="right"
                content={
                  <div
                    className="text-center"
                    style={{
                      maxWidth: '203px',
                    }}
                  >
                    Адрес является архивным и более не используется организацией в качестве места приема документов
                  </div>
                }
              >
                <LmIcon
                  icon="outline-notifications-info"
                  size={18}
                  color="var(--LM-neutrals-day-700)"
                />
              </LmTooltip>
            ),
        },
        {
          dataIndex: 'name',
          title: 'Наименование организации',
          render: (item: any) => item.fullName,
          width: '45%',
        },
        {
          dataIndex: 'address',
          title: 'Адрес',
          render: (item: any) => item.fullAddress,
          width: '30%',
        },
        {
          dataIndex: 'schedule',
          title: 'Время работы',
          render: (item: any) =>
            getDayOfTheWeekFromString(item.timetable).map((item: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`schedule_${index}`}>{item}</div>
            )),
          width: '20%',
        },
      ]}
    />
  );
};

export default ViewAddressDocuments;
