import { LmSelectNewProps } from '@mes-ui/lemma';
import React from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import { SelectOptionType } from '../../../../types/entities';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import FieldAsyncSelect from './async-select';
import { setAsyncValues } from '../../../../lib/utils/requests';

type Props = Pick<LmSelectNewProps<SelectOptionType>, 'placeholder' | 'explainText'> & {
  values: SearchRequestsInitialFormData;
  label: string;
  disabled: boolean;
};

const FieldOrganization: React.FC<Props> = ({ values, label, placeholder, explainText, disabled }) => {
  const { setFieldValue } = useFormikContext<FormikHelpers<SearchRequestsInitialFormData>>();

  const onChange = () => {
    const dataToClearValues = [
      {
        key: 'serviceId',
      },
      {
        key: 'placeServiceId',
      },
      {
        key: 'trainingGroupId',
      },
    ];

    setAsyncValues(dataToClearValues, setFieldValue);
  };

  return (
    <FieldAsyncSelect
      disabled={disabled}
      onChange={onChange}
      nameFieldId="organizationId"
      nameFieldName="organizationName"
      label={label}
      placeholder={placeholder}
      explainText={explainText}
      showTooltip
      disabledPortalTooltip
      loadOptions={async (query) => await lookupApi.getOrganization(query, values.vedomstvoId)}
    />
  );
};

export default FieldOrganization;
