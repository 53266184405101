import { LmSelectNewProps } from '@mes-ui/lemma';
import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { EducationTypeEnum } from '../../../../types/education-type';
import requestsApi from '../../../../lib/api/requests';
import { serviceTemplateApi } from '../../../../lib/api/service-template';

type Props = Pick<LmSelectNewProps<SelectOptionType>, 'placeholder' | 'explainText'> & {
  values: SearchRequestsInitialFormData;
  allowedEducationTypeId: number;
  isArtHouseSportEducation: boolean;
  label: string;
};

const allOption = {
  value: null,
  label: 'Все',
};

const FieldProgrammLevel: React.FC<Props> = ({
  allowedEducationTypeId,
  values,
  isArtHouseSportEducation,
  label,
  placeholder,
  explainText,
}) => {
  const [programmLevelData, setProgrammLevelData] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    if (isArtHouseSportEducation) {
      const fetch = async () => {
        const dataProgrammLevels = await requestsApi.getProgrammLevels();

        dataProgrammLevels && setProgrammLevelData([allOption, ...dataProgrammLevels]);
      };

      fetch();
    }
  }, [isArtHouseSportEducation]);

  useEffect(() => {
    const fetchProgrammLevels = async (classificatorEKUId: number) => {
      setProgrammLevelData([
        allOption,
        ...(await serviceTemplateApi.getProgrammLevels({
          classificatorEKUId,
        })),
      ]);
    };

    if (values.classificatorEKUId) {
      fetchProgrammLevels(values.classificatorEKUId);
    } else {
      setProgrammLevelData([]);
    }
  }, [values.classificatorEKUId]);

  const [, , helpers] = useField('programmLevelId');
  const [, , helpersName] = useField('programmLevelName');

  const onChange = (option?: SelectOptionType) => {
    helpers.setValue(option?.value);
    helpersName.setValue(option?.label);
  };

  return allowedEducationTypeId !== EducationTypeEnum.DayCareCentersEducation ? (
    <FormikSelect
      disabled={!values.classificatorEKUId && !isArtHouseSportEducation}
      name="programmLevelId"
      label={label}
      size="small"
      withSearch
      options={programmLevelData}
      placeholder={placeholder}
      explainText={explainText}
      defaultValue={
        values.programmLevelId
          ? {
              label: values.programmLevelName ?? '',
              value: values.programmLevelId,
            }
          : values.programmLevelName === allOption.label
            ? allOption
            : null
      }
      selectedValue={onChange}
    />
  ) : null;
};

export default FieldProgrammLevel;
