import { LmButton, LmLoader, LmTab, LmTabs, LmTabsList } from '@mes-ui/lemma';
import { Push } from '@mosru/esz_uikit';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from '../../components/infinite-scroll';
import { formatDateAndTime } from '../../lib/utils/date';
import { userProfileSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';
import { useGetEventsQuery, useReadAllEventMutation } from '../../store/event';
import { EventParamsFormData } from '../../types/events';
import { getEventColor, getEventIcon, handleRead, tabList } from './utils';

type ListProps = {
  search: EventParamsFormData;
};

const List = ({ search }: ListProps) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [activeTab, setActiveTab] = useState<tabList>(tabList.All);

  const { data: eventList = [], isFetching } = useGetEventsQuery({
    startDate: search?.dates?.[0] ?? undefined,
    endDate: search?.dates?.[1] ?? undefined,
    selectedTypeId: search.selectedTypeId,
  });
  const [readAllEvents] = useReadAllEventMutation();

  const eventCount = eventList.length;
  const newEventCount = eventList.filter(({ isRead }) => !isRead).length;

  const activeList = useMemo(
    () =>
      activeTab === tabList.All
        ? eventList
        : activeTab === tabList.IsRead
          ? eventList.filter(({ isRead }) => isRead)
          : eventList.filter(({ isRead }) => !isRead),
    [activeTab, eventList]
  );

  const handleReadAll = async () => {
    await readAllEvents();
  };

  return (
    <div>
      <Push size={20} />
      <div className="container">
        {isFetching ? (
          <div className="loader-container">
            <LmLoader
              view="page"
              title="Загружаем уведомления"
              description="Нужно немного подождать"
            />
          </div>
        ) : (
          <>
            <div className="flex justify-between">
              <LmTabs
                initialState={{
                  tabsColor: 'accent',
                  tabsSize: 'medium',
                  tabsType: 'Button',
                }}
              >
                <LmTabsList dataTest="eventsPillTabs">
                  <LmTab
                    dataTest={`eventsPillTab${activeTab === tabList.All ? '-ActiveAll' : ''}`}
                    title={`Все ${eventCount}`}
                    value={1}
                    onClick={() => setActiveTab(tabList.All)}
                  />
                  <LmTab
                    dataTest={`eventsPillTab${activeTab === tabList.IsNew ? '-ActiveIsNew' : ''}`}
                    title={`Новые ${newEventCount}`}
                    value={2}
                    onClick={() => setActiveTab(tabList.IsNew)}
                  />
                  <LmTab
                    dataTest={`eventsPillTab${activeTab === tabList.IsRead ? '-ActiveIsRead' : ''}`}
                    title={`Прочитанные ${eventCount - newEventCount}`}
                    value={3}
                    onClick={() => setActiveTab(tabList.IsRead)}
                  />
                </LmTabsList>
              </LmTabs>
              <LmButton
                type="button"
                variant="secondary"
                onClick={handleReadAll}
              >
                Отметить все прочитанными
              </LmButton>
            </div>

            <Push size={8} />

            <ul className="ul-small">
              <InfiniteScroll
                data={activeList}
                renderItem={(item) => (
                  <li
                    key={item.id}
                    className="ul-small__item"
                  >
                    <div className="event-item items-center">
                      <div
                        className={`event-item__media cursor-pointer ${getEventColor(item.eventTypeId)}`}
                        onClick={() => handleRead(item, userProfile)}
                      >
                        {getEventIcon(item.eventTypeId)}
                      </div>
                      <div className="event-item__body">
                        <div
                          className="cursor-pointer"
                          dangerouslySetInnerHTML={{
                            __html: item.eventText || '',
                          }}
                          onClick={() => handleRead(item, userProfile)}
                        />
                        <div className="flex items-center color-gray-dark">
                          {item.dateCreate && <div className="event-date">{formatDateAndTime(item.dateCreate)}</div>}
                          {!item.isRead && (
                            <>
                              <Push
                                size={8}
                                orientation="horizontal"
                              />
                              <div className="event-new-badge" />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default List;
