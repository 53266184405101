import { SelectValueType } from '@mosru/esz_uikit';
import { ClassificatorChildData, ClassificatorSearchData, ClassificatorTreeData } from '../../types/classificator';
import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { IdName } from '../../types/service-class';

// меню для 6 отчета
export const getChildrens = async (data: {
  parentId?: number | string;
  edType?: SelectValueType;
  orgId?: number;
}): Promise<ClassificatorChildData[]> => {
  return await fetchRequest.get(`${apiService.data}/Classificator/Child/List`, data);
};
export const search = async (data: {
  parentId?: number | string;
  edType?: SelectValueType;
  orgId?: number;
  searchString?: string;
}): Promise<ClassificatorSearchData[]> => {
  return await fetchRequest.get(`${apiService.data}/Classificator/Search`, data);
};

export const getParents = async (data: { id: number }): Promise<any> => {
  return await fetchRequest.get(`${apiService.data}/Classificator/Parent/List`, data);
};

export const getProgrammLevels = async (classificatorEKUId: number): Promise<IdName[]> =>
  await fetchRequest.get(`${apiService.data}/Classificator/ProgrammLevel?classificatorEKUId=${classificatorEKUId}`);

export const getClassificatorTree = async (data: {
  educationTypeId?: number;
  vedomstvoId?: number;
  organizationId?: number;
  yearOfTrainingId?: number;
}): Promise<ClassificatorTreeData[]> => await fetchRequest.get(`${apiService.data}/Classificator/Tree`, data);

export const classificatorApi = {
  getParents,
  getChildrens,
  getProgrammLevels,
  getClassificatorTree,
  search,
};
