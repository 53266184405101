import { hasAccessObjectAny } from '../../../lib/utils';
import { accessObject } from '../../../mock-data/access-enum';
import { AuthorizationData } from '../../../types/authorization-data';

const access = (userProfile: AuthorizationData, name: number, action: number) => {
  return hasAccessObjectAny(userProfile, [name], action);
};

export const accessPeople = (userProfile: AuthorizationData, action: number) => {
  const archivePupilOP = access(userProfile, accessObject.PupilOP, action);
  const archiveDOGM = access(userProfile, accessObject.PupilDOGM, action);
  const archiveDayCare = access(userProfile, accessObject.PupilDayCare, action);
  const archiveSport = access(userProfile, accessObject.PupilSport, action);
  const archiveArtHouse = access(userProfile, accessObject.PupilArtHouse, action);
  const archiveNonDOGM = access(userProfile, accessObject.PupilNonDOGM, action);
  const archiveVA = access(userProfile, accessObject.PupilVA, action);

  return (
    archiveDOGM || archiveDayCare || archiveSport || archiveArtHouse || archiveNonDOGM || archiveVA || archivePupilOP
  );
};
