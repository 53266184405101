export enum FormTypeEnum {
  RequestSearchAD = 1,
  EditRequestApplicant = 2,
  EditRequestEnrollment = 3,
  EditRequestAD = 4,
  EditRequestDayCare = 5,
  EditRequestOther = 6,
  LearnFinish = 7,
  LearnerEditAD = 8,
  LearnerEdit = 9,
  EditRequestApplicantDsitDkgm = 10,
}
