import React, { useContext, useState } from 'react';
import PublicationStatus from '../components/publication-status';
import { ServiceContext } from '../index';
import Info from './panels/info';
import Photo from '../components/panels/photo';
import Module from './panels/module';
import ContactPerson from '../components/panels/contact-person';
import PlanTemplate from '../components/panels/plan';
import Financing from '../components/panels/financing';
import AddressClass from '../components/panels/address-class';
import AddressDocumentsTemplate from '../components/panels/address-documents';

const ServiceProgramDetails = () => {
  const { serviceData, accessPanelEdit } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState<string | null>(null);

  return (
    <>
      {accessPanelEdit && (
        <PublicationStatus
          serviceStatusId={serviceData.serviceStatusId}
          editMode={editMode}
          educationType={serviceData.educationTypeId}
        />
      )}
      {(!editMode || editMode === 'info') && <Info setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'contact') && <ContactPerson setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'address-class') && (
        <AddressClass
          setEditModeParent={setEditMode}
          editCount="many"
        />
      )}
      {!editMode && <Module />}
      {(!editMode || editMode === 'financing') && <Financing setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'address-documents') && (
        <AddressDocumentsTemplate
          setEditModeParent={setEditMode}
          textForEmpty="Для публикации образовательной программы необходимо указать хотя бы один адрес приема документов"
        />
      )}
      {!editMode && <PlanTemplate editable />}
      {(!editMode || editMode === 'photo') && <Photo setEditModeParent={setEditMode} />}
    </>
  );
};

export default ServiceProgramDetails;
