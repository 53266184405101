import React from 'react';
import { SearchRequestsInitialFormData } from '../../../../../../types/requests';
import { AuthorizationData } from '../../../../../../types/authorization-data';
import { FieldsModelItem } from '../../../../../../types/entities';
import { ChildServiceProps } from '..';
import FieldEducationType from '../../../fields/education-type';
import FieldVedomstvo from '../../../fields/vedomstvo';
import FieldClassificatorEku from '../../../fields/classificator-eku';
import FieldProgrammLevel from '../../../fields/programmLevel';
import FieldOrganization from '../../../fields/organization';
import FieldService from '../../../fields/service';
import FieldServiceClass from '../../../fields/service-class';
import FieldPlaceService from '../../../fields/place-service';
import FieldTrainingGroup from '../../../fields/training-group';
import FieldYearOfTraining from '../../../fields/year-of-training';
import FieldTestDate from '../../../fields/test-date';
import { NotificationField } from '../../../../../../components/fields/notification';

type Props = ChildServiceProps & {
  fieldshModelItem?: FieldsModelItem | null;
  isAdmin: boolean;
  values: SearchRequestsInitialFormData;
  userProfile: AuthorizationData;
};

const ChildServiceAdmin: React.FC<Props> = ({
  allowedEducationTypeId,
  isArtHouseSportEducation,
  canChangeOrganization,
  fieldshModelItem,
  isAdmin,
  values,
  onChangeEducationType,
}) => {
  return (
    <>
      {/* Тип услуги */}
      <FieldEducationType onChangeEducationType={onChangeEducationType} />

      {/* "Департамент" */}
      {fieldshModelItem?.vedomstvoId?.visible && (
        <FieldVedomstvo
          isAdmin={isAdmin}
          label={fieldshModelItem?.vedomstvoId?.label || ''}
          placeholder={fieldshModelItem?.vedomstvoId?.placeholder ?? 'Выберите департамент...'}
        />
      )}

      {/* "Вид деятельности", "Профессия",  "Вид деятельности"  */}
      {fieldshModelItem?.classificatorEKUId?.visible && (
        <FieldClassificatorEku
          label={fieldshModelItem?.classificatorEKUId?.label ?? ''}
          placeholder={fieldshModelItem?.classificatorEKUId?.placeholder ?? ''}
          isAdmin={isAdmin}
        />
      )}

      {/* "Квалификация",  "Уровень программы"  */}
      {fieldshModelItem?.programmLevelId?.visible &&
        (fieldshModelItem?.classificatorEKUId?.visible || isArtHouseSportEducation) && (
        <FieldProgrammLevel
          allowedEducationTypeId={allowedEducationTypeId}
          values={values}
          isArtHouseSportEducation={isArtHouseSportEducation}
          label={fieldshModelItem?.programmLevelId?.label || ''}
          placeholder={fieldshModelItem?.programmLevelId?.placeholder}
          explainText={!values.classificatorEKUId ? fieldshModelItem?.programmLevelId?.explainText : undefined}
        />
      )}

      {/* По уведомлению */}
      <NotificationField />

      {/* "Дата испытаний / явки" */}
      {fieldshModelItem?.testDate?.visible && (
        <FieldTestDate
          isArtHouseSportEducation={isArtHouseSportEducation}
          label={fieldshModelItem?.testDate?.label || ''}
          placeholder={fieldshModelItem?.testDate?.placeholder}
        />
      )}

      {/* "Образовательная организация" */}
      <FieldOrganization
        values={values}
        label={fieldshModelItem?.organizationId?.label || ''}
        placeholder={fieldshModelItem?.organizationId?.placeholder}
        explainText={!values.vedomstvoId && !isAdmin ? fieldshModelItem?.organizationId?.explainText : undefined}
        disabled={(!canChangeOrganization && values.vedomstvoId !== undefined) || (!isAdmin && !values.vedomstvoId)}
      />

      {/* "Наименование детского объединения", "Наименование образовательной программы",
            "Наименование программы по присмотру и уходу за детьми школьного возраста",
            "Наименование услуги"
                */}
      {fieldshModelItem?.serviceId?.visible && (
        <FieldService
          values={values}
          label={fieldshModelItem?.serviceId?.label || ''}
          placeholder={fieldshModelItem?.serviceId?.placeholder}
          explainText={!values.organizationId ? fieldshModelItem?.serviceId?.explainText : undefined}
          disabled={!values.organizationId}
        />
      )}

      {/* "Наименование группы" */}
      {fieldshModelItem?.serviceClassId?.visible && (
        <FieldServiceClass
          values={values}
          allowedEducationTypeId={allowedEducationTypeId}
          label={fieldshModelItem?.serviceClassId?.label}
          placeholder={fieldshModelItem?.serviceClassId?.placeholder ?? ''}
        />
      )}

      {/* 'Место предоставления', 'Адрес предоставления' */}
      {fieldshModelItem?.placeServiceId?.visible && (
        <FieldPlaceService
          values={values}
          isAdmin={isAdmin}
          label={fieldshModelItem?.placeServiceId?.label || ''}
          placeholder={fieldshModelItem?.placeServiceId?.placeholder}
          explainText={!values.organizationId ? fieldshModelItem?.placeServiceId?.explainText : undefined}
        />
      )}

      {/* "План приема" */}
      {fieldshModelItem?.trainingGroupId?.visible && (
        <FieldTrainingGroup
          values={values}
          label={fieldshModelItem?.trainingGroupId?.label || ''}
          placeholder={fieldshModelItem?.trainingGroupId?.placeholder}
          explainText={!values.serviceId ? fieldshModelItem?.trainingGroupId?.explainText : undefined}
        />
      )}

      {/* "Период обучения" */}
      {fieldshModelItem?.yearOfTrainingId?.visible && (
        <FieldYearOfTraining
          values={values}
          isArtHouseSportEducation={isArtHouseSportEducation}
          label={fieldshModelItem?.yearOfTrainingId?.label || 'Период обучения'}
          placeholder={fieldshModelItem?.yearOfTrainingId?.placeholder ?? ''}
        />
      )}
    </>
  );
};

export default ChildServiceAdmin;
