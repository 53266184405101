import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import { ServiceData, ServiceDataInfo } from '../../../../types/service';

type Props = FieldProps<number>;

export const HoursPerWeek = ({
  isNotTableMain,
  disabled,
  label = 'Часов в неделю',
  editMode,
  value,
  otherElement,
  parent,
  name,
  required,
}: Props) => {
  const { setFieldValue, values } = useFormikContext<ServiceDataInfo & ServiceData>();

  const inputName = parent ? `${parent}.${name}` : 'hoursPerWeek';

  useEffect(() => {
    (() => {
      const editValue = () => {
        setFieldValue(inputName, 14);
      };

      return parent ? values.info?.hoursPerWeek > 14 && editValue() : values.hoursPerWeek > 14 && editValue();
    })();
  }, [name, inputName, parent, setFieldValue, values]);

  return (
    <Field
      required={required}
      isNotTableMain={isNotTableMain}
      label={label}
      editMode={editMode}
      value={value}
      otherElement={otherElement}
    >
      <FormikInputNumber
        maxLength={2}
        max={14}
        min={0}
        placeholder="0"
        name={inputName}
        disabled={disabled}
      />
    </Field>
  );
};
