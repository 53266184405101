import { LmSelectNew } from '@mes-ui/lemma';
import React from 'react';
import { pageSizeOptions } from '../../mock-data';
import { SelectOptionType } from '../../types/entities';

type Props = {
  value: number;
  onChange: (value: number) => void;
};

const PageSizeSelect = ({ value, onChange }: Props) => {
  return (
    <div
      style={{
        width: 190,
      }}
    >
      <LmSelectNew
        dataTest="selectPageSize"
        name="pageSize"
        value={pageSizeOptions.find((item) => item.value === value) || null}
        onChange={(selectedOption: SelectOptionType | null) => {
          const { value } = selectedOption as SelectOptionType;

          onChange(Number(value));
        }}
        size="small"
        options={pageSizeOptions}
        grouped={false}
        multiple={false}
      />
    </div>
  );
};

export default PageSizeSelect;
