import { Response } from '../../types/entities';
import { fetchRequest } from './index';
import { apiService } from '../../config/constants';

export const getAddressId = async (addressId: number): Promise<Response<any>> =>
  await fetchRequest.get(`${apiService.data}/Address/${addressId}`);

export const addressApi = {
  getAddressId,
};
