// Тип документа.
export enum DocumentTypeEnum {
  /** Свидетельство о рождении (ID:1) */
  BirthCertificate = 1,

  /** Паспорт гражданина РФ (ID:2) */
  Passport = 2,

  /** Приказ (ID:3) */
  Order = 3,

  /** Договор (ID:4) */
  Contract = 4,

  /** Свидетельство иностранного образца (ID:5) */
  BirthCertificateForeign = 5,

  /** Паспорт иностранного образца (ID:6) */
  ForeignPassport = 6,

  /** Сертификат, выдается по окончании модуля */
  Certificate = 7,

  /** Свидетельство, выдается по окончании образовательной программы */
  Attestation = 8,

  OldPassport = 9,

  /** Вид на жительство иностранного гражданина. */
  ResidencePermit = 10,

  /** Запись о рождении в ЕГР ЗАГС */
  BirthRecord = 11,
}
