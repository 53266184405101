import React from 'react';
import { LmLoader } from '@mes-ui/lemma';

const FilterLoader = () => {
  return (
    <div className="filter-loader">
      <LmLoader view="block" />
    </div>
  );
};

export default FilterLoader;
