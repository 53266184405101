import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { routes } from '../../config/constants';
import { formatDate } from '../../lib/utils/date';
import { pageSizeOptions } from '../../mock-data';
import { MegaRelationHistoryTypeEnum } from '../../mock-data/mega-relation-history-type-enum';
import { ListPage, ServiceClassHistoryData } from '../../types/service-class';
import { generateLink, getServiceEditLink } from '../../lib/utils';
import { EducationTypeEnum } from '../../types/education-type';
import learnerApi from '../../lib/api/learner';
import serviceClassApi from '../../lib/api/service-class';
import AsyncTable from '../table/async-table';

type Props = {
  id: number;
  type: 'group' | 'learner';
  educationType: EducationTypeEnum;
};

const TransfersService: React.FC<Props> = ({ id, type, educationType }) => {
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0].value);
  const [pageNum, setPageNum] = useState(0);
  const [history, setHistory] = useState<ServiceClassHistoryData[]>([]);
  const [total, setTotal] = useState(0);

  const paginationRef = useRef<any>();

  useEffect(() => {
    const fetch = async () => {
      let data: ListPage<ServiceClassHistoryData>;

      switch (type) {
        case 'group':
          data = await serviceClassApi.getServiceClassHistory(
            id,
            MegaRelationHistoryTypeEnum.ToOtherService,
            pageNum + 1,
            pageSize
          );
          break;
        case 'learner':
          data = await learnerApi.getHistory(id, MegaRelationHistoryTypeEnum.ToOtherService, pageNum + 1, pageSize);
          break;
        default:
          data = {
            total: 0,
            items: [],
          };
          break;
      }

      setHistory(data.items);
      setTotal(data.total);

      if (data.total && pageNum) {
        paginationRef?.current?.scrollIntoView({
          block: 'end',
          behavior: 'auto',
        });
      }
    };

    if (id) {
      fetch();
    }
  }, [pageNum, pageSize, id, type]);

  const baseColumn = {
    width: '20%',
    dataIndex: 'baseDocument',
    title: 'Основание',
    render: (item: any) => item.baseDocument,
  };

  const dateChange = {
    width: '15%',
    dataIndex: 'eventDate',
    title: 'Дата изменения',
    render: (item: any) => formatDate(item.eventDate),
  };

  const operator = {
    width: '20%',
    dataIndex: 'userName',
    title: 'Оператор',
    render: (item: any) => item.userName,
  };

  const columns = [
    {
      width: '20%',
      dataIndex: 'pupilName',
      title: 'Ученик',
      render: (item: any) => (
        <Link
          className="organization-table-name"
          to={generateLink(routes.learner, {
            id: item.pupilId,
          })}
          type="button"
          target="_blank"
        >
          {item.pupilName}
        </Link>
      ),
    },
    {
      width: '25%',
      dataIndex: 'eventTypeName',
      title: 'Действие',
      render: (item: any) => {
        const linkOld = getServiceEditLink(educationType, item.oldObject.id, true);
        const linkNew = getServiceEditLink(educationType, item.newObject.id, true);

        return (
          <>
            Перевод с услуги{' '}
            <a
              rel="noreferrer"
              className="organization-table-name"
              target="_blank"
              href={linkOld}
            >
              {item.oldObject.value}
            </a>{' '}
            ({item.oldObject.name}) на услугу{' '}
            <a
              rel="noreferrer"
              className="organization-table-name"
              target="_blank"
              href={linkNew}
            >
              {item.newObject.value}
            </a>{' '}
            ({item.newObject.name})
          </>
        );
      },
    },
  ];

  return total ? (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            История переводов на другие услуги
            <span className="color-gray-dark">
              {' \u00A0'}
              {total}
            </span>
          </>
        )}
      >
        <AsyncTable
          dataTest="transfersService"
          paginationRef={paginationRef}
          pageNum={pageNum}
          setPageNumber={setPageNum}
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={history}
          itemsAll={total}
          columns={
            type === 'learner'
              ? [dateChange, baseColumn, ...columns, operator]
              : [dateChange, ...columns, baseColumn, operator]
          }
        />
      </Panel>
    </>
  ) : null;
};

export default TransfersService;
