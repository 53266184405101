import { apiService } from '../../config/constants';
import { RestrictionsBudgetPlacesData, RestrictionsBudgetPlacesRequestData } from '../../types/classificator';
import { fetchRequest } from './index';

export const getBudgetPlaces = async (data: {
  educationTypeId?: number;
  organizationId?: number;
  yearOfTrainingId?: number;
  classificatorEKUId?: number;
}): Promise<RestrictionsBudgetPlacesData[]> => {
  return await fetchRequest.get(`${apiService.data}/ClassificatorEKULimit`, data);
};

export const editBudgetPlaces = async (data: RestrictionsBudgetPlacesRequestData): Promise<void> =>
  await fetchRequest.put(`${apiService.data}/ClassificatorEKULimit`, data);

export const getPrevYearBudgetPlaces = async (data: {
  educationTypeId?: number;
  organizationId?: number;
  yearOfTrainingId?: number;
}): Promise<RestrictionsBudgetPlacesData[]> => {
  return await fetchRequest.get(`${apiService.data}/ClassificatorEKULimit/PrevYear`, data);
};

export const classificatorEKULimitApi = {
  getBudgetPlaces,
  editBudgetPlaces,
  getPrevYearBudgetPlaces,
};
