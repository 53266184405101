import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import { GetReport, OptionFilter, ReportFilterData, TypeSwitch } from '../../../../types/reports';
import { ReportFilterTypeEnum } from '../../../../mock-data/report-filter-type-enum';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';

type Props = {
  values: ReportFilterData;
  filterData: GetReport | null;
  typeFinance: TypeSwitch[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const FilterHorizontal: React.FC<Props> = ({ values, filterData, typeFinance, setFieldValue }) => {
  // Источник финансирования
  const sourceFinance = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
      if (item.key === ReportFilterTypeEnum.ByTypeFinansing && item.visible) {
        return (
          <div
            className="column__switch"
            key={item.key}
          >
            <div>{item.parameterCaption}</div>
            <Push size={8} />
            <LmSwitcher
              dataTest="typeFinance"
              size="small"
              value={values[item.parameterField] || 0}
              onChange={(value) => setFieldValue(item.parameterField, value)}
              variant="gray"
            >
              {typeFinance.map((element: TypeSwitch) => {
                return (
                  <LmSwitcherOption
                    dataTest={`switcher-${item.parameterField}-${element.id}`}
                    key={element.id}
                    value={element.id}
                  >
                    {element.name}
                  </LmSwitcherOption>
                );
              })}
            </LmSwitcher>
          </div>
        );
      }

      return null;
    })
    : null;

  // на дату
  const onDate = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
      if (item.key === ReportFilterTypeEnum.ByReportDate && item.visible) {
        return (
          <div
            className="column__switch"
            key={item.key}
          >
            <div>{item.parameterCaption}</div>
            <Push size={8} />
            <FormikDatePicker
              name={item.parameterField}
              size="small"
              fullWidth={false}
            />
          </div>
        );
      }

      return null;
    })
    : null;

  // за период
  const onPeriod = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
      if (item.key === ReportFilterTypeEnum.ByPeriodFrom && item.visible) {
        return (
          <div
            style={{
              maxWidth: '215px',
            }}
            className="column__switch"
            key={item.key}
          >
            <div>{item.parameterCaption}</div>
            <Push size={8} />
            <FormikDatePicker
              name={item.parameterField}
              size="small"
              showErrorImmediately
              fullWidth
            />
          </div>
        );
      }

      return null;
    })
    : null;

  // за период по
  const beforeDate = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
      if (item.key === ReportFilterTypeEnum.ByPeriodTo && item.visible) {
        return (
          <div
            style={{
              maxWidth: '215px',
            }}
            className="column__switch"
            key={item.key}
          >
            <div>{item.parameterCaption}</div>
            <Push size={8} />
            <FormikDatePicker
              name={item.parameterField}
              placeholder="ДД.ММ.ГГГГ"
              size="small"
              showErrorImmediately
              fullWidth
            />
          </div>
        );
      }

      return null;
    })
    : null;

  return (
    <div className="wrap__switch">
      {sourceFinance}
      {onDate}
      {onPeriod}
      {beforeDate}
    </div>
  );
};

export default FilterHorizontal;
