import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmListItem } from '@mes-ui/lemma';
import DropDown from '../../../../components/drop-down';
import TableOptions from '../../../../components/table-options';
import { DisciplineEditObject } from '../../../../types/teacher';

type Props = {
  id: number;
  editMode: boolean;
  filterTable: () => void;
  submitHandler: (id: number) => void;
  isAcceptedEdit: boolean;
  editObject: DisciplineEditObject;
  setEditMode: (v: boolean) => void;
  deleteDiscipline: (v: number) => void;
};

const EditDisciplines: React.FC<Props> = ({
  id,
  submitHandler,
  deleteDiscipline,
  editMode,
  editObject,
  setEditMode,
  filterTable,
  isAcceptedEdit,
}) => {
  const handleClose = () => {
    setEditMode(false);
    filterTable();
  };

  return isAcceptedEdit ? (
    <div className="teacher-table-comment">
      <div className="teacher-table-comment__controls">
        {editMode ? (
          <>
            <LmButton
              dataTest="closeDiscipline"
              type="button"
              variant="secondary"
              icon="filled-edit-close"
              iconSize={20}
              onClick={handleClose}
            />
            <Push
              size={12}
              orientation="horizontal"
            />
            <LmButton
              dataTest="submitDiscipline"
              type="button"
              color="success"
              variant="outline"
              icon="filled-edit-checkmark"
              iconSize={20}
              disabled={!editObject.newId || editObject.newId === id}
              onClick={() => {
                submitHandler(id);
              }}
            />
          </>
        ) : (
          <>
            <DropDown
              dataTest="disciplineOptions"
              component={() => <TableOptions />}
            >
              <>
                <LmListItem
                  dataTest="editDiscipline"
                  text="Редактировать"
                  icon="outline-edit-edit"
                  iconSize={20}
                  onClick={() => {
                    filterTable();
                    setEditMode(true);
                  }}
                />
                <LmListItem
                  dataTest="deleteDiscipline"
                  text="Удалить"
                  icon="outline-edit-trash-alt"
                  iconSize={20}
                  onClick={() => deleteDiscipline(id)}
                />
              </>
            </DropDown>
            <Push
              size={2}
              orientation="horizontal"
            />
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default EditDisciplines;
