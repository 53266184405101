import queryString from 'query-string';
import { apiService } from '../../config/constants';
import { SystemPropertyEnum } from '../../mock-data/system-property-enum';
import { OrganizationPhotoItem } from '../../types/organization';
import { List, PhotoOrderData, ServicePhotoItem } from '../../types/service';
import { fetchRequest } from './index';
import systemPropertyApi from './system-propetry';

const PHOTO_API = `${apiService.data}/Photo`;

const postS3Signer = async (fileUri: string, method: 'post' | 'delete'): Promise<string> => {
  return await fetchRequest.post(
    `${PHOTO_API}/s3signer?${queryString.stringify({
      fileUri,
      method,
    })}`
  );
};

const postPhotoS3 = async (fileName: string, file: Blob): Promise<void> => {
  const token = await photoApi.postS3Signer(fileName, 'post');
  const S3StorageAPIUrl = await systemPropertyApi.getSystemProperty(SystemPropertyEnum.S3StorageAPIUrl);

  const formData = new FormData();

  formData.append('content', file, fileName);
  const response = await window.fetch(`${S3StorageAPIUrl}/${fileName}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    return Promise.reject(response);
  }
};

const deletePhotoS3 = async (fileName: string): Promise<void> => {
  const token = await photoApi.postS3Signer(fileName, 'delete');
  const S3StorageAPIUrl = await systemPropertyApi.getSystemProperty(SystemPropertyEnum.S3StorageAPIUrl);

  const response = await window.fetch(`${S3StorageAPIUrl}/${fileName}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = await response.clone().json();

    return Promise.reject(error);
  }
};

export const putOrganizationPhoto = async (image: OrganizationPhotoItem): Promise<number> => {
  const formData = new FormData();

  formData.append('id', (image.id ?? 0).toString());
  formData.append('Image', image.body);
  formData.append('OrgId', image.orgId.toString());
  formData.append('Width', image.width.toString());
  formData.append('Height', image.height.toString());
  formData.append('Name', image.name);
  formData.append('SourceFileName', image.sourceFileName || '');

  return await fetchRequest.putFormData(`${PHOTO_API}/Organization/${image.orgId}/Image`, formData);
};

export const getOrganizationPhotos = async (orgId: number): Promise<OrganizationPhotoItem[]> =>
  await fetchRequest.get(`${PHOTO_API}/Organization/${orgId}/Image`);

export const deleteOrganizationPhoto = async (id: number, orgId: number) =>
  await fetchRequest.delete(`${PHOTO_API}/Organization/${orgId}/Image/${id}`);

export const putServicePhoto = async (serviceId: number, image: ServicePhotoItem): Promise<number> => {
  const formData = new FormData();

  formData.append('id', (image.id ?? 0).toString());
  formData.append('Image', image.image);
  formData.append('OrgId', serviceId.toString());
  formData.append('Width', image.width.toString());
  formData.append('Height', image.height.toString());
  formData.append('Name', image.name);
  formData.append('SourceFileName', image.sourceFileName);

  if (image.serviceId) {
    formData.append('ServiceId', image.serviceId.toString());
  }
  if (image.educationTypeId) {
    formData.append('EducationTypeId', image.educationTypeId.toString());
  }
  if (image.sortOrder) {
    formData.append('SortOrder', image.sortOrder.toString());
  }

  return await fetchRequest.putFormData(`${PHOTO_API}/Service/${serviceId}/Image`, formData);
};

export const deleteServicePhoto = async (id: number | string, serviceId: number | string) =>
  await fetchRequest.delete(`${PHOTO_API}/Service/${serviceId}/Image/${id}`);

export const updateServicePhotoListOrder = async (data: List<PhotoOrderData>) =>
  await fetchRequest.patch(`${PHOTO_API}/Service/${data.serviceId}/Image/Order`, data);

export const putTemplatePhoto = async (serviceId: number, image: ServicePhotoItem): Promise<number> => {
  const formData = new FormData();

  formData.append('id', (image.id ?? 0).toString());
  formData.append('Image', image.image);
  formData.append('OrgId', serviceId.toString());
  formData.append('Width', image.width.toString());
  formData.append('Height', image.height.toString());
  formData.append('Name', image.name);
  formData.append('SourceFileName', image.sourceFileName);

  if (image.templateId) {
    formData.append('TemplateId', image.templateId.toString());
  }
  if (image.educationTypeId) {
    formData.append('EducationTypeId', image.educationTypeId.toString());
  }
  if (image.sortOrder) {
    formData.append('SortOrder', image.sortOrder.toString());
  }

  return await fetchRequest.putFormData(`${PHOTO_API}/Template/${serviceId}/Image`, formData);
};

export const deleteTemplatePhoto = async (id: number | string, serviceId: number | string) =>
  await fetchRequest.delete(`${PHOTO_API}/Template/${serviceId}/Image/${id}`);

export const updateTemplatePhotoListOrder = async (data: List<PhotoOrderData>) =>
  await fetchRequest.patch(`${PHOTO_API}/Template/${data.templateId}/Image/Order`, data);

const photoApi = {
  postS3Signer,
  postPhotoS3,
  deletePhotoS3,
  putOrganizationPhoto,
  getOrganizationPhotos,
  deleteOrganizationPhoto,
  putServicePhoto,
  deleteServicePhoto,
  updateServicePhotoListOrder,
  putTemplatePhoto,
  deleteTemplatePhoto,
  updateTemplatePhotoListOrder,
};

export default photoApi;
