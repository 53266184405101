import React from 'react';
import Dialog from '../../../components/modals/dialog';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const ModalAboutSignature = ({ show, onCloseHandler }: Props) => {
  return (
    <Dialog
      dataTest="signatureInfo"
      isOpenDialog={show}
      title="Сведения об электронной подписи"
      description={
        <>
          Пользователь: NF, ЭП: 23.11.2022 15:45:12
          <div className="about-sign">
            <div className="flex flex-column">
              electronic signature_00209313
              <span>действует до 12.01.2025</span>
            </div>
          </div>
        </>
      }
      variant="information"
      buttonSecondaryText="Ок"
      onClickSeconadaryButton={onCloseHandler}
    />
  );
};

export default ModalAboutSignature;
