import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import moment from 'moment';
import { LmInfoBox } from '@mes-ui/lemma';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import Popup from '../../../../components/modals/popup';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import learnerApi from '../../../../lib/api/learner';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { ServiceClassTableContext } from '../../utils';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassPupil } from '../../../../types/service-class';
import { Learner } from '../../../../types/learners';

type Props = {
  open: boolean;
  close: () => void;
  readOnly?: boolean;
  groupMembers?: ServiceClassPupil | null;
};

const Certificate: React.FC<Props> = ({ open, close, readOnly, groupMembers }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData, learners } =
    useContext(ServiceClassTableContext);

  const [loader, setLoader] = useState<boolean>(true);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [hasFinishedService, setHasFinishedService] = useState<boolean>(false);
  const [documents, setDocuments] = useState<SelectOptionType[]>([]);

  const [currentDocData, setCurrentDocData] = useState<Learner.Document | null>(null);

  const selectedPeople = learners?.find((value) => selected.includes(value.id));

  const initialValues = useMemo(
    () => ({
      documentTypeId: 7,
      serviceName: serviceClassData.serviceName,
      unionCatalogServicesId: serviceClassData.serviceId,
      programmModuleId: serviceClassData.programmModuleId,
      programName: serviceClassData.programmModuleName,
      organizationId: serviceClassData.organizationId,
      organizationName: serviceClassData.organizationName,
      pupilId: selectedPeople?.pupilId,
      programmLevelName: readOnly ? groupMembers?.programmLevelName : selectedPeople?.programmLevelName,
      programmLevelId: readOnly ? groupMembers?.programmLevelId : selectedPeople?.programmLevelId,
      docNumber: readOnly ? currentDocData?.docNumber : undefined,
      issueDate: readOnly ? moment(currentDocData?.issueDate).toDate() : undefined,
    }),
    [serviceClassData, selectedPeople, readOnly, groupMembers, currentDocData]
  );

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const submitForm = useCallback(
    async (values: any) => {
      await learnerApi.postDocument({
        ...values,
        programmLevel: values.programmLevelId,
      });
      close();
      fetchTable();
      setSelected([]);
      setOpenDetails(false);
    },
    [close, fetchTable, setSelected, setOpenDetails]
  );

  useEffect(() => {
    const fetch = async () => {
      const documentTypes = await dictionariesApi.getDocumentTypes(7);

      setDocuments(documentTypes);
      if (selectedPeople?.pupilId) {
        const finishedServices = await learnerApi.getDocumentFinishedServices({
          pupilId: selectedPeople?.pupilId,
        });

        setHasFinishedService(finishedServices.some((item) => item.serviceId === serviceClassData.serviceId));
      }
      setLoader(false);
    };

    fetch();
  }, [selectedPeople?.pupilId, serviceClassData.serviceId]);

  useEffect(() => {
    if (readOnly) {
      const fetch = async () => {
        if (groupMembers) {
          try {
            setIsLoadingData(true);
            const data = {
              pupilId: String(groupMembers?.pupilId),
              docId: String(groupMembers?.completionDocumentList[0].id),
            };
            const document = await learnerApi.getDocument(data);

            setCurrentDocData(document);
          } finally {
            setIsLoadingData(false);
          }
        }
      };

      fetch();
    }
  }, [groupMembers, readOnly]);

  const showReadOnlyModal = readOnly ? true : hasFinishedService;

  const professionOption = readOnly
    ? {
        label: groupMembers?.classificatorEKUName || '',
        value: groupMembers?.serviceId || null,
      }
    : {
        label: selectedPeople?.classificatorEKUName || '',
        value: serviceClassData.serviceId || null,
      };

  const programmLevelOption = readOnly
    ? {
        label: groupMembers?.programmLevelName || '',
        value: groupMembers?.programmLevelId || null,
      }
    : {
        label: selectedPeople?.programmLevelName || '',
        value: selectedPeople?.programmLevelId || null,
      };

  return (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<any>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="certificatePopup"
              open={open}
              title="Выдача сертификата"
              loading={loader || isLoadingData}
              buttonPrimaryText="Выдать сертификат"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid || readOnly,
              }}
              hidePrimaryButton={!showReadOnlyModal}
              hideSecondaryButton={!showReadOnlyModal}
              onClearData={resetForm}
              onSubmit={handleSubmit}
              onClose={close}
            >
              {showReadOnlyModal ? (
                <>
                  <LmInfoBox
                    dataTest="certificateInfo"
                    className="infobox--full-width"
                    variant="information"
                    description={`Документ о прохождении обучения для "${
                      readOnly ? groupMembers?.pupilName : selectedPeople?.pupilName
                    }"`}
                    hidenFooter
                  />

                  <Push size={16} />
                  <FormikSelect
                    disabled
                    name="documentTypeId"
                    label="Тип документа"
                    required
                    size="small"
                    options={documents}
                    placeholder="Выберите..."
                  />
                  <Push size={16} />
                  <FormikInput
                    label="Номер документа"
                    required
                    size="small"
                    name="docNumber"
                    placeholder="Введите..."
                    disabled={readOnly}
                  />
                  <Push size={16} />
                  <FormikDatePicker
                    label="Дата"
                    required
                    size="small"
                    name="issueDate"
                    disabled={readOnly}
                  />
                  <Push size={16} />
                  <FormikSelect
                    disabled
                    name="unionCatalogServicesId"
                    label="Образовательная программа"
                    required
                    size="small"
                    defaultValue={
                      serviceClassData.serviceId && serviceClassData.serviceName
                        ? {
                            label: serviceClassData.serviceName,
                            value: serviceClassData.serviceId,
                          }
                        : null
                    }
                    options={
                      serviceClassData.serviceId && serviceClassData.serviceName
                        ? [
                            {
                              label: serviceClassData.serviceName,
                              value: serviceClassData.serviceId,
                            },
                          ]
                        : []
                    }
                    placeholder="Выберите..."
                  />
                  <Push size={16} />
                  <FormikSelect
                    disabled
                    name="programmModuleId"
                    label="Модуль"
                    required
                    size="small"
                    defaultValue={
                      serviceClassData.programmModuleId && serviceClassData.programmModuleName
                        ? {
                            label: serviceClassData.programmModuleName,
                            value: serviceClassData.programmModuleId,
                          }
                        : null
                    }
                    options={
                      serviceClassData.programmModuleId && serviceClassData.programmModuleName
                        ? [
                            {
                              label: serviceClassData.programmModuleName,
                              value: serviceClassData.programmModuleId,
                            },
                          ]
                        : []
                    }
                    placeholder="Выберите..."
                  />
                  <Push size={16} />
                  <FormikSelect
                    disabled
                    name="organizationId"
                    label="Организация"
                    size="small"
                    defaultValue={
                      serviceClassData.organizationId && serviceClassData.organizationName
                        ? {
                            label: serviceClassData.organizationName,
                            value: serviceClassData.organizationId,
                          }
                        : null
                    }
                    options={
                      serviceClassData.organizationId && serviceClassData.organizationName
                        ? [
                            {
                              label: serviceClassData.organizationName,
                              value: serviceClassData.organizationId,
                            },
                          ]
                        : []
                    }
                    placeholder="Выберите..."
                  />
                  <Push size={16} />
                  <FormikSelect
                    label="Профессия"
                    disabled
                    name="classificatorEKUId"
                    size="small"
                    // Возможно тут что-то другое должно быть
                    defaultValue={professionOption}
                    options={[]}
                    placeholder="Выберите..."
                  />
                  <Push size={16} />
                  <FormikSelect
                    disabled
                    name="programmLevelId"
                    label="Квалификация"
                    size="small"
                    // Возможно тут что-то другое должно быть
                    defaultValue={programmLevelOption}
                    options={[]}
                    placeholder="Выберите..."
                  />
                </>
              ) : (
                <LmInfoBox
                  dataTest="certificateError"
                  className="infobox--full-width"
                  variant="alert"
                  description="Не найдено завершенных образовательных программ"
                  hidenFooter
                />
              )}
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default Certificate;

const getValidationSchema = () =>
  objectYup().shape({
    documentTypeId: stringYup().required('Выберите тип документа'),
    docNumber: stringYup().required('Введите номер документа'),
    issueDate: docDate.nullable().required('Введите дату документа'),
    unionCatalogServicesId: stringYup().required('Выберите образовательную программу'),
  });
