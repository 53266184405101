import React from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import { regCityOptions } from '../../../../../../lib/utils/learners';
import FormikInput from '../../../../../../components/formik/formik-input';
import FormikSelect from '../../../../../../components/formik/formik-select';
import { checkAddress, downloadMoscowAddresses } from '../utils';
import { Learner } from '../../../../../../types/learners';
import { SelectOptionType } from '../../../../../../types/entities';

type Props = {
  editMode: boolean;
  currentAddressRegistration: SelectOptionType | undefined;
  setCurrentAddressRegistration: (option?: SelectOptionType) => void;
};

export const AddressRegistration = ({ editMode, setCurrentAddressRegistration, currentAddressRegistration }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  return (
    <div className="table-data__item table-data__group table-data__group--center">
      <div className="table-data__label table-data__label--main">Адрес регистрации</div>
      <div className="table-data__body">
        {editMode ? (
          <>
            <LmSwitcher
              dataTest="groupRegCity"
              size="small"
              onChange={(value) => {
                setFieldValue('regCity', value);
                setCurrentAddressRegistration({
                  label: '',
                  value: 0,
                });

                if (value === 'other') {
                  setFieldValue('regAddress', null);
                } else {
                  setFieldValue('regAddressId', null);
                }
              }}
              variant="gray"
              value={values.regCity}
            >
              {regCityOptions.map((item) => (
                <LmSwitcherOption
                  dataTest={`switcher-regCity-${item.value}`}
                  key={item.value}
                  value={item.value}
                >
                  {item.label}
                </LmSwitcherOption>
              ))}
            </LmSwitcher>
            <Push size={10} />

            {values.regCity === 'other' ? (
              <FormikInput
                size="small"
                placeholder="Укажите адрес регистрации"
                name="regAddress"
              />
            ) : (
              <FormikSelect
                name="regAddressId"
                size="small"
                selectedValue={setCurrentAddressRegistration}
                loadOptions={async (query) => downloadMoscowAddresses(query)}
                withSearch
                defaultValue={currentAddressRegistration?.value ? currentAddressRegistration : null}
                options={[]}
                placeholder="Укажите адрес регистрации"
              />
            )}
          </>
        ) : (
          checkAddress(values.regAddress ?? '', '')
        )}
      </div>
    </div>
  );
};
