import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { object as objectYup, string as stringYup } from 'yup';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import { notify } from '../../../../redux/ducks/notifications';
import { onSignIn } from '../../../../redux/thunks/auth';
import {
  initialChangePasswordData,
  ChangePasswordData,
  PasswordSettings,
  defaultPasswordSettings,
  LoginData,
} from '../../../../types/auth';
import FormikInput from '../../../../components/formik/formik-input';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { authApi } from '../../../../lib/api';
import history from '../../../../history';
import { routes } from '../../../../config/constants';
import PasswordStrength from '../../../password-rules/strength';
import PasswordRequirement from '../../../password-rules/requirement';
import { getRequirements, validatePassword } from '../../../password-rules/utils';
import { PasswordRequirementType } from '../../../password-rules/types/password';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';

type Props = {
  requirements?: PasswordRequirementType[];
};

const ChangePasswordForm = ({ requirements }: Props) => {
  const [settings, setSettings] = useState<PasswordSettings>(defaultPasswordSettings);
  const location = useLocation();

  const dispatch = useDispatch();

  const { search, state } = useLocation<LoginData>();

  if (!state) {
    history.push({
      pathname: routes.login,
      search: location.search,
    });
  }
  const initialData = useMemo(
    () => ({
      ...initialChangePasswordData,
      login: state?.login,
      oldPassword: state?.password,
      remember: state.remember,
    }),
    [state]
  );
  const initialErrors = useInitialErrors(initialData, getValidationSchema(settings));

  if (state === undefined) {
    history.push({
      pathname: routes.login,
      search,
    });
  }

  const passwordRules = useMemo(() => {
    return requirements ?? getRequirements(settings);
  }, [requirements, settings]);

  const signInHandler = useCallback(
    async (values: ChangePasswordData) => {
      try {
        const response = await authApi.changePassword(values);

        if (response.errors?.length) {
          dispatch(
            notify.danger({
              dataTest: 'sign',
              title: response.errors?.join(', '),
            })
          );
        } else {
          const responseSing = await authApi.signIn({
            login: values.login,
            password: values.newPassword,
            appName: 'ui',
            needCheckPasswordExpired: false,
            remember: true,
            impersonateKey: '',
          });

          if (responseSing.isLoggedIn) {
            await dispatch(onSignIn(responseSing.token));
          } else {
            dispatch(
              notify.danger({
                dataTest: 'responseSign',
                title: responseSing.errors?.join(', '),
              })
            );
          }
        }
      } catch (err) {}
    },
    [dispatch]
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await authApi.getSettings();

      setSettings(data);
    };

    fetchData();
  }, []);

  return (
    <Formik
      onSubmit={signInHandler}
      enableReinitialize
      initialValues={initialData}
      validationSchema={getValidationSchema(settings)}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ChangePasswordData>) => {
        const { handleSubmit, values, isSubmitting, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <FormikInput
              label="Новый пароль"
              type="password"
              placeholder="Введите пароль..."
              value={values.newPassword}
              name="newPassword"
            />

            <Push size={8} />
            <PasswordStrength
              password={values.newPassword}
              passwordRules={passwordRules}
            />

            <Push size={12} />
            <PasswordRequirement
              password={values.newPassword}
              passwordRules={passwordRules}
            />

            <Push size={16} />
            <FormikInput
              label="Повторите пароль"
              type="password"
              placeholder="Введите пароль еще раз..."
              value={values.confirmNewPassword}
              name="confirmNewPassword"
            />
            <Push size={12} />
            <FormikCheckbox
              name="remember"
              label="Запомнить меня"
              boxSize="small"
            />
            <Push size={16} />
            <LmButton
              dataTest="saveAndLogin"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              size="medium"
              widthFull
            >
              Сохранить и войти в систему
            </LmButton>
          </form>
        );
      }}
    </Formik>
  );
};

const getValidationSchema = (settings: PasswordSettings) =>
  objectYup().shape({
    newPassword: stringYup()
      .nullable()
      .test('password-requirement', '', (value) => validatePassword(value, settings)),
    confirmNewPassword: stringYup()
      .nullable()
      .test(
        'passwords-match',
        'Введенные пароли не совпадают. Проверьте ввод пароля и повторите попытку',
        function (value) {
          return this.parent.newPassword === value;
        }
      ),
  });

export default ChangePasswordForm;
