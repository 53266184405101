export const searchTeachersInitialFormData = {
  query: '',
  showArchive: false,
  isContactPerson: '',
  presentInNSI: '',
  disciplineId: null,
  organizationId: null,
};

export const defaultOptionValue = {
  value: 0,
  label: 'Все',
};

export const contactOptions = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

export const teacherInfoOptions = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Присутствуют',
    value: true,
  },
  {
    label: 'Отсутствуют',
    value: false,
  },
];
