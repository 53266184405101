import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import {
  SearchGroupTrainingInitialFormData,
  SearchGroupTrainingInitialFormDataAddition,
} from '../../../../types/service-class';
import lookupApi from '../../../../lib/api/lookup';
import { AuthorizationData, generalAccess } from '../../../../types/authorization-data';
import { hasGeneralAccess } from '../../../../lib/utils';
import { usePrevious } from '../../../../hooks/use-previous';

type Props = {
  userProfile: AuthorizationData;
  isAdmin: boolean;
  educationTypeData: SelectOptionType[];
};

export const FieldPlaceService: React.FC<Props> = ({ isAdmin, userProfile, educationTypeData }) => {
  const { setFieldValue, values } = useFormikContext<
    SearchGroupTrainingInitialFormData & SearchGroupTrainingInitialFormDataAddition
  >();

  const accessMain = hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) || isAdmin;

  const prevOrganization = usePrevious(values.organizationId);
  const prevPlaceServiceId = usePrevious(values.placeServiceId);

  const canEdit = () => {
    if (isAdmin && !values.organizationId) {
      return false;
    }

    return !(accessMain && !values.organizationId);
  };

  const getPlaceServiceOptions = async (
    query: string,
    values: SearchGroupTrainingInitialFormData
  ): Promise<SelectOptionType[]> => {
    return accessMain
      ? await lookupApi.getPlaceService(
        query,
        values.vedomstvoId,
        values.organizationId,
          values.educationTypeId as number
      )
      : await lookupApi.getPlaceService(
        query,
        userProfile.vedomstvoId,
        userProfile.organizationId,
          educationTypeData[0]?.value as number
      );
  };

  useEffect(() => {
    // если мы меняем организацию, то обнуляем значение
    if (values.organizationId !== prevOrganization && prevOrganization) {
      setFieldValue('placeServiceName', null);
      setFieldValue('placeServiceId', null);
    }

    // если в организации нет значения, то обнуляем значение
    if ('organizationId' in values && !values.organizationId) {
      setFieldValue('placeServiceName', null);
      setFieldValue('placeServiceId', null);
    }
  }, [prevOrganization, prevPlaceServiceId, setFieldValue, values, values.organizationId]);

  const defaultValue = () => {
    if (
      (isAdmin && values.placeServiceName && values.placeServiceId) ||
      (values.placeServiceName && values.placeServiceId)
    ) {
      return {
        label: values.placeServiceName,
        value: values.placeServiceId,
      };
    } else {
      return null;
    }
  };

  return (
    <FormikSelect
      label="Место предоставления"
      size="small"
      withSearch
      options={[]}
      name="placeServiceId"
      showTooltip
      disabledPortalTooltip
      disabled={!canEdit()}
      placeholder="Начните вводить..."
      explainText={!canEdit() ? 'Сначала выберите образовательную организацию' : undefined}
      loadOptions={async (query) => {
        return getPlaceServiceOptions(query, values);
      }}
      defaultValue={defaultValue()}
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('placeServiceName', v.label);
        }
      }}
    />
  );
};
