import React from 'react';
import { useField } from 'formik';
import { Link } from 'react-router-dom';
import { LmRadio } from '@mes-ui/lemma';
import Field, { FieldProps } from '../../../../components/fields/field';
import { routes } from '../../../../config/constants';
import { ServiceClass, StatusCount } from '../../../../types/requests';
import { formatDate } from '../../../../lib/utils/date';
import { serviceIdField } from './service';
import { styleVars } from '../../../../theme/vars';
import { EducationTypeEnum } from '../../../../types/education-type';
import { schoolOrganizationIdField } from './organization';
import { generateUrlSearchString } from '../../../../lib/utils';
import { useGetTrainingGroupSCBooking } from '../../../../hooks/get-training-group-sc-booking';
import { getNameGroup } from '../../../../lib/utils/requests';
import SimpleTable from '../../../../components/table/simple-table';

type AdmissionPlanProps = FieldProps<number> & { parent?: string };

export const scheduleOfTimetableIdField = 'scheduleOfTimetableId';
export const trainingGroupIdFieldId = 'trainingGroupId';

const AdmissionPlan = ({
  label = 'План приема',
  name,
  editMode,
  required,
  defaultValue,
  parent,
}: AdmissionPlanProps) => {
  const [serviceField] = useField<number>(parent ? `${parent}.${serviceIdField}` : serviceIdField);
  const [field, , helpers] = useField(parent ? `${parent}.${name}` : name);
  const [, , trainingGroupIdFieldHelpers] = useField(trainingGroupIdFieldId);

  const [schoolOrganizationIdOption] = useField<number>(
    parent ? `school.${schoolOrganizationIdField}` : schoolOrganizationIdField
  );

  const trainingGroupList = useGetTrainingGroupSCBooking({
    serviceId: serviceField.value,
  });

  return trainingGroupList && schoolOrganizationIdOption.value ? (
    <Field
      label={label}
      editMode={editMode}
      disabled={!editMode}
      required={required}
      defaultValue={defaultValue}
    >
      <SimpleTable
        data={trainingGroupList}
        headerBorderTop={false}
        columns={[
          {
            dataIndex: 'id',
            title: '',

            render: (item: any) => (
              <LmRadio
                dataTest="scheduleOfTimetableId"
                checked={field.value === item.scheduleOfTimetableId}
                onChange={() => {
                  helpers.setValue(item.scheduleOfTimetableId);
                  trainingGroupIdFieldHelpers.setValue(item.id);
                }}
                disabled={!editMode || item.serviceClassList.length === 0}
                resetAllPadding
              />
            ),
            width: '40px',
          },
          {
            dataIndex: 'educationStartDate',
            title: 'Начало занятий',
            render: (item: any) => formatDate(item.educationDateStart),
            width: '150px',
          },
          {
            dataIndex: 'serviceClass',
            title: 'Группа',
            render: (item: any) => {
              return item.serviceClassList.length > 0 ? (
                <div className="flex flex-column wrap">
                  {item.serviceClassList?.map((serviceClass: ServiceClass) => {
                    return (
                      <Link
                        key={serviceClass.id}
                        className="brand-link"
                        to={`${routes.serviceClasses}/${serviceClass.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getNameGroup(serviceClass.code, serviceClass.name)}
                      </Link>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    color: styleVars.brandGrayDark,
                  }}
                >
                  Группа обучения не найдена
                </div>
              );
            },
          },
          {
            dataIndex: 'numbers',
            title: 'Всего / активные / зачислено / отчислено / отказано / отозвано',
            width: '270px',
            render: (item: any) =>
              item.statusCount?.map((statusCount: StatusCount, index: number) => {
                return statusCount.volume === 0 ? (
                  <>
                    {0}
                    {index < item.statusCount.length - 1 && <> / </>}
                  </>
                ) : (
                  <>
                    {' '}
                    <Link
                      to={{
                        pathname: routes.requests,
                        search: generateUrlSearchString({
                          educationTypeId: EducationTypeEnum.DayCareCentersEducation,
                          serviceId: serviceField.value,

                          requestStatusId: statusCount.requestStatusId === 0 ? '' : statusCount.requestStatusId,
                          serviceClassId: item.serviceClassList[0]?.id,

                          trainingGroupId: item.id,
                        }),
                      }}
                      target="_blank"
                      className="brand-link"
                    >
                      {statusCount.volume}
                    </Link>
                    {index < item.statusCount.length - 1 && <> / </>}
                  </>
                );
              }),
          },
        ]}
      />
    </Field>
  ) : null;
};

export default AdmissionPlan;
