import { createApi } from '@reduxjs/toolkit/query/react';
import { apiService } from '../../config/constants';
import axiosBaseQuery from '../../lib/api/axios-base-query';
import api from '../../lib/api/api';
import { AppealRequestData } from '../../types/tss';

// Define a service using a base URL and expected endpoints
export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: axiosBaseQuery({
    baseURL: `${apiService.data}/Tss/`,
  }),
  tagTypes: ['Appeal', 'Contract', 'ArchiveLink'],
  endpoints: (builder) => ({
    getTssArchiveLink: builder.query<string, number>({
      query: (userId: number) => {
        return api.GET({
          url: 'Archive',
          params: {
            userId,
          },
        });
      },
      providesTags: ['ArchiveLink'],
    }),
    getAppealLink: builder.query<string, AppealRequestData | void>({
      query: (params) => {
        return api.GET({
          url: 'Appeal',
          params,
        });
      },
      providesTags: ['Appeal'],
    }),
    getContractUrl: builder.query<string, void>({
      query: () => {
        return api.GET({
          url: 'Contract',
        });
      },
      providesTags: ['Contract'],
    }),
  }),
});

export const { useGetTssArchiveLinkQuery, useGetAppealLinkQuery, useGetContractUrlQuery } = homeApi;
