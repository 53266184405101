import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LmSubHeader } from '@mes-ui/lemma';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { siteMapList } from '../../mock-data/routes';
import { useGetMenuSettingsQuery } from '../../store/menu-settings';
import TreeLinkWrapper from '../../components/tree-link-wrapper';
import { routes } from '../../config/constants';
import { sendReachGoal } from '../../lib/metrica';

const SiteMap = () => {
  const {
    data: { data: menuSetting } = {
      data: [],
    },
  } = useGetMenuSettingsQuery();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    sendReachGoal('sitemap');
  }, []);

  return (
    <>
      <LmSubHeader
        sticky
        hideReturnButton
        description=""
        title="Карта сайта"
        dataTest="siteMapSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Карта сайта',
          },
        ]}
      />
      <TreeLinkWrapper
        userProfile={userProfile}
        itemList={siteMapList}
        menuSetting={menuSetting}
        isOpened
      />
    </>
  );
};

export default SiteMap;
