import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object as objectYup, string as stringYup } from 'yup';
import { useParams } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmInfoBox, LmSubHeader } from '@mes-ui/lemma';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import { handleBookingChange } from '../../../../redux/ducks/booking';
import { bookingSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { RequestData, TrainingGroupRequestData } from '../../../../types/requests';
import { EducationTypeEnum } from '../../../../types/education-type';
import requestsApi from '../../../../lib/api/requests';
import { formatDate } from '../../../../lib/utils/date';
import { routes } from '../../../../config/constants';
import FIO from '../../components/fields/fio';
import BirthDate from '../../components/fields/birthdate';
import Gender from '../../../../components/fields/gender';
import Document from '../../components/fields/document';
import DocumentNumber from '../../components/fields/document-number';
import Issued from '../../components/fields/issued';
import Snils from '../../components/fields/snils';
import MoscowRegistration from '../../components/fields/moscow-registration';
import Phone from '../../../../components/fields/phone';
import Email from '../../../../components/fields/email';
import {
  birthRecordIssued,
  dateOfBirth,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  lastName,
  middleName,
  nullableEmail,
  phone,
  scheduleOfTimetable,
  serviceId,
  sexId,
  snils,
  stringRequired,
  serviceClass,
} from '../../../../lib/utils/validation';
import ServiceDonm from '../../components/fields/service-donm';
import { getServiceEditLink } from '../../../../lib/utils';
import WrapperFields from '../../components/fields/wrapper-fields';
import { RequestContext } from '../../../../lib/utils/requests';
import history from '../../../../history';
import AdmissionPlanService from '../../components/fields/service-and-group-info/admission-plan-service';
import ServiceClasses from '../../components/fields/service-and-group-info/service-classes';
import { redirectToRequestAndCleanBooking } from '../../utils';
import WrapperFieldsDocument from '../../components/fields/wrapper-fields-document';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import SavePanel from '../../../../components/save-panel';

type Props = {
  request?: RequestData;
  serviceInfoReadOnly?: boolean;
};

const RequestProfCreate: React.FC<Props> = ({ request, serviceInfoReadOnly }) => {
  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const { admin } = useContext(RequestContext);

  const dispatch = useDispatch();

  const { booking } = useSelector((state: AppState) => ({
    booking: bookingSelector(state),
  }));

  const initialValue = useMemo(() => {
    if (request) {
      const item = {
        ...request,
        trainingGroup: request.trainingGroup || ({} as TrainingGroupRequestData),
      };

      if (!item.applicant?.documentTypeId) {
        item.applicant = {
          ...item.applicant,
          documentTypeId: 2,
          documentTypeName: 'Паспорт гражданина РФ',
        };
      }
      if (item.child && item.child.sexId === undefined) {
        item.child.sexId = 1;
      }

      return item;
    }

    return {
      child: {
        sexId: 1,
        sexName: 'Мужской',
        documentTypeId: 1,
        documentTypeName: 'Свидетельство о рождении',
      },
      applicant: {
        documentTypeId: 2,
        documentTypeName: 'Паспорт гражданина РФ',
      },
    } as RequestData;
  }, [request]);

  useEffect(() => {
    return () => {
      dispatch(handleBookingChange());
    };
  }, [dispatch]);

  useEffect(() => {
    request?.trainingGroup &&
      dispatch(
        handleBookingChange(
          {
            bookingId: request.trainingGroup.bookingGuid,
            slotId: request.trainingGroup.slotId,
          },
          request.trainingGroup.serviceClass?.id
        )
      );
  }, [dispatch, request?.trainingGroup]);

  const submitForm = useCallback(
    async (values: RequestData) => {
      const fetch = async () => {
        setLoadBtn(true);
        try {
          if (booking) {
            values.trainingGroup.bookingGuid = booking.bookingId;
            values.trainingGroup.slotId = booking.slotId;
            values.educationTypeId = EducationTypeEnum.ProfessionalEducation;
            values.child.isChild = true;
            values.child.educationTypeId = EducationTypeEnum.ProfessionalEducation;
            values.applicant.isChild = false;
            values.applicant.educationTypeId = EducationTypeEnum.ProfessionalEducation;
            values.applicant.phone = addPhonePrefix(values.applicant.phone);

            const requestId = await requestsApi.createRequest(values);

            redirectToRequestAndCleanBooking(requestId);
          }
        } catch {
        } finally {
          setLoadBtn(false);
        }
      };

      fetch();
    },
    [booking]
  );

  const back = () => history.push(routes.requests);

  const dataParams = useParams<Record<string, string | undefined>>();
  const hasEdTypeOrPupilId = 'id' in dataParams || 'pupilId' in dataParams;

  const goBackToService = () => {
    window.open(
      getServiceEditLink(
        request?.educationTypeId ?? EducationTypeEnum.ProfessionalEducation,
        request?.trainingGroup.serviceId
      ),
      '_blank'
    );
  };

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={back}
        description={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(new Date())}
              <Push
                size={20}
                orientation="horizontal"
              />
              Источник: Оператор
            </div>
          </>
        }
        title="Новое заявление"
        dataTest="requestPreparationCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Заявления',
            link: routes.requests,
          },
          {
            label: 'Новое заявление',
          },
        ]}
        buttonsOrAnyActions={
          serviceInfoReadOnly
            ? [
                {
                  dataTest: 'goToService',
                  buttonText: ' Вернуться к ОП',
                  rounded: 'small',
                  variant: 'secondary',
                  onClick: goBackToService,
                },
              ]
            : undefined
        }
      />

      <Formik
        validateOnMount
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValue}
        validationSchema={getValidationSchema}
      >
        {(formikProps: FormikProps<RequestData>) => {
          const { values, handleSubmit, submitForm, isSubmitting, isValid } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />
              <LmInfoBox
                dataTest="infoFoundWarning"
                className="infobox--full-width"
                variant="warning"
                description="Проверка в реестре контингента не проводилась. Сведения будут проверены, результаты проверки отображены после первого сохранения."
                hidenFooter
              />
              <Panel title={() => 'Информация о ребенке'}>
                <div className="container">
                  <div className="table-data">
                    <WrapperFields />
                    <Gender
                      editMode
                      required
                      name="child.sex"
                    />
                    <WrapperFieldsDocument showErrorImmediately={hasEdTypeOrPupilId || !!initialValue.child?.number} />
                    <Snils
                      name="child.snils"
                      disabled={!admin}
                      editMode
                    />
                    <MoscowRegistration
                      name="child.moscowRegistration"
                      editMode
                    />
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              <Panel title={() => 'Информация о заявителе'}>
                <div className="container">
                  <div className="table-data">
                    <FIO
                      label="ФИО заявителя"
                      required
                      editMode
                      parent="applicant"
                    />
                    <BirthDate
                      name="applicant.dateOfBirth"
                      editMode
                      required
                    />
                    <Phone
                      name="applicant.phone"
                      editMode
                      required
                      showErrorImmediately={!!initialValue.applicant?.phone}
                    />
                    <Email
                      name="applicant.email"
                      editMode
                    />
                    <Document
                      name="documentType"
                      parent="applicant"
                      editMode
                      dependentFields
                      formType={FormTypeEnum.EditRequestApplicant}
                    />
                    <Issued
                      name="applicant.issued"
                      editMode
                    />
                    <DocumentNumber
                      parent="applicant"
                      editMode
                      calendarPosition="top-end"
                    />
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              <Panel title={() => 'Информация об образовательной программе и группе обучения'}>
                <div className="container">
                  <div className="table-data">
                    <ServiceDonm
                      menuPlacement="top"
                      parent="trainingGroup"
                      name="service"
                      label="Образовательная программа"
                      editMode
                      required
                      educationType={EducationTypeEnum.ProfessionalEducation}
                      disabled={serviceInfoReadOnly}
                      isNeedBooking
                    />

                    {values.trainingGroup?.serviceId && (
                      <AdmissionPlanService
                        name="trainingGroup.scheduleOfTimetableId"
                        disabled={serviceInfoReadOnly}
                        editMode
                        required
                        isNeedBooking
                      />
                    )}

                    {values.trainingGroup?.scheduleOfTimetableId && (
                      <ServiceClasses
                        contingentGuid={values.contingentGuid}
                        serviceId={values.trainingGroup?.serviceId}
                        isNeedBooking
                        trainingGroupId={values.trainingGroup.id || values.trainingGroup.trainingGroupId}
                        disabledRadio={serviceInfoReadOnly}
                      />
                    )}
                  </div>
                </div>
              </Panel>

              <SavePanel
                buttonPrimaryText="Создать заявление"
                primaryButtonModifiers={{
                  loading: isSubmitting || loadBtn,
                  disabled: !isValid,
                }}
                onClickPrimaryButton={submitForm}
                onClickSeconadaryButton={back}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const getValidationSchema = () =>
  objectYup().shape({
    child: childrenValidationSchema,
    applicant: applicantValidationSchema,
    trainingGroup: trainingGroupSchema,
  });

const trainingGroupSchema = objectYup().shape({
  serviceId,
  scheduleOfTimetableId: scheduleOfTimetable,
  serviceClass,
});

export const childrenValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  sexId,
  documentTypeId: stringRequired,
  series: documentSeries(true),
  number: documentNumber(true),
  dateOfIssue: dateOfIssue(true),
  snils,
  issued: birthRecordIssued,
});

export const applicantValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  email: nullableEmail,
  documentTypeId: stringYup().nullable(),
  series: documentSeries().nullable(),
  number: documentNumber().nullable(),
  dateOfIssue: dateOfIssue(),
  phone,
  dateOfBirth,
});

export default RequestProfCreate;
