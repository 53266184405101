import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LmButton, LmIcon, LmSubHeader, LmListItem } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import { routes } from '../../../config/constants';
import { useGetFromRoute } from '../../../hooks/get-from-route';
import LearnerDetails from './details';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import Favorite from '../../../components/favorite';
import DropDown from '../../../components/drop-down';
import { getRequestEducationLinks } from '../../../lib/utils/education';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { goBack, hasAccessObjectParent, hasGeneralAccess } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import learnerApi from '../../../lib/api/learner';
import { showSuccessNotification } from '../../../lib/utils/learners';
import { accessPeople } from './access';
import LearnerHistory from './change-history';

const LearnerInfo = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const fromRoute = useGetFromRoute();

  const [learnerName, setLearnerName] = useState('');

  const [requestOptions, setRequestOptions] = useState<{ link: string; label: string; value: number }[]>([]);

  const [extendEntityGuid, setExtendEntityGuid] = useState('');
  const [deleteLearner, setDeleteLearners] = useState(false);

  const [peopleId, setPeopleId] = useState(0);

  const [reloadRequest, setReloadRequest] = useState(false);

  const [peopleIsArchive, setPeopleIsArchive] = useState(false);

  useEffect(() => {
    setRequestOptions(getRequestEducationLinks(userProfile.objectAccessActionList, [], peopleId));
    const fetchData = async () => {
      const educationTypes = await dictionariesApi.getEducationTypes();

      setRequestOptions(getRequestEducationLinks(userProfile.objectAccessActionList, educationTypes, peopleId));
    };

    fetchData();
  }, [peopleId, userProfile.objectAccessActionList]);

  const learnerRestore = async (id: number) => {
    try {
      await learnerApi.patchLearner(id);

      showSuccessNotification();

      setReloadRequest(true);
      setPeopleIsArchive(false);
    } catch (e) {}
  };

  const redirect = (value: string) => {
    return window.open(value, '_blank');
  };

  const handleArchive = () => {
    setDeleteLearners(true);
  };

  const handlePrint = () => {
    window.print();
  };

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.Create)) {
      buttons.push({
        buttonText: '',
        children: (
          <DropDown
            dataTest="newRequest"
            component={() => (
              <LmButton
                dataTest="newRequest"
                type="button"
                variant="secondary"
                icon="filled-edit-plus"
                iconSize={20}
              >
                <span className="icon-button-right">
                  Новое заявление
                  <LmIcon
                    icon="filled-chevrons-small-down"
                    size={20}
                  />
                </span>
              </LmButton>
            )}
          >
            <>
              {requestOptions.map((item) => {
                return (
                  <LmListItem
                    key={item.label}
                    dataTest={item.label}
                    text={item.label}
                    onClick={() => redirect(item.link)}
                  />
                );
              })}
            </>
          </DropDown>
        ),
      });
    }
    if (!peopleIsArchive && (accessPeople(userProfile, accessAction.Archive) || isAdminEdit)) {
      buttons.push({
        dataTest: 'toArchive',
        icon: 'filled-hardware-hard-drive',
        buttonText: 'В архив',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleArchive,
      });
    }
    if (peopleIsArchive && (accessPeople(userProfile, accessAction.Archive) || isAdminEdit)) {
      buttons.push({
        dataTest: 'restore',
        icon: 'filled-hardware-hard-drive',
        buttonText: 'Восстановить',
        rounded: 'small',
        variant: 'secondary',
        onClick: () => learnerRestore(peopleId),
      });
    }
    buttons.push({
      dataTest: 'print',
      icon: 'filled-hardware-printer',
      buttonText: 'Печать',
      rounded: 'small',
      variant: 'secondary',
      onClick: handlePrint,
    });

    return buttons;
  };

  return (
    <LmSubHeader
      sticky
      description=""
      title={learnerName}
      dataTest="learnerSubHeader"
      titleChildren={
        <Favorite
          entityId={peopleId}
          typeEntity="обучающегося"
          currentEntityType={learnerName}
          entityTypeId={EntityTypeEnum.Pupil}
        />
      }
      arrowOnClick={() => goBack(fromRoute || routes.learners)}
      routes={[
        {
          label: 'Главная',
          link: routes.main,
        },
        {
          label: 'Обучающиеся',
          link: routes.learners,
        },
        {
          label: 'Сведения об обучающемся',
        },
      ]}
      className="content-panel"
      tabs={[
        {
          title: 'Основные сведения',
          value: 1,
          dataTest: 'basicInfo',
        },
        {
          title: 'История изменений',
          value: 2,
          dataTest: 'changeHistory',
        },
      ]}
      panels={[
        {
          children: (
            <LearnerDetails
              isAdminEdit={isAdminEdit}
              setPeopleId={setPeopleId}
              deleteLearner={deleteLearner}
              reloadRequest={reloadRequest}
              setLearnerName={setLearnerName}
              setReloadRequest={setReloadRequest}
              setDeleteLearners={setDeleteLearners}
              setPeopleIsArchive={setPeopleIsArchive}
              setExtendEntityGuid={setExtendEntityGuid}
            />
          ),
          value: 1,
        },
        {
          children: (
            <LearnerHistory
              peopleId={peopleId}
              extendEntityGuid={extendEntityGuid}
            />
          ),
          value: 2,
          className: 'content-panel content-panel--auto',
          noPadding: true,
        },
      ]}
      buttonsOrAnyActions={getButtons()}
    />
  );
};

export default LearnerInfo;
