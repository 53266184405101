import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LmServiceHeader, LmText, LmButton, LmNavigationItem, LmTooltip } from '@mes-ui/lemma';
import { authApi } from '../../lib/api';
import { redirectToAupdUrl } from '../../lib/utils';
import { currentProfileSelector, isAuthorizedSelector, profilesSelector } from '../../redux/selectors';
import { logout, onSignIn, onSignInWithCookie } from '../../redux/thunks/auth';
import { reducerRecord, setUserProfile } from '../../redux/ducks/user-profile';
import { clearProfiles, setAuthorized, setCurrentProfiles } from '../../redux/ducks/app';
import tokenManager from '../../lib/token-manager';
import { CurrentProfileType, ProfilesType } from '../../redux/types/entities';
import { AppState } from '../../redux/types/state';
import { AuthorizationData } from '../../types/authorization-data';

type HeaderProps = {
  userProfile: AuthorizationData;
  navigationList: LmNavigationItem[];
  onChangeLoading: (value: boolean) => void;
  onChangeErrorAuth: (value: boolean) => void;
  aupdTokenCookie: string | undefined;
};

const Header = ({ userProfile, navigationList, aupdTokenCookie, onChangeLoading, onChangeErrorAuth }: HeaderProps) => {
  const dispatch = useDispatch();

  const { currentProfile, aupdProfiles, isAuthorized } = useSelector((state: AppState) => ({
    currentProfile: currentProfileSelector(state),
    aupdProfiles: profilesSelector(state),
    isAuthorized: isAuthorizedSelector(state),
  }));

  const isAupd = userProfile.tokenAupd || aupdTokenCookie;

  const localProfile: CurrentProfileType = useMemo(
    () =>
      userProfile.id
        ? {
            id: userProfile.organizationId,
            icon: 'filled-misc-organization',
            title: userProfile.organizationName,
            subtitle: userProfile.roles?.map(({ name }) => name).join(', '),
          }
        : undefined,
    [userProfile.id, userProfile.organizationId, userProfile.organizationName, userProfile.roles]
  );

  const profiles: ProfilesType = useMemo(
    () => (isAupd ? aupdProfiles : localProfile ? [localProfile] : []),
    [aupdProfiles, isAupd, localProfile]
  );

  const logoutHandler = async () => {
    await dispatch(logout());
    tokenManager.clearToken();
    dispatch(clearProfiles());
    dispatch(setUserProfile(reducerRecord));
    dispatch(setAuthorized(false));
    await redirectToAupdUrl();
  };

  const handleChangeProfile = useCallback(
    async (selectedValue: CurrentProfileType, needUpdate = true) => {
      if (selectedValue) {
        onChangeLoading(true);
        try {
          const { token, isLoggedIn } = await authApi.loginAupd({
            token: aupdTokenCookie || '',
            login: selectedValue.id as string,
          });

          if (isLoggedIn) {
            await dispatch(needUpdate ? onSignIn(token) : onSignInWithCookie(token));
          }
          onChangeErrorAuth(false);
        } catch (err: any) {
          console.error(err.response);
          onChangeErrorAuth(true);
        } finally {
          onChangeLoading(false);
          needUpdate && dispatch(setCurrentProfiles(selectedValue));
        }
      }
    },
    [aupdTokenCookie, dispatch, onChangeErrorAuth, onChangeLoading]
  );

  // Вход в первую организацию АУПД при первом рендере
  useEffect(() => {
    isAupd && !isAuthorized && currentProfile && handleChangeProfile(currentProfile, false);
  }, [currentProfile, handleChangeProfile, isAupd, isAuthorized]);

  return (
    <LmServiceHeader
      dataTest="eszHeader"
      serviceName={<LmText variant="h4">Кружки</LmText>}
      serviceLogo="service--circles--logo"
      selectedProfile={isAupd ? currentProfile : localProfile}
      onChangeProfile={handleChangeProfile}
      profiles={profiles}
      navigationList={navigationList}
      wrapperClassName="header__additional"
      className="header"
    >
      {!isAupd && (
        <LmTooltip
          dataTest="logout"
          placement="bottom"
          withArrow
          content="Выход"
        >
          <LmButton
            dataTest="logout"
            variant="secondary"
            icon="filled-account-log-out"
            onClick={logoutHandler}
          />
        </LmTooltip>
      )}
    </LmServiceHeader>
  );
};

export default Header;
