import React, { useMemo, useState } from 'react';
import { useField } from 'formik';
import { LmSelectNew, LmSelectNewProps } from '@mes-ui/lemma';
import { MenuPlacementType, SelectOptionType } from '../../types/entities';

export type FormicSelectProps = Omit<
  LmSelectNewProps<SelectOptionType>,
  'multiple' | 'value' | 'onChange' | 'grouped' | 'settingDropdownAsPopover' | 'settingTooltip'
> & {
  name: string;
  value?: SelectOptionType;
  selectedValue?: (value?: SelectOptionType) => void;
  selectedValueMulti?: (value: SelectOptionType[]) => void;
  defaultValue?: SelectOptionType | null;
  options: SelectOptionType[];
  loadOptions?: (value: string) => Promise<SelectOptionType[]>;
  menuPlacement?: MenuPlacementType;
  maxMenuHeight?: number;
  isMulti?: boolean;
  isCreatable?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  showErrorImmediately?: boolean;
  showTooltip?: boolean;
  disabledPortalTooltip?: boolean;
  enablePortal?: boolean;
};

const FormikSelect: React.FC<FormicSelectProps> = ({
  name,
  defaultValue,
  selectedValue,
  selectedValueMulti,
  options,
  isMulti,
  showErrorImmediately,
  loadOptions,
  isCreatable,
  dataTest,
  menuPlacement,
  maxMenuHeight,
  onKeyDown,
  enablePortal,
  showTooltip,
  disabledPortalTooltip,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const [localOptions, setLocalOptions] = useState<SelectOptionType[]>([]);
  const value = useMemo(() => {
    const option =
      options?.find((option) => option.value === field.value) ||
      localOptions?.find((option) => option.value === field.value);

    return defaultValue ? option || defaultValue : option || null;
    // eslint-disable-next-line
  }, [options, field.value, defaultValue]);

  const setValue = (selectedOption: SelectOptionType | null) => {
    helpers.setValue(selectedOption?.value, true);
    if (selectedValue) {
      selectedValue(selectedOption || undefined);
    }
  };

  const setValueMulti = (selectedOption: SelectOptionType[]) => {
    helpers.setValue(selectedOption, true);
    if (selectedValueMulti) {
      selectedValueMulti(selectedOption);
    }
  };

  const metaErrorText = showErrorImmediately ? meta.error : meta.touched ? meta.error : undefined;
  const isError = props.isError || !!metaErrorText;
  const explainText = metaErrorText || props.explainText;
  const settingDropdownAsPopover = {
    placement: menuPlacement,
    disablePortal: !enablePortal,
    maxHeight: maxMenuHeight,
  };

  return loadOptions ? (
    <LmSelectNew
      {...props}
      {...field}
      settingTooltip={{
        showTooltip,
        disabledPortalTooltip,
      }}
      dataTest={dataTest || name}
      value={value}
      onChange={setValue}
      options={options || []}
      withSearch
      clearable
      grouped={false}
      multiple={false}
      explainText={explainText}
      isError={isError}
      getFilterOptions={async (query) => {
        const options = await loadOptions(query);

        setLocalOptions(options);

        return options;
      }}
      settingDropdownAsPopover={settingDropdownAsPopover}
      searchOnKeyDown={onKeyDown}
      createOption={
        isCreatable
          ? (value) => {
              setValue({
                value,
                label: value,
              });
            }
          : undefined
      }
      selectOnBlur={() => setTimeout(() => helpers.setTouched(true), 0)}
    />
  ) : isMulti ? (
    <LmSelectNew
      {...props}
      {...field}
      dataTest={dataTest || name}
      value={field.value || []}
      onChange={setValueMulti}
      options={options}
      multiple
      clearable
      grouped={false}
      explainText={explainText}
      isError={isError}
      settingDropdownAsPopover={settingDropdownAsPopover}
      searchOnKeyDown={onKeyDown}
      selectOnBlur={() => setTimeout(() => helpers.setTouched(true), 0)}
      settingTooltip={{
        showTooltip,
        disabledPortalTooltip,
      }}
    />
  ) : (
    <LmSelectNew
      {...props}
      {...field}
      dataTest={dataTest || name}
      value={value}
      onChange={setValue}
      options={options}
      grouped={false}
      multiple={false}
      explainText={explainText}
      isError={isError}
      settingDropdownAsPopover={settingDropdownAsPopover}
      searchOnKeyDown={onKeyDown}
      selectOnBlur={() => setTimeout(() => helpers.setTouched(true), 0)}
      settingTooltip={{
        showTooltip,
        disabledPortalTooltip,
      }}
    />
  );
};

export default FormikSelect;
