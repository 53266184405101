import React, { useEffect, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import organizationApi from '../../../lib/api/organization';
import serviceClassApi from '../../../lib/api/service-class';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { formatDate } from '../../../lib/utils/date';
import { EducationTypeEnum } from '../../../types/education-type';
import { ServiceClassData, ServiceClassPupilsListParams } from '../../../types/service-class';
import LightTable, { LightTableType } from '../light-table';

type Props = {
  data: ServiceClassData | undefined;
  type: string | undefined;
};

const nameChildrenEducation: { [index: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]: 'ГПД',
  [EducationTypeEnum.VirtualAssistantEducation]: 'ОУ',
  [EducationTypeEnum.ArtHouseEducation]: 'Специализация',
  [EducationTypeEnum.ChildrenEducation]: 'ДО',
  [EducationTypeEnum.ChildrenNonDogmEducation]: 'ДО',
  [EducationTypeEnum.SportEducation]: 'ДО',
  [EducationTypeEnum.ProfessionalEducation]: 'ОП',
};

const relatedAssociationsTitle: { [index: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]: 'Связанные ГПД',
  [EducationTypeEnum.VirtualAssistantEducation]: 'Связанные ОУ',
  [EducationTypeEnum.ArtHouseEducation]: '',
  [EducationTypeEnum.ChildrenEducation]: 'Связанные ДО',
  [EducationTypeEnum.ChildrenNonDogmEducation]: 'Связанные ДО',
  [EducationTypeEnum.SportEducation]: '',
  [EducationTypeEnum.ProfessionalEducation]: 'Связанные ОП',
};

const trainingDatesTitleByEducationType: { [index: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]: 'Даты обучения',
  [EducationTypeEnum.VirtualAssistantEducation]: 'Даты обучения',
  [EducationTypeEnum.ChildrenNonDogmEducation]: 'Даты обучения',
  [EducationTypeEnum.ArtHouseEducation]: 'Дата начала занятий',
  [EducationTypeEnum.ChildrenEducation]: 'Дата начала занятий',
  [EducationTypeEnum.SportEducation]: 'Дата начала занятий',
  [EducationTypeEnum.ProfessionalEducation]: 'Дата начала занятий',
};

const PrintList: React.FC<Props> = ({ data }) => {
  const [learners, setLearners] = useState<LightTableType>();
  const [organizationName, setOrganizationName] = useState<string>();
  const [addressName, setAddressName] = useState<string>();

  const isDO =
    data?.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
    data?.educationTypeId === EducationTypeEnum.ChildrenEducation;

  const dataTableStudentsColumn: { [index: string]: string }[] = [
    {
      name: '№',
    },
    {
      name: 'ФИО',
    },
    {
      name: 'Подгруппы',
    },
    {
      name: nameChildrenEducation[data?.educationTypeId as number],
    },
    {
      name: trainingDatesTitleByEducationType[data?.educationTypeId as number],
    },
  ];

  if (data?.educationTypeId === EducationTypeEnum.ArtHouseEducation) {
    dataTableStudentsColumn.splice(4, 0, {
      name: 'Этап обучения',
    });
  }

  const dataTableChildrenAssociations = {
    column: [
      {
        name: '№',
      },
      {
        name: 'Наименование',
      },
    ],

    row: [
      {
        description: [
          {
            name: '1',
          },
          {
            name: `${data?.fullServiceName ? data?.fullServiceName : ''} ${data?.address ? `, ${data?.address}` : ''}`,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    const fetchAddress = async (serviceId: number) => {
      const addresses = await serviceTemplateApi.getPlaceServiceAddresses({
        serviceId,
      });

      if (addresses[0]) {
        setAddressName(addresses[0].label);
      }
    };

    if (data && data.serviceId && isDO) {
      fetchAddress(data.serviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.serviceId]);

  useEffect(() => {
    const getOrganization = async () => {
      if (data?.id) {
        const response = await organizationApi.getOrganization(Number(data?.organizationId));

        setOrganizationName(response.fullName);
      }
    };

    getOrganization();
  }, [data]);

  useEffect(() => {
    const fetch = async () => {
      const res = await serviceClassApi.getServiceClassLearnerList({
        serviceClassId: data?.id as number,
        educationTypeId: data?.educationTypeId,
        showExcluded: false,
      } as ServiceClassPupilsListParams);

      const rowData = res.items.map((item: any, index: number) => {
        const subclassListData = item.subclassList.map((item: any) => item.name);

        const checkByType = [
          EducationTypeEnum.DayCareCentersEducation,
          EducationTypeEnum.VirtualAssistantEducation,
          EducationTypeEnum.ChildrenNonDogmEducation,
        ].includes(Number(data?.educationTypeId));

        const rangeDate =
          item.dateLearnStart && item.dateLearnEnd
            ? `${formatDate(item.dateLearnStart)} - ${formatDate(item.dateLearnEnd)}`
            : '';

        const startTrainingDate = item.trainingGroupStartDate ? formatDate(item.trainingGroupStartDate) : '';

        const trainingDatesData = checkByType ? rangeDate : startTrainingDate;

        const row = {
          description: [
            {
              name: index + 1,
            },
            {
              name: item.pupilName,
            },
            {
              name: item.subclassList.length > 0 && subclassListData.join(', '),
            },
            {
              name: `${item.classificatorEKUName}, ${item.servicePeriod}`,
            },
            {
              name: trainingDatesData,
            },
          ],
        };

        if (data?.educationTypeId === EducationTypeEnum.ArtHouseEducation) {
          row.description?.splice(4, 0, {
            name: item.serviceStageName,
          });
        }

        return row;
      });

      setLearners({
        row: rowData,
        column: dataTableStudentsColumn,
      });
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.educationTypeId, data?.id]);

  const teacher = data?.teacher?.list.find((item) => item.isSupervisor)?.name;

  return (
    <div className="word-break-all">
      <p>
        <strong>Организация: </strong> {organizationName || ''}
      </p>
      <p>
        <strong>Наименование группы обучения: </strong> {data?.name || ''}
      </p>
      {isDO && (
        <>
          <p>
            <strong>Адрес: </strong> {data?.address || addressName || ''}
          </p>
          <p>
            <strong>План приема: </strong> {data?.trainingGroupName || ''}
          </p>
        </>
      )}
      <p>
        <strong>Код группы обучения: </strong> {data?.code || ''}
      </p>
      {isDO && (
        <>
          <p>
            <strong>Дата начала занятий: </strong> {data?.trainStartDate ? formatDate(data?.trainStartDate) : ''}
          </p>
          <p>
            <strong>Дата окончания занятий: </strong> {data?.trainEndDate ? formatDate(data?.trainEndDate) : ''}
          </p>
        </>
      )}
      {data?.educationTypeId === EducationTypeEnum.ArtHouseEducation && (
        <p>
          <strong>Этапы обучения: </strong> {data?.serviceStageList || ''}
        </p>
      )}
      <p>
        <strong>Руководитель: </strong> {teacher || ''}
      </p>
      <p>
        <strong>Предельное число обучающихся в группе, чел: </strong> {data?.capacity || ''}
      </p>
      <p>
        <strong>Зачислено: </strong> {data?.included || ''}
      </p>
      <p>
        <strong>Комментарий: </strong> {data?.description || ''}
      </p>

      {relatedAssociationsTitle[data?.educationTypeId as number] && (
        <>
          <h2 className="text-center">{relatedAssociationsTitle[data?.educationTypeId as number]}</h2>
          <LightTable data={dataTableChildrenAssociations} />
        </>
      )}

      <h2 className="text-center">Обучающиеся</h2>
      <LightTable data={learners} />
      <Push size={28} />
    </div>
  );
};

export default PrintList;
