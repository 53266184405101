import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { Panel } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import FormikInput from '../../../../components/formik/formik-input';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { GroupMembersField } from '../../components/dkgm-dsit-template/panels/fields/group-members';
import { ProgrammService } from './programm-service';
import { RuleService } from './rule-service';
import { ServiceData, ServiceFileTextType } from '../../../../types/service';
import { ServiceDuration } from '../../components/dkgm-dsit-template/panels/fields/service-duration';
import { routes } from '../../../../config/constants';
import { generateLink } from '../../../../lib/utils';
import { HoursPerWeek } from '../../components/fields/hours-per-week';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  programmFile: ServiceFileTextType;
  setProgrammFile: React.Dispatch<React.SetStateAction<ServiceFileTextType>>;
  ruleFile: ServiceFileTextType;
  setRuleFile: React.Dispatch<React.SetStateAction<ServiceFileTextType>>;
  parent?: string;
  checkEditable?: boolean;
  isVisibleHoursPerWeek: boolean;
};

const Info = ({
  setEditModeParent,
  programmFile,
  setProgrammFile,
  ruleFile,
  setRuleFile,
  parent,
  editMode,
  setEditMode,
  checkEditable,
  isVisibleHoursPerWeek,
}: Props) => {
  const { values } = useFormikContext<ServiceData>();

  const getFieldFullName = useCallback((name: string) => (parent ? `${parent}.${name}` : name), [parent]);

  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;

      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  return (
    <Panel
      title={() => <>Сведения об образовательной услуге</>}
      headingControl={() => {
        return checkEditable && !editMode && setEditModeParent ? (
          <button
            type="button"
            onClick={() => {
              setEditModeParent && setEditModeParent('info');
              setEditMode && setEditMode(true);
            }}
            className="icon-group"
          >
            <span className="icon-group__icon">
              <LmIcon
                icon="filled-edit-edit"
                size={20}
                color="var(--LM-blue-200)"
              />
            </span>
            <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
          </button>
        ) : null;
      }}
    >
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Образовательная организация</div>
          <div className="table-data__body">{getFieldValue('organizationName') || '—'}</div>
        </div>

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Наименование услуги {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className={`table-data-grid${getFieldValue('serviceId') > 0 ? '-3-1' : ''} items-center`}>
              <div>
                {editMode ? (
                  <FormikInput
                    name={getFieldFullName('name')}
                    size="small"
                    placeholder="Введите..."
                    disabled={!getFieldValue('canChangeName')}
                  />
                ) : (
                  getFieldValue('name') || '—'
                )}
              </div>

              {getFieldValue('serviceId') > 0 && (
                <div>
                  <div className="table-data__group">
                    <div className="table-data__label">Код</div>
                    <div className="table-data__body">{getFieldValue('code') || '—'}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ClassificatorNameField
          parent={parent}
          editMode={editMode}
          name="classificator"
          label="Реестр базовых направлений "
          modalTitle="Реестр базовых направлений"
          disabled={!getFieldValue('canChangeClassificatorEKU')}
        />

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Наименование шаблона</div>
          <div className="table-data__body">
            {getFieldValue('templateId') ? (
              <Link
                className="brand-link cursor-pointer"
                to={generateLink(routes.myTemplate, {
                  id: getFieldValue('templateId'),
                })}
                target="_blank"
                rel="noreferrer"
              >
                {getFieldValue('templateName')}
              </Link>
            ) : (
              '—'
            )}
          </div>
        </div>

        <ServiceDuration
          editMode={editMode}
          parent={parent}
          disabled={!getFieldValue('canChangeDuration')}
        />
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Занятия {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            {editMode ? (
              <div className={`table-data-grid${isVisibleHoursPerWeek ? '-2-1' : '-1-1'}`}>
                <LessonLevelField
                  placeholder="Выберите..."
                  isNotTableMain
                  editMode={editMode}
                  label="Уровень"
                  name="programmLevel"
                  parent={parent}
                  disabled={!getFieldValue('canChangeProgrammLevel')}
                  disabledPlaceholder="Сначала выберите базовое направление"
                />

                {isVisibleHoursPerWeek && (
                  <HoursPerWeek
                    isNotTableMain
                    parent={parent}
                    name="hoursPerWeek"
                    editMode={editMode}
                    disabled={!getFieldValue('canChangeHoursPerWeek')}
                  />
                )}
              </div>
            ) : (
              <div className="table-data-grid-2">
                <div className="table-data__group">
                  <div className="table-data__label">Уровень</div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 218,
                    }}
                  >
                    {getFieldValue('programmLevelName')}
                  </div>
                </div>
                {isVisibleHoursPerWeek && (
                  <div className="table-data__group">
                    <div className="table-data__label">Часов в неделю</div>
                    <div className="table-data__body">{getFieldValue('hoursPerWeek')}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <GroupMembersField
          editMode={editMode}
          parent={parent}
          disabled={!getFieldValue('canChangeSexOrAge')}
        />
        <ProgrammService
          editMode={editMode}
          programmFile={programmFile}
          setProgrammFile={setProgrammFile}
          parent={parent}
          disabled={!getFieldValue('canChangeProgrammService')}
        />
        <RuleService
          editMode={editMode}
          ruleFile={ruleFile}
          setRuleFile={setRuleFile}
          parent={parent}
          disabled={!getFieldValue('canChangeRuleService')}
        />
      </div>
    </Panel>
  );
};

export default Info;
