import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmInfoBox } from '@mes-ui/lemma';
import Informer from '../../../../components/informer';
import { typePersonEnum } from '../../../../mock-data/type-person-enum';
import { RequestData } from '../../../../types/requests';

type Props = {
  setOpen: (value: typePersonEnum) => void;
  disabled: boolean;
  requestData?: RequestData;
};

export const InfoLike = ({ setOpen, disabled, requestData }: Props) => {
  return requestData ? (
    <div className="container">
      {requestData.contingentLinkTypeId >= 3 && requestData.contingentGuid && (
        <>
          <LmInfoBox
            dataTest="infoLikeWarning"
            className="infobox--full-width"
            variant="warning"
            description="Обучающийся идентифицирован в Реестре контингента, сведения об общеобразовательной организации отсутствуют. Функционал зачисления доступен"
            hidenFooter
            hasBorder
          />
          <Push
            size={16}
            orientation="vertical"
          />
        </>
      )}

      {!requestData.contingentGuid && (
        <>
          <Informer
            dataTest="infoChildLikeError"
            color="danger"
            description="Функционал зачисления недоступен, так как сведения об обучающемся отсутствуют в Реестре контингента"
            controls={
              <LmButton
                dataTest="infoChildLikeError"
                color="danger"
                variant="primary"
                disabled={disabled}
                onClick={() => setOpen(typePersonEnum.child)}
              >
                Проверить сведения
              </LmButton>
            }
          />
          <Push size={16} />
        </>
      )}
      {!requestData.applicantContingentGuid && (
        <>
          <Informer
            dataTest="infoApplicantLikeError"
            color="danger"
            description="Функционал зачисления недоступен, так как сведения о заявителе отсутствуют в Реестре контингента"
            controls={
              <LmButton
                dataTest="infoApplicantLikeError"
                color="danger"
                variant="primary"
                disabled={disabled}
                onClick={() => setOpen(typePersonEnum.applicant)}
              >
                Проверить сведения
              </LmButton>
            }
          />
          <Push size={16} />
        </>
      )}
    </div>
  ) : null;
};
