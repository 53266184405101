import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import {
  SearchGroupTrainingInitialFormData,
  SearchGroupTrainingInitialFormDataAddition,
} from '../../../../types/service-class';
import { roleLabel } from '../../../../lib/utils/service-class';
import lookupApi from '../../../../lib/api/lookup';
import learnerApi from '../../../../lib/api/learner';
import { AuthorizationData } from '../../../../types/authorization-data';
import { usePrevious } from '../../../../hooks/use-previous';
import { serviceTemplateApi } from '../../../../lib/api/service-template';

type Props = {
  userProfile: AuthorizationData;
  setProgramModules: (value: SelectOptionType[]) => void;
};

export const FieldPlace: React.FC<Props> = ({ userProfile, setProgramModules }) => {
  const { setFieldValue, values } = useFormikContext<
    SearchGroupTrainingInitialFormData & SearchGroupTrainingInitialFormDataAddition
  >();

  const defaultValue =
    values.serviceId && values.serviceName
      ? {
          label: values.serviceName ?? '',
          value: values.serviceId,
        }
      : null;

  const selectValue = (v?: SelectOptionType) => {
    if (v) {
      setFieldValue('serviceName', v.label);
      (async () => {
        const response = await learnerApi.getDocumentProgramModules({
          serviceId: v.value,
        });

        setProgramModules(response);
      })();
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const service = values.serviceId && (await serviceTemplateApi.getServiceName(values.serviceId));

      setFieldValue('serviceName', service);
    };

    if (values.serviceId && !values.serviceName) {
      fetch();
    }
  }, [values.serviceId, values.serviceName, setFieldValue]);

  const prevEducationTypeId = usePrevious(values.educationTypeId);
  const prevServiceId = usePrevious(values.serviceId);

  useEffect(() => {
    // если мы меняем Тип образования, то обнуляем значение Наименование услуги
    if (values.educationTypeId !== prevEducationTypeId && prevServiceId) {
      setFieldValue('serviceName', null);
      setFieldValue('serviceId', null);
    }
  }, [prevEducationTypeId, prevServiceId, setFieldValue, values.educationTypeId]);

  return (
    <FormikSelect
      label={roleLabel(userProfile)}
      name="serviceId"
      size="small"
      withSearch
      options={[]}
      showTooltip
      disabledPortalTooltip
      placeholder="Начните вводить..."
      loadOptions={async (query) => {
        return await lookupApi.getService(
          query,
          values.educationTypeId,
          values.vedomstvoId,
          values.organizationId,
          null,
          false
        );
      }}
      selectedValue={selectValue}
      defaultValue={defaultValue}
    />
  );
};
