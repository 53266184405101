import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { useGetProgrammLevels } from '../../../../../hooks/get-programm-level';
import { usePrevious } from '../../../../../hooks/use-previous';
import { SelectOptionType } from '../../../../../types/entities';

type Props = {
  title: string;
};
const FieldProgrammLevel: React.FC<Props> = ({ title }) => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const programmLevelData: SelectOptionType[] = useGetProgrammLevels(values?.classificatorEKUId as number);
  const prevProgrammLevelId = usePrevious(values?.programmLevelId);

  const prevDirectionId = usePrevious(values.classificatorEKUId);

  // При изменении "Базового направления" обнуляем значение "Уровня программы"
  useEffect(() => {
    if (values.classificatorEKUId !== prevDirectionId && prevProgrammLevelId !== undefined) {
      setFieldValue('programmLevelId', undefined);
      setFieldValue('programmLevelName', '');
    }
  }, [prevDirectionId, setFieldValue, values.classificatorEKUId, prevProgrammLevelId]);

  return (
    <FormikSelect
      label={title}
      name="programmLevelId"
      size="small"
      disabled={!values.classificatorEKUId}
      options={programmLevelData}
      withSearch
      placeholder="Начните вводить или выберите..."
      explainText={
        !values.classificatorEKUId
          ? title === 'Квалификация'
            ? 'Сначала выберите профессию'
            : 'Сначала выберите вид деятельности'
          : undefined
      }
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('programmLevelName', v.label);
        }
      }}
      defaultValue={
        values.programmLevelId || (values.programmLevelId === null && values.programmLevelName === 'Все')
          ? {
              value: values.programmLevelId,
              label: values.programmLevelName || '',
            }
          : undefined
      }
    />
  );
};

export default FieldProgrammLevel;
