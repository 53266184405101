import React from 'react';
import { Link } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmBadgeStatus } from '@mes-ui/lemma';
import { Learner } from '../../../../types/learners';
import { routes } from '../../../../config/constants';
import { serviceClassStatusEnum } from '../../../../mock-data/service-class-status';
import { formatDate } from '../../../../lib/utils/date';

type Props = {
  data: Learner.StatementHistory[];
};

const History: React.FC<Props> = ({ data }) => {
  return (
    <ul className="learner-history-list">
      {data
        .sort((a, b) => (a.date > b.date ? -1 : 1))
        .map((item) => {
          return (
            <li
              key={item.serviceClassId}
              className="learner-history-list__item"
            >
              <div className="container">
                <div className="learner-history-media">
                  <div className="learner-history-media__object">{item.date ? formatDate(item.date) : ''}</div>
                  <div className="learner-history-media__body">
                    <div className="learner-history-media-body">
                      <div className="learner-history-media-body__item">
                        <div className="font-weight-bold">
                          <span className="font-size-base">{item.megaRelationStatusName}</span>

                          {item.statusDescription ? (
                            <span className="color-success">&nbsp; {item.statusDescription}</span>
                          ) : null}
                        </div>
                        <Push size={8} />
                        <Link
                          to={`${routes.serviceClasses}/${item.serviceClassId}`}
                          className="brand-link font-size-xsmall"
                        >
                          Группа {item.serviceClassCode}
                        </Link>
                        <Push size={2} />
                        <div className="font-weight-bold">{item.serviceClassName}</div>
                        <Push size={8} />
                        <LmBadgeStatus
                          dataTest={
                            item.serviceClassStatusId === serviceClassStatusEnum.Archive
                              ? 'serviceClassArchive'
                              : 'serviceClassActive'
                          }
                          color={item.serviceClassStatusId === serviceClassStatusEnum.Archive ? 'grey' : 'green'}
                        >
                          {item.serviceClassStatusId === serviceClassStatusEnum.Archive ? 'В архиве' : 'Активна'}
                        </LmBadgeStatus>
                      </div>
                      <div className="learner-history-media-body__item">
                        {(item.megaRelationStatusName === 'Зачислен' ||
                          item.megaRelationStatusName === 'Переведен') && (
                          <div className="learner-history-media-body__detail">
                            <div className="learner-history-media-body__detail-label">Этап обучения</div>
                            <div className="learner-history-media-body__detail-value">{item.serviceStageName} этап</div>
                          </div>
                        )}

                        <div className="learner-history-media-body__detail">
                          <div className="learner-history-media-body__detail-label">Основание</div>
                          <div className="learner-history-media-body__detail-value">
                            {item.moveReasonName ? (
                              item.moveReasonName
                            ) : (
                              <>
                                {item.docName}
                                {item.docNumber ? ` № ${item.docNumber}` : null} {item.docDate ? 'от' : null}
                                &nbsp;
                                {item.docDate ? formatDate(item.docDate) : null}
                              </>
                            )}
                          </div>
                        </div>
                        {item.megaRelationStatusName !== 'Зачислен' && (
                          <div className="learner-history-media-body__detail">
                            <div className="learner-history-media-body__detail-label">Комментарий</div>
                            <div className="learner-history-media-body__detail-value">{item.description}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`learner-history-media__icon ${
                      item.megaRelationStatusName === 'Отчислен'
                        ? 'learner-history-media__icon--warning'
                        : item.megaRelationStatusName === 'Зачислен'
                          ? 'learner-history-media__icon--success'
                          : 'learner-history-media__icon--primary'
                    }`}
                  >
                    {item.megaRelationStatusName === 'Отчислен' ? (
                      <LmIcon
                        icon="filled-account-log-out"
                        size={20}
                        color="var(--LM-neutrals-day-0)"
                      />
                    ) : item.megaRelationStatusName === 'Зачислен' ? (
                      <LmIcon
                        icon="filled-arrows-wait"
                        size={20}
                        color="var(--LM-neutrals-day-0)"
                      />
                    ) : (
                      <LmIcon
                        icon="filled-arrows-flip-horizontal"
                        size={20}
                        color="var(--LM-neutrals-day-0)"
                      />
                    )}
                  </div>

                  <div className="learner-history-media__arrow">
                    <LmIcon
                      icon="filled-chevrons-filled-arrow-up"
                      size={24}
                      color="var(--LM-neutrals-day-400)"
                    />
                  </div>
                </div>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export default History;
