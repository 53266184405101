import React, { useContext, useState } from 'react';
import { LmButton } from '@mes-ui/lemma';
import SavePanel from '../../../../components/save-panel';
import Expulsion from '../../components/modals/expulsion';
import Recovery from '../../components/modals/recovery';
import Union from '../../components/modals/union';
import TransferGroup from '../../components/modals/transfer-group';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import Change from '../../components/modals/change';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, openDetails, active } = useContext(ServiceClassTableContext);

  const { adminView, adminEdit, accessRecover } = useContext(ServiceClassContext);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);

  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);

  const [openUnionPopup, setOpenUnionPopup] = useState<boolean>(false);

  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);

  return (
    <>
      {' '}
      {openDetails && (
        <SavePanel
          hidePrimaryButton
          hideSecondaryButton
        >
          <LmButton
            dataTest="cancelSelection"
            type="button"
            variant="text"
            size="medium"
            onClick={() => {
              setSelected([]);
              setOpenDetails(false);
            }}
          >
            Отменить выбор
          </LmButton>
          {active ? (
            <>
              <LmButton
                dataTest="expel"
                type="button"
                color="danger"
                variant="outline"
                size="medium"
                onClick={() => {
                  setOpenExpulsionPopup(true);
                }}
              >
                Отчислить
              </LmButton>

              {(adminView || adminEdit) && (
                <LmButton
                  dataTest="changeDates"
                  type="button"
                  variant="outline"
                  size="medium"
                  onClick={() => setOpenChangeModal(true)}
                >
                  Изменить даты обучения
                </LmButton>
              )}

              <LmButton
                dataTest="transferGroup"
                type="button"
                variant="outline"
                size="medium"
                onClick={() => setOpenTransferGroupPopup(true)}
              >
                Перевести в другую группу
              </LmButton>

              <LmButton
                dataTest="union"
                type="button"
                variant="outline"
                size="medium"
                onClick={() => setOpenUnionPopup(true)}
              >
                Объединить в подгруппу
              </LmButton>
            </>
          ) : (
            accessRecover && (
              <LmButton
                dataTest="recover"
                type="button"
                variant="outline"
                size="medium"
                onClick={() => setOpenRecoveryPopup(true)}
              >
                Восстановить
              </LmButton>
            )
          )}
        </SavePanel>
      )}
      <Change
        open={openChangeModal}
        close={() => setOpenChangeModal(false)}
      />
      <Recovery
        open={openRecoveryPopup}
        close={() => setOpenRecoveryPopup(false)}
      />
      <Expulsion
        open={openExpulsionPopup}
        close={() => setOpenExpulsionPopup(false)}
      />
      <TransferGroup
        open={openTransferGroupPopup}
        close={() => setOpenTransferGroupPopup(false)}
      />
      <Union
        open={openUnionPopup}
        close={() => setOpenUnionPopup(false)}
      />
    </>
  );
};

export default PanelButtons;
