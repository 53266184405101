import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Panel } from '@mosru/esz_uikit';
import FormFinancing from '../panels/form';
import { ServiceData } from '../../../../types/service';
import { setAsyncValues } from '../../../../lib/utils/requests';

const CreateFinancing = () => {
  const { values, setFieldValue, dirty } = useFormikContext<ServiceData>();

  useEffect(() => {
    if (dirty) {
      const fieldsToClean = [
        {
          key: 'financing.typeValueServiceId',
        },
        {
          key: 'financing.typeValueServiceName',
        },
        {
          key: 'financing.fullPrice',
        },
        {
          key: 'financing.lessonPrice',
        },
        {
          key: 'financing.periodPrice',
        },
        {
          key: 'financing.subsidiesPercent',
        },
      ];

      setAsyncValues(fieldsToClean, setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.financing?.typeFinancingId]);

  return (
    <Panel title={() => <>Финансирование</>}>
      <FormFinancing
        values={values.financing}
        editMode
        parent="financing"
      />
    </Panel>
  );
};

export default CreateFinancing;
