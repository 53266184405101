import React from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import { LmInput, LmInputProps } from '@mes-ui/lemma';

export type FormicInputProps = Omit<LmInputProps, 'name' | 'onChange' | 'size' | 'inputSize' | 'value'> & {
  name: string;
  value?: string | number;
  labelId?: string;
  error?: string;
  textRight?: boolean;
  size?: 'small' | 'medium' | 'large';
  showErrorImmediately?: boolean;
};

const INPUT = 'INPUT';

const FormikInput: React.FC<FormicInputProps> = ({
  showErrorImmediately,
  value: valueProp,
  size = 'medium',
  labelId,
  textRight,
  error: errorProp,
  dataTest,
  ...props
}) => {
  const { name } = props;
  const [field, meta, helper] = useField(name);

  const value = valueProp || field.value || '';

  const error = showErrorImmediately ? meta.error : meta.touched && meta.error;
  const errorText = errorProp || (error && (meta.error as string)) || '';

  const resettable = props.resettable ?? (!!value && !textRight);

  const handleSetValue = (value: string | number | null) => {
    helper.setTouched(true, true);
    helper.setValue(value);
  };

  const handleChange = (value: string | number, name?: string | null, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target.nodeName === INPUT) {
      field.onChange(event);
    }

    handleSetValue(value);
  };

  return (
    <LmInput
      guide={false}
      placeholderChar={'\u2000'}
      {...field}
      {...props}
      dataTest={dataTest || props.name}
      id={props.id || labelId}
      value={value}
      resettable={resettable}
      isError={props.isError || !!errorText}
      explainText={errorText || props.explainText}
      textPosition={textRight ? 'right' : 'left'}
      inputSize={size}
      classes={{
        ...props.classes,
        wrapper: classnames(
          props.classes?.wrapper,
          (props.rightInsideItem || props.leftInsideItem) && 'input-inside-item',
          (props.leftPrefix || props.rightPrefix) && 'input-prefix'
        ),
      }}
      onChange={handleChange}
    />
  );
};

export default FormikInput;
