import React from 'react';
import FormikDatePicker from '../../../../../../../components/formik/formik-datepicker';
import { getSelectedDate } from '../../../../../../../lib/utils/date';

type Props = {
  docDate: Date | string;
  accessCheck: boolean;
  newDocument: boolean;
};

export const DocumentDate: React.FC<Props> = ({ docDate, newDocument, accessCheck }) => {
  const date = new Date(docDate);

  date.toISOString();

  return (
    <FormikDatePicker
      label="Дата"
      required
      disabled={!accessCheck}
      selectedDay={!newDocument ? getSelectedDate(date) : undefined}
      size="small"
      name="docDate"
    />
  );
};
