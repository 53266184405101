// Модель для авторизации пользователя (из ESZ.Authorization.DTO)

export type LoginData = {
  login: string;
  password: string;
  remember: boolean;
  /** Точка входа (ui, admin, adminib или admin+adminib) */
  appName: string;
  /** Ключ имперсонации */
  impersonateKey: string;
  /** запрос на проверку пароля согласно политикам паролей */
  needCheckPasswordExpired: boolean;
};

export type LoginResponse = {
  isLoggedIn: boolean;
  isPasswordExpired: boolean;
  isUserCreated: boolean;
  message: string;
  token: string;
  warningMessage: string;
  errors?: string[];
};

export const initialLoginData: LoginData = {
  login: '',
  password: '',
  remember: false,
  appName: 'ui',
  impersonateKey: '',
  needCheckPasswordExpired: true,
};

export type ChangePasswordData = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  login: string;
  changeByExpired: boolean;
  remember: boolean;
};

export const initialChangePasswordData = {
  login: '',
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  changeByExpired: true,
};

export type PasswordSettings = {
  minLength: number;
  passwordCase: number;
  specialSymbols: number;
  layout: number;
  maxPasswordDuration: number;
  minCountChangeSymbols: number;
  minPasswordDuration: number;
  usageOfPrevPasswordCount: number;
  maxAttemptsWrongPassword: number;
  passwordBlockInterval: number;
  periodForBlock: number;
  id: number;
  daysOfRepeatLoginUseCheck: number;
  daysLoginBlock: number;
  inactivityInterval: number;
  logsStorageTime: number;
  isAvailableDSLogin: boolean;
  case: number;
  passwordSpecialSymbols: number;
  passwordLayout: number;
};

export const defaultPasswordSettings = {
  minLength: 6,
  passwordCase: 1,
  specialSymbols: 1,
  layout: 1,
  maxPasswordDuration: 120,
  minCountChangeSymbols: 1,
  minPasswordDuration: 0,
  usageOfPrevPasswordCount: 1,
  maxAttemptsWrongPassword: 3,
  passwordBlockInterval: 15,
  periodForBlock: 5,
  id: 1,
  daysOfRepeatLoginUseCheck: 365,
  daysLoginBlock: 90,
  inactivityInterval: 60,
  logsStorageTime: 183,
  isAvailableDSLogin: true,
  case: 1,
  passwordSpecialSymbols: 1,
  passwordLayout: 1,
};

export type AuthOrganizationsType = {
  login: string;
  name: string;
  organizationId: number;
  organizationName: string;
  roleList: string[];
};
