import React from 'react';
import { Panel } from '@mosru/esz_uikit';
import FormikInput from '../../../../components/formik/formik-input';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { ServiceDuration } from '../../components/dkgm-dsit-template/panels/fields/service-duration';
import { LessonLevelField } from '../../components/fields/lesson-level';
import FormikToggle from '../../../../components/formik/formik-toggle';
import { GroupMembersField } from '../../components/dkgm-dsit-template/panels/fields/group-members';
import { HoursPerWeek } from '../../components/fields/hours-per-week';

type InfoProps = {
  isVisibleHoursPerWeek: boolean;
};

const Info = ({ isVisibleHoursPerWeek }: InfoProps) => {
  const toggle = (name: string): JSX.Element => {
    return (
      <div className="table-data__control">
        <FormikToggle
          name={name}
          size="small"
        />
      </div>
    );
  };

  return (
    <Panel
      title={() => 'Сведения об образовательной услуге'}
      headingControl={() => (
        <div className="color-gray-dark text-right">
          Разрешить
          <br />
          редактир.
        </div>
      )}
    >
      <div className="container">
        <ClassificatorNameField
          editMode
          parent="info"
          name="classificator"
          label="Реестр базовых направлений"
          modalTitle="Реестр базовых направлений"
          toggleName="canChangeClassificatorEKU"
        />

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Наименование услуги <span className="table-data__required" />
          </div>
          <div className="table-data__body">
            <FormikInput
              name="info.name"
              size="small"
              placeholder="Введите..."
            />
          </div>

          {toggle('info.canChangeName')}
        </div>

        <ServiceDuration
          isTemplate
          parent="info"
          editMode
        />

        <LessonLevelField
          isToggle
          editMode
          required
          parent="info"
          name="programmLevel"
          label="Уровень программы"
          disabledPlaceholder="Сначала выберите базовое направление"
          otherElement={toggle('info.canChangeProgrammLevel')}
        />

        {isVisibleHoursPerWeek && (
          <HoursPerWeek
            editMode
            required
            parent="info"
            name="hoursPerWeek"
            otherElement={toggle('info.canChangeHoursPerWeek')}
          />
        )}

        <GroupMembersField
          isTemplate
          parent="info"
          editMode
        />

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Описание услуги <span className="table-data__required" />
          </div>
          <div className="table-data__body">
            <FormikTextarea
              name="info.programmService"
              maxRows={2}
              placeholder="Введите..."
            />
          </div>
          {toggle('info.canChangeProgrammService')}
        </div>

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Порядок предоставления</div>
          <div className="table-data__body">
            <FormikTextarea
              name="info.ruleService"
              maxRows={2}
              placeholder="Введите..."
            />
          </div>
          {toggle('info.canChangeRuleService')}
        </div>
      </div>
    </Panel>
  );
};

export default Info;
