import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { useDispatch } from 'react-redux';
import Popup from '../../../../components/modals/popup';
import { notify } from '../../../../redux/ducks/notifications';
import InputGroup from './input-group';
import List from './list';
import learnerApi from '../../../../lib/api/learner';
import { notificationMessages, transformDataForPopup } from '../../../../lib/utils/learners';
import { Learner, SplitModalListElement } from '../../../../types/learners';
import { OperationResultEnum } from '../../../../mock-data/operation-result-enum';

type Props = {
  modal: boolean;
  showModal: (value: boolean) => void;
};

const SplitModal: React.FC<Props> = ({ modal, showModal }) => {
  const [dataPortable, setDataPortable] = useState<SplitModalListElement[]>([]);
  const [actualPersonalData, setActualPersonalData] = useState<SplitModalListElement[]>([]);
  const [isLoadingMerge, setIsLoadingMerge] = useState(false);

  const dispatch = useDispatch();

  const editDataPortable = (data: Learner.Data) => setDataPortable([...dataPortable, transformDataForPopup(data)]);
  const editDataActual = (data: Learner.Data) => {
    if (actualPersonalData.length) {
      return;
    }
    setActualPersonalData([...actualPersonalData, transformDataForPopup(data)]);
  };

  const searchDataAll = async (value: string, editData: (data: Learner.Data) => void) => {
    try {
      setIsLoadingMerge(true);
      const learner = await learnerApi.getLearner(
        {
          pupilId: value,
          showExcluded: true,
        },
        false
      );

      if (learner.isArchive) {
        dispatch(
          notify.danger({
            dataTest: 'archivePerson',
            title: notificationMessages.noPersonalData,
          })
        );

        return;
      }

      editData(learner);
    } finally {
      setIsLoadingMerge(false);
    }
  };

  const deleteItemActualPersonalData = (value: number) => {
    return setActualPersonalData(actualPersonalData.filter((item: SplitModalListElement) => item.id !== value));
  };
  const deleteItemPersonalData = (value: number) => {
    return setDataPortable(dataPortable.filter((item: SplitModalListElement) => item.id !== value));
  };

  const mergeUser = async () => {
    const mergeArray = dataPortable.map((item: SplitModalListElement) => item.id);

    const request = await learnerApi.learnerMerge(actualPersonalData[0].id, mergeArray);

    if (request.resultCode === OperationResultEnum.Success) {
      dispatch(
        notify.success({
          dataTest: 'merge',
          title: notificationMessages.mergeSuccess,
        })
      );
      setTimeout(() => {
        setDataPortable([]);
        setActualPersonalData([]);
      }, 2000);
    } else {
      dispatch(
        notify.danger({
          dataTest: 'merge',
          title: request.errorMessage,
        })
      );
    }
    showModal(false);
  };

  const hideModal = (): void => {
    showModal(false);
    setActualPersonalData([]);
    setDataPortable([]);
  };

  return (
    <div>
      <Popup
        dataTest="splitPopup"
        open={modal}
        size="large"
        title="Объединение личных дел обучающихся"
        buttonPrimaryText={`Объединить личных дел: ${
          dataPortable.length ? dataPortable.length + actualPersonalData.length : 0
        }`}
        primaryButtonModifiers={{
          disabled: !(dataPortable.length && actualPersonalData.length),
        }}
        onSubmit={mergeUser}
        onClose={hideModal}
      >
        <div className="learners-modal-grid">
          <div className="learners-modal-grid__col">
            <div className="line-height-text">
              <Push size={16} />
              <div className="font-weight-bold">Переносимые личные дела</div>
              <Push size={4} />
              <div className="color-gray-dark">
                Выберите личные дела, которые необходимо перенести (одно или несколько)
              </div>
            </div>
            <Push size={12} />
            <InputGroup
              data={dataPortable}
              comparisonData={actualPersonalData}
              disabled={isLoadingMerge}
              onSelect={(value) => searchDataAll(value, editDataPortable)}
              labelId="dataPortable"
            />
            <Push size={20} />
            <List
              deleteElement={deleteItemPersonalData}
              data={dataPortable}
            />
          </div>
          <div className="learners-modal-grid__col">
            <div className="line-height-text">
              <Push size={16} />
              <div className="font-weight-bold">Актуальное личное дело</div>
              <Push size={4} />
              <div className="color-gray-dark">Выберите одно личное дело, в которое необходимо осуществить перенос</div>
            </div>
            <Push size={12} />

            <InputGroup
              comparisonData={dataPortable}
              disabled={!!actualPersonalData.length || isLoadingMerge}
              onSelect={(value) => searchDataAll(value, editDataActual)}
              labelId="actualPersonalData"
            />
            <Push size={20} />
            <List
              deleteElement={deleteItemActualPersonalData}
              data={actualPersonalData}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default SplitModal;
