import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { array as arrayYup, object as objectYup, string as stringYup } from 'yup';
import { LmButton, LmIcon, LmListItem, InteractiveChevronTemplate, LmSelectNew } from '@mes-ui/lemma';
import { Panel, Push } from '@mosru/esz_uikit';
import SavePanel from '../../../components/save-panel';
import { classificatorApi } from '../../../lib/api/classificator';
import { classificatorEKULimitApi } from '../../../lib/api/classificator-EKU-limit';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import { hasGeneralAccess } from '../../../lib/utils';
import { checkPrevYearOfTraining } from '../../../lib/utils/year-of-training';
import { generalAccess } from '../../../mock-data/access-enum';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import {
  BudgetPlaceFormData,
  ClassificatorChildData,
  RestrictionsBudgetPlacesData,
  RestrictionsBudgetPlacesFormData,
  RestrictionsBudgetPlacesRequestData,
} from '../../../types/classificator';
import { EducationTypeEnum } from '../../../types/education-type';
import { SelectOptionType, YearOfTraining } from '../../../types/entities';
import DropDown from '../../../components/drop-down';
import ProfessionTable from './services/profession-table';
import CheckableTreeModal from './services/modal/checkable-tree-modal';
import { getClassificatorList, MAX_LIMIT_VOLUME } from './services/utils';
import { LoaderCustom } from '../../../components/loader-custom';

type OrganizationServicesProps = {
  organizationId: number;
  vedomstvoId?: number;
  isArchive?: boolean;
};

const OrganizationServices = ({ organizationId, vedomstvoId, isArchive }: OrganizationServicesProps) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const [editMode, setEditMode] = useState(false);
  const [yearList, setYearList] = useState<SelectOptionType[]>([]);
  const [data, setData] = useState<RestrictionsBudgetPlacesData[]>([]);
  const [initialValues, setInitialValues] = useState<RestrictionsBudgetPlacesFormData>({
    data: [],
  });
  const [selectedYear, setSelectedYear] = useState<SelectOptionType | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingYears, setLoadingYears] = useState(false);
  const [showTreeModal, setShowTreeModal] = useState(false);
  const [isEditableYear, setIsEditableYear] = useState(false);
  const [currentYearOfTraining, setCurrentYearOfTraining] = useState<YearOfTraining>();

  const isEditable = useMemo(
    () =>
      !isArchive &&
      isEditableYear &&
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasGeneralAccess(userProfile, generalAccess.AdminOfAcceptanceLimits)),
    [userProfile, isArchive, isEditableYear]
  );

  const getYearOfTrainingList = async () => {
    const getCurrentYearOfTraining = async () => {
      try {
        return await dictionariesApi.getCurrentYearOfTrainings();
      } catch (e) {
        return undefined;
      }
    };

    try {
      setLoadingYears(true);
      const currentYear = await getCurrentYearOfTraining();

      setCurrentYearOfTraining(currentYear);

      const years = await dictionariesApi.getYearOfTrainings();
      const formatYearList = years.map((year) => ({
        ...year,
        label: year.label && year.label.replace(/(\d{4}).*(\d{4}).*/, '$1 - $2 учебный год'),
      }));
      const selectedCurrentYear =
        formatYearList.find(({ value }) => value === currentYear?.id) || formatYearList[formatYearList.length - 1];

      setYearList(formatYearList);
      setSelectedYear(selectedCurrentYear);
    } catch (error) {
      setYearList([]);
      setSelectedYear(null);
    } finally {
      setLoadingYears(false);
    }
  };

  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await classificatorEKULimitApi.getBudgetPlaces({
        organizationId,
        educationTypeId,
        yearOfTrainingId: selectedYear?.value as number,
      });

      setData(response);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [organizationId, selectedYear]);

  const checkEditableYear = useCallback(
    (year?: string) =>
      !!year && !!currentYearOfTraining?.name && !checkPrevYearOfTraining(year, currentYearOfTraining.name),
    [currentYearOfTraining]
  );

  useEffect(() => {
    getYearOfTrainingList();
  }, []);

  useEffect(() => {
    if (!selectedYear) {
      setData([]);

      return;
    }

    getTableData();
  }, [selectedYear, getTableData]);

  useEffect(() => {
    setIsEditableYear(checkEditableYear(selectedYear?.label));
  }, [selectedYear, checkEditableYear]);

  useEffect(() => {
    setInitialValues({
      data: data.map((item) => ({
        ...item,
        hasPlaces: item.limitVolume !== null,
      })),
    });
  }, [data]);

  const convertToBudgetPlaceFormData = (data: ClassificatorChildData): BudgetPlaceFormData => ({
    id: 0,
    classificatorEKUId: data.id,
    classificatorEKUName: data.name,
    educationTypeId: data.educationTypeId,
    organizationId,
    volume: null,
    usedVolume: null,
    limitVolume: null,
    yearOfTrainingId: selectedYear?.value as number,
    yearOfTrainingName: selectedYear?.label ?? null,
    hasPlaces: false,
    hasServices: false,
  });

  const handleShowEditForm = () => {
    setEditMode(true);
  };

  const handleCancel = (resetForm: () => void) => () => {
    setEditMode(false);
    resetForm();
  };

  const handleSubmit = async (values: RestrictionsBudgetPlacesFormData) => {
    if (selectedYear) {
      setLoading(true);
      try {
        const requestData: RestrictionsBudgetPlacesRequestData = {
          organizationId,
          educationTypeId,
          yearOfTrainingId: selectedYear.value as number,
          limits: values.data.map((item) => {
            const newItem = {
              ...item,
              limitVolume: typeof item.limitVolume === 'string' ? parseInt(item.limitVolume) : item.limitVolume,
            };

            delete newItem.hasPlaces;

            return newItem;
          }),
        };

        await classificatorEKULimitApi.editBudgetPlaces(requestData);
        getTableData();
        setEditMode(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleShowTreeModal = () => {
    setShowTreeModal(true);
  };

  const handleCloseTreeModal = () => {
    setShowTreeModal(false);
  };

  const filterOption = async (): Promise<SelectOptionType[]> =>
    yearList.filter((option) => !editMode || checkEditableYear(option?.label));

  return (
    <>
      <Push size={12} />
      {loadingYears ? (
        <LoaderCustom
          size={180}
          hasPanel
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, resetForm, submitForm, isValid, setValues }: FormikProps<RestrictionsBudgetPlacesFormData>) => {
            const handleChangeYear = (option: any) => {
              setValues({
                data: [],
              });
              setSelectedYear(option);
            };

            const handleAddFew = (list: ClassificatorChildData[]) => {
              const newData = [...values.data, ...list.map((item) => convertToBudgetPlaceFormData(item))];

              setValues({
                data: newData,
              });
            };

            const handleAddRemaining = async () => {
              if (!selectedYear) {
                return;
              }

              setLoading(true);
              try {
                const data = await classificatorApi.getClassificatorTree({
                  educationTypeId,
                  organizationId,
                  vedomstvoId,
                  yearOfTrainingId: selectedYear.value as number,
                });

                const classifictorList = getClassificatorList(data);

                const newData = [
                  ...values.data,
                  ...classifictorList
                    .filter(({ id }) => !values.data.find(({ classificatorEKUId }) => classificatorEKUId === id))
                    .map((item) => convertToBudgetPlaceFormData(item)),
                ];

                setValues({
                  data: newData,
                });
              } finally {
                setLoading(false);
              }
            };

            const handleAddLastYear = async () => {
              if (!selectedYear) {
                return;
              }

              setLoading(true);
              try {
                const data = await classificatorEKULimitApi.getPrevYearBudgetPlaces({
                  organizationId,
                  educationTypeId,
                  yearOfTrainingId: selectedYear.value as number,
                });

                setValues({
                  data: data.map((item) => ({
                    ...item,
                    id: 0,
                    yearOfTrainingName: selectedYear.label,
                    yearOfTrainingId: selectedYear.value as number,
                    hasPlaces: item.limitVolume !== null,
                    hasServices: false,
                  })),
                });
              } finally {
                setLoading(false);
              }
            };

            return (
              <>
                <Panel
                  title={() => (
                    <div className="flex items-center">
                      Администрирование бюджетных мест на зачисление
                      <Push
                        orientation="horizontal"
                        size={16}
                      />
                      <div
                        className="font-weight-base"
                        style={{
                          width: 272,
                        }}
                      >
                        <LmSelectNew
                          dataTest="selectedYear"
                          name="selectedYear"
                          onChange={handleChangeYear}
                          options={yearList}
                          value={selectedYear || null}
                          size="small"
                          placeholder="Выберите..."
                          grouped={false}
                          multiple={false}
                          getFilterOptions={filterOption}
                          settingDropdownAsPopover={{
                            disablePortal: true,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  headingControl={() =>
                    isEditable &&
                    (editMode ? (
                      <DropDown
                        dataTest="addService"
                        component={(open) => (
                          <LmButton
                            dataTest="addService"
                            type="button"
                          >
                            Добавить
                            <InteractiveChevronTemplate open={open} />
                          </LmButton>
                        )}
                      >
                        <>
                          <LmListItem
                            dataTest="fewServices"
                            text="Несколько услуг"
                            onClick={handleShowTreeModal}
                          />
                          <LmListItem
                            dataTest="allServices"
                            text="Все услуги"
                            onClick={handleAddRemaining}
                          />
                          <LmListItem
                            dataTest="allLastServices"
                            text="Все услуги с предыдущего года"
                            onClick={handleAddLastYear}
                          />
                        </>
                      </DropDown>
                    ) : (
                      <button
                        type="button"
                        onClick={handleShowEditForm}
                        className="icon-group"
                      >
                        <span className="icon-group__icon">
                          <LmIcon
                            icon="filled-edit-edit"
                            size={20}
                            color="var(--LM-blue-200)"
                          />
                        </span>
                        <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                      </button>
                    ))
                  }
                >
                  {loading ? <LoaderCustom size={20} /> : <ProfessionTable editMode={editMode} />}
                </Panel>
                {editMode && (
                  <SavePanel
                    primaryButtonModifiers={{
                      loading,
                      disabled: !isValid || !isEditableYear,
                    }}
                    onClickSeconadaryButton={handleCancel(resetForm)}
                    onClickPrimaryButton={submitForm}
                  />
                )}
                <CheckableTreeModal
                  show={showTreeModal}
                  organizationId={organizationId}
                  educationTypeId={educationTypeId}
                  yearOfTrainingId={selectedYear?.value as number}
                  vedomstvoId={vedomstvoId}
                  checkableKeys={values.data.map(({ classificatorEKUId }) => classificatorEKUId)}
                  onSave={handleAddFew}
                  onClose={handleCloseTreeModal}
                />
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default OrganizationServices;

const educationTypeId = EducationTypeEnum.ProfessionalEducation;

const validationSchema = objectYup().shape({
  data: arrayYup().of(
    objectYup().shape({
      limitVolume: stringYup()
        .test('required-limit-volume', ' ', function (value) {
          const convertValue = typeof value === 'string' ? parseInt(value) : value;

          return convertValue
            ? convertValue <= MAX_LIMIT_VOLUME && (!this.parent.volume || convertValue >= this.parent.volume)
            : convertValue === null;
        })
        .nullable(),
    })
  ),
});
