import { FormikHelpers, useField, useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import { EducationTypeEnum } from '../../../../types/education-type';
import Field, { FieldProps } from '../../../../components/fields/field';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { ServiceClassContext } from '../../service-class';

type OrganizationFieldProps = FieldProps<string> & {
  educationType: EducationTypeEnum;
};

export const organizationFieldIdName = 'organizationId';

const OrganizationField = ({
  label = 'Организация',
  name,
  editMode,
  required,
  defaultValue = '—',
  educationType,
  disabled,
}: OrganizationFieldProps) => {
  const fieldName = `${name}Name`;

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { setFieldValue } = useFormikContext<FormikHelpers<any>>();

  const { adminEdit } = useContext(ServiceClassContext);

  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldValue, , helpersValue] = useField(organizationFieldIdName);

  const fieldNameVedomstvo = 'vedomstvoId';
  const [vedomstvo] = useField(fieldNameVedomstvo);
  const initialVedomstvoRef = useRef<number>(vedomstvo.value);

  const fetchOptions = useCallback(
    async (query: string) =>
      await lookupApi.getOrganization(
        query,
        adminEdit ? vedomstvo.value : userProfile.vedomstvoId,
        educationType === EducationTypeEnum.DayCareCentersEducation
      ),
    [adminEdit, educationType, userProfile.vedomstvoId, vedomstvo.value]
  );

  useEffect(() => {
    if (initialVedomstvoRef.current !== vedomstvo.value) {
      setFieldValue(organizationFieldIdName, null);
      setFieldValue(fieldName, '');
    }
  }, [fieldName, setFieldValue, vedomstvo.value]);

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={fieldLabel.value}
      defaultValue={defaultValue}
    >
      <FormikSelect
        name={organizationFieldIdName}
        size="small"
        withSearch
        options={[]}
        disabled={disabled}
        loadOptions={fetchOptions}
        defaultValue={
          fieldValue.value
            ? {
                label: fieldLabel.value,
                value: fieldValue.value as number,
              }
            : null
        }
        selectedValue={(selected?: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : '');
          helpersValue.setValue(selected ? selected.value : null);
        }}
        placeholder="Начните вводить..."
      />
    </Field>
  );
};

export default OrganizationField;
