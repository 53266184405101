import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import { SearchInitialFormData } from '../../../../../types/service';
import { usePrevious } from '../../../../../hooks/use-previous';
import { SelectOptionType } from '../../../../../types/entities';

const FieldAddress: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const prevVedomstvoId = usePrevious(values.vedomstvoId);
  const prevOrganizationId = usePrevious(values.organizationId);
  const prevEducationTypeId = usePrevious(values.educationTypeId);
  const prevAddressId = usePrevious(values.servicePlaceId);

  useEffect(() => {
    // При изменении "Департамента", "Организации", "Типа образования" обнуляем значение "Адрес предоставления услуги"
    if (
      (values.vedomstvoId !== prevVedomstvoId ||
        values.organizationId !== prevOrganizationId ||
        values.educationTypeId !== prevEducationTypeId) &&
      prevAddressId
    ) {
      setFieldValue('servicePlaceId', null);
      setFieldValue('servicePlaceQuery', null);
    }
  }, [
    values.vedomstvoId,
    values.organizationId,
    values.educationTypeId,
    prevVedomstvoId,
    prevOrganizationId,
    prevEducationTypeId,
    prevAddressId,
    setFieldValue,
  ]);

  return (
    <FormikSelect
      label="Адрес предоставления услуги"
      name="servicePlaceId"
      size="small"
      disabled={!values.organizationId}
      defaultValue={
        values?.servicePlaceId
          ? {
              label: values?.servicePlaceQuery ?? '',
              value: values?.servicePlaceId ?? null,
            }
          : null
      }
      options={[]}
      withSearch
      showTooltip
      disabledPortalTooltip
      loadOptions={async (query) => {
        return await lookupApi.getPlaceService(
          query,
          values.vedomstvoId as number,
          values.organizationId as number,
          values.educationTypeId as number
        );
      }}
      placeholder="Начните вводить..."
      explainText={!values.organizationId ? 'Сначала выберите организацию' : undefined}
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('servicePlaceQuery', v.label);
        }
      }}
    />
  );
};

export default FieldAddress;
