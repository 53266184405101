import { fetchRequest } from '.';
import { apiService } from '../../config/constants';
import { ExternalSystemResponseData } from '../../types/external-system';

const getCheckStatuses = async (
  extendEtityGuid: string,
  responseTypeIds: number[]
): Promise<ExternalSystemResponseData[]> => {
  const params = responseTypeIds.map((n) => `responseTypeIds=${n}`).join('&');

  try {
    const statuses = await fetchRequest.get(`${apiService.data}/extSysExchange/${extendEtityGuid}?${params}`);

    return statuses;
  } catch {
    return [];
  }
};

export const externalSystemApi = {
  getCheckStatuses,
};
