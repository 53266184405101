import classNames from 'classnames';
import React from 'react';
import FooterWrapper from '../footer/footer-wrapper';

type Classes = {
  content: string;
  filter: string;
};

type ViewContainerProps = {
  subHeader?: JSX.Element;
  classes?: Classes;
  noPadding?: boolean;
  contentFlexColumn?: boolean;
  filterComponent?: React.ReactNode;
  noContainer?: boolean;
};

const ViewContainer: React.FC<ViewContainerProps> = ({
  subHeader,
  classes,
  noPadding,
  contentFlexColumn,
  filterComponent,
  children,
  noContainer,
}) => {
  return noContainer ? (
    <>{children}</>
  ) : (
    <>
      {subHeader}
      <div
        id="viewContainer"
        className="content__container"
      >
        <div className={classNames('content__body', !!filterComponent && 'content__body--filter')}>
          <div
            className={classNames(
              'content__inner',
              !noPadding && 'content__inner--padding',
              contentFlexColumn && 'content__inner--flex-column',
              classes?.content
            )}
          >
            {children}
          </div>
          <FooterWrapper />
        </div>
        {filterComponent && <div className={classNames('content__filter', classes?.filter)}>{filterComponent}</div>}
      </div>
    </>
  );
};

export default ViewContainer;
