import React, { useEffect, useState } from 'react';
import { TreeNodeType, Push } from '@mosru/esz_uikit';
import { LmLoader } from '@mes-ui/lemma';
import { Key } from 'rc-tree/lib/interface';
import SidePage from '../../modals/side-page';
import SelectCategory from './select-category';
import TreeElement from './tree-element';
import { classificatorApi } from '../../../lib/api/classificator';
import { transformClassificatorSearch } from '../../../lib/utils/learners';
import { mockCategoryTreeModal } from '../helpers';
import { TreeModalContext } from '../index';
import { SelectOptionType } from '../../../types/entities';
import Search from './search';

const Content = () => {
  const {
    open,
    title,
    selected,
    closePopup,
    educationId,
    dataTreePopup,
    selectOptions,
    numberElements,
    setDataTreePopup,
    setValueBasicDirection,
    currentMenuClosePopup,
    organizationId,
    showAmount,
  } = React.useContext(TreeModalContext);

  const [initialData, setInitialData] = useState<TreeNodeType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [currentCategory, setCurrentCategory] = useState<SelectOptionType | undefined>(findMenu(currentMenuClosePopup));

  const [edId, setEdId] = useState<number | null>(null);
  const [basicDirection, setBasicDirection] = useState<SelectOptionType | null>(null);

  const [keyRandom, setKeyRandom] = useState<number>(0);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  function findMenu(currentMenu?: SelectOptionType) {
    if (selectOptions && currentMenuClosePopup) {
      if (currentMenuClosePopup.value) {
        return selectOptions.find((item: SelectOptionType) => item.value === currentMenu?.value);
      } else {
        return selectOptions[0];
      }
    } else if (selectOptions) {
      return selectOptions[0];
    } else {
      return mockCategoryTreeModal[0];
    }
  }

  useEffect(() => {
    if (educationId) {
      setEdId(educationId);
    } else {
      setEdId(currentCategory?.value as number);
    }
  }, [currentCategory, educationId]);

  useEffect(() => {
    // делаем запрос от выбранной категории
    (async () => {
      setLoading(false);
      const edType = educationId || currentCategory?.value;

      if (numberElements) {
        setDataTreePopup(dataTreePopup);
        setInitialData(dataTreePopup);
        setLoading(true);
      } else if (edType) {
        const response = await classificatorApi.search({
          edType: educationId || currentCategory?.value,
          orgId: organizationId,
        });

        setInitialData(transformClassificatorSearch(response, showAmount));
        setDataTreePopup(transformClassificatorSearch(response, showAmount));
        setKeyRandom(Math.random());
        setLoading(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory?.value]);

  const handleClose = (value: boolean) => {
    closePopup(value);
  };

  const save = () => {
    setValueBasicDirection(basicDirection as SelectOptionType);
    closePopup(false);
  };

  return (
    <SidePage
      dataTest="treeModal"
      open={open}
      title={title}
      description="Направленность > профиль > вид деятельности"
      additionalHeader={
        selected && (
          <div className="modal__additional-header">
            <SelectCategory
              selectOptions={selectOptions}
              currentCategory={currentCategory || null}
              setDataTreePopup={setDataTreePopup}
              setCurrentCategory={setCurrentCategory}
            />
          </div>
        )
      }
      classes={{
        body: !loading ? 'flex flex-column' : undefined,
      }}
      onClose={handleClose}
      onSubmit={save}
    >
      <Search
        setLoading={setLoading}
        setKeyRandom={setKeyRandom}
        setInitialData={setInitialData}
        setSelectedKeys={setSelectedKeys}
        setExpandedKeys={setExpandedKeys}
      />

      <Push size={16} />

      {!loading ? (
        <div className="loader-auto">
          <LmLoader view="block" />
        </div>
      ) : (
        <TreeElement
          educationId={edId}
          keyRandom={keyRandom}
          initialData={initialData}
          selectedKeys={selectedKeys}
          setBasicDirectionValue={setBasicDirection}
          organizationId={organizationId}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
        />
      )}
    </SidePage>
  );
};

export default Content;
