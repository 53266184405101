import React from 'react';
import { formatDate, formatTime } from '../../../lib/utils/date';
import { serviceTypeEnum } from '../../../mock-data/print';
import { RequestData } from '../../../types/requests';
import { checkIfData } from '../../../lib/utils';

type TemplateEnum = {
  [index: string]: string;
};

const serviceInformationTitle: TemplateEnum = {
  [serviceTypeEnum.donm]: 'Информация о детском объединении и группе обучения',
  [serviceTypeEnum.notDonm]: 'Информация о детском объединении и группе обучения',
  [serviceTypeEnum.digitalTutor]: 'Информация об услуге',
  [serviceTypeEnum.artHouseEducation]: 'Информация об услуге',
  [serviceTypeEnum.sportEducation]: 'Информация об услуге',
  [serviceTypeEnum.programEducational]: 'Информация об образовательной программе',
};

const serviceName: TemplateEnum = {
  [serviceTypeEnum.donm]: 'Наименование детского объединения',
  [serviceTypeEnum.notDonm]: 'Наименование детского объединения',
  [serviceTypeEnum.digitalTutor]: 'Наименование услуги',
  [serviceTypeEnum.programEducational]: 'Наименование образовательной программы',
  [serviceTypeEnum.artHouseEducation]: 'Наименование услуги',
  [serviceTypeEnum.sportEducation]: 'Наименование услуги',
};

type Props = {
  data: RequestData | undefined;
  type: string | undefined;
};

const PrintList: React.FC<Props> = ({ data, type = 'donm' }) => {
  return (
    <>
      <h3 className="text-center">{serviceInformationTitle[type]}</h3>
      <p>
        <strong>Номер заявления: </strong> {checkIfData(data?.requestNumber)}
      </p>
      <p>
        <strong>Дата заявления: </strong> {formatDate(data?.dateRequest ?? '')}
      </p>
      {data && (
        <p>
          <strong>{serviceName[type]}: </strong> {checkIfData(data.trainingGroup.fullServiceName)}
        </p>
      )}

      <p>
        <strong>Образовательная организация: </strong> {checkIfData(data?.trainingGroup.organizationName)}
      </p>
      {type !== serviceTypeEnum.digitalTutor && (
        <p>
          <strong>Место приема документов: </strong> {checkIfData(data?.trainingGroup.itemsWorkAddress)}
        </p>
      )}

      {data?.trainingGroup?.isTestService && (
        <p>
          <strong>Место проведения вступительных испытаний: </strong> {checkIfData(data?.evaluation?.addressEvaluation)}
        </p>
      )}

      {type !== serviceTypeEnum.donm && type !== serviceTypeEnum.notDonm ? (
        <p>
          <strong>Преподаватель: </strong> {checkIfData(data?.trainingGroup.teacherName)}
        </p>
      ) : (
        <p>
          <strong>Наименование группы обучения: </strong> {data?.trainingGroup.serviceClass?.code},{' '}
          {data?.trainingGroup.serviceClass?.name}
        </p>
      )}
      <p>
        <strong>Дата начала занятий: </strong> {formatDate(data?.trainingGroup.educationStartDate ?? '')}
      </p>
      <p>
        <strong>Источник заявления: </strong> {checkIfData(data?.requestSourceName)}
      </p>

      {data?.trainingGroup?.isTestService && (
        <>
          <h3 className="text-center">Информация о вступительных испытаниях</h3>
          <p>
            <strong>Дата испытаний: </strong> {data?.evaluation?.testDate ? formatDate(data?.evaluation?.testDate) : ''}
          </p>
          <p>
            <strong>Время испытаний: </strong> {formatTime(data.evaluation.testTimeFrom)} -{' '}
            {formatTime(data?.evaluation?.testTimeTo)}
          </p>
        </>
      )}

      <h3 className="text-center">Информация о заявителе (законном представителе) и ребенке</h3>
      <p>
        <strong>ФИО ребенка: </strong> {checkIfData(data?.child.lastName)} {checkIfData(data?.child.firstName)}{' '}
        {checkIfData(data?.child.middleName)}
      </p>
      <p>
        <strong>Дата рождения: </strong> {formatDate(data?.child.dateOfBirth ?? '')}
      </p>
      <p>
        <strong>ФИО заявителя: </strong>
        {checkIfData(data?.applicant.lastName)} {checkIfData(data?.applicant.firstName)}{' '}
        {checkIfData(data?.applicant.middleName)}
      </p>
      <p>
        <strong>Телефон: </strong> {checkIfData(data?.applicant.phone)}
      </p>
      <p>
        <strong>Адрес электронной почты: </strong> {checkIfData(data?.applicant.email)}
      </p>
    </>
  );
};

export default PrintList;
