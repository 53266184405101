import { SelectOptionType } from '../../types/entities';

export const day: SelectOptionType[] = [
  {
    label: 'Все',
    value: 3,
  },
  {
    label: 'Утро',
    value: 1,
  },
  {
    label: 'День',
    value: 2,
  },
  {
    label: 'Вечер',
    value: 4,
  },
];

export const weekday = [
  {
    id: 1,
    name: 'Понедельник',
    shortName: 'Пн',
    time: 'Все',
    selected: 3,
    checked: false,
    field: 'monday',
  },

  {
    id: 2,
    name: 'Вторник',
    shortName: 'Вт',
    time: 'Все',
    selected: 3,
    checked: false,
    field: 'tuesday',
  },

  {
    id: 3,
    name: 'Среда',
    shortName: 'Ср',
    time: 'Все',
    selected: 3,
    checked: false,
    field: 'wednesday',
  },
  {
    id: 4,
    name: 'Четверг',
    shortName: 'Чт',
    time: 'Все',
    selected: 3,
    checked: false,
    field: 'thursday',
  },
  {
    id: 5,
    name: 'Пятница',
    shortName: 'Пт',
    time: 'Все',
    selected: 3,
    checked: false,
    field: 'friday',
  },
  {
    id: 6,
    name: 'Суббота',
    shortName: 'Сб',
    time: 'Все',
    selected: 3,
    checked: false,
    field: 'saturday',
  },
  {
    id: 7,
    name: 'Воскресенье',
    shortName: 'Вс',
    time: 'Все',
    selected: 3,
    checked: false,
    field: 'sunday',
  },
];
