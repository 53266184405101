import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmLoader } from '@mes-ui/lemma';
import { EducationTypeEnum } from '../../types/education-type';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { adminAccess, getEmptyFunctionToOverride } from '../../lib/utils';
import ServiceClassGpdCreate from './day-care/create';
import { ServiceData } from '../../types/service';
import ServiceChildCreate from './child/create';
import { useGetDataEducationTypes } from '../../hooks/get-education-types';
import { accessObject, accessVedomst } from '../../mock-data/access-enum';
import { accessAction } from '../../types/authorization-data';
import { RegistryTypeEnum } from '../../mock-data/registry-type-enum';
import ServiceProgramCreate from './preparation/create';
import DigitalTutorCreate from './digital-tutor/create';
import { serviceTemplateApi } from '../../lib/api/service-template';
import { organizationAccess } from './utils';
import ServiceCreate from './dkgm-dsit/create/create';

const initialValue = {} as ServiceData;

export type CreateServiceContextType = {
  serviceData: ServiceData;
  createService?: () => void | undefined;
  adminView?: boolean;
  adminEdit?: boolean;
  admin?: boolean;
  currentEducationType: number;
  educationTypes: number[];
  type: RegistryTypeEnum;
  organizationEdit: boolean;
};

export const CreateServiceContext = createContext<CreateServiceContextType>({} as CreateServiceContextType);

const CreateService = (type: RegistryTypeEnum) => {
  const [service, setService] = useState<ServiceData>();
  const { edType } = useParams<Record<string, string | undefined>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isDkgm = userProfile.vedomstvoId === accessVedomst.Dkgm;

  useEffect(() => {
    const fetch = async () => {
      if (edType) {
        try {
          setIsLoading(true);
          const emptyService = await serviceTemplateApi.getEmptyService(edType);

          // TODO: Убрать, когда Саша сделает это все на бэке
          emptyService.info.isFemalePresent = true;
          emptyService.info.isMalePresent = true;
          emptyService.financing.typeFinancingId = 2;
          emptyService.financing.typeFinancingName = 'платно';
          emptyService.financing.typeValueServiceId = 1;
          emptyService.financing.typeValueServiceName = 'За месяц';
          if (
            emptyService.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
            emptyService.educationTypeId === EducationTypeEnum.SportEducation
          ) {
            emptyService.info.durationOfTrainingUnitId = 1;
            emptyService.info.genderAll = true;
            emptyService.test.toursNumber = 1;
          }

          if (emptyService.educationTypeId === EducationTypeEnum.ArtHouseEducation && isDkgm) {
            emptyService.info.hoursPerWeek = 1;
          }

          setService(emptyService);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetch();
  }, [edType, isDkgm]);

  const educationTypeData = useGetDataEducationTypes(userProfile, accessObject.Services, accessAction.ViewRegistry);
  const currentEducationTypes = educationTypeData?.map((item) => item.value) as EducationTypeEnum[];

  const edTypeId = edType ? parseInt(edType) : null;

  const currentServiceClass = () => {
    switch (edTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return <ServiceChildCreate />;
      case EducationTypeEnum.ArtHouseEducation:
      case EducationTypeEnum.SportEducation:
        return <ServiceCreate />;
      case EducationTypeEnum.DayCareCentersEducation:
        return <ServiceClassGpdCreate />;
      case EducationTypeEnum.ProfessionalEducation:
        return <ServiceProgramCreate />;
      case EducationTypeEnum.VirtualAssistantEducation:
        return <DigitalTutorCreate />;
      default:
        return null;
    }
  };

  const initialValues: CreateServiceContextType = useMemo(
    () => ({
      serviceData: service || initialValue,
      createService: getEmptyFunctionToOverride(),
      adminView: adminAccess('view', userProfile),
      adminEdit: adminAccess('edit', userProfile),
      admin: adminAccess('all', userProfile),
      educationTypes: currentEducationTypes,
      currentEducationType: edType ? parseInt(edType) : 0,
      type,
      organizationEdit: organizationAccess(userProfile),
    }),
    [service, userProfile, currentEducationTypes, type, edType]
  );

  return (
    <>
      {edType && service ? (
        <CreateServiceContext.Provider value={initialValues}>{currentServiceClass()}</CreateServiceContext.Provider>
      ) : isLoading ? (
        <>
          <Push size={12} />
          <div className="loader-auto">
            <LmLoader view="page" />
          </div>
        </>
      ) : null}
    </>
  );
};

export default CreateService;
