import { hasGeneralAccess } from '../../lib/utils';
import { accessVedomst, generalAccess } from '../../mock-data/access-enum';
import { AuthorizationData } from '../../types/authorization-data';

export const checkAccessSign = (vedomstvoId: number | undefined, isOIV?: boolean) =>
  vedomstvoId === accessVedomst.Dkgm ||
  vedomstvoId === accessVedomst.Kdc ||
  (!isOIV && vedomstvoId === accessVedomst.Dsit);

export const getAccessSign = (userProfile: AuthorizationData, isAdmin: boolean, isOIV?: boolean) => {
  const accessUseSign =
    hasGeneralAccess(userProfile, generalAccess.UseSign) || hasGeneralAccess(userProfile, generalAccess.UseFakeSign);

  return isAdmin || (accessUseSign && checkAccessSign(userProfile.vedomstvoId, isOIV));
};
