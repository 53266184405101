import { LmBadgeColors } from '@mes-ui/lemma/components/LmBadge/model';
import { RequestStatusEnum } from '../../mock-data/request-status-enum';
import { StatusColor } from '../../mock-data/status-color-enum';

type ColorObjectType = {
  color: LmBadgeColors;
};

export const getStatusColor = (value: string): ColorObjectType => {
  switch (value) {
    case StatusColor.success:
      return {
        color: 'green',
      };
    case StatusColor.pending:
      return {
        color: 'grape',
      };
    case StatusColor.reject:
      return {
        color: 'red',
      };
    case StatusColor.submitted:
      return {
        color: 'cian',
      };
    case StatusColor.suspend:
      return {
        color: 'orange',
      };
    case StatusColor.pendingOrNew:
      return {
        color: 'indigo',
      };
    default:
      return {
        color: 'grey',
      };
  }
};

export const getRequestStatusColor = (value?: number) => {
  let requestType: string;

  switch (value) {
    case RequestStatusEnum.Included:
      requestType = StatusColor.success;
      break;
    case RequestStatusEnum.DogmForInclude:
    case RequestStatusEnum.WaitForElectronicAccept:
      requestType = StatusColor.pending;
      break;
    case RequestStatusEnum.Excluded:
    case RequestStatusEnum.IncludeDeclined:
    case RequestStatusEnum.ApplicantRefused:
      requestType = StatusColor.reject;
      break;
    case RequestStatusEnum.DocumentAccepted:
    case RequestStatusEnum.TestCompleted:
    case RequestStatusEnum.AcceptedElectronically:
    case RequestStatusEnum.NeedTest:
    case RequestStatusEnum.ForInclude:
      requestType = StatusColor.submitted;
      break;
    case RequestStatusEnum.Suspended:
      requestType = StatusColor.suspend;
      break;
    case RequestStatusEnum.New:
    case RequestStatusEnum.UnderConsideration:
      requestType = StatusColor.pendingOrNew;
      break;
    default:
      requestType = '';
  }

  return getStatusColor(requestType);
};

export const messageResponse = {
  success: 'Действие выполнено успешно!',
};
