export enum RequestSourceEnum {
  /// <summary> Портал mos.ru </summary>
  Mpgu = 1,
  /// <summary> Оператор </summary>
  Operator = 2,
  /// <summary> Пользователи ОСИП </summary>
  Osip = 3,
  /// <summary> ЭЖД </summary>
  EJD = 4,
}
