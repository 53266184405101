import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LmBadgeStatus, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistory from '../../../components/change-history';
import { routes } from '../../../config/constants';
import Favorite from '../../../components/favorite';
import ServiceProgramDetails from './details';
import { ServiceContext } from '..';
import { getHeaderLink } from '../utils';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import { generateLink, goBack } from '../../../lib/utils';
import ToArchiveModal from '../components/modals/to-archive';
import RecoverModal from '../components/modals/recover';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import ErrorArchiveModal from '../components/modals/error-archive';
import { ArchiveObstacle, NumberSeatsAdmissionPlan } from '../../../types/service';
import { CopyProgramModal } from '../components/modals/copy-program';
import { TypeFinancingEnum } from '../../../mock-data/type-financing-enum';
import { classificatorEKULimitApi } from '../../../lib/api/classificator-EKU-limit';
import { RestrictionsBudgetPlacesData } from '../../../types/classificator';

const ServiceProgram = () => {
  const {
    serviceData,
    educationTypes,
    type,
    accessPanelEdit,
    accessAddArchive,
    canRecover,
    numberSeatsAdmissionPlan,
    routeBack,
  } = useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalErrorArchive, setShowModalErrorArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);
  const [archiveObstacle, setArchiveObstacle] = useState<ArchiveObstacle>();
  const [loadCopy, setLoadCopy] = useState(false);

  const [showModalCopyProgram, setShowModalCopyProgram] = useState<boolean>(false);
  const [restrictionsBudgetPlaces, setRestrictionsBudgetPlaces] = useState<RestrictionsBudgetPlacesData[]>([]);

  const history = useHistory();

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);
  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;

  // список годов без повтора и суммой мест
  const noRepeatYearOfTraining = useMemo(() => {
    return numberSeatsAdmissionPlan.reduce((accum: NumberSeatsAdmissionPlan[], currentValue) => {
      const findYear = accum.find(({ yearOfTrainingId }) => currentValue.yearOfTrainingId === yearOfTrainingId);

      if (findYear) {
        findYear.volume = (findYear.volume || 0) + (currentValue.volume || 0);

        return [...accum];
      } else {
        return [
          ...accum,
          {
            ...currentValue,
          },
        ];
      }
    }, []);
  }, [numberSeatsAdmissionPlan]);

  // список Планы приема с количеством оставшихся мест
  const numberSeats = useMemo(() => {
    return numberSeatsAdmissionPlan.map((item) => {
      const currentYear = restrictionsBudgetPlaces.find((elem) => elem.yearOfTrainingId === item.yearOfTrainingId);

      if (currentYear?.limitVolume) {
        return {
          ...item,
          numberSeatsLeft: currentYear.limitVolume - (currentYear.volume || 0),
        };
      }

      return null;
    }, []);
  }, [numberSeatsAdmissionPlan, restrictionsBudgetPlaces]);

  // проверяем количество оставшихся мест по годам с суммой количество мест по годам (Планы приема)
  const checkPlace = useMemo(() => {
    return numberSeats.every((item) => {
      // текущий год
      const countNumberSeats =
        noRepeatYearOfTraining.find(({ yearOfTrainingId }) => item?.yearOfTrainingId === yearOfTrainingId)?.volume || 0;

      return !!item && item.numberSeatsLeft && item.numberSeatsLeft >= countNumberSeats;
    });
  }, [noRepeatYearOfTraining, numberSeats]);

  const handleCopyProgram = useCallback(() => {
    setLoadCopy(true);
    setTimeout(async () => {
      try {
        const serviceId = await serviceTemplateApi.copyService(serviceData.id);

        history.push(
          generateLink(routes.registerEducationProgram, {
            id: serviceId,
          })
        );
      } catch {}
      setLoadCopy(false);
    }, 500);
  }, [history, serviceData.id]);

  useEffect(() => {
    const fetch = async () => {
      const data: RestrictionsBudgetPlacesData[] = [];

      try {
        for (let i = 0; i < noRepeatYearOfTraining.length; i++) {
          const response = await classificatorEKULimitApi.getBudgetPlaces({
            organizationId: serviceData.info.organizationId,
            educationTypeId: serviceData.info.educationTypeId,
            yearOfTrainingId: noRepeatYearOfTraining[i].yearOfTrainingId,
            classificatorEKUId: serviceData.info.classificatorEKUId,
          });

          if (response.length) {
            data.push(response[0]);
          }
        }
      } catch (e) {}
      setRestrictionsBudgetPlaces(data);
    };

    fetch();
  }, [
    noRepeatYearOfTraining,
    numberSeatsAdmissionPlan,
    serviceData.info.classificatorEKUId,
    serviceData.info.educationTypeId,
    serviceData.info.organizationId,
  ]);

  const showCopyProgram = () => {
    const access = accessPanelEdit && serviceData.serviceStatusId === ServiceStatusEnum.Draft;

    if (serviceData.financing.typeFinancingId === TypeFinancingEnum.Free) {
      return access && checkPlace;
    } else {
      return access;
    }
  };

  const openRecoveryModal = () => {
    setShowModalRecover(true);
  };

  const redirectToServiceClasses = () => {
    history.push(
      generateLink(
        routes.serviceClasses,
        {},
        {
          serviceId: serviceData.id,
          showArchive: isArchive || undefined,
        }
      )
    );
  };

  const copyService = () => {
    serviceData.financing.typeFinancingId === TypeFinancingEnum.Free && numberSeatsAdmissionPlan.length
      ? setShowModalCopyProgram(true)
      : handleCopyProgram();
  };

  const handleArchive = async () => {
    const data = await serviceTemplateApi.getArchiveObstacle(serviceData.id);

    if (data.pupilList.length || data.requestList.length) {
      setShowModalErrorArchive(true);
      setArchiveObstacle(data);
    } else {
      setShowModalArchive(true);
    }
  };

  const handlePrint = () => {
    window.open(
      generateLink(routes.printService, {
        id: serviceData.id,
      }),
      '_blank'
    );
  };
  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (isArchive && canRecover) {
      buttons.push({
        dataTest: 'restore',
        icon: 'filled-arrows-upload',
        buttonText: 'Восстановить',
        rounded: 'small',
        variant: 'secondary',
        onClick: openRecoveryModal,
      });
    }
    buttons.push({
      dataTest: 'goToGroup',
      icon: 'filled-arrows-arrow-right',
      buttonText: 'Перейти к группам обучения',
      rounded: 'small',
      variant: 'secondary',
      onClick: redirectToServiceClasses,
    });

    if (!isArchive) {
      if (showCopyProgram()) {
        buttons.push({
          dataTest: 'createCopy',
          icon: 'outline-edit-copy',
          buttonText: 'Создать копию программы',
          rounded: 'small',
          variant: 'secondary',
          loading: loadCopy,
          onClick: copyService,
        });
      }
      if (checkAccessAddArchive) {
        buttons.push({
          dataTest: 'toArchive',
          icon: 'filled-hardware-hard-drive',
          buttonText: 'В архив',
          rounded: 'small',
          variant: 'secondary',
          onClick: handleArchive,
        });
      }
      buttons.push({
        dataTest: 'print',
        icon: 'filled-hardware-printer',
        buttonText: 'Печать',
        rounded: 'small',
        variant: 'secondary',
        onClick: handlePrint,
      });
    }

    return buttons;
  };

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={() => goBack(routeBack)}
        title="Образовательная программа"
        dataTest="servicePreparationSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Образовательные программы',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          {
            label: 'Описание образовательной программы',
          },
        ]}
        description={
          <div className="flex justify-end">
            {serviceData?.serviceStatusId === ServiceStatusEnum.Arhive ? (
              <LmBadgeStatus
                dataTest="serviceStatus"
                color="grey"
              >
                Архивная
              </LmBadgeStatus>
            ) : (
              <LmBadgeStatus
                dataTest="serviceStatus"
                color={serviceData?.serviceStatusId === ServiceStatusEnum.Signed ? 'green' : 'orange'}
              >
                {serviceData?.serviceStatusId === ServiceStatusEnum.Signed
                  ? 'Опубликовано на Mos.ru'
                  : 'Не опубликовано на Mos.ru'}
              </LmBadgeStatus>
            )}
          </div>
        }
        tabs={[
          {
            title: 'Основные сведения',
            value: 1,
            dataTest: 'basicInfo',
          },
          {
            title: 'История изменений',
            value: 2,
            dataTest: 'changeHistory',
          },
        ]}
        className="content-panel"
        panels={[
          {
            children: <ServiceProgramDetails />,
            value: 1,
          },
          {
            children: (
              <ChangeHistory
                name=""
                entityTypes={entityTypesForHistory.services}
                extendEntityGuid={serviceData.extendEntityGuid}
              />
            ),
            value: 2,
            className: 'content-panel content-panel--auto',
            noPadding: true,
          },
        ]}
        buttonsOrAnyActions={getButtons()}
        titleChildren={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      <ToArchiveModal
        onCloseHandler={() => setShowModalArchive(false)}
        show={showModalArchive}
      />
      <ErrorArchiveModal
        onCloseHandler={() => setShowModalErrorArchive(false)}
        show={showModalErrorArchive}
        data={archiveObstacle}
      />
      <RecoverModal
        onCloseHandler={() => setShowModalRecover(false)}
        show={showModalRecover}
      />
      <CopyProgramModal
        id={serviceData?.id}
        handleCopyProgram={handleCopyProgram}
        show={showModalCopyProgram}
        numberSeats={numberSeats}
        onClose={() => setShowModalCopyProgram(false)}
      />
    </>
  );
};

export default ServiceProgram;
