import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '../../../../components/modals/dialog';
import { ServiceClassContext } from '../../service-class';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
  serviceClassCode: string;
};

const ConfirmDeletionModal = ({ show, onCloseHandler, serviceClassCode }: Props) => {
  const { isRemovedGroup, deleteServiceClass } = useContext(ServiceClassContext);

  const history = useHistory();

  const confirm = async () => {
    deleteServiceClass && (await deleteServiceClass());
    !isRemovedGroup && onCloseHandler();
    history.push(generateLink(routes.serviceClasses, {}));
  };

  return (
    <Dialog
      dataTest="deleteServiceClass"
      isOpenDialog={show}
      title="Подтвердите удаление группы обучения"
      description={
        <>
          Вы действительно хотите удалить группу обучения <b>{serviceClassCode} ?</b>
        </>
      }
      variant="alert"
      buttonSecondaryText="Нет"
      buttonPrimaryText="Да"
      primaryButtonModifiers={{
        loading: isRemovedGroup,
      }}
      onClickSeconadaryButton={onCloseHandler}
      onClickPrimaryButton={confirm}
    />
  );
};

export default ConfirmDeletionModal;
