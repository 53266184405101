import React, { useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { LmFilterVertical } from '@mes-ui/lemma';
import FormikSelect from '../../components/formik/formik-select';
import { entityTypeEnum } from '../../mock-data';
import { initialFavoriteFilterFormData } from '../../mock-data/favorites';
import { historyState } from '../../mock-data/history-state';
import FormikDatePicker from '../../components/formik/formik-datepicker';
import { SearchFavoriteFormData } from '../../types/favorite';
import { getCountDiff, replaceHistoryState } from '../../lib/utils';

type Props = {
  submitForm: (values: SearchFavoriteFormData) => void;
  initialForm: SearchFavoriteFormData;
};

const FavoriteSearch = ({ submitForm, initialForm }: Props) => {
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);

  const handleClickAdvancedSearch = (isOpen: boolean) => {
    replaceHistoryState({
      [historyState.openAdvanced]: isOpen,
    });
    setOpen(isOpen);
  };

  const handleSubmit = (values: SearchFavoriteFormData, formikHelpers: FormikHelpers<SearchFavoriteFormData>) => {
    submitForm({
      ...values,
      startDate: values.date && values.date[0],
      endDate: values.date && values.date[1],
    });
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialForm}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue, resetForm, isSubmitting }: FormikProps<SearchFavoriteFormData>) => {
        const countDiff = getCountDiff(values, {
          ...initialFavoriteFilterFormData,
          startDate: values.startDate,
          endDate: values.endDate,
        });

        return (
          <form onSubmit={handleSubmit}>
            <LmFilterVertical
              dataTest="favoriteFilter"
              classNameContent="filter-content"
              open={open}
              searchValue={values.query}
              searchPlaceholder="Поиск по избранному..."
              onChange={(value) => setFieldValue('query', value)}
              toggleOpen={handleClickAdvancedSearch}
              onClear={() => {
                resetForm({
                  values: initialFavoriteFilterFormData,
                });
                submitForm(initialFavoriteFilterFormData);
              }}
              primaryButtonModifiers={{
                loading: isSubmitting,
                type: 'submit',
              }}
              buttonSecondaryText={countDiff ? `Сбросить фильтры (${countDiff})` : 'Сбросить фильтры'}
            >
              <>
                <FormikSelect
                  label="Тип объекта"
                  name="entityTypeId"
                  size="small"
                  withSearch
                  options={entityTypeEnum}
                />
                <FormikDatePicker
                  label="Дата добавления в избранное"
                  isIntervalDate
                  name="date"
                  size="small"
                />
              </>
            </LmFilterVertical>
          </form>
        );
      }}
    </Formik>
  );
};

export default FavoriteSearch;
