import React, { Component, ErrorInfo } from 'react';
import UnknownError from '../containers/errors/unknown';

type State = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

export function withErrorBoundary<P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) {
  return class extends Component<P> {
    public state: State = {
      error: undefined,
      errorInfo: undefined,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      return this.catchFunc(error, errorInfo);
    }

    catchFunc = (error: Error, errorInfo: ErrorInfo) => {
      // catch errors in any components below and re-render with error message
      this.setState({
        error,
        errorInfo,
      });
      console.error(error, errorInfo);
    };

    render() {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.error) {
        return <UnknownError />;
      }

      // Normally, just render children
      return <WrappedComponent {...this.props} />;
    }
  };
}
