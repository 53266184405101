import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmButton, LmInput, LmIcon, LmCheckbox, LmListItem, LmSelectNew } from '@mes-ui/lemma';
import TableOptions from '../../../../components/table-options';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import DropDown from '../../../../components/drop-down';
import { DisciplineData } from '../../../../types/service';
import lookupApi from '../../../../lib/api/lookup';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceContext } from '../../index';
import RemoveModal from '../../../../components/remove-modal';
import SimpleTable from '../../../../components/table/simple-table';
import { SelectOptionType } from '../../../../types/entities';

const Disciplines = () => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [optionsState, setOptionsState] = useState<(DisciplineData & { number?: number })[]>([]);
  const [editObj, setEditObj] = useState<(DisciplineData & { number?: number }) | null>(null);
  const [removedId, setRemovedId] = useState(0);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const updateByClassificator = async () => {
    await serviceTemplateApi.updateByClassifier(serviceData.id);
    updateService();
  };

  useEffect(() => {
    setOptionsState(
      serviceData?.serviceDisciplines?.map((item, index) => ({
        ...item,
        number: index + 1,
      })) ?? []
    );
  }, [serviceData?.serviceDisciplines]);

  const handleCheck = useCallback(async () => {
    let id = editObj?.id;

    if (editObj?.id) {
      await serviceTemplateApi.updateDiscipline(serviceData.id, serviceData.educationTypeId, editObj);
    } else if (editObj) {
      id = await serviceTemplateApi.createDiscipline(serviceData.id, serviceData.educationTypeId, editObj);
    }
    setOptionsState((prev) => {
      if (editObj?.number) {
        prev[editObj.number - 1] = {
          ...editObj,
          id,
        };
      }

      return [...prev];
    });
    setEditObj(null);
    updateService();
  }, [editObj, serviceData.educationTypeId, serviceData.id, updateService]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'number',
        title: '№',
        render: (item: any) => (
          <div className={editObj?.number === item.number ? 'table-row-item-height flex items-center' : ''}>
            {item.number}
          </div>
        ),
        width: '40px',
      },
      {
        dataIndex: 'disciplineName',
        title: 'Наименование дисциплины',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <LmSelectNew
              dataTest="selectDisciplineId"
              name="disciplineId"
              size="small"
              options={
                editObj?.disciplineId && editObj?.disciplineName
                  ? [
                      {
                        label: editObj.disciplineName,
                        value: editObj.disciplineId,
                      },
                    ]
                  : []
              }
              value={
                editObj?.disciplineId
                  ? ({
                      value: editObj?.disciplineId,
                      label: editObj?.disciplineName,
                    } as SelectOptionType)
                  : null
              }
              withSearch
              getFilterOptions={(query) => lookupApi.getDisciplines(query)}
              onChange={(selectedOption: SelectOptionType | null) => {
                const option = selectedOption
                  ? (selectedOption as SelectOptionType)
                  : {
                      value: undefined,
                      label: undefined,
                    };

                setEditObj((prev) => {
                  if (option.value && typeof option.value === 'string') {
                    return {
                      ...prev,
                      disciplineId: parseInt(option.value),
                      disciplineName: option.label,
                    };
                  }
                  if (option.value && typeof option.value === 'number') {
                    return {
                      ...prev,
                      disciplineId: option.value,
                      disciplineName: option.label,
                    };
                  }

                  return {
                    ...prev,
                    disciplineId: undefined,
                    disciplineName: undefined,
                  };
                });
              }}
              placeholder="Начните вводить..."
              grouped={false}
              multiple={false}
              settingDropdownAsPopover={{
                disablePortal: true,
              }}
            />
          ) : (
            item.disciplineName
          ),
        width: '33%',
      },
      {
        dataIndex: 'hours',
        title: 'Кол-во часов',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <LmInput
              dataTest="hours"
              name="hours"
              width={88}
              value={`${editObj?.hoursCount ?? ''}`}
              resettable
              onChange={(value) => {
                setEditObj((prev) => ({
                  ...prev,
                  hoursCount: parseInt(value as string) || 0,
                }));
              }}
            />
          ) : (
            item.hoursCount
          ),
        width: '33%',
      },
      {
        dataIndex: 'required',
        title: 'Обязательная',
        render: (item: any) => (
          <div className="flex">
            <div className="flex-auto">
              {editObj?.number === item.number ? (
                <div className="table-row-item-height flex items-center">
                  <LmCheckbox
                    checked={editObj?.isRequired || false}
                    onChange={(value) =>
                      setEditObj((prev) => ({
                        ...prev,
                        isRequired: value,
                      }))
                    }
                    name={item.number}
                    dataTest={item.number}
                    resetAllPadding
                  />
                </div>
              ) : item.isRequired ? (
                'Да'
              ) : (
                'Нет'
              )}
            </div>
            <div className="flex-none">
              {editObj?.number === item.number ? (
                <div className="flex">
                  <LmButton
                    dataTest="clearDiscipline"
                    type="button"
                    variant="secondary"
                    icon="filled-edit-close"
                    iconSize={20}
                    onClick={() => {
                      setEditObj(null);
                      if (!item.disciplineId) {
                        setOptionsState((prev) => prev.slice(0, -1));
                      }
                    }}
                  />
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  <LmButton
                    dataTest="submitDiscipline"
                    type="button"
                    color="success"
                    variant="outline"
                    icon="filled-edit-checkmark"
                    iconSize={20}
                    disabled={!(editObj?.disciplineId && editObj.hoursCount)}
                    onClick={handleCheck}
                  />
                </div>
              ) : (
                checkEditable && (
                  <DropDown
                    dataTest="disciplineOptions"
                    component={() => <TableOptions />}
                  >
                    <>
                      <LmListItem
                        dataTest="editDiscipline"
                        text="Редактировать"
                        icon="outline-edit-edit"
                        iconSize={20}
                        onClick={() => setEditObj(item)}
                      />
                      <LmListItem
                        dataTest="deleteDiscipline"
                        text="Удалить"
                        icon="outline-edit-trash-alt"
                        iconSize={20}
                        onClick={() => {
                          setRemovedId(item.id);
                        }}
                      />
                    </>
                  </DropDown>
                )
              )}
            </div>
          </div>
        ),
        width: '33%',
      },
    ],
    [editObj, checkEditable, handleCheck]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Дисциплины
            <span className="color-gray-dark">
              {' \u00A0'} {optionsState.length}
            </span>
          </>
        )}
        headingControl={() => {
          return (
            checkEditable && (
              <div className="flex">
                {serviceData?.id > 0 && (
                  <>
                    <LmButton
                      type="button"
                      variant="outline"
                      size="medium"
                      disabled={!!editObj}
                      onClick={updateByClassificator}
                    >
                      Обновить по РБНДО
                    </LmButton>
                    <Push
                      size={12}
                      orientation="horizontal"
                    />
                  </>
                )}
                <button
                  type="button"
                  onClick={() => {
                    setOptionsState((prev) => [
                      ...prev,
                      {
                        number: optionsState.length + 1,
                      },
                    ]);
                    setEditObj({
                      number: optionsState.length + 1,
                    });
                  }}
                  disabled={!!editObj}
                  className="icon-group"
                >
                  <span className="icon-group__icon">
                    <LmIcon
                      icon="filled-edit-plus"
                      size={20}
                      color="var(--LM-blue-200)"
                    />
                  </span>
                  <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
                </button>
              </div>
            )
          );
        }}
      >
        <SimpleTable
          data={optionsState}
          columns={columns}
        />
      </Panel>
      <RemoveModal
        title="Дисциплина"
        description="Внимание! Данный объект будет удален из списка дисциплин. Хотите продолжить?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={async () => {
          await serviceTemplateApi.deleteDiscipline(serviceData.id, removedId);
          setRemovedId(0);
          setOptionsState((prev) => prev.filter((item) => item.id !== removedId));
          updateService();
        }}
      />
    </>
  );
};

export default Disciplines;
