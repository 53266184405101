import React from 'react';
import Dialog from '../../../../components/modals/dialog';

type Props = {
  show: boolean;
  onClose: () => void;
  handleRemoval: () => void;
};

const RemovalFromPublicationModal = ({ show, onClose, handleRemoval }: Props) => {
  return (
    <Dialog
      dataTest="unpublishedService"
      isOpenDialog={show}
      title="Снятие с публикации"
      description="Внимание! Публикация на Mos.ru будет отменена. Продолжить?"
      variant="alert"
      buttonPrimaryText="Да, продолжить"
      onClickSeconadaryButton={onClose}
      onClickPrimaryButton={handleRemoval}
    />
  );
};

export default RemovalFromPublicationModal;
