import React, { ReactNode } from 'react';
import { LmList, LmPopover, LmPopoverProps, useAnchorElState } from '@mes-ui/lemma';

export type DropDownProps = Pick<LmPopoverProps, 'placement'> & {
  component: (open: boolean) => ReactNode;
  children: ReactNode;
  dataTest: string;
};

const DropDown: React.FC<DropDownProps> = ({ component, children, dataTest, placement }) => {
  // @ts-ignore
  const [currentEl, setCurrentEl, clearCurrentEl] = useAnchorElState(null);

  return (
    <>
      <div
        data-test={`${dataTest}-dropDownButton`}
        onClick={setCurrentEl}
      >
        {component(!!currentEl)}
      </div>
      <LmPopover
        dataTest={dataTest}
        open={!!currentEl}
        onClose={clearCurrentEl}
        anchorEl={currentEl}
        placement={placement || 'bottom-end'}
        padding="small"
        disablePortal
      >
        <LmList
          dataTest={dataTest}
          onClick={clearCurrentEl}
        >
          {children}
        </LmList>
      </LmPopover>
    </>
  );
};

export default DropDown;
