import React, { useMemo } from 'react';
import Content from './content';
import { TreeModalPopup } from '../../types/tree-popup';

export const TreeModalContext = React.createContext<TreeModalPopup>({} as TreeModalPopup);

const TreeModal: React.FC<TreeModalPopup> = (props) => {
  const { open } = props;

  const treeModalData: TreeModalPopup = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);

  return <TreeModalContext.Provider value={treeModalData}>{open ? <Content /> : null}</TreeModalContext.Provider>;
};

export default TreeModal;
