import { LmButton, LmIcon, LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import { Push } from '@mosru/esz_uikit';
import { useCallback, useEffect, useState } from 'react';
import Informer from '../../../components/informer';
import requestsApi from '../../../lib/api/requests';
import { openBlobFileNewTab } from '../../../lib/utils';
import { ScannedDocument } from '../../../types/requests';

type Props = {
  documents: ScannedDocument[];
  updateRequest: () => void;
  requestId: number;
  disabled: boolean;
};

const CopyDocuments = ({ documents, requestId, updateRequest, disabled }: Props) => {
  const [checksDetails, setChecksDetails] = useState(false);

  const [documentsState, setDocumentsState] = useState<(ScannedDocument & { disabled?: boolean })[]>([]);

  useEffect(() => {
    setDocumentsState(
      documents.map((document: ScannedDocument) => ({
        ...document,
        disabled: document.approved,
      }))
    );
  }, [documents]);

  const handleChange = useCallback((value: boolean, document: ScannedDocument) => {
    setDocumentsState((prev) =>
      prev.map((item) => {
        if (item.id === document.id) {
          item.approved = value;
        }

        return item;
      })
    );
  }, []);

  return (
    <>
      <Push size={12} />
      <Informer
        dataTest="scannedCopiesWarning"
        color="warning"
        title="Досланные скан-копии документов Заявителя"
        body={
          checksDetails ? (
            <div className="request-scan">
              {documentsState.map((document) => (
                <div
                  key={document.id}
                  className="request-scan__document"
                >
                  <button
                    id="btn-contract"
                    type="button"
                    className="icon-group"
                    onClick={async () => {
                      const response = await requestsApi.getScannedDocument(document.id);

                      if (response?.blob) {
                        openBlobFileNewTab(response.blob);
                      }
                    }}
                  >
                    <span className="icon-group__text font-weight-bold color-primary">{document.fileName}</span>
                  </button>
                  <LmSwitcher
                    dataTest="switcherDocument"
                    className="request-scan__switcher"
                    size="small"
                    onChange={(value) => handleChange(value, document)}
                    variant="gray"
                    value={!!document.approved}
                    disabled={disabled || document.disabled}
                  >
                    <LmSwitcherOption
                      dataTest="switcher-document-approved"
                      value
                    >
                      Принять
                    </LmSwitcherOption>
                    <LmSwitcherOption
                      dataTest="switcher-document-decline"
                      value={false}
                    >
                      Отклонить
                    </LmSwitcherOption>
                  </LmSwitcher>
                </div>
              ))}
            </div>
          ) : undefined
        }
        controls={
          <>
            <button
              data-test="collapsibleButton"
              type="button"
              onClick={() => setChecksDetails(!checksDetails)}
              className="informer__collapsible informer__collapsible--warning"
            >
              <span>{checksDetails ? 'Свернуть' : 'Подробнее'}</span>
              <span className="informer__collapsible-icon">
                {checksDetails ? (
                  <LmIcon
                    icon="filled-chevrons-small-up"
                    size={20}
                  />
                ) : (
                  <LmIcon
                    icon="filled-chevrons-small-down"
                    size={20}
                  />
                )}
              </span>
            </button>

            <LmButton
              dataTest="saveResult"
              type="button"
              color="warning"
              size="small"
              disabled={disabled}
              onClick={async () => {
                const declined = documentsState.filter((item) => !item.approved);

                if (declined.length > 0) {
                  await requestsApi.declineDocument(
                    requestId,
                    declined.map((item) => item.id)
                  );
                } else {
                  await requestsApi.acceptDocument(requestId);
                }

                updateRequest();
              }}
            >
              Сохранить результат
            </LmButton>
          </>
        }
      />
    </>
  );
};

export default CopyDocuments;
