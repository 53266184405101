import React, { useMemo } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import { ContractStatusEnum } from '../../../mock-data/contract-status-enum';
import { ContractRequestData } from '../../../types/requests';
import { formatDateAndTime } from '../../../lib/utils/date';
import SimpleTable from '../../../components/table/simple-table';
import requestsApi from '../../../lib/api/requests';
import { downloadBlobFile } from '../../../lib/utils';

type Props = {
  requestContract: ContractRequestData | undefined;
  needPaperContract: boolean;
};

const Contract: React.FC<Props> = ({ requestContract, needPaperContract }) => {
  const columns = useMemo(() => {
    const columnList = [
      {
        dataIndex: 'number',
        title: '№ договора',
        render: (item: any) =>
          item.contractNumber && (
            <button
              id="btn-contract"
              type="button"
              className="icon-group"
              onClick={async () => {
                const response = await requestsApi.getContract(item.id);

                if (response) {
                  downloadBlobFile(response.contentDisposition, response.blob);
                }
              }}
            >
              <span className="icon-group__text font-weight-bold color-primary">№{item.contractNumber}</span>
            </button>
          ),
      },
      {
        dataIndex: 'status',
        title: 'Статус',
        render: (item: any) => item.contractStatusName,
      },
    ];

    if (requestContract?.contractStatusId === ContractStatusEnum.Terminated) {
      columnList.push({
        dataIndex: 'contractDeclineReasonName',
        title: 'Сторона расторжения договора',
        render: (item: any) => item.contractDeclineReasonName,
      });
    }

    columnList.push({
      dataIndex: 'date',
      title: 'Дата',
      render: (item: any) =>
        item.contractStatusDate ? <span>от {formatDateAndTime(item.contractStatusDate)}</span> : '',
    });

    if (
      requestContract?.contractStatusId === ContractStatusEnum.Signed ||
      requestContract?.contractStatusId === ContractStatusEnum.Terminated
    ) {
      columnList.push({
        dataIndex: 'contractSigUrl',
        title: 'Электронная подпись',
        render: (item: any) => (
          <button
            id="btn-contract"
            type="button"
            className="icon-group"
            onClick={async () => {
              const response = await requestsApi.getSignContract(item.id);

              if (response) {
                downloadBlobFile(response.contentDisposition, response.blob);
              }
            }}
          >
            <span className="icon-group__text font-weight-bold color-primary">Посмотреть</span>
          </button>
        ),
      });
    }

    return columnList;
  }, [requestContract?.contractStatusId]);

  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация о договоре'}>
        {needPaperContract && (
          <div className="container">
            <LmInfoBox
              dataTest="paperViewWarning"
              className="infobox--full-width"
              variant="warning"
              description="Заявителю необходимо предоставить договор в бумажном виде"
              hidenFooter
            />
            <Push size={20} />
          </div>
        )}
        {requestContract ? (
          <SimpleTable
            data={[requestContract]}
            pageSize={20}
            columns={columns}
          />
        ) : (
          <div className="requests-no-data">Нет данных</div>
        )}
      </Panel>
    </>
  );
};

export default Contract;
