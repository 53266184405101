import { useField } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { LmIcon, LmTooltip } from '@mes-ui/lemma';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceClassContext } from '../../service-class';
import { serviceIdField } from './service';
import { SelectOptionType } from '../../../../types/entities';

type AddressFieldProps = FieldProps<string> &
  Record<string, any> & {
    explainText?: string;
    isCreatable?: boolean;
  };

export const addressFieldName = 'address';
export const addressFieldId = 'addressId';

const AddressField = ({
  label = 'Адрес',
  name,
  editMode,
  required,
  defaultValue = '—',
  disabled,
  explainText,
  isCreatable,
}: AddressFieldProps) => {
  const { serviceClassData } = useContext(ServiceClassContext);

  const fieldName = `${name}`;
  const fieldIdName = `${name}Id`;
  const [serviceField] = useField<number>(serviceIdField);

  const [addressData, setAddressData] = useState<SelectOptionType[]>([]);

  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldId, , helpersId] = useField<number>(fieldIdName);

  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      const data = await serviceTemplateApi.getPlaceServiceAddresses({
        serviceId,
      });

      setAddressData(data);
      if (isCreatable && data[0] && !fieldId.value) {
        helpersId.setValue(Number(data[0].value));
        helpersLabel.setValue(data[0].label);
      }
    };

    if (serviceField.value) {
      fetchTrainGroup(serviceField.value);
    } else {
      setAddressData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceField.value]);

  const iconInfo = (
    <span
      style={{
        marginRight: '10px',
      }}
    >
      <LmTooltip
        withArrow
        placement="bottom"
        content={
          <div
            style={{
              textAlign: 'center',
              width: '200px',
              fontSize: '12px',
              wordBreak: 'break-word',
            }}
          >
            Адрес более не используется организацией в качестве места предоставления услуги
          </div>
        }
      >
        <LmIcon
          icon="outline-notifications-info"
          size={18}
          color="var(--LM-neutrals-day-700)"
        />
      </LmTooltip>
    </span>
  );

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={fieldLabel.value}
      defaultValue={defaultValue}
      iconForValue={serviceClassData.isAddressNotFoundInService ? iconInfo : null}
    >
      <FormikSelect
        name={fieldIdName}
        size="small"
        withSearch
        options={addressData}
        defaultValue={
          fieldId.value
            ? {
                value: fieldId.value,
                label: fieldLabel.value,
              }
            : null
        }
        selectedValue={(selected?: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : undefined);
        }}
        placeholder="Выберите..."
        disabled={disabled}
        explainText={explainText}
      />
    </Field>
  );
};

export default AddressField;
