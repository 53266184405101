import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { BudgetPlacesData } from '../../../../types/service';

type BudgetPlacesFieldProps = {
  budgetPlaces?: BudgetPlacesData;
};

const BudgetPlacesField = ({ budgetPlaces }: BudgetPlacesFieldProps) => {
  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Бюджетных мест на текущий
        <br />
        год
      </div>
      <div className="table-data__body">
        <div className="flex">
          <div className="table-data__group">
            <div className="table-data__label">Занято</div>
            <div className="table-data__body">{budgetPlaces?.volume ?? '—'}</div>
          </div>
          <Push
            size={16}
            orientation="horizontal"
          />
          <div className="table-data__group">
            <div className="table-data__label">Всего</div>
            <div className="table-data__body">{budgetPlaces?.limitVolume ?? '—'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetPlacesField;
