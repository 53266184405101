import React from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import FormikInput from '../../../../../../components/formik/formik-input';
import FormikCheckbox from '../../../../../../components/formik/formik-checkbox';
import { Learner } from '../../../../../../types/learners';

type Props = {
  editMode: boolean;
  hasInContingent: boolean;
};

export const FIO = ({ editMode, hasInContingent }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        ФИО учащегося {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="table-data-grid-4">
            <FormikInput
              size="small"
              placeholder="Фамилия"
              name="lastName"
              showErrorImmediately
            />
            <FormikInput
              size="small"
              placeholder="Имя"
              name="firstName"
              showErrorImmediately
            />
            <FormikInput
              size="small"
              disabled={values.isMiddleName}
              placeholder="Отчество"
              name="middleName"
              showErrorImmediately
            />
            <div>
              <FormikCheckbox
                onChange={() => {
                  setTimeout(() => {
                    setFieldValue('middleName', '');
                  });
                }}
                showErrorImmediately
                label="Нет отчества"
                name="isMiddleName"
                boxSize="small"
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <span>
              {values.lastName} {values.firstName} {values.middleName}
            </span>
            {hasInContingent ? (
              <span className="color-success-dark flex">
                <LmIcon
                  icon="outline-edit-checkmark-circle"
                  size={18}
                  color="var(--LM-green-200)"
                />{' '}
                <Push
                  orientation="horizontal"
                  size={6}
                />{' '}
                Сведения найдены в Реестре контингента
              </span>
            ) : (
              <span className="color-danger-dark flex">
                <LmIcon
                  icon="outline-edit-close-circle"
                  size={18}
                  color="var(--LM-red-200)"
                />{' '}
                <Push
                  orientation="horizontal"
                  size={6}
                />{' '}
                Сведения в Реестре контингента отсутствуют
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
