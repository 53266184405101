import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { TrainingGroupRequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { EducationTypeEnum } from '../../../types/education-type';
import { getServiceEditLink } from '../../../lib/utils';

type Props = {
  trainingGroup: TrainingGroupRequestData | undefined;
  educationType: EducationTypeEnum;
};

const Info: React.FC<Props> = ({ trainingGroup, educationType }) => {
  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация об услуге'}>
        <div className="container">
          <div className="table-data">
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Наименование услуги</div>
              <div className="table-data__body">
                <Link
                  className="brand-link cursor-pointer"
                  to={getServiceEditLink(educationType, trainingGroup?.serviceId)}
                  target="_blank"
                >
                  {trainingGroup?.serviceName}
                </Link>
              </div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Организация</div>
              <div className="table-data__body">{trainingGroup?.organizationName}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Место приема документов</div>
              <div className="table-data__body">{trainingGroup?.itemsWorkAddress}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Преподаватель</div>
              <div className="table-data__body">{trainingGroup?.teacherName || '—'}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Период обучения плана приема</div>
              <div className="table-data__body">{trainingGroup?.yearOfTrainingName} учебный год</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Дата начала занятий</div>
              <div className="table-data__body">
                {trainingGroup?.educationStartDate ? formatDate(trainingGroup.educationStartDate) : ''}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Info;
