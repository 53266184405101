import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Push, SelectOptionType } from '@mosru/esz_uikit';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import SavePanel from '../../../../components/save-panel';
import Photo from '../../components/panels/photo';
import AddressClass from '../../components/panels/address-class';
import AddressDocuments from '../../components/panels/address-documents';
import PlanTemplate from '../../components/panels/plan';
import TemplateContactPerson from '../../components/panels/contact-person/template-contact-person';
import { routes } from '../../../../config/constants';
import { CreateServiceContext } from '../../create';
import { ServiceData } from '../../../../types/service';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import history from '../../../../history';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import TemplateInfo from '../panels/template-info';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { generateLink } from '../../../../lib/utils';
import { infoValidationSchema } from '../panels/info';
import { convertParallelInResponse, getHeaderLink, setGenderStartEndToBack } from '../../utils';
import CreateFinancing from './create-finansing';
import { financingValidationSchema } from '../panels/finansing';

const DayCareCreateDetails = () => {
  const { serviceData, currentEducationType, organizationEdit, educationTypes, type } =
    useContext(CreateServiceContext);
  const [load, setLoad] = useState<boolean>(false);

  const initialErrors = useInitialErrors(serviceData, getValidationSchema());

  const submitForm = async (values: ServiceData) => {
    setLoad(true);
    try {
      const payload: ServiceData = {
        ...values,
        info: {
          ...setGenderStartEndToBack(values.info),
          educationTypeId: currentEducationType,
          parallelList: convertParallelInResponse(values.info.parallelList as SelectOptionType[]),
        },
        contactPerson: values.contactPerson && {
          ...values.contactPerson,
          phone: addPhonePrefix(values.contactPerson.phone),
        },
      };
      const id = await serviceTemplateApi.createService(payload);

      history.push(
        generateLink(routes.dayCareProgram, {
          id,
        })
      );
    } finally {
      setLoad(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={serviceData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
      onSubmit={submitForm}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, submitForm, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <TemplateInfo
              editMode
              parent="info"
              isCreate
              organizationEdit={organizationEdit}
            />
            <TemplateContactPerson
              editMode
              parent="contactPerson"
            />
            <AddressClass editCount="many" />
            <Push size={12} />
            <CreateFinancing />
            <AddressDocuments />
            <PlanTemplate isCreate />
            <Photo />

            <SavePanel
              buttonPrimaryText="Создать программу"
              primaryButtonModifiers={{
                loading: load,
                disabled: !isValid,
              }}
              onClickSeconadaryButton={() =>
                history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))
              }
              onClickPrimaryButton={submitForm}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default DayCareCreateDetails;

const getValidationSchema = () => {
  return objectYup().shape({
    contactPerson: contactPersonValidationSchema(),
    info: infoValidationSchema(),
    financing: financingValidationSchema,
  });
};
