import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmToggle } from '@mes-ui/lemma';
import FormikSelect from '../../../../../../components/formik/formik-select';
import lookupApi from '../../../../../../lib/api/lookup';
import { checkAddress } from '../utils';
import { Learner } from '../../../../../../types/learners';
import { SelectOptionType } from '../../../../../../types/entities';

type Props = {
  editMode: boolean;
  addressMatches: boolean;
  currentAddressResidence: SelectOptionType | undefined;
  currentAddressRegistration: SelectOptionType | undefined;
  setAddressMatches: (value: boolean) => void;
  setCurrentAddressResidence: (option?: SelectOptionType) => void;
};

export const FactAddress = ({
  editMode,
  addressMatches,
  setAddressMatches,
  currentAddressResidence,
  currentAddressRegistration,
  setCurrentAddressResidence,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const waitCurrentAddress = async (value: string) => {
    await new Promise((resolve) => setTimeout(resolve, 500));

    return [
      {
        value: null,
        label: value,
      },
    ];
  };

  useEffect(() => {
    if (addressMatches && values.regCity === 'other' && !values.regAddress) {
      setCurrentAddressResidence(undefined);
    }
  }, [addressMatches, setCurrentAddressResidence, values.regAddress, values.regCity]);

  useEffect(() => {
    if (addressMatches && editMode) {
      setFieldValue('factAddress', undefined);
    }
  }, [addressMatches, editMode, setFieldValue]);

  return (
    <div className="table-data__item table-data__group table-data__group--center">
      <div className="table-data__label table-data__label--main">Адрес проживания</div>
      <div className="table-data__body">
        {editMode ? (
          <>
            <div className="flex items-center">
              <LmToggle
                checked={addressMatches}
                label="Совпадает с адресом регистрации"
                name="addressMatches"
                dataTest="addressMatches"
                onChange={(checked) => {
                  if (checked) {
                    setAddressMatches(true);
                    if (values.regCity === 'other') {
                      if (values.regAddress) {
                        setCurrentAddressResidence({
                          label: values.regAddress,
                          value: null,
                        });
                      }
                    } else {
                      setCurrentAddressResidence(currentAddressRegistration);
                    }
                  } else {
                    setAddressMatches(false);
                    setCurrentAddressResidence(undefined);
                  }
                }}
              />
            </div>
            {!addressMatches && (
              <>
                <Push size={10} />
                <FormikSelect
                  size="small"
                  withSearch
                  options={[]}
                  name="factAddressId"
                  selectedValue={(option?) => {
                    setFieldValue('factAddress', option?.label);
                    setCurrentAddressResidence(option);
                  }}
                  loadOptions={async (query) => {
                    const res = await lookupApi.getAddress(query);

                    return res.length ? res : waitCurrentAddress(query);
                  }}
                  defaultValue={values.factAddress ? currentAddressResidence : null}
                  placeholder="Укажите адрес проживания"
                />
                <Push size={10} />
              </>
            )}
          </>
        ) : (
          checkAddress(values.factAddress ?? '', '')
        )}
      </div>
    </div>
  );
};
