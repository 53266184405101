import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmButton, LmInput, LmIcon, LmCheckbox, LmListItem, LmSelectNew } from '@mes-ui/lemma';
import DropDown from '../../../../components/drop-down';
import TableOptions from '../../../../components/table-options';
import { DisciplineData, ServiceData } from '../../../../types/service';
import lookupApi from '../../../../lib/api/lookup';
import SimpleTable from '../../../../components/table/simple-table';
import { SelectOptionType } from '../../../../types/entities';

type Discipline = (DisciplineData & { number?: number }) | null;

const initialData: Discipline = {
  number: undefined,
  id: undefined,
  disciplineId: undefined,
  disciplineName: undefined,
  isRequired: undefined,
  hoursCount: undefined,
};

const CreateDisciplines = () => {
  const { values, setFieldValue } = useFormikContext<ServiceData>();
  const [editObj, setEditObj] = useState<Discipline>(null);

  useEffect(() => {
    if (!values.serviceDisciplines) {
      setFieldValue('serviceDisciplines', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.serviceDisciplines]);

  const handleCheck = useCallback(async () => {
    const updatedDisciplines = values.serviceDisciplines.map((el: Discipline) =>
      el?.number === editObj?.number
        ? {
            ...editObj,
            educationTypeId: values.info?.educationTypeId,
            serviceId: 0,
          }
        : el
    );

    setEditObj(null);
    setFieldValue('serviceDisciplines', updatedDisciplines);
  }, [editObj, setFieldValue, values.info?.educationTypeId, values.serviceDisciplines]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'number',
        title: '№',
        render: (item: any) => (
          <div className={editObj?.number === item.number ? 'table-row-item-height flex items-center' : ''}>
            {item.number}
          </div>
        ),
        width: '40px',
      },
      {
        dataIndex: 'disciplineName',
        title: 'Наименование дисциплины',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <LmSelectNew
              dataTest="selectDisciplineId"
              name="disciplineId"
              size="small"
              options={
                editObj?.disciplineId && editObj?.disciplineName
                  ? [
                      {
                        label: editObj.disciplineName,
                        value: editObj.disciplineId,
                      },
                    ]
                  : []
              }
              value={
                editObj?.disciplineId
                  ? ({
                      value: editObj?.disciplineId,
                      label: editObj?.disciplineName,
                    } as SelectOptionType)
                  : null
              }
              withSearch
              getFilterOptions={(query) => lookupApi.getDisciplines(query)}
              onChange={(selectedOption: SelectOptionType | null) => {
                const option = selectedOption
                  ? (selectedOption as SelectOptionType)
                  : {
                      value: undefined,
                      label: undefined,
                    };

                setEditObj((prev) => {
                  if (option.value && typeof option.value === 'string') {
                    return {
                      ...prev,
                      disciplineId: parseInt(option.value),
                      disciplineName: option.label,
                    };
                  }
                  if (option.value && typeof option.value === 'number') {
                    return {
                      ...prev,
                      disciplineId: option.value,
                      disciplineName: option.label,
                    };
                  }

                  return {
                    ...prev,
                    disciplineId: undefined,
                    disciplineName: undefined,
                  };
                });
              }}
              placeholder="Начните вводить..."
              grouped={false}
              multiple={false}
              settingDropdownAsPopover={{
                disablePortal: true,
              }}
            />
          ) : (
            item.disciplineName
          ),
        width: '33%',
      },
      {
        dataIndex: 'hours',
        title: 'Кол-во часов',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <LmInput
              dataTest="hours"
              name="hours"
              width={88}
              value={`${editObj?.hoursCount ?? ''}`}
              resettable
              onChange={(value) => {
                setEditObj((prev) => ({
                  ...prev,
                  hoursCount: parseInt(value as string) || 0,
                }));
              }}
            />
          ) : (
            item.hoursCount
          ),
        width: '33%',
      },
      {
        dataIndex: 'required',
        title: 'Обязательная',
        render: (item: any) => (
          <div className="flex">
            <div className="flex-auto">
              {editObj?.number === item.number ? (
                <div className="table-row-item-height flex items-center">
                  <LmCheckbox
                    checked={editObj?.isRequired || false}
                    onChange={(value) =>
                      setEditObj((prev) => ({
                        ...prev,
                        isRequired: value,
                      }))
                    }
                    name={item.number}
                    dataTest={item.number}
                    resetAllPadding
                  />
                </div>
              ) : item.isRequired ? (
                'Да'
              ) : (
                'Нет'
              )}
            </div>
            <div className="flex-none">
              {editObj?.number === item.number ? (
                <div className="flex">
                  <LmButton
                    dataTest="clearDiscipline"
                    type="button"
                    variant="secondary"
                    icon="filled-edit-close"
                    iconSize={20}
                    onClick={() => {
                      setEditObj(null);
                      const clearDiscipline = values.serviceDisciplines.filter((item) => item.disciplineId);

                      setFieldValue('serviceDisciplines', clearDiscipline);
                    }}
                  />
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  <LmButton
                    dataTest="submitDiscipline"
                    type="button"
                    color="success"
                    variant="outline"
                    icon="filled-edit-checkmark"
                    iconSize={20}
                    disabled={!(editObj && editObj.disciplineName && editObj.hoursCount)}
                    onClick={handleCheck}
                  />
                </div>
              ) : (
                <DropDown
                  dataTest="disciplineOptions"
                  component={() => <TableOptions />}
                >
                  <>
                    <LmListItem
                      dataTest="editDiscipline"
                      text="Редактировать"
                      icon="outline-edit-edit"
                      iconSize={20}
                      onClick={() => setEditObj(item)}
                    />
                    <LmListItem
                      dataTest="deleteDiscipline"
                      text="Удалить"
                      icon="outline-edit-trash-alt"
                      iconSize={20}
                      onClick={() => {
                        setFieldValue(
                          'serviceDisciplines',
                          values.serviceDisciplines.filter((i: any) => item.number !== i.number)
                        );
                      }}
                    />
                  </>
                </DropDown>
              )}
            </div>
          </div>
        ),
        width: '33%',
      },
    ],
    [editObj, handleCheck, values.serviceDisciplines, setFieldValue]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Дисциплины
            <span className="color-gray-dark">
              {' \u00A0'} {values.serviceDisciplines?.length ?? 0}
            </span>
          </>
        )}
        headingControl={() => {
          return (
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  const number = (values.serviceDisciplines?.length || 0) + 1;
                  const data = {
                    ...initialData,
                    number,
                  };

                  setFieldValue('serviceDisciplines', [...values.serviceDisciplines, data]);
                  setEditObj(data);
                }}
                disabled={!!editObj}
                className="icon-group"
              >
                <span className="icon-group__icon">
                  <LmIcon
                    icon="filled-edit-plus"
                    size={20}
                    color="var(--LM-blue-200)"
                  />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
              </button>
            </div>
          );
        }}
      >
        <SimpleTable
          data={values.serviceDisciplines}
          columns={columns}
        />
      </Panel>
    </>
  );
};

export default CreateDisciplines;
