import React, { useEffect, useMemo, useState, Fragment, useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, array as arrayYup, number, ValidationError } from 'yup';
import moment from 'moment';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInfoBox, LmTooltip } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../../components/formik/formik-input-number';
import Popup from '../../../../../components/modals/popup';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { classificatorEKULimitApi } from '../../../../../lib/api/classificator-EKU-limit';
import { checkPrevYearOfTraining } from '../../../../../lib/utils/year-of-training';
import { TypeFinancingEnum } from '../../../../../mock-data/type-financing-enum';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import DateField from '../../fields/add-plan/date';
import TimeField from '../../fields/add-plan/time';
import { ScheduleData, ServiceData, TrainingGroupData, TrainingStageData } from '../../../../../types/service';
import { validationCheckDate } from '../../../../../lib/utils/validation';
import { calcLearnEnd } from '../../../../../lib/utils/service';
import Separator from './separator';
import FormikCheckbox from '../../../../../components/formik/formik-checkbox';

type Props = {
  show: boolean;
  planData: TrainingGroupData | undefined;
  serviceData: ServiceData;
  onClose: () => void;
  postSubmit: (plan?: TrainingGroupData) => void;
};

type YearOfTrainingList = Pick<SelectOptionType, 'label' | 'value'> & {
  limit: number;
};

const PreparationPlanModal: React.FC<Props> = ({ show, planData, serviceData, onClose, postSubmit }) => {
  const [initialValues, setInitialValues] = useState<TrainingGroupData>({} as TrainingGroupData);
  const [periodOptions, setPeriodOptions] = useState<SelectOptionType[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>();
  const [limitVolume, setLimitVolume] = useState(0);
  const [yearList, setYearList] = useState<YearOfTrainingList[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const initialErrors = useInitialErrors(initialValues, getValidationSchema(serviceData, limitVolume, selectedYear));

  const modalTitle = `${planData?.id ? 'Изменить' : 'Добавить'} план приема`;
  const submitLabel = planData?.id ? 'Сохранить' : 'Добавить';

  const showErrorImmediately = (planData?.id ?? 0) > 0;

  const hasLimitedVolume =
    !!serviceData.financing && serviceData.financing.typeFinancingId !== TypeFinancingEnum.Payment;
  const hasFreeVolume = serviceData.financing?.typeFinancingId === TypeFinancingEnum.Free;
  const hasRequest =
    !!initialValues && initialValues.scheduleList?.length > 0 && initialValues.scheduleList[0].requestTotalCount > 0;

  const getYearOfTrainingsList = useCallback(async () => {
    const getCurrentYearOfTraining = async () => {
      try {
        return await dictionariesApi.getCurrentYearOfTrainings();
      } catch (e) {
        return undefined;
      }
    };

    try {
      setLoadingData(true);
      const currentYear = await getCurrentYearOfTraining();
      const data = await classificatorEKULimitApi.getBudgetPlaces({
        educationTypeId: serviceData.educationTypeId,
        organizationId: serviceData.info.organizationId,
        classificatorEKUId: serviceData.info.classificatorEKUId,
      });

      const filterData = planData
        ? data
        : data.filter(
          ({ yearOfTrainingName }) =>
            !currentYear?.name ||
              (!!yearOfTrainingName && !checkPrevYearOfTraining(yearOfTrainingName, currentYear.name))
        );

      setYearList(
        filterData.map((item) => ({
          label: item.yearOfTrainingName || '',
          value: item.yearOfTrainingId,
          limit: item.limitVolume ? item.limitVolume - (item.volume ?? 0) : 0,
        }))
      );
    } catch (error) {
      setYearList([]);
    } finally {
      setLoadingData(false);
    }
  }, [serviceData, planData]);

  const submitTrainingGroup = async (plan: TrainingGroupData | undefined) => {
    if (plan) {
      if (plan.volume && plan.volume > 9999999999) {
        plan.volume = undefined;
      }

      if (!plan.id || plan.id === 0) {
        if (plan.scheduleList) {
          plan.scheduleList[0].trainingGroupId = 0;
        }

        await serviceTemplateApi.createTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      } else {
        await serviceTemplateApi.updateTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      }
    }
    postSubmit(plan);
  };

  useEffect(() => {
    if (show) {
      getYearOfTrainingsList();
    } else {
      setSelectedYear(undefined);
      setLimitVolume(0);
      setYearList([]);
    }
  }, [show, getYearOfTrainingsList]);

  useEffect(() => {
    if (serviceData.stage?.list?.length > 0) {
      setPeriodOptions(
        serviceData.stage.list.map((s) => {
          return {
            label: s.name,
            value: s.id || 0,
          };
        })
      );
    }
  }, [serviceData.stage]);

  useEffect(() => {
    if (!loadingData) {
      if (planData) {
        setInitialValues({
          ...planData,
          volume:
            planData.volume === null && serviceData.financing.typeFinancingId !== TypeFinancingEnum.Payment
              ? 0
              : planData.volume,
          stageList:
            !planData.yearOfTrainingId &&
            (!planData.scheduleList?.length || !planData.scheduleList[0].requestTotalCount)
              ? planData.stageList.map((stage) => ({
                  ...stage,
                  dateStart: '',
                  dateEnd: '',
                }))
              : planData.stageList,
          yearOfTrainingId: planData.yearOfTrainingId || undefined,
        });
      } else {
        setInitialValues({
          scheduleList: [
            {
              requestStart: moment(new Date()).format('YYYY-MM-DDT00:00:00'),
              requestTimeStart: '08:00',
              requestTimeEnd: '22:00',
            } as ScheduleData,
          ],
        } as TrainingGroupData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData, loadingData]);

  useEffect(() => {
    if (yearList.length && initialValues.yearOfTrainingId) {
      const initialYear = yearList.find(({ value }) => value === initialValues.yearOfTrainingId);
      const year = initialYear?.label && initialYear.label.split('-')[0];
      const currentVolume = initialValues.volume || 0;

      setSelectedYear(year ? parseInt(year) : undefined);
      setLimitVolume(initialYear ? initialYear.limit + currentVolume : 0);
    }
  }, [initialValues.yearOfTrainingId, initialValues.volume, yearList]);

  const minVolumeValue = useMemo(() => {
    const min = initialValues?.scheduleList?.length > 0 ? initialValues.scheduleList[0].requestsPositiveCount : 1;

    return min > 0 ? min : 1;
  }, [initialValues]);

  const showMinimalVolumeTooltip = useMemo(
    () => !hasFreeVolume && (planData?.id ?? 0) > 0,
    [planData?.id, hasFreeVolume]
  );

  const handleSelectYear = (value?: SelectOptionType) => {
    const option = value as YearOfTrainingList;
    const year = option?.label && option.label.split('-')[0];
    const currentVolume = planData && option?.value === planData.yearOfTrainingId ? planData.volume || 0 : 0;

    setSelectedYear(year ? parseInt(year) : undefined);
    setLimitVolume(option ? option.limit + currentVolume : 0);
  };

  return (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema(serviceData, limitVolume, selectedYear)}
      onSubmit={submitTrainingGroup}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<TrainingGroupData>) => {
        const { handleSubmit, isSubmitting, isValid, values, setFieldValue, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="planPopup"
              open={show}
              title={modalTitle}
              loading={loadingData}
              size="large"
              buttonPrimaryText={submitLabel}
              primaryButtonModifiers={{
                disabled: loadingData || !isValid || isSubmitting,
              }}
              onClearData={resetForm}
              onClose={onClose}
              onSubmit={handleSubmit}
            >
              <>
                <FormikSelect
                  label="Учебный год"
                  required
                  showErrorImmediately={showErrorImmediately}
                  name="yearOfTrainingId"
                  size="small"
                  withSearch
                  options={yearList}
                  placeholder="Выберите..."
                  selectedValue={handleSelectYear}
                  disabled={!!planData}
                />
                {periodOptions?.map((period, periodIndex) => {
                  const stageOrder =
                    serviceData.stage.list.find(({ id }) => id === planData?.serviceStageId)?.orderNumber ?? 1;

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={periodIndex}>
                      <Push size={16} />
                      <FormikFormGroup
                        name=""
                        label={`Дата начала занятий / Учебный период ${
                          periodOptions.length > 1 ? periodIndex + 1 : ''
                        }`}
                        required
                      >
                        <div className="flex items-start">
                          <div
                            className="flex flex-auto"
                            style={{
                              width: '50%',
                            }}
                          >
                            <DateField
                              name={`stageList[${periodIndex}].dateStart`}
                              disabled={!values.yearOfTrainingId || (hasRequest && periodIndex < stageOrder)}
                              showErrorImmediately={showErrorImmediately}
                              placeholder={values.yearOfTrainingId ? 'ДД.ММ.ГГГГ' : 'Выберите учебный год'}
                            />
                          </div>
                          <Separator />
                          <div
                            className="flex flex-auto"
                            style={{
                              width: '50%',
                            }}
                          >
                            <DateField
                              name={`stageList[${periodIndex}].dateEnd`}
                              disabled={!values.yearOfTrainingId || (hasRequest && periodIndex < stageOrder - 1)}
                              showErrorImmediately={showErrorImmediately}
                              placeholder={values.yearOfTrainingId ? 'ДД.ММ.ГГГГ' : 'Выберите учебный год'}
                            />
                          </div>
                        </div>
                      </FormikFormGroup>
                    </Fragment>
                  );
                })}

                <Push size={16} />
                <FormikFormGroup
                  name=""
                  label="Количество мест"
                  required
                >
                  <div className="flex items-start">
                    <div
                      style={{
                        marginTop: `${hasFreeVolume ? '8px' : ''}`,
                      }}
                    >
                      <FormikInputNumber
                        name="volume"
                        disabled={values.volume === null}
                        placeholder="0"
                        showErrorImmediately={showErrorImmediately}
                      />
                    </div>

                    {showMinimalVolumeTooltip && values.volume !== null && (
                      <>
                        <Push
                          size={8}
                          orientation="horizontal"
                        />
                        <div className="flex items-center flex-none table-row-item-height">
                          <LmTooltip
                            withArrow
                            placement="bottom"
                            content={<div className="text-center">Минимальное количество мест — {minVolumeValue}.</div>}
                          >
                            <LmIcon
                              icon="outline-notifications-info"
                              size={18}
                              color="var(--LM-neutrals-day-700)"
                            />
                          </LmTooltip>
                        </div>
                      </>
                    )}

                    {hasFreeVolume && (
                      <>
                        <Push
                          size={24}
                          orientation="horizontal"
                        />
                        <div className="flex-auto">
                          <LmInfoBox
                            dataTest="budgetWarning"
                            className="infobox--full-width"
                            variant="warning"
                            description={`Бюджетных мест осталось ${limitVolume}`}
                            hidenFooter
                          />
                        </div>
                      </>
                    )}

                    {!hasLimitedVolume && (
                      <>
                        <Push
                          size={8}
                          orientation="horizontal"
                        />
                        <div className="flex items-center flex-none table-row-item-height">
                          <FormikCheckbox
                            label="Без ограничений"
                            name="restrictions"
                            checked={values.volume === null}
                            onChange={(c) => {
                              setFieldValue('volume', c ? null : planData?.volume ?? 1);
                            }}
                            disabled={initialValues.volume === null && hasRequest}
                            boxSize="small"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </FormikFormGroup>

                <Push size={16} />
                <FormikFormGroup
                  name=""
                  label="Период приема заявлений на Mos.ru"
                  required
                >
                  <div className="flex items-start">
                    <div
                      className="flex flex-auto"
                      style={{
                        width: '50%',
                      }}
                    >
                      <DateField
                        name="scheduleList[0].requestStart"
                        dependentTimeName="scheduleList[0].requestTimeStart"
                        showErrorImmediately={showErrorImmediately}
                        placement="top-start"
                      />
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <div className="flex-none input-time">
                        <TimeField
                          name="scheduleList[0].requestTimeStart"
                          dependentDateName="scheduleList[0].requestStart"
                        />
                      </div>
                    </div>
                    <Separator />
                    <div
                      className="flex flex-auto"
                      style={{
                        width: '50%',
                      }}
                    >
                      <DateField
                        name="scheduleList[0].requestEnd"
                        dependentTimeName="scheduleList[0].requestTimeEnd"
                        showErrorImmediately={showErrorImmediately}
                        placement="top-start"
                      />
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <div className="flex-none input-time">
                        <TimeField
                          name="scheduleList[0].requestTimeEnd"
                          dependentDateName="scheduleList[0].requestEnd"
                        />
                      </div>
                    </div>
                  </div>
                </FormikFormGroup>
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default PreparationPlanModal;

const getValidationSchema = (serviceData: ServiceData, limitVolume: number, yearOfTraining?: number) => {
  const depsDate: [string, string] = ['dateStart', 'dateEnd'];
  const depsMosDate: [string, string] = ['requestStart', 'requestEnd'];
  const service = serviceData;
  const { stage } = serviceData;
  const hasLimitedVolume =
    !!serviceData.financing && serviceData.financing.typeFinancingId !== TypeFinancingEnum.Payment;

  const dateValidation = objectYup().shape(
    {
      dateStart: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'dateStart',
          end: 'dateEnd',
        },
        'Дата начала учебного периода не может быть больше даты окончания',
        'start'
      ),
      dateEnd: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'dateStart',
          end: 'dateEnd',
        },
        'Дата окончания учебного периода не может быть меньше даты начала',
        'end'
      ),
    },
    [depsDate]
  );

  const dateMosValidation = objectYup().shape(
    {
      requestStart: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'requestStart',
          end: 'requestEnd',
        },
        'Дата начала приема заявлений больше даты окончания приема заявлений',
        'start'
      ),
      requestEnd: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'requestStart',
          end: 'requestEnd',
        },
        'Дата окончания приема заявлений меньше даты начала приема заявлений',
        'end'
      ),
      requestTimeStart: stringYup().nullable().required('Введите время начала'),
      requestTimeEnd: stringYup().nullable().required('Введите время окончания'),
    },
    [depsMosDate]
  );

  const requiredYearOfTraining = (message?: string) =>
    number()
      .nullable()
      .test('required-year-of-training', message || '', (value) => !!value && yearOfTraining !== undefined);

  const validation = {
    yearOfTrainingId: number()
      .nullable()
      .when('scheduleList', {
        is: (value: ScheduleData[] | null) => {
          return !value || !value.length || !value[0].requestTotalCount;
        },
        then: () => {
          return requiredYearOfTraining('Выберите учебный год');
        },
        otherwise: () => {
          return requiredYearOfTraining();
        },
      }),
    volume: number()
      .nullable()
      .typeError('Введите количество мест')
      .test('volume', 'Введите количество мест', (value) => (hasLimitedVolume ? !!value : value === null || !!value))
      .test('volume', '', function (value) {
        const data = this.parent as TrainingGroupData;

        if (data?.scheduleList && data?.scheduleList[0]?.requestTotalCount > 0 && value) {
          return value >= data?.scheduleList[0]?.requestsPositiveCount;
        }

        return true;
      })
      .test('volume', 'Превышено количество мест', function (value) {
        return !hasLimitedVolume || (!!value && value <= limitVolume);
      }),
    stageList: arrayYup().when('yearOfTrainingId', {
      is: (value: number | null) => {
        return !!value;
      },
      then: () =>
        arrayYup()
          .test('check-date', '', function (value) {
            if (!value?.length || !this.parent.yearOfTrainingId || !yearOfTraining) {
              return true;
            }

            const errors: ValidationError[] = [];
            const minDateStart = new Date(yearOfTraining, 8, 1).getTime();
            const maxDateStart = new Date(yearOfTraining + 1, 7, 30).getTime();
            const maxDateEnd = new Date(yearOfTraining + 1, 7, 31).getTime();

            for (let i = 0; i < value.length; i++) {
              const dateStart = new Date(value[i].dateStart);
              const dateEnd = new Date(value[i].dateEnd);

              if (dateStart.getTime() < minDateStart || dateStart.getTime() > maxDateStart) {
                errors.push(
                  new ValidationError(
                    'Дата начала занятий должна быть в интервале с 1 сентября по 30 августа выбранного учебного года',
                    undefined,
                    `stageList[${i}].dateStart`
                  )
                );
              }

              if (dateEnd.getTime() > maxDateEnd) {
                errors.push(
                  new ValidationError(
                    'Дата окончания занятий не может быть больше 31 августа выбранного учебного года',
                    undefined,
                    `stageList[${i}].dateEnd`
                  )
                );
              }
            }

            return errors.length > 0 ? new ValidationError(errors) : true;
          })
          .test('TrainingStageData[]', 'ошибка', function (values) {
            const list = values as TrainingStageData[];
            const errors: ValidationError[] = [];

            if (list && list.length > 1) {
              for (let i = 0; i < list.length; i++) {
                if (list[i]?.dateStart) {
                  if (i > 0 && list[i - 1].dateEnd && new Date(list[i].dateStart) < new Date(list[i - 1].dateEnd)) {
                    errors.push(
                      new ValidationError(
                        'Дата начала учебного периода не может быть меньше даты окончания предыдущего периода',
                        undefined,
                        `stageList[${i}].dateStart`
                      )
                    );
                  }
                  if (
                    i < list.length - 1 &&
                    i >= 0 &&
                    list[i + 1].dateStart &&
                    new Date(list[i].dateEnd) > new Date(list[i + 1].dateStart)
                  ) {
                    errors.push(
                      new ValidationError(
                        'Дата окончания учебного периода не может быть больше даты начала следующего периода',
                        undefined,
                        `stageList[${i}].dateEnd`
                      )
                    );
                  }
                }
              }
            }

            if (
              list?.length > 0 &&
              list[0] &&
              list[0].dateStart &&
              list[stage.list.length - 1] &&
              list[stage.list.length - 1].dateEnd
            ) {
              const learnEnd = calcLearnEnd(
                new Date(list[0].dateStart),
                undefined,
                service.info.durationOfTraining,
                service.info.durationOfTrainingMonths,
                service.info.durationOfTrainingWeeks,
                service.info.durationOfTrainingDays
              );

              if (learnEnd < new Date(list[stage.list.length - 1].dateEnd)) {
                errors.push(
                  new ValidationError(
                    'Дата окончания учебного периода выходит за рамки продолжительности программы обучения',
                    undefined,
                    `stageList[${list.length - 1}].dateEnd`
                  )
                );
              }
            }

            return errors.length > 0 ? new ValidationError(errors) : true;
          })
          .of(dateValidation)
          .required(''),
    }),
    scheduleList: arrayYup()
      .test(
        'dateEnd',
        'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
        function (value) {
          const tg = this.parent as TrainingGroupData;
          const schedule = value as ScheduleData[];

          if (tg?.stageList && tg.stageList.length > 0 && schedule && schedule[0].requestEnd) {
            if (tg.stageList[0].dateEnd) {
              const a = new Date(schedule[0].requestEnd);
              const b = new Date(tg.stageList[0].dateEnd);

              return b < a
                ? new ValidationError(
                  'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
                  undefined,
                  'scheduleList[0].requestEnd'
                )
                : true;
            }
          }

          return true;
        }
      )
      .of(dateMosValidation)
      .required(''),
  };

  return objectYup().shape(validation);
};
