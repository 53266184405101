import { LmSelectNewProps } from '@mes-ui/lemma';
import React from 'react';
import { useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';

type Props = Pick<LmSelectNewProps<SelectOptionType>, 'placeholder' | 'explainText'> & {
  disabled?: boolean;
  onChange?: (value?: SelectOptionType) => void;
  nameFieldId: string;
  nameFieldName: string;
  label: string;
  loadOptions: (value: string) => Promise<SelectOptionType[]>;
  option?: SelectOptionType[];
  showTooltip?: boolean;
  disabledPortalTooltip?: boolean;
};

const FieldAsyncSelect: React.FC<Props> = ({
  disabled,
  onChange,
  nameFieldId,
  nameFieldName,
  label,
  loadOptions,
  option,
  showTooltip,
  disabledPortalTooltip,
  ...rest
}) => {
  const [fieldId, , helpers] = useField(nameFieldId);
  const [fieldName, , helpersName] = useField(nameFieldName);

  return (
    <FormikSelect
      {...rest}
      name={nameFieldId}
      label={label}
      size="small"
      withSearch
      loadOptions={loadOptions}
      options={option || []}
      disabled={disabled}
      selectedValue={(option?: SelectOptionType) => {
        helpers.setValue(option?.value);
        helpersName.setValue(option?.label);
        onChange && onChange(option);
      }}
      defaultValue={
        fieldId.value
          ? {
              label: fieldName.value,
              value: fieldId.value,
            }
          : null
      }
      showTooltip={showTooltip}
      disabledPortalTooltip={disabledPortalTooltip}
    />
  );
};

export default FieldAsyncSelect;
