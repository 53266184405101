import React from 'react';
import { LmInfoBox } from '@mes-ui/lemma';

type Props = {
  isAdminEdit: boolean;
};

export const Status: React.FC<Props> = ({ isAdminEdit }) => {
  return !isAdminEdit ? (
    <LmInfoBox
      dataTest="personalIncorrectInfo"
      className="infobox--full-width"
      variant="information"
      description="В случае обнаружения ошибки в персональных данных обучающегося необходимо обратиться в СТП для внесения изменений"
      hidenFooter
    />
  ) : null;
};
