import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AppState } from '../types/state';
import { authApi } from '../../lib/api';
import tokenManager from '../../lib/token-manager';
import { routes } from '../../config/constants';
import history from '../../history';
import { LoginData } from '../../types/auth';
import { getRedirectUrlFromQuery } from '../../lib/utils';
import { moduleName as appModule, setLastRoute } from '../ducks/app';
import { initApp } from './init';

export const signIn = async (data: LoginData) => await authApi.signIn(data);

export const logout = () => async () => {
  await authApi.logout();
};

export const onSignIn =
  (token: string) => async (dispatch: ThunkDispatch<AppState, undefined, AnyAction>, getState: () => AppState) => {
    const { lastRoute } = getState()[appModule];
    let redirectUrl = getRedirectUrlFromQuery();

    if (lastRoute && lastRoute.activeUntil > Date.now() && lastRoute.path !== routes.login) {
      redirectUrl = lastRoute.path;
    }

    tokenManager.setToken(token);
    // Очищаем роут для редиректа
    dispatch(initApp());
    dispatch(setLastRoute());
    history.push(redirectUrl && redirectUrl !== window.location.pathname ? redirectUrl : routes.main);
  };

export const onSignInWithCookie =
  (token: string) => async (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => {
    tokenManager.setToken(token);
    dispatch(initApp());
    dispatch(setLastRoute());
  };
