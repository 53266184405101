import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LmLoader, LmNavigationItem, LmNavigationSidebar } from '@mes-ui/lemma';
import FooterWrapper from '../../components/footer/footer-wrapper';
import Header from '../../components/header/header';
import { aupdTokenFieldName, debugMode, routes } from '../../config/constants';
import tokenManager from '../../lib/token-manager';
import { getCookie, redirectToLogin } from '../../lib/utils';
import { convertToHeaderList, convertToNavigationList, selectNavigationItem } from '../../lib/utils/navigation';
import { routeList } from '../../mock-data/routes';
import { getAupdOrganizations } from '../../redux/ducks/aupd-organizations';
import { useGetMenuSettingsQuery } from '../../store/menu-settings';
import LoginLayoutError from '../errors/login-layout-error';
import ProtectedRoute from './protected';
import { AppState } from '../../redux/types/state';
import { aupdOrganizationsSelector, isAuthorizedSelector, userProfileSelector } from '../../redux/selectors';
import AccessError from '../errors/access';
import Room from '../room';
import { selectServiceItemCallback } from '../../lib/utils/service';
import { withErrorBoundary } from '../../hoc/error-boundary';

const Layout = () => {
  const [simplifiedVersion, setSimplifiedVersion] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [isErrorAuthAupd, setIsErrorAuthAupd] = useState(false);
  const [navigationList, setNavigationList] = useState<LmNavigationItem[]>([]);

  const location = useLocation();
  const dispatch = useDispatch();

  const { userProfile, isAuthorized, aupdOrganizations } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
    isAuthorized: isAuthorizedSelector(state),
    aupdOrganizations: aupdOrganizationsSelector(state),
  }));

  /* eslint-disable object-curly-newline */
  const { data: { data: menuSettings } = { data: undefined } } = useGetMenuSettingsQuery(undefined, {
    skip: !userProfile.id || !isAuthorized,
  });
  /* eslint-enable object-curly-newline */

  const aupdTokenCookie = getCookie(aupdTokenFieldName);

  const isVisibleNavigation = !isErrorAuthAupd && !isLoadingContent;

  const currentNavItem = navigationList.find(({ selected }) => selected);

  const hasViewContainer = !!document.getElementById('viewContainer');

  useEffect(() => {
    const fetch = async () => {
      const isTokenValid = await tokenManager.isTokenValid();

      if (!isAuthorized && !aupdTokenCookie && !isTokenValid) {
        // eslint-disable-next-line no-console
        debugMode && console.warn('redirected to login from src/containers/routes/layout.tsx');
        await redirectToLogin();
      }
    };

    fetch();
  }, [aupdTokenCookie, isAuthorized]);

  useEffect(() => {
    if (menuSettings && userProfile.id) {
      const list = convertToHeaderList(menuSettings, null, userProfile);

      setNavigationList(selectNavigationItem(list, location.pathname));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuSettings, userProfile]);

  useEffect(() => {
    setNavigationList((prev) => selectNavigationItem(prev, location.pathname));
  }, [location.pathname]);

  // открытое новое окно (подбор услуги при создании группы обучения ДОНМ)
  useEffect(() => {
    setSimplifiedVersion(!!selectServiceItemCallback);
  }, []);

  useEffect(() => {
    (async function () {
      if (!aupdOrganizations.length && aupdTokenCookie && !isErrorAuthAupd) {
        try {
          const organizations = await dispatch(getAupdOrganizations(aupdTokenCookie));

          if (!organizations.length) {
            setIsErrorAuthAupd(true);
          }
        } catch {
          setIsErrorAuthAupd(true);
        }
      }
    })();
  }, [aupdOrganizations, aupdTokenCookie, dispatch, isErrorAuthAupd]);

  const contentHolder = useMemo(
    () => (
      <div className={classNames('content-holder', hasViewContainer && 'content-holder--full')}>
        {userProfile.id ? (
          <Switch>
            {routeList.map((item) => (
              <ProtectedRoute
                exact
                key={`${item.id}`}
                path={item.route || item.link}
                component={item.component}
                checkAccess={item.checkAccess}
              />
            ))}
            <ProtectedRoute
              exact
              path={routes.room}
              component={Room}
            />
            <Route
              exact
              path={routes.accessDeny}
              component={AccessError}
            />
            <ProtectedRoute component={AccessError} />
          </Switch>
        ) : null}
      </div>
    ),
    [hasViewContainer, userProfile.id]
  );

  return (
    <>
      {!simplifiedVersion && (
        <Header
          userProfile={userProfile}
          navigationList={isVisibleNavigation ? navigationList : []}
          aupdTokenCookie={aupdTokenCookie}
          onChangeLoading={setIsLoadingContent}
          onChangeErrorAuth={setIsErrorAuthAupd}
        />
      )}
      <div className={classNames('layout', simplifiedVersion && 'layout--simple')}>
        {!simplifiedVersion ? (
          <>
            {currentNavItem && isVisibleNavigation && (
              <LmNavigationSidebar
                dataTest="navigationSidebar"
                title={currentNavItem.title}
                className="layout__sider"
                navigationList={convertToNavigationList(currentNavItem)}
              />
            )}
            <div
              className={classNames(
                'layout__content',
                (!currentNavItem || !isVisibleNavigation) && 'layout__content--full'
              )}
            >
              {isLoadingContent ? (
                <div className="loader-auto">
                  <LmLoader view="page" />
                </div>
              ) : isErrorAuthAupd ? (
                <LoginLayoutError />
              ) : (
                <>
                  {contentHolder}
                  {!hasViewContainer && <FooterWrapper />}
                </>
              )}
            </div>
          </>
        ) : (
          contentHolder
        )}
      </div>
    </>
  );
};

export default withErrorBoundary(Layout);
