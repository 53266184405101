import React, { useContext } from 'react';
import { LmInput } from '@mes-ui/lemma';
import { ServiceClassTableContext } from '../../utils';

const HeadingControl: React.FC = () => {
  const { setSearch, searchString, filterLearners } = useContext(ServiceClassTableContext);

  return (
    <div className="flex">
      <div
        className="flex"
        style={{
          width: 244,
        }}
      >
        <LmInput
          dataTest="filterLearners"
          name="search"
          value={searchString}
          searchButton="icon"
          resettable
          placeholder="Введите..."
          onChange={(value) => {
            setSearch(value as string);
          }}
          onSubmitSearch={filterLearners}
        />
      </div>
    </div>
  );
};

export default HeadingControl;
