import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { formatDate } from '../../../../../../lib/utils/date';
import { EMPTY_VALUE } from '../../../../../../mock-data/empty-value';
import { PersonRequestData } from '../../../../../../types/requests';
import { checkIfData } from '../../../../../../lib/utils';

type Props = {
  data?: PersonRequestData;
};

const Person = ({ data }: Props) => {
  const getPersonData = () => {
    if (data) {
      const {
        snils,
        dateOfBirth,
        documentTypeName,
        lastName,
        firstName,
        middleName,
        sexName,
        series,
        number,
        dateOfIssue,
      } = data;

      return {
        snils,
        dateOfBirth: dateOfBirth ? formatDate(dateOfBirth) : EMPTY_VALUE,
        documentTypeName,
        name: `${checkIfData(lastName)} ${checkIfData(firstName)} ${checkIfData(middleName)}`,
        gender: sexName ? sexName.slice(0, 3).toLowerCase() : EMPTY_VALUE,
        info: `${series || ''} ${number || EMPTY_VALUE} от ${dateOfIssue ? formatDate(dateOfIssue) : EMPTY_VALUE}`,
      };
    }
  };

  const person = getPersonData();

  return (
    <div className="requests-check-details__item">
      <div className="requests-check-details__inner requests-check-details__user">
        <div className="requests-check-details__grid">
          <div>
            <div className="icon-group">
              <div className="icon-group__icon">
                <LmIcon
                  icon="filled-account-person"
                  size={20}
                  color="var(--LM-neutrals-day-600)"
                />
              </div>
              <div className="icon-group__text font-weight-bold">{person?.name}</div>
            </div>
            <Push size={4} />
            <div className="color-gray-dark flex">
              <Push
                size={28}
                orientation="horizontal"
              />
              {person?.dateOfBirth}
              <Push
                size={10}
                orientation="horizontal"
              />
              {person?.gender}
            </div>
          </div>
          <div>
            <Push size={2} />
            {data?.documentTypeName}
            <Push size={4} />
            <div className="color-gray-dark">{person?.info}</div>
          </div>
          <div>
            <Push size={2} />
            СНИЛС
            <Push size={4} />
            <div className="color-gray-dark">{person?.snils ?? '—'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Person;
