import React, { useState } from 'react';
import ChangeHistorySearch from '../../../../components/change-history/search';
import ViewContainer from '../../../../components/view-container';
import { initialChangeHistoryFilterFormData } from '../../../../mock-data/change-history-filter';
import { entityTypesForHistory } from '../../../../mock-data/entity-type-enum';
import ChangeHistory from '../../../../components/change-history';
import Enrollments from '../../../../components/change-history/enrollments';
import Expulsions from '../../../../components/change-history/expulsions';
import TransfersGroup from '../../../../components/change-history/transfers-group';
import Recoveries from '../../../../components/change-history/recoveries';
import TransfersService from '../../../../components/change-history/transfers-service';
import TransfersStage from '../../../../components/change-history/transfers-stage';
import StartEndDateChange from '../../../../components/change-history/start-end-date-change';
import { HistoryFilterFormData } from '../../../../types/history';

type Props = {
  extendEntityGuid: string;
  peopleId: number;
};

const LearnerHistory = ({ extendEntityGuid, peopleId }: Props) => {
  const [searchData, setSearchData] = useState<HistoryFilterFormData>(initialChangeHistoryFilterFormData);

  return (
    <ViewContainer
      filterComponent={
        <ChangeHistorySearch
          searchData={searchData}
          onChangeSearch={setSearchData}
          entityTypes={entityTypesForHistory.learners}
        />
      }
    >
      <ChangeHistory
        name="сведения об обучающемся"
        extendEntityGuid={extendEntityGuid}
        entityTypes={entityTypesForHistory.learners}
        noFilter
        search={searchData}
      />
      <Enrollments
        type="learner"
        id={peopleId ?? 0}
      />
      <TransfersGroup
        type="learner"
        id={peopleId ?? 0}
      />
      <Expulsions
        type="learner"
        id={peopleId ?? 0}
      />
      <Recoveries
        type="learner"
        id={peopleId ?? 0}
      />
      <TransfersService
        educationType={1}
        type="learner"
        id={peopleId ?? 0}
      />
      <TransfersStage
        type="learner"
        id={peopleId ?? 0}
      />
      <StartEndDateChange
        type="learner"
        id={peopleId ?? 0}
      />
    </ViewContainer>
  );
};

export default LearnerHistory;
