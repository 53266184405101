import React from 'react';
import { Push } from '@mosru/esz_uikit';

const Separator = () => {
  return (
    <div className="flex items-center flex-none table-row-item-height">
      <Push
        size={8}
        orientation="horizontal"
      />
      —
      <Push
        size={8}
        orientation="horizontal"
      />
    </div>
  );
};

export default Separator;
