import React from 'react';
import { LmInfoBox } from '@mes-ui/lemma';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';

type Props = {
  contingentLinkTypeId: number;
};

export const StatusChild: React.FC<Props> = ({ contingentLinkTypeId }) => {
  const text = {
    found: 'Сведения найдены в Реестре Контингента',
    other:
      'Проверка в реестре контингента не проводилась.' +
      ' Сведения будут проверены, результаты проверки' +
      ' отображены после первого сохранения.',
  };

  return contingentLinkTypeId === ContingentLinkTypeEnum.Found ? (
    <LmInfoBox
      dataTest="infoFoundSuccess"
      className="infobox--full-width"
      variant="success"
      description={text.found}
      hidenFooter
    />
  ) : (
    <LmInfoBox
      dataTest="infoFoundWarning"
      className="infobox--full-width"
      variant="warning"
      description={text.other}
      hidenFooter
    />
  );
};
