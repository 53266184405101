import queryString, { StringifiableRecord } from 'query-string';
import { SelectOptionType } from '@mosru/esz_uikit';
import { aupdApiUrl, aupdTokenFieldName, debugMode, routes } from '../../config/constants';
import { notify } from '../../redux/ducks/notifications';
import { store } from '../../store/store';
import tokenManager from '../token-manager';
import history from '../../history';
import { AuthorizationData, ObjectAccessAction } from '../../types/authorization-data';
import { EducationTypeEnum } from '../../types/education-type';
import { generalAccess } from '../../mock-data/access-enum';
import auth from '../api/auth';
import { getFileNameFromDisposition } from './reports';

/**
 * Добавление куки
 * @param name - имя
 * @param value - значение
 * @param duration - длительность активности куки (в днях)
 */
export function setCookie(name: string, value: any, duration: number | null) {
  let expires = '';

  if (duration) {
    const date = new Date();

    date.setTime(date.getTime() + duration * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/;`;
}

// возвращает куки с указанным name,
// или undefined, если ничего не найдено
export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([/\\.$?*|{}/\\(/\\)/\\[\]\\/\\//\\+^])/g, '\\$1')}=([^;]*)`)
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name: string) {
  setCookie(name, '', -1);
}

export function isAuthorized() {
  return tokenManager.isTokenValid();
}

export const getRedirectLoginPath = async (query: StringifiableRecord = {}) => {
  let isLocalAuth = false;

  try {
    isLocalAuth = await auth.checkLocalAuthApiKey();
  } catch {}
  const isAupdTokenExist = getCookie(aupdTokenFieldName);

  if (isLocalAuth || isAupdTokenExist) {
    return queryString.stringifyUrl({
      url: isLocalAuth ? routes.login : aupdApiUrl,
      query,
    });
  } else {
    return aupdApiUrl ?? '';
  }
};

export const redirectToAupdUrl = async () => {
  let isLocalAuth = false;

  try {
    isLocalAuth = await auth.checkLocalAuthApiKey();
  } catch {}
  if (isLocalAuth) {
    history.push(routes.login);
  } else {
    window.location.href = `${aupdApiUrl}/choice`;
  }
};

export const redirectToLogin = async (
  options?: Partial<{
    redirectUrl: string;
    errorMessage: string;
    replace: boolean;
    [key: string]: any;
  }>
) => {
  const { redirectUrl, errorMessage, replace, ...params } = options || {};
  const url = await getRedirectLoginPath({
    'redirect-url': options?.redirectUrl,
    error: options?.errorMessage,
    ...params,
  });

  if (url === aupdApiUrl || !debugMode) {
    window.location.href = url;
  } else if (options?.replace) {
    history.replace(url);
  } else {
    history.push(url);
  }
};

export async function checkAuthorization() {
  if (!(await tokenManager.isTokenValid())) {
    await redirectToLogin({
      redirectUrl: window.location.pathname,
    });

    return false;
  }

  return true;
}

export const getRedirectUrlFromQuery = () => {
  const queryParams = queryString.parse(window.location.search);

  return queryParams['redirect-url'] &&
    !Array.isArray(queryParams['redirect-url']) &&
    queryParams['redirect-url'].toLowerCase
    ? queryParams['redirect-url']
    : undefined;
};

/** Имеет ли пользователя право выполнять действие хотя бы с одним из указанных объектов */
export const hasAccessObjectAny = (userProfile: AuthorizationData, objectIds: number[], action: number): boolean =>
  userProfile.objectAccessActionList.some(
    (item) => objectIds.some((row) => row === item.accessObject) && item.accessAction === action
  );

/** Имеет ли пользователь право GeneralAccess */
export const hasGeneralAccess = (userProfile: AuthorizationData, generalAccess: number): boolean =>
  userProfile.generalAccessList?.some((item) => item === generalAccess);

/**  Имеет ли пользователя право выполнять действие хотя бы с одним из дочерних объектов */
export const hasAccessObjectParent = (
  userProfile: AuthorizationData,
  parentObjectId: number,
  accessAction: number
): boolean =>
  userProfile?.objectAccessActionList?.some(
    (item) => item.parentAccessObject === parentObjectId && item.accessAction === accessAction
  );

export const adminAccess = (
  typeAccess: 'all' | 'edit' | 'view' | 'edit-or-view',
  userProfile: AuthorizationData
): boolean => {
  switch (typeAccess) {
    case 'all':
      return (
        hasGeneralAccess(userProfile, generalAccess.AdminView) && hasGeneralAccess(userProfile, generalAccess.AdminEdit)
      );

    case 'edit':
      return hasGeneralAccess(userProfile, generalAccess.AdminEdit);

    case 'view':
      return hasGeneralAccess(userProfile, generalAccess.AdminView);

    case 'edit-or-view':
      return (
        hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit)
      );

    default:
      return false;
  }
};

export function generateLink(template: string, params: { [key: string]: string | number }, queryParams?: any) {
  let formattedString = template;
  const matches = formattedString?.match(/:[a-zA-Z0-9]*/g);

  if (matches) {
    matches.forEach((i: string) => {
      const variable = i.replace(/:/g, '');
      const matchData = params[variable];

      formattedString = formattedString.replace(i, matchData?.toString());
    });
  }
  if (queryParams) {
    formattedString += `?${queryString.stringify(queryParams)}`;
  }

  return formattedString;
}

export const getEducationTypes = (
  userProfile: AuthorizationData,
  objectIds: number[],
  actionId: number
): EducationTypeEnum[] => {
  const filtered = userProfile.objectAccessActionList.filter((item: ObjectAccessAction) => {
    return objectIds.some(
      (objectId) =>
        (objectId === item.accessObject || objectId === item.parentAccessObject) && item.accessAction === actionId
    );
  });
  let resultEducationTypeId: EducationTypeEnum = EducationTypeEnum.None;

  filtered.forEach((item: ObjectAccessAction) => {
    if (item.educationType) {
      resultEducationTypeId |= item.educationType;
    }
  });
  const resultEducationTypes: EducationTypeEnum[] = [];
  const enumVals = Object.values(EducationTypeEnum);

  enumVals.forEach((val) => {
    const enumKey = val as keyof typeof EducationTypeEnum;
    const enumVal = EducationTypeEnum[enumKey];

    if (enumVal !== EducationTypeEnum.All && (resultEducationTypeId & enumVal) !== EducationTypeEnum.None) {
      resultEducationTypes.push(enumVal);
    }
  });

  return resultEducationTypes;
};

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');

  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);

    return;
  }
  navigator.clipboard.writeText(text).catch((err) => {
    console.error('Async: Could not copy text: ', err);
  });
};

export const downloadBase64File = (contentType: string, base64Data: string, fileName: string) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadBlobFile = (contentDisposition: string, blob: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.target = '_blank';
  a.download = getFileNameFromDisposition(contentDisposition);
  a.click();
};

export const openBlobFileNewTab = (blob: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.target = '_blank';
  a.click();
};

export const clearTypeaheadNames = (request: any): any => {
  const result = {
    ...request,
  };

  Object.keys(request).forEach((item) => {
    if (item.endsWith('Name') && !item.startsWith('child')) {
      result[item] = undefined;
    }
  });

  return result;
};
export const redirect = (value: string) => {
  return window.open(value, '_blank');
};

export const checkIfData = (text: string | undefined | null) => {
  return text || '';
};

export const getDefaultValue = (value?: number, label = ''): SelectOptionType | undefined => {
  return value
    ? {
        value,
        label,
      }
    : undefined;
};

export const parseUrlSearchString = (query: string) => {
  return query
    .replace('?', '')
    .split('&')
    .reduce((params: any, param) => {
      const [key, value] = param.split('=');

      params[key] = value
        ? Number.isNaN(parseInt(value))
          ? decodeURIComponent(value.replace(/\+/g, ' '))
          : parseInt(value)
        : '';

      return params;
    }, {});
};

export const generateUrlSearchString = (params: { [key: string]: string | number }) => {
  let formattedString = '?';

  for (const paramsKey in params) {
    formattedString = `${formattedString}&${paramsKey}=${params[paramsKey]}`;
  }

  return formattedString;
};

export const getRandomInt = (): number => {
  const min = 1;
  const max = 10_000;

  return Math.floor(Math.random() * (max - min)) + min; // Максимум не включается, минимум включается
};

// Формирование опций для селекта из массива данных
export const transformSelectOptions = <T>(
  list: T[],
  valueField: string | null = 'id',
  labelRender: (item: any) => string = (item) => item.name
): SelectOptionType[] => {
  return list.map((item: any) => {
    return {
      label: labelRender(item) ?? item,
      value: valueField ? item[valueField] : item,
    };
  });
};

export const showExcelErrorData = () => {
  store.dispatch(
    notify.danger({
      dataTest: 'receivingExcelData',
      title: 'Ошибка получения данных',
    })
  );
};

export const sliceText = (str: string, length = 100) =>
  str
    ?.split(' ')
    .map((item) => {
      let result = '';

      for (let i = 0; i < item.length; i += length) {
        if (i + length >= item.length) {
          result += `${item.slice(i, item.length)}`;
        } else {
          result += `${item.slice(i, i + length)} `;
        }
      }

      return result;
    })
    .join(' ');

export const getCountDiff = <T>(values: T, initialData: T): number => {
  return Object.keys(values).reduce((acc, valueIndex) => {
    if (
      values[valueIndex as keyof T] !== initialData[valueIndex as keyof T] &&
      values[valueIndex as keyof T] !== null
    ) {
      acc++;
    }

    return acc;
  }, 0);
};

export const replaceDotsWithCommas = (str: number): string => str.toString().replace(/\./g, ',');

/**
 * Сформировать ссылку на страницу редактирования услуги в зависимости от типа образования.
 *
 * @param {EducationTypeEnum} educationType - Тип образования
 * @param {number | undefined} serviceId - Идентификатор услуги
 * @param isLearnerHistory - используем ли в истории обучающегося
 */
export const getServiceEditLink = (
  educationType: EducationTypeEnum,
  serviceId: number | undefined,
  isLearnerHistory?: boolean
) => {
  let route = routes.service;

  switch (educationType) {
    case EducationTypeEnum.ChildrenEducation:
    case EducationTypeEnum.ChildrenNonDogmEducation:
      route = isLearnerHistory ? route : routes.registerChildEducation;
      break;
    case EducationTypeEnum.DayCareCentersEducation:
      route = routes.dayCareProgram;
      break;
    case EducationTypeEnum.VirtualAssistantEducation:
    case EducationTypeEnum.ArtHouseEducation:
    case EducationTypeEnum.SportEducation:
      route = routes.service;
      break;
    case EducationTypeEnum.ProfessionalEducation:
      route = routes.registerEducationProgram;
      break;
    default:
      break;
  }

  return generateLink(route, {
    id: serviceId ?? 0,
  });
};

export const replaceHistoryState = (newState: Record<string, any>) => {
  window.history.replaceState(
    {
      ...window.history.state,
      ...newState,
    },
    ''
  );
};

const getHistory = (routeBack: string) => {
  if (routeBack) {
    if (history.length <= 1) {
      history.push(routeBack);
    } else {
      history.goBack();
    }
  }
};

export const goBack = (routeBack?: string) => {
  if (routeBack) {
    getHistory(routeBack);
  } else {
    history.goBack();
  }
};

export const generateUniqueKey = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const getEmptyFunctionToOverride = () => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return () => {};
};
