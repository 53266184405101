import React from 'react';
import classNames from 'classnames';
import { PasswordRequirementProps } from './types/password';

const PasswordRequirement = ({ password, passwordRules }: PasswordRequirementProps) => {
  return (
    <ul className="auth-validation-list">
      {passwordRules.map((rule) => (
        <li
          key={rule.label}
          className={classNames(
            'auth-validation-list__item',
            rule.condition(password) && 'auth-validation-list__item auth-validation-list__item--checked'
          )}
        >
          {rule.label}
        </li>
      ))}
    </ul>
  );
};

export default PasswordRequirement;
