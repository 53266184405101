import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { InteractiveChevronTemplate, LmButton, LmInfoBox, LmListItem, LmSelectNew, LmToggle } from '@mes-ui/lemma';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import DropDown from '../../../../components/drop-down';
import { ServiceContext } from '../../index';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { EntranceTestsData, TrainingGroupData } from '../../../../types/service';
import TgListWithTests from './tg-list-with-tests';
import TgList from './tg-list';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import history from '../../../../history';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';

type Props = {
  editable?: boolean;
  serviceId?: number;
  isTestService: boolean;
  test?: EntranceTestsData;
};

const TrainingGroups: React.FC<Props> = ({ editable, serviceId, isTestService, test }) => {
  const { serviceData, accessPanelEdit } = useContext(ServiceContext);
  const [showArchive, setShowArchive] = useState(true);
  const [trainingGroups, setTrainingGroups] = useState<TrainingGroupData[]>([]);
  const [currentAdmissionPlans, setCurrentAdmissionPlans] = useState<SelectOptionType>({
    value: null,
    label: '',
  });
  const [admissionPlans, settAdmissionPlans] = useState<SelectOptionType[]>([]);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  useEffect(() => {
    const trainingGroupOptions = test?.admissionPlanList?.map(
      (item) =>
        ({
          value: item.yearOfTrainingId,
          label: item.yearOfTrainingName,
        } as SelectOptionType)
    );

    if (trainingGroupOptions?.length) {
      settAdmissionPlans(trainingGroupOptions);
      setCurrentAdmissionPlans({
        value: trainingGroupOptions[0]?.value,
        label: trainingGroupOptions[0].label,
      });
    }
  }, [test?.admissionPlanList]);

  const getTGList = useCallback(async () => {
    if (serviceId) {
      const response = await serviceTemplateApi.getServiceTrainingGroupList(serviceId);

      setTrainingGroups(response ?? []);
    }
  }, [serviceId]);

  useEffect(() => {
    getTGList();
  }, [serviceId, getTGList, serviceData.serviceStatusId]);

  const tgList = useMemo(
    () =>
      trainingGroups?.filter(
        (tg) =>
          (showArchive || tg.scheduleList[0]?.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive) &&
          tg.yearOfTrainingId === currentAdmissionPlans?.value
      ),
    [trainingGroups, currentAdmissionPlans, showArchive]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <div className="flex items-center">
            Планы приема{' '}
            {editable && (
              <>
                <span className="color-gray-dark">
                  {'\u00A0'} {tgList.length}
                </span>
                <div className="flex font-weight-base">
                  <Push
                    orientation="horizontal"
                    size={16}
                  />
                  <div
                    style={{
                      width: 224,
                    }}
                  >
                    <LmSelectNew
                      dataTest="selectAdmissionPlan"
                      name="admissionPlan"
                      value={currentAdmissionPlans || null}
                      onChange={(option: SelectOptionType | null) => {
                        setCurrentAdmissionPlans(option as SelectOptionType);
                      }}
                      size="small"
                      placeholder="Учебный год"
                      options={admissionPlans}
                      grouped={false}
                      multiple={false}
                      settingDropdownAsPopover={{
                        disablePortal: true,
                      }}
                    />
                  </div>
                  <Push
                    orientation="horizontal"
                    size={16}
                  />
                  <LmToggle
                    label="Отображать архивные"
                    size="small"
                    checked={showArchive}
                    name="showArchive"
                    dataTest="showArchive"
                    onChange={(value) => setShowArchive(value)}
                  />
                </div>
              </>
            )}
          </div>
        )}
        headingControl={() => {
          return editable && checkEditable && admissionPlans?.length > 0 ? (
            <DropDown
              dataTest="addPlan"
              component={(open) => (
                <LmButton
                  dataTest="addPlan"
                  type="button"
                  variant="outline"
                >
                  Добавить
                  <InteractiveChevronTemplate open={open} />
                </LmButton>
              )}
            >
              <>
                <LmListItem
                  dataTest="plan"
                  text="План приема"
                  onClick={() => {
                    history.push(
                      `${generateLink(routes.trainingGroup, {
                        serviceId: serviceId ?? 0,
                        id: 0,
                      })}?isRetro=0&yearId=${currentAdmissionPlans.value}`
                    );
                  }}
                />
                {!isTestService && (
                  <LmListItem
                    dataTest="planWithoutPublication"
                    text="План приема без публикации на Mos.ru"
                    onClick={() => {
                      history.push(
                        `${generateLink(routes.trainingGroup, {
                          serviceId: serviceId ?? 0,
                          id: 0,
                        })}?isRetro=1&yearId=${currentAdmissionPlans.value}`
                      );
                    }}
                  />
                )}
              </>
            </DropDown>
          ) : null;
        }}
      >
        {editable ? (
          isTestService ? (
            <TgListWithTests
              list={tgList}
              getTGList={getTGList}
            />
          ) : (
            <TgList
              list={tgList}
              getTGList={getTGList}
            />
          )
        ) : (
          <div className="container">
            <LmInfoBox
              dataTest="planWarning"
              className="infobox--full-width"
              variant="warning"
              description="Планы приема станут доступными для ввода после первого сохранения."
              hidenFooter
            />
            <Push size={24} />
          </div>
        )}
      </Panel>
    </>
  );
};

export default TrainingGroups;
