import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { RequestSourceEnum } from '../../../mock-data/request-source-enum';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { EducationTypeEnum } from '../../../types/education-type';
import { RequestData } from '../../../types/requests';
import ActionsForm from './actions';
import AddressForm from './address';
import ApplicantForm from './applicant';
import Association from './association';
import ChildForm from './child';
import Contract from './contract';
import CopyDocuments from './copy-documents';
import Organization from './organization';
import Status from './status';
import InterdepartmentalChecks from '../components/fields/interdepartmental-checks';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
};

const Details: React.FC<Props> = ({ requestData, updateRequest }) => {
  const [editMode, setEditMode] = useState<string | null>(null);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const btnEditDisabled = useMemo(
    () =>
      requestData.requestStatusId === RequestStatusEnum.Archive ||
      requestData.requestStatusId === RequestStatusEnum.Included ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.ApplicantRefused ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      requestData.requestStatusId === RequestStatusEnum.WaitingSendingDocuments ||
      requestData.requestStatusId === RequestStatusEnum.WaitingDraftContract ||
      !hasAccessObjectAny(userProfile, [accessObject.RequestDOGM, accessObject.RequestNonDOGM], accessAction.Edit),
    [requestData.requestStatusId, userProfile]
  );

  const isAdmin = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const canNotEditPersonalData = useMemo(
    () =>
      requestData.requestSourceId === RequestSourceEnum.Mpgu &&
      requestData.educationTypeId === EducationTypeEnum.ChildrenEducation &&
      !isAdmin,
    [requestData.requestSourceId, requestData.educationTypeId, isAdmin]
  );

  return (
    <>
      {!editMode && (
        <Status
          updateRequest={updateRequest}
          requestData={requestData}
          isAdmin={isAdmin}
        />
      )}
      {!editMode && requestData.educationTypeId !== EducationTypeEnum.ChildrenNonDogmEducation && (
        <InterdepartmentalChecks requestData={requestData} />
      )}
      {!editMode &&
        (requestData.requestStatusId === RequestStatusEnum.WaitingDocumentVerification ||
          (requestData.requestStatusId === RequestStatusEnum.WaitingSendingDocuments &&
            !!requestData.scannedDocuments.length)) && (
        <CopyDocuments
          documents={requestData.scannedDocuments}
          updateRequest={updateRequest}
          requestId={requestData.id}
          disabled={requestData.requestStatusId === RequestStatusEnum.WaitingSendingDocuments}
        />
      )}

      {(!editMode || editMode === 'child') && (
        <ChildForm
          editable={!btnEditDisabled}
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          requestChild={requestData.child}
          contingentLinkTypeId={requestData.contingentLinkTypeId}
          isSourceMosRu={requestData.requestSourceId === RequestSourceEnum.Mpgu}
        />
      )}
      {(!editMode || editMode === 'address') &&
        requestData.educationTypeId !== EducationTypeEnum.ChildrenNonDogmEducation && (
        <AddressForm
          requestId={requestData.id}
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          address={requestData.regAddress}
          educationTypeId={requestData.educationTypeId}
          editable={!btnEditDisabled && !(requestData.requestSourceId === RequestSourceEnum.Mpgu)}
        />
      )}
      {!editMode && (
        <Organization
          contingentGuid={requestData.contingentGuid}
          contingentLinkTypeId={requestData.contingentLinkTypeId}
        />
      )}
      {(!editMode || editMode === 'applicant') && (
        <ApplicantForm
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          requestApplicant={requestData.applicant}
          editable={!btnEditDisabled && !canNotEditPersonalData}
        />
      )}
      {!editMode && (
        <Contract
          requestContract={requestData.contract}
          needPaperContract={requestData.needPaperContract}
        />
      )}
      {!editMode && (
        <Association
          trainingGroup={requestData.trainingGroup}
          educationType={requestData.educationTypeId}
        />
      )}
      {!editMode && requestData.requestStatusId !== RequestStatusEnum.New && (
        <ActionsForm
          requestId={requestData.id}
          updateRequest={updateRequest}
          decline={requestData.decline}
          setEditModeParent={setEditMode}
          requestData={requestData}
          requestStatusId={requestData.requestStatusId}
          extendEntityGuid={requestData.extendEntityGuid}
          serviceId={requestData.trainingGroup?.serviceId}
          isNewService={requestData.isNewService && requestData.requestSourceId === RequestSourceEnum.Mpgu}
        />
      )}
    </>
  );
};

export default Details;
