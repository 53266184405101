import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { classificatorApi } from '../../../../lib/api/classificator';
import { IdName } from '../../../../types/service-class';
import { ServiceDataInfo } from '../../../../types/service';
import { SelectOptionType } from '../../../../types/entities';

type Props = FieldProps<string> & {
  disabledPlaceholder?: string;
  hasTooltip?: boolean;
};

export const LessonLevelField: React.FC<Props> = ({
  placeholder = 'Выберите...',
  disabled,
  isToggle,
  name,
  editMode,
  label,
  isNotTableMain,
  parent,
  otherElement,
  required,
  disabledPlaceholder = 'Сначала выберите профессию',
  hasTooltip,
}) => {
  const nameLabel = parent ? `${parent}.${name}Name` : `${name}Name`;
  const nameValue = parent ? `${parent}.${name}Id` : `${name}Id`;

  const [fieldLabel, , setFieldLabel] = useField(nameLabel);
  const [fieldValue, , setValue] = useField(nameValue);
  const [fieldClassificator, ,] = useField(parent ? `${parent}.classificatorEKUId` : 'classificatorEKUId');
  const [programmLevels, setProgrammLevels] = useState<SelectOptionType[]>([]);

  const { setFieldValue, values, setFieldTouched } = useFormikContext<ServiceDataInfo>();

  useEffect(() => {
    const fetch = async () => {
      const result = await classificatorApi.getProgrammLevels(fieldClassificator.value);

      setProgrammLevels(
        result.map((r: IdName) => {
          return {
            value: r.id,
            label: r.name,
          };
        })
      );
    };

    if (editMode && fieldClassificator?.value) {
      fetch();
    }
  }, [fieldClassificator.value, editMode]);

  useEffect(() => {
    if (!fieldClassificator.value) {
      setFieldValue(nameLabel, null);
      setFieldValue(nameValue, null);
    }
  }, [fieldClassificator.value, nameLabel, nameValue, setFieldValue]);

  useEffect(() => {
    setFieldTouched(parent ? `${parent}.altStart` : 'altStart', true);
    setFieldTouched(parent ? `${parent}.altEnd` : 'altEnd', true);
    setFieldTouched(parent ? `${parent}.hoursPerWeek` : 'hoursPerWeek', true);
    setFieldTouched(parent ? `${parent}.durationOfTraining` : 'durationOfTraining', true);
    setFieldTouched(parent ? `${parent}.durationOfTrainingMonths` : 'durationOfTrainingMonths', true);
    setFieldTouched(parent ? `${parent}.durationOfTrainingWeeks` : 'durationOfTrainingWeeks', true);
    setFieldTouched(parent ? `${parent}.durationOfTrainingDays` : 'durationOfTrainingDays', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.programmLevelId, parent]);

  return (
    <Field
      label={label}
      isToggle={isToggle}
      editMode={editMode}
      value={fieldLabel.value}
      otherElement={otherElement}
      isNotTableMain={isNotTableMain}
      required={required}
    >
      <FormikSelect
        required
        size="small"
        name={nameValue}
        disabled={disabled || !fieldClassificator.value}
        options={programmLevels}
        placeholder={placeholder}
        showTooltip={hasTooltip}
        explainText={disabled || !fieldClassificator.value ? disabledPlaceholder : undefined}
        selectedValue={(option?: SelectOptionType) => {
          setFieldLabel.setValue(option?.label);
          setValue.setValue(option?.value);
        }}
        defaultValue={
          fieldLabel && fieldLabel.value
            ? {
                label: fieldLabel.value,
                value: fieldValue.value,
              }
            : null
        }
      />
    </Field>
  );
};
