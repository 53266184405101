export enum MegaRelationHistoryTypeEnum {
  // Перевод на следующий этап
  ToTheNextStage = 1,
  // Перевод в другой класс
  ToOtherClass = 2,
  // Перевод на другую услугу
  ToOtherService = 3,
  // Отчисление
  Exclude = 4,
  // Зачисление в группу
  Include = 5,
  // Восстановлен в группе
  Recover = 6,
  // Изменение плановых дат периода обучения
  ChangeLearnDates = 7,
}
