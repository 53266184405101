import React, { useContext, useState } from 'react';
import Info from './panels/info';
import { ServiceContext } from '..';
import Financing from '../components/panels/financing';
import Photo from '../components/panels/photo';
import ContactPerson from '../components/panels/contact-person';
import PlanTemplate from '../components/panels/plan';
import AddressDocumentsTemplate from '../components/panels/address-documents';
import Period from './panels/period';
import AddressClass from '../components/panels/address-class';
import PublicationStatus from '../components/publication-status';
import { TrainingGroupData } from '../../../types/service';

const ServiceChildDetails = () => {
  const [editMode, setEditMode] = useState<string | null>(null);
  const { serviceData, accessPanelEdit } = useContext(ServiceContext);
  const [plans, setPlans] = useState<TrainingGroupData[]>([]);

  return (
    <>
      {accessPanelEdit && (
        <PublicationStatus
          plans={plans}
          serviceStatusId={serviceData.serviceStatusId}
          editMode={editMode}
          educationType={serviceData.educationTypeId}
        />
      )}

      {(!editMode || editMode === 'info') && <Info setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'contact') && <ContactPerson setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'address-class') && serviceData.info?.typeOfAttendanceId !== 2 && (
        <AddressClass
          setEditModeParent={setEditMode}
          editCount="one"
          textForEmpty="Для публикации детского объединения необходимо указать хотя бы один адрес проведения занятий"
        />
      )}
      {(!editMode || editMode === 'period') && <Period setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'financing') && <Financing setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'address-documents') && (
        <AddressDocumentsTemplate
          setEditModeParent={setEditMode}
          textForEmpty="Для публикации детского объединения необходимо указать хотя бы один адрес приема документов"
        />
      )}
      {(!editMode || editMode === 'plan') && (
        <PlanTemplate
          setPlans={setPlans}
          editable
        />
      )}
      {(!editMode || editMode === 'photo') && <Photo setEditModeParent={setEditMode} />}
    </>
  );
};

export default ServiceChildDetails;
