import React, { useState } from 'react';
import { InteractiveChevronTemplate, LmText } from '@mes-ui/lemma';

type FilterContentItemProps = {
  title: string;
  defaultClose?: boolean;
  dataTest: string;
};

const FilterContentItem: React.FC<FilterContentItemProps> = ({ title, defaultClose, dataTest, children }) => {
  const [open, setOpen] = useState(!defaultClose);

  const handleClickTitle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div data-test={`${dataTest}-filterContentItem`}>
      <div
        className="filter-item__title"
        data-test={`${dataTest}-filterItemTitle`}
        onClick={handleClickTitle}
      >
        <LmText
          variant="md"
          weight="semi-bold"
        >
          {title}
        </LmText>
        <InteractiveChevronTemplate open={open} />
      </div>
      {open ? (
        <div
          className="filter-item__content"
          data-test={`${dataTest}-filterItemContent`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default FilterContentItem;
