import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { object as objectYup } from 'yup';
import { LmIcon, LmLoader } from '@mes-ui/lemma';
import { Panel, Push } from '@mosru/esz_uikit';
import SavePanel from '../../../components/save-panel';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { notify } from '../../../redux/ducks/notifications';
import OrganizationField from '../components/fields/organization';
import ClassParallelLetter from '../components/fields/class-parallel-leter';
import { SchoolRequestData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import { classParallelId, organizationId } from '../../../lib/utils/validation';

type Props = {
  setEditModeParent: (value: string | null) => void;
  data: SchoolRequestData;
  updateRequest: () => void;
  requestId: number;
  showEditBtn?: (type: string) => boolean;
  requestStatusId?: number;
  changeStatusRequest?: () => void;
};

const Organization: React.FC<Props> = ({
  setEditModeParent,
  data,
  updateRequest,
  requestId,
  showEditBtn,
  requestStatusId,
  changeStatusRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(data, organizationValidationSchema);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const submitForm = useCallback(
    async (values: SchoolRequestData) => {
      setLoading(true);
      try {
        values.requestId = requestId;
        await requestsApi.saveOrganization(requestId, values);

        setEditModeParent(null);
        setEditMode(false);
        // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
        changeStatusRequest && changeStatusRequest();
        updateRequest();
      } catch (e) {
        dispatch(
          notify.danger({
            dataTest: 'saveInfoDayCare',
            title: 'Не удалось сохранить информацию о группе по присмотру и уходу за детьми школьного возраста',
            description: e,
          })
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditModeParent, updateRequest, requestId]
  );

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={organizationValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={data}
    >
      {(formikProps: FormikProps<SchoolRequestData>) => {
        const { handleSubmit, submitForm, isSubmitting, resetForm, isValid, initialValues } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Общеобразовательная организация'}
              headingControl={() => {
                return !editMode && showEditBtn && showEditBtn('organization') ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('organization');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <OrganizationField
                    editMode={editMode}
                    name="schoolOrganization"
                    requestStatusId={requestStatusId}
                    initialValues={initialValues}
                    required
                  />

                  <ClassParallelLetter editMode={editMode} />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export const organizationValidationSchema = objectYup().shape({
  schoolOrganizationId: organizationId,
  classParallelId,
});

export default Organization;
