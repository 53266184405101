import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { SelectOptionType, TreeNodeType } from '@mosru/esz_uikit';
import { LmFilterVertical } from '@mes-ui/lemma';
import { useHistory, useLocation } from 'react-router-dom';
import TreeModal from '../../../components/tree-modal';
import { replaceHistoryState } from '../../../lib/utils';
import { historyState } from '../../../mock-data/history-state';
import { getCountDiff } from '../../../lib/utils/service';
import { SearchInitialFormData } from '../../../types/service';
import FieldsContainer from './components/fields-container';
import { ServiceRegistryContext } from './initial-data';
import { summElementTree } from '../../../components/tree-modal/helpers';
import { RegistryTypeEnum } from '../../../mock-data/registry-type-enum';
import { sendReachGoal } from '../../../lib/metrica';

type Props = {
  submitForm: (values: SearchInitialFormData) => void;
  setCurrentSearchData: (values: SearchInitialFormData) => void;
  searchPlaceholder: string;
  initialValues: SearchInitialFormData;
};

const Search: React.FC<Props> = ({ submitForm, searchPlaceholder, setCurrentSearchData, initialValues }) => {
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const [openTreePopup, setOpenTreePopup] = useState(false);

  const [dataTreePopup, setDataTreePopup] = useState<TreeNodeType[]>([]);

  const [basicDirection, setBasicDirection] = useState<SelectOptionType | null>();
  const allElementNumbersTreePopupRef = useRef<number>(0);

  // текущее меню в тот момент когда его закрыли
  const [currentMenuClosePopup, setCurrentMenuClosePopup] = useState<SelectOptionType>({
    label: '',
    value: 0,
  });

  const location = useLocation();
  const history = useHistory();

  const resetState = () => {
    setBasicDirection(null);
  };

  const { type, initialData, educationTypeData } = React.useContext(ServiceRegistryContext);

  useEffect(() => {
    allElementNumbersTreePopupRef.current = summElementTree(dataTreePopup);
  }, [dataTreePopup, dataTreePopup.length]);

  const treeModalOptions = educationTypeData.filter((item) => item.label !== 'Все');

  const handleClickAdvancedSearch = (isOpen: boolean) => {
    replaceHistoryState({
      [historyState.openAdvanced]: isOpen,
    });
    setOpen(isOpen);
  };

  const handleSearch = (values: SearchInitialFormData, formikHelpers: FormikHelpers<SearchInitialFormData>) => {
    sendReachGoal(`onclick-service-search-${values.educationTypeId}`);
    submitForm(values);
    formikHelpers.setSubmitting(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleSearch}
        enableReinitialize
        initialValues={initialValues}
      >
        {({ handleSubmit, isSubmitting, values, resetForm, setFieldValue }: FormikProps<SearchInitialFormData>) => {
          const { eventId } = initialValues;

          // если мы переходим по ссылке с формы уведомлений увеличиваем count
          const countDiff = getCountDiff(values, initialData) + (eventId ? 1 : 0);

          const onResetForm = () => {
            let newObj: SearchInitialFormData = initialData;

            if (eventId) {
              history.replace(location.pathname);
              replaceHistoryState({
                [historyState.openAdvanced]: open,
              });
              const { eventId, ...obj } = initialData;

              newObj = obj;
            }

            resetState();
            resetForm({
              values: newObj,
            });
            submitForm(newObj);
            setCurrentSearchData(newObj);
          };

          return (
            <form onSubmit={handleSubmit}>
              <LmFilterVertical
                dataTest="serviceFilter"
                classNameContent="filter-content"
                open={open}
                searchValue={values.query}
                searchPlaceholder={searchPlaceholder}
                onChange={(value) => setFieldValue('query', value)}
                toggleOpen={handleClickAdvancedSearch}
                onClear={onResetForm}
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  type: 'submit',
                }}
                buttonSecondaryText={countDiff ? `Сбросить фильтры (${countDiff})` : 'Сбросить фильтры'}
              >
                <FieldsContainer
                  basicDirection={basicDirection}
                  type={type}
                  setOpenTreePopup={setOpenTreePopup}
                  setBasicDirection={setBasicDirection}
                  setCurrentSearchData={setCurrentSearchData}
                />
              </LmFilterVertical>
            </form>
          );
        }}
      </Formik>

      <TreeModal
        open={openTreePopup}
        title={
          type === RegistryTypeEnum.serviceEducationProgramType ? 'Реестр профессий' : 'Реестр базовых направлений'
        }
        closePopup={() => {
          setOpenTreePopup(false);
        }}
        dataTreePopup={dataTreePopup}
        selectOptions={treeModalOptions}
        setDataTreePopup={setDataTreePopup}
        selected={educationTypeData.length !== 1}
        setValueBasicDirection={setBasicDirection}
        setCurrentMenuClosePopup={setCurrentMenuClosePopup}
        currentMenuClosePopup={currentMenuClosePopup}
        placeholderName="Поиск..."
        numberElements={allElementNumbersTreePopupRef.current ?? 0}
      />
    </>
  );
};

export default Search;
