import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number as numberYup, object as objectYup } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmSubHeader } from '@mes-ui/lemma';
import history from '../../../../history';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import SavePanel from '../../../../components/save-panel';
import { handleBookingChange } from '../../../../redux/ducks/booking';
import FIO from '../../components/fields/fio';
import BirthDate from '../../components/fields/birthdate';
import Gender from '../../../../components/fields/gender';
import Document from '../../components/fields/document';
import DocumentNumber from '../../components/fields/document-number';
import Issued from '../../components/fields/issued';
import IssuedPlace from '../../components/fields/issued-place';
import Snils from '../../components/fields/snils';
import MoscowRegistration from '../../components/fields/moscow-registration';
import Phone from '../../../../components/fields/phone';
import Email from '../../../../components/fields/email';
import Service from '../../components/fields/service';
import AdmissionPlan from '../../components/fields/admission-plan';
import VisitDays from '../../components/fields/visit-days';
import ServiceClassField from '../../components/fields/service-class';
import { formatDate } from '../../../../lib/utils/date';
import { routes } from '../../../../config/constants';
import OrganizationField from '../../components/fields/organization';
import ClassParallelLetter from '../../components/fields/class-parallel-leter';
import RepresentationType from '../../components/fields/representation-type';
import { PersonRequestData, RequestData, TrainingGroupRequestData } from '../../../../types/requests';
import requestsApi from '../../../../lib/api/requests';
import { EducationTypeEnum } from '../../../../types/education-type';
import {
  classParallelId,
  dateOfBirth,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  issued,
  issuedNullable,
  issuedPlace,
  lastName,
  middleName,
  nullableEmail,
  organizationId,
  phone,
  scheduleOfTimetable,
  serviceId,
  sexId,
  snils,
  stringRequired,
} from '../../../../lib/utils/validation';
import { bookingSelector, userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { ServiceClassListData, ServiceClassPeriodListScheduleData } from '../../../../types/service-class';
import { LoadingModal } from '../../components/modals';
import WrapperFieldsDocument from '../../components/fields/wrapper-fields-document';
import WrapperFields from '../../components/fields/wrapper-fields';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import { StatusChild } from '../../components/status-child';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { redirectToRequestAndCleanBooking } from '../../utils';

type Props = {
  request?: RequestData;
};

const DayCareRequestCreate: React.FC<Props> = ({ request }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { userProfile, booking } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
    booking: bookingSelector(state),
  }));

  const [serviceClassList, setServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);
  const [initialDataServiceClassList, setInitialDataServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);

  const dataParams = useParams<Record<string, string | undefined>>();
  // Если это новое заявление, то мы не будем сразу отображать ошибки валидации.
  const hasEdTypeOrPupilId = 'id' in dataParams || 'pupilId' in dataParams;

  useEffect(() => {
    return () => {
      dispatch(handleBookingChange());
    };
  }, [dispatch]);

  useEffect(() => {
    request?.trainingGroup &&
      dispatch(
        handleBookingChange(
          {
            bookingId: request.trainingGroup.bookingGuid,
            slotId: request.trainingGroup.slotId,
          },
          request.trainingGroup.serviceClass?.id
        )
      );
  }, [dispatch, request?.trainingGroup]);

  const initialValue = useMemo(() => {
    if (request) {
      return {
        ...request,
        applicant: {
          ...request.applicant,
          firstName: request.applicant?.firstName?.trim(),
          lastName: request.applicant?.lastName?.trim(),
          middleName: request.applicant?.middleName?.trim(),
          sexId: request.applicant ? request.applicant?.sexId : 1,
          sexName: request.applicant?.sexName || 'Мужской',
          educationTypeId: request.applicant?.educationTypeId ?? EducationTypeEnum.DayCareCentersEducation,
        } as PersonRequestData,
        child:
          request.child &&
          ({
            ...request.child,
            firstName: request.child?.firstName?.trim(),
            lastName: request.child?.lastName?.trim(),
            middleName: request.child?.middleName?.trim(),
            sexId: request.child ? request.child?.sexId : 1,
            sexName: request.child?.sexName || 'Мужской',
            educationTypeId: request.child?.educationTypeId ?? EducationTypeEnum.DayCareCentersEducation,
          } as PersonRequestData),
        trainingGroup: {} as TrainingGroupRequestData,
      };
    }

    return {
      child: {
        sexId: 1,
        sexName: 'Мужской',
        series: '',
        documentTypeId: DocumentTypeEnum.BirthCertificate,
        documentTypeName: 'Свидетельство о рождении',
        educationTypeId: EducationTypeEnum.DayCareCentersEducation,
      },
      applicant: {
        sexId: 1,
        sexName: 'Мужской',
        documentTypeId: DocumentTypeEnum.Passport,
        documentTypeName: 'Паспорт гражданина РФ',
        educationTypeId: EducationTypeEnum.DayCareCentersEducation,
      },
    } as RequestData;
  }, [request]);

  const initialErrors = useInitialErrors(initialValue, getValidationSchema());

  const submitForm = useCallback(
    async (values: RequestData) => {
      const fetch = async () => {
        setLoading(true);
        try {
          if (booking) {
            values.trainingGroup.bookingGuid = booking.bookingId;
            values.trainingGroup.slotId = booking.slotId;
            values.educationTypeId = EducationTypeEnum.DayCareCentersEducation;
            values.child.isChild = true;
            values.applicant.isChild = false;
            values.dateRequest = moment().format().toString();
            values.applicant.phone = addPhonePrefix(values.applicant.phone);
            const newRequestId = await requestsApi.createRequest(values);

            setLoading(false);
            redirectToRequestAndCleanBooking(newRequestId);
          }
        } catch (error) {}
      };

      fetch();
    },
    [booking]
  );

  const checkIssuedRequired = (values: RequestData): boolean =>
    Boolean(
      values.child.documentTypeId === DocumentTypeEnum.Passport ||
        values.child.documentTypeId === DocumentTypeEnum.BirthCertificate ||
        values.child.documentTypeId === DocumentTypeEnum.BirthCertificateForeign
    );

  const checkTypeIssuePlaceRequired = (values: RequestData): boolean =>
    Boolean(
      values.child.documentTypeId === DocumentTypeEnum.BirthCertificateForeign ||
        values.child.documentTypeId === DocumentTypeEnum.BirthCertificate
    );

  const handleCancel = () => {
    history.push(routes.requests);
  };

  return (
    <>
      <LmSubHeader
        sticky
        description={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(new Date())}
              <Push
                size={20}
                orientation="horizontal"
              />
              Источник: Оператор
            </div>
          </>
        }
        arrowOnClick={handleCancel}
        title="Новое заявление"
        dataTest="requestDayCareCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Заявления',
            link: routes.requests,
          },
          {
            label: 'Новое заявление',
          },
        ]}
      />

      <Formik
        initialErrors={initialErrors}
        validateOnMount
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValue}
        validationSchema={getValidationSchema}
      >
        {(formikProps: FormikProps<RequestData>) => {
          const { handleSubmit, submitForm, isSubmitting, isValid, values, initialValues } = formikProps;

          const isBirthRecord = values.child.documentTypeId === DocumentTypeEnum.BirthRecord;

          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />

              <Panel title={() => 'Информация о ребенке'}>
                <div className="container">
                  <StatusChild contingentLinkTypeId={values.contingentLinkTypeId} />
                  <Push size={20} />
                  <div className="table-data">
                    <WrapperFields hasSearchOrganization />
                    <Gender
                      editMode
                      required
                      name="child.sex"
                    />
                    <WrapperFieldsDocument
                      formType={FormTypeEnum.EditRequestDayCare}
                      showErrorImmediately={!!initialValue.child?.number}
                    />
                    {isBirthRecord ? null : (
                      <Issued
                        name="child.issued"
                        editMode
                        required={(checkIssuedRequired(values) && hasEdTypeOrPupilId) || checkIssuedRequired(values)}
                        showErrorImmediately={checkIssuedRequired(values) && hasEdTypeOrPupilId}
                      />
                    )}
                    <IssuedPlace
                      name="typeIssuePlace"
                      parent="child"
                      editMode
                      required={
                        (checkTypeIssuePlaceRequired(values) && hasEdTypeOrPupilId) ||
                        checkTypeIssuePlaceRequired(values)
                      }
                      vedomstvoId={userProfile?.vedomstvoId}
                      showErrorImmediately={checkTypeIssuePlaceRequired(values) && hasEdTypeOrPupilId}
                    />
                    <Snils
                      name="child.snils"
                      editMode
                      disabled
                    />
                    <MoscowRegistration
                      name="child.moscowRegistration"
                      editMode
                    />
                  </div>
                </div>
              </Panel>

              <Push size={12} />

              <Panel title={() => 'Общеобразовательная организация'}>
                <div className="container">
                  <div className="table-data">
                    <OrganizationField
                      editMode
                      required
                      parent="school"
                      name="schoolOrganization"
                      initialValues={initialValues}
                    />
                    <ClassParallelLetter
                      parent="school"
                      editMode
                    />
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              <Panel title={() => 'Информация о заявителе'}>
                <div className="container">
                  <div className="table-data">
                    <RepresentationType
                      name="typeRepresentation"
                      parent="applicant"
                      required
                      editMode
                    >
                      <Gender
                        isNotTableMain
                        name="sex"
                        parent="applicant"
                        required
                        editMode
                        size={1}
                      />
                    </RepresentationType>
                    <FIO
                      label="ФИО заявителя"
                      required
                      editMode
                      parent="applicant"
                    />
                    <BirthDate
                      name="dateOfBirth"
                      parent="applicant"
                      editMode
                      required
                    />
                    <Phone
                      name="applicant.phone"
                      editMode
                      required
                      showErrorImmediately={!!initialValue.applicant?.phone}
                    />
                    <Email
                      name="applicant.email"
                      editMode
                    />
                    <Document
                      name="documentType"
                      parent="applicant"
                      editMode
                      dependentFields
                      formType={FormTypeEnum.EditRequestApplicant}
                    />
                    <Issued
                      name="applicant.issued"
                      editMode
                    />
                    <DocumentNumber
                      parent="applicant"
                      editMode
                      showErrorImmediately
                      calendarPosition="top-end"
                    />
                    <Snils
                      name="applicant.snils"
                      editMode
                    />
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              <Panel title={() => 'Информация о группе по присмотру и уходу за детьми школьного возраста'}>
                <div className="container">
                  <div className="table-data">
                    <Service
                      menuPlacement="top"
                      parent="trainingGroup"
                      editMode
                      required
                    />
                    <AdmissionPlan
                      name="scheduleOfTimetableId"
                      parent="trainingGroup"
                      editMode
                      required
                    />
                    <VisitDays
                      name="desiredDays"
                      parent="trainingGroup"
                      editMode
                      setServiceClassList={setServiceClassList}
                      initialDataServiceClassList={initialDataServiceClassList}
                      required
                      initialValues={initialValues}
                    />
                    <ServiceClassField
                      name="serviceClass"
                      parent="trainingGroup"
                      editMode
                      serviceClassList={serviceClassList}
                      setServiceClassList={setServiceClassList}
                      setInitialDataServiceClassList={setInitialDataServiceClassList}
                      required
                      isNeedBooking
                      educationType={EducationTypeEnum.DayCareCentersEducation}
                    />
                  </div>
                </div>
              </Panel>

              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid || !booking,
                }}
                onClickSeconadaryButton={handleCancel}
                onClickPrimaryButton={submitForm}
              />
            </form>
          );
        }}
      </Formik>
      <LoadingModal showModal={loading} />
    </>
  );
};

export const trainingGroupSchema = objectYup().shape({
  serviceId,
  serviceClass: objectYup({
    id: numberYup().required('Укажите группу по уходу и присмотру за детьми школьного возраста'),
  }),
  scheduleOfTimetableId: scheduleOfTimetable,
  desiredDays: numberYup().moreThan(0, 'Укажите дни для посещения').required('Укажите дни для посещения'),
});

const schoolSchema = objectYup().shape({
  schoolOrganizationId: organizationId,
  classParallelId,
});

const getValidationSchema = () =>
  objectYup().shape({
    child: childrenValidationSchema,
    applicant: applicantValidationSchema,
    trainingGroup: trainingGroupSchema,
    school: schoolSchema,
  });

export const childrenValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  sexId,
  documentTypeId: stringRequired,
  series: documentSeries(true),
  number: documentNumber(true),
  dateOfIssue: dateOfIssue(true),
  issued,
  typeIssuePlaceId: issuedPlace,
  snils,
});

export const applicantValidationSchema = objectYup().shape({
  typeRepresentationId: stringRequired,
  sexId,
  firstName,
  lastName,
  middleName,
  email: nullableEmail,
  phone,
  dateOfBirth,
  issued: issuedNullable,
  documentTypeId: stringRequired,
  series: documentSeries().nullable(),
  number: documentNumber().nullable(),
  dateOfIssue: dateOfIssue(),
});

export default DayCareRequestCreate;
