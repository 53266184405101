import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LmBadgeStatus, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistory from '../../../components/change-history';
import Dialog from '../../../components/modals/dialog';
import { routes } from '../../../config/constants';
import TestsDetails from './tests-details';
import { TrainingGroupStatusEnum } from '../../../mock-data/training-group-status-enum';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { formatTime, formatTimeFromDate } from '../../../lib/utils/date';
import { TypesOfRepetitionEnum } from '../../../mock-data/type-of-repetition';
import { ScheduleData } from '../../../types/service';
import { generateLink, parseUrlSearchString } from '../../../lib/utils';
import history from '../../../history';
import { entityTypesForHistory } from '../../../mock-data/entity-type-enum';

export type ScheduleDataEx = ScheduleData & {
  requestTimeStart: string;
  requestTimeEnd: string;
  repeat: boolean;
  endDate: boolean;
  everyday?: boolean;
  everymonth?: boolean;
  everyWeekRepeatNumber1?: number;
  everymonthRepeatNumber1?: number;
  everymonthRepeatNumber2?: number;
  everymonthRepeatNumber3?: number;
  everymonthRepeatNumber4?: number;
};

const Tests = () => {
  const params = useParams<{ serviceId: string; tgId: string; id: string }>();
  const [schedule, setSchedule] = useState<ScheduleDataEx>({} as ScheduleDataEx);
  const [removedId, setRemovedId] = useState<number | undefined>();
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);
  const [educationType, setEducationType] = useState<number>(0);
  const location = useLocation();
  const { isRetro } = parseUrlSearchString(location.search);

  const canDelete = useMemo(
    () =>
      schedule && schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft && schedule.requestTotalCount === 0,
    [schedule]
  );

  useEffect(() => {
    const fetch = async (id: string) => {
      const result = await serviceTemplateApi.getServiceById(id);

      setEducationType(result.educationTypeId ?? 0);
    };

    if (params.serviceId && params.serviceId !== '0') {
      fetch(params.serviceId);
    }
  }, [params.serviceId]);

  const getSchedule = useCallback(async () => {
    if (params.id && params.id !== '0') {
      const result = await serviceTemplateApi.getTrainingSchedule(params.id);

      if (result) {
        setSchedule({
          ...result,
          requestTimeStart: result.requestStart ? formatTimeFromDate(result.requestStart) : '',
          requestTimeEnd: result.requestEnd ? formatTimeFromDate(result.requestEnd) : '',
          timeStart: result.timeStart ? formatTime(result.timeStart) : result.timeStart,
          timeEnd: result.timeEnd ? formatTime(result.timeEnd) : result.timeEnd,
          repeat: !!result.typesOfRepetitionId && result.typesOfRepetitionId !== TypesOfRepetitionEnum.NoRepeat,
          endDate: !!result.periodTo,
          everyday: (result.repeatNumber1 ?? 0) === 0,
          everymonth: (result.dayOfWeek ?? 0) > 0,
          everyWeekRepeatNumber1:
            result.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatWeekly ? result.repeatNumber1 : 1,
          everymonthRepeatNumber1: (result.dayOfWeek ?? 0) > 0 ? 1 : result.repeatNumber1,
          everymonthRepeatNumber2: (result.dayOfWeek ?? 0) > 0 ? 1 : result.repeatNumber2,
          everymonthRepeatNumber3: (result.dayOfWeek ?? 0) === 0 ? 1 : result.repeatNumber1,
          everymonthRepeatNumber4: (result.dayOfWeek ?? 0) === 0 ? 1 : result.repeatNumber2,
        });
      }
    } else {
      setSchedule({
        id: 0,
        trainingGroupStatusId: TrainingGroupStatusEnum.Draft,
      } as ScheduleDataEx);
    }
  }, [params.id]);

  useEffect(() => {
    getSchedule();
  }, [params?.id, getSchedule]);

  const handleEditModal = useCallback(async () => {
    await serviceTemplateApi.updateTrainingScheduleStatus({
      serviceId: params.serviceId,
      educationTypeId: schedule.educationTypeId,
      scheduleOfTimetableId: schedule.id,
      trainingGroupStatusId: TrainingGroupStatusEnum.Unavailable,
    });
    getSchedule();
  }, [schedule, params.serviceId, getSchedule]);

  const getStatus = (status: TrainingGroupStatusEnum) => {
    switch (status) {
      case TrainingGroupStatusEnum.Draft:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="grey"
          >
            Черновик
          </LmBadgeStatus>
        );
      case TrainingGroupStatusEnum.Unavailable:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="orange"
          >
            Приема нет
          </LmBadgeStatus>
        );
      case TrainingGroupStatusEnum.Signed:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="green"
          >
            Опубликовано
          </LmBadgeStatus>
        );
      case TrainingGroupStatusEnum.Archive:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="grey"
          >
            Архив
          </LmBadgeStatus>
        );
      default:
        break;
    }
  };

  const updateStatus = useCallback(
    async (statusId: TrainingGroupStatusEnum) => {
      await serviceTemplateApi.updateTrainingScheduleStatus({
        serviceId: params.serviceId,
        educationTypeId: schedule.educationTypeId,
        scheduleOfTimetableId: schedule.id,
        trainingGroupStatusId: statusId,
      });
      getSchedule();
    },
    [params.serviceId, schedule.educationTypeId, schedule.id, getSchedule]
  );

  const handleArchive = async () => {
    await updateStatus(TrainingGroupStatusEnum.Archive);
  };

  const handleRemove = () => {
    setRemovedId(schedule.id);
  };

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (
      (schedule.isRetro ||
        [TrainingGroupStatusEnum.Draft, TrainingGroupStatusEnum.Unavailable].includes(
          schedule.trainingGroupStatusId
        )) &&
      canDelete
    ) {
      buttons.push({
        dataTest: 'toArchive',
        icon: 'filled-hardware-hard-drive',
        buttonText: 'В архив',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleArchive,
      });
      buttons.push({
        dataTest: 'delete',
        icon: 'filled-edit-trash-alt',
        buttonText: 'Удалить',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleRemove,
      });
    } else if (schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Unavailable) {
      buttons.push({
        dataTest: 'toArchive',
        icon: 'filled-hardware-hard-drive',
        buttonText: 'В архив',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleArchive,
      });
      buttons.push({
        dataTest: 'resumeReception',
        buttonText: 'Возобновить прием',
        rounded: 'small',
        variant: 'secondary',
        onClick: async () => {
          await updateStatus(TrainingGroupStatusEnum.Signed);
        },
      });
    } else if (
      !schedule.isRetro &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
      schedule.requestTotalCount > 0
    ) {
      buttons.push({
        dataTest: 'stopReception',
        buttonText: 'Остановить прием',
        rounded: 'small',
        variant: 'secondary',
        onClick: () => {
          setShowUnavailableModal(true);
        },
      });
    }

    return buttons;
  };

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={() =>
          history.push(
            generateLink(routes.trainingGroup, {
              serviceId: params.serviceId,
              id: params.tgId,
            })
          )
        }
        title={`Вступительные испытания${isRetro ? ' без публикации на Mos.ru' : ''}`}
        dataTest="serviceDkgmDsitTestSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Образовательные услуги',
            link: routes.services,
          },
          {
            label: 'Описание образовательной услуги',
            link: generateLink(routes.service, {
              id: params.serviceId,
            }),
          },
          {
            label: 'План приема',
            link: generateLink(routes.trainingGroup, {
              serviceId: params.serviceId,
              id: params.tgId,
            }),
          },
          {
            label: 'Вступительные испытания',
          },
        ]}
        description={<div className="flex justify-end">{getStatus(schedule.trainingGroupStatusId)}</div>}
        tabs={[
          {
            title: 'Основные сведения',
            value: 1,
            dataTest: 'basicInfo',
          },
          {
            title: 'История изменений',
            value: 2,
            dataTest: 'changeHistory',
          },
        ]}
        className="content-panel"
        panels={[
          {
            children: (
              <TestsDetails
                scheduleData={schedule}
                getSchedule={getSchedule}
                serviceId={parseInt(params.serviceId)}
                educationType={educationType}
                isRetro={isRetro}
              />
            ),
            value: 1,
          },
          {
            children: (
              <ChangeHistory
                name=""
                extendEntityGuid={schedule?.trainingGroupExtendEntityGuid}
                entityTypes={entityTypesForHistory.services}
              />
            ),
            value: 2,
            className: 'content-panel content-panel--auto',
            noPadding: true,
          },
        ]}
        buttonsOrAnyActions={getButtons()}
      />
      <Dialog
        dataTest="stopReception"
        isOpenDialog={showUnavailableModal}
        title="Остановка приема заявлений"
        description="Внимание! После подтверждения прием заявлений на Mos.ru будет остановлен."
        variant="question"
        callCloseAfterSubmit
        onClickPrimaryButton={handleEditModal}
        onClickSeconadaryButton={() => setShowUnavailableModal(false)}
      />
      <Dialog
        dataTest="deleteTrainingSchedule"
        isOpenDialog={!!removedId}
        title="Удаление расписания вступительных испытаний"
        description="Вы уверены, что хотите удалить выбранное расписание вступительных испытаний? Обратите внимание, что вся информация о выбранном расписании вступительных испытаний будет утеряна."
        variant="alert"
        buttonPrimaryText="Да, удалить"
        callCloseAfterSubmit
        onClickPrimaryButton={async () => {
          await serviceTemplateApi.deleteTrainingSchedule(params.serviceId, schedule.id);
          history.push(
            `${generateLink(routes.trainingGroup, {
              serviceId: params.serviceId,
              id: params.tgId ?? 0,
            })}?isRetro=${isRetro}`
          );
        }}
        onClickSeconadaryButton={() => setRemovedId(undefined)}
      />
    </>
  );
};

export default Tests;
