import React, { useCallback, useEffect, useRef } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import {
  classParallelIdField,
  classParallelNameField,
  classLetterlIdField,
  classLetterlNameField,
} from './class-parallel-leter';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';
import { serviceIdField, serviceNameField } from './service';
import { serviceClassIdField } from './service-class';
import { RequestData, SchoolRequestData } from '../../../../types/requests';

type OrganizationProps = FieldProps<SelectOptionType> & {
  parent?: string;
  requestStatusId?: number;
  initialValues?: RequestData | SchoolRequestData;
};

export const schoolOrganizationIdField = 'schoolOrganizationId';
export const schoolOrganizationNameField = 'schoolOrganizationName';

const OrganizationField = ({
  label = 'Общеобразовательная организация',
  name,
  editMode,
  required,
  parent,
  requestStatusId,
  initialValues,
}: OrganizationProps) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { setFieldValue } = useFormikContext<FormikHelpers<any>>();

  const organizationId = parent ? `${parent}.${name}Id` : `${name}Id`;
  const organizationName = parent ? `${parent}.${name}Name` : `${name}Name`;

  const classParallelId = parent ? `${parent}.${classParallelIdField}` : classParallelIdField;
  const classParallelName = parent ? `${parent}.${classParallelNameField}` : classParallelNameField;
  const classLetterId = parent ? `${parent}.${classLetterlIdField}` : classLetterlIdField;
  const classLetterName = parent ? `${parent}.${classLetterlNameField}` : classLetterlNameField;

  const serviceId = parent ? `trainingGroup.${serviceIdField}` : serviceIdField;
  const serviceName = parent ? `trainingGroup.${serviceNameField}` : serviceNameField;

  const serviceClassId = parent ? `trainingGroup.${serviceClassIdField}` : serviceClassIdField;

  const fetchOptions = useCallback(
    async (query: string) => await lookupApi.getOrganization(query, userProfile.vedomstvoId, true),
    [userProfile.vedomstvoId]
  );

  const [nameField] = useField(organizationName);
  const [idField] = useField(organizationId);

  const initialOrganizationIdRef = useRef<number>(0);

  useEffect(() => {
    // Установка изначального значения селекта.
    if (idField.value) {
      initialOrganizationIdRef.current = idField.value as number;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedValue = (option?: SelectOptionType) => {
    if (option) {
      setFieldValue(organizationId, option.value);
      setFieldValue(organizationName, option.label);

      // Очистка зависимых полей, когда значение селекта отличается от предыдущего.
      if (initialOrganizationIdRef.current !== option.value) {
        initialOrganizationIdRef.current = option.value as number;
        setFieldValue(classParallelId, '');
        setFieldValue(classParallelName, '');
        setFieldValue(classLetterId, '');
        setFieldValue(classLetterName, '');

        setFieldValue(serviceClassId, '');
      }
    } else {
      setFieldValue(organizationId, '');
      setFieldValue(organizationName, '');
      setFieldValue(classParallelId, '');
      setFieldValue(classParallelName, '');
      setFieldValue(classLetterId, '');
      setFieldValue(classLetterName, '');
      setFieldValue(serviceId, '');
      setFieldValue(serviceName, '');

      setFieldValue(serviceClassId, '');
    }
  };

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={nameField.value}
    >
      <FormikSelect
        required
        name={organizationId}
        disabled={Boolean(
          requestStatusId === RequestStatusEnum.Draft ||
            (initialValues as RequestData)?.school?.schoolOrganizationId ||
            (initialValues as SchoolRequestData)?.schoolOrganizationId
        )}
        size="small"
        placeholder="Начните вводить..."
        loadOptions={fetchOptions}
        selectedValue={(option?: SelectOptionType) => {
          setSelectedValue(option);
        }}
        defaultValue={
          idField.value && {
            value: idField.value,
            label: nameField.value,
          }
        }
        options={[]}
      />
    </Field>
  );
};

export default OrganizationField;
