import React, { useCallback, useContext, useState } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmListItem } from '@mes-ui/lemma';
import TableOptions from '../../../../components/table-options';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import DropDown from '../../../../components/drop-down';
import AddModule from '../../components/modals/add-module';
import { ServiceContext } from '../../index';
import { ProgrammModule } from '../../../../types/entities';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import RemoveModal from '../../../../components/remove-modal';
import SimpleTable from '../../../../components/table/simple-table';

const Module: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [editableItem, setEditableItem] = useState();
  const [removedId, setRemovedId] = useState(0);

  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitForm = useCallback(
    async (values: ProgrammModule) => {
      if (values.id) {
        await serviceTemplateApi.updateModule(values.serviceId, values.id, values);
      } else {
        await serviceTemplateApi.createModule(values.serviceId, values);
      }
      setShowModal(false);
      setEditableItem(undefined);
      updateService();
    },
    [updateService]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Модули <span className="table-data__required" />{' '}
            <span className="color-gray-dark">
              {' \u00A0'} {serviceData?.programmModule?.length || ''}
            </span>
          </>
        )}
        headingControl={() =>
          checkEditable && (
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-plus"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          )
        }
      >
        {serviceData?.programmModule?.length ? (
          <SimpleTable
            data={serviceData?.programmModule?.map((item, index) => ({
              ...item,
              count: index + 1,
            }))}
            columns={[
              {
                dataIndex: 'count',
                title: '№',
                render: (item: any) => item.count,
                width: '40px',
              },
              {
                dataIndex: 'name',
                title: 'Наименование',
                render: (item: any) => <div className="overflow-wrap-anywhere">{item.name}</div>,
              },
              {
                dataIndex: '',
                title: '',
                render: (item: any) =>
                  checkEditable && (
                    <DropDown
                      dataTest="moduleOptions"
                      component={() => <TableOptions />}
                    >
                      <>
                        <LmListItem
                          dataTest="editModule"
                          text="Редактировать"
                          icon="outline-edit-edit"
                          iconSize={20}
                          onClick={() => {
                            setShowModal(true);
                            setEditableItem(item);
                          }}
                          disabled={!item.canChangeModule}
                        />
                        <LmListItem
                          dataTest="deleteModule"
                          text="Удалить"
                          icon="outline-edit-trash-alt"
                          iconSize={20}
                          onClick={() => {
                            setRemovedId(item.id);
                          }}
                          disabled={!item.canChangeModule}
                        />
                      </>
                    </DropDown>
                  ),
                width: '40px',
              },
            ]}
          />
        ) : (
          <div className="table-no-data">Нет данных</div>
        )}
      </Panel>

      <AddModule
        show={showModal}
        hideModal={() => {
          setShowModal(false);
          setEditableItem(undefined);
        }}
        module={editableItem}
        onSubmit={submitForm}
        serviceData={serviceData}
      />
      <RemoveModal
        title="Модули"
        description="Внимание! Данный объект будет удален из реестра «модули». Хотите продолжить?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={async () => {
          await serviceTemplateApi.deleteModule(serviceData.id, removedId);
          await updateService();
          setRemovedId(0);
        }}
      />
    </>
  );
};

export default Module;
