import React, { useState } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { useGetChangeLogQuery } from '../../store/static-content';
import { formatDate } from '../../lib/utils/date';

const DesktopInfo = () => {
  const [open, setOpen] = useState(false);

  const { data } = useGetChangeLogQuery();

  return data ? (
    <Panel>
      <div className="container">
        <div className="desktop-info">
          <div className="desktop-info__head">
            <div className="line-height-text">
              <div className="desktop-info__title">Версия МЭШ.Кружки</div>
              <Push size={4} />
              <span className="color-gray-dark">{`Текущая версия ${data.releaseVersion} от ${formatDate(
                data.releaseDate
              )}`}</span>
            </div>
            <button
              type="button"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <LmIcon
                  icon="filled-chevrons-large-up"
                  size={24}
                  color="var(--LM-neutrals-day-700)"
                />
              ) : (
                <LmIcon
                  icon="filled-chevrons-large-down"
                  size={24}
                  color="var(--LM-neutrals-day-700)"
                />
              )}
            </button>
          </div>
          {open && (
            <div
              className="desktop-info__body"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          )}
        </div>
      </div>
    </Panel>
  ) : null;
};

export default DesktopInfo;
