import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useField } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmRadio, LmText } from '@mes-ui/lemma';
import serviceClassApi, { MAX_LIST_ITEM_COUNT } from '../../../../../lib/api/service-class';
import { transformServiceGroupListDonm } from '../../../../../lib/utils/requests';
import { routes } from '../../../../../config/constants';
import { handleBookingChange } from '../../../../../redux/ducks/booking';
import { ScheduleDays, ServiceClassListData } from '../../../../../types/service-class';
import { bookingApi } from '../../../../../lib/api/booking';
import Field from '../../../../../components/fields/field';
import { LoaderCustom } from '../../../../../components/loader-custom';
import SimpleTable from '../../../../../components/table/simple-table';

type ServiceClassList = {
  code: string;
  dates: ScheduleDays.TransformDays[] | undefined;
  id: number;
  name: number;
  teacher: string;
  volumes: string;
};

type Props = {
  serviceId: number;
  trainingGroupId?: number;
  disabledRadio?: boolean;
  contingentGuid: string;
  isNeedBooking?: boolean;
};

const fieldName = 'trainingGroup.serviceClass.id';

const ServiceClasses: React.FC<Props> = ({
  serviceId,
  trainingGroupId,
  contingentGuid,
  isNeedBooking,
  disabledRadio = false,
}) => {
  const [serviceClassList, setServiceClassList] = useState<ServiceClassList[]>([]);
  const [loading, setLoading] = useState(false);
  const [openElement, setOpenElement] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const response = await serviceClassApi.getServiceClassList(
          serviceId,
          trainingGroupId,
          undefined,
          undefined,
          undefined,
          MAX_LIST_ITEM_COUNT
        );
        const listGroup = response?.map((sc: ServiceClassListData<ScheduleDays.ScheduleData>) =>
          transformServiceGroupListDonm(sc)
        );

        setServiceClassList(listGroup);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (serviceId) {
      fetch();
    }
  }, [contingentGuid, serviceId, trainingGroupId]);

  const renderDates = (periodsSchedule: ScheduleDays.TransformDays[]) => {
    if (periodsSchedule) {
      return periodsSchedule.map((item: ScheduleDays.TransformDays) => {
        const days = (
          <div>
            {editDay(item.monday)}
            {editDay(item.tuesday)}
            {editDay(item.wednesday)}
            {editDay(item.thursday)}
            {editDay(item.friday)}
            {editDay(item.saturday)}
            {editDay(item.sunday)}
          </div>
        );

        return periodsSchedule.length > 1 ? (
          <div className="flex flex-column">
            <div className="flex items-center">
              <span>{item.period}&nbsp;</span>
              <span
                role="presentation"
                className="brand-link cursor-pointer flex items-center"
                onClick={() => (openElement === item.id ? setOpenElement(0) : setOpenElement(item.id))}
              >
                {item.startEnd}{' '}
                <LmIcon
                  icon={openElement === item.id ? 'filled-chevrons-small-up' : 'filled-chevrons-small-down'}
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
            </div>
            {openElement === item.id && (
              <>
                <Push
                  orientation="vertical"
                  size={8}
                />
                <div>{days}</div>
                <Push
                  orientation="vertical"
                  size={8}
                />
              </>
            )}
          </div>
        ) : (
          <div>{days}</div>
        );
      });
    }
  };

  function editDay(day: any) {
    if (Array.isArray(day)) {
      return day.map((item: string) => item);
    } else {
      return day.fromTo ? (
        <div>
          <span>{day.name}&nbsp;</span>
          {day.fromTo}
        </div>
      ) : null;
    }
  }

  const [field, , helperField] = useField(fieldName);

  const handleSelectServiceClass = async (id: number) => {
    helperField.setTouched(true);
    helperField.setValue(id);
    if (isNeedBooking) {
      try {
        const booking = await bookingApi.preBookingByServiceClass(id);

        dispatch(handleBookingChange(booking, id));
      } catch {
        dispatch(handleBookingChange());
      }
    }
  };

  return loading ? (
    <LoaderCustom size={16} />
  ) : serviceClassList?.length > 0 ? (
    <Field
      label="Выбор группы"
      editMode
      required
    >
      <SimpleTable
        data={serviceClassList}
        headerBorderTop={false}
        columns={[
          {
            dataIndex: 'id',
            title: '',
            render: (item: any) => (
              <LmRadio
                dataTest={fieldName}
                name={fieldName}
                boxSize="large"
                onChange={() => handleSelectServiceClass(item.id)}
                checked={item.id === field.value}
                disabled={disabledRadio}
                resetAllPadding
              />
            ),
            width: '52px',
          },
          {
            dataIndex: 'name',
            title: 'Код/ Наименование группы',
            render: (item: any) => (
              <>
                <Link
                  className="brand-link cursor-pointer"
                  to={`${routes.serviceClasses}/${item.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.code}
                </Link>
                <Push size={4} />
                <LmText
                  variant="md"
                  color="note"
                >
                  {item.name}
                </LmText>
              </>
            ),
            width: '240px',
          },
          {
            dataIndex: 'teacher',
            title: 'Преподаватель',
            render: (item: any) => item.teacher,
          },
          {
            dataIndex: 'dates',
            title: 'Расписание',
            render: (item: any) => renderDates(item.dates),
          },
          {
            dataIndex: 'volumes',
            title: 'Свободно/ Всего',
            render: (item: any) => item.volumes,
            width: '151px',
          },
        ]}
      />
    </Field>
  ) : null;
};

export default ServiceClasses;
