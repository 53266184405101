import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { documentTypeField } from './document';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { IssuedPlaceEnum } from '../../../../mock-data/issued-place';

type IssuedPlaceProps = FieldProps<string> & {
  vedomstvoId?: number;
  size?: 1 | 2 | 3;
  parent?: string;
  showErrorImmediately?: boolean;
};

export const issuePlace = 'typeIssuePlaceId';

const IssuedPlace = ({
  label = 'Место выдачи',
  name,
  editMode,
  required,
  vedomstvoId,
  disabled = false,
  size = 3,
  parent,
  showErrorImmediately,
  ...props
}: IssuedPlaceProps) => {
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);
  const [optionsSet, setOptionsSet] = useState<boolean>(false);
  const fieldId = parent ? `${parent}.${name}Id` : `${name}Id`;
  const fieldName = parent ? `${parent}.${name}Name` : `${name}Name`;
  const [id] = useField(fieldId);
  const [lab, , helpersLabel] = useField(fieldName);

  const documentTypeIdField = buildFormFieldName(parent, documentTypeField);
  const [documentTypeIdOptions] = useField(documentTypeIdField);

  useEffect(() => {
    const fetchOptions = async () => {
      setOptionsData(await dictionariesApi.getTypeIssuePlace());
      setOptionsSet(true);
    };

    if (editMode && !optionsSet) {
      fetchOptions();
    }
  }, [editMode, optionsSet, vedomstvoId]);

  const defaultValue =
    documentTypeIdOptions.value === DocumentTypeEnum.BirthCertificateForeign
      ? {
          value: IssuedPlaceEnum.ForeignStates,
          label: 'Иностранные государства',
        }
      : id.value
        ? {
          value: id.value,
          label: lab.value,
        }
        : null;

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={lab.value}
      size={size}
      {...props}
    >
      <FormikSelect
        required
        label=""
        showErrorImmediately={showErrorImmediately}
        name={fieldId}
        size="small"
        withSearch
        options={optionsData}
        placeholder="Выберите..."
        defaultValue={defaultValue}
        disabled={disabled || documentTypeIdOptions.value === DocumentTypeEnum.BirthCertificateForeign}
        selectedValue={(selected?: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : undefined);
        }}
        clearable
      />
    </Field>
  );
};

export default IssuedPlace;
