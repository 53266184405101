/** Типы обучения в виде перечисления */
export enum EducationTypeEnum {
  None = 0,
  ChildrenEducation = 1,
  ProfessionalEducation = 2,
  DayCareCentersEducation = 8,
  SportEducation = 16,
  ArtHouseEducation = 32,
  ChildrenNonDogmEducation = 64,
  VirtualAssistantEducation = 128,
  All = ChildrenEducation |
    ProfessionalEducation |
    DayCareCentersEducation |
    SportEducation |
    ArtHouseEducation |
    ChildrenNonDogmEducation |
    VirtualAssistantEducation,
}

export enum EducationTypeToCamelCaseEnum {
  none = 0,
  childrenEducation = 1,
  professionalEducation = 2,
  dayCareCentersEducation = 8,
  sportEducation = 16,
  artHouseEducation = 32,
  childrenNonDogmEducation = 64,
  virtualAssistantEducation = 128,
}

export const EducationTypeEnumDescription = new Map<EducationTypeEnum, string>([
  [EducationTypeEnum.None, 'Ни один'],
  [EducationTypeEnum.ChildrenEducation, 'Детские объединения департамента образования'],
  [EducationTypeEnum.ProfessionalEducation, 'Профподготовка'],
  [EducationTypeEnum.DayCareCentersEducation, 'Группы по уходу и присмотру за детьми школьного возраста'],
  [EducationTypeEnum.SportEducation, 'Спортивные секции'],
  [EducationTypeEnum.ArtHouseEducation, 'Дома творчества'],
  [EducationTypeEnum.ChildrenNonDogmEducation, 'Детские объединения'],
  [EducationTypeEnum.VirtualAssistantEducation, 'Виртуальный помошник'],
  [EducationTypeEnum.All, 'Все'],
]);
