import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Panel, Push, SimpleTable, TableDataType } from '@mosru/esz_uikit';
import { reportsApi } from '../../../lib/api/reports';
import { buildReportHeader, transformResultDataTable } from '../../../lib/utils/reports';
import { DataTable } from '../../../types/reports';

const SavedReport = () => {
  const { id } = useParams<Record<string, string>>();
  const [dataTable, setDataTable] = useState<DataTable>(Object);
  const [headerTable, setHeaderTable] = useState<any>([]);
  const [parameters, setParameters] = useState<[]>([]);
  const [tableName, setTableName] = useState<string>('');
  const [fixedData, setFixedDate] = useState<TableDataType[]>();

  useEffect(() => {
    const fetch = async () => {
      const savedReport = await reportsApi.getSavedReport(id, false);

      if (savedReport) {
        setHeaderTable(buildReportHeader(savedReport.header));
        setFixedDate(transformResultDataTable(savedReport?.data).total);
        setDataTable({
          items: transformResultDataTable(savedReport?.data).data,
          fixedColumns: savedReport.fixedColumns,
          title: savedReport.tableName,
          itemsCount: undefined,
        });
        setParameters(savedReport.parameters);
        setTableName(savedReport.tableName);
      }
    };

    fetch();
  }, [id]);

  return (
    <>
      {dataTable.items && (
        <Panel>
          <Push size={20} />
          <div className="container">
            <h4 className="save__report--title">{tableName}</h4>
            <ul className="save__report--result">
              {parameters.map((item: any) => {
                return (
                  <li className="save__report--result-item">
                    <span>{item.item1}</span> : <span className="result">{item.item2}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <Push size={20} />
          <div
            style={{
              width: '100%',
            }}
          >
            <SimpleTable
              border
              fixedHeader
              fixedData={fixedData}
              data={dataTable.items}
              pageSize={100}
              columns={headerTable}
              fixedCols={dataTable.fixedColumns}
            />
          </div>
        </Panel>
      )}
    </>
  );
};

export default SavedReport;
