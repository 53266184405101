import { LmIcon } from '@mes-ui/lemma';

const TableOptions = () => {
  return (
    <span
      data-test="tableOptions"
      className="table-options drop-down-bg"
    >
      <LmIcon
        icon="filled-menus-more-horizontal"
        size={24}
        color="var(--LM-neutrals-day-600)"
      />
    </span>
  );
};

export default TableOptions;
