import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
// @ts-ignore
import Files from 'react-files';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import { ReactFilesError, ReactFilesFile } from '../../../../../../../types/files';
import learnerApi from '../../../../../../../lib/api/learner';
import { File } from './file';
import { FileData } from '../../../../../../../types/document-modal-other';

type Props = {
  id: number;
  fileIds: number[];
  files: FileData[];
  pupilId: string;
  accessCheck: boolean;
  setFiles: (value: FileData[]) => void;
  setFileIds: (value: number[]) => void;
};

export const UploadFiles: React.FC<Props> = ({ id, setFileIds, setFiles, fileIds, files, pupilId, accessCheck }) => {
  const { setFieldValue } = useFormikContext<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFieldValue('files', files);
  }, [files, setFieldValue]);

  const uploadFile = (newFiles: ReactFilesFile[]) => {
    if (newFiles) {
      const formData = new FormData();

      formData.append('docFile', newFiles[0]);

      (async () => {
        setIsLoading(true);
        try {
          const photoData = await learnerApi.postFileUploadOtherCity(pupilId, id ?? 0, formData);
          const fl = [...files, photoData];
          const filesId = [...fileIds, photoData.id];

          setFiles(fl);
          setFileIds(filesId);
          setFieldValue('fileIds', filesId);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  };

  const onChangeHandler = (files: ReactFilesFile[]) => {
    if (!accessCheck) {
      return;
    }
    uploadFile(files);
  };

  return (
    <>
      <Push size={16} />
      Предоставленные документы <span className="table-data__required" />
      <Push size={8} />
      <Files
        multiple={false}
        clickable={accessCheck}
        className="auth-files-dropzone"
        onChange={(files: ReactFilesFile[]) => onChangeHandler(files)}
        onError={(error: ReactFilesError) => {
          console.error(error);
        }}
        accepts={['.jpeg', '.png', '.bmp', '.pdf', '.rar', '.zip']}
      >
        <div className="auth-files-dropzone__title">Перетащите файл сюда, чтобы загрузить</div>
        <Push size={4} />
        Формат файлов: jpeg, png, bmp, pdf, rar, zip
        <Push size={16} />
        <LmButton
          type="button"
          loading={isLoading}
          disabled={!accessCheck}
        >
          Выбрать файл
        </LmButton>
      </Files>
      {!!files?.length && <Push size={8} />}
      {files?.map((item: FileData) => (
        <File
          id={item.id}
          key={item.id}
          files={files}
          size={item.size}
          name={item.name}
          fileIds={fileIds}
          setFiles={setFiles}
          setFileIds={setFileIds}
          accessCheck={accessCheck}
        />
      ))}
    </>
  );
};
