import React from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import { SearchLearnersInitialFormData } from '../../../../../types/learners';
import { SelectOptionType } from '../../../../../types/entities';

const SERVICE_ID = 'serviceId';

type Props = {
  service?: SelectOptionType;
  educationType: number | null;
  vedomstvo: number | null;
  organization: number | null;
  setService: (value?: SelectOptionType) => void;
};

export const ServiceField = ({ service, educationType, vedomstvo, organization, setService }: Props) => {
  const { setFieldValue } = useFormikContext<SearchLearnersInitialFormData>();

  const onSelectedValue = (option?: SelectOptionType) => {
    if (!option) {
      setService(undefined);
      setFieldValue(SERVICE_ID, null);
    } else {
      setService(option);
    }
  };

  return (
    <FormikSelect
      size="small"
      label="Детское объединение / услуга"
      withSearch
      options={[]}
      showTooltip
      disabledPortalTooltip
      name={SERVICE_ID}
      defaultValue={service}
      selectedValue={onSelectedValue}
      placeholder="Начните вводить..."
      loadOptions={async (query) => await lookupApi.getService(query, educationType, vedomstvo, organization)}
    />
  );
};
