import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import Popup from '../../../../components/modals/popup';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import { ScheduleDays, ServiceClassListData, ServiceClassTransfer } from '../../../../types/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import serviceClassApi from '../../../../lib/api/service-class';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';
import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceClassContext } from '../../service-class';

type Props = {
  open: boolean;
  close: () => void;
};

const initialValues: ServiceClassTransfer = {
  megaRelationIds: [],
  serviceClassId: null,
  educationTypeId: 0,
  docNumber: '',
  docDate: '',
  documentTypeId: 3,
  isExamParticipate: false,
};

const TransferGroup: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);
  const { updateServiceClass } = useContext(ServiceClassContext);
  const [documents, setDocuments] = useState<SelectOptionType[]>([]);
  const [serviceClasses, setServiceClasses] = useState<SelectOptionType[]>([]);
  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const submitForm = useCallback(
    async (values: ServiceClassTransfer) => {
      values.megaRelationIds = selected || [];
      values.educationTypeId = serviceClassData.educationTypeId;
      await serviceClassApi.transferClass(serviceClassData.id || 0, values);
      close();
      fetchTable();
      setSelected([]);
      setOpenDetails(false);
      updateServiceClass();
    },
    [
      selected,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      close,
      fetchTable,
      setSelected,
      setOpenDetails,
      updateServiceClass,
    ]
  );

  useEffect(() => {
    const fetch = async () => {
      const documentTypes = await dictionariesApi.getDocumentTypes(3);

      setDocuments(documentTypes);
      const needExcludeServiceId = [EducationTypeEnum.ArtHouseEducation, EducationTypeEnum.SportEducation].includes(
        serviceClassData.educationTypeId
      );

      const serviceClasses: ServiceClassListData<ScheduleDays.ScheduleData>[] =
        await serviceClassApi.getServiceClassList(
          needExcludeServiceId ? undefined : serviceClassData.serviceId,
          undefined,
          serviceClassData.organizationId,
          serviceClassData.educationTypeId
        );

      const filterServiceClasses = serviceClasses
        .filter((item) => item.id !== serviceClassData.id)
        .map((item) => {
          return {
            value: item.id,
            label: `${item.id}, ${item.code}, ${item.name}`,
          };
        });

      setServiceClasses(filterServiceClasses);
    };

    fetch();
  }, [
    serviceClassData.organizationId,
    serviceClassData.trainingGroupId,
    serviceClassData.serviceId,
    serviceClassData.educationTypeId,
    serviceClassData,
  ]);

  return (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<ServiceClassTransfer>) => {
        const { handleSubmit, isSubmitting, isValid, values, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="transferGroupPopup"
              open={open}
              title="Перевод обучающегося в другую группу обучения"
              buttonPrimaryText="Перевести"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid || !values.serviceClassId,
              }}
              onSubmit={handleSubmit}
              onClose={close}
              onClearData={resetForm}
            >
              <>
                <FormikSelect
                  label="Группа обучения"
                  required
                  showErrorImmediately
                  name="serviceClassId"
                  size="small"
                  withSearch
                  options={serviceClasses}
                  maxMenuHeight={205}
                  placeholder="Выберите..."
                />
                <Push size={16} />
                <FormikSelect
                  name="documentTypeId"
                  label="Тип документа"
                  required
                  size="small"
                  options={documents}
                  placeholder="Выберите..."
                />
                <Push size={16} />
                <FormikInput
                  label="Номер документа"
                  size="small"
                  name="docNumber"
                  placeholder="Введите..."
                />
                <Push size={16} />
                <FormikDatePicker
                  label="Дата документа"
                  size="small"
                  name="docDate"
                  disablePortal={false}
                  placement="top-start"
                />
                <Push size={16} />
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default TransferGroup;

const getValidationSchema = () =>
  objectYup().shape({
    serviceClassId: stringYup().required('Выберите группу обучения').typeError('Выберите группу обучения'),
    documentTypeId: stringYup().nullable().required('Выберите тип документа'),
    docDate: docDate.nullable(),
  });
