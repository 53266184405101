import React, { useContext, useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Push } from '@mosru/esz_uikit';
import SavePanel from '../../../../components/save-panel';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ServiceContext } from '../..';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { FinancingData } from '../../../../types/service';
import Financing from '../components/financing';
import { checkPeriodPrice } from '../../../../lib/utils/validation';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const FinancingPanel: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const initialValues = serviceData?.financing ?? ({} as FinancingData);
  const initialErrors = useInitialErrors(initialValues, financingValidationSchema());
  const [editMode, setEditMode] = useState(!setEditModeParent);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitForm = async (values: FinancingData) => {
    try {
      if (values) {
        await serviceTemplateApi.updateFinancing(serviceData.id, values);
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        updateService();
      }
    } catch {}
  };

  return (
    <Formik
      onSubmit={async (values, formikHelpers) => {
        await submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={financingValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<FinancingData>) => {
        const { isSubmitting, isValid, resetForm, handleSubmit, submitForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Financing
              setEditModeParent={setEditModeParent}
              editMode={editMode}
              setEditMode={setEditMode}
              checkEditable={checkEditable}
            />
            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent && setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default FinancingPanel;

export const financingValidationSchema = (isTemplate?: boolean) =>
  objectYup().shape({
    typeFinancingId: stringYup().required('Выберите тип финансирования'),
    typeValueServiceId: stringYup()
      .nullable()
      .when('typeFinancingId', {
        is: '2',
        then: (s) => s.required('Выберите периодичность оплаты'),
      }),
    periodPrice: isTemplate
      ? stringYup().nullable()
      : stringYup()
        .nullable()
        .test((value: string | null | undefined, ctx: any) => checkPeriodPrice(value, ctx)),
  });
