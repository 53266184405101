import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import { ErrorPerson } from '../../../../../../types/like-modal';

type Props = ErrorPerson;

export const Error: React.FC<Props> = ({ color, text, show }) => {
  const currentInfo = (color: string) => {
    switch (color) {
      case 'danger':
        return (
          <LmInfoBox
            dataTest="newPersonError"
            className="infobox--full-width"
            variant="alert"
            description={text}
            hidenFooter
          />
        );
      case 'success':
        return (
          <LmInfoBox
            dataTest="newPersonSuccess"
            className="infobox--full-width"
            variant="success"
            description={`Создана новая персона в МЭШ.Контингент: ${text}.`}
            hidenFooter
          />
        );
      default:
        return null;
    }
  };

  return show ? (
    <>
      {currentInfo(color)}
      {text !== 'success' && (
        <Push
          size={12}
          orientation="vertical"
        />
      )}
    </>
  ) : null;
};
