import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TeacherContext } from '..';
import Dialog from '../../../../components/modals/dialog';
import teacherApi from '../../../../lib/api/teacher';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const AddArchiveModal = ({ show, onCloseHandler }: Props) => {
  const { teacher } = useContext(TeacherContext);
  const [loading, setLoading] = useState<boolean>(false);

  const historyRouter = useHistory();

  const addArchiveTeacher = async () => {
    setLoading(true);

    try {
      await teacherApi.archiveTeacher(teacher?.id as number);
      historyRouter.goBack();
    } finally {
      setLoading(false);
      onCloseHandler();
    }
  };

  return (
    <Dialog
      dataTest="archiveTeacher"
      isOpenDialog={show}
      title="Отправка преподавателя в архив"
      description="Сведения о преподавателе будут отправлены в архив. Продолжить?"
      variant="alert"
      buttonPrimaryText="Да, продолжить"
      primaryButtonModifiers={{
        loading,
      }}
      onClickSeconadaryButton={onCloseHandler}
      onClickPrimaryButton={addArchiveTeacher}
    />
  );
};

export default AddArchiveModal;
