import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { EducationTypeEnum } from '../../types/education-type';
import ServiceClassDonmCreate from './donm/create';
import { ServiceClassContext, ServiceClassContextType } from './service-class';
import { ServiceClassData } from '../../types/service-class';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import ServiceClassDsitCreate from './dsit/create';
import ServiceClassDkgmCreate from './dkgm/create';
import { getEmptyFunctionToOverride, hasGeneralAccess } from '../../lib/utils';
import { generalAccess } from '../../types/authorization-data';
import ServiceClassPreparationCreate from './preparation/create';
import ServiceClassGpdCreate from './day-care/create';
import useQuery from '../../hooks/use-query';
import ServiceClassDtCreate from './digital-tutor/create';

const initialValue: ServiceClassData = {
  educationTypeId: 0,
  organization: '',
  name: '',
  code: '',
  description: '',
  trainingGroupName: '',
  isArchive: false,
  teachers: [],
  teacher: {
    educationTypeId: 0,
    list: [],
  },
  schedule: {
    educationTypeId: 0,
    list: [],
  },
};

const CreateServiceClass = () => {
  const [startDateSchedulePeriod, setStartDateSchedulePeriod] = useState<Date | null>(null);
  const [endDateSchedulePeriod, setEndDateSchedulePeriod] = useState<Date | null>(null);

  const { edType } = useParams<Record<string, string | undefined>>();
  const serviceId = useQuery().get('serviceId');

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const initialValues: ServiceClassContextType = useMemo(() => {
    const educationTypeId = edType ? parseInt(edType) : 0;

    return {
      serviceClassData: {
        ...initialValue,
        educationTypeId,
        organizationId: userProfile.organizationId,
        teacher: {
          ...initialValue.teacher,
          educationTypeId,
        },
        schedule: {
          ...initialValue.schedule,
          educationTypeId,
        },
        serviceId: serviceId ? parseInt(serviceId) : undefined,
      },
      updateServiceClass: getEmptyFunctionToOverride(),
      adminView: hasGeneralAccess(userProfile, generalAccess.AdminView),
      adminEdit: hasGeneralAccess(userProfile, generalAccess.AdminEdit),
      admin:
        hasGeneralAccess(userProfile, generalAccess.AdminView) &&
        hasGeneralAccess(userProfile, generalAccess.AdminEdit),
      oiv: hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV),
      startDateSchedulePeriod,
      endDateSchedulePeriod,
      setStartDateSchedulePeriod,
      setEndDateSchedulePeriod,
    };
  }, [
    edType,
    serviceId,
    userProfile,
    startDateSchedulePeriod,
    endDateSchedulePeriod,
    setStartDateSchedulePeriod,
    setEndDateSchedulePeriod,
  ]);

  const edTypeId = edType ? parseInt(edType) : null;

  const currentServiceClass = () => {
    switch (edTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return <ServiceClassDonmCreate showSearchServiceBtn />;
      case EducationTypeEnum.ArtHouseEducation:
        return <ServiceClassDkgmCreate />;
      case EducationTypeEnum.SportEducation:
        return <ServiceClassDsitCreate />;
      case EducationTypeEnum.DayCareCentersEducation:
        return <ServiceClassGpdCreate />;
      case EducationTypeEnum.ProfessionalEducation:
        return <ServiceClassPreparationCreate />;
      case EducationTypeEnum.VirtualAssistantEducation:
        return <ServiceClassDtCreate />;
      default:
        return null;
    }
  };

  return (
    <>
      {edType && (
        <ServiceClassContext.Provider value={initialValues}>{currentServiceClass()}</ServiceClassContext.Provider>
      )}
    </>
  );
};

export default CreateServiceClass;
