import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmLoader } from '@mes-ui/lemma';
import { ServiceData, SignInfoData, TemplateService } from '../../types/service';
import TemplateServicesCreate from './template-services/create';
import { templateApi } from '../../lib/api/template';
import { CreateTemplateContext, TemplateContext } from './create-template';
import { accessVedomst } from '../../mock-data/access-enum';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';

const CopyTemplate = () => {
  const [serviceData, setServiceData] = useState<TemplateService.Data>();
  const { id } = useParams<Record<string, string>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let data = (await templateApi.getTemplate(id)) as TemplateService.Data;

        data = {
          ...data,
          id: 0,
          info: {
            ...data.info,
            templateId: 0,
            serviceFormId: 1,
          },
          test: {
            ...data.test,
            templateId: 0,
            toursNumber: data.test.toursNumber ?? 1,
          },
          schedule: {
            ...data.schedule,
            templateId: 0,
          },
          admissionPlan: undefined,
          financing: {
            ...data.financing,
            templateId: 0,
          },
          candidateRestrictionType: {
            ...data.candidateRestrictionType,
            templateId: 0,
          },
          sign: {} as SignInfoData,
          copyTemplateId: id,
        };
        setServiceData(data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  const initialValues: CreateTemplateContext = useMemo(
    () => ({
      serviceData: serviceData || ({} as ServiceData),
      edType: serviceData?.educationTypeId,
      isKdc: userProfile.vedomstvoId === accessVedomst.Kdc,
    }),
    [serviceData, userProfile]
  );

  return (
    <>
      {serviceData ? (
        <TemplateContext.Provider value={initialValues}>
          <TemplateServicesCreate />
        </TemplateContext.Provider>
      ) : isLoading ? (
        <>
          <Push size={12} />
          <div className="loader-auto">
            <LmLoader view="page" />
          </div>
        </>
      ) : null}
    </>
  );
};

export default CopyTemplate;
