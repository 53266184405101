import React, { Key } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmInputNumber, LmListItem } from '@mes-ui/lemma';
import DropDown from '../../components/drop-down';
import TableOptions from '../../components/table-options';
import { hasAccessObjectAny } from '../../lib/utils';
import { allowOnlyNumbersNoSpace } from '../../lib/utils/validation';
import { accessAction, accessObject } from '../../mock-data/access-enum';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';

type Props = {
  code: string;
  setCode: (value: string) => void;
  setRemovedId: () => void;
  editMode: boolean;
  isArchive: boolean;
  setEditMode: (v: boolean) => void;
  submitHandler: () => void;
  restoreHandler: () => void;
};

const Edit: React.FC<Props> = ({
  code,
  submitHandler,
  setCode,
  setRemovedId,
  editMode,
  setEditMode,
  isArchive,
  restoreHandler,
}) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const handleCodeChange = (value: Key) => {
    const valueString = `${value || ''}`;

    setCode(valueString === '0' ? '' : valueString.replace('-', ''));
  };

  return (
    <div className="disciplines-table-comment">
      <div className="disciplines-table-comment__body">
        {editMode ? (
          <LmInputNumber
            dataTest="code"
            variant="vertical"
            name="code"
            value={code}
            maxLength={5}
            min={0}
            placeholder="0"
            onChange={handleCodeChange}
            onKeyPress={(e) => allowOnlyNumbersNoSpace(e, true)}
          />
        ) : isArchive ? (
          <span className="color-gray-dark">{code}</span>
        ) : (
          code
        )}
      </div>
      <div className="disciplines-table-comment__controls">
        {editMode ? (
          <>
            <LmButton
              dataTest="closeDiscipline"
              type="button"
              variant="secondary"
              icon="filled-edit-close"
              iconSize={20}
              onClick={() => {
                setEditMode(false);
              }}
            />
            <Push
              size={12}
              orientation="horizontal"
            />
            <LmButton
              dataTest="submitDiscipline"
              type="button"
              color="success"
              variant="outline"
              icon="filled-edit-checkmark"
              iconSize={20}
              onClick={() => {
                submitHandler();
              }}
            />
          </>
        ) : (
          <>
            {(hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Archive) ||
              (hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Edit) && !isArchive)) && (
              <DropDown
                dataTest="disciplineOptions"
                component={() => <TableOptions />}
              >
                <>
                  {isArchive ? (
                    hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Archive) && (
                      <LmListItem
                        dataTest="restoreDiscipline"
                        text="Вернуть из архива"
                        icon="filled-arrows-wait"
                        iconSize={20}
                        onClick={restoreHandler}
                      />
                    )
                  ) : (
                    <>
                      {hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Edit) && (
                        <LmListItem
                          dataTest="editDiscipline"
                          text="Редактировать"
                          icon="outline-edit-edit"
                          iconSize={20}
                          onClick={() => setEditMode(true)}
                        />
                      )}
                      {hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Archive) && (
                        <LmListItem
                          dataTest="deleteDiscipline"
                          text="Удалить"
                          icon="outline-edit-trash-alt"
                          iconSize={20}
                          onClick={setRemovedId}
                        />
                      )}
                    </>
                  )}
                </>
              </DropDown>
            )}
            <Push
              size={24}
              orientation="horizontal"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Edit;
