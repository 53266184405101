import './LightTable.sass';

export type RowItemType = {
  description: {
    name: string;
  }[];
};

export type LightTableType = {
  column: { [index: string]: string }[];
  row: RowItemType[];
};

type Props = {
  data: LightTableType | undefined;
};

const LightTable: React.FC<Props> = ({ data }) => {
  return (
    <table className="table-classic">
      <thead>
        <tr className="table-classic__row">
          {data?.column?.map((item) => {
            return <th className="table-classic__header">{item.name}</th>;
          })}
        </tr>
      </thead>

      <tbody>
        {data?.row?.map((item) => {
          return (
            <tr className="table-classic__row">
              {item.description?.map((item) => {
                return <td className="table-classic__cell">{item.name}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LightTable;
