import React from 'react';
import classNames from 'classnames';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmListItem, LmTextArea } from '@mes-ui/lemma';
import DropDown from '../../components/drop-down';
import TableOptions from '../../components/table-options';

type Props = {
  comment: string;
  editMode: boolean;
  setRemovedId: () => void;
  submitHandler: () => void;
  setEditMode: (v: boolean) => void;
  setComment: (value: string) => void;
};

const FavoriteEdit: React.FC<Props> = ({ comment, submitHandler, setComment, setRemovedId, editMode, setEditMode }) => {
  return (
    <div className={classNames('favorite-table-comment', editMode && 'favorite-table-comment--edit')}>
      <div className="favorite-table-comment__body">
        {editMode ? (
          <div className="favorite-table-comment__textarea">
            <LmTextArea
              name="comment"
              dataTest="comment"
              widthFull
              value={comment}
              minRows={2}
              maxRows={2}
              onChange={(value) => {
                const valueString = value as string;

                setComment(valueString);
              }}
            />
          </div>
        ) : (
          comment
        )}
      </div>
      <div className="favorite-table-comment__controls">
        {editMode ? (
          <>
            <Push
              size={24}
              orientation="horizontal"
            />
            <LmButton
              dataTest="closeFavorite"
              type="button"
              variant="secondary"
              onClick={() => {
                setEditMode(false);
              }}
              icon="filled-edit-close"
              iconSize={20}
            />
            <Push
              size={8}
              orientation="horizontal"
            />
            <LmButton
              dataTest="submitFavorite"
              type="button"
              variant="outline"
              color="success"
              disabled={!comment || !comment.trim()}
              onClick={submitHandler}
              icon="filled-edit-checkmark"
              iconSize={20}
            />
          </>
        ) : (
          <>
            <Push
              size={16}
              orientation="horizontal"
            />
            <DropDown
              dataTest="favoriteOptions"
              component={() => <TableOptions />}
            >
              <>
                <LmListItem
                  dataTest="editFavorite"
                  text="Редактировать комментарий"
                  icon="outline-edit-edit"
                  iconSize={20}
                  onClick={() => setEditMode(true)}
                />
                <LmListItem
                  dataTest="deleteFavorite"
                  text="Удалить из избранного"
                  icon="outline-edit-trash-alt"
                  iconSize={20}
                  onClick={setRemovedId}
                />
              </>
            </DropDown>
          </>
        )}
      </div>
    </div>
  );
};

export default FavoriteEdit;
