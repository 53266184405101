import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistory from '../../../components/change-history';
import { routes } from '../../../config/constants';
import Favorite from '../../../components/favorite';
import DigitalTutorDetails from './details';
import { ServiceContext } from '..';
import { getHeaderLink, getTabsButton } from '../utils';
import ToArchiveModal from '../components/modals/to-archive';
import { generateLink, goBack } from '../../../lib/utils';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import RecoverModal from '../components/modals/recover';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { ArchiveObstacle } from '../../../types/service';
import ErrorArchiveModal from '../components/modals/error-archive';

const DigitalTutor = () => {
  const { serviceData, educationTypes, type, accessAddArchive, accessPanelEdit, canRecover, routeBack } =
    useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalErrorArchive, setShowModalErrorArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);
  const [archiveObstacle, setArchiveObstacle] = useState<ArchiveObstacle>();

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);

  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;
  const history = useHistory();

  const openRecoveryModal = () => {
    setShowModalRecover(true);
  };

  const redirectToServiceClasses = () => {
    history.push(
      generateLink(
        routes.serviceClasses,
        {},
        {
          serviceId: serviceData.id,
          showArchive: isArchive || undefined,
        }
      )
    );
  };

  const handleArchive = async () => {
    const data = await serviceTemplateApi.getArchiveObstacle(serviceData.id);

    if (data.pupilList.length || data.requestList.length) {
      setShowModalErrorArchive(true);
      setArchiveObstacle(data);
    } else {
      setShowModalArchive(true);
    }
  };

  const handlePrint = () => {
    window.open(
      generateLink(routes.printService, {
        id: serviceData.id,
      }),
      '_blank'
    );
  };

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (isArchive && canRecover) {
      buttons.push({
        dataTest: 'restore',
        icon: 'filled-arrows-upload',
        buttonText: 'Восстановить',
        rounded: 'small',
        variant: 'secondary',
        onClick: openRecoveryModal,
      });
    }
    buttons.push({
      dataTest: 'goToGroup',
      icon: 'filled-arrows-arrow-right',
      buttonText: 'Перейти к группам обучения',
      rounded: 'small',
      variant: 'secondary',
      onClick: redirectToServiceClasses,
    });

    if (!isArchive) {
      if (checkAccessAddArchive) {
        buttons.push({
          dataTest: 'toArchive',
          icon: 'filled-hardware-hard-drive',
          buttonText: 'В архив',
          rounded: 'small',
          variant: 'secondary',
          onClick: handleArchive,
        });
      }
      buttons.push({
        dataTest: 'print',
        icon: 'filled-hardware-printer',
        buttonText: 'Печать',
        rounded: 'small',
        variant: 'secondary',
        onClick: handlePrint,
      });
    }

    return buttons;
  };

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={() => goBack(routeBack)}
        title="Услуга цифрового репетитора"
        dataTest="serviceDigitalSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Услуги цифрового репетитора',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          {
            label: 'Описание услуги цифровой репетитор',
          },
        ]}
        description={getTabsButton(serviceData.serviceStatusId, serviceData.serviceStatusName)}
        tabs={[
          {
            title: 'Основные сведения',
            value: 1,
            dataTest: 'basicInfo',
          },
          {
            title: 'История изменений',
            value: 2,
            dataTest: 'changeHistory',
          },
        ]}
        className="content-panel"
        panels={[
          {
            children: <DigitalTutorDetails />,
            value: 1,
          },
          {
            children: (
              <ChangeHistory
                name=""
                entityTypes={entityTypesForHistory.services}
                extendEntityGuid={serviceData.extendEntityGuid}
              />
            ),
            value: 2,
            className: 'content-panel content-panel--auto',
            noPadding: true,
          },
        ]}
        buttonsOrAnyActions={getButtons()}
        titleChildren={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      <ToArchiveModal
        onCloseHandler={() => setShowModalArchive(false)}
        show={showModalArchive}
      />
      <RecoverModal
        onCloseHandler={() => setShowModalRecover(false)}
        show={showModalRecover}
      />
      <ErrorArchiveModal
        onCloseHandler={() => setShowModalErrorArchive(false)}
        show={showModalErrorArchive}
        data={archiveObstacle}
      />
    </>
  );
};

export default DigitalTutor;
