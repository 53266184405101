import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmBadgeStatus, LmSubHeader, LmLoader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistory from '../../../components/change-history';
import Dialog from '../../../components/modals/dialog';
import { routes } from '../../../config/constants';
import PlanDetails from './plan-details';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { ScheduleData, ServiceData, TrainingGroupData } from '../../../types/service';
import { generateLink, hasAccessObjectAny, hasGeneralAccess, parseUrlSearchString } from '../../../lib/utils';
import { TrainingGroupStatusEnum } from '../../../mock-data/training-group-status-enum';
import { entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import history from '../../../history';
import { formatDate } from '../../../lib/utils/date';

const Plan = () => {
  const [isRetro, setIsRetro] = useState<boolean>();
  const params = useParams<{ serviceId: string; id: string }>();
  const [service, setService] = useState<ServiceData>();
  const [trainingGroup, setTrainingGroup] = useState<TrainingGroupData>();
  const [schedule, setSchedule] = useState<ScheduleData>();
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    setIsRetro(
      trainingGroup?.scheduleList?.length
        ? trainingGroup.scheduleList[0].isRetro
        : !!parseUrlSearchString(location.search).isRetro
    );
  }, [trainingGroup, location.search]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const service = await serviceTemplateApi.getServiceById(params.serviceId);

        setService(service);
      } catch (e) {
        setService({} as ServiceData);
      } finally {
        setIsLoading(false);
      }
    };

    if (params.serviceId) {
      fetch();
    }
  }, [params.serviceId]);

  const getTraininig = useCallback(async () => {
    if (params.id !== '0') {
      try {
        setIsLoading(true);
        const tg = await serviceTemplateApi.getTrainingGroup(params.id);

        setTrainingGroup(tg);
        if (tg?.scheduleList?.length > 0) {
          setSchedule(tg?.scheduleList[0]);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setTrainingGroup({
        id: 0,
      } as TrainingGroupData);
    }
  }, [params.id]);

  useEffect(() => {
    getTraininig();
  }, [params.id, getTraininig]);

  const canEdit = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
      hasAccessObjectAny(userProfile, [accessObject.ServiceArtHouse, accessObject.ServiceSport], accessAction.Edit),
    [userProfile]
  );

  const scheduleStatus = useMemo(() => {
    if (!service || !trainingGroup || !trainingGroup.scheduleList) {
      return undefined;
    } else {
      return service?.isTestService
        ? trainingGroup?.scheduleList.some((s) =>
          [TrainingGroupStatusEnum.Signed, TrainingGroupStatusEnum.Unavailable].includes(s.trainingGroupStatusId)
        )
          ? TrainingGroupStatusEnum.Signed
          : TrainingGroupStatusEnum.Draft
        : trainingGroup?.scheduleList[0]?.trainingGroupStatusId;
    }
  }, [trainingGroup, service]);

  const canDelete = useMemo(
    () =>
      canEdit &&
      scheduleStatus === TrainingGroupStatusEnum.Draft &&
      (schedule?.requestTotalCount === 0 || service?.isTestService),
    [schedule, scheduleStatus, canEdit, service?.isTestService]
  );

  const updateStatus = useCallback(
    async (statusId: TrainingGroupStatusEnum) => {
      await serviceTemplateApi.updateTrainingGroupStatus({
        serviceId: params.serviceId,
        educationTypeId: trainingGroup?.educationTypeId,
        trainingGroupId: trainingGroup?.id ?? 0,
        trainingGroupStatusId: statusId,
      });
      getTraininig();
    },
    [trainingGroup, params.serviceId, getTraininig]
  );

  const handleEditModal = async () => {
    if (trainingGroup) {
      await updateStatus(TrainingGroupStatusEnum.Unavailable);
    }
  };

  const getStatus = (status?: TrainingGroupStatusEnum) => {
    switch (status) {
      case TrainingGroupStatusEnum.Archive:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="grey"
          >
            Архивное
          </LmBadgeStatus>
        );
      case TrainingGroupStatusEnum.Unavailable:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="orange"
          >
            Приема нет
          </LmBadgeStatus>
        );
      case TrainingGroupStatusEnum.Signed:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="green"
          >
            Опубликовано
          </LmBadgeStatus>
        );
      default:
        return (
          <LmBadgeStatus
            dataTest="trainingGroupStatus"
            color="grey"
          >
            Черновик
          </LmBadgeStatus>
        );
    }
  };

  const handleArchive = async () => {
    await updateStatus(TrainingGroupStatusEnum.Archive);
  };

  const handleDelete = async () => {
    if (trainingGroup) {
      await serviceTemplateApi.deleteTrainingGroup(params.serviceId, trainingGroup.id);
      history.push(
        generateLink(routes.service, {
          id: params.serviceId,
        })
      );
    }
  };
  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (canDelete) {
      if (!service?.isTestService) {
        buttons.push({
          dataTest: 'toArchive',
          icon: 'filled-hardware-hard-drive',
          buttonText: 'В архив',
          rounded: 'small',
          variant: 'secondary',
          onClick: handleArchive,
        });
      }
      buttons.push({
        dataTest: 'delete',
        icon: 'filled-edit-trash-alt',
        buttonText: 'Удалить',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleDelete,
      });
    }
    if (schedule?.trainingGroupStatusId === TrainingGroupStatusEnum.Unavailable && !service?.isTestService) {
      buttons.push({
        dataTest: 'resumeReception',
        buttonText: 'Возобновить прием',
        rounded: 'small',
        variant: 'secondary',
        onClick: async () => {
          await updateStatus(TrainingGroupStatusEnum.Signed);
        },
      });
    } else if (
      schedule &&
      !schedule.isRetro &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
      schedule.requestTotalCount > 0 &&
      !service?.isTestService
    ) {
      buttons.push({
        dataTest: 'stopReception',
        buttonText: 'Остановить прием',
        rounded: 'small',
        variant: 'secondary',
        onClick: () => {
          setShowUnavailableModal(true);
        },
      });
    }

    return buttons;
  };

  return (
    <>
      {service && trainingGroup ? (
        <>
          <LmSubHeader
            sticky
            arrowOnClick={() =>
              history.push(
                generateLink(routes.service, {
                  id: params.serviceId,
                })
              )
            }
            title={`План приема${
              isRetro && !service?.isTestService
                ? ' без публикации на Mos.ru'
                : trainingGroup?.educationStartDate
                  ? ` ${formatDate(trainingGroup.educationStartDate)}`
                  : ''
            }`}
            dataTest="serviceDsitDkgmPlanSubHeader"
            routes={[
              {
                label: 'Главная',
                link: routes.main,
              },
              {
                label: 'Образовательные услуги',
                link: routes.services,
              },
              {
                label: 'Описание образовательной услуги',
                link: generateLink(routes.service, {
                  id: params.serviceId,
                }),
              },
              {
                label: 'План приема',
              },
            ]}
            description={
              !service?.isTestService ? (
                <div className="flex justify-end">{getStatus(schedule?.trainingGroupStatusId)}</div>
              ) : (
                ''
              )
            }
            tabs={[
              {
                title: 'Основные сведения',
                value: 1,
                dataTest: 'basicInfo',
              },
              {
                title: 'История изменений',
                value: 2,
                dataTest: 'changeHistory',
              },
            ]}
            className="content-panel"
            panels={[
              {
                children: (
                  <PlanDetails
                    trainingGroup={trainingGroup}
                    service={service}
                    getTraininig={getTraininig}
                  />
                ),
                value: 1,
              },
              {
                children: (
                  <ChangeHistory
                    name=""
                    extendEntityGuid={trainingGroup?.extendEntityGuid}
                    entityTypes={entityTypesForHistory.services}
                  />
                ),
                value: 2,
                className: 'content-panel content-panel--auto',
                noPadding: true,
              },
            ]}
            buttonsOrAnyActions={getButtons()}
          />
          <Dialog
            dataTest="stopReception"
            title="Остановка приема заявлений"
            description="Внимание! После подтверждения прием заявлений на Mos.ru будет остановлен."
            isOpenDialog={showUnavailableModal}
            variant="question"
            callCloseAfterSubmit
            onClickSeconadaryButton={() => {
              setShowUnavailableModal(false);
            }}
            onClickPrimaryButton={handleEditModal}
          />
        </>
      ) : isLoading ? (
        <>
          <Push size={12} />
          <div className="loader-auto">
            <LmLoader view="page" />
          </div>
        </>
      ) : null}
    </>
  );
};

export default Plan;
