import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { generatePath } from 'react-router-dom';
import { object as objectYup, string as stringYup } from 'yup';
import { useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import SavePanel from '../../../../components/save-panel';
import { EducationTypeEnum } from '../../../../types/education-type';
import Photo from '../../components/panels/photo';
import Info from './info';
import { ServiceData, TemplateService } from '../../../../types/service';
import history from '../../../../history';
import { templateApi } from '../../../../lib/api/template';
import { routes } from '../../../../config/constants';
import Financing from './financing';
import { Tests } from './tests';
import EditServiceSchedule from '../../components/panels/service-schedule/edit';
import { convertScheduleToBack } from '../../../../lib/utils/time-table';
import { TemplateContext } from '../../create-template';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { infoValidationSchema } from '../panels/info';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessVedomst } from '../../../../mock-data/access-enum';
import LimitEdit from '../../components/dkgm-dsit-template/panels/limits/limit-edit';
import { checkPeriodPrice } from '../../../../lib/utils/validation';
import { SelectOptionType } from '../../../../types/entities';

const TemplateServicesCreateDetails = () => {
  const { serviceData } = useContext(TemplateContext);
  const [load, setLoad] = useState<boolean>(false);
  const [scheduleError, setScheduleError] = useState<boolean>(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [canChangeSchedule, setCanChangeSchedule] = useState<boolean>(!!serviceData?.schedule?.canChangeSchedule);

  const [scheduleType, setScheduleType] = useState<SelectOptionType | null>({
    label: serviceData.schedule.scheduleTypeOfServiceName,
    value: serviceData.schedule.scheduleTypeOfServiceId,
  });

  const hasHoursPerWeek =
    userProfile.vedomstvoId === accessVedomst.Dkgm &&
    serviceData?.educationTypeId === EducationTypeEnum.ArtHouseEducation;

  const initialErrors = useInitialErrors(serviceData, infoValidationSchema(hasHoursPerWeek));

  const submitForm = async (values: TemplateService.Data) => {
    setLoad(true);

    try {
      const idTemplate = await templateApi.createTemplate({
        ...values,
        schedule: {
          ...values.schedule,
          canChangeSchedule,
          scheduleTypeOfServiceName: scheduleType?.label as string,
          scheduleTypeOfServiceId: scheduleType?.value as number,
        },
      });

      history.push(
        generatePath(routes.myTemplate, {
          id: idTemplate,
        })
      );

      setLoad(false);
    } finally {
      setLoad(false);
    }
  };

  return (
    <Formik
      onSubmit={(values: TemplateService.Data, actions) => {
        submitForm(values);
        actions.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={serviceData}
      initialErrors={initialErrors}
      validationSchema={dataValidationSchema(hasHoursPerWeek)}
    >
      {(formikProps: FormikProps<TemplateService.Data>) => {
        const { isValid, handleSubmit, setFieldValue } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />

            <Info isVisibleHoursPerWeek={hasHoursPerWeek} />

            {userProfile.vedomstvoId === accessVedomst.Kdc && (
              <>
                <Push size={12} />
                <LimitEdit
                  editMode
                  checkEditable
                  isTemplate
                  parent="candidateRestrictionType"
                />
              </>
            )}

            <Push size={12} />

            <Financing />

            <Push size={12} />

            <Tests />

            <Push size={12} />

            <Panel title={() => 'Организации'}>
              <div className="container">
                <LmInfoBox
                  dataTest="organizationWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Возможность добавления организаций станет доступной после первого сохранения."
                  hidenFooter
                />
              </div>
              <Push size={24} />
            </Panel>

            <Push size={12} />

            <EditServiceSchedule
              isCreate
              title="Расписание предоставления услуги"
              submit={(schedule, selectedSchedule) => {
                setFieldValue('schedule.list', convertScheduleToBack(schedule));
                setFieldValue('schedule.scheduleTypeOfServiceName', selectedSchedule.label);
                setFieldValue('schedule.scheduleTypeOfServiceId', selectedSchedule.value);
                handleSubmit();
              }}
              scheduleSubmit={scheduleSubmit}
              setScheduleSubmit={setScheduleSubmit}
              setScheduleError={setScheduleError}
              serviceData={serviceData as ServiceData}
              setCanChangeSchedule={setCanChangeSchedule}
              canChangeSchedule={canChangeSchedule}
              isTemplate
              setScheduleType={setScheduleType}
            />

            <Photo />

            <SavePanel
              buttonPrimaryText="Создать шаблон"
              primaryButtonModifiers={{
                loading: load,
                disabled: !isValid || scheduleError,
              }}
              onClickSeconadaryButton={() => history.push(routes.myTemplates)}
              onClickPrimaryButton={() => setScheduleSubmit(true)}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default TemplateServicesCreateDetails;

export const testValidationSchema = () =>
  objectYup().shape({
    periodGettingDocument: stringYup().required('Введите значение').nullable(),
  });

export const financingValidationSchema = () =>
  objectYup().shape({
    typeFinancingId: stringYup().default('Выберите тип финансирования'),
    periodPrice: stringYup()
      .nullable()
      .min(1, 'Введите значение')
      .test((value: string | null | undefined, ctx: any) => checkPeriodPrice(value, ctx)),
  });

export const dataValidationSchema = (isHourPerWeek: boolean) =>
  objectYup().shape({
    info: infoValidationSchema(isHourPerWeek),
    financing: financingValidationSchema(),
    test: testValidationSchema(),
  });
