import React from 'react';
import { useField } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import FormikInput from '../../../../../components/formik/formik-input';
import { phoneMask } from '../../../../../lib/utils/mask';
import ContactName from '../../fields/contact-name';

type Props = {
  parent?: string;
};

export const CreateContactPerson = ({ parent }: Props) => {
  const fullNameField = parent ? `${parent}.fullName` : 'fullName';
  const phoneField = parent ? `${parent}.phone` : 'phone';
  const organizationFieldName = 'info.organizationId';

  const [organizationField] = useField(organizationFieldName);

  return (
    <>
      <Push size={12} />
      <Panel title={() => <>Контактное лицо</>}>
        <div className="container">
          <ContactName
            name={fullNameField}
            editMode
            phoneField={phoneField}
            organizationId={organizationField.value}
          />
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">Телефон</div>
            <div className="table-data__body">
              <FormikInput
                size="small"
                maskRegex={phoneMask}
                placeholder="(XXX) XXX-XX-XX"
                leftPrefix="+7"
                name={phoneField}
                showErrorImmediately
              />
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};
