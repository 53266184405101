import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmBadgeStatus, LmIcon, LmTooltip } from '@mes-ui/lemma';
import { generateLink, hasAccessObjectAny } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { setTextColorByCheckCurrentDate, textColorByStatusId } from '../../../../lib/utils/service-class';
import { formatDate } from '../../../../lib/utils/date';
import { GetCheckboxColumn } from '../../components/table/chekbox-column';
import { displayContractStatus, ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import AsyncTable from '../../../../components/table/async-table';
import { LoaderCustom } from '../../../../components/loader-custom';

const TableGroupComposition = () => {
  const {
    loading,
    setSelected,
    setOpenDetails,
    pageNum,
    pageSize,
    totalCount,
    setPageNum,
    setPageSize,
    learners,
    selected,
  } = useContext(ServiceClassTableContext);

  const { isArchiveGroup } = useContext(ServiceClassContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const hasEditAccess = hasAccessObjectAny(userProfile, [accessObject.ServiceClassDayCare], accessAction.Edit);

  return !loading ? (
    <AsyncTable
      dataTest="pupils"
      itemsAll={totalCount}
      pageNum={pageNum}
      setPageNumber={setPageNum}
      data={learners}
      pageSize={pageSize}
      setPageSize={setPageSize}
      columns={[
        GetCheckboxColumn(selected, learners, setSelected, setOpenDetails, isArchiveGroup || !hasEditAccess),
        {
          dataIndex: 'count',
          title: '№',
          render: (item: any) => item.count,
          width: '40px',
        },
        {
          dataIndex: 'pupilName',
          title: 'ФИО',
          render: (item: any) => (
            <>
              <div className="icon-group">
                <div
                  className="icon-group__text cursor-pointer text-decoration-underline"
                  onClick={() => {
                    window.open(
                      generateLink(routes.learner, {
                        id: item.pupilId,
                      }),
                      '_blank'
                    );
                  }}
                >
                  {item.pupilName}
                </div>
                <div className="icon-group__icon">
                  {(item.isMoreThanEighteen || item.isLearnEnds) && (
                    <LmTooltip
                      withArrow
                      placement="bottom"
                      content={
                        <div>
                          {item.isMoreThanEighteen && <div>Уже исполнилось 18 лет</div>}
                          {item.isLearnEnds && <div>Истек срок обучения</div>}
                        </div>
                      }
                    >
                      {item.isMoreThanEighteen || (item.isMoreThanEighteen && item.isLearnEnds) ? (
                        <LmIcon
                          icon="outline-notifications-info"
                          size={18}
                          color="var(--LM-red-200)"
                          className="flex-none"
                        />
                      ) : (
                        <LmIcon
                          icon="outline-notifications-info"
                          size={18}
                          color="var(--LM-neutrals-day-700)"
                        />
                      )}
                    </LmTooltip>
                  )}
                </div>
              </div>
              <Push size={4} />
              <span className={textColorByStatusId[item.statusId]}>{item.statusName}</span>
              {item?.subclassList.length > 0 && (
                <>
                  <Push size={17} />
                  <div className="flex wrap">
                    {item?.subclassList?.map((subItem: any) => {
                      return (
                        <div
                          style={{
                            marginBottom: '4px',
                            marginRight: '4px',
                          }}
                        >
                          <LmBadgeStatus dataTest="subclassStatus">{subItem.name}</LmBadgeStatus>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          ),
        },
        {
          dataIndex: 'className',
          title: 'Класс',
          render: (item: any) => item.className,
          width: '10%',
        },
        {
          dataIndex: 'representativeFio',
          title: 'Представитель',
          render: (item: any) => (
            <>
              {item.representativeFio}
              <Push size={4} />
              <div className="color-gray-dark">{item.representativePhone}</div>
            </>
          ),
          width: '30%',
        },
        {
          dataIndex: 'datesLearn',
          title: 'Даты обучения',
          render: (item: any) => {
            return (
              <div>
                <span>с {formatDate(item.dateLearnStart)}</span>
                <span
                  style={{
                    color: setTextColorByCheckCurrentDate(item.dateLearnStart, item.dateLearnEnd),
                  }}
                >
                  {' '}
                  по {formatDate(item.dateLearnEnd)}
                </span>
              </div>
            );
          },
          width: '16%',
        },
        {
          dataIndex: 'contractStatusName',
          title: 'Статус договора',
          render: (item: any) => displayContractStatus(item.contractStatusId),
          width: '16%',
        },
      ]}
    />
  ) : (
    <LoaderCustom size={180} />
  );
};

export default TableGroupComposition;
