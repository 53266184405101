import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { ContingentLinkTypeEnum } from '../../../../mock-data/contingent-link-type-enum';
import BirthDate from './birthdate';
import ContingentSearch from './contingent-search';
import { ChildrenSearchResult } from '../../../../types/contingent';
import { RequestData } from '../../../../types/requests';
import FIO from './fio';
import { usePrevious } from '../../../../hooks/use-previous';

/**
 * Этот компонент нужен для того, когда мы выбрали
 *  обучающегося и нажали кнопку выбрать, то задизейблиться кнопка поиска.
 *  При вводе значения в фамилию | отчество | имя | дата рождения она становится активной.
 * */

type SelectedUser = {
  dateOfBirth?: Date | string;
  lastName?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
};

type Props = {
  hasSearchOrganization?: boolean;
};

const WrapperFields: React.FC<Props> = ({ hasSearchOrganization = false }) => {
  const { values } = useFormikContext<RequestData>();

  const [childrenList, setChildrenList] = useState<ChildrenSearchResult[] | null>(null);
  const [isClickedSelect, setIsClickedSelect] = useState<boolean>(false);

  const [currentUser, setCurrentUser] = useState<RequestData>({} as RequestData);
  const prevUser = usePrevious(currentUser);

  const [selectedUser, setSelectedUser] = useState<SelectedUser>({});

  useEffect(() => {
    if (isClickedSelect) {
      const prev = prevUser?.child;

      const compareFirstName = prev?.firstName !== selectedUser?.firstName;
      const compareLastName = prev?.lastName !== selectedUser?.lastName;
      const compareMiddleName = prev?.middleName !== selectedUser?.middleName;

      const dateOfBirth = moment(prev?.dateOfBirth).isSame(selectedUser.dateOfBirth);

      if (compareFirstName || compareLastName || compareMiddleName || !dateOfBirth) {
        setIsClickedSelect(false);
      }
    }
  }, [isClickedSelect, prevUser?.child, selectedUser]);

  useEffect(() => {
    !isClickedSelect && setCurrentUser && setCurrentUser(values);
  }, [isClickedSelect, setCurrentUser, values]);

  useEffect(() => {
    const v = values?.child;

    setSelectedUser &&
      setSelectedUser({
        lastName: v?.lastName,
        firstName: v?.firstName,
        middleName: v?.middleName,
        dateOfBirth: v?.dateOfBirth,
      });
  }, [setSelectedUser, values?.child]);

  return (
    <>
      <FIO
        required
        editMode
        newRequest
        parent="child"
        label="ФИО учащегося"
        contingent={ContingentLinkTypeEnum.Found}
      />
      <BirthDate
        required
        editMode
        searchButton
        parent="child"
        name="dateOfBirth"
        isClickedSelect={isClickedSelect}
        setChildrenList={setChildrenList}
      />
      <ContingentSearch
        editMode
        parent="child"
        childrenList={childrenList}
        setChildrenList={setChildrenList}
        setIsClickedSelect={setIsClickedSelect}
        hasSearchOrganization={hasSearchOrganization}
      />
    </>
  );
};

export default WrapperFields;
