import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SelectOptionType, TreeNodeType, Push, TableDataType } from '@mosru/esz_uikit';
import { LmSubHeader, LmListItem } from '@mes-ui/lemma';
import TableOptions from '../../../components/table-options';
import ViewContainer from '../../../components/view-container';
import { routes } from '../../../config/constants';
import { notify } from '../../../redux/ducks/notifications';
import ReportFilter from './filter';
import { DataTable, ReportFilterData, ReportProgress, ReportSchedule } from '../../../types/reports';
import SheduleModal, { Weekday } from '../../../components/shedule-modal';
import LoadingModal from '../../../components/loading-modal';
import TreeModal from '../../../components/tree-modal';
import TableReport from './table';
import { useGetDataReport } from '../../../hooks/get-data-report';
import { reportsApi } from '../../../lib/api/reports';
import DropDown from '../../../components/drop-down';
import { generateLink, hasAccessObjectAny } from '../../../lib/utils';
import {
  blobToBase64,
  buildReportHeader,
  errorNoData,
  getFileNameFromDisposition,
  transformResultDataTable,
} from '../../../lib/utils/reports';
import { pageSizeOptions } from '../../../mock-data';
import SavedReportsFilter from './saved-reports-filter/index';
import { ReportProgressEnum } from '../../../mock-data/report-progress-enum';
import { mockCategoryTreeModal, summElementTree } from '../../../components/tree-modal/helpers';
import { weekday } from '../../../components/shedule-modal/helpers';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { accessAction, accessObject } from '../../../types/authorization-data';

const Report = () => {
  const history = useHistory();

  const currentReportId = Number(history.location.pathname.replace(/[^0-9]/g, ''));
  const data = useGetDataReport(currentReportId);

  const [dataTable, setDataTable] = useState<DataTable>(Object);
  const [headerTable, setHeaderTable] = useState<any>([]); // разный тип данных, header-ы  из папки headers
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [openTreePopup, setOpenTreePopup] = useState<boolean>(false);
  const [scheduleModal, setScheduleModal] = useState<boolean>(false);
  const [resetScheduleModal, setResetScheduleModal] = useState<boolean>(false);
  const [listSchedule, setListSchedule] = useState<ReportSchedule>({
    selectDaysForRequest: '',
    selectShortDay: '',
  });
  const [pageNumber, setPageNumber] = useState<number | undefined>(0);
  const [query, setQuery] = useState<ReportFilterData | undefined>(undefined);
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0].value);
  const [dataTreePopup, setDataTreePopup] = useState<TreeNodeType[]>([]);

  const [currentSelectDay, setCurrentSelectDay] = useState<Weekday[]>([]);
  // базовое направление передаеться в filter vertical
  const [valueBasicDirection, setValueBasicDirection] = useState<SelectOptionType | undefined>({
    value: 0,
    label: '',
  });

  const [fixedData, setFixedData] = useState<TableDataType[]>();

  const [dateCreate, setDateCreate] = useState<Date>(new Date());

  const allElementNumbersTreePopupRef = useRef<number>(0);

  // текущее меню в тот момент когда его закрыли
  const [currentMenuClosePopup, setCurrentMenuClosePopup] = useState<SelectOptionType>({
    label: '',
    value: 0,
  });

  // только для 6, 10, 26, 32 отчетов
  const [currentQuery, setCurrentQuery] = useState({
    ...query,
    pageNumber,
    pageSize,
  });

  const isSavedReports = currentReportId === 10;

  const dispatch = useDispatch();

  const openScheduleModal = () => {
    setScheduleModal(true);
  };

  const saveSchedule = (value: ReportSchedule) => {
    setListSchedule(value);
  };

  const resetScheduleModalHandler = (value: boolean) => {
    setResetScheduleModal(value);
    setCurrentSelectDay(weekday);
    if (value) {
      setListSchedule({
        selectDaysForRequest: '',
        selectShortDay: '',
      });
    }
  };

  const setHeader = (header: { [key: string]: any }[]) => {
    setHeaderTable(header);
  };

  const resetTable = () => {
    setDataTable({} as DataTable);
    setPageSize(pageSizeOptions[0].value);
    setPageNumber(0);
    if (isSavedReports) {
      setCurrentQuery({
        pageSize: pageSizeOptions[0].value,
        pageNumber: 0,
      });
    }
  };

  const openPopupTreeHandler = (value: boolean) => {
    setOpenTreePopup(value);
  };

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const mashReportAccess =
    data.filterData?.linkToMashReport &&
    hasAccessObjectAny(
      userProfile,
      currentReportId === 37
        ? [accessObject.AccessReportDo37, accessObject.AccessReportDayCare37, accessObject.AccessReportOp37]
        : // @ts-ignore
          [accessObject[`AccessReport${currentReportId}`]],
      accessAction.CreateReportUsingIFrame
    );

  const eszReportAccess = hasAccessObjectAny(
    userProfile,
    currentReportId === 37
      ? [accessObject.AccessReportDo37, accessObject.AccessReportDayCare37, accessObject.AccessReportOp37]
      : // @ts-ignore
        [accessObject[`AccessReport${currentReportId}`]],
    accessAction.CreateReport
  );

  const isPaginationReport = useMemo(
    () => currentReportId === 6 || currentReportId === 10 || currentReportId === 26 || currentReportId === 32,
    [currentReportId]
  );

  useEffect(() => {
    if (isPaginationReport) {
      return;
    }
    setPageNumber(0);
  }, [pageSize, isPaginationReport]);

  useEffect(() => {
    allElementNumbersTreePopupRef.current = summElementTree(dataTreePopup);
  }, [dataTreePopup]);

  const savedReportToExcel = async (reportId: string) => {
    const savedReport = await reportsApi.getSavedReport(reportId, true);

    if (savedReport) {
      const downloadLink = document.createElement('a');

      downloadLink.href = (await blobToBase64(savedReport.blob)) as string;
      downloadLink.download = getFileNameFromDisposition(savedReport.contentDisposition);
      downloadLink.click();
    }
  };

  const BuildDropDownMenu = useCallback(() => {
    return {
      title: null,
      width: '10%',
      hiddenSort: true,
      render: (item: any) => (
        <>
          <div className="disciplines-table-comment right">
            <div className="disciplines-table-comment__controls">
              <DropDown
                dataTest="reportOptions"
                component={() => <TableOptions />}
              >
                <>
                  <LmListItem
                    dataTest="openReport"
                    text="Открыть"
                    icon="filled-arrows-arrow-right"
                    iconSize={20}
                    onClick={() => {
                      window.open(
                        generateLink(routes.savedReport, {
                          id: item.SaveReportId,
                        }),
                        '_blank'
                      );
                    }}
                  />
                  <LmListItem
                    dataTest="excelReport"
                    text="Excel"
                    icon="filled-files-download"
                    iconSize={20}
                    onClick={async () => {
                      await savedReportToExcel(item.SaveReportId);
                    }}
                  />
                </>
              </DropDown>
            </div>
          </div>
          <Push
            size={2}
            orientation="horizontal"
          />
        </>
      ),
    };
  }, []);

  const showReport = useCallback(
    (response: any) => {
      if (currentReportId === 32) {
        (response.header as []).reverse();
      }

      const header = buildReportHeader(response.header);

      // для витрины  сохраненных очтетов добавляем в последнюю колонку
      // кнопки для манипуляции с сохраненными отчетоами
      if (currentReportId === 10) {
        header.push(BuildDropDownMenu());
      }
      setHeader(header);
      setFixedData(transformResultDataTable(response?.data).total);
      setDataTable({
        items: transformResultDataTable(response?.data).data,
        fixedColumns: response.fixedColumns,
        title: response.tableName,
        itemsCount: response.totalItemCount,
      });
      setDateCreate(new Date(response.dateCreate));
    },
    [BuildDropDownMenu, currentReportId]
  );

  const fetch = useCallback(
    async (query) => {
      const showError = (message: string) =>
        dispatch(
          notify.warning({
            dataTest: 'report',
            title: errorNoData + message,
          })
        );

      setLoading(true);
      if (!data.longReport) {
        try {
          const response = await reportsApi.postReports(currentReportId, query);

          showReport(response);
          setLoading(false);
          // выбрасываем ошибку если например база недоступна
        } catch (e: any) {
          showReport(Object);
          setLoading(false);
        }
      } else {
        let delay = 1000;

        setLoadingModal(true);

        let timerRequest = setTimeout(async function request() {
          let response: ReportProgress = {} as ReportProgress;

          try {
            response = await reportsApi.postReportProgress(currentReportId, query);
          } catch (e: any) {
            showError(e.message ?? '');
            clearInterval(timerRequest);
            setLoading(false);
            setLoadingModal(false);

            return;
          }

          // если отчет не сформировался,увеличиваем время для повторного запроса
          if (response.resultCode === ReportProgressEnum.Pending && delay < 5000) {
            delay *= 1.5;
          }

          timerRequest = setTimeout(request, delay);
          if (response.resultCode !== ReportProgressEnum.Pending) {
            if (response.resultCode === ReportProgressEnum.Success) {
              showReport(response.data);
            } else {
              showError('unknow error.');
            }

            clearInterval(timerRequest);
            setLoading(false);
            setLoadingModal(false);
          }
        }, delay);
      }
    },
    [currentReportId, data.longReport, showReport, dispatch]
  );

  useEffect(() => {
    if (isPaginationReport) {
      return;
    }
    if (query && currentReportId !== 0) {
      fetch(query);
    }
  }, [query, isPaginationReport, fetch, currentReportId]);

  useEffect(() => {
    if ((query || isSavedReports) && isPaginationReport) {
      fetch(currentQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuery, isPaginationReport, fetch]);

  useEffect(() => {
    if (isPaginationReport) {
      setPageNumber(0);
      if (query) {
        setCurrentQuery((prev: any) => ({
          ...query,
          pageNumber: 0,
          pageSize: prev.pageSize,
        }));
      }
    }
  }, [isPaginationReport, query]);

  useEffect(() => {
    if (isPaginationReport) {
      setCurrentQuery((prev: any) =>
        pageNumber === prev.pageNumber
          ? prev
          : {
              ...prev,
              pageNumber,
            }
      );
    }
  }, [isPaginationReport, pageNumber]);

  useEffect(() => {
    if (isPaginationReport) {
      setPageNumber(0);
      setCurrentQuery((prev: any) =>
        pageSize === prev.pageSize
          ? prev
          : {
              ...prev,
              pageSize,
              pageNumber: 0,
            }
      );
    }
  }, [isPaginationReport, pageSize]);

  const tableReport = useMemo(() => {
    return (
      <TableReport
        headerTable={headerTable}
        reportId={currentReportId}
        dataTable={dataTable}
        fixedData={fixedData}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        pageSize={pageSize}
        loading={loading}
        dateCreate={dateCreate}
      />
    );
  }, [headerTable, currentReportId, dataTable, fixedData, pageNumber, pageSize, loading, dateCreate]);

  return (
    <>
      {data.filterData?.title && (
        <LmSubHeader
          sticky
          arrowOnClick={() => history.push(routes.reports)}
          title={data.filterData.title}
          dataTest="reportSubHeader"
          description={
            isSavedReports
              ? 'Здесь хранятся все отчеты, которые вы когда-либо формировали из системы. Вы можете искать по ним, используя расширенный поиск.'
              : ''
          }
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Отчеты',
              link: routes.reports,
            },
            {
              label: data.filterData.title,
            },
          ]}
          tabs={
            mashReportAccess && eszReportAccess
              ? [
                  {
                    value: 1,
                    title: 'По данным ЕСЗ',
                    dataTest: 'eszData',
                  },
                  {
                    value: 2,
                    title: 'По данным МЭШ.Отчеты',
                    dataTest: 'mesReport',
                  },
                ]
              : undefined
          }
          className={isSavedReports && eszReportAccess ? 'content-panel' : undefined}
          panels={[
            {
              children: eszReportAccess ? (
                <ViewContainer
                  noContainer={!isSavedReports}
                  filterComponent={
                    isSavedReports && (
                      <SavedReportsFilter
                        loading={loading}
                        setQuery={setQuery}
                        setHeader={setHeader}
                        resetTable={resetTable}
                        reportFiltersData={data.filterData}
                      />
                    )
                  }
                >
                  {!isSavedReports && (
                    <>
                      <Push size={12} />
                      <ReportFilter
                        data={data}
                        loading={loading}
                        setQuery={setQuery}
                        setHeader={setHeader}
                        setLoading={setLoading}
                        resetTable={resetTable}
                        listSchedule={listSchedule}
                        setDataTable={setDataTable}
                        setLoadingModal={setLoadingModal}
                        currentReportId={currentReportId}
                        setOpenTreePopup={setOpenTreePopup}
                        openScheduleModal={openScheduleModal}
                        valueBasicDirection={valueBasicDirection}
                        setValueBasicDirection={setValueBasicDirection}
                        resetScheduleModalHandler={resetScheduleModalHandler}
                      />
                      <Push size={12} />
                    </>
                  )}
                  {tableReport}
                </ViewContainer>
              ) : null,
              value: 1,
              noPadding: isSavedReports,
              className: isSavedReports ? 'content-panel content-panel--auto' : undefined,
            },
            {
              children: mashReportAccess ? (
                <iframe
                  src={data.filterData.linkToMashReport}
                  title="report-iframe"
                  className="report__iframe"
                />
              ) : null,
              value: 2,
              noPadding: true,
            },
          ]}
        />
      )}

      <SheduleModal
        show={scheduleModal}
        title="Время работы"
        reset={resetScheduleModal}
        saveSchedule={saveSchedule}
        currentSelectDay={currentSelectDay}
        resetModal={resetScheduleModalHandler}
        setCurrentSelectDay={setCurrentSelectDay}
        currentShortDay={listSchedule.selectShortDay}
        onCloseHandler={() => setScheduleModal(false)}
      />

      <TreeModal
        selected
        expandAll={false}
        open={openTreePopup}
        dataTreePopup={dataTreePopup}
        setDataTreePopup={setDataTreePopup}
        closePopup={openPopupTreeHandler}
        title="Реестр базовых направлений"
        setValueBasicDirection={setValueBasicDirection}
        setCurrentMenuClosePopup={setCurrentMenuClosePopup}
        currentMenuClosePopup={currentMenuClosePopup}
        numberElements={allElementNumbersTreePopupRef.current || 0}
        selectOptions={mockCategoryTreeModal}
      />

      <LoadingModal
        show={loadingModal}
        title="Идет формирование отчета"
        onCloseHandler={() => setLoadingModal(loadingModal)}
      />
    </>
  );
};

export default Report;
