import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { LmInfoBox } from '@mes-ui/lemma';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import Popup from '../../../../components/modals/popup';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceTransferModal } from '../../../../types/service-class';
import serviceClassApi from '../../../../lib/api/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import lookupApi from '../../../../lib/api/lookup';
import { ServiceClassTableContext } from '../../utils';

type Props = {
  open: boolean;
  close: () => void;
};

const Specialization: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState<SelectOptionType[]>([]);
  const [specializations, setSpecializations] = useState<SelectOptionType[]>([]);

  const initialValues = useMemo(() => {
    return {
      comment: '',
      documentTypeId: documents[0]?.value,
      serviceId: specializations[0]?.value,
    };
  }, [documents, specializations]);

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const documentTypes = await dictionariesApi.getDocumentTypes(3);
        const specializationsList = await lookupApi.getService(
          undefined,
          serviceClassData.educationTypeId,
          undefined,
          serviceClassData.organizationId,
          undefined,
          true,
          true,
          100
        );

        setSpecializations(specializationsList);
        setDocuments(documentTypes);
      } finally {
        setLoading(false);
      }
    };

    open && fetch();
  }, [open, serviceClassData.educationTypeId, serviceClassData.organizationId, serviceClassData.serviceId]);

  const submitForm = useCallback(
    async (data: ServiceTransferModal) => {
      if (serviceClassData.id) {
        await serviceClassApi.serviceTransfer(serviceClassData.id, {
          ...data,

          megaRelationIds: selected,
          serviceClassId: serviceClassData.id,
          educationTypeId: serviceClassData.educationTypeId,
        });

        fetchTable();
        setOpenDetails(false);
        setSelected([]);
        close();
      }
    },
    [setSelected, close, setOpenDetails, selected, serviceClassData.educationTypeId, serviceClassData.id, fetchTable]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
      initialErrors={initialErrors}
      initialValues={initialValues as ServiceTransferModal}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<ServiceTransferModal>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="changeSpecialization"
              open={open}
              title="Изменение специализации"
              loading={loading}
              buttonPrimaryText="Перевести"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid,
              }}
              onClearData={resetForm}
              onSubmit={handleSubmit}
              onClose={close}
            >
              {specializations.length === 0 ? (
                <LmInfoBox
                  dataTest="specializationInfo"
                  className="infobox--full-width"
                  variant="information"
                  description="Нет доступных для перевода специализаций"
                  hidenFooter
                />
              ) : (
                <>
                  <FormikSelect
                    name="serviceId"
                    label="Специализация"
                    required
                    size="small"
                    withSearch
                    options={specializations}
                    placeholder="Выберите..."
                  />

                  <Push size={16} />
                  <FormikSelect
                    label="Тип документа"
                    required
                    name="documentTypeId"
                    size="small"
                    withSearch
                    options={documents}
                    placeholder="Выберите..."
                  />

                  <Push size={16} />

                  <FormikInput
                    label="Номер документа"
                    size="small"
                    name="docNumber"
                    placeholder="Введите..."
                  />

                  <Push size={16} />

                  <FormikDatePicker
                    label="Дата документа"
                    size="small"
                    name="docDate"
                  />

                  <Push size={16} />

                  <FormikTextarea
                    label="Причина перевода"
                    name="comment"
                    placeholder="Введите..."
                    maxRows={2}
                  />
                </>
              )}
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default Specialization;

const getValidationSchema = () =>
  objectYup().shape({
    serviceId: stringYup().required('Выберите специализацию').nullable(),
    documentTypeId: stringYup().required('Выберите тип документа'),
  });
