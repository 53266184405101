import React, { useContext, useMemo } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInfoBox, LmSelectNew, LmToggle } from '@mes-ui/lemma';
import { period } from '../../../../../components/schedule';
import { getEmptyFunctionToOverride } from '../../../../../lib/utils';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { convertScheduleToFront } from '../../../../../lib/utils/time-table';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { ServiceContext } from '../../../index';
import { useGetOptions } from './use-get-options';
import { showText } from './index';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setEditMode: (value: boolean) => void;
  isTemplate?: boolean;
  title?: string;
  isTemplateOrService?: boolean | undefined;
};

const ViewServiceSchedule = ({ title, isTemplate, setEditModeParent, setEditMode, isTemplateOrService }: Props) => {
  const { serviceData, accessPanelEdit, userProfile } = useContext(ServiceContext);

  const scheduleData = useMemo(() => convertScheduleToFront(serviceData.schedule.list), [serviceData.schedule.list]);

  const checkEditable =
    setEditModeParent &&
    serviceData.serviceStatusId === ServiceStatusEnum.Draft &&
    accessPanelEdit &&
    (serviceData.educationTypeId !== EducationTypeEnum.ChildrenEducation || !!serviceData?.schedule?.canChangeSchedule);

  const { options, scheduleType } = useGetOptions(isTemplate, serviceData, userProfile);

  return (
    <Panel
      title={() => (
        <div className="flex items-center">
          {title}
          <Push
            orientation="horizontal"
            size={16}
          />
          {!!scheduleData.length && (
            <div className="font-weight-base">
              <LmSelectNew
                dataTest="selectScheduleType"
                name="scheduleType"
                onChange={getEmptyFunctionToOverride()}
                disabled
                options={options}
                value={scheduleType}
                size="small"
                grouped={false}
                multiple={false}
                settingDropdownAsPopover={{
                  disablePortal: true,
                }}
              />
            </div>
          )}
        </div>
      )}
      headingControl={() => (
        <>
          {checkEditable && (
            <button
              type="button"
              onClick={() => {
                setEditMode(true);
                setEditModeParent && setEditModeParent('schedule');
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-edit"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          )}
          {isTemplate && (
            <>
              <Push
                orientation="horizontal"
                size={16}
              />
              <LmToggle
                size="small"
                checked={!!serviceData.schedule.canChangeSchedule}
                disabled
                onChange={getEmptyFunctionToOverride()}
              />
            </>
          )}
        </>
      )}
    >
      {serviceData.schedule.scheduleTypeOfServiceId === 2 ? (
        <div className="container">
          <LmInfoBox
            dataTest="scheduleInfo"
            className="infobox--full-width"
            variant="information"
            description={showText(isTemplateOrService)}
            hidenFooter
          />
          <Push size={20} />
        </div>
      ) : (
        <>
          <div className="table-data-striped">
            {scheduleData
              .filter((s) => !s.dayOff)
              .map((item) => (
                <div
                  key={item.name}
                  data-test={item.name}
                  className="table-data-striped__item"
                >
                  <div className="table-data-striped__label">{item.name}</div>
                  <div className="table-data-striped__body">
                    {item.period === 3
                      ? `${item.from} — ${item.to}`
                      : `${period.find((p) => p.value === item.period)?.label ?? ''}`}
                  </div>
                </div>
              ))}
          </div>
          <Push size={8} />
        </>
      )}
    </Panel>
  );
};

export default ViewServiceSchedule;
