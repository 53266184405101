import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Panel } from '@mosru/esz_uikit';
import { LmRadio } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { formatDate } from '../../../../lib/utils/date';
import { notify } from '../../../../redux/ducks/notifications';
import { TrainingGroupByServiceDonm, TrainingGroupRequestData } from '../../../../types/requests';
import { serviceIdField } from '../../components/fields/service-donm';
import { bookingApi } from '../../../../lib/api/booking';
import { LoaderCustom } from '../../../../components/loader-custom';
import SimpleTable from '../../../../components/table/simple-table';

type AdmissionPlanProps = FieldProps<TrainingGroupByServiceDonm[]>;
const slotIdField = 'trainingGroup.slotId';

const AdmissionPlan = ({ label = 'План приема', name, editMode }: AdmissionPlanProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { setFieldValue } = useFormikContext<{ trainingGroup: TrainingGroupRequestData }>();
  const [trainingGroupList, setTrainingGroupList] = useState<TrainingGroupByServiceDonm[] | null>(null);
  const [serviceField] = useField<number>(serviceIdField);
  const [field, , helpers] = useField(name);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const tgList = await serviceTemplateApi.getTrainingGroupsByService(serviceField.value ?? 0);

        setTrainingGroupList(tgList);
      } finally {
        setIsLoading(false);
      }
    };

    if (serviceField.value != null) {
      fetch();
    } else {
      setTrainingGroupList(null);
    }
  }, [serviceField.value]);

  useEffect(() => {
    const fetch = async () => {
      const slots = await bookingApi.getTrainingGroupSlots(field.value);

      if (slots?.length > 0) {
        setFieldValue(slotIdField, slots[0].id);
      } else {
        setFieldValue(slotIdField, undefined);
        dispatch(
          notify.danger({
            dataTest: 'fullPlaces',
            title: 'Нет свободных мест для бронирования',
          })
        );
      }
    };

    if (field.value) {
      fetch();
    }
  }, [field.value, setFieldValue, dispatch]);

  return (
    <Panel
      title={() => (
        <>
          {label}
          <span className="color-gray-dark">
            {' \u00A0'} {trainingGroupList?.length ?? ''}
          </span>
        </>
      )}
    >
      {isLoading ? (
        <LoaderCustom size={20} />
      ) : trainingGroupList ? (
        <SimpleTable
          data={trainingGroupList}
          headerBorderTop={false}
          columns={[
            {
              dataIndex: 'id',
              title: '',
              render: (item: any) => (
                <LmRadio
                  dataTest={item.scheduleOfTimetableId}
                  checked={field.value === item.scheduleOfTimetableId}
                  onChange={() => {
                    helpers.setValue(item.scheduleOfTimetableId);
                  }}
                  disabled={!editMode}
                  resetAllPadding
                />
              ),
              width: '40px',
            },
            {
              dataIndex: 'date',
              title: 'Начало занятий',
              render: (item: any) => formatDate(item.educationStartDate),
            },
            {
              dataIndex: 'serviceStage',
              title: 'Этап обучения',
              render: (item: any) => item.serviceStage,
            },
            {
              dataIndex: 'numbers',
              title: 'Количество мест',
              render: (item: any) => item.volume ?? 'Без ограничений',
            },
            {
              dataIndex: 'teacher',
              title: 'Преподаватель',
              render: (item: any) => item.teacher,
            },
          ]}
        />
      ) : (
        <div className="table-no-data">Нет данных</div>
      )}
    </Panel>
  );
};

export default AdmissionPlan;
