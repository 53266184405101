import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { usePrevious } from '../../../../../hooks/use-previous';

const FieldParallel: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const prevParallelId = usePrevious(values.parallelId);
  const prevOrganizationId = usePrevious(values.organizationId);

  useEffect(() => {
    if (values.organizationId !== prevOrganizationId) {
      const fetchParallels = async () => {
        if (!values.organizationId) {
          return;
        }

        if (!values.parallelData?.length) {
          const parallels = await dictionariesApi.getParallels(values.organizationId as number);

          setFieldValue('parallelData', parallels);
        }
      };

      fetchParallels();
    }
  }, [
    prevOrganizationId,
    prevParallelId,
    setFieldValue,
    values.organizationId,
    values.parallelData?.length,
    values.parallelId,
  ]);

  useEffect(() => {
    // При обнулении "Организации" обнуляем значение "Параллель"
    if (values.organizationId !== prevOrganizationId && prevParallelId) {
      setFieldValue('parallelId', null);
      setFieldValue('parallelName', null);
      setFieldValue('parallelData', null);
    }
  }, [setFieldValue, values.vedomstvoId, prevOrganizationId, values.parallelId, prevParallelId, values.organizationId]);

  const disabled = Boolean(!values.organizationId);

  return (
    <FormikSelect
      label="Параллель"
      size="small"
      name="parallelId"
      withSearch
      options={values.parallelData ?? []}
      placeholder="Выберите..."
      explainText={disabled ? 'Сначала выберите организацию' : undefined}
      disabled={disabled}
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('parallelName', v.label);
        }
      }}
      clearable
    />
  );
};

export default FieldParallel;
