import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '@mosru/esz_uikit';
import { LmIcon, LmSelectNew } from '@mes-ui/lemma';
import { notify } from '../../../../redux/ducks/notifications';
import { Discipline } from '../../../../types/discipline';
import lookupApi from '../../../../lib/api/lookup';
import EditDisciplines from './edit-disciplines';
import teacherApi from '../../../../lib/api/teacher';
import { DisciplineEditObject, Teacher } from '../../../../types/teacher';
import { TeacherContext } from '../index';
import { isError, transformData } from '../helpers';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { SelectOptionType } from '../../../../types/entities';
import { LoaderCustom } from '../../../../components/loader-custom';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  values: Teacher;
  discipline: Discipline[] | undefined;
  setDiscipline: (value: Discipline[] | undefined) => void;
};

const TeacherDisciplines = ({ values, discipline, setDiscipline }: Props) => {
  const context = useContext(TeacherContext);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const access =
    !hasGeneralAccess(userProfile, generalAccess.AdminEdit) &&
    !hasAccessObjectAny(userProfile, [accessObject.Teachers, accessObject.TeachersAD], accessAction.Edit);

  const [rerenderTableKey, setRerenderTableKey] = useState(0);
  const [editObj, setEditObj] = useState<DisciplineEditObject>({
    id: 0,
    name: '',
  });

  const [tableData, setTableData] = useState<DisciplineEditObject[]>(transformData(discipline));

  const dispatch = useDispatch();

  const filterTable = () => setTableData(tableData.filter((item: DisciplineEditObject) => item.id));

  const getError = () => {
    isError();
    filterTable();
  };

  const newDisciplineTeacher = async (valueDiscipline: { newId?: number; name: string }) => {
    if (discipline) {
      const allDisciplines = [
        ...discipline,
        {
          id: valueDiscipline.newId,
          code: '',
          name: valueDiscipline.name,
        },
      ];

      if (!(values.firstName && values.lastName)) {
        getError();
      } else {
        await teacherApi.addTeacherDiscipline(values.id, {
          id: valueDiscipline.newId,
          name: valueDiscipline.name,
          code: '',
        });
        setTableData(transformData(allDisciplines));
        setDiscipline(allDisciplines);
      }
    }
  };

  const updateDisciplinesTeacher = async (currentId: number, valueDiscipline: DisciplineEditObject) => {
    if (discipline) {
      const searchDisciplines = discipline.find((item: Discipline) => item.id === currentId);

      if (searchDisciplines) {
        const updatedList = discipline.map((item: Discipline) => {
          if (item.id === currentId) {
            return {
              ...item,
              id: valueDiscipline.newId,
              name: valueDiscipline.name,
            };
          }

          return item;
        });

        if (!(values.firstName && values.lastName)) {
          getError();
        } else {
          await teacherApi.deleteTeacherDiscipline(values.id, currentId);
          await teacherApi.addTeacherDiscipline(values.id, {
            id: valueDiscipline.newId,
            name: valueDiscipline.name,
            code: '',
          });
          setDiscipline(updatedList);
          setTableData(transformData(updatedList));
        }
      } else {
        newDisciplineTeacher(valueDiscipline);
      }
    }
  };

  const deleteDiscipline = async (id: number) => {
    if (discipline) {
      const deleteDis = discipline.filter((item: Discipline) => item.id !== id);

      if (!(values.firstName && values.lastName)) {
        getError();
      } else {
        await teacherApi.deleteTeacherDiscipline(values.id, id);
        setEditObj({
          id: 0,
          name: '',
        });
        setTableData(transformData(deleteDis));
        setDiscipline(deleteDis);
      }
    }
  };

  const selectDiscipline = (item: DisciplineEditObject) => {
    const { name, id } = editObj;

    if (!name) {
      return null;
    }

    if (name) {
      return {
        label: name,
        value: id || 0,
      };
    } else {
      return {
        label: item.name,
        value: item.id || 0,
      };
    }
  };

  const handleEditDiscipline = (id: number) => {
    if (tableData.find(({ id }) => editObj.newId === id)) {
      dispatch(
        notify.danger({
          dataTest: 'doubleDiscipline',
          title: 'Данная дисциплина уже добавлена',
        })
      );

      return;
    }

    if (editObj.name) {
      updateDisciplinesTeacher(id, editObj);
    } else {
      filterTable();
    }
    setEditObj({
      id: 0,
      name: '',
    });
    setRerenderTableKey(Math.random());
  };

  return (
    <Panel
      title={() => (
        <>
          Дисциплины
          <span className="color-gray-dark">
            {' \u00A0'}
            {tableData.length}
          </span>
        </>
      )}
      headingControl={() =>
        !access ? (
          <button
            type="button"
            disabled={editObj.id === undefined}
            onClick={() => {
              const newData = {
                name: '',
              };
              const newTableData = new Array(newData).concat(tableData);

              setTableData(newTableData);
              setEditObj(newData);
            }}
            className="icon-group"
          >
            <span className="icon-group__icon">
              <LmIcon
                icon="filled-edit-plus"
                size={20}
                color="var(--LM-blue-200)"
              />
            </span>
            <span className="icon-group__text color-primary">
              <b>Добавить дисциплину</b>
            </span>
          </button>
        ) : null
      }
    >
      {context?.loading ? (
        <LoaderCustom size={20} />
      ) : tableData.length ? (
        <SimpleTable
          dataTest="disciplines"
          data={tableData}
          key={rerenderTableKey}
          columns={[
            {
              dataIndex: 'count',
              title: '№',
              width: '80px',
              render: (item: any) => item.count,
            },
            {
              dataIndex: 'name',
              title: 'Наименование',
              width: '96%',
              render: (item: any) => {
                return editObj.id === item.id ? (
                  <LmSelectNew
                    dataTest="selectDiscipline"
                    name="discipline"
                    withSearch
                    placeholder="Начните вводить название дисциплины..."
                    onChange={(selectedOption: SelectOptionType | null) => {
                      const option = selectedOption as SelectOptionType;

                      const value = (option && option.value) || null;
                      const label = (option && option.label) || null;

                      setEditObj((prev: DisciplineEditObject) => ({
                        ...prev,
                        newId: value,
                        name: label,
                      }));
                    }}
                    value={selectDiscipline(item)}
                    getFilterOptions={async (query) => await lookupApi.getDisciplines(query)}
                    options={[]}
                    size="small"
                    grouped={false}
                    multiple={false}
                    settingDropdownAsPopover={{
                      disablePortal: true,
                    }}
                  />
                ) : (
                  item.name
                );
              },
            },
            {
              dataIndex: 'code',
              title: '',
              width: '80px',
              render: (item: any) => (
                <EditDisciplines
                  id={item.id}
                  isAcceptedEdit={!access}
                  editObject={editObj}
                  key={tableData.length}
                  filterTable={filterTable}
                  deleteDiscipline={deleteDiscipline}
                  setEditMode={(mode) => {
                    setEditObj(
                      mode
                        ? item
                        : {
                            id: 0,
                            name: '',
                          }
                    );
                    setRerenderTableKey(Math.random());
                  }}
                  editMode={editObj.id === item.id}
                  submitHandler={handleEditDiscipline}
                />
              ),
            },
          ]}
        />
      ) : (
        <div className="teacher-no-data">Не добавлено ни одной преподаваемой дисциплины...</div>
      )}
    </Panel>
  );
};

export default TeacherDisciplines;
