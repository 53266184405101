import { LmIconNames } from '@mes-ui/lemma/components/LmIcon/lmIconGenerateComponents';
import accessDeny from '../containers/main';
import { routes } from '../config/constants';
import { accessAction, accessObject, AuthorizationData, generalAccess } from '../types/authorization-data';
import { sidebarIcon } from '../types/enum';
import { hasAccessObjectAny, hasAccessObjectParent, hasGeneralAccess } from '../lib/utils';
import NormativeDocs from '../containers/normative-docs';
import SiteMap from '../containers/sitemap';
import UserManual from '../containers/user-manual';
import Faq from '../containers/faq';
import Favorite from '../containers/favorites';
import Reports from '../containers/reports';
import Disciplines from '../containers/disciplines';
import Teacher from '../containers/teachers/teacher';
import OrganizationList from '../containers/organizations';
import OrganizationInfo from '../containers/organizations/organization';
import Learners from '../containers/learners/list';
import LearnerInfo from '../containers/learners/learner';
import TeachersList from '../containers/teachers/list';
import Report from '../containers/reports/report';
import SavedReport from '../containers/reports/report/saved-report';
import { ReportInformation } from '../types/reports';
import Requests from '../containers/requests/list';
import Request from '../containers/requests/request';
import CreateRequest from '../containers/requests/create';
import CreateRequestBasedOn from '../containers/requests/request-based-on';
import ServiceClass from '../containers/service-class/service-class';
import { RegistryTypeEnum } from './registry-type-enum';
import ServiceClassRegistry from '../containers/service-class/registry';
import { ServiceRegistry } from '../containers/service/registry';
import CreateRequestByPupil from '../containers/requests/request-by-pupil';
import CreateRequestFromSchedule from '../containers/requests/request-by-schedule';
import CreateServiceClass from '../containers/service-class/create';
import Desktop from '../containers/desktop';
import Service from '../containers/service';
import Plan from '../containers/service/dkgm-dsit/plan';
import CreateService from '../containers/service/create';
import Tests from '../containers/service/dkgm-dsit/tests';
import CreateTemplate from '../containers/service/create-template';
import CreateServiceByTemplate from '../containers/service/create-by-template';
import CopyTemplate from '../containers/service/copy-template';
import homeApi from '../lib/api/home';
import Events from '../containers/events';

export type SidebarLinkType = {
  name: string; // Название в итоге тянется из базы
  type: number;
  id: number; // ID записи в базе. Если в базе нет записи, но нам нужно сделать роут, то значение 900+
  parent?: number;
  link?: string;
  route?: string;
  redirect?: () => void;
  icon?: LmIconNames;
  component?: () => JSX.Element;
  checkAccess: (userProfile: AuthorizationData) => boolean;
  hasChildren?: boolean;
};

export const routeType = {
  sidebarTitle: 1,
  sidebarLink: 2,
  footerLink: 4,
  siteMapLink: 8,
  router: 16,
  report: 32,
};

const routList: SidebarLinkType[] = [
  {
    name: 'Реестры',
    type: routeType.sidebarLink + routeType.sidebarTitle + routeType.siteMapLink,
    id: 1,
    checkAccess: () => true,
  },
  {
    name: 'Заявления',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.requests,
    icon: sidebarIcon.request,
    id: 2,
    parent: 1,
    component: Requests,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Заявление',
    type: routeType.router,
    link: routes.request,
    icon: sidebarIcon.request,
    id: 992,
    parent: 1,
    component: Request,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Создание заявления',
    type: routeType.router,
    link: routes.createRequest,
    icon: sidebarIcon.request,
    id: 993,
    parent: 1,
    component: CreateRequest,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.Create) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Создание заявления на основе другого',
    type: routeType.router,
    link: routes.createRequestBasedOn,
    icon: sidebarIcon.request,
    id: 994,
    parent: 1,
    component: CreateRequestBasedOn,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.Create) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Создание заявления из карточки ученика',
    type: routeType.router,
    link: routes.createRequestByPupil,
    icon: sidebarIcon.request,
    id: 995,
    parent: 1,
    component: CreateRequestByPupil,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.Create) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Создание заявления из карточки ученика',
    type: routeType.router,
    link: routes.createRequestBySchedule,
    icon: sidebarIcon.request,
    id: 996,
    parent: 1,
    component: CreateRequestFromSchedule,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.Create) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Образовательные услуги',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.services,
    icon: sidebarIcon.myServices,
    id: 3,
    parent: 1,
    component: () => ServiceRegistry(RegistryTypeEnum.serviceEducationType), // LoginDsit
    checkAccess: (userProfile) =>
      (hasAccessObjectAny(
        userProfile,
        [accessObject.ServiceSport, accessObject.ServiceArtHouse, accessObject.ServiceVA],
        accessAction.ViewRegistry
      ) &&
        !hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.ViewRegistry)) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
  },
  {
    name: 'Услуга',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.service,
    icon: sidebarIcon.myServices,
    id: 6667,
    parent: 1,
    component: () => Service(RegistryTypeEnum.serviceEducationType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.ServiceSport, accessObject.ServiceArtHouse, accessObject.ServiceVA],
        accessAction.View
      ) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
  },
  {
    name: 'Услуга',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.createService,
    icon: sidebarIcon.myServices,
    id: 6672,
    parent: 1,
    component: () => CreateService(RegistryTypeEnum.serviceEducationType),
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Services, accessAction.Create) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
  },
  {
    name: 'Услуга',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.createServiceByTemplate,
    icon: sidebarIcon.myServices,
    id: 6673,
    parent: 1,
    component: () => CreateServiceByTemplate(RegistryTypeEnum.serviceEducationType),
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Services, accessAction.CreateByTemplate) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
  },
  {
    name: 'План приема',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.trainingGroup,
    icon: sidebarIcon.myServices,
    id: 6668,
    parent: 1,
    component: Plan,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.ServiceSport, accessObject.ServiceArtHouse], accessAction.Edit) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
  },
  {
    name: 'Вступительные испытания',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.entranceTests,
    icon: sidebarIcon.myServices,
    id: 6669,
    parent: 1,
    component: Tests,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.ServiceSport, accessObject.ServiceArtHouse], accessAction.Edit) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
  },
  {
    name: 'Мои шаблоны',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.myTemplates,
    icon: sidebarIcon.myTemplates,
    id: 4,
    parent: 1,
    component: () => ServiceRegistry(RegistryTypeEnum.serviceTemplateType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.TemplateSport, accessObject.TemplateArtHouse],
        accessAction.ViewRegistry
      ) || hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Мои шаблоны',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.myTemplate,
    icon: sidebarIcon.myTemplates,
    id: 6671,
    parent: 1,
    component: () => Service(RegistryTypeEnum.serviceTemplateType, true),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.TemplateSport, accessObject.TemplateArtHouse], accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Новый шаблон',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.createTemplate,
    icon: sidebarIcon.myTemplates,
    id: 6673,
    parent: 1,
    component: () => CreateTemplate(),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.TemplateSport, accessObject.TemplateArtHouse], accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Новый шаблон',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.copyTemplate,
    icon: sidebarIcon.myTemplates,
    id: 6673,
    parent: 1,
    component: () => CopyTemplate(),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.TemplateSport, accessObject.TemplateArtHouse], accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },

  {
    name: 'Реестр детских объединений',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.registerChildEducations,
    icon: sidebarIcon.myTemplates,
    id: 5,
    parent: 1,
    component: () => ServiceRegistry(RegistryTypeEnum.serviceChildType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.ServiceDOGM, accessObject.ServiceNonDOGM],
        accessAction.ViewRegistry
      ) || hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Детское объединение',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.registerChildEducation,
    icon: sidebarIcon.myServices,
    id: 6668,
    parent: 1,
    component: () => Service(RegistryTypeEnum.serviceChildType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.ServiceDOGM, accessObject.ServiceNonDOGM], accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Реестр обучающихся',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.learners,
    icon: sidebarIcon.registerLearners,
    id: 6,
    parent: 1,
    component: Learners,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Pupils, accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Обучающийся',
    type: routeType.router,
    link: routes.learner,
    icon: sidebarIcon.registerLearners,
    id: 996,
    parent: 1,
    component: LearnerInfo,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Pupils, accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Реестр образовательных программ',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.registerEducationPrograms,
    icon: sidebarIcon.registerEducationPrograms,
    id: 7,
    parent: 1,
    component: () => ServiceRegistry(RegistryTypeEnum.serviceEducationProgramType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.ServiceOP], accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Реестр образовательных программ',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.registerEducationProgram,
    icon: sidebarIcon.registerEducationPrograms,
    id: 6670,
    parent: 1,
    component: () => Service(RegistryTypeEnum.serviceEducationProgramType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.ServiceOP], accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Услуги МД',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.registerServices,
    icon: sidebarIcon.registerServices,
    id: 8,
    parent: 1,
    component: accessDeny,
    checkAccess: (userProfile) => hasAccessObjectAny(userProfile, [accessObject.ServiceAD], accessAction.ViewRegistry),
  },
  {
    // TODO: название в зависимости от прав
    name: 'Группы обучения',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.serviceClasses,
    icon: sidebarIcon.serviceClasses,
    id: 9,
    parent: 1,
    component: ServiceClassRegistry,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.ServiceClasses, accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Группа обучения',
    type: routeType.router,
    link: routes.serviceClass,
    icon: sidebarIcon.serviceClasses,
    id: 997,
    parent: 1,
    component: ServiceClass,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.ServiceClasses, accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Группа обучения',
    type: routeType.router,
    link: routes.createServiceClass,
    icon: sidebarIcon.serviceClasses,
    id: 666,
    parent: 1,
    component: CreateServiceClass,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.ServiceClasses, accessAction.Create) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
  },
  {
    name: 'Справочники',
    type: routeType.sidebarLink + routeType.sidebarTitle + routeType.siteMapLink,
    id: 10,
    checkAccess: () => true,
  },
  {
    name: 'Преподаватели',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.teachers,
    icon: sidebarIcon.teachers,
    id: 11,
    parent: 10,
    component: TeachersList,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.Teachers, accessObject.TeachersAD], accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Преподаватель',
    type: routeType.router,
    link: routes.teacher,
    id: 911,
    parent: 10,
    component: Teacher,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.Teachers, accessObject.TeachersAD], accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Реестр дисциплин',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.disciplines,
    icon: sidebarIcon.disciplines,
    id: 12,
    parent: 10,
    component: Disciplines,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Организации',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.organizations,
    icon: sidebarIcon.organizations,
    id: 13,
    parent: 10,
    component: OrganizationList,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Организация',
    type: routeType.router,
    link: routes.organization,
    icon: sidebarIcon.organizations,
    id: 913,
    parent: 10,
    component: OrganizationInfo,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.ViewRegistry) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Сведения об организации',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.organizationInfo,
    icon: sidebarIcon.organizationInfo,
    id: 23,
    parent: 10,
    component: OrganizationInfo,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.View) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Программы',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.programs,
    icon: sidebarIcon.registerServices,
    id: 27,
    parent: 10,
    component: () => ServiceRegistry(RegistryTypeEnum.serviceChildType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.Services, accessObject.ServiceDayCare, accessObject.ServiceDOGM, accessObject.ServiceVA],
        accessAction.View
      ) || hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Программа',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.program,
    icon: sidebarIcon.registerServices,
    id: 6669,
    parent: 10,
    component: () => Service(RegistryTypeEnum.serviceChildType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.Services, accessObject.ServiceDayCare, accessObject.ServiceDOGM, accessObject.ServiceVA],
        accessAction.View
      ) || hasGeneralAccess(userProfile, generalAccess.AdminView),
  },

  {
    name: 'Мой аккаунт',
    type: routeType.sidebarLink + routeType.sidebarTitle + routeType.siteMapLink,
    id: 14,
    checkAccess: () => true,
  },
  {
    name: 'Избранное',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router + routeType.footerLink,
    link: routes.favorite,
    icon: sidebarIcon.favorite,
    id: 16,
    parent: 14,
    component: Favorite,
    checkAccess: (userProfile) => userProfile.login !== 'report_account',
  },
  {
    name: 'Рабочий стол',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.main,
    icon: sidebarIcon.main,
    id: 15,
    parent: 14,
    component: Desktop,
    checkAccess: () => true,
  },

  {
    name: 'Аналитика',
    type: routeType.sidebarLink + routeType.sidebarTitle + routeType.siteMapLink,
    id: 17,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Reports, accessAction.CreateReport) ||
      hasAccessObjectParent(userProfile, accessObject.Reports, accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Отчеты',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.reports,
    icon: sidebarIcon.reports,
    id: 18,
    parent: 17,
    component: Reports,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Reports, accessAction.CreateReport) ||
      hasAccessObjectParent(userProfile, accessObject.Reports, accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
    hasChildren: true,
  },
  {
    name: 'Основные отчеты',
    type: routeType.report,
    link: routes.reports,
    icon: sidebarIcon.reports,
    id: 18,
    parent: 17,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectParent(userProfile, accessObject.Reports, accessAction.CreateReport) ||
      hasAccessObjectParent(userProfile, accessObject.Reports, accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
    hasChildren: true,
  },

  // Для конкретного отчета
  {
    name: '',
    type: routeType.router,
    route: routes.commonReport,
    id: 117,
    parent: 91,
    component: Report,
    checkAccess: () => true,
  },

  // Отчеты по профподготовке
  {
    name: 'Отчеты по профподготовке',
    type: routeType.report + routeType.siteMapLink,
    link: routes.reports,
    icon: sidebarIcon.reports,
    id: 91,
    parent: 17,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [
          accessObject.AccessReport1,
          accessObject.AccessReport2,
          accessObject.AccessReport3,
          accessObject.AccessReport4,
          accessObject.AccessReport7,
          accessObject.AccessReport8,
          accessObject.AccessReport9,
        ],
        accessAction.CreateReport
      ) ||
      hasAccessObjectAny(
        userProfile,
        [
          accessObject.AccessReport1,
          accessObject.AccessReport2,
          accessObject.AccessReport3,
          accessObject.AccessReport4,
          accessObject.AccessReport7,
          accessObject.AccessReport8,
          accessObject.AccessReport9,
        ],
        accessAction.CreateReportUsingIFrame
      ) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
    hasChildren: true,
  },
  {
    name: 'Количество выданных документов о прохождении обучения',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/1`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 101,
    parent: 91,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport1], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport1], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество обучающихся по образовательным программам и по модулям ОП',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/2`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 102,
    parent: 91,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport2], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport2], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество программ и модулей всего и в каждой программе',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/3`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 103,
    parent: 91,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport3], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport3], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Сведения о статусе обучения по программам профподготовки',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/4`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 104,
    parent: 91,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport4], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport4], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество обучающихся по образовательным программам',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/7`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 107,
    parent: 91,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport7], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport7], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество обучающихся по интенсивным образовательным программам',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/8`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 108,
    parent: 91,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport8], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport8], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество обучающихся, не сдавших квалификационный экзамен',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/9`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 109,
    parent: 91,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport9], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport9], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Охват детей, состоящих на внутришкольном учете, программами дополнительного образования',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/5`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 105,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport5], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport5], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'О переходе обучающихся по уровням обучения в разрезе направленностей',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/6`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 106,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport6], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport6], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Витрина сохранённых отчетов',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/10`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 110,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport10], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport10], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Сохраненный отчет',
    type: routeType.router,
    route: routes.savedReport,
    icon: sidebarIcon.file,
    id: 199,
    parent: 18,
    component: SavedReport,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport10], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport10], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  // Отчеты ДОНМ
  {
    name: 'Отчеты ДОНМ',
    type: routeType.report + routeType.siteMapLink,
    link: routes.reports,
    icon: sidebarIcon.reports,
    id: 92,
    parent: 17,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [
          accessObject.AccessReport11,
          accessObject.AccessReport12,
          accessObject.AccessReport13,
          accessObject.AccessReport14,
          accessObject.AccessReport15,
          accessObject.AccessReport16,
          accessObject.AccessReport17,
          accessObject.AccessReport18,
          accessObject.AccessReport20,
        ],
        accessAction.CreateReport
      ) ||
      hasAccessObjectAny(
        userProfile,
        [
          accessObject.AccessReport11,
          accessObject.AccessReport12,
          accessObject.AccessReport13,
          accessObject.AccessReport14,
          accessObject.AccessReport15,
          accessObject.AccessReport16,
          accessObject.AccessReport17,
          accessObject.AccessReport18,
          accessObject.AccessReport20,
        ],
        accessAction.CreateReportUsingIFrame
      ) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
    hasChildren: true,
  },
  {
    name: 'Список обучающихся, состоящих на внутришкольном учете и не получающих дополнительное образование',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/11`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 111,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport11], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport11], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Численность детей в возрасте от 5 до 18 лет (уникальных), осваивающих программы дополнительного образования детей в УДО',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/12`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 112,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport12], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport12], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Число "человеко-кружков" по детям в возрасте от 5 до 18 лет, осваивающим программы дополнительного образования детей в УДО',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/13`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 113,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport13], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport13], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Численность детей в возрасте от 5 до 18 лет (уникальных), осваивающих программы дополнительного образования детей в колледжах',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/14`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 114,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport14], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport14], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Число "человеко-кружков" по детям в возрасте от 5 до 18 лет, осваивающим программы дополнительного образования детей в колледжах',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/15`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 115,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport15], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport15], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Численность детей в возрасте от 5 до 18 лет (уникальных), осваивающих программы дополнительного образования детей в школах',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/16`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 116,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport16], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport16], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Число "человеко-кружков" по детям в возрасте от 5 до 18 лет, осваивающим программы дополнительного образования детей в школах',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/17`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 117,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport17], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport17], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Охват детей в возрасте от 5 до 18 лет (уникальных) программами дополнительного образования детей',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/18`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 118,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport18], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport18], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Охват детей в возрасте от 5 до 18 лет "человеко-кружками" по программам дополнительного образования',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/20`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 120,
    parent: 92,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport20], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport20], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Охват детей в возрасте от 5 до 18 лет (уникальных) программами дополнительного образования детей (ДСиТ и ДКгМ)',
    type: routeType.report,
    link: `${routes.reports}/19`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 119,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport19], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport19], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Охват детей в возрасте от 5 до 18 лет "человеко-кружками" по программам дополнительного образования (ДСиТ и ДКгМ)',
    type: routeType.report,
    link: `${routes.reports}/21`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 121,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport21], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport21], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Сводный отчет',
    type: routeType.report,
    link: `${routes.reports}/22`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 122,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport22], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport22], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Отчет в разрезе города',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/23`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 123,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport23], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport23], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'По направленности',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/24`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 124,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport24], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport24], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Численность обучающихся, осваивающих программы дополнительного образования детей, формирующие навыки для жизни, учебы и труда (человеко-кружков)',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/25`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 125,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport25], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport25], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Перечень кружков',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/26`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 126,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport26], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport26], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество кружков по направленности',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/27`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 127,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport27], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport27], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество программ по направленности',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/28`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 128,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport28], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport28], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество уникальных обучающихся по направленностям',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/29`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 129,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport29], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport29], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Количество человеко-кружков по направленностям',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/30`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 130,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport30], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport30], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Степень выполнения государственного задания Департамента спорта и туризма г. Москвы',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/31`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 131,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport31], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport31], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Перечень образовательных организаций, реализующих дополнительные общеобразовательные программы, формирующие умения и навыки для учебы, жизни и труда',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/32`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 132,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport32], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport32], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'По типам ОО',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/33`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 133,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport33], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport33], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Список обучающихся',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/34`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 134,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport34], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport34], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Сведения о численности контингента образовательной организации, посещающего группы по присмотру и уходу за детьми школьного возраста',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/35`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 135,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport35], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport35], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Обучающиеся групп по присмотру и уходу за детьми школьного возраста',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/36`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 136,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport36], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport36], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Ведомость учащихся',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/37`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 137,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.AccessReportDo37, accessObject.AccessReportDayCare37, accessObject.AccessReportOp37],
        accessAction.CreateReport
      ) ||
      hasAccessObjectAny(
        userProfile,
        [accessObject.AccessReportDo37, accessObject.AccessReportDayCare37, accessObject.AccessReportOp37],
        accessAction.CreateReportUsingIFrame
      ) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Отчет "По городу (в разрезе направлений)"',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/38`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 138,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport38], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport38], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Состояние обработки заявлений на обучение по программам дополнительного образования детей в разрезе направлений',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/39`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 139,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport39], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport39], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Состояние обработки заявлений на обучение по программам дополнительного образования детей',
    type: routeType.report + routeType.siteMapLink,
    link: `${routes.reports}/40`,
    route: routes.commonReport,
    icon: sidebarIcon.file,
    id: 140,
    parent: 18,
    component: accessDeny,
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.AccessReport40], accessAction.CreateReport) ||
      hasAccessObjectAny(userProfile, [accessObject.AccessReport40], accessAction.CreateReportUsingIFrame) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Дополнительно',
    type: routeType.sidebarLink + routeType.sidebarTitle + routeType.siteMapLink,
    id: 19,
    checkAccess: () => true,
  },
  {
    name: 'Руководство пользователя',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router + routeType.footerLink,
    link: routes.userManual,
    icon: sidebarIcon.userManual,
    id: 20,
    parent: 19,
    component: UserManual,
    checkAccess: () => true,
  },
  {
    name: 'Ответы на вопросы',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router + routeType.footerLink,
    link: routes.faq,
    icon: sidebarIcon.faq,
    id: 21,
    parent: 19,
    component: Faq,
    checkAccess: () => true,
  },
  {
    name: 'Документы',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router + routeType.footerLink,
    link: routes.normativeDoc,
    icon: sidebarIcon.normativeDoc,
    id: 22,
    parent: 19,
    component: NormativeDocs,
    checkAccess: () => true,
  },
  {
    name: 'МЭШ.Договор',
    type: routeType.sidebarLink + routeType.siteMapLink,
    icon: sidebarIcon.mesh,
    redirect: async () => {
      const url = await homeApi.getContractUrl();

      window.open(url);
    },
    id: 25,
    parent: 19,
    component: accessDeny,
    checkAccess: (userProfile) => hasGeneralAccess(userProfile, generalAccess.AuthorizationContracts),
  },
  {
    id: 93,
    name: 'Карта сайта',
    type: routeType.sidebarLink + routeType.router,
    icon: sidebarIcon.siteMap,
    link: routes.siteMap,
    component: SiteMap,
    parent: 19,
    checkAccess: () => true,
  },
  {
    name: 'Программы продленного дня',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.dayCarePrograms,
    icon: sidebarIcon.registerEducationPrograms,
    id: 26,
    parent: 1,
    component: () => ServiceRegistry(RegistryTypeEnum.serviceGpdType), // LoginGpd
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.ViewRegistry) &&
      !hasGeneralAccess(userProfile, generalAccess.AdminView),
  },
  {
    name: 'Программа продленного дня',
    type: routeType.sidebarLink + routeType.siteMapLink + routeType.router,
    link: routes.dayCareProgram,
    icon: sidebarIcon.registerEducationPrograms,
    id: 26,
    parent: 1,
    component: () => Service(RegistryTypeEnum.serviceGpdType),
    checkAccess: (userProfile) =>
      hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.ViewRegistry),
  },
  {
    name: 'Реестр уведомлений',
    type: routeType.router,
    link: routes.events,
    id: 27,
    parent: 1,
    component: Events,
    checkAccess: () => true,
  },
];

export const sidebarList = routList.filter((item) => item.type & routeType.sidebarLink);
export const routeList = routList.filter((item) => item.type & routeType.router);
export const footerList = routList.filter((item) => item.type & routeType.footerLink);
export const siteMapList = routList.filter((item) => item.type & routeType.siteMapLink);
export const reportList = routList.filter((item) => item.type & routeType.report);

export const convertReportInfoToSidebarLink = (item: ReportInformation): SidebarLinkType => ({
  name: item.title,
  type: routeType.report + routeType.siteMapLink,
  link: item.hasChildren ? `${routes.reports}` : `${routes.reports}/${item.id}`,
  route: routes.commonReport,
  icon: sidebarIcon.file,
  id: item.id,
  parent: item.reportParent,
  hasChildren: item.hasChildren,
  checkAccess: () => true,
});
