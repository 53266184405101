export enum RegistryTypeEnum {
  // Реестр детских объединений
  serviceChildType = 'service-child',

  // Реестр образовательных программ
  serviceEducationProgramType = 'service-education-program',

  // Реестр программ продленного дня
  serviceGpdType = 'service-gpd',

  // Шаблоны услуг
  serviceTemplateType = 'service-template',

  // Реестр образовательных услуг/Мои услуги
  serviceEducationType = 'services',
}
