import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import {
  CandidateRestrictionTypeListData,
  EditTemplateStatusData,
  TemplateEditTestData,
  TemplateService,
} from '../../types/service';

const getTemplate = async (id: string) => await fetchRequest.get(`${apiService.data}/Template/${id}`);

const updateTemplate = async (id: number, values: TemplateService.Info) =>
  await fetchRequest.patch(`${apiService.data}/Template/${id}`, values);

const createTemplate = async (values: TemplateService.Data) =>
  await fetchRequest.post(`${apiService.data}/Template`, values);

const getEmptyTemplate = async (educationTypeId: number) =>
  await fetchRequest.get(`${apiService.data}/Template/Empty/${educationTypeId}`);

const getAdmissionPlanList = async ({
  templateId = 0,
  yearOfTrainingId = 0,
  pageNumber = 0,
  pageSize = 10,
  isOnlyActual = true,
}): Promise<{ items: TemplateService.AdmissionPlanList[]; totalCount: number }> =>
  await fetchRequest.get(`${apiService.data}/Template/${templateId}/AdmissionPlan/List`, {
    YearOfTrainingId: yearOfTrainingId,
    PageNumber: pageNumber,
    PageSize: pageSize,
    isOnlyActual,
  });

const deleteAdmissionPlan = async (templateId: number, id: number) =>
  await fetchRequest.delete(`${apiService.data}/Template/${templateId}/AdmissionPlan/${id}`);

const updateAdmissionPlan = async (
  templateId: number,
  id: number,
  values: TemplateService.AdmissionPlanList
): Promise<number | { message: string }> =>
  await fetchRequest.put(`${apiService.data}/Template/${templateId}/AdmissionPlan/${id}`, values);

const updateInfoDataAdmissionPlan = async (templateId: number, values: TemplateService.AdmissionPlan) =>
  await fetchRequest.patch(`${apiService.data}/Template/${templateId}/AdmissionPlan`, values);

const createAdmissionPlan = async (
  templateId: number,
  values: TemplateService.AdmissionPlanList
): Promise<number | { message: string }> =>
  await fetchRequest.post(`${apiService.data}/Template/${templateId}/AdmissionPlan`, values);

const copyAdmissionPlanLastYear = async (templateId: number) =>
  await fetchRequest.patch(`${apiService.data}/Template/${templateId}/AdmissionPlan/LastYear`);

const deleteAllAdmissionPlanPeriod = async (templateId: number, yearOfTrainingId: number) =>
  await fetchRequest.delete(`${apiService.data}/Template/${templateId}/AdmissionPlan/Year/${yearOfTrainingId}`);

const getAllOrganizationAdmissionPlan = async (templateId: number) =>
  await fetchRequest.patch(`${apiService.data}/Template/${templateId}/AdmissionPlan/OrganizationRegistry`);

const updateFinancing = async (templateId: number, values: Partial<TemplateService.Financing>) => {
  await fetchRequest.patch(`${apiService.data}/Template/${templateId}/Financing`, values);
};

const updateSchedule = async (templateId: number, serviceSchedule: TemplateService.Schedule) =>
  await fetchRequest.patch(`${apiService.data}/Template/${templateId}/Schedule`, serviceSchedule);

const signTemplate = async (templateId: number, data: TemplateService.Sign) =>
  await fetchRequest.patch(`${apiService.data}/Template/${templateId}/Sign`, data);

const updateTemplateStatus = async (data: EditTemplateStatusData) =>
  await fetchRequest.patch(`${apiService.data}/Template/${data.templateId}/Status`, data);

const updateTemplateTest = async (templateId: number, data: TemplateEditTestData) =>
  await fetchRequest.patch(`${apiService.data}/Template/${templateId}/test`, data);

const setTemplateCandidateRestriction = async (data: CandidateRestrictionTypeListData) =>
  await fetchRequest.patch(`${apiService.data}/Template/${data.serviceId}/CandidateRestriction`, data);

const deleteTemplate = async (templateId: number) =>
  await fetchRequest.delete(`${apiService.data}/Template/${templateId}`);

export const templateApi = {
  getTemplate,
  createTemplate,
  updateFinancing,
  updateSchedule,
  deleteTemplate,
  updateTemplate,
  getEmptyTemplate,
  deleteAdmissionPlan,
  updateAdmissionPlan,
  createAdmissionPlan,
  getAdmissionPlanList,
  copyAdmissionPlanLastYear,
  updateInfoDataAdmissionPlan,
  deleteAllAdmissionPlanPeriod,
  getAllOrganizationAdmissionPlan,
  signTemplate,
  updateTemplateStatus,
  updateTemplateTest,
  setTemplateCandidateRestriction,
};
