import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import FormikInputNumber from '../../../../../../components/formik/formik-input-number';
import { ServiceDataInfo } from '../../../../../../types/service';
import FormikSelect from '../../../../../../components/formik/formik-select';
import { dictionariesApi } from '../../../../../../lib/api/dictionaries';
import FormikToggle from '../../../../../../components/formik/formik-toggle';
import { SelectOptionType } from '../../../../../../types/entities';

type Props = { editMode: boolean; parent?: string; disabled?: boolean; isTemplate?: boolean };

export const ServiceDuration: React.FC<Props> = ({ isTemplate, editMode, parent, disabled }) => {
  const { values } = useFormikContext<ServiceDataInfo>();
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    (async () => setOptions(await dictionariesApi.getDurationOfTrainingUnitList()))();
  }, []);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Продолжительность услуги {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="table-data-grid-2 items-start">
            <div className="table-data__group">
              <div className="table-data__body">
                <FormikSelect
                  required
                  size="small"
                  placeholder=""
                  options={options}
                  disabled={disabled}
                  name={parent ? `${parent}.durationOfTrainingUnitId` : 'durationOfTrainingUnitId'}
                />
              </div>
            </div>
            <div className="table-data__group">
              <div className="table-data__body">
                <FormikInputNumber
                  placeholder="0"
                  disabled={disabled}
                  name={parent ? `${parent}.durationOfTraining` : 'durationOfTraining'}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            {values.durationOfTrainingUnitId && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">
                    {options?.find((m) => m.value === values.durationOfTrainingUnitId)?.label}
                  </div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 200,
                    }}
                  >
                    {values.durationOfTraining}
                  </div>
                </div>

                <Push
                  size={16}
                  orientation="horizontal"
                />
              </>
            )}
          </div>
        )}
      </div>
      {isTemplate && (
        <div className="table-data__control">
          <FormikToggle
            disabled={!editMode}
            name={parent ? `${parent}.canChangeDuration` : 'canChangeDuration'}
            size="small"
          />
        </div>
      )}
    </div>
  );
};
