import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { TreeNodeType, Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import FormikSelect from '../../../../components/formik/formik-select';
import { FieldProps } from '../../../../components/fields/field';
import TreeModal from '../../../../components/tree-modal';
import { ServiceData } from '../../../../types/service';
import lookupApi from '../../../../lib/api/lookup';
import FormikToggle from '../../../../components/formik/formik-toggle';
import { SelectOptionType } from '../../../../types/entities';

type Props = FieldProps<string> & {
  modalTitle: string;
  toggleName?: string;
  showAmount?: boolean;
};

export const ClassificatorNameField: React.FC<Props> = ({
  name,
  label = 'Вид деятельности',
  editMode,
  modalTitle = '',
  parent,
  toggleName,
  disabled,
  showAmount,
}) => {
  const { values, setFieldValue } = useFormikContext<ServiceData>();

  const nameLabel = parent ? `${parent}.${name}EKUName` : `${name}EKUName`;
  const nameValue = parent ? `${parent}.${name}EKUId` : `${name}EKUId`;
  const organization = parent ? `${parent}.organizationId` : 'organizationId';

  const [fieldValue, , setValue] = useField(nameValue);
  const [fieldLabel, , setFieldLabel] = useField(nameLabel);
  const [fieldOrganization] = useField(organization);

  const [open, setOpen] = useState<boolean>(false);

  const [dataTreePopup, setDataTreePopup] = useState<TreeNodeType[]>([]);

  return (
    <>
      <div className="table-data__item table-data__group">
        <div className="table-data__label table-data__label--main">
          {label} {editMode && <span className="table-data__required" />}
        </div>
        <div className="table-data__body">
          <div className="flex items-center">
            <div className="flex-auto">
              {editMode ? (
                <FormikSelect
                  size="small"
                  withSearch
                  options={[]}
                  loadOptions={async (query) =>
                    await lookupApi.getClassificator(query, values.educationTypeId, fieldOrganization.value)
                  }
                  name={nameValue}
                  selectedValue={(option?: SelectOptionType) => {
                    setValue.setValue(option?.value);
                    setFieldLabel.setValue(option?.label);
                  }}
                  placeholder="Начните вводить или выберите..."
                  defaultValue={
                    fieldLabel.value && fieldValue.value
                      ? {
                          label: fieldLabel.value,
                          value: fieldValue.value,
                        }
                      : null
                  }
                  disabled={disabled}
                />
              ) : (
                fieldLabel.value || '—'
              )}
            </div>
            {editMode && (
              <>
                <Push
                  size={10}
                  orientation="horizontal"
                />
                <LmButton
                  dataTest="options"
                  type="button"
                  variant="outline"
                  icon="filled-controls-options-horizontal"
                  iconSize={20}
                  disabled={disabled}
                  onClick={() => setOpen(true)}
                />
              </>
            )}

            {toggleName && (
              <div className="table-data__control">
                <FormikToggle
                  size="small"
                  disabled={!editMode || disabled}
                  name={parent ? `${parent}.${toggleName}` : toggleName}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <TreeModal
        open={open}
        title={modalTitle}
        showAmount={showAmount}
        dataTreePopup={dataTreePopup}
        setDataTreePopup={setDataTreePopup}
        educationId={values.educationTypeId}
        closePopup={() => setOpen(false)}
        setValueBasicDirection={(option) => {
          setFieldValue(nameLabel, option?.label);
          setFieldValue(nameValue, option?.value);
        }}
        numberElements={0}
        organizationId={fieldOrganization.value}
      />
    </>
  );
};
