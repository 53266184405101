import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { TrainingGroupRequestData } from '../../../types/requests';
import { getServiceEditLink } from '../../../lib/utils';
import { EducationTypeEnum } from '../../../types/education-type';
import { routes } from '../../../config/constants';
import { formatDate } from '../../../lib/utils/date';

type Props = {
  trainingGroup: TrainingGroupRequestData | undefined;
  educationType: EducationTypeEnum;
};

const Association: React.FC<Props> = ({ trainingGroup, educationType }) => {
  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация о детском объединении и группе обучения'}>
        <div className="container">
          <div className="table-data">
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Детское объединение</div>
              <div className="table-data__body">
                <Link
                  className="brand-link cursor-pointer"
                  to={() => getServiceEditLink(educationType, trainingGroup?.serviceId)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {trainingGroup?.serviceName}
                </Link>
              </div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Образовательная организация</div>
              <div className="table-data__body">
                <span>{trainingGroup?.organizationName}</span>
              </div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Адрес приема документов</div>
              <div className="table-data__body">{trainingGroup?.itemsWorkAddress}</div>
            </div>

            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Группа обучения</div>
              <div className="table-data__body">
                <Link
                  className="brand-link"
                  to={`${routes.serviceClasses}/${trainingGroup?.serviceClass?.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {trainingGroup?.serviceClass?.code}, {trainingGroup?.serviceClass?.name},{' '}
                  {trainingGroup?.serviceClass?.teacher}
                </Link>
              </div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Дата начала занятий</div>
              <div className="table-data__body">
                {trainingGroup?.educationStartDate && formatDate(trainingGroup?.educationStartDate)}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Association;
