import React from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import { ServiceRegistryContext } from '../../initial-data';
import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';
import { SelectOptionType } from '../../../../../types/entities';

const FieldEducationType: React.FC = () => {
  const { setFieldValue } = useFormikContext();

  const { educationTypeData, isAdmin, type } = React.useContext(ServiceRegistryContext);

  return (
    <FormikSelect
      label="Тип образования"
      name="educationTypeId"
      size="small"
      disabled={!(educationTypeData.length > 1) && (!isAdmin || RegistryTypeEnum.serviceEducationProgramType === type)}
      defaultValue={{
        label: educationTypeData[0]?.label,
        value: educationTypeData[0]?.value,
      }}
      withSearch
      options={educationTypeData}
      placeholder="Выберите..."
      showTooltip
      disabledPortalTooltip
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('educationTypeName', v.label);
        }
      }}
    />
  );
};

export default FieldEducationType;
