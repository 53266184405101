import React from 'react';
import Dialog from '../../../../components/modals/dialog';

type Props = {
  open: boolean;
  title: string;
  description: string;
  onCloseHandler: () => void;
};

export const Notification = ({ open, title, onCloseHandler, description }: Props) => {
  return (
    <Dialog
      dataTest="notification"
      isOpenDialog={open}
      title={title}
      description={description}
      variant="information"
      buttonSecondaryText="Закрыть"
      onClickSeconadaryButton={onCloseHandler}
    />
  );
};
