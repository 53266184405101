import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchLearnersInitialFormData } from '../../../../../types/learners';
import { AppState } from '../../../../../redux/types/state';
import { userProfileSelector } from '../../../../../redux/selectors';
import { adminAccess, hasGeneralAccess } from '../../../../../lib/utils';
import { generalAccess } from '../../../../../mock-data/access-enum';
import { SelectOptionType } from '../../../../../types/entities';

type Props = {
  vedomstvo?: SelectOptionType;
  setVedomstvo: (value?: SelectOptionType) => void;
  onVedomstvoSelect: (value?: SelectOptionType) => void;
  vedomstvoOptions: SelectOptionType[];
};

const VEDOMSTVO_ID = 'vedomstvoId';
const DEFAULT_VALUE = {
  label: 'Все',
  value: null,
};

export const VedomstvoField = ({ vedomstvo, onVedomstvoSelect, setVedomstvo, vedomstvoOptions }: Props) => {
  const { setFieldValue } = useFormikContext<SearchLearnersInitialFormData>();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdmin = adminAccess('edit-or-view', userProfile);

  const canChangeVedomstvo: boolean = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  useEffect(() => {
    if (!isAdmin) {
      const findVedomstvo = vedomstvoOptions.find((item) => item.value === userProfile.vedomstvoId);

      const vedomstvo = findVedomstvo ?? {
        label: userProfile.vedomstvoName,
        value: userProfile.vedomstvoId as number,
      };

      setVedomstvo(vedomstvo);
      setFieldValue('vedomstvoId', vedomstvo.value);
    }
  }, [isAdmin, setFieldValue, setVedomstvo, userProfile.vedomstvoId, userProfile.vedomstvoName, vedomstvoOptions]);

  const handleVedomstvoSelect = (option?: SelectOptionType) => {
    setFieldValue('organizationId', null);
    setFieldValue('serviceId', null);
    onVedomstvoSelect(option);
  };

  const vedomstvoValue = {
    label: vedomstvo?.label ?? DEFAULT_VALUE.label,
    value: vedomstvo?.value ?? DEFAULT_VALUE.value,
  };

  return (
    <FormikSelect
      name={VEDOMSTVO_ID}
      size="small"
      withSearch
      showTooltip
      disabledPortalTooltip
      options={vedomstvoOptions}
      disabled={!canChangeVedomstvo}
      defaultValue={vedomstvoValue}
      selectedValue={handleVedomstvoSelect}
      placeholder="Выберите департамент..."
      label="Департамент"
    />
  );
};
