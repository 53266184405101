import React, { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { LmIcon } from '@mes-ui/lemma';
import { ServiceImageData } from './index';

type Props = {
  file: ServiceImageData;
  index: number;
  setFiles: (files: ServiceImageData[]) => void;
  files: ServiceImageData[];
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  openGallery: () => void;
};

const PhotoCard: FC<Props> = ({ file, files, index, setFiles, moveCard, openGallery }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<any, void, { handlerId: Identifier | null }>({
    accept: 'photo',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'photo',
    item: () => {
      return {
        id: file.id,
        index,
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function deletePhoto() {
    const newFiles =
      file.id.indexOf('file') === 0
        ? files.filter((item) => item.id !== file.id)
        : files.map((f) => {
          return f.id === file.id
            ? {
                  ...f,
                  removed: true,
                }
            : f;
        });

    setFiles(newFiles);
  }

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="service-photo"
      style={{
        opacity: isDragging ? 0 : 1,
      }}
      data-handler-id={handlerId}
    >
      <div className="service-photo__inner">
        <div
          role="presentation"
          className="service-photo__img"
          onClick={openGallery}
          style={{
            backgroundImage: `url('${file.preview.url}')`,
          }}
        />
        <div className="service-photo__head">
          <div className="service-photo__dnd">
            <LmIcon
              icon="filled-files-drag-and-drop"
              size={20}
              color="var(--LM-neutrals-day-0)"
            />
          </div>
          {index === 0 ? 'Главное фото' : `Фото №${index + 1}`}
          <button
            type="button"
            onClick={deletePhoto}
            className="service-photo__delete"
          >
            <LmIcon
              icon="outline-edit-trash-alt"
              size={20}
              color="var(--LM-neutrals-day-0)"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhotoCard;
