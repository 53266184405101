import React from 'react';
import { Push } from '@mosru/esz_uikit';
import Dialog from '../../../../components/modals/dialog';
import { NumberSeatsAdmissionPlan } from '../../../../types/service';

type NumberSeats = (NumberSeatsAdmissionPlan & { numberSeatsLeft: number }) | null;

type Props = {
  id: number;
  show: boolean;
  onClose: () => void;
  handleCopyProgram: () => void;
  numberSeats: NumberSeats[];
};

export const CopyProgramModal = ({ show, onClose, numberSeats, handleCopyProgram }: Props) => {
  const handleClick = () => {
    handleCopyProgram();
    onClose();
  };

  return (
    <Dialog
      dataTest="copyProgram"
      isOpenDialog={show}
      title="Создание копии программы"
      description={
        <>
          Вы собираетесь создать копию текущей программы со следующими планами приема:
          <Push
            orientation="vertical"
            size={16}
          />
          {numberSeats.map((item, index) => {
            if (item?.yearOfTrainingName) {
              return (
                /* eslint-disable-next-line react/no-array-index-key */
                <div key={index}>
                  {`${item.yearOfTrainingName} учебный год с количеством мест ${item.volume || 0} из ${
                    item.numberSeatsLeft
                  } оставшихся${numberSeats.length - 1 === index || numberSeats.length === 1 ? '.' : ';'}`}
                </div>
              );
            }

            return null;
          })}
        </>
      }
      variant="question"
      buttonPrimaryText="Да, продолжить"
      onClickSeconadaryButton={onClose}
      onClickPrimaryButton={handleClick}
    />
  );
};
