import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState } from '../types/state';
import { isAuthorized } from '../../lib/utils';
import { setLoading, setAuthorized, clearProfiles } from '../ducks/app';
import { getUserProfile, reducerRecord, setUserProfile } from '../ducks/user-profile';
import { getAupdOrganizations } from '../ducks/aupd-organizations';

export const initApp = () => async (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => {
  try {
    dispatch(setLoading(true));
    const authorized = await isAuthorized();

    dispatch(setAuthorized(authorized));
    if (authorized) {
      const { tokenAupd } = await dispatch(getUserProfile());

      if (tokenAupd) {
        await dispatch(getAupdOrganizations(tokenAupd));
      }
    } else {
      dispatch(setUserProfile(reducerRecord));
      dispatch(clearProfiles());
    }
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setLoading(false));
  }
};
