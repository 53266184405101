import React, { useContext } from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../../config/constants';
import { CreateServiceContext } from '../../create';
import { getHeaderLink } from '../../utils';
import DigitalTutorCreateCreateDetails from './details';
import history from '../../../../history';

const DigitalTutorCreate = () => {
  const { educationTypes, type, serviceData } = useContext(CreateServiceContext);

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))}
        title="Новая услуга"
        dataTest="serviceDigitalCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Услуги цифрового репетитора',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          {
            label: 'Описание услуги цифровой репетитор',
          },
        ]}
      />
      <DigitalTutorCreateCreateDetails />
    </>
  );
};

export default DigitalTutorCreate;
