import React, { useContext, useMemo, useState } from 'react';
import { LmBadgeStatus, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import ChangeHistory from '../../../components/change-history';
import { routes } from '../../../config/constants';
import Favorite from '../../../components/favorite';
import ServiceDetails from './details';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { ServiceContext } from '../index';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import { getHeaderLink } from '../utils';
import { generateLink, goBack } from '../../../lib/utils';
import ToArchiveModal from '../components/modals/to-archive';
import RecoverModal from '../components/modals/recover';

const ServiceDKGM = () => {
  const { serviceData, educationTypes, type, accessPanelEdit, accessAddArchive, canRecover, routeBack } =
    useContext(ServiceContext);

  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);

  const checkAccessAddArchive = useMemo(() => {
    return (
      serviceData.serviceStatusId === ServiceStatusEnum.Draft &&
      accessPanelEdit &&
      accessAddArchive &&
      (!serviceData.info?.hasMegaRelations || serviceData.info?.hasAllMegaRelationsExcludedOrChanged)
    );
  }, [
    serviceData.serviceStatusId,
    serviceData.info?.hasMegaRelations,
    serviceData.info?.hasAllMegaRelationsExcludedOrChanged,
    accessPanelEdit,
    accessAddArchive,
  ]);
  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;

  const getStatus = (status?: ServiceStatusEnum) => {
    switch (status) {
      case ServiceStatusEnum.Arhive:
        return (
          <LmBadgeStatus
            dataTest="serviceStatus"
            color="grey"
          >
            Архивная
          </LmBadgeStatus>
        );
      case ServiceStatusEnum.Draft:
        return (
          <LmBadgeStatus
            dataTest="serviceStatus"
            color="orange"
          >
            Не опубликовано на Mos.ru
          </LmBadgeStatus>
        );
      case ServiceStatusEnum.Signed:
        return (
          <LmBadgeStatus
            dataTest="serviceStatus"
            color="green"
          >
            Опубликовано на Mos.ru
          </LmBadgeStatus>
        );
      default:
        return (
          <LmBadgeStatus
            dataTest="serviceStatus"
            color="grey"
          >
            Архивная
          </LmBadgeStatus>
        );
    }
  };

  const openRecoveryModal = () => {
    setShowModalRecover(true);
  };

  const openArchiveModal = () => {
    setShowModalArchive(true);
  };

  const handlePrint = () => {
    window.open(
      generateLink(routes.printService, {
        id: serviceData.id,
      }),
      '_blank'
    );
  };
  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (isArchive) {
      if (canRecover) {
        buttons.push({
          dataTest: 'restore',
          icon: 'filled-arrows-upload',
          buttonText: 'Восстановить',
          rounded: 'small',
          variant: 'secondary',
          onClick: openRecoveryModal,
        });

        return buttons;
      }
    } else {
      if (checkAccessAddArchive) {
        buttons.push({
          dataTest: 'toArchive',
          icon: 'filled-hardware-hard-drive',
          buttonText: 'В архив',
          rounded: 'small',
          variant: 'secondary',
          onClick: openArchiveModal,
        });
      }
      buttons.push({
        dataTest: 'print',
        icon: 'filled-hardware-printer',
        buttonText: 'Печать',
        rounded: 'small',
        variant: 'secondary',
        onClick: handlePrint,
      });
    }

    return buttons;
  };

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={() => goBack(routeBack)}
        title="Образовательная услуга"
        dataTest="serviceDkgmDsitSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Образовательные услуги',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          {
            label: 'Описание образовательной услуги',
          },
        ]}
        description={<div className="flex justify-end">{getStatus(serviceData.serviceStatusId)}</div>}
        tabs={[
          {
            title: 'Основные сведения',
            value: 1,
            dataTest: 'basicInfo',
          },
          {
            title: 'История изменений',
            value: 2,
            dataTest: 'changeHistory',
          },
        ]}
        className="content-panel"
        panels={[
          {
            children: <ServiceDetails />,
            value: 1,
          },
          {
            children: (
              <ChangeHistory
                name="сведения о программе"
                extendEntityGuid={serviceData.extendEntityGuid}
                entityTypes={entityTypesForHistory.services}
              />
            ),
            value: 2,
            className: 'content-panel content-panel--auto',
            noPadding: true,
          },
        ]}
        buttonsOrAnyActions={getButtons()}
        titleChildren={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      <ToArchiveModal
        onCloseHandler={() => setShowModalArchive(false)}
        show={showModalArchive}
      />
      <RecoverModal
        onCloseHandler={() => setShowModalRecover(false)}
        show={showModalRecover}
      />
    </>
  );
};

export default ServiceDKGM;
