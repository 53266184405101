import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { object as objectYup, string as stringYup, array as arrayYup } from 'yup';
import { Panel } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { TeacherType } from '../../../../types/service-class';
import { ServiceClassContext } from '../../service-class';
import serviceClassApi from '../../../../lib/api/service-class';
import TeachersField from '../fields/teachers';
import { serviceClassStatusEnum } from '../../../../mock-data/service-class-status';
import SavePanel from '../../../../components/save-panel';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type PersonType = {
  teachers: TeacherType[];
};

const ServiceClassTeacher: React.FC<Props> = ({ setEditModeParent, setEditMode, editMode }) => {
  const { serviceClassData, updateServiceClass, accessEditServiceClass } = useContext(ServiceClassContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: PersonType) => {
    const payload = {
      serviceClassId: serviceClassData.id,
      educationTypeId: serviceClassData.educationTypeId,
      list: [...values.teachers],
    };

    try {
      setIsLoading(true);
      await serviceClassApi.updateServiceClassTeacherList(payload);
    } finally {
      setIsLoading(false);
    }

    updateServiceClass();

    setEditModeParent && setEditModeParent(null);
    if (setEditMode) {
      setEditMode(false);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{
        teachers: serviceClassData.teacher.list,
      }}
      validationSchema={getValidationSchema}
      render={({ values, isValid, resetForm, submitForm }) => {
        return (
          <Form>
            <Panel
              title={() => (
                <>
                  Ответственные преподаватели{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {values.teachers?.length}{' '}
                  </span>
                </>
              )}
              headingControl={() => {
                return !editMode &&
                  serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                  accessEditServiceClass ? (
                      <button
                        type="button"
                        onClick={() => {
                          setEditModeParent && setEditModeParent('teacher');
                          if (setEditMode) {
                            setEditMode(true);
                          }
                        }}
                        className="icon-group"
                      >
                        <span className="icon-group__icon">
                          <LmIcon
                            icon="filled-edit-edit"
                            size={20}
                            color="var(--LM-blue-200)"
                          />
                        </span>
                        <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                      </button>
                    ) : null;
              }}
            >
              {editMode ? (
                <TeachersField name="teachers" />
              ) : (
                <SimpleTable
                  dataTest="teachers"
                  data={serviceClassData.teacher.list}
                  columns={[
                    {
                      dataIndex: 'name',
                      title: 'ФИО',
                      width: '50%',
                      render: (item: any) => item.name,
                    },
                    {
                      dataIndex: 'chief',
                      title: 'Руководитель',
                      width: '50%',
                      render: (item: any) => (item.isSupervisor ? 'Да' : ''),
                    },
                  ]}
                />
              )}
            </Panel>
            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isLoading,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent && setEditModeParent(null);
                  if (setEditMode) {
                    setEditMode(false);
                  }
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </Form>
        );
      }}
    />
  );
};

export default ServiceClassTeacher;

const getValidationSchema = objectYup().shape({
  teachers: arrayYup()
    .of(
      objectYup().shape({
        name: stringYup().required('Введите ФИО преподавателя'),
      })
    )
    .required('Должен состоять из списка преподавателей') // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Минимум 1 преподаватель'),
});
