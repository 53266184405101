export enum EntityTypeEnum {
  // Анкета-заявка, Заявление (формы с данным именованием для всех типов образования и департаментов);
  Request = 1,

  // Описание детского объединения"/"Описание образовательной услуги"/ "Описание программы по присмотру и уходу"/ "Описание услуги"/ "Описание образовательной программы
  Service = 2,

  // Сведения об организации
  Organization = 3,

  // Группа
  ServiceClass = 4,

  // Обучающийся
  Pupil = 5,

  // Пункты работы с заявителями
  ItemsWork = 6,

  // Место предоставления услуг
  PlaceService = 7,

  // Персона
  Person = 8,

  // Веса в поиске
  SphinxRanker = 9,

  // Объявление
  Advertisement = 10,

  // Вопросы и ответы
  Faq = 11,

  // Нормативные документы
  NormativeDocument = 12,

  // Руководства пользователя
  UserManual = 13,

  // Адрес
  Address = 14,

  // Параллель
  ClassParallel = 15,

  // Роль
  Role = 16,

  // Пользователь
  User = 17,
}

export const entityTypesForHistory = {
  learners: [EntityTypeEnum.Pupil],
  requests: [EntityTypeEnum.Request],
  organizations: [EntityTypeEnum.Organization],
  serviceClasses: [EntityTypeEnum.ServiceClass],
  services: [EntityTypeEnum.Service],
  teachers: [EntityTypeEnum.Person],
};
