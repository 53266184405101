import React from 'react';
import Dialog from '../modals/dialog';

type Props = {
  show: boolean;
  onCloseHandle: () => void;
  onRestoreHandler: () => void;
  title: string;
  description: string;
};

const RestoreDocumentModal = ({ show, onCloseHandle, onRestoreHandler, title, description }: Props) => (
  <Dialog
    dataTest="restoreDialog"
    isOpenDialog={show}
    variant="question"
    title={title}
    description={description}
    buttonPrimaryText="Да, восстановить"
    onClickSeconadaryButton={onCloseHandle}
    onClickPrimaryButton={onRestoreHandler}
  />
);

export default RestoreDocumentModal;
