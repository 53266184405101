export enum ReportProgressEnum {
  //
  // Summary:
  //     Успешно
  Success = 1,
  //
  // Summary:
  //     Неуспешно
  Error = 2,
  //
  // Summary:
  //     Всё еще выполняется
  Pending = 3,
}
