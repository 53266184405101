import React, { FC, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { LmActionBar, LmActionBarProps } from '@mes-ui/lemma';

const SavePanel: FC<LmActionBarProps> = ({ secondaryButtonModifiers, primaryButtonModifiers, ...props }) => {
  const portalElement = useMemo(() => {
    const element = document.createElement('div');

    element.className = 'room-save-container room-save-container--portal';

    return element;
  }, []);
  const container = useMemo(() => document.getElementsByClassName('layout__content')[0], []);
  const element = useMemo(() => container.getElementsByClassName('content-holder')[0], [container]);

  useEffect(() => {
    element.after(portalElement);

    return () => {
      container.removeChild(portalElement);
    };
  }, [portalElement, element, container]);

  return ReactDOM.createPortal(
    <div className="room-panel-save">
      <LmActionBar
        dataTest="savePanel"
        {...props}
        secondaryButtonModifiers={{
          size: 'medium',
          ...secondaryButtonModifiers,
        }}
        primaryButtonModifiers={{
          size: 'medium',
          ...primaryButtonModifiers,
        }}
      />
    </div>,
    portalElement
  );
};

SavePanel.defaultProps = {
  buttonPrimaryText: 'Сохранить',
};

export default SavePanel;
