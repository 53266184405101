import { Field as FormikField, FieldProps as FormikFieldProps, useField, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import Field, { FieldProps } from '../../../../components/fields/field';
import contingentApi from '../../../../lib/api/contingents';
import { ChildrenSearchResult } from '../../../../types/contingent';
import { fioFirstNameField, fioLastNameField, fioMiddleNameField } from './fio';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import { formatDate, getSelectedDate } from '../../../../lib/utils/date';

type BirthDateProps = FieldProps<any> & {
  searchButton?: boolean;
  setChildrenList?: React.Dispatch<React.SetStateAction<ChildrenSearchResult[] | null>> | null;
  disabled?: boolean;
  parent?: string;
  isClickedSelect?: boolean;
};

export const birthDateField = 'dateOfBirth';

const BirthDate = ({
  label = 'Дата рождения',
  name,
  editMode,
  required,
  searchButton,
  setChildrenList = null,
  disabled = false,
  parent,
  isClickedSelect,
}: BirthDateProps) => {
  const { values, initialValues } = useFormikContext<any>();

  const [fioFirstName] = useField(buildFormFieldName(parent, fioFirstNameField));
  const [fioLastName] = useField(buildFormFieldName(parent, fioLastNameField));
  const [fioMiddleName] = useField(buildFormFieldName(parent, fioMiddleNameField));
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true);

  useEffect(() => {
    const isCLick = isClickedSelect || false;

    setSearchDisabled(!fioLastName.value || !fioFirstName.value || disabled || isCLick);
  }, [fioLastName, fioFirstName, disabled, isClickedSelect]);

  const [loading, setLoading] = useState(false);
  const birthDateField = buildFormFieldName(parent, name);

  const searchBtnDisabledRef = useRef<boolean>(false);

  const prevFirstName = parent ? initialValues?.child?.firstName : initialValues?.firstName;
  const prevLastName = parent ? initialValues?.child?.lastName : initialValues?.lastName;
  const prevMiddleName = parent ? initialValues?.child?.middleName : initialValues?.middleName;
  const prevDateOfBirth = parent ? initialValues?.child?.dateOfBirth : initialValues?.dateOfBirth;

  const currentFirstName = parent ? values?.child?.firstName : values?.firstName;
  const currentLastName = parent ? values?.child?.lastName : values?.lastName;
  const currentMiddleName = parent ? values?.child?.middleName : values?.middleName;
  const currentDateOfBirth = parent ? values?.child?.dateOfBirth : values?.dateOfBirth;

  useEffect(() => {
    const compareFirstName = prevFirstName === currentFirstName;
    const compareLastName = prevLastName === currentLastName;
    const compareMiddleName = prevMiddleName === currentMiddleName;
    const dateOfBirth = prevDateOfBirth === currentDateOfBirth;

    searchBtnDisabledRef.current = compareFirstName && compareLastName && compareMiddleName && dateOfBirth;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSearch = async (value: any) => {
    try {
      setLoading(true);
      const result = await contingentApi.searchChildrenByPersonalData(
        fioFirstName.value,
        fioLastName.value,
        fioMiddleName.value,
        value ? moment(value).format('YYYY-MM-DD') : undefined
      );

      if (result && setChildrenList) {
        setChildrenList(result);
      }
    } catch {}
    setLoading(false);
  };

  return (
    <FormikField name={birthDateField}>
      {({ field }: FormikFieldProps) => {
        const { value } = field;

        return (
          <Field
            editMode={editMode}
            label={label}
            defaultValue="Не указано"
            value={value ? formatDate(value) : value}
            required={required}
          >
            <div className="flex items-start white-space-nowrap">
              <FormikDatePicker
                size="small"
                name={birthDateField}
                selectedDay={getSelectedDate(value)}
                disabled={disabled}
                fullWidth={false}
              />
              {searchButton && (
                <>
                  <Push
                    orientation="horizontal"
                    size={16}
                  />
                  <LmButton
                    dataTest="searchPupil"
                    type="button"
                    loading={loading}
                    disabled={searchDisabled || searchBtnDisabledRef.current}
                    icon="filled-internet-code-search"
                    iconSize={20}
                    onClick={async () => {
                      await handleSearch(value);
                    }}
                  >
                    Поиск
                  </LmButton>
                </>
              )}
            </div>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default BirthDate;
