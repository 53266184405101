import { EducationTypeEnum } from './education-type';

// Изменяемые в разных видах обучения названия сущностей + предлоги и прочее.
export type LangModel = {
  ServiceIm: string;
  ServiceRod: string;
  ServiceDat: string;
  ServiceVin: string;
  ServiceTvo: string;
  ServicePre: string;
  ServiceAbbr: string;
  ServiceMust: string;
  ServiceThis: string;
  ServiceMultiIm: string;
  ServiceMultiRod: string;
  ServiceShortIm: string;
  ServiceShortRod: string;
  ServiceShortDat: string;
  ServiceShortVin: string;
  ServiceShortTvo: string;
  ServiceShortPre: string;
  ServiceWhoRod: string;
  Classificator: string;
  ProgrammLevel: string;
  Postfix: string;
  AboutPrefix: string;
  And: string;
  GroupAllPrefix: string;
  OrganizationIm: string;
  OrganizationRod: string;
  OrganizationDat: string;
  OrganizationVin: string;
  PlaceAddressName: string;
};

const Lang = new Map<EducationTypeEnum, LangModel>([
  [
    EducationTypeEnum.ChildrenEducation,
    {
      ServiceIm: 'Детское объединение',
      ServiceRod: 'Детского объединения',
      ServiceDat: 'Детскому объединению',
      ServiceVin: 'Детское объединение',
      ServiceTvo: 'Детским объединением',
      ServicePre: 'Детском объединении',
      ServiceAbbr: 'ДО',
      ServiceMust: 'должно',
      ServiceThis: 'данного',
      ServiceMultiIm: 'Детские объединения',
      ServiceMultiRod: 'Детских объединений',
      ServiceShortIm: 'Наименование ДО',
      ServiceShortRod: 'Дет. объединения',
      ServiceShortDat: 'Дет. объединению',
      ServiceShortVin: 'Дет. объединение',
      ServiceShortTvo: 'Дет. объединением',
      ServiceShortPre: 'Дет. объединении',
      ServiceWhoRod: 'его',
      Classificator: 'Вид деятельности',
      ProgrammLevel: 'Уровень программы',
      Postfix: 'о',
      AboutPrefix: 'о',
      And: 'и',
      GroupAllPrefix: 'всех его группах',
      OrganizationIm: 'Образовательная организация',
      OrganizationRod: 'Образовательной организации',
      OrganizationDat: 'Образовательной организации',
      OrganizationVin: 'Образовательную организацию',
      PlaceAddressName: 'Адрес ДО',
    },
  ],
  [
    EducationTypeEnum.ProfessionalEducation,
    {
      ServiceIm: 'Образовательная программа',
      ServiceRod: 'Образовательной программы',
      ServiceDat: 'Образовательной программе',
      ServiceVin: 'Образовательную программу',
      ServiceTvo: 'Образовательной программой',
      ServicePre: 'Образовательной программе',
      ServiceAbbr: 'ОП',
      ServiceMust: 'должна',
      ServiceThis: 'данной',
      ServiceMultiIm: 'Образовательные программы',
      ServiceMultiRod: 'Образовательных программ',
      ServiceShortIm: 'Наименование образовательной программы',
      ServiceShortRod: 'Образ. программы',
      ServiceShortDat: 'Образ. программе',
      ServiceShortVin: 'Образ. программу',
      ServiceShortTvo: 'Образ. программой',
      ServiceShortPre: 'Образ. программе',
      ServiceWhoRod: 'её',
      Classificator: 'Профессия',
      ProgrammLevel: 'Квалификация',
      Postfix: 'а',
      AboutPrefix: 'об',
      And: 'и',
      GroupAllPrefix: 'всех ее группах',
      OrganizationIm: 'Образовательная организация',
      OrganizationRod: 'Организации',
      OrganizationDat: 'Организации',
      OrganizationVin: 'Образовательную Организацию',
      PlaceAddressName: 'Адрес ОП',
    },
  ],
  [
    EducationTypeEnum.DayCareCentersEducation,
    {
      ServiceIm: 'Программа по уходу и присмотру за детьми школьного возраста',
      ServiceRod: 'Программы по уходу и присмотру за детьми школьного возраста',
      ServiceDat: 'Программе по уходу и присмотру за детьми школьного возраста',
      ServiceVin: 'Программу',
      ServiceTvo: 'Программой по уходу и присмотру за детьми школьного возраста',
      ServicePre: 'Программе по уходу и присмотру за детьми школьного возраста',
      ServiceAbbr: 'ГПД',
      ServiceMust: 'должна',
      ServiceThis: 'данной',
      ServiceMultiIm: 'Программы по уходу и присмотру за детьми школьного возраста',
      ServiceMultiRod: 'Программ по уходу и присмотру за детьми школьного возраста',
      ServiceShortIm: 'Наименование программы ГПД',
      ServiceShortRod: 'Программы',
      ServiceShortDat: 'Программе',
      ServiceShortVin: 'Программу',
      ServiceShortTvo: 'Программой',
      ServiceShortPre: 'Программе',
      ServiceWhoRod: 'её',
      Classificator: 'Классификатор услуги',
      ProgrammLevel: 'Уровень программы',
      Postfix: 'а',
      AboutPrefix: 'о',
      And: 'и',
      GroupAllPrefix: 'всех ее группах',
      OrganizationIm: 'Образовательная организация',
      OrganizationRod: 'Организации',
      OrganizationDat: 'Организации',
      OrganizationVin: 'Образовательную организацию',
      PlaceAddressName: 'Адрес ГПД',
    },
  ],
  [
    EducationTypeEnum.SportEducation,
    {
      ServiceIm: 'Услуга',
      ServiceRod: 'Услуги',
      ServiceDat: 'Услуге',
      ServiceVin: 'Наименование услуги',
      ServiceTvo: 'Услугой',
      ServicePre: 'Услуге',
      ServiceAbbr: 'ОУ',
      ServiceMust: 'должна',
      ServiceThis: 'данной',
      ServiceMultiIm: 'Услуги',
      ServiceMultiRod: 'Услуг',
      ServiceShortIm: 'Наименование услуги',
      ServiceShortRod: 'Услуги',
      ServiceShortDat: 'Услуге',
      ServiceShortVin: 'Услугу',
      ServiceShortTvo: 'Услугой',
      ServiceShortPre: 'Услуге',
      ServiceWhoRod: 'её',
      Classificator: 'Вид деятельности',
      ProgrammLevel: 'Уровень программы',
      Postfix: 'а',
      AboutPrefix: 'об',
      And: 'и',
      GroupAllPrefix: 'всех ее группах',
      OrganizationIm: 'Образовательная организация',
      OrganizationRod: 'Организации',
      OrganizationDat: 'Организации',
      OrganizationVin: 'Образовательную организацию',
      PlaceAddressName: 'Адрес',
    },
  ],
  [
    EducationTypeEnum.VirtualAssistantEducation,
    {
      ServiceIm: 'Услуга',
      ServiceRod: 'Услуги',
      ServiceDat: 'Услуге',
      ServiceVin: 'Услугу цифрового репетитора',
      ServiceTvo: 'Услугой',
      ServicePre: 'Услуге',
      ServiceAbbr: 'ОУ',
      ServiceMust: 'должна',
      ServiceThis: 'данной',
      ServiceMultiIm: 'Услуги',
      ServiceMultiRod: 'Услуг',
      ServiceShortIm: 'Наименование услуги',
      ServiceShortRod: 'Услуги',
      ServiceShortDat: 'Услуге',
      ServiceShortVin: 'Услугу',
      ServiceShortTvo: 'Услугой',
      ServiceShortPre: 'Услуге',
      ServiceWhoRod: 'её',
      Classificator: 'Вид деятельности',
      ProgrammLevel: 'Уровень программы',
      Postfix: 'а',
      AboutPrefix: 'об',
      And: 'и',
      GroupAllPrefix: 'всех ее группах',
      OrganizationIm: 'Образовательная организация',
      OrganizationRod: 'Организации',
      OrganizationDat: 'Организации',
      OrganizationVin: 'Организацию',
      PlaceAddressName: 'Адрес ОУ',
    },
  ],
  [
    EducationTypeEnum.ChildrenEducation | EducationTypeEnum.ProfessionalEducation,
    {
      ServiceIm: 'Детское объединение/ Образовательная программа',
      ServiceRod: 'Детского объединения/ Образовательной программы',
      ServiceDat: 'Детскому объединению/ Образовательной программе',
      ServiceVin: 'Детское объединение/ Образовательную программу',
      ServiceTvo: 'Детским объединением/ Образовательной программой',
      ServicePre: 'Детском объединении/ Образовательной программе',
      ServiceAbbr: 'ДО/ ОП',
      ServiceMust: 'должно',
      ServiceThis: 'данного',
      ServiceMultiIm: 'Детские объединения/ Образовательные программы',
      ServiceMultiRod: 'Детских объединений/ Образовательных программ',
      ServiceShortIm: 'Дет. объединение/ Образ. программа',
      ServiceShortRod: 'Дет. объединения/ Образ. программы',
      ServiceShortDat: 'Дет. объединению/ Образ. программе',
      ServiceShortVin: 'Дет. объединение/ Образ. программу',
      ServiceShortTvo: 'Дет. объединением/ Образ. программой',
      ServiceShortPre: 'Дет. объединении/ Образ. программе',
      ServiceWhoRod: 'его',
      Classificator: 'Вид деятельности/ Профессия',
      ProgrammLevel: 'Уровень программы/ Квалификация',
      Postfix: 'о',
      AboutPrefix: 'о',
      And: 'и',
      GroupAllPrefix: 'всех его группах',
      OrganizationIm: 'Образовательная организация',
      OrganizationRod: 'Образовательной организации',
      OrganizationDat: 'Образовательной организации',
      OrganizationVin: 'Образовательную организацию',
      PlaceAddressName: 'Адрес ДО/ОП',
    },
  ],
]);

export const get = (educationTypeId: number | undefined, word: keyof LangModel, isLowerCase = false): string => {
  educationTypeId =
    !educationTypeId || educationTypeId === EducationTypeEnum.All
      ? EducationTypeEnum.ChildrenEducation
      : educationTypeId;
  let langObject = Lang.get(educationTypeId);

  if (!langObject) {
    switch (educationTypeId) {
      case EducationTypeEnum.DayCareCentersEducation | EducationTypeEnum.ChildrenEducation:
        educationTypeId = EducationTypeEnum.ChildrenEducation;
        break;
      case EducationTypeEnum.ChildrenNonDogmEducation:
        educationTypeId = EducationTypeEnum.ChildrenEducation;
        break;
      case EducationTypeEnum.ArtHouseEducation:
        educationTypeId = EducationTypeEnum.SportEducation;
        break;
      default:
        educationTypeId = EducationTypeEnum.ChildrenEducation;
        break;
    }
    langObject = Lang.get(educationTypeId);
  }
  if (!langObject) {
    langObject = Lang.get(EducationTypeEnum.ChildrenEducation);
  }
  if (!langObject) {
    throw new Error('Объект с наименованиями сущностей не инициализирован');
  }
  const result: string = langObject[word] ?? '';

  return isLowerCase ? result.toLowerCase() : result;
};

export const langApi = {
  get,
};
