import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmLoader } from '@mes-ui/lemma';
import serviceClassApi from '../../../lib/api/service-class';
import { ServiceClassData } from '../../../types/service-class';
import PrintList from './print-list';
import { withErrorBoundary } from '../../../hoc/error-boundary';
import { sendReachGoal } from '../../../lib/metrica';

const ServiceClassPrint = () => {
  const { id, type } = useParams<Record<string, string | undefined>>();
  const [serviceClass, setServiceClass] = useState<ServiceClassData | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = 'Карточка группы';

    const getServiceClass = async () => {
      if (id) {
        const response = await serviceClassApi.getServiceClass(id);

        setServiceClass(response);
        setLoading(false);
      }
    };

    getServiceClass();
    sendReachGoal('print');
  }, [id]);

  return loading ? (
    <div className="loader-container-wrap">
      <div className="loader-container">
        <LmLoader
          view="page"
          title="Загружаем форму печати"
          description="Нужно немного подождать"
        />
      </div>
    </div>
  ) : (
    <div className="container">
      <Push size={16} />
      <div className="flex justify-end btn-panel-print">
        <LmButton
          type="button"
          onClick={() => window.print()}
        >
          Печать
        </LmButton>
      </div>

      <h2 className="text-center">Группа обучения</h2>
      <PrintList
        data={serviceClass}
        type={type}
      />
    </div>
  );
};

export default withErrorBoundary(ServiceClassPrint);
