import React, { useCallback, useLayoutEffect } from 'react';
import GlobalHeaderAupd from '@mes-ui/global-header';
import { useDispatch, useSelector } from 'react-redux';
import { aupdTokenFieldName } from '../../config/constants';
import { selectServiceItemCallback } from '../../lib/utils/service';
import { clearProfiles, setAuthorized } from '../../redux/ducks/app';
import { reducerRecord, setUserProfile } from '../../redux/ducks/user-profile';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import tokenManager from '../../lib/token-manager';
import { getCookie } from '../../lib/utils';

const GlobalHeader = () => {
  const dispatch = useDispatch();

  const handleFooBar = useCallback(() => {
    tokenManager.clearToken();
    dispatch(clearProfiles());
    dispatch(setUserProfile(reducerRecord));
    dispatch(setAuthorized(false));
  }, [dispatch]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const aupdToken = getCookie(aupdTokenFieldName);

  useLayoutEffect(() => {
    if (!localStorage.getItem(aupdTokenFieldName) && aupdToken) {
      localStorage.setItem(aupdTokenFieldName, `"${aupdToken}"`);
    }
  }, [aupdToken]);

  return userProfile.tokenAupd && aupdToken && !selectServiceItemCallback ? (
    <div className="global-header">
      <GlobalHeaderAupd callBeforeRedirect={handleFooBar} />
    </div>
  ) : null;
};

export default GlobalHeader;
