import React, { useContext } from 'react';
import { TableColumnType } from '@mosru/esz_uikit';
import { LmIcon, LmTooltip } from '@mes-ui/lemma';
import { ServiceContext } from '../../../index';
import { EducationTypeEnum } from '../../../../../types/education-type';
import SimpleTable from '../../../../../components/table/simple-table';

const ViewAddressClass: React.FC = () => {
  const { serviceData } = useContext(ServiceContext);

  const hasAddressArchive = serviceData.placeService.list.some(({ isAddressArchive }) => isAddressArchive);

  const isChildrenEducation =
    serviceData.educationTypeId === EducationTypeEnum.ChildrenEducation ||
    serviceData.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation;

  const columns: TableColumnType[] = [
    {
      dataIndex: 'address',
      title: 'Адрес',
      render: (item: any) => item.fullAddress,
    },
  ];

  if (!isChildrenEducation) {
    columns.splice(0, 0, {
      dataIndex: 'number',
      title: '№',
      render: (item: any) => item.number,
      width: '40px',
    });
  }

  if (hasAddressArchive) {
    columns.splice(isChildrenEducation ? 0 : 1, 0, {
      dataIndex: 'isAddressArchive',
      title: '',
      width: '1%',
      render: (item: any) =>
        item.isAddressArchive && (
          <LmTooltip
            withArrow
            placement="right"
            content={
              <div
                className="text-center"
                style={{
                  maxWidth: '203px',
                }}
              >
                Адрес более не используется организацией в качестве места предоставления услуги
              </div>
            }
          >
            <LmIcon
              icon="outline-notifications-info"
              size={18}
              color="var(--LM-neutrals-day-700)"
            />
          </LmTooltip>
        ),
    });
  }

  if (
    serviceData.educationTypeId === EducationTypeEnum.SportEducation ||
    serviceData.educationTypeId === EducationTypeEnum.ArtHouseEducation
  ) {
    columns.splice(hasAddressArchive ? 2 : 1, 0, {
      dataIndex: 'organization',
      title: 'Наименование организации',
      render: (item: any) => item.name,
    });
  }

  return (
    <SimpleTable
      dataTest="addressClass"
      data={serviceData.placeService.list.map((item, index) => ({
        ...item,
        number: index + 1,
      }))}
      columns={columns}
    />
  );
};

export default ViewAddressClass;
