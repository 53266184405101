import React, { useCallback, useEffect, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import { GetReport, OptionFilter, ReportFilterData, ReportSchedule } from '../../../../types/reports';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikInput from '../../../../components/formik/formik-input';
import lookupApi from '../../../../lib/api/lookup';
import { ReportFilterTypeEnum } from '../../../../mock-data/report-filter-type-enum';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { SelectOptionType } from '../../../../types/entities';

type Props = {
  filterData: GetReport | null;
  currentReportId: number;
  openScheduleModal: () => void;
  listSchedule: ReportSchedule;
  resetScheduleModalHandler: (value: boolean) => void;
  setOpenTreePopup: (value: boolean) => void;
  values: ReportFilterData;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setValueBasicDirection: React.Dispatch<React.SetStateAction<SelectOptionType | undefined>>;
  valueBasicDirection?: SelectOptionType;
};

const defaultValues = {
  label: 'Все',
  value: '',
};

const FilterVertical: React.FC<Props> = ({
  filterData,
  setOpenTreePopup,
  listSchedule,
  openScheduleModal,
  resetScheduleModalHandler,
  currentReportId,
  values,
  setFieldValue,
  setValueBasicDirection,
  valueBasicDirection,
}) => {
  const [parallels, setParallels] = useState<SelectOptionType[]>([]);
  const [letters, setLetters] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    const fetch = async () => {
      if (!values.organizationId) {
        setParallels([]);
        setLetters([]);

        return;
      }

      const parallels = await dictionariesApi.getParallels(values.organizationId as number);

      const letters = await dictionariesApi.getLetters(values.organizationId as number);

      if (values.organizationId) {
        setParallels(parallels?.length !== 1 ? [defaultValues, ...parallels] : parallels);
        setLetters(letters?.length !== 1 ? [defaultValues, ...letters] : letters);
      }
    };

    if (currentReportId === 36) {
      fetch();
    }
  }, [values.organizationId, currentReportId]);

  const getFilterName = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = filterData?.filterList.find((f) => f.key === filterType);

      if (filter === undefined) {
        return '';
      }

      return filter.parameterField;
    },
    [filterData]
  );

  const onOrganizationSelect = (value?: SelectOptionType) => {
    // попробуем подтянуть тип организации из самой организации, как в монолите
    const typeFilter = filterData?.filterList.find((f) => f.key === ReportFilterTypeEnum.ByTypeProvServ);

    if (typeFilter) {
      const orgTypeId = value ? JSON.parse(value.additionalPropertiesJson as string).OrgTypeId : undefined;

      setFieldValue(
        getFilterName(ReportFilterTypeEnum.ByTypeProvServ),
        typeFilter.listItems?.find((f) => f.id === orgTypeId) ? orgTypeId : ''
      );
    }
  };

  const onTypeProvServSelect = () => {
    setFieldValue(getFilterName(ReportFilterTypeEnum.ByOrganization), null);
  };

  useEffect(() => {
    if (valueBasicDirection?.value !== undefined && getFilterName(ReportFilterTypeEnum.ByClassificatorEKU)) {
      setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassificatorEKU), valueBasicDirection.value as number);
      setFieldValue('classificatorEKUName', valueBasicDirection?.label, false);
    }
  }, [valueBasicDirection, setFieldValue, getFilterName]);

  const BuildAllOptions = (filter: OptionFilter) => {
    const all = {
      label: filter.allOption ?? '',
      value: '',
    };

    if (filter === undefined) {
      return [all];
    }

    if (filter.listItems && filter.listItems.length > 1) {
      return [
        all,
        ...filter.listItems.map((i) => {
          return {
            label: i.name,
            value: i.id,
          };
        }),
      ];
    } else if (filter.listItems?.length === 1) {
      return filter.listItems?.map((i) => {
        return {
          label: i.name,
          value: i.id,
        };
      });
    } else {
      return [
        {
          label: filter.displayValue,
          value: 0,
        },
      ];
    }
  };

  const BuildOptions = (filter: OptionFilter) => {
    const all = {
      label: filter.allOption ?? '',
      value: '',
    };

    if (filter === undefined) {
      return [all];
    }

    if (filter.listItems && filter.listItems.length > 0) {
      return [
        ...filter.listItems.map((i) => {
          return {
            label: i.name,
            value: i.id,
          };
        }),
      ];
    } else {
      return [
        {
          label: filter.displayValue,
          value: 0,
        },
      ];
    }
  };

  const age = (key: number) => {
    return (
      <div key={key}>
        <div>Возраст</div>
        <Push size={8} />
        <div className="report__age--wrap">
          <div className="report__age--element">
            <FormikInputNumber
              name="minAge"
              placeholder="0"
              showErrorImmediately
              excludeZero
            />
          </div>
          <Push
            size={8}
            orientation="horizontal"
          />
          <div className="report__age--element">
            <FormikInputNumber
              name="maxAge"
              placeholder="0"
              showErrorImmediately
              excludeZero
            />
          </div>
        </div>
        <Push size={16} />
      </div>
    );
  };

  return filterData?.filterList ? (
    <>
      {filterData?.filterList.map((item: OptionFilter) => {
        return (
          <div key={item.key}>
            {/* Департмент */}
            {item.key === ReportFilterTypeEnum.ByVedomstvo && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  options={BuildAllOptions(item)}
                  disabled={item.readOnly}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.typeAheadPlaceholder}
                  defaultValue={BuildAllOptions(item)[0]}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Межрайонный Совет Директоров */}
            {item.key === ReportFilterTypeEnum.ByMrsd && item.visible ? (
              <FormikSelect
                key={item.key}
                name={item.parameterField}
                label={item.parameterCaption}
                size="small"
                withSearch
                options={[]}
                disabled={item.readOnly}
                placeholder={item.typeAheadPlaceholder}
                loadOptions={async (query) => await lookupApi.getOrganizationMrsd(query)}
              />
            ) : null}

            {/* Организация */}

            {item.key === ReportFilterTypeEnum.ByOrganization && item.visible ? (
              <>
                {currentReportId === 35 ? <Push size={16} /> : null}

                <FormikSelect
                  loadOptions={async (query) => {
                    return await lookupApi.getOrganization(query, values.vedomstvoId as number);
                  }}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  required={currentReportId === 37}
                  size="small"
                  withSearch
                  defaultValue={
                    filterData.filterParams.organizationId
                      ? {
                          value: item.value,
                          label: item.displayValue,
                        }
                      : null
                  }
                  placeholder={item.typeAheadPlaceholder}
                  disabled={!!filterData.filterParams.organizationId}
                  options={[]}
                  selectedValue={(value?: SelectOptionType) => {
                    if (!value) {
                      setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassParallel), null);
                      setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassLetter), null);
                    }
                    onOrganizationSelect(value);
                  }}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Тип организации */}
            {item.key === ReportFilterTypeEnum.ByTypeProvServ && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  options={BuildAllOptions(item)}
                  disabled={item.readOnly}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.typeAheadPlaceholder}
                  defaultValue={
                    item.displayValue
                      ? BuildAllOptions(item)?.filter((f) => f.label === item.displayValue)[0] ??
                        BuildAllOptions(item)[0]
                      : BuildAllOptions(item)[0]
                  }
                  selectedValue={onTypeProvServSelect}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Тип обучения */}
            {item.key === ReportFilterTypeEnum.ByEducationType && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  options={BuildOptions(item)}
                  disabled={item.readOnly}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.typeAheadPlaceholder}
                  defaultValue={
                    item.displayValue
                      ? BuildAllOptions(item)?.filter((f) => f.label === item.displayValue)[0] ??
                        BuildAllOptions(item)[0]
                      : BuildAllOptions(item)[0]
                  }
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Возрастная категория */}
            {item.key === ReportFilterTypeEnum.ByAgeGroup && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  options={BuildAllOptions(item)}
                  disabled={item.readOnly}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.typeAheadPlaceholder}
                  defaultValue={BuildAllOptions(item)[0]}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Уровень программы */}
            {item.key === ReportFilterTypeEnum.ByProgrammLevel && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  options={BuildAllOptions(item)}
                  disabled={item.readOnly}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.typeAheadPlaceholder}
                  defaultValue={BuildAllOptions(item)[0]}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Округ */}
            {item.key === ReportFilterTypeEnum.ByTerritory && item.visible ? (
              <>
                <FormikSelect
                  placeholder={item.allOption}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  size="small"
                  withSearch
                  options={BuildAllOptions(item)}
                  defaultValue={BuildAllOptions(item)[0]}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Образовательная программа */}
            {item.key === ReportFilterTypeEnum.ByService && item.visible ? (
              <>
                <FormikSelect
                  loadOptions={async (query) => {
                    return await lookupApi.getService(
                      query,
                      filterData?.educationTypeId,
                      values.vedomstvoId ?? undefined,
                      values.organizationId ?? undefined
                    );
                  }}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  size="small"
                  withSearch
                  selectedValue={(val?: SelectOptionType) => {
                    setFieldValue('serviceName', val ? val.label : undefined);
                  }}
                  /* defaultValue={
                     values.organizationId
                     ? {
                     value: item.value,
                     label: item.displayValue,
                     }
                     : null
                     } */
                  placeholder={item.typeAheadPlaceholder}
                  disabled={!!filterData.filterParams.serviceId}
                  options={[]}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Учебный период */}
            {item.key === ReportFilterTypeEnum.ByYearOfTraining && item.visible ? (
              <>
                <FormikSelect
                  placeholder={item.allOption}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  size="small"
                  withSearch
                  options={BuildAllOptions(item)}
                  defaultValue={BuildAllOptions(item)[0]}
                />
                {currentReportId === 31 ? null : <Push size={16} />}
              </>
            ) : null}

            {/* Группа обучения */}
            {item.key === ReportFilterTypeEnum.ByServiceClass && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.typeAheadPlaceholder}
                  loadOptions={async (query) =>
                    await lookupApi.getServiceClass(query, values.serviceId, filterData?.educationTypeId, 8)
                  }
                  options={[]}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Параллель */}
            {item.key === ReportFilterTypeEnum.ByClassParallel && item.visible ? (
              <>
                <FormikSelect
                  placeholder="Выберите..."
                  explainText={!values.organizationId ? 'Сначала выберите организацию' : undefined}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  size="small"
                  disabled={!values.organizationId}
                  withSearch
                  options={parallels}
                  defaultValue={parallels ? parallels[0] : undefined}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Буква класса */}
            {item.key === ReportFilterTypeEnum.ByClassLetter && item.visible ? (
              <>
                <FormikSelect
                  placeholder="Выберите..."
                  explainText={!values.organizationId ? 'Сначала выберите организацию' : undefined}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  size="small"
                  withSearch
                  defaultValue={letters ? letters[0] : undefined}
                  disabled={!values.organizationId}
                  options={letters}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Преподователь */}
            {item.key === ReportFilterTypeEnum.ByTeacher && item.visible ? (
              <FormikSelect
                loadOptions={async (query) => {
                  return await lookupApi.getTeachers(query, values.organizationId ?? undefined);
                }}
                key={item.key}
                name={item.parameterField}
                label={item.parameterCaption}
                size="small"
                withSearch
                placeholder={item.typeAheadPlaceholder}
                options={[]}
              />
            ) : null}

            {/* Статус публикации */}
            {item.key === ReportFilterTypeEnum.ByServiceStatus && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  options={BuildAllOptions(item)}
                  disabled={item.readOnly}
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.typeAheadPlaceholder}
                  defaultValue={BuildAllOptions(item)[0]}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* Наличие в реестре контингента */}
            {item.key === ReportFilterTypeEnum.ByContingentLinkType && item.visible ? (
              <>
                <FormikSelect
                  size="small"
                  withSearch
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  placeholder={item.allOption}
                  options={BuildAllOptions(item)}
                />
                <Push size={16} />
              </>
            ) : null}

            {/* ФИО обучающегося */}
            {item.key === ReportFilterTypeEnum.BySearchText && item.visible ? (
              <>
                <FormikInput
                  key={item.key}
                  label={item.parameterCaption}
                  size="small"
                  name={item.parameterField}
                  placeholder="Введите..."
                />
                <Push size={16} />
              </>
            ) : null}

            {item.key === ReportFilterTypeEnum.ByMinAgeGroup ? <>{age(item.key)}</> : null}

            {/* Расписание */}
            {item.key === ReportFilterTypeEnum.BySchedule && item.visible ? (
              <div className="report__search--wrap">
                <div className="search__wrap--input">
                  <FormikInput
                    key={item.key}
                    label={item.parameterCaption}
                    size="small"
                    name={item.parameterField}
                    value={listSchedule?.selectShortDay}
                    placeholder="Не указано"
                    disabled
                  />
                </div>
                <div className="search__wrap--btn">
                  <div className="flex">
                    <LmButton
                      dataTest="resetSchedule"
                      type="button"
                      variant="secondary"
                      icon="filled-edit-close"
                      iconSize={20}
                      disabled={item.readOnly}
                      onClick={() => {
                        resetScheduleModalHandler(true);
                      }}
                    />
                    <Push
                      size={10}
                      orientation="horizontal"
                    />
                    <div
                      style={{
                        minWidth: '184px',
                      }}
                    >
                      <LmButton
                        type="button"
                        widthFull
                        variant="outline"
                        disabled={item.readOnly}
                        onClick={openScheduleModal}
                      >
                        Выбрать расписание
                      </LmButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Базовое направление */}

            {item.key === ReportFilterTypeEnum.ByClassificatorEKU && item.visible ? (
              <div className="report__search--wrap">
                <div className="search__wrap--input">
                  <FormikSelect
                    key={values[item.parameterField]}
                    size="small"
                    withSearch
                    options={[]}
                    name={item.parameterField}
                    label={item.parameterCaption}
                    required={currentReportId === 6}
                    selectedValue={(value) => setValueBasicDirection(value)}
                    loadOptions={async (query) => await lookupApi.getClassificator(query)}
                    placeholder="Начните вводить..."
                    defaultValue={!valueBasicDirection?.value ? null : valueBasicDirection}
                  />
                </div>
                <div className="search__wrap--btn">
                  <LmButton
                    dataTest="options"
                    type="button"
                    variant="outline"
                    icon="filled-controls-options-horizontal"
                    iconSize={20}
                    onClick={() => setOpenTreePopup(true)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  ) : null;
};

export default FilterVertical;
