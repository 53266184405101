import React, { useCallback, useContext, useEffect, useState, Fragment } from 'react';
import { Formik, FormikProps } from 'formik';
import { Push } from '@mosru/esz_uikit';
import FormikInput from '../../../../../components/formik/formik-input';
import Popup from '../../../../../components/modals/popup';
import { ServiceClassSubClass } from '../../../../../types/service-class';
import serviceClassApi from '../../../../../lib/api/service-class';
import { SubClassItem } from './sub-class-item';
import { ServiceClassTableContext } from '../../../utils';
import { LoaderCustom } from '../../../../../components/loader-custom';

type FormData = {
  newSubclassName: string;
};

const initialValues = {
  newSubclassName: '',
};

type Props = {
  open: boolean;
  close: () => void;
};

const Union: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const [listSubClass, setListSubClass] = useState<ServiceClassSubClass[]>([]);

  const callOptions = useCallback(() => {
    setOpenDetails(false);
    fetchTable();
    setSelected([]);
    close();
  }, [setSelected, close, setOpenDetails, fetchTable]);

  const submitForm = useCallback(
    async (values: FormData) => {
      if (values.newSubclassName || listSubClass.length) {
        const list = listSubClass
          .filter((item) => !(!item.isAllSelectedLearnersInSubclass && item.hasSelectedLearnersInSubclass))
          .map((item) => {
            return {
              isEnabled: item.hasSelectedLearnersInSubclass,
              subclassId: item.id,
            };
          });

        if (list.length === 0 && !values.newSubclassName) {
          callOptions();

          return;
        }

        if (serviceClassData.id) {
          await serviceClassApi.updateSubClassList(serviceClassData.id, {
            ...values,
            megaRelationIds: selected,
            serviceClassId: serviceClassData.teacher.serviceClassId as number,
            educationTypeId: serviceClassData.educationTypeId,
            subclassList: values.newSubclassName
              ? [
                  {
                    isEnabled: true,
                    subclassId: 0,
                  },
                  ...list,
                ]
              : list,
          });

          callOptions();
        }
      } else {
        close();
        setOpenDetails(false);
        setSelected([]);
      }
    },
    [
      callOptions,
      setSelected,
      close,
      setOpenDetails,
      listSubClass,
      selected,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      serviceClassData.teacher.serviceClassId,
    ]
  );

  useEffect(() => {
    const fetch = async () => {
      setLoadingModal(true);
      if (serviceClassData.id) {
        try {
          const response = await serviceClassApi.postSubClassList(serviceClassData.id, {
            educationTypeId: serviceClassData.educationTypeId,
            serviceClassId: serviceClassData.id,
            megaRelationIds: selected,
          });

          setListSubClass(response);
        } finally {
          setLoadingModal(false);
        }
      }
    };

    open && fetch();
  }, [open, selected, serviceClassData.educationTypeId, serviceClassData.id]);

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<FormData>) => {
        const { handleSubmit, isSubmitting, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="uniteStudents"
              title="Объединение обучающихся в подгруппы"
              open={open}
              loading={loadingModal}
              buttonPrimaryText="Объединить"
              primaryButtonModifiers={{
                loading: isSubmitting,
              }}
              onSubmit={handleSubmit}
              onClose={close}
              onClearData={resetForm}
            >
              <>
                <FormikInput
                  label="Наименование новой подгруппы"
                  size="small"
                  name="newSubclassName"
                  placeholder="Введите..."
                  autoComplete="off"
                />

                <Push
                  orientation="vertical"
                  size={12}
                />
                {loading ? (
                  <LoaderCustom size={12} />
                ) : (
                  listSubClass.map((item) => (
                    <Fragment key={item.id}>
                      <SubClassItem
                        id={item.id}
                        name={item.name}
                        setLoading={setLoading}
                        updateTable={fetchTable}
                        listSubClass={listSubClass}
                        selectedPeople={selected}
                        setListSubClass={setListSubClass}
                        serviceClassData={serviceClassData}
                        isAllSelectedLearnersInSubclass={item.isAllSelectedLearnersInSubclass}
                        hasSelectedLearnersInSubclass={item.hasSelectedLearnersInSubclass}
                      />
                      <Push
                        orientation="vertical"
                        size={12}
                      />
                    </Fragment>
                  ))
                )}
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default Union;
