import { LmFilterVertical } from '@mes-ui/lemma';
import { SelectOptionType } from '@mosru/esz_uikit';
import { Formik, FormikProps } from 'formik';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { number, object as objectYup } from 'yup';
import FormikSelect from '../../../../components/formik/formik-select';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { reportsApi } from '../../../../lib/api/reports';
import { removeEmptyDataForRequest } from '../../../../lib/utils/reports';
import { ReportFilterTypeEnum } from '../../../../mock-data/report-filter-type-enum';
import {
  GetReport,
  OptionFilter,
  ReportFilterData,
  ReportInformation,
  SavedReportsFilterContextType,
  TypeSwitch,
} from '../../../../types/reports';
import OrganizationItem from './filter-items/organization-item';
import OtherItem from './filter-items/other-item';
import ServiceItem from './filter-items/service-item';

type Props = {
  reportFiltersData: GetReport | null;
  loading: boolean;
  setQuery: (data: ReportFilterData | undefined) => void;
  setHeader: (header: { [key: string]: any }[]) => void;
  resetTable: () => void;
};

export const disableFilterPlaceholder = 'Выбор фильтра не доступен для вашей роли';

export const SavedReportsFilterContext = createContext<SavedReportsFilterContextType>(
  {} as SavedReportsFilterContextType
);

const SavedReportsFilter: React.FC<Props> = ({ reportFiltersData, loading, setQuery, setHeader, resetTable }) => {
  const [formData, setFormData] = useState<ReportFilterData>({
    vedomstvoId: null,
    organizationId: null,
  });
  const [listReport, setListReport] = useState<SelectOptionType[] | undefined>();
  const [typesFinancing, setTypesFinancing] = useState<TypeSwitch[]>([]);
  const [contingents, setContingents] = useState<TypeSwitch[]>([]);
  const [organization, setOrganization] = useState<SelectOptionType | undefined>(undefined);
  const [resetFlag, setResetFlag] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [parallels, setParallels] = useState<SelectOptionType[]>([]);
  const [letters, setLetters] = useState<SelectOptionType[]>([]);

  const getUserValidationSchema = () => {
    return objectYup().shape({
      maxAge: number()
        .nullable()
        .test('maxAge', 'Максимальный возраст не может быть меньше минимального', function (value) {
          return !(this.parent.minAge !== undefined && value !== undefined && value && value < this.parent.minAge);
        })
        .min(1, 'От 1')
        .max(120, 'До 120')
        .typeError('Введите значение меньшее или равное 120.'),

      minAge: number()
        .nullable()
        .test('minAge', 'Минимальный возраст не может быть больше максимального', function (value) {
          return !(this.parent.maxAge !== undefined && value !== undefined && value && value > this.parent.maxAge);
        })
        .min(1, 'От 1')
        .max(120, 'До 120')
        .typeError('Введите значение меньшее или равное 120.'),
    });
  };

  /** установка значений по-умолчанию */
  const initialFormData: ReportFilterData = useMemo(() => {
    const d: ReportFilterData = {
      vedomstvoId: null,
      organizationId: null,
    };

    reportFiltersData?.filterList?.forEach((f: OptionFilter) => {
      if (f.value !== null) {
        d[f.parameterField] = f.value;
      }
    });
    setFormData(d);

    return d;
  }, [reportFiltersData]);

  const FillFilterValues = (filter: OptionFilter): TypeSwitch[] => {
    const filterValues: TypeSwitch[] = [];

    for (let i = 0; i < filter.listItems.length; i++) {
      filterValues.push({
        name: filter.listItems[i].name,
        id: filter.listItems[i].id,
        selected: filter.displayValue === filter.listItems[i].name,
      });
    }

    return filterValues;
  };

  const getFilterData = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);

      if (filter === undefined) {
        return [];
      }

      const defaultValue = {
        label: filter.allOption ?? '',
        value: '',
      };

      return [
        defaultValue,
        ...(filter.listItems || []).map((i) => {
          return {
            label: i.name,
            value: i.id,
          };
        }),
      ];
    },
    [reportFiltersData]
  );

  const getFilterAllOption = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);

      if (filter === undefined) {
        return {
          label: '',
          value: '',
        };
      }

      return {
        label: filter.allOption ?? '',
        value: '',
      };
    },
    [reportFiltersData]
  );

  const getFilterName = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);

      if (filter === undefined) {
        return '';
      }

      return filter.parameterField;
    },
    [reportFiltersData]
  );

  const getFilterLabel = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);

      if (filter === undefined) {
        return '';
      }

      return filter.parameterCaption;
    },
    [reportFiltersData]
  );

  const getFilterReadOnly = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);

      if (filter === undefined) {
        return undefined;
      }

      return filter.readOnly;
    },
    [reportFiltersData]
  );

  const isVisible = useCallback(
    (filterName: string) => {
      const filter = reportFiltersData?.filterList.find((f) => f.parameterField === filterName);

      if (filter === undefined) {
        return false;
      }

      return filter.visible;
    },
    [reportFiltersData]
  );

  const getFilterTypeAheadPlaceholder = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);

      if (filter === undefined) {
        return {};
      }

      return filter.readOnly
        ? {
            explainText: disableFilterPlaceholder,
          }
        : {
            placeholder: filter.typeAheadPlaceholder,
          };
    },
    [reportFiltersData]
  );

  // список отчетов для поиска
  useEffect(() => {
    const fetch = async () => {
      const reports = await reportsApi.getAviableReportList();
      const transformReports = reports?.map((item: ReportInformation) => {
        return {
          label: item.title,
          value: item.id,
        };
      });

      setListReport([
        {
          label: 'Все отчеты',
          value: '',
        },
        ...transformReports,
      ]);
    };

    fetch();
  }, [reportFiltersData]);

  // Инициализация фильтров типа Switch
  useEffect(() => {
    const fetch = async () => {
      const typeFinancingFilter = reportFiltersData?.filterList.find(
        (f) => f.key === ReportFilterTypeEnum.ByTypeFinansing
      );

      if (typeFinancingFilter !== undefined) {
        setTypesFinancing([
          {
            name: 'Все',
            id: 0,
            selected: true,
          },
          ...FillFilterValues(typeFinancingFilter),
        ]);
        setFormData((prev: any) => ({
          ...prev,
          [getFilterName(ReportFilterTypeEnum.ByTypeFinansing)]: 0,
        }));
      }

      const contingentLinkFilter = reportFiltersData?.filterList.find(
        (f) => f.key === ReportFilterTypeEnum.ByContingentLinkType
      );

      if (contingentLinkFilter !== undefined) {
        setContingents([
          {
            name: 'Все',
            id: 0,
            selected: true,
          },
          ...FillFilterValues(contingentLinkFilter),
        ]);
        setFormData((prev: any) => ({
          ...prev,
          [getFilterName(ReportFilterTypeEnum.ByContingentLinkType)]: 0,
        }));
      }
    };

    fetch();
  }, [getFilterName, reportFiltersData, resetFlag]);

  useEffect(() => {
    const fetch = async () => {
      const defaultValues = {
        label: 'Все',
        value: 'Все',
      };

      if (!organization) {
        setParallels([]);
        setLetters([]);
      } else {
        const parallels = await dictionariesApi.getParallels(organization.value as number);
        const letters = await dictionariesApi.getLetters(organization.value as number);

        setParallels(parallels?.length !== 1 ? [defaultValues, ...parallels] : parallels);
        setLetters(letters?.length !== 1 ? [defaultValues, ...letters] : letters);
      }
    };

    fetch();
  }, [organization, getFilterData]);

  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  const contextFilterValues = useMemo(
    () => ({
      getFilterName,
      getFilterLabel,
      getFilterData,
      getFilterAllOption,
      getFilterReadOnly,
      getFilterTypeAheadPlaceholder,
    }),
    [getFilterAllOption, getFilterData, getFilterLabel, getFilterName, getFilterReadOnly, getFilterTypeAheadPlaceholder]
  );

  const submitForm = (value: ReportFilterData, actions: { setSubmitting: (arg: boolean) => void }) => {
    setIsLoading(true);

    removeEmptyDataForRequest(value);
    setQuery(value);
    actions.setSubmitting(false);
  };

  const searchAction = (data: any) => {
    const fields = Object.keys(data);

    return Object.keys(data).reduce((acc, valueIndex, index) => {
      if (
        data[valueIndex] !== undefined &&
        (data[valueIndex] !== 0 || fields[index] === 'ageGroup' || fields[index] === 'territoryId') &&
        data[valueIndex] !== '' &&
        data[valueIndex] !== null &&
        data[valueIndex] !== initialFormData[valueIndex] &&
        isVisible(fields[index]) &&
        fields[index] !== 'periodTo' // костыль
      ) {
        acc++;
      }

      return acc;
    }, 0);
  };

  const resetForm = () => {
    resetTable();
    setHeader([]);
    setFormData(initialFormData);
    setQuery(undefined);
    setOrganization(undefined);
    setResetFlag(Math.random());
  };

  return (
    <Formik
      onSubmit={submitForm}
      enableReinitialize
      initialValues={formData}
      validationSchema={getUserValidationSchema}
    >
      {({ handleSubmit, values }: FormikProps<ReportFilterData>) => {
        const filterCount = searchAction(values);

        return (
          <form
            onSubmit={handleSubmit}
            style={{
              zIndex: 5,
            }}
          >
            <LmFilterVertical
              dataTest="savedReportFilter"
              title="Фильтр"
              classNameContent="filter-content filter-content--wrap"
              onClear={resetForm}
              primaryButtonModifiers={{
                loading: isLoading,
                type: 'submit',
              }}
              buttonSecondaryText={filterCount ? `Сбросить фильтры (${filterCount})` : 'Сбросить фильтры'}
            >
              <>
                <FormikSelect
                  name="reportId"
                  size="small"
                  withSearch
                  options={listReport || []}
                  defaultValue={{
                    label: 'Все отчеты',
                    value: '',
                  }}
                />
                <SavedReportsFilterContext.Provider value={contextFilterValues}>
                  <OrganizationItem
                    organization={organization}
                    setOrganization={setOrganization}
                  />
                  <ServiceItem
                    parallels={parallels}
                    letters={letters}
                    typesFinancing={typesFinancing}
                    resetFlag={resetFlag}
                  />
                  <OtherItem contingents={contingents} />
                </SavedReportsFilterContext.Provider>
              </>
            </LmFilterVertical>
          </form>
        );
      }}
    </Formik>
  );
};

export default SavedReportsFilter;
