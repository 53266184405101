import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { LmButton } from '@mes-ui/lemma';
import FormikInput from '../../../../components/formik/formik-input';
import { ReportSchedule } from '../../../../types/reports';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AuthorizationData } from '../../../../types/authorization-data';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  listSchedule: ReportSchedule;
  openScheduleModal: () => void;
  resetScheduleModalHandler: (value: boolean) => void;
};

export const FieldSchedule: React.FC<Props> = ({
  isAdmin,
  userProfile,
  listSchedule,
  openScheduleModal,
  resetScheduleModalHandler,
}) => {
  const { setFieldValue } = useFormikContext();

  // Расписание работы группы
  const accessWorkSchedule: boolean = useMemo(() => {
    return hasAccessObjectAny(userProfile, [accessObject.ServiceClassDayCare], accessAction.View) || isAdmin;
  }, [isAdmin, userProfile]);

  useEffect(() => {
    setFieldValue('scheduleOfServiceText', listSchedule?.selectShortDay);
    setFieldValue('scheduleOfService', listSchedule?.selectDaysForRequest);
  }, [listSchedule?.selectDaysForRequest, listSchedule?.selectShortDay, setFieldValue]);

  const handleClear = () => {
    resetScheduleModalHandler(true);
  };

  return accessWorkSchedule ? (
    <div className="report__search--wrap">
      <div className="search__wrap--input">
        <FormikInput
          label="Расписание работы группы"
          value={listSchedule.selectShortDay}
          size="small"
          name="selectShortDay"
          placeholder="Не указано"
          disabled
        />
      </div>
      <div className="search__wrap--btn">
        <div className="btn__group">
          <LmButton
            dataTest="resetSchedule"
            type="button"
            variant="secondary"
            icon="filled-edit-close"
            iconSize={20}
            onClick={handleClear}
          />
          <LmButton
            type="button"
            variant="outline"
            icon="filled-controls-options-horizontal"
            onClick={openScheduleModal}
          />
        </div>
      </div>
    </div>
  ) : null;
};
