import React, { useCallback } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import { schoolOrganizationIdField } from './organization';
import { EducationTypeEnum } from '../../../../types/education-type';
import { classParallelIdField } from './class-parallel-leter';
import { TrainingGroupRequestData } from '../../../../types/requests';
import { scheduleOfTimetableIdField } from './admission-plan';
import { serviceClassIdField } from './service-class';
import { desiredDaysField } from './visit-days';
import { userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { getServiceEditLink } from '../../../../lib/utils';

type MenuPlacement = 'bottom' | 'auto' | 'top';

type ServiceProps = Omit<FieldProps<string>, 'name'> & {
  parent?: string;
  trainingGroup?: TrainingGroupRequestData;
  menuPlacement?: MenuPlacement;
};

export const serviceIdField = 'serviceId';
export const serviceNameField = 'serviceName';

const Service = ({
  label = 'Наименование программы',
  editMode,
  required,
  defaultValue,
  parent,
  trainingGroup,
  menuPlacement = 'auto',
}: ServiceProps) => {
  const { setFieldValue } = useFormikContext<FormikHelpers<any>>();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const serviceId = parent ? `${parent}.${serviceIdField}` : `${serviceIdField}`;
  const serviceName = parent ? `${parent}.${serviceNameField}` : `${serviceNameField}`;

  const scheduleOfTimetableId = parent ? `${parent}.${scheduleOfTimetableIdField}` : `${scheduleOfTimetableIdField}`;

  const [serviceIdOptions] = useField<number>(parent ? `${parent}.${serviceIdField}` : `${serviceIdField}`);
  const [serviceNameOptions] = useField<string>(parent ? `${parent}.${serviceNameField}` : `${serviceNameField}`);

  const [organizationField] = useField(parent ? `school.${schoolOrganizationIdField}` : `${schoolOrganizationIdField}`);

  const [classParallelId] = useField(parent ? `school.${classParallelIdField}` : `${classParallelIdField}`);

  const desiredDays = parent ? `trainingGroup.${desiredDaysField}` : desiredDaysField;

  const serviceClassId = parent ? `trainingGroup.${serviceClassIdField}` : serviceClassIdField;

  const disabled = Boolean(organizationField.value) && Boolean(classParallelId.value);

  const fetchOptions = useCallback(
    async (query: string) =>
      await lookupApi.getService(
        query,
        EducationTypeEnum.DayCareCentersEducation,
        null,
        userProfile.organizationId,
        classParallelId.value,
        true,
        true
      ),
    [classParallelId, userProfile.organizationId]
  );

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={
        serviceIdOptions.value ? (
          <Link
            className="brand-link"
            to={() => getServiceEditLink(EducationTypeEnum.DayCareCentersEducation, serviceIdOptions.value)}
            target="_blank"
            rel="noreferrer"
          >
            {`${trainingGroup?.serviceName}` ?? serviceNameOptions.value}
          </Link>
        ) : (
          '-'
        )
      }
      defaultValue={defaultValue}
    >
      <div className="flex">
        <div
          style={{
            width: '100%',
          }}
        >
          <FormikSelect
            name={serviceId}
            required
            size="small"
            withSearch
            loadOptions={fetchOptions}
            options={[]}
            menuPlacement={menuPlacement}
            placeholder="Выберите..."
            explainText={!disabled ? 'Сначала выберите общеобразовательную организацию и параллель' : undefined}
            selectedValue={(option?: SelectOptionType) => {
              if (option) {
                setFieldValue(serviceId, option.value);
                setFieldValue(serviceName, option.label);
              } else {
                setFieldValue(serviceId, '');
                setFieldValue(serviceName, '');
                setFieldValue(scheduleOfTimetableId, '');
                setFieldValue(desiredDays, '');
                setFieldValue(serviceClassId, '');
              }
            }}
            disabled={!disabled}
            defaultValue={
              trainingGroup?.serviceId
                ? trainingGroup?.serviceId === serviceIdOptions.value
                  ? ({
                      value: trainingGroup?.serviceId,
                      label: `${trainingGroup?.fullServiceName}, ${trainingGroup?.itemsWorkAddress}`,
                    } as SelectOptionType)
                  : ({
                      value: serviceIdOptions.value,
                      label: serviceNameOptions.value,
                    } as SelectOptionType)
                : null
            }
          />
        </div>
        <Push
          orientation="horizontal"
          size={8}
        />
        <div
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <LmButton
            type="button"
            variant="outline"
            disabled={!serviceIdOptions.value}
            onClick={() =>
              window.open(
                getServiceEditLink(EducationTypeEnum.DayCareCentersEducation, serviceIdOptions.value),
                '_blank'
              )
            }
          >
            Просмотр
          </LmButton>
        </div>
      </div>
    </Field>
  );
};

export default Service;
