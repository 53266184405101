import React from 'react';
import { LmDialog, LmDialogProps } from '@mes-ui/lemma';

type DialogProps = Omit<LmDialogProps, 'isOpenOverlay' | 'dataTest'> & {
  dataTest: string;
  callCloseAfterSubmit?: boolean;
};

const Dialog = ({
  isOpenDialog,
  buttonSecondaryText = 'Отмена',
  primaryButtonModifiers,
  secondaryButtonModifiers,
  callCloseAfterSubmit,
  onClickPrimaryButton,
  ...rest
}: DialogProps) => {
  return (
    <LmDialog
      {...rest}
      isOpenOverlay
      isOpenDialog={isOpenDialog}
      buttonSecondaryText={buttonSecondaryText}
      primaryButtonModifiers={{
        size: 'medium',
        ...primaryButtonModifiers,
      }}
      secondaryButtonModifiers={{
        size: 'medium',
        ...secondaryButtonModifiers,
      }}
      onClickPrimaryButton={() => {
        onClickPrimaryButton?.();
        callCloseAfterSubmit && rest.onClickSeconadaryButton?.();
      }}
    />
  );
};

export default Dialog;
