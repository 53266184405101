import React, { useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { object as objectYup, string as stringYup } from 'yup';
import { useLocation } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import { LoginData, initialLoginData } from '../../../../types/auth';
import FormikInput from '../../../../components/formik/formik-input';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import history from '../../../../history';
import { loginDebugMode, routes } from '../../../../config/constants';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import { authApi } from '../../../../lib/api';
import { onSignIn } from '../../../../redux/thunks/auth';

type Props = {
  onSuccess?: () => void;
  setErrorBox: (text: string, isShow: boolean) => void;
};

const Signin = ({ setErrorBox }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialErrors = useInitialErrors(initialLoginData, getValidationSchema());

  const signInHandler = useCallback(
    async (values: LoginData, { setErrors }) => {
      try {
        const response = await authApi.signIn(values);

        if (response.isPasswordExpired) {
          history.push({
            pathname: routes.changePassword,
            search: location.search,
            state: values,
          });
        } else if (response.isLoggedIn) {
          await dispatch(onSignIn(response.token));
        } else {
          setErrorBox(response.message, true);
          setErrors({
            login: ' ',
            password: ' ',
          });
        }
      } catch (err) {
        setErrorBox('Произошла ошибка. Повторите попытку позже.', true);
      }
    },
    [dispatch, location.search, setErrorBox]
  );

  return (
    <Formik
      onSubmit={signInHandler}
      enableReinitialize
      initialValues={initialLoginData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<LoginData>) => {
        const { handleSubmit, values, isSubmitting, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <FormikInput
              label="Логин"
              name="login"
              value={values.login}
              placeholder="Введите логин..."
              size="medium"
            />

            <Push size={12} />
            <FormikInput
              label="Пароль"
              type="password"
              placeholder="Введите пароль..."
              value={values.password}
              name="password"
              size="medium"
            />

            <Push size={20} />
            <FormikCheckbox
              label="Запомнить меня"
              name="remember"
              boxSize="small"
            />

            <Push size={20} />
            <LmButton
              dataTest="enterEsz"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              size="medium"
              widthFull
            >
              Войти в систему
            </LmButton>
          </form>
        );
      }}
    </Formik>
  );
};

export default Signin;

const getValidationSchema = () =>
  objectYup().shape({
    login: stringYup().nullable().required('Введите логин'),
    password:
      loginDebugMode === 'true' ? stringYup().nullable() : stringYup().nullable().min(6, '').required('Введите пароль'),
  });
