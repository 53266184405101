import React from 'react';
import { EventUrl } from '../../mock-data/event';
import useQuery from '../../hooks/use-query';
import FormikTextarea from '../formik/formik-textarea';

export const NotificationField = () => {
  const queryEventName = useQuery().get(EventUrl.EventName);

  return queryEventName ? (
    <FormikTextarea
      label="По уведомлению"
      minRows={2}
      maxRows={4}
      value={queryEventName}
      disabled
      name="event"
      placeholder="Введите..."
    />
  ) : null;
};
