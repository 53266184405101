import React from 'react';
import { LmListItem } from '@mes-ui/lemma';
import DropDown from '../../components/drop-down';
import TableOptions from '../../components/table-options';
import { routes } from '../../config/constants';
import history from '../../history';

type EditProps = {
  id: number;
  onSign: () => void;
  canEdit: boolean;
  canSign: boolean;
  canDelete: boolean;
  setRemovedId: () => void;
};

const Edit: React.FC<EditProps> = ({ setRemovedId, id, onSign, canEdit, canSign, canDelete }) => {
  return (
    <div className="disciplines-table-comment right">
      <div className="disciplines-table-comment__controls">
        <DropDown
          dataTest="organizationOptions"
          component={() => <TableOptions />}
        >
          <>
            {canEdit && (
              <LmListItem
                dataTest="editOrganization"
                text="Редактировать"
                icon="outline-edit-edit"
                iconSize={20}
                onClick={() => history.push(`${routes.organizations}/${id}`)}
              />
            )}
            {canSign && (
              <LmListItem
                dataTest="signOrganization"
                text="Подписать эл. подписью"
                icon="filled-edit-checkmark-circle-outline"
                iconSize={20}
                onClick={onSign}
              />
            )}
            {canDelete && (
              <LmListItem
                dataTest="deleteOrganization"
                text="Удалить"
                icon="outline-edit-trash-alt"
                iconSize={20}
                onClick={setRemovedId}
              />
            )}
          </>
        </DropDown>
      </div>
    </div>
  );
};

export default Edit;
