import React from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmIcon, LmToggle, LmTooltip } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import { BudgetPlaceFormData, RestrictionsBudgetPlacesFormData } from '../../../../types/classificator';
import { MAX_LIMIT_VOLUME } from './utils';
import SimpleTable from '../../../../components/table/simple-table';

type ProfessionTableProps = {
  editMode: boolean;
};

const MIN_HEIGHT_FIELD = 36;

const ProfessionTable = ({ editMode }: ProfessionTableProps) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<RestrictionsBudgetPlacesFormData>();

  const getCurrentIndex = (id: number) => values.data.findIndex(({ classificatorEKUId }) => classificatorEKUId === id);

  const errorMessageLimit = (limitVolume: number | string | null, volume: number | null) => {
    const limitNumber = limitVolume && Number(limitVolume);

    return limitNumber
      ? limitNumber > MAX_LIMIT_VOLUME
        ? `Количество бюджетных мест не может быть больше ${MAX_LIMIT_VOLUME}`
        : !!volume && limitNumber < volume && 'Невозможно уменьшить лимит, указанное количество мест уже занято.'
      : limitNumber !== null && 'Укажите количество или снимите отметку о наличии бюджетных мест';
  };

  const handleChangeToggle = (id: number) => (checked: boolean) => {
    const index = getCurrentIndex(id);

    setFieldValue(`data[${index}].hasPlaces`, checked);
    setFieldTouched(`data[${index}].limitVolume`, true);
    setFieldValue(`data[${index}].limitVolume`, checked ? 0 : null);
  };

  const handleDelete = (currentId: number) => () => {
    setFieldValue(
      'data',
      values.data.filter(({ classificatorEKUId }) => classificatorEKUId !== currentId)
    );
  };

  const checkValidationValue = (value: string) => (value.length > 0 && value[0] === '0' ? value.slice(1) : value);

  const filterTable = (optionsData: BudgetPlaceFormData[]) => {
    return optionsData.sort((a, b) => {
      if (a.classificatorEKUName && b.classificatorEKUName) {
        const labelA = a.classificatorEKUName;
        const labelB = b.classificatorEKUName;

        if (labelA < labelB) {
          return -1;
        }
        if (labelB > labelA) {
          return 1;
        }
      }

      return 0;
    });
  };

  return (
    <SimpleTable
      data={!editMode ? filterTable(values.data) : values.data}
      // pageSize={Number.MAX_SAFE_INTEGER}
      columns={[
        {
          dataIndex: 'classificatorEKUName',
          title: 'Доступные услуги',
          render: (item: any) => (
            <div
              className="flex items-center"
              style={{
                minHeight: MIN_HEIGHT_FIELD,
              }}
            >
              {item.classificatorEKUName}
            </div>
          ),
          width: '35%',
          sortable: true,
        },
        {
          dataIndex: 'hasPlaces',
          title: 'Бюджетные места',
          render: (item: any) => (
            <div
              className="flex justify-center items-center"
              style={{
                width: 127,
                minHeight: MIN_HEIGHT_FIELD,
              }}
            >
              <LmToggle
                disabled={!editMode || !!item.volume}
                name={item.id}
                dataTest={item.id}
                checked={item.hasPlaces}
                onChange={handleChangeToggle(item.classificatorEKUId)}
              />
            </div>
          ),
          width: '172px',
          sortable: true,
        },
        {
          dataIndex: 'usedVolume',
          title: 'Зачислено',
          render: (item: any) => (
            <div
              className="flex items-center"
              style={{
                minHeight: MIN_HEIGHT_FIELD,
              }}
            >
              {item.hasPlaces ? item.usedVolume || 0 : '—'}
            </div>
          ),
          width: '118px',
          sortable: true,
        },
        {
          dataIndex: 'limitVolume',
          title: (
            <span className="flex">
              Занято
              <LmTooltip
                withArrow
                content={
                  <div
                    className="text-center font-size-xsmall font-weight-base"
                    style={{
                      maxWidth: '268px',
                    }}
                  >
                    Количество мест, зарезервированных в планах приема образовательных программ для соответствующих
                    профессий
                  </div>
                }
                placement="bottom"
              >
                <div
                  style={{
                    marginLeft: 4,
                  }}
                >
                  <LmIcon
                    icon="outline-notifications-info"
                    size={18}
                    color="var(--LM-neutrals-day-700)"
                  />
                </div>
              </LmTooltip>
              / Всего бюджетных мест
            </span>
          ),
          render: (item: any) => (
            <div
              className="flex items-center"
              style={{
                minHeight: MIN_HEIGHT_FIELD,
              }}
            >
              {item.limitVolume !== null ? (
                <>
                  <div
                    className="flex-none text-right"
                    style={{
                      minWidth: 25,
                    }}
                  >
                    {item.volume ?? 0} /
                  </div>
                  {editMode ? (
                    <>
                      <Push
                        orientation="horizontal"
                        size={8}
                      />
                      <div className="flex-none">
                        <FormikInputNumber
                          name={`data[${getCurrentIndex(item.classificatorEKUId)}].limitVolume`}
                          value={checkValidationValue(item.limitVolume)}
                          showErrorImmediately
                          maxLength={`${MAX_LIMIT_VOLUME}`.length}
                          max={MAX_LIMIT_VOLUME}
                        />
                      </div>
                      <Push
                        orientation="horizontal"
                        size={12}
                      />
                      <div className="flex-auto color-danger line-height-base">
                        {errorMessageLimit(item.limitVolume, item.volume)}
                      </div>
                    </>
                  ) : (
                    <>&nbsp;{item.limitVolume ?? 0}</>
                  )}
                </>
              ) : (
                <span
                  className="flex justify-center"
                  style={{
                    width: 27,
                  }}
                >
                  —
                </span>
              )}
              {editMode && (
                <div className="ml-auto">
                  <LmButton
                    dataTest="deleteLimit"
                    type="button"
                    variant="secondary"
                    icon="outline-edit-trash-alt"
                    iconSize={20}
                    disabled={!!item.volume || item.hasServices}
                    onClick={handleDelete(item.classificatorEKUId)}
                  />
                </div>
              )}
            </div>
          ),
          sortable: true,
        },
      ]}
    />
  );
};

export default ProfessionTable;
