import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { bookingApi } from '../../lib/api/booking';
import { BookingResultData } from '../../types/booking';
import { BookingType } from '../types/entities';
import { AppState } from '../types/state';

export const moduleName = 'booking';
export const SET_BOOKING = `${moduleName}/SET_BOOKING`;

const reducerRecord: BookingType = Object.freeze({
  serviceId: undefined,
  bookingId: undefined,
});

// Reducer
export default function reducer(state: BookingType = reducerRecord, action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case SET_BOOKING:
      return {
        ...state,
        booking: payload.booking,
        serviceId: payload.serviceId,
      };
    default:
      return state;
  }
}

// AC
export const setBookingState = (data?: BookingType) => ({
  type: SET_BOOKING,
  payload: {
    booking: data?.booking,
    serviceId: data?.serviceId,
  },
});

// Thunks
export const clearBookingRequest =
  () => async (dispatch: ThunkDispatch<AppState, BookingType, AnyAction>, getState: () => AppState) => {
    const { booking, serviceId } = getState()[moduleName];

    if (booking?.bookingId && serviceId) {
      await bookingApi.deletePreBookingByServiceClass(booking.bookingId, serviceId);
    }
  };

export const handleBookingChange =
  (booking?: BookingResultData, serviceId?: number) =>
    async (dispatch: ThunkDispatch<AppState, BookingType, AnyAction>, getState: () => AppState) => {
      const { booking: currentBooking } = getState()[moduleName];

      if (currentBooking && booking?.bookingId !== currentBooking.bookingId) {
        await dispatch(clearBookingRequest());
      }

      dispatch(
        setBookingState({
          booking,
          serviceId,
        })
      );
    };
