import React, { useContext } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmBadgeStatus, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import Details from './details';
import { getRequestStatusColor } from '../../../lib/utils/dictionary';
import Favorite from '../../../components/favorite';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import ChangeHistory from '../../../components/change-history';
import { RequestData } from '../../../types/requests';
import { routes } from '../../../config/constants';
import { formatDate } from '../../../lib/utils/date';
import { RequestContext } from '../../../lib/utils/requests';
import { generateLink, goBack } from '../../../lib/utils';
import { EducationTypeEnum } from '../../../types/education-type';
import { serviceTypeEnum } from '../../../mock-data/print';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
};

const DkgmRequest: React.FC<Props> = ({ requestData, updateRequest }) => {
  const { oiv, adminView, routeBack } = useContext(RequestContext);

  const handleCreate = () => {
    window.open(
      generateLink(routes.createRequestBasedOn, {
        id: requestData.id,
      }),
      '_blank'
    );
  };

  const handlePrint = () => {
    window.open(
      generateLink(routes.printRequest, {
        type:
          requestData.educationTypeId === EducationTypeEnum.ArtHouseEducation
            ? serviceTypeEnum.artHouseEducation
            : serviceTypeEnum.sportEducation,
        id: requestData.id,
      }),
      '_blank'
    );
  };

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (!oiv && !adminView) {
      buttons.push({
        dataTest: 'createFromCurrent',
        buttonText: 'Создать на основе текущего',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleCreate,
        icon: 'filled-edit-plus',
      });
    }
    buttons.push({
      dataTest: 'print',
      icon: 'filled-hardware-printer',
      buttonText: 'Печать',
      rounded: 'small',
      variant: 'secondary',
      onClick: handlePrint,
    });

    return buttons;
  };

  return (
    <LmSubHeader
      sticky
      description={
        <>
          <Push size={12} />
          <div className="flex items-center">
            Дата заявления: {formatDate(requestData.dateRequest)}
            <Push
              size={20}
              orientation="horizontal"
            />
            Источник: {requestData.requestSourceName}
          </div>
          <Push size={12} />
        </>
      }
      arrowOnClick={() => goBack(routeBack)}
      titleChildren={
        <div className="flex items-center">
          <Favorite
            entityId={requestData.id}
            typeEntity="заявление"
            currentEntityType={`№${requestData.requestNumber || ''}`}
            entityTypeId={EntityTypeEnum.Request}
          />
          <Push
            size={20}
            orientation="horizontal"
          />
          <LmBadgeStatus
            dataTest="requestStatus"
            {...getRequestStatusColor(requestData.requestStatusId)}
          >
            {requestData.requestStatusName}
          </LmBadgeStatus>
        </div>
      }
      title={`Заявление №${requestData.requestNumber}`}
      dataTest="requestDkgmSubHeader"
      routes={[
        {
          label: 'Главная',
          link: routes.main,
        },
        {
          label: 'Заявления',
          link: routes.requests,
        },
        {
          label: 'Сведения о заявлении',
        },
      ]}
      tabs={[
        {
          title: 'Основные сведения',
          value: 1,
          dataTest: 'basicInfo',
        },
        {
          title: 'История изменений',
          value: 2,
          dataTest: 'changeHistory',
        },
      ]}
      className="content-panel"
      panels={[
        {
          children: (
            <Details
              requestData={requestData}
              updateRequest={updateRequest}
            />
          ),
          value: 1,
        },
        {
          children: (
            <ChangeHistory
              name="сведения о заявлении"
              extendEntityGuid={requestData?.extendEntityGuid}
              entityTypes={entityTypesForHistory.requests}
            />
          ),
          value: 2,
          className: 'content-panel content-panel--auto',
          noPadding: true,
        },
      ]}
      buttonsOrAnyActions={getButtons()}
    />
  );
};

export default DkgmRequest;
