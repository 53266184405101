import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmSelectNew, LmToggle } from '@mes-ui/lemma';
import EditSchedule, { ScheduleType } from '../../../../../components/schedule';
import { getTimeTableFromString } from '../../../../../lib/utils/time-table';

import { ServiceData } from '../../../../../types/service';
import { useGetOptions } from './use-get-options';
import { UseGetSchedule } from './use-get-schedule';
import { AppState } from '../../../../../redux/types/state';
import { userProfileSelector } from '../../../../../redux/selectors';
import { showText } from './index';

type Props = {
  submit: (schedule: ScheduleType[], selectedSchedule: SelectOptionType, canChangeSchedule?: boolean) => void;
  setScheduleSubmit: (value: boolean) => void;
  scheduleSubmit: boolean;
  setScheduleError?: (value: boolean) => void;
  serviceData: ServiceData;
  isTemplate?: boolean;
  setCanChangeSchedule?: (value: boolean) => void; // сейчас используется только для создания шаблонов
  canChangeSchedule?: boolean;
  title?: string;
  setCurrentSelectOption?: (value: SelectOptionType) => void;
  isTemplateOrService?: boolean | undefined;
  isCreate?: boolean;
  setScheduleType?: (value: SelectOptionType) => void;
  editMode?: boolean;
  isService?: boolean;
};

const EditServiceSchedule = ({
  submit,
  scheduleSubmit,
  setScheduleSubmit,
  setScheduleError,
  serviceData,
  isTemplate,
  canChangeSchedule,
  setCanChangeSchedule,
  title,
  setCurrentSelectOption,
  isTemplateOrService,
  isCreate = false,
  isService,
  setScheduleType: propsSetScheduleType,
  editMode,
}: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { options, scheduleType, setScheduleType } = useGetOptions(isTemplate, serviceData, userProfile);

  const { scheduleData, setScheduleData } = UseGetSchedule(serviceData);

  const toggle = () => {
    if (!isTemplate) {
      return;
    }
    if (setCanChangeSchedule) {
      return (
        <>
          <Push
            orientation="horizontal"
            size={16}
          />
          <LmToggle
            size="small"
            checked={!!canChangeSchedule}
            onChange={setCanChangeSchedule}
          />
        </>
      );
    }
  };

  const disabled = useMemo(() => {
    if (isService) {
      return !(isService && canChangeSchedule);
    }
    if (isCreate) {
      return !isCreate;
    }
    if (editMode) {
      return !editMode;
    } else {
      return !canChangeSchedule;
    }
  }, [canChangeSchedule, editMode, isCreate, isService]);

  return (
    <EditSchedule
      timeRoundingUp
      disabled={disabled}
      title={() => (
        <div className="flex items-center">
          {title}

          <Push
            orientation="horizontal"
            size={16}
          />
          {!!scheduleData.length && (
            <div
              className="font-weight-base"
              style={{
                width: 220,
              }}
            >
              <LmSelectNew
                dataTest="selectScheduleType"
                name="scheduleType"
                onChange={(selectedOption: SelectOptionType | null) => {
                  selectedOption && setScheduleType(selectedOption as SelectOptionType);
                  propsSetScheduleType && propsSetScheduleType(selectedOption as SelectOptionType);

                  if (setCurrentSelectOption) {
                    setCurrentSelectOption(selectedOption as SelectOptionType);
                  }

                  if ((selectedOption as SelectOptionType).value === 2) {
                    setScheduleData(getTimeTableFromString('') as ScheduleType[]);
                  }
                }}
                options={options}
                value={scheduleType || null}
                disabled={disabled}
                size="small"
                grouped={false}
                multiple={false}
                settingDropdownAsPopover={{
                  disablePortal: true,
                }}
                settingTooltip={{
                  showTooltip: true,
                }}
              />
            </div>
          )}
        </div>
      )}
      headingControl={() => toggle()}
      submit={scheduleSubmit}
      submitError={() => {
        console.error('submitError');
      }}
      submitSuccess={(s) => {
        if (scheduleSubmit && scheduleType) {
          submit(s, scheduleType, canChangeSchedule);
          setScheduleSubmit(false);
        }
      }}
      scheduleData={scheduleData}
      showCustomPeriod
      textInfoBox={showText(isTemplateOrService)}
      setCurrentErrorSchedule={setScheduleError}
      isHiding={scheduleType?.value === 2}
    />
  );
};

export default EditServiceSchedule;
