import React, { useState, Fragment } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon, LmSubHeader, LmLoader } from '@mes-ui/lemma';
import { useGetFaqQuery } from '../../store/static-content';
import { routes } from '../../config/constants';

const Faq = () => {
  const { data = [], isFetching } = useGetFaqQuery();

  return (
    <>
      <LmSubHeader
        sticky
        title="Ответы на вопросы"
        description=""
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Ответы на вопросы',
          },
        ]}
        hideReturnButton
        dataTest="faqSubHeader"
      />
      {isFetching ? (
        <div className="loader-auto">
          <LmLoader view="page" />
        </div>
      ) : (
        data.map((item, i) => (
          <Fragment key={`faq_${item.id}`}>
            <Push size={i === 0 ? 12 : 16} />
            <div className="faq-panel">
              <div className="faq-panel__heading">
                <div className="container">{item.name}</div>
              </div>
              {item.faqList.map((faqItem) => (
                <div
                  key={`faqItem_${faqItem.id}`}
                  className="faq-panel__item"
                >
                  <Accordion
                    title={faqItem.question}
                    description={faqItem.answer}
                  />
                </div>
              ))}
            </div>
          </Fragment>
        ))
      )}
    </>
  );
};

const Accordion = ({ title, description }: { title: string; description: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="faq-accordion">
      <div className="container">
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className="faq-accordion__btn"
        >
          <span className="faq-accordion__title">{title}</span>
          {open ? (
            <LmIcon
              icon="filled-chevrons-large-up"
              size={24}
              color="var(--LM-neutrals-day-700)"
            />
          ) : (
            <LmIcon
              icon="filled-chevrons-large-down"
              size={24}
              color="var(--LM-neutrals-day-700)"
            />
          )}
        </button>
        {open && (
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            className="faq-accordion__description"
          />
        )}
      </div>
    </div>
  );
};

export default Faq;
