import React, { useCallback, useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import {
  array as arrayYup,
  date as dateYup,
  object as objectYup,
  setLocale,
  string as stringYup,
  number as numberYup,
} from 'yup';
import moment from 'moment';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { snilsMask } from '../../../../lib/utils/mask';
import SavePanel from '../../../../components/save-panel';
import { initialPupilDocument } from '../../../../mock-data/learners';
import FormikInput from '../../../../components/formik/formik-input';
import { checkActiveElement, checkEmptyData, notificationMessages } from '../../../../lib/utils/learners';
import learnerApi from '../../../../lib/api/learner';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { notify } from '../../../../redux/ducks/notifications';
import { DuplicateData, Learner } from '../../../../types/learners';
import { addressApi } from '../../../../lib/api/address';
import {
  foreignDocument,
  rusRegexWithSpace,
  rusRegexWithSpaceAndNumber,
  spaceRegexp,
  validateDocumentBirthRecord,
} from '../../../../lib/utils/validation';
import Document from './components/fields/document';
import { Status } from './components/status';
import { FIO } from './components/fields/fio';
import { Gender } from './components/fields/gender';
import { Birthday } from './components/fields/birthday';
import { AddressRegistration } from './components/fields/address-registration';
import { FactAddress } from './components/fields/fact-address';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import DuplicateModal from '../modal/duplicate-modal';
import { SelectOptionType } from '../../../../types/entities';
import { LoaderCustom } from '../../../../components/loader-custom';

type Props = {
  loading: boolean;
  userData: Learner.Data;
  isAdminEdit: boolean;
  updateLearn: (val: boolean) => void;
  setLearnerName: (value: string) => void;
  setEditModeParent: (edit: boolean) => void;
};

const initialSelect = {
  label: '',
  value: 0,
};

const LearnerFormInfo = ({ isAdminEdit, updateLearn, setEditModeParent, userData, setLearnerName, loading }: Props) => {
  const [editMode, setEditMode] = useState(false);

  const {
    id,
    firstName,
    lastName,
    middleName,
    sexId,
    birthDate,
    snils,
    regAddressId,
    factAddressId,
    regAddress,
    factAddress,
    pupilDocument,
  } = userData;

  const currentTypeAddress = regAddress ? 'other' : 'moscow';

  const isEmptyAllAddress = !regAddress && !factAddress && !factAddressId && !regAddressId;

  const initialData: Learner.Info = useMemo(() => {
    return {
      id, // ID личного дела
      firstName, // имя
      lastName, // отчество
      middleName, // фамилия
      sexId, // пол
      snils, // снилс
      regAddressId,
      factAddressId,
      regAddress,
      factAddress,
      birthDate: birthDate ? new Date(birthDate) : undefined, // дата рождения
      regCity: isEmptyAllAddress ? 'other' : currentTypeAddress,
      activeDocument: checkActiveElement(pupilDocument[0]?.documentTypeId), // текущий док удост.лич
      isMiddleName: !middleName,
      pupilDocument: pupilDocument.length
        ? pupilDocument.map((doc) => ({
            ...doc,
            documentTypeId: doc.documentTypeId || checkActiveElement(doc.documentTypeId)?.value, // тип документа
            docDate: doc.docDate ? new Date(doc.docDate) : undefined, // дата выдачи паспорта
          }))
        : [
            {
              ...initialPupilDocument,
              pupilId: id,
            },
          ],
    };
  }, [
    birthDate,
    currentTypeAddress,
    factAddress,
    factAddressId,
    firstName,
    id,
    isEmptyAllAddress,
    lastName,
    middleName,
    pupilDocument,
    regAddress,
    regAddressId,
    sexId,
    snils,
  ]);

  const [addressMatches, setAddressMatches] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<DuplicateData[]>();
  const [randomFormikKey, setRandomFormikKey] = useState(0);

  const [currentAddressRegistration, setCurrentAddressRegistration] = useState<SelectOptionType | undefined>(
    initialSelect
  );

  const [currentAddressResidence, setCurrentAddressResidence] = useState<SelectOptionType | undefined>(initialSelect);

  const formRef = useRef<FormikProps<Learner.Info> | null>(null);

  const dispatch = useDispatch();

  const checkAddresMatches = useCallback(() => {
    if (regAddress && factAddress && regAddress === factAddress) {
      setAddressMatches(true);
    } else if (factAddressId && regAddressId && factAddressId === regAddressId) {
      setAddressMatches(true);
    } else {
      setAddressMatches(false);
    }
  }, [regAddress, factAddress, factAddressId, regAddressId]);

  useEffect(() => {
    const fetch = async () => {
      if (regAddressId) {
        const result = await addressApi.getAddressId(regAddressId);

        setCurrentAddressRegistration({
          label: result.data.fullAddressName,
          value: result.data.id,
        });
      }
    };

    fetch();
  }, [regAddressId]);

  useEffect(() => {
    const fetch = async () => {
      if (factAddressId) {
        const result = await addressApi.getAddressId(factAddressId);

        setCurrentAddressResidence({
          label: result.data.fullAddressName,
          value: result.data.id,
        });
      }
    };

    fetch();
  }, [factAddressId]);

  // Если совпадает название адреса или id, то устанавливаем тогл "Совпадает с адресом регистрации" в true
  useEffect(() => {
    checkAddresMatches();
  }, [regAddress, factAddress, factAddressId, regAddressId, checkAddresMatches]);

  const submitForm = useCallback(
    async (values: Learner.Info) => {
      let response;
      const otherCity: boolean = values.regCity === 'other';

      const currentRegAddress = otherCity ? values.regAddress?.trim() : currentAddressRegistration?.label;

      const getFactAddress = addressMatches
        ? currentRegAddress
        : currentAddressResidence?.value
          ? null
          : currentAddressResidence?.label;

      const getFactAddressId = addressMatches ? null : currentAddressResidence?.value || null;

      const dataRequest = {
        ...userData,
        id: initialData.id,
        sexId: values.sexId,
        birthDate: values.birthDate,
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        middleName: values.middleName?.trim(),
        snils: values.snils,
        regAddress: otherCity ? values.regAddress?.trim() : null,
        factAddress: getFactAddress,
        factAddressId: getFactAddressId,
        regAddressId: otherCity ? null : currentAddressRegistration?.value,
        pupilDocument: values.pupilDocument.map((doc) => ({
          ...doc,
          docNumber: doc.docNumber?.trim(),
          docIssuer: doc.docIssuer?.trim(),
          id: initialData.pupilDocument.some(({ id }) => id === doc.id) ? doc.id : 0,
        })),
      };

      delete dataRequest.access;
      const dataFilter = checkEmptyData(dataRequest);

      try {
        response = await learnerApi.putLearner(dataFilter);

        if (response.message) {
          const duplicates = JSON.parse(response.message);

          setDuplicates(duplicates);

          return;
        }

        setLearnerName(`${values.lastName} ${values.firstName} ${values.middleName || ''}`);

        dispatch(
          notify.success({
            dataTest: 'learnerInfo',
            title: notificationMessages.saveSuccess,
          })
        );

        setTimeout(() => {
          updateLearn(true);
          setEditMode(false);
          setEditModeParent(false);
        }, 1500);
      } catch (e) {
        console.error(e);
      }
    },
    [
      currentAddressRegistration?.label,
      currentAddressRegistration?.value,
      addressMatches,
      currentAddressResidence?.value,
      currentAddressResidence?.label,
      userData,
      initialData.id,
      initialData.pupilDocument,
      setLearnerName,
      updateLearn,
      setEditModeParent,
      dispatch,
    ]
  );

  const initialErrors = useInitialErrors(initialData, getValidationSchema());

  const resetForm = async () => {
    setEditMode(false);
    setEditModeParent(false);
    setRandomFormikKey(Math.random());
    setCurrentAddressRegistration(initialSelect);
    setCurrentAddressResidence(initialSelect);

    if (regAddressId) {
      const result = await addressApi.getAddressId(regAddressId);

      setCurrentAddressRegistration({
        label: result.data.fullAddressName,
        value: result.data.id,
      });
    }
    if (factAddressId) {
      const result = await addressApi.getAddressId(factAddressId);

      setCurrentAddressResidence({
        label: result.data.fullAddressName,
        value: result.data.id,
      });
    }

    checkAddresMatches();
  };

  return loading ? (
    <LoaderCustom
      size={180}
      hasPanel
    />
  ) : (
    <>
      <Formik
        initialErrors={initialErrors}
        validationSchema={getValidationSchema()}
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialData}
        validateOnMount
        key={randomFormikKey}
        innerRef={(ref) => {
          formRef.current = ref;
        }}
      >
        {(formikProps: FormikProps<Learner.Info>) => {
          const { handleSubmit, submitForm, values, isSubmitting, isValid } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />
              <Panel
                title={() => 'Персональные данные'}
                headingControl={() =>
                  !editMode &&
                  isAdminEdit &&
                  !userData.isArchive && (
                    <button
                      type="button"
                      onClick={() => {
                        setEditMode(true);
                        setEditModeParent(true);
                      }}
                      className="icon-group"
                    >
                      <span className="icon-group__icon">
                        <LmIcon
                          icon="filled-edit-edit"
                          size={20}
                          color="var(--LM-blue-200)"
                        />
                      </span>
                      <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                    </button>
                  )
                }
              >
                <div className="container">
                  <div className="table-data">
                    <Status isAdminEdit={isAdminEdit} />

                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">ID личного дела</div>
                      <div className="table-data__body">{userData.id}</div>
                    </div>

                    <FIO
                      editMode={editMode}
                      hasInContingent={!!userData.hasInContingent}
                    />

                    <Gender editMode={editMode} />

                    <Birthday editMode={editMode} />

                    {values.pupilDocument.map((doc, index) =>
                      (editMode && values.pupilDocument[0].documentTypeId !== DocumentTypeEnum.BirthCertificate) ||
                      (!editMode && values.pupilDocument.length < 2) ? (
                            <Fragment key={`doc_${doc.id}`}>
                              <Document
                                index={index}
                                editMode={editMode}
                              />
                            </Fragment>
                          ) : (
                            <div
                              key={`doc_${doc.id}`}
                              className="table-data__item table-data__item--not-border"
                            >
                              <Document
                                index={index}
                                editMode={editMode}
                              />
                            </div>
                          )
                    )}

                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">СНИЛС</div>
                      <div className="table-data__body">
                        <div className="table-data-grid-3">
                          {editMode ? (
                            <FormikInput
                              size="small"
                              maskRegex={snilsMask}
                              placeholder="XXX-XXX-XXX XX"
                              name="snils"
                              showErrorImmediately
                            />
                          ) : (
                            values.snils ?? <span className="color-gray">Не указано</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <AddressRegistration
                      editMode={editMode}
                      currentAddressRegistration={currentAddressRegistration}
                      setCurrentAddressRegistration={setCurrentAddressRegistration}
                    />

                    <FactAddress
                      editMode={editMode}
                      addressMatches={addressMatches}
                      setAddressMatches={setAddressMatches}
                      currentAddressResidence={currentAddressResidence}
                      setCurrentAddressResidence={setCurrentAddressResidence}
                      currentAddressRegistration={currentAddressRegistration}
                    />
                  </div>
                </div>
              </Panel>

              {editMode && (
                <SavePanel
                  primaryButtonModifiers={{
                    loading: isSubmitting,
                    disabled: !isValid,
                  }}
                  onClickSeconadaryButton={resetForm}
                  onClickPrimaryButton={submitForm}
                />
              )}
            </form>
          );
        }}
      </Formik>
      <DuplicateModal
        show={!!duplicates}
        duplicates={duplicates ?? []}
        onCloseHandler={() => {
          setDuplicates(undefined);
        }}
      />
    </>
  );
};

export default LearnerFormInfo;

setLocale({
  date: {
    max: 'Введённая дата не может быть позднее текущей даты',
    min: 'Введённая дата является слишком маленькой',
  },
});

const getValidationSchema = () =>
  objectYup().shape({
    firstName: stringYup()
      .required('Введите имя')
      .typeError('Введите имя')
      .matches(spaceRegexp, {
        message: 'Пробел в начале или конце недопустим',
      })
      .matches(rusRegexWithSpace, {
        message: 'Только русские буквы',
      }),

    lastName: stringYup()
      .required('Введите фамилию')
      .typeError('Введите фамилию')
      .matches(spaceRegexp, {
        message: 'Пробел в начале или конце недопустим',
      })
      .matches(rusRegexWithSpace, {
        message: 'Только русские буквы',
      }),
    middleName: stringYup()
      .nullable()
      .when('isMiddleName', {
        is: false,
        then: (s) =>
          s
            .required('Введите отчество')
            .typeError('Введите отчество')
            .matches(spaceRegexp, {
              message: 'Пробел в начале или конце недопустим',
            })
            .matches(rusRegexWithSpace, {
              message: 'Только русские буквы',
            }),
      }),
    pupilDocument: arrayYup().of(
      objectYup().shape({
        documentTypeId: numberYup().nullable().required('Выберите документ'),
        docSeries: stringYup()
          .nullable()
          .typeError('Введите серию документа')
          .when('documentTypeId', {
            is: DocumentTypeEnum.BirthCertificate,
            then: (s) =>
              s
                .max(7, 'Серия должна содержать не более 7 символов')
                .matches(
                  /^[A-Za-z]{1,4}-[А-Яа-я]{2,2}$/,
                  'Серия свидетельства должна быть вида XXXX-XX, английские и русские буквы'
                )
                .required('Введите серию документа'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.Passport,
            then: (s) =>
              s
                .length(4, 'Серия должна содержать 4 цифры')
                .matches(/^[0-9]{4,4}$/, 'Серия паспорта должна содержать 4 цифры')
                .required('Введите серию документа'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.BirthCertificateForeign,
            then: foreignDocument.series,
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.ForeignPassport,
            then: foreignDocument.series,
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.OldPassport,
            then: (s) =>
              s
                .max(7, 'Серия должна содержать не более 7 символов')
                .matches(
                  /^[A-Za-z]{1,4}-[А-Яа-я]{2,2}$/,
                  'Серия паспорта должна быть вида XXXX-XX, английские и русские буквы'
                )
                .required('Введите серию документа'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.ResidencePermit,
            then: (s) =>
              s
                .length(2, 'Серия документа должна содержать 2 цифры')
                .matches(/^[0-9]{2,2}$/, 'Серия документа должна содержать 2 цифры')
                .required('Введите серию документа'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.BirthRecord,
            then: (s) => s.optional(),
          }),
        docNumber: stringYup()
          .nullable()
          .required('Введите номер документа')
          .typeError('Введите номер документа')
          .when('documentTypeId', {
            is: DocumentTypeEnum.BirthCertificate,
            then: (s) => s.matches(/^[0-9]{6}$/, 'Номер должен содержать 6 цифр'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.Passport,
            then: (s) =>
              s.length(6, 'Номер должен содержать 6 цифр').matches(/^[0-9]{6,6}$/, 'Номер должен содержать 6 цифр'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.BirthCertificateForeign,
            then: foreignDocument.number,
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.ForeignPassport,
            then: foreignDocument.number,
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.OldPassport,
            then: (s) =>
              s
                .max(6, 'Номер должен содержать не более 6 цифр')
                .matches(/^[0-9]{6,6}$/, 'Номер паспорта должен содержать 6 цифр'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.ResidencePermit,
            then: (s) =>
              s
                .length(7, 'Номер документа должен содержать 7 цифр')
                .matches(/^[0-9]{7,7}$/, 'Номер документа должен содержать 7 цифр'),
          })
          .when('documentTypeId', {
            is: DocumentTypeEnum.BirthRecord,
            then: (s) => s.test('documentTypeId', '', validateDocumentBirthRecord),
          }),
        docDate: dateYup()
          .nullable()
          .required('Введите дату выдачи')
          .max(moment().format('L'))
          .min(moment('01.01.1910', 'DD/MM/YYYY').format('L')),
        docIssuer: stringYup()
          .nullable()
          .test((value: string | null | undefined, ctx: any) => {
            const parent = ctx.from[0];

            if (parent?.value?.documentTypeId === DocumentTypeEnum.BirthRecord) {
              if (!value) {
                return ctx.createError({
                  message: 'Введите кем внесена запись',
                });
              }
            } else if (value && value.length !== value.trim().length) {
              return ctx.createError({
                message: 'Пробел в начале или конце недопустим',
              });
            }

            return true;
          }),
      })
    ),
    birthDate: dateYup()
      .nullable()
      .required('Введите дату рождения')
      .max(moment().format('L'))
      .min(moment('01.01.1910', 'DD/MM/YYYY').format('L')),
    snils: stringYup()
      .matches(/^[0-9]{3,3}-[0-9]{3,3}-[0-9]{3,3} [0-9]{2,2}$/, 'СНИЛС должен содержать 11 цифр')
      .nullable()
      .test('test_snils', 'СНИЛС указан некорректно', (snils: string | null | undefined) => {
        return validateSnils(snils);
      }),
    factAddressId: numberYup()
      .nullable()
      .test('fact-address-name', 'Используйте только русские буквы', (value, ctx) => {
        return !ctx.parent.factAddress || rusRegexWithSpaceAndNumber.test(ctx.parent.factAddress);
      }),
  });

function validateSnils(snilsVal: string | null | undefined): boolean {
  if (!snilsVal) {
    return true;
  } // Можно оставлять пустым

  snilsVal = snilsVal.replace(/[^\d]/g, '');

  if (snilsVal.length !== 11) {
    return false;
  }

  const crc = parseInt(snilsVal.slice(9), 10);

  snilsVal = `${snilsVal}`;

  let sum = 0;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(snilsVal[i]) * (9 - i);
  }

  if (sum > 101) {
    sum %= 101;
  }

  if (sum === 100 || sum === 101) {
    sum = 0;
  }

  return sum === crc;
}
