import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { apiService } from '../../config/constants';
import { NotifyType } from '../../containers/password-rules/types/room';
import api from '../../lib/api/api';
import axiosBaseQuery from '../../lib/api/axios-base-query';
import { EventData, EventParamsRequestData } from '../../types/events';

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: axiosBaseQuery({
    baseURL: `${apiService.event}/Event/`,
  }),
  tagTypes: ['Events', 'EventTypes'],
  endpoints: (builder) => ({
    getUserEventTypes: builder.query<NotifyType[], void>({
      query: () => {
        return api.POST({
          url: 'UserEventTypes',
        });
      },
      providesTags: ['EventTypes'],
    }),
    getEvents: builder.query<EventData[], EventParamsRequestData>({
      query: (data) => {
        return api.POST({
          url: 'Event',
          data,
        });
      },
      keepUnusedDataFor: 0,
      providesTags: ['Events'],
    }),
    changeSubscribe: builder.mutation<void, number[]>({
      query(data) {
        return api.POST({
          url: 'UnSubscribe',
          data,
        });
      },
      invalidatesTags: ['EventTypes'],
    }),
    readEvent: builder.mutation<void, number>({
      query(id) {
        return api.POST({
          url: `MarkEvent/${id}`,
        });
      },
      invalidatesTags: ['Events'],
    }),
    readAllEvent: builder.mutation<void, void>({
      query() {
        return api.POST({
          url: 'MarkEvent',
        });
      },
      invalidatesTags: ['Events'],
    }),
  }),
});

export const { useGetUserEventTypesQuery, useChangeSubscribeMutation, useGetEventsQuery, useReadAllEventMutation } =
  eventApi;
