import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { SelectOptionType } from '@mosru/esz_uikit';
import { LmFilterVertical } from '@mes-ui/lemma';
import FilterLoader from '../../../components/loader-custom/filter-loader';
import { EventUrl } from '../../../mock-data/event';
import { SearchRequestsInitialFormData, SearchRequestsTableOptions } from '../../../types/requests';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { getEducationTypes, hasGeneralAccess, parseUrlSearchString, replaceHistoryState } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import {
  filterEducations,
  getEducationTypeForCreateLinks,
  getRequestEducationLinks,
} from '../../../lib/utils/education';
import { transformPrivelegiesData } from '../../../lib/utils/learners';
import { EducationTypeEnum, EducationTypeToCamelCaseEnum } from '../../../types/education-type';
import requestsApi from '../../../lib/api/requests';
import { requestHistoryState } from '../utils';
import StatementChild from './search-tabs/statement-child';
import ChildServiceView from './search-tabs/child-service/index';
import { getCountDiff, infoChild, period, privilegeStatusList, setAsyncValues } from '../../../lib/utils/requests';
import { FieldsModelItem } from '../../../types/entities';
import { fieldsModel } from './fields-model';
import { searchRequestsInitialFormData } from './index';
import InitFilters from './fields/init-filters';
import useQuery from '../../../hooks/use-query';
import { sendReachGoal } from '../../../lib/metrica';

type Props = {
  submitForm: (values: SearchRequestsInitialFormData) => void;
  setTableOptions: React.Dispatch<SetStateAction<SearchRequestsTableOptions>>; // ((prevState:SearchRequestsTableData) => SearchRequestsTableData) => void;
  setRequestOptions: (val: { value: number; label: string; link: string }[]) => void;
  initialSearchFilters?: SearchRequestsInitialFormData;
};

const RequestsSearch: React.FC<Props> = ({ submitForm, setTableOptions, setRequestOptions, initialSearchFilters }) => {
  const [open, setOpen] = useState(!!window.history.state[requestHistoryState.openAdvanced]);
  const [contractStatusData, setContractStatusData] = useState<SelectOptionType[]>([]);
  const [contractDeclineReasonOptions, setContractDeclineReasonOptions] = useState<SelectOptionType[]>([]);
  const [requestStatusData, setRequestStatusData] = useState<SelectOptionType[]>([]);
  const [requestSourceData, setRequestSourceData] = useState<SelectOptionType[]>([]);
  const [privilegiesData, setPrivilegiesData] = useState<SelectOptionType[]>([]);

  const [isLoadingStatementChild, setIsLoadingStatementChild] = useState(true);

  const location = useLocation();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState<SearchRequestsInitialFormData>(
    initialSearchFilters || window.history.state[requestHistoryState.search] || searchRequestsInitialFormData
  );

  const [fieldshModelItem, setFieldsModelItem] = useState<FieldsModelItem | null>(null);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  // может ли пользователь менять организацию
  const canChangeOrganization: boolean = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const educationTypeData = useMemo(
    () =>
      filterEducations(
        userProfile.objectAccessActionList,
        [],
        undefined,
        accessObject.Requests,
        accessAction.ViewRegistry
      ),
    [userProfile.objectAccessActionList]
  );

  const eventId = Number(useQuery().get(EventUrl.EventId));

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  const changeFieldsModel = useCallback(
    (educationTypeId?: number) => {
      if (fieldsModel) {
        const type = EducationTypeToCamelCaseEnum[educationTypeId || 0];
        const fieldsModelItem = fieldsModel[!type && educationTypeData.length > 1 ? 'allEducations' : type];

        setFieldsModelItem(fieldsModelItem);

        return fieldsModelItem;
      }
    },
    [educationTypeData.length]
  );

  // установка начальных значений в зависимости от прав пользователя
  const initialFilters = useMemo(() => {
    let submitData = {
      ...searchRequestsInitialFormData,
      organizationId: canChangeOrganization ? searchRequestsInitialFormData.organizationId : userProfile.organizationId,
      organizationName: canChangeOrganization ? undefined : userProfile.organizationName,
      educationTypeId: educationTypeData.length > 0 ? educationTypeData[0].value : undefined,
      educationTypeName: educationTypeData.length > 0 ? educationTypeData[0].label : undefined,
      eventId: eventId || null,
    };

    const fieldsModelLocaleItem = changeFieldsModel(submitData.educationTypeId);

    submitData.vedomstvoId =
      fieldsModelLocaleItem?.vedomstvoId?.defaultValue.id || (isAdmin ? null : userProfile.vedomstvoId);
    submitData.vedomstvoName =
      fieldsModelLocaleItem?.vedomstvoName?.defaultValue.name || (isAdmin ? 'Все' : userProfile.vedomstvoName) || '';

    let doNotSearch = false;

    if (location.state) {
      submitData = {
        ...submitData,
        ...(location.state as SearchRequestsInitialFormData),
      };
    } else if (location.search) {
      const urlParams = parseUrlSearchString(location.search);

      if (urlParams.serviceId) {
        doNotSearch = true;
      }
    }

    if (!doNotSearch) {
      const currentEducationTypeId = window.history.state[requestHistoryState.search]?.educationTypeId;

      currentEducationTypeId && changeFieldsModel(currentEducationTypeId);
      submitForm(window.history.state[requestHistoryState.search] || submitData);
    }

    setTableOptions((prev) => {
      return {
        ...prev,
        loadEnable: true,
      };
    });

    return submitData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, educationTypeData, userProfile, canChangeOrganization, setTableOptions, isAdmin, location.state]);

  // виды образования, на которые у пользователя есть доступ
  const allowedEducationTypeId: number = useMemo(() => {
    const allowedEdTypes: EducationTypeEnum[] = getEducationTypes(
      userProfile,
      [accessObject.Requests],
      accessAction.ViewRegistry
    );
    const allowedEdTypeId: number = allowedEdTypes.reduce(
      (previousValue, currentValue) => previousValue | currentValue,
      0
    );

    return allowedEdTypeId === 0 ? EducationTypeEnum.ChildrenEducation : allowedEdTypeId;
  }, [userProfile]);

  // пользователь ДСИТ/ДКГМ/КДЦ
  const isArtHouseSportEducation: boolean = useMemo(() => {
    const otherEducations: number =
      allowedEducationTypeId &
      (EducationTypeEnum.All ^ (EducationTypeEnum.SportEducation | EducationTypeEnum.ArtHouseEducation));
    const isArtHouseSportEd: boolean =
      ((allowedEducationTypeId & EducationTypeEnum.SportEducation) !== 0 ||
        (allowedEducationTypeId & EducationTypeEnum.ArtHouseEducation) !== 0) &&
      otherEducations === 0;

    setTableOptions((prev) => {
      return {
        ...prev,
        isArtHouseSportEducation: isArtHouseSportEd,
        allowedEducationTypeId,
      };
    });

    return isArtHouseSportEd;
  }, [allowedEducationTypeId, setTableOptions]);

  // загрузка справочников департаментов и видов образования
  useEffect(() => {
    setRequestOptions(getRequestEducationLinks(userProfile.objectAccessActionList, []));

    const fetchData = async () => {
      // Данные для кнопки "Новое заявление"
      const educationTypeForCreate = await requestsApi.getEducationTypeForCreate();

      setRequestOptions(getEducationTypeForCreateLinks(educationTypeForCreate));
      setRequestStatusData([
        {
          value: '',
          label: 'Все',
        },
        ...(await requestsApi.getRequestStatuses()),
      ]);
      setRequestSourceData([
        {
          value: '',
          label: 'Все',
        },
        ...(await dictionariesApi.getRequestSources()),
      ]);
      setContractStatusData([
        {
          value: '',
          label: 'Все',
        },
        ...(await dictionariesApi.getContractStatuses()),
      ]);
      setContractDeclineReasonOptions([
        {
          value: '',
          label: 'Все',
        },
        ...(await dictionariesApi.getContractDeclineReasonOptions(allowedEducationTypeId)),
      ]);
      if (hasGeneralAccess(userProfile, generalAccess.CheckPrivileges) || isAdmin) {
        const privelegiesAll = await dictionariesApi.getPrivilegeCategories();
        const transformPrivelegies = transformPrivelegiesData(privelegiesAll);

        setPrivilegiesData(transformPrivelegies);
      }
    };

    fetchData().finally(() => {
      setIsLoadingStatementChild(false);
    });
  }, [userProfile, setRequestOptions, isArtHouseSportEducation, isAdmin, allowedEducationTypeId]);

  useEffect(() => {
    initialSearchFilters && setInitialValues(initialSearchFilters);
  }, [initialSearchFilters]);

  const handleClickAdvancedSearch = (isOpen: boolean) => {
    replaceHistoryState({
      [requestHistoryState.openAdvanced]: isOpen,
    });
    setOpen(isOpen);
  };

  const handleSearch = (
    values: SearchRequestsInitialFormData,
    formikHelpers: FormikHelpers<SearchRequestsInitialFormData>
  ) => {
    submitForm(values);
    formikHelpers.setSubmitting(false);
    sendReachGoal('onclick-request-search');
  };

  return (
    <Formik
      onSubmit={handleSearch}
      enableReinitialize
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        values,
        isSubmitting,
        resetForm,
        setFieldValue,
      }: FormikProps<SearchRequestsInitialFormData>) => {
        const countDiff = getCountDiff(values, initialFilters) + (eventId ? 1 : 0);

        const StatementChildProps = {
          setTableOptions,
          infoChild,
          privilegeStatusList,
          values,
          period,
          isArtHouseSportEducation,
          userProfile,
          contractStatusData,
          contractDeclineReasonOptions,
          requestStatusData,
          requestSourceData,
          privilegiesData,
          allowedEducationTypeId,
        };

        // сброс фильтров и возвращение к начальным фильтрам в поиске
        const onResetForm = () => {
          resetForm({
            values: initialFilters,
          });

          const dataToClearValues = [
            {
              key: 'serviceId',
            },
            {
              key: 'placeServiceId',
            },
            {
              key: 'trainingGroupId',
            },
            {
              key: 'programmLevelId',
            },
            {
              key: 'organizationId',
            },
            {
              key: 'classificatorEKUId',
            },
            {
              key: 'serviceClassId',
            },
            {
              key: 'privilegeCategoryId',
            },
            {
              key: 'privilegeStatusId',
            },
            {
              key: 'testDate',
            },
            {
              key: 'yearOfTrainingId',
            },
          ];

          let newObj: SearchRequestsInitialFormData = initialFilters;

          if (eventId) {
            history.replace(location.pathname);
            replaceHistoryState({
              [requestHistoryState.openAdvanced]: open,
            });
            const { eventId, ...obj } = initialFilters;

            newObj = obj;
          }

          changeFieldsModel(newObj.educationTypeId);

          setAsyncValues(dataToClearValues);

          submitForm(newObj);
        };

        const onChangeEducationTypeHandler = (educationTypeId: number) => {
          if (fieldsModel) {
            const fieldsModelItem = changeFieldsModel(educationTypeId);

            // Нужно для правильной работы счетчика countDiff
            for (const item in fieldsModelItem) {
              if (!fieldsModelItem[item]?.visible) {
                setFieldValue(item, null);
              }
            }
          }
        };

        return (
          <form onSubmit={handleSubmit}>
            <InitFilters submitForm={submitForm} />
            <LmFilterVertical
              dataTest="requestFilter"
              classNameContent="filter-content filter-content--wrap"
              open={open}
              searchValue={values.query}
              searchPlaceholder="Поиск по заявлениям..."
              onChange={(value) => setFieldValue('query', value)}
              toggleOpen={handleClickAdvancedSearch}
              onClear={onResetForm}
              primaryButtonModifiers={{
                loading: isSubmitting,
                type: 'submit',
              }}
              buttonSecondaryText={countDiff ? `Сбросить фильтры (${countDiff})` : 'Сбросить фильтры'}
            >
              {isLoadingStatementChild ? (
                <FilterLoader />
              ) : (
                <>
                  <StatementChild {...StatementChildProps} />
                  <ChildServiceView
                    educationTypeData={educationTypeData}
                    onChangeEducationType={onChangeEducationTypeHandler}
                    allowedEducationTypeId={allowedEducationTypeId}
                    isArtHouseSportEducation={isArtHouseSportEducation}
                    fieldshModelItem={fieldshModelItem}
                    canChangeOrganization={canChangeOrganization}
                  />
                </>
              )}
            </LmFilterVertical>
          </form>
        );
      }}
    </Formik>
  );
};

export default RequestsSearch;
