import tokenManager from '../lib/token-manager';
import { redirectToLogin } from '../lib/utils';

export const signOut = async (callback?: () => void) => {
  // eslint-disable-next-line no-console
  process.env.NODE_ENV === 'development' && console.warn('last access token', tokenManager.getToken());
  tokenManager.clearToken();
  typeof callback === 'function' && callback();
  await redirectToLogin();
};
