import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
// @ts-ignore
import Files from 'react-files';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmButton, LmIcon, LmInfoBox } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import SavePanel from '../../../../../components/save-panel';
import photoApi from '../../../../../lib/api/photo';
import { deletePhoto, getPhotoName, postPhoto } from '../../../../../lib/utils/photo';
import { SystemPropertyEnum } from '../../../../../mock-data/system-property-enum';
import { notify } from '../../../../../redux/ducks/notifications';
import { ErrorCodeEnum, ReactFilesError, ReactFilesFile } from '../../../../../types/files';
import { ServiceContext } from '../../../index';
import { PhotoData, ServicePhotoItem } from '../../../../../types/service';
import PhotoCard from './photo-card';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { GalleryModal } from '../../../../../components/modal-gallery';
import systemPropertyApi from '../../../../../lib/api/system-propetry';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
};

export type ServiceImageData = ReactFilesFile & {
  removed?: boolean;
  sourceFileName?: string;
};

const photoCount = 4;
const ACCEPTS = ['.jpg', '.jpeg'];

const Photo: React.FC<Props> = ({ setEditModeParent, isTemplate = false }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [files, setFiles] = useState<ServiceImageData[]>([]);
  const [s3StorageAPIUrl, setS3StorageAPIUrl] = useState<string>();
  const [loader, setLoader] = useState(false);

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<ServiceImageData>();

  const dispatch = useDispatch();

  const openFullScreen = (currentImage: ServiceImageData, open: boolean) => {
    setFullScreen(open);
    setCurrentImage(currentImage);
  };

  const compareOrderPhoto = (a: PhotoData, b: PhotoData) => {
    const first = a.sortOrder ?? a.id;
    const second = b.sortOrder ?? b.id;

    if (first < second) {
      return -1;
    } else {
      return 1;
    }
  };

  const servicePhotos = useMemo(
    () =>
      serviceData?.photos?.length
        ? serviceData.photos.sort(compareOrderPhoto).map((photo): ServiceImageData => {
          return {
            id: photo.id.toString(),
            extension: 'jpg',
            sizeReadable: '',
            name: photo.name,
            sourceFileName: photo.sourceFileName,
            preview: {
              type: 'image',
              fullSize: `${s3StorageAPIUrl}/${photo.sourceFileName}`,
              url: `${window.location.protocol}/Images/Photos/Thumbnails${photo.path}`,
            },
            removed: false,
          } as ServiceImageData;
        })
        : [],
    [s3StorageAPIUrl, serviceData?.photos]
  );

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setFiles((prevCards: ServiceImageData[]) => {
      const activeCards = prevCards.filter((f) => !f.removed);
      const removedCards = prevCards.filter((f) => f.removed);
      const sortedCards = update(activeCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, activeCards[dragIndex] as ServiceImageData],
        ],
      });

      return [...removedCards, ...sortedCards];
    });
  }, []);

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();

      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  const dataURItoBlob = async (dataURI: string) => (await fetch(dataURI)).blob();

  const save = async () => {
    setLoader(true);
    try {
      const remList = files.filter((f) => f.removed);

      if (remList?.length > 0) {
        for (let i = 0; i < remList?.length; i++) {
          try {
            await deletePhoto(remList[i].sourceFileName);
          } catch {
            return;
          }

          if (isTemplate) {
            await photoApi.deleteTemplatePhoto(remList[i].id, serviceData.info?.templateId ?? 0);
          } else {
            await photoApi.deleteServicePhoto(remList[i].id, serviceData.id);
          }
        }
      }

      if (files?.length) {
        for (let i = 0; i < files?.length; i++) {
          if (files[i].id.indexOf('file') === 0) {
            const item = (await createImage(files[i].preview.url)) as HTMLImageElement;

            const blob = await dataURItoBlob(files[i].preview.url);
            const photoName = files[i].sourceFileName || getPhotoName(files[i].name, ACCEPTS);
            const image = {
              name: files[i].name,
              width: item.width,
              height: item.height,
              sortOrder: i + 1,
              image: blob,
              educationTypeId: serviceData.educationTypeId,
              templateId: serviceData.id,
              serviceId: serviceData.id,
              sourceFileName: photoName,
            } as ServicePhotoItem;

            let result = 0;

            try {
              await postPhoto(photoName, blob);
            } catch {
              return;
            }

            if (isTemplate) {
              result = await photoApi.putTemplatePhoto(serviceData.id, image);
            } else {
              result = await photoApi.putServicePhoto(serviceData.id, image);
            }

            files[i].id = String(result);
          }
        }
      }

      const sortList = files
        .filter((item) => !item.removed)
        .map((item, index) => ({
          sortOrder: index,
          photoId: Number(item.id),
        }));

      if (sortList.length > 0) {
        if (isTemplate) {
          await photoApi.updateTemplatePhotoListOrder({
            templateId: serviceData.info?.templateId,
            educationTypeId: serviceData.educationTypeId,
            list: sortList,
          });
        } else {
          await photoApi.updateServicePhotoListOrder({
            serviceId: serviceData.id,
            educationTypeId: serviceData.educationTypeId,
            list: sortList,
          });
        }
      }
    } finally {
      setLoader(false);
    }

    setEditModeParent && setEditModeParent(null);
    setEditMode(false);
    updateService();
  };

  useEffect(() => {
    const fetch = async () => {
      const url = await systemPropertyApi.getSystemProperty(SystemPropertyEnum.S3StorageAPIUrl);

      setS3StorageAPIUrl(url);
    };

    fetch();
  }, []);

  useEffect(() => {
    setFiles(s3StorageAPIUrl ? servicePhotos : []);
  }, [s3StorageAPIUrl, servicePhotos]);

  const checkEditable =
    setEditModeParent &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Arhive) &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Signed) &&
    accessPanelEdit;

  const isPossibleAddFile = files?.filter((f) => !f.removed)?.length < photoCount;

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => 'Фотографии услуги'}
        headingControl={() =>
          checkEditable &&
          !editMode && (
            <button
              type="button"
              onClick={() => {
                setEditMode(true);
                setEditModeParent && setEditModeParent('photo');
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-edit"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          )
        }
      >
        {serviceData?.id ? (
          !editMode ? (
            <div className="container">
              {files.length ? (
                <div className="service-photo-grid">
                  {files.map((file, i) => (
                    <div
                      key={file.id}
                      role="presentation"
                      className="service-photo"
                      onClick={() => openFullScreen(file, true)}
                    >
                      <div className="service-photo__inner">
                        <div
                          className="service-photo__img"
                          style={{
                            backgroundImage: `url('${file.preview.url}')`,
                          }}
                        />
                        <div className="service-photo__head">{i === 0 ? 'Главное фото' : `Фото №${i + 1}`}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span className="color-gray-dark">Фотографии отсутствуют</span>
              )}
              <Push size={24} />
            </div>
          ) : (
            <div className="container">
              <Files
                clickable={isPossibleAddFile ?? true}
                multiple={false}
                className="auth-files-dropzone"
                onChange={(newFiles: ReactFilesFile[]) => {
                  if (newFiles.length) {
                    const img = document.createElement('img');

                    img.src = newFiles[0].preview.url;
                    img.onload = function () {
                      const { width } = img;
                      const { height } = img;

                      if (width > 1920 || width < 1600) {
                        dispatch(
                          notify.danger({
                            dataTest: 'widthPhoto',
                            title:
                              'Ширина изображения должна быть в интервале 1600-1920px, выберите другое изображение и повторите загрузку.',
                          })
                        );
                      } else if (height > 1200 || height < 900) {
                        dispatch(
                          notify.danger({
                            dataTest: 'heightPhoto',
                            title:
                              'Высота изображения должна быть в интервале 900-1200px, выберите другое изображение и повторите загрузку.',
                          })
                        );
                      } else if (isPossibleAddFile) {
                        setFiles([...files, ...newFiles]);
                      }
                    };
                  }
                }}
                onError={(error: ReactFilesError) => {
                  if (isPossibleAddFile) {
                    if (error.code === ErrorCodeEnum.fileTooSmall || error.code === ErrorCodeEnum.fileTooLarge) {
                      dispatch(
                        notify.danger({
                          dataTest: 'sizePhoto',
                          title:
                            'Размер изображения должен быть в интервале 0.3-2.5Мб, выберите другое изображение и повторите загрузку.',
                        })
                      );
                    }
                    if (error.code === ErrorCodeEnum.invalidFileType) {
                      dispatch(
                        notify.danger({
                          dataTest: 'formatPhoto',
                          title:
                            'Для загрузки доступны изображения в формате JPG, выберите другое изображение и повторите загрузку.',
                        })
                      );
                    }
                    if (error.code === ErrorCodeEnum.maximumFileCount) {
                      dispatch(
                        notify.danger({
                          dataTest: 'maxPhoto',
                          title: `Максимальное количество загружаемых файлов - ${photoCount}.`,
                        })
                      );
                    }
                  }
                }}
                accepts={ACCEPTS}
                maxFileSize={2_621_440}
                minFileSize={314_572}
              >
                <div className="org-photo-dropzone__title font-size-base">Перетащите файлы сюда, чтобы загрузить</div>
                <Push size={16} />
                <div className="text-center line-height-text">
                  Формат файлов: JPG. Ориентация — горизонтальная.
                  <br />
                  Ширина: 1600-1920 px. Высота: 900-1200 px. Размер: 0,3-2,5 Мб.
                  <br />
                  Не более 4 фотографий.
                </div>
                <Push size={16} />
                <LmButton
                  type="button"
                  disabled={!(files?.filter((f) => !f.removed)?.length < photoCount ?? true)}
                >
                  Выбрать файл
                </LmButton>
              </Files>
              <DndProvider backend={HTML5Backend}>
                {!!files.length && (
                  <>
                    <Push size={24} />
                    <div className="font-size-base font-weight-bold">Загруженные фотографии</div>
                    <Push size={16} />
                    <div className="service-photo-grid">
                      {files
                        .filter((f) => !f.removed)
                        .map((file, i) => (
                          <PhotoCard
                            key={file.id}
                            file={file}
                            files={files}
                            index={i}
                            setFiles={setFiles}
                            moveCard={moveCard}
                            openGallery={() => openFullScreen(file, true)}
                          />
                        ))}
                    </div>
                  </>
                )}
              </DndProvider>
              <Push size={24} />
            </div>
          )
        ) : (
          <div className="container">
            <LmInfoBox
              dataTest="photoWarning"
              className="infobox--full-width"
              variant="warning"
              description="Возможность добавления фотографий услуги станет доступна после первого сохранения."
              hidenFooter
            />
            <Push size={24} />
          </div>
        )}
      </Panel>

      {editMode && setEditModeParent && (
        <SavePanel
          primaryButtonModifiers={{
            loading: loader,
          }}
          onClickSeconadaryButton={() => {
            setEditMode(false);
            setEditModeParent && setEditModeParent(null);
            setFiles(servicePhotos);
          }}
          onClickPrimaryButton={save}
        />
      )}

      {currentImage && (
        <GalleryModal
          show={fullScreen}
          currentImage={currentImage}
          onCloseHandler={() => setFullScreen(false)}
          images={files.filter((item) => !item.removed)}
        />
      )}
    </>
  );
};

export default Photo;
