import React from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';

import OrganizationField from '../../components/fields/organization';
import ParallelField from '../../components/fields/parallel';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import NameCodeField from '../../components/fields/name-code';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  editMode?: boolean;
  setEditMode?: (value: boolean) => void;
  parent?: string;
  isCreate?: boolean;
  organizationEdit?: boolean;
  isDraft?: boolean;
  accessEditable?: boolean;
};

const TemplateInfo: React.FC<Props> = ({
  setEditModeParent,
  editMode,
  setEditMode,
  parent,
  isCreate,
  organizationEdit,
  isDraft,
  accessEditable,
}) => {
  const parentValue = parent || '';
  const checkEditable = !editMode && setEditModeParent && isDraft && accessEditable;

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => <>Сведения о программе по уходу и присмотру за детьми школьного возраста</>}
        headingControl={() => {
          return checkEditable ? (
            <button
              type="button"
              onClick={() => {
                setEditModeParent && setEditModeParent('info');
                setEditMode && setEditMode(true);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-edit"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        <div className="container">
          <OrganizationField
            name="organization"
            parent={parentValue}
            label={
              <>
                Образовательная <br /> организация
              </>
            }
            required
            editMode={editMode}
            disabled={!organizationEdit || (!organizationEdit && !isCreate)}
            isForDayCareSchool
          />
          <NameCodeField
            name=""
            parent={parentValue}
            editMode={editMode}
            required
            isCreate={isCreate}
          />
          <ParallelField
            name="parallel"
            parent={parentValue}
            required
            editMode={editMode}
          />
          <SimpleTextArea
            name={parentValue ? `${parentValue}.programmService` : 'programmService'}
            editMode={editMode}
            label="Описание программы"
            placeholder="Введите..."
            required
            maxLength={1000}
          />
        </div>
      </Panel>
    </>
  );
};

export default TemplateInfo;
