import React from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import { LmInputProps } from '@mes-ui/lemma';
import Field, { FieldProps } from './field';
import FormikInput from '../formik/formik-input';

export type SimpleInputProps = FieldProps<string> & { showErrorImmediately?: boolean } & Pick<
    LmInputProps,
    'onKeyPress'
  >;

const SimpleInput = ({
  label,
  defaultValue = '—',
  name,
  editMode,
  required,
  maxLength,
  value,
  showErrorImmediately = false,
  placeholder,
  disabled,
  showRequiredIcon,
  onKeyPress,
}: SimpleInputProps) => {
  return (
    <FormikField name={name}>
      {({ field }: FormikFieldProps) => {
        const v = value ?? field.value;

        return (
          <Field
            label={label}
            editMode={editMode}
            required={required}
            value={v}
            defaultValue={defaultValue}
            showRequiredIcon={showRequiredIcon}
          >
            <FormikInput
              name={name}
              placeholder={placeholder || 'Введите...'}
              size="small"
              maxLength={maxLength}
              disabled={disabled}
              onKeyPress={onKeyPress}
              showErrorImmediately={showErrorImmediately}
            />
          </Field>
        );
      }}
    </FormikField>
  );
};

export default SimpleInput;
