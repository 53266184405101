// Место выдачи.
export enum IssuedPlaceEnum {
  // "Москва".
  Moscow = 1,

  // "Регионы РФ".
  RegionsRussianFederation = 2,

  // "Иностранные государства".
  ForeignStates = 3,
}
