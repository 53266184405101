import { LmIconNames } from '@mes-ui/lemma/components/LmIcon/lmIconGenerateComponents';

export const sidebarIcon: Record<string, LmIconNames> = Object.freeze({
  main: 'outline-edit-grid',
  reports: 'outline-business-pie-chart',
  request: 'outline-files-file',
  myServices: 'outline-image-video-color-palette',
  registerLearners: 'outline-misc-school',
  myTemplates: 'outline-image-video-color-palette',
  registerChildEducation: 'outline-image-video-color-palette',
  registerEducationPrograms: 'outline-image-video-color-palette',
  registerServices: 'outline-image-video-color-palette',
  serviceClasses: 'outline-account-people',
  teachers: 'outline-account-person',
  disciplines: 'outline-internet-code-cube',
  organizations: 'filled-misc-organization',
  organizationInfo: 'filled-misc-organization',
  favorite: 'outline-misc-star',
  userManual: 'outline-notifications-info',
  faq: 'outline-notifications-question-mark-circle',
  normativeDoc: 'outline-edit-book',
  mesh: 'outline-edit-redo',
  file: 'outline-files-file',
  siteMap: 'outline-social-share',
});
