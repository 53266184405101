import React, { useEffect, useState, createContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../config/constants';
import TeacherForm from './form/index';
import ChangeHistory from '../../../components/change-history';
import { entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import teacherApi from '../../../lib/api/teacher';
import { Teacher as TeacherType } from '../../../types/teacher';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { goBack, hasAccessObjectAny } from '../../../lib/utils';
import AddArchiveModal from './modal/add-archive';

export type TeacherContextType = {
  loading: boolean;
  teacher: TeacherType | undefined;
};

export const TeacherContext = createContext<TeacherContextType>({} as TeacherContextType);

const Teacher = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [teacher, setTeacher] = useState<TeacherType | undefined>(undefined);

  const { id } = useParams<Record<string, string | undefined>>();

  const [loading, setLoading] = useState<boolean>(true);

  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);

  const getTeacher = useCallback(async () => {
    if (id) {
      try {
        const data = await teacherApi.getTeacher(id);

        setTeacher(data);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    getTeacher();
  }, [id, getTeacher]);

  const accessAddArchive = hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.Archive);

  const initialValues: TeacherContextType = useMemo(
    () => ({
      loading,
      teacher,
    }),
    [loading, teacher]
  );

  const openArchiveModal = () => {
    setShowModalArchive(true);
  };

  return (
    <TeacherContext.Provider value={initialValues}>
      {!loading && (
        <LmSubHeader
          sticky
          description=""
          title={teacher?.fullName || ''}
          dataTest="teacherSubHeader"
          arrowOnClick={() => goBack(routes.teachers)}
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Преподаватели',
              link: routes.teachers,
            },
            {
              label: 'Сведения о преподавателе',
            },
          ]}
          tabs={[
            {
              title: 'Основные сведения',
              value: 1,
              dataTest: 'basicInfo',
            },
            {
              title: 'История изменений',
              value: 2,
              dataTest: 'changeHistory',
            },
          ]}
          className="content-panel"
          panels={[
            {
              children: teacher && (
                <TeacherForm
                  onChange={setTeacher}
                  teacher={teacher}
                />
              ),
              value: 1,
            },
            {
              children: (
                <ChangeHistory
                  name="преподавателей"
                  entityTypes={entityTypesForHistory.teachers}
                  extendEntityGuid={teacher?.extendEntityGuid}
                />
              ),
              value: 2,
              className: 'content-panel content-panel--auto',
              noPadding: true,
            },
          ]}
          buttonsOrAnyActions={
            !teacher?.isArchive && teacher?.externalSystemId !== 1
              ? [
                  {
                    dataTest: 'toArchive',
                    icon: 'filled-hardware-hard-drive',
                    buttonText: 'В архив',
                    rounded: 'small',
                    variant: 'secondary',
                    disabled: !accessAddArchive,
                    onClick: openArchiveModal,
                  },
                ]
              : undefined
          }
        />
      )}

      <AddArchiveModal
        onCloseHandler={() => setShowModalArchive(false)}
        show={showModalArchive}
      />
    </TeacherContext.Provider>
  );
};

export default Teacher;
