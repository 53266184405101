import { useFormikContext } from 'formik';
import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmRadio, LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import FormikSelect from '../../../../components/formik/formik-select';
import { weekday } from '../../../../components/shedule-modal/helpers';
import {
  daysForTrainingSchedule,
  postfixMatrix,
  repetitionTypes,
  weekNumberInMonth,
} from '../../../../lib/utils/service';
import { TypesOfRepetitionEnum } from '../../../../mock-data/type-of-repetition';
import { ScheduleDataEx } from '../tests';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';

type Props = {
  editMode: boolean;
  enabled: boolean;
};

const ScheduleRepetition: React.FC<Props> = ({ editMode, enabled }) => {
  const { values, setFieldValue } = useFormikContext<ScheduleDataEx>();

  return (
    <>
      {values.repeat && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className="flex">
              {editMode ? (
                <LmSwitcher
                  dataTest="repetitionTypes"
                  size="small"
                  value={values.typesOfRepetitionId}
                  onChange={(value) => setFieldValue('typesOfRepetitionId', value)}
                  variant="gray"
                  disabled={!enabled}
                >
                  {repetitionTypes.map((element) => {
                    return (
                      <LmSwitcherOption
                        dataTest={`switcher-typesOfRepetitionId-${element.value}`}
                        key={element.value}
                        value={element.value}
                      >
                        {element.label}
                      </LmSwitcherOption>
                    );
                  })}
                </LmSwitcher>
              ) : (
                <div className="flex items-center flex-none table-row-item-height">
                  {repetitionTypes.find((r) => values.typesOfRepetitionId === r.value)?.label ?? '—'}
                </div>
              )}
              <Push
                size={16}
                orientation="horizontal"
              />
            </div>
          </div>
        </div>
      )}

      {values.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatDaily && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Ежедневный повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className="flex">
              {editMode ? (
                <>
                  <LmRadio
                    name="everyday"
                    dataTest="everyday"
                    onChange={() => {
                      setFieldValue('everyday', false);
                    }}
                    checked={!values.everyday}
                    disabled={!enabled}
                  >
                    Каждый
                  </LmRadio>
                  <Push
                    size={16}
                    orientation="horizontal"
                  />
                  <FormikInputNumber
                    name="repeatNumber1"
                    disabled={!enabled}
                  />
                  <div className="flex items-center flex-none table-row-item-height">
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    день
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                  </div>
                  <Push
                    size={32}
                    orientation="horizontal"
                  />
                  <LmRadio
                    name="everyday"
                    dataTest="everyday"
                    onChange={() => {
                      setFieldValue('everyday', true);
                    }}
                    checked={values.everyday ?? false}
                    disabled={!enabled}
                  >
                    Каждый рабочий день
                  </LmRadio>
                </>
              ) : (
                <div className="flex items-center flex-none table-row-item-height">
                  {values.everyday ? 'каждый рабочий день' : `каждый ${values.repeatNumber1}-й день`}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {values.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatWeekly && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Еженедельный повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className="flex">
              <div className="flex items-center flex-none table-row-item-height">
                каждую
                <Push
                  size={8}
                  orientation="horizontal"
                />
              </div>

              {editMode ? (
                <FormikInputNumber
                  name="everyWeekRepeatNumber1"
                  disabled={!enabled}
                />
              ) : (
                <div>
                  <div className="flex items-center flex-none table-row-item-height">
                    {`${values.everyWeekRepeatNumber1}-ю`}
                  </div>
                </div>
              )}

              <div className="flex items-center flex-none table-row-item-height">
                <Push
                  size={8}
                  orientation="horizontal"
                />
                неделю в следующие дни:
              </div>

              {editMode ? (
                daysForTrainingSchedule.map((day) => {
                  return (
                    <>
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <FormikCheckbox
                        name={day.value}
                        label={day.label}
                        disabled={!enabled}
                        boxSize="small"
                      />
                    </>
                  );
                })
              ) : (
                <div>
                  <div className="flex items-center flex-none table-row-item-height">
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    {daysForTrainingSchedule
                      .filter((d) => !!(values as any)[d.value])
                      ?.map((e) => {
                        return e.label;
                      })
                      .join(', ')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {values.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatMonthly && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Ежемесячный повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            {editMode ? (
              <div className="flex">
                <LmRadio
                  name="everymonth"
                  dataTest="everymonth"
                  onChange={() => {
                    setFieldValue('everymonth', false);
                  }}
                  checked={!values.everymonth}
                  disabled={!enabled}
                />

                <FormikInputNumber
                  name="everymonthRepeatNumber1"
                  disabled={!enabled}
                />
                <div className="flex items-center flex-none table-row-item-height">
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  числа каждого
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                </div>
                <FormikInputNumber
                  name="everymonthRepeatNumber2"
                  disabled={!enabled}
                />
                <div className="flex items-center flex-none table-row-item-height">
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  месяца
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                </div>
              </div>
            ) : !values.everymonth ? (
              `${values.everymonthRepeatNumber1}-го числа каждого ${values.everymonthRepeatNumber2}-го месяца`
            ) : null}

            {editMode && (
              <Push
                size={16}
                orientation="vertical"
              />
            )}

            {editMode ? (
              <div className="flex">
                <LmRadio
                  name="everymonth"
                  dataTest="everymonth"
                  onChange={() => {
                    setFieldValue('everymonth', true);
                  }}
                  checked={values.everymonth ?? false}
                  disabled={!enabled}
                >
                  в
                </LmRadio>
                <Push
                  size={8}
                  orientation="horizontal"
                />

                <div
                  style={{
                    width: 130,
                  }}
                >
                  <FormikSelect
                    required
                    name="everymonthRepeatNumber3"
                    size="small"
                    withSearch
                    options={weekNumberInMonth}
                    defaultValue={{
                      label: weekNumberInMonth[0].label,
                      value: weekNumberInMonth[0].value,
                    }}
                    disabled={!enabled}
                  />
                </div>

                <Push
                  size={8}
                  orientation="horizontal"
                />
                <div
                  style={{
                    width: 172,
                  }}
                >
                  <FormikSelect
                    required
                    name="dayOfWeek"
                    size="small"
                    withSearch
                    options={weekday.map((e) => {
                      return {
                        label: e.name,
                        value: e.id,
                      };
                    })}
                    defaultValue={{
                      label: weekday[0].name,
                      value: weekday[0].id,
                    }}
                    disabled={!enabled}
                  />
                </div>

                <div className="flex items-center flex-none table-row-item-height">
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  каждого
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                </div>
                <FormikInputNumber
                  name="everymonthRepeatNumber4"
                  disabled={!enabled}
                />
                <div className="flex items-center flex-none table-row-item-height">
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                  месяца
                  <Push
                    size={8}
                    orientation="horizontal"
                  />
                </div>
              </div>
            ) : values.everymonth ? (
              `в${values.everymonthRepeatNumber3 === 2 ? 'о' : ''} ${
                weekNumberInMonth.find((w) => w.value === values.everymonthRepeatNumber3)?.label
              }${values.everymonthRepeatNumber3 !== 5 ? '-' : ''}${
                postfixMatrix[values.everymonthRepeatNumber3 !== 5 ? 0 : 1][(values.dayOfWeek ?? 1) - 1]
              } ${daysForTrainingSchedule.find((w) => w.id === values.dayOfWeek)?.fullName} каждого ${
                values.everymonthRepeatNumber4
              }-го месяца`
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleRepetition;
