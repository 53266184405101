import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LmButton } from '@mes-ui/lemma';
import SavePanel from '../../../../components/save-panel';
import Expulsion from '../../components/modals/expulsion';
import TransferGroup from '../../components/modals/transfer-group';
import Certificate from '../../components/modals/certificate';
import Recovery from '../../components/modals/recovery';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, selected, openDetails, active } = useContext(ServiceClassTableContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { accessRecover } = useContext(ServiceClassContext);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);
  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);
  const [openCertificatePopup, setOpenCertificatePopup] = useState<boolean>(false);

  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);
  const hasCreateDocument = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.CompletionDocument], accessAction.Create),
    [userProfile]
  );

  const handleDeselect = () => {
    setSelected([]);
    setOpenDetails(false);
  };

  const showExpulsionModal = () => {
    setOpenExpulsionPopup(true);
  };

  const showTransferGroupModal = () => {
    setOpenTransferGroupPopup(true);
  };

  const showCertificateModal = () => {
    setOpenCertificatePopup(true);
  };

  const showRecoveryModal = () => {
    setOpenRecoveryPopup(true);
  };

  return (
    <>
      {' '}
      {openDetails && (
        <SavePanel
          hideSecondaryButton
          hidePrimaryButton
        >
          <LmButton
            dataTest="cancelSelection"
            type="button"
            variant="text"
            size="medium"
            onClick={handleDeselect}
          >
            Отменить выбор
          </LmButton>

          {active ? (
            <>
              <LmButton
                dataTest="expel"
                type="button"
                color="danger"
                variant="outline"
                size="medium"
                onClick={showExpulsionModal}
              >
                Отчислить
              </LmButton>

              <LmButton
                dataTest="transferGroup"
                type="button"
                variant="outline"
                size="medium"
                onClick={showTransferGroupModal}
              >
                Перевести в другую группу обучения
              </LmButton>

              {(hasCreateDocument || isAdminEdit) && (
                <LmButton
                  dataTest="issueCertificate"
                  type="button"
                  variant="outline"
                  size="medium"
                  disabled={selected.length > 1}
                  onClick={showCertificateModal}
                >
                  Выдать сертификат
                </LmButton>
              )}
            </>
          ) : (
            accessRecover && (
              <LmButton
                dataTest="recover"
                type="button"
                variant="outline"
                size="medium"
                onClick={showRecoveryModal}
              >
                Восстановить
              </LmButton>
            )
          )}
        </SavePanel>
      )}
      <Recovery
        open={openRecoveryPopup}
        close={() => setOpenRecoveryPopup(false)}
      />
      <Expulsion
        open={openExpulsionPopup}
        close={() => setOpenExpulsionPopup(false)}
      />
      <TransferGroup
        open={openTransferGroupPopup}
        close={() => setOpenTransferGroupPopup(false)}
      />
      <Certificate
        open={openCertificatePopup}
        close={() => setOpenCertificatePopup(false)}
      />
    </>
  );
};

export default PanelButtons;
