// Перечисление типов ответов от внешних систем
export enum ExternalSystemResponseTypeEnum {
  // Проверка достоверности свидетельства о рождении обучающегося
  BirthCertificateValidityCheckingRequest = 10,
  // Проверка достоверности паспорта обучающегося
  PupilPassportValidityCheckingRequest = 11,
  // Проверка достоверности паспорта заявителя
  ApplicantPassportValidityCheckingRequest = 12,
  // Проверка достоверности адреса регистрации обучающегося
  RegistrationAddressValidityCheckingRequest = 13,
  // Проверка родства обучающегося и родителя
  RelatednessCheckingRequest = 14,
  // Проверки корректности записи о рождении в ЕГР ЗАГС
  BirthRecordCheck = 16,
}
