import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { LmIcon, LmLoader } from '@mes-ui/lemma';
import { Panel, Push } from '@mosru/esz_uikit';
import { useDispatch, useSelector } from 'react-redux';
import SavePanel from '../../../components/save-panel';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { handleBookingChange, setBookingState } from '../../../redux/ducks/booking';
import { notify } from '../../../redux/ducks/notifications';
import { bookingSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import Service from '../components/fields/service';
import AdmissionPlan from '../components/fields/admission-plan';
import VisitDays from '../components/fields/visit-days';
import ServiceClassField from '../components/fields/service-class';
import { SchoolRequestData, TrainingGroupRequestData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import { ServiceClassListData, ServiceClassPeriodListScheduleData } from '../../../types/service-class';
import { trainingGroupSchema } from './create';
import { EducationTypeEnum } from '../../../types/education-type';

type Props = {
  setEditModeParent: (value: string | null) => void;
  trainingGroup: TrainingGroupRequestData;
  updateRequest: () => void;
  requestId: number;
  showEditBtn?: (type: string) => boolean;
  school: SchoolRequestData;
  changeStatusRequest?: () => void;
};

const ServiceClass: React.FC<Props> = ({
  setEditModeParent,
  trainingGroup,
  updateRequest,
  requestId,
  showEditBtn,
  school,
  changeStatusRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceClassList, setServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);

  const initialErrors = useInitialErrors(trainingGroup, trainingGroupSchema);

  const [initialDataServiceClassList, setInitialDataServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);

  const dispatch = useDispatch();

  const { booking } = useSelector((state: AppState) => ({
    booking: bookingSelector(state),
  }));

  useEffect(() => {
    return () => {
      dispatch(setBookingState());
    };
  }, [dispatch]);

  useEffect(() => {
    trainingGroup &&
      dispatch(
        handleBookingChange(
          {
            bookingId: trainingGroup.bookingGuid,
            slotId: trainingGroup.slotId,
          },
          trainingGroup.serviceClass?.id
        )
      );
  }, [dispatch, trainingGroup]);

  const submitForm = useCallback(
    async (values: TrainingGroupRequestData) => {
      setLoading(true);
      try {
        if (booking) {
          values.slotId = booking.slotId;
          values.bookingGuid = booking.bookingId;
          await requestsApi.saveTrainingGroup(requestId, values);

          dispatch(setBookingState());
          setEditModeParent(null);
          setEditMode(false);
          // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
          changeStatusRequest && changeStatusRequest();

          updateRequest();
        }
      } catch (e) {
        dispatch(
          notify.danger({
            dataTest: 'saveInfoDayCare',
            title: 'Не удалось сохранить информацию о группе по присмотру и уходу за детьми школьного возраста',
          })
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditModeParent, updateRequest, requestId, booking]
  );

  const initValues = useMemo(() => {
    return {
      ...trainingGroup,
      schoolOrganizationId: school?.schoolOrganizationId,
      schoolOrganizationName: school?.schoolOrganizationName,
      classParallelId: school?.classParallelId,
      classParallelName: school?.classParallelName,
      classLetterId: school?.classLetterId,
      classLetterName: school?.classLetterName,
    };
  }, [trainingGroup, school]);

  const handleCancel = (resetForm: VoidFunction) => {
    if (trainingGroup && trainingGroup.bookingGuid !== booking?.bookingId) {
      dispatch(handleBookingChange());
    }

    setEditModeParent(null);
    setEditMode(false);
    resetForm();
  };

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={trainingGroupSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={initValues}
    >
      {(formikProps: FormikProps<TrainingGroupRequestData>) => {
        const { handleSubmit, submitForm, isSubmitting, isValid, resetForm, initialValues } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о группе по присмотру и уходу за детьми школьного возраста'}
              headingControl={() => {
                return !editMode && showEditBtn?.('serviceClass') ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('serviceClass');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <Service
                    editMode={editMode}
                    trainingGroup={trainingGroup}
                    required
                  />
                  <AdmissionPlan
                    name="scheduleOfTimetableId"
                    editMode={editMode}
                    required
                  />
                  <VisitDays
                    name="desiredDays"
                    editMode={editMode}
                    setServiceClassList={setServiceClassList}
                    initialDataServiceClassList={initialDataServiceClassList}
                    required
                    initialValues={initialValues}
                    curentServiceClassId={trainingGroup.serviceClass?.id}
                  />
                  <ServiceClassField
                    name="serviceClass"
                    editMode={editMode}
                    serviceClassList={serviceClassList}
                    setServiceClassList={setServiceClassList}
                    setInitialDataServiceClassList={setInitialDataServiceClassList}
                    required
                    isNeedBooking
                    curentServiceClassId={trainingGroup.serviceClass?.id}
                    educationType={EducationTypeEnum.DayCareCentersEducation}
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid || !booking,
                }}
                onClickSeconadaryButton={() => handleCancel(resetForm)}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceClass;
