import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmLoader } from '@mes-ui/lemma';
import SavePanel from '../../../components/save-panel';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { addPhonePrefix } from '../../../lib/utils/format-number';
import FIO from '../components/fields/fio';
import Issued from '../components/fields/issued';
import RepresentationType from '../components/fields/representation-type';
import Phone from '../../../components/fields/phone';
import Email from '../../../components/fields/email';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import { PersonRequestData } from '../../../types/requests';
import {
  documentNumber,
  documentSeries,
  firstName,
  lastName,
  middleName,
  nullableDate,
  nullableEmail,
  phone,
  stringRequired,
} from '../../../lib/utils/validation';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import { submitPersonRequestData } from '../utils';

type Props = {
  requestApplicant: PersonRequestData;
  setEditModeParent: (value: string | null) => void;
  editable: boolean;
  updateRequest: () => void;
};

const ApplicantForm: React.FC<Props> = ({ requestApplicant, setEditModeParent, editable, updateRequest }) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialErrors = useInitialErrors(requestApplicant, applicantValidationSchema);

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      const request = {
        ...values,
        phone: addPhonePrefix(values.phone),
      };

      await submitPersonRequestData(request, false, setLoading, setEditMode, setEditModeParent, updateRequest);
    },
    [setEditModeParent, updateRequest]
  );

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={applicantValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={requestApplicant}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, submitForm, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о заявителе'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('applicant');
                      setEditMode(true);
                      resetForm();
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <RepresentationType
                    required
                    name="typeRepresentation"
                    editMode={editMode}
                  />
                  <FIO
                    required
                    label="ФИО заявителя"
                    editMode={editMode}
                  />
                  <Phone
                    required
                    name="phone"
                    editMode={editMode}
                  />
                  <Email
                    name="email"
                    editMode={editMode}
                    defaultValue="Не представлен"
                  />
                  <Document
                    name="documentType"
                    editMode={editMode}
                    formType={FormTypeEnum.EditRequestApplicant}
                    dependentFields
                  />
                  <Issued
                    maxLength={250}
                    name="issued"
                    editMode={editMode}
                  />
                  <DocumentNumber
                    editMode={editMode}
                    defaultValue="—"
                    calendarPosition="top-end"
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ApplicantForm;

const applicantValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  email: nullableEmail,
  phone,
  documentTypeId: stringRequired,
  dateOfIssue: nullableDate,
  series: documentSeries().nullable(),
  number: documentNumber().nullable(),
});
