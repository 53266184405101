import { array as arrayYup, object as objectYup, string as stringYup, ValidationError } from 'yup';
import { LmBadgeStatus } from '@mes-ui/lemma';
import { LmBadgeColors } from '@mes-ui/lemma/components/LmBadge/model';
import { Push } from '@mosru/esz_uikit';
import { routes } from '../../config/constants';
import { serviceTemplateApi } from '../../lib/api/service-template';
import { calcLearnEnd } from '../../lib/utils/service';
import { validationCheckDate } from '../../lib/utils/validation';
import { hasAccessObjectAny, hasGeneralAccess } from '../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../mock-data/access-enum';
import { RegistryTypeEnum } from '../../mock-data/registry-type-enum';
import { ServiceStatusEnum } from '../../mock-data/service-status-enum';
import { ScheduleData, ServiceData, ServiceDataInfo, TrainingGroupData, TrainingStageData } from '../../types/service';
import { AuthorizationData } from '../../types/authorization-data';
import { EducationTypeEnum } from '../../types/education-type';
import { SelectOptionType } from '../../types/entities';
import { ParallelOptionsByResponse } from './components/fields/parallel';

export const MAX_STAGE_NAME_LENGTH = 300;

export const toArchive = async (id: string) => {
  if (id) {
    try {
      await serviceTemplateApi.serviceToArchive(id);
    } catch {}
  }
};

export const getHeaderLink = (
  currentEducationType: EducationTypeEnum[],
  type: RegistryTypeEnum,
  educationTypeId: number
): string => {
  type LinkByEducationRegistryType = {
    [index: number]: string;
  };
  // Когда у пользователя больше 1 роли, мы делаем редирект на общий роут.
  const checkAccessAmount = currentEducationType.length > 1;

  const childrenEducationLink = checkAccessAmount ? routes.programs : routes.registerChildEducations;
  const dkgmOrDsitEducationLink = type === RegistryTypeEnum.serviceTemplateType ? routes.myTemplates : routes.services;

  const linkByEducationRegistryType: LinkByEducationRegistryType = {
    [EducationTypeEnum.ChildrenEducation]: childrenEducationLink,
    [EducationTypeEnum.DayCareCentersEducation]: checkAccessAmount ? routes.programs : routes.dayCarePrograms,
    [EducationTypeEnum.VirtualAssistantEducation]: checkAccessAmount ? routes.programs : routes.services,
    [EducationTypeEnum.ChildrenNonDogmEducation]: childrenEducationLink,
    [EducationTypeEnum.ArtHouseEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.SportEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.ProfessionalEducation]: routes.registerEducationPrograms,
  };

  return linkByEducationRegistryType[educationTypeId];
};

export const convertParallelInResponse = (options: SelectOptionType[]): ParallelOptionsByResponse[] => {
  return options?.map(
    (item: SelectOptionType) =>
      ({
        id: item.value,
        parallelName: item.label,
      } as ParallelOptionsByResponse)
  );
};

export const convertParallelInSelect = (options: ParallelOptionsByResponse[]): SelectOptionType[] => {
  const newOptions = options?.map(
    (item: ParallelOptionsByResponse) =>
      ({
        value: item.id,
        label: item.parallelName,
      } as SelectOptionType)
  );

  return newOptions.sort((a, b) => {
    const labelFirst = +a.label;
    const labelSecond = +b.label;

    if (labelFirst > labelSecond) {
      return 1;
    } else if (labelFirst < labelSecond) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const organizationAccess = (userProfile: AuthorizationData): boolean => {
  return (
    hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) ||
    hasGeneralAccess(userProfile, generalAccess.AdminView) ||
    hasGeneralAccess(userProfile, generalAccess.AdminEdit)
  );
};

export const getTabsButton = (serviceStatusId: ServiceStatusEnum, serviceStatusName: string): JSX.Element => {
  const color: LmBadgeColors =
    serviceStatusId === ServiceStatusEnum.Arhive
      ? 'grey'
      : serviceStatusId === ServiceStatusEnum.Draft
        ? 'orange'
        : 'green';

  return (
    <>
      <Push
        size={12}
        orientation="vertical"
      />
      <div className="flex justify-end">
        <LmBadgeStatus
          dataTest="serviceStatus"
          color={color}
        >
          {serviceStatusName}
        </LmBadgeStatus>
      </div>
    </>
  );
};

export const accessPanelEdit = (
  userProfile: AuthorizationData,
  isAdmin: boolean,
  educationType: EducationTypeEnum,
  isTemplate?: boolean
): boolean => {
  const serviceDOGM: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceDOGM], accessAction.Edit);

  const serviceNonDOGM: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceNonDOGM], accessAction.Edit);

  const serviceSport = hasAccessObjectAny(
    userProfile,
    [isTemplate ? accessObject.TemplateSport : accessObject.ServiceSport],
    accessAction.Edit
  );

  const serviceArtHouse: boolean = hasAccessObjectAny(
    userProfile,
    [isTemplate ? accessObject.TemplateArtHouse : accessObject.ServiceArtHouse],
    accessAction.Edit
  );

  const serviceOP: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceOP], accessAction.Edit);

  const serviceDayCare: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.Edit);

  const serviceVa: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Edit);

  const access = {
    [EducationTypeEnum.DayCareCentersEducation]: serviceDayCare,
    [EducationTypeEnum.VirtualAssistantEducation]: serviceVa,
    [EducationTypeEnum.ChildrenNonDogmEducation]: serviceNonDOGM,
    [EducationTypeEnum.ArtHouseEducation]: serviceArtHouse,
    [EducationTypeEnum.ChildrenEducation]: serviceDOGM,
    [EducationTypeEnum.SportEducation]: serviceSport,
    [EducationTypeEnum.ProfessionalEducation]: serviceOP,
  };

  return access[educationType] || isAdmin;
};

export const accessObjectByEducationTypeService: { [key: number]: number } = {
  [EducationTypeEnum.DayCareCentersEducation]: accessObject.ServiceDayCare,
  [EducationTypeEnum.ProfessionalEducation]: accessObject.ServiceOP,
  [EducationTypeEnum.VirtualAssistantEducation]: accessObject.ServiceVA,
  [EducationTypeEnum.ArtHouseEducation]: accessObject.ServiceArtHouse,
  [EducationTypeEnum.ChildrenEducation]: accessObject.ServiceDOGM,
  [EducationTypeEnum.ChildrenNonDogmEducation]: accessObject.ServiceNonDOGM,
  [EducationTypeEnum.SportEducation]: accessObject.ServiceSport,
};

export const accessByEducationTypeService = (
  userProfile: AuthorizationData,
  educationType: number,
  accessAction: number
): boolean => {
  return hasAccessObjectAny(userProfile, [accessObjectByEducationTypeService[educationType]], accessAction);
};

export const setGenderStartEndToBack = (info: ServiceDataInfo) => {
  const newInfo = {
    ...info,
  };

  if (info.isFemalePresent) {
    newInfo.altFemaleStart = info.altStart;
    newInfo.altFemaleEnd = info.altEnd;
  }
  if (!info.isMalePresent) {
    newInfo.altStart = undefined;
    newInfo.altEnd = undefined;
  }

  return newInfo;
};

export const setGenderStartEndFromBack = (serviceData: ServiceData) => {
  if (serviceData.info.isFemalePresent && serviceData.info.altFemaleEnd) {
    serviceData.info.altStart = serviceData.info.altFemaleStart;
    serviceData.info.altEnd = serviceData.info.altFemaleEnd;
  }

  return serviceData;
};

export const VOLUME_INFO_MESSAGE =
  'Данный параметр рассчитывается автоматически и является суммой значений предельного кол-ва человек в связанных группах обучения.';

export const getValidationSchemaChildPlan = (serviceData: ServiceData) => {
  const depsDate: [string, string] = ['dateStart', 'dateEnd'];
  const depsMosDate: [string, string] = ['requestStart', 'requestEnd'];
  const service = serviceData;
  const { stage } = serviceData;

  const dateValidation = objectYup().shape(
    {
      dateStart: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'dateStart',
          end: 'dateEnd',
        },
        'Дата начала учебного периода не может быть больше даты окончания',
        'start'
      ),
      dateEnd: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'dateStart',
          end: 'dateEnd',
        },
        'Дата окончания учебного периода не может быть меньше даты начала',
        'end'
      ),
    },
    [depsDate]
  );

  const dateMosValidation = objectYup().shape(
    {
      requestStart: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'requestStart',
          end: 'requestEnd',
        },
        'Дата начала приема заявлений больше даты окончания приема заявлений',
        'start'
      ),
      requestEnd: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'requestStart',
          end: 'requestEnd',
        },
        'Дата окончания приема заявлений меньше даты начала приема заявлений',
        'end'
      ),
      requestTimeStart: stringYup().nullable().required('Введите время начала'),
      requestTimeEnd: stringYup().nullable().required('Введите время окончания'),
    },
    [depsMosDate]
  );

  return {
    serviceStageId: stringYup().required('Выберите учебный период'),
    stageList: arrayYup()
      .test('TrainingStageData[]', 'ошибка', function (values) {
        const list = values as TrainingStageData[];
        const errors: ValidationError[] = [];

        if (list && list.length > 1) {
          for (let i = 0; i < list.length; i++) {
            if (list[i]?.dateStart) {
              if (i > 0 && list[i - 1].dateEnd && new Date(list[i].dateStart) < new Date(list[i - 1].dateEnd)) {
                errors.push(
                  new ValidationError(
                    'Дата начала учебного периода не может быть меньше даты окончания предыдущего периода',
                    undefined,
                    `stageList[${i}].dateStart`
                  )
                );
              }
              if (
                i < list.length - 1 &&
                i >= 0 &&
                list[i + 1].dateStart &&
                new Date(list[i].dateEnd) > new Date(list[i + 1].dateStart)
              ) {
                errors.push(
                  new ValidationError(
                    'Дата окончания учебного периода не может быть больше даты начала следующего периода',
                    undefined,
                    `stageList[${i}].dateEnd`
                  )
                );
              }
            }
          }
        }

        if (
          list?.length > 0 &&
          list[0] &&
          list[0].dateStart &&
          list[stage.list.length - 1] &&
          list[stage.list.length - 1].dateEnd
        ) {
          const learnEnd = calcLearnEnd(
            new Date(list[0].dateStart),
            undefined,
            service.info.durationOfTraining,
            service.info.durationOfTrainingMonths,
            service.info.durationOfTrainingWeeks,
            service.info.durationOfTrainingDays
          );

          if (learnEnd < new Date(list[stage.list.length - 1].dateEnd)) {
            errors.push(
              new ValidationError(
                'Дата окончания учебного периода выходит за рамки продолжительности программы обучения',
                undefined,
                `stageList[${list.length - 1}].dateEnd`
              )
            );
          }
        }

        return errors.length > 0 ? new ValidationError(errors) : true;
      })
      .of(dateValidation)
      .required(),
    scheduleList: arrayYup()
      .test(
        'dateEnd',
        'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
        function (value) {
          const tg = this.parent as TrainingGroupData;
          const schedule = value as ScheduleData[];

          if (tg?.stageList && tg.stageList.length > 0 && schedule && schedule[0].requestEnd) {
            const index = stage.list?.findIndex((s) => s.id === parseInt(tg.serviceStageId as string));

            if (index !== -1 && tg.stageList[index].dateEnd) {
              const a = new Date(schedule[0].requestEnd);
              const b = new Date(tg.stageList[index].dateEnd);

              return b < a
                ? new ValidationError(
                  'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
                  undefined,
                  'scheduleList[0].requestEnd'
                )
                : true;
            }
          }

          return true;
        }
      )
      .of(dateMosValidation)
      .required(),
  };
};
