import React, { useState } from 'react';
import { LmIcon, LmTooltip } from '@mes-ui/lemma';
import Dialog from '../modals/dialog';
import ModalFavorite from './modal';
import { useDeleteFavoriteMutation } from '../../store/favorites';

type Props = {
  entityId: number;
  entityTypeId: number;
  typeEntity?: string;
  currentEntityType: string;
};

const Favorite: React.FC<Props> = ({ ...props }) => {
  const [id, setId] = useState<number>();

  const [entityId, setEntityId] = useState<number>();
  const [commentTooltip, setCommentTooltip] = useState<string>('');

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDeletePopup, setDeletePopup] = useState<boolean>(false);

  const openPopupHandler = () => setOpenPopup(true);
  const closePopupHandler = () => setOpenPopup(false);

  const openDeletePopupHandler = () => setDeletePopup(true);
  const closeDeletePopupHandler = () => setDeletePopup(false);

  const [deleteFavoriteQuery] = useDeleteFavoriteMutation();

  const deleteFavorite = async () => {
    if (id) {
      deleteFavoriteQuery(id);
      setEntityId(0);
      closeDeletePopupHandler();
    }
  };

  return (
    <>
      <div
        data-test="favorite"
        className="cursor-pointer"
        role="presentation"
        onClick={entityId ? openDeletePopupHandler : openPopupHandler}
      >
        {!entityId ? (
          <LmIcon
            icon="outline-misc-star"
            color="var(--LM-neutrals-day-700)"
          />
        ) : (
          <LmTooltip
            withArrow
            placement="right"
            content={commentTooltip}
          >
            <LmIcon
              icon="filled-misc-star"
              color="var(--LM-neutrals-day-700)"
            />
          </LmTooltip>
        )}
      </div>
      <ModalFavorite
        setId={setId}
        show={openPopup}
        resetComment={entityId}
        entityId={props.entityId}
        setEntityId={setEntityId}
        typeEntity={props.typeEntity}
        entityTypeId={props.entityTypeId}
        setCommentTooltip={setCommentTooltip}
        title="Добавление в избранное"
        onCloseHandler={() => closePopupHandler()}
        currentEntityType={props.currentEntityType}
      />

      <Dialog
        dataTest="deleteFavoriteDialog"
        isOpenDialog={openDeletePopup}
        title={`Вы уверены, что хотите убрать ${props.typeEntity} из избранного?`}
        description={
          <div>
            Вы уверены, что хотите убрать{' '}
            <b>
              {props.typeEntity} {props.currentEntityType}
            </b>{' '}
            из избранного? Обратите внимание, что комментарий будет удален.
          </div>
        }
        variant="alert"
        buttonSecondaryText="Нет, вернуть"
        buttonPrimaryText="Да, убрать"
        onClickSeconadaryButton={closeDeletePopupHandler}
        onClickPrimaryButton={deleteFavorite}
      />
    </>
  );
};

export default Favorite;
