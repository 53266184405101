import React from 'react';
import { useFormikContext } from 'formik';
import FormikDatePicker from '../../../../../../components/formik/formik-datepicker';
import { Learner } from '../../../../../../types/learners';

type Props = {
  editMode: boolean;
};

export const Birthday = ({ editMode }: Props) => {
  const { values } = useFormikContext<Learner.Info>();

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Дата рождения {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <FormikDatePicker
            size="small"
            name="birthDate"
            showErrorImmediately
            fullWidth={false}
          />
        ) : values.birthDate ? (
          values.birthDate.toLocaleDateString('ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })
        ) : (
          'Не выбран'
        )}
      </div>
    </div>
  );
};
