import { Push } from '@mosru/esz_uikit';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { LmButton } from '@mes-ui/lemma';
import FormikCheckbox from '../../components/formik/formik-checkbox';
import FormikInput from '../../components/formik/formik-input';
import { SearchDisciplinesFormData } from '../../types/discipline';

type Props = {
  submitForm: (values: SearchDisciplinesFormData) => void;
  initialForm: SearchDisciplinesFormData;
};

const DisciplineSearch = ({ submitForm, initialForm }: Props) => {
  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialForm}
    >
      {(formikProps: FormikProps<SearchDisciplinesFormData>) => {
        const { handleSubmit, values, isSubmitting } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className="filter-search flex">
              <FormikInput
                placeholder="Поиск по дисциплинам..."
                value={values.query}
                leftInsideItem={{
                  icon: 'filled-internet-code-search',
                }}
                name="query"
                size="medium"
              />
              <Push
                size={12}
                orientation="horizontal"
              />
              <div
                style={{
                  minWidth: 184,
                }}
              >
                <FormikCheckbox
                  name="showArchive"
                  label="Отображать архивные"
                  resetAllPadding
                />
              </div>
              <Push
                size={24}
                orientation="horizontal"
              />
              <LmButton
                type="submit"
                loading={isSubmitting}
              >
                Начать поиск
              </LmButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default DisciplineSearch;
