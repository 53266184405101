import { EducationTypeEnum } from './education-type';
import { UserIdEnum } from '../mock-data/access-enum';

export type AuthorizationDataIdentity = {
  /** Токен пользователя */
  name: string;
  /** Тип аутентификации */
  authenticationType?: any;
  /** Аутентифицирован */
  isAuthenticated: boolean;
};

/** Описание операции доступа к объекту (новая ролевая модель) */
export type ObjectAccessAction = {
  /** объект доступа */
  accessObject: number;
  /** Тип образования */
  educationType?: EducationTypeEnum;
  /** операция доступа */
  accessAction: number;
  parentAccessObject: number;
};

// ФИО
export type FullNameData = {
  // Имя
  firstName: string;
  // Отчество
  middleName: string;
  // Фамилия
  lastName: string;
};

export type AuthorizationData = {
  /** Id пользователя */
  id: number;
  /** Логин пользователя */
  login: string;
  /** JWT токен аторизации */
  token: string;
  /** Имя пользователя */
  userName: string;
  /** IP-адрес пользователя */
  userIP: string;
  /** Логин администратора, осуществляющего имперсонацию */
  impersonationLogin: string;
  /** Id организации */
  organizationId: number;
  /** Название организации */
  organizationName: string;
  /** Id ведомства */
  vedomstvoId?: number;
  /** Название департамента */
  vedomstvoName: string;
  /** ФИО */
  fullName: FullNameData;
  /** СНИЛС */
  snils: string;
  /** Список общих прав */
  generalAccessList: Array<number>;
  /** список операций доступа к объектам */
  objectAccessActionList: Array<ObjectAccessAction>;
  identity: AuthorizationDataIdentity;
  email: string;
  roles: Role[];
  tokenAupd: string;
  userTypeId: UserIdEnum;
};

export type Role = {
  id: number;
  name: string;
};

// Действия
export const accessAction = {
  // Просмотр
  View: 1,
  // Редактирование
  Edit: 2,
  // Создание
  Create: 3,
  // Отправка в архив
  Archive: 4,
  // Просмотр реестра
  ViewRegistry: 5,
  // Восстановление обучающегося в группе
  Recover: 6,
  // Построение отчета в АИС \"Зачисление в УДО\"
  CreateReport: 7,
  // Создание по шаблону
  CreateByTemplate: 8,
  // Построение отчетов с использованием тега iFrame
  CreateReportUsingIFrame: 9,
};

// Формы
export const accessObject = {
  // Группы
  ServiceClasses: 1,
  // Личные дела
  Pupils: 2,
  // Организации
  Organizations: 3,
  // Группа детского объединения ДОНМ
  ServiceClassDOGM: 4,
  // Группа образовательной программы
  ServiceClassOP: 5,
  // Группа программы по присмотру и уходу
  ServiceClassDayCare: 6,
  // Группа спортивной секции
  ServiceClassSport: 7,
  // Группа дома творчества
  ServiceClassArtHouse: 8,
  // Группа детского объединения
  ServiceClassNonDOGM: 9,
  // Обучающийся в детском объединении ДОНМ
  PupilDOGM: 10, // 0x0000000A
  // Образовательная организация
  EducationalOrganization: 11, // 0x0000000B
  // Общие права
  Generic: 12, // 0x0000000C
  // Использование ЭП
  Sign: 13, // 0x0000000D
  // Интеграция с внешними системами
  Integration: 14, // 0x0000000E
  // Отчеты
  Reports: 15, // 0x0000000F
  // Количество выданных документов о прохождении обучения
  AccessReport1: 16, // 0x00000010
  // Количество обучающихся по образовательным программам и по модулям ОП
  AccessReport2: 17, // 0x00000011
  // Количество программ и модулей всего и в каждой программе
  AccessReport3: 18, // 0x00000012
  // Сведения о статусе обучения по программам профподготовки
  AccessReport4: 19, // 0x00000013
  // Охват детей, состоящих на внутришкольном учете, программами дополнительного образования
  AccessReport5: 20, // 0x00000014
  // О переходе обучающихся по уровням обучения в разрезе направленностей
  AccessReport6: 21, // 0x00000015
  // Количество обучающихся по образовательным программам
  AccessReport7: 22, // 0x00000016
  // Количество обучающихся по интенсивным образовательным программам
  AccessReport8: 23, // 0x00000017
  // Количество обучающихся, не сдавших квалификационный экзамен
  AccessReport9: 24, // 0x00000018
  // Витрина сохраненных отчетов
  AccessReport10: 25, // 0x00000019
  // Список обучающихся, состоящих на внутришкольном учете и не получающих дополнительное образование
  AccessReport11: 26, // 0x0000001A
  // Численность детей в возрасте от 5 до 18 лет (уникальных), осваивающих программы дополнительного образования детей в УДО
  AccessReport12: 27, // 0x0000001B
  // Число \"человеко-кружков\" по детям в возрасте от 5 до 18 лет, осваивающим программы дополнительного образования детей в УДО
  AccessReport13: 28, // 0x0000001C
  // Численность детей в возрасте от 5 до 18 лет (уникальных), осваивающих программы дополнительного образования детей в колледжах
  AccessReport14: 29, // 0x0000001D
  // Число \"человеко-кружков\" по детям в возрасте от 5 до 18 лет, осваивающим программы дополнительного образования детей в колледжах
  AccessReport15: 30, // 0x0000001E
  // Численность детей в возрасте от 5 до 18 лет (уникальных), осваивающих программы дополнительного образования детей в школах
  AccessReport16: 31, // 0x0000001F
  // Число \"человеко-кружков\" по детям в возрасте от 5 до 18 лет, осваивающим программы дополнительного образования детей в школах
  AccessReport17: 32, // 0x00000020
  // Охват детей в возрасте от 5 до 18 лет (уникальных) программами дополнительного образования детей
  AccessReport18: 33, // 0x00000021
  // Охват детей в возрасте от 5 до 18 лет (уникальных) программами дополнительного образования детей (ДСиТ и ДКгМ)
  AccessReport19: 34, // 0x00000022
  // Охват детей в возрасте от 5 до 18 лет \"человеко-кружками\" по программам дополнительного образования
  AccessReport20: 35, // 0x00000023
  // Охват детей в возрасте от 5 до 18 лет \"человеко-кружками\" по программам дополнительного образования (ДСиТ и ДКгМ)
  AccessReport21: 36, // 0x00000024
  // Сводный отчет
  AccessReport22: 37, // 0x00000025
  // Отчет \"По городу\"
  AccessReport23: 38, // 0x00000026
  // По направленности
  AccessReport24: 39, // 0x00000027
  // Численность обучающихся, осваивающих программы дополнительного образования детей, формирующие навыки для жизни, учебы и труда (человеко-кружков)
  AccessReport25: 40, // 0x00000028
  // Перечень кружков
  AccessReport26: 41, // 0x00000029
  // Количество кружков по направленности
  AccessReport27: 42, // 0x0000002A
  // Количество программ по направленности
  AccessReport28: 43, // 0x0000002B
  // Количество уникальных обучающихся по направленностям
  AccessReport29: 44, // 0x0000002C
  // Количество человеко-кружков по направленностям
  AccessReport30: 45, // 0x0000002D
  // Степень выполнения государственного задания Департамента спорта и туризма г. Москвы
  AccessReport31: 46, // 0x0000002E
  // Перечень образовательных организаций, реализующих дополнительные общеобразовательные программы, формирующие умения и навыки для учебы, жизни и труда
  AccessReport32: 47, // 0x0000002F
  // По типам ОО
  AccessReport33: 48, // 0x00000030
  // Список обучающихся
  AccessReport34: 49, // 0x00000031
  // Сведения о численности контингента образовательной организации, посещающего группы по присмотру и уходу за детьми школьного возраста
  AccessReport35: 50, // 0x00000032
  // Обучающиеся групп по присмотру и уходу за детьми школьного возраста
  AccessReport36: 51, // 0x00000033
  // Отчет \"Ведомость учащихся (кружки)\"
  AccessReportDo37: 52, // 0x00000034
  // Отчет \"По городу(в разрезе направлений)\"
  AccessReport38: 53, // 0x00000035
  // Состояние обработки заявлений на обучение по программам дополнительного образования детей в разрезе направлений
  AccessReport39: 54, // 0x00000036
  // Состояние обработки заявлений на обучение по программам дополнительного образования детей
  AccessReport40: 55, // 0x00000037
  // Заявления
  Requests: 56, // 0x00000038
  // Заявление в детское объединение ДОГМ
  RequestDOGM: 57, // 0x00000039
  // Заявление на образовательную программу
  RequestOP: 58, // 0x0000003A
  // Заявление в группу по присмотру и уходу
  RequestDayCare: 59, // 0x0000003B
  // Заявление в спортивную секцию
  RequestSport: 60, // 0x0000003C
  // Заявление на образовательную услугу дома творчества
  RequestArtHouse: 61, // 0x0000003D
  // Заявление в детское объединение
  RequestNonDOGM: 62, // 0x0000003E
  // Программы
  Services: 63, // 0x0000003F
  // Детское объединение ДОГМ
  ServiceDOGM: 64, // 0x00000040
  // Детское объединение
  ServiceNonDOGM: 65, // 0x00000041
  // Образовательная программа
  ServiceOP: 66, // 0x00000042
  // Программа по присмотру и уходу
  ServiceDayCare: 67, // 0x00000043
  // Спортивная секция
  ServiceSport: 68, // 0x00000044
  // Образовательная услуга дома творчества
  ServiceArtHouse: 69, // 0x00000045
  // Услуга московского долголетия
  ServiceAD: 70, // 0x00000046
  // Заявление на услугу московского долголетия
  RequestAD: 71, // 0x00000047
  // Шаблоны
  Templates: 72, // 0x00000048
  // Шаблон спортивной секции
  TemplateSport: 73, // 0x00000049
  // Шаблон образовательной услуги дома творчества
  TemplateArtHouse: 74, // 0x0000004A
  // Группа Преподаватели
  TeachersGroup: 75, // 0x0000004B
  // Преподаватели
  Teachers: 76, // 0x0000004C
  // Уведомления
  Event: 77, // 0x0000004D
  // Справочники
  Directory: 78, // 0x0000004E
  // Справочник дисциплин
  Discipline: 79, // 0x0000004F
  // Преподаватели программ МД
  TeachersAD: 80, // 0x00000050
  // Документы
  Documents: 81, // 0x00000051
  // Документ о прохождении обучения
  CompletionDocument: 82, // 0x00000052
  // Группа программы МД
  ServiceClassAD: 83, // 0x00000053
  // Отчет \"Ведомость учащихся (образовательные программы)\"
  AccessReportOp37: 84, // 0x00000054
  // Отчет \"Ведомость учащихся (группы по присмотру и уходу)\"
  AccessReportDayCare37: 85, // 0x00000055
  // Обучающийся профобучения
  PupilOP: 86, // 0x00000056
  // Обучающийся на программе по присмотру и уходу
  PupilDayCare: 87, // 0x00000057
  // Обучающийся в спортивной секции
  PupilSport: 88, // 0x00000058
  // Обучающийся дома творчества
  PupilArtHouse: 89, // 0x00000059
  // Обучающийся детского объединения
  PupilNonDOGM: 90, // 0x0000005A
  // Обучающийся цифрового репетитора
  PupilVA: 91, // 0x0000005B
  // Группа цифрового репетитора
  ServiceClassVA: 92, // 0x0000005C
  // Заявление на услугу цифровой репетитор
  RequestVA: 93, // 0x0000005D
  // Услуга цифровой репетитор
  ServiceVA: 94, // 0x0000005E
};

// Общие права
export const generalAccess = {
  // Доступ к информации подведомственных организаций
  VedomstvoOIV: 1,
  // Администрирование. Просмотр
  AdminView: 2,
  // Администрирование. Редактирование
  AdminEdit: 3,
  // Доступ к пилотируемому функционалу
  EarlyAdopter: 4,
  // Использование ЭП
  UseSign: 5,
  // Использование фиктивной ЭП
  UseFakeSign: 6,
  // Применение / отмена изменений РБНДО и МВК
  ApplyClassificatorChanges: 7,
  // Внесение заявлений без бронирования
  RequestWithoutBooking: 8,
  // Проверка льгот в ИС РМСП
  CheckPrivileges: 12, // 0x0000000C
  // Возможность выгрузки результатов поиска витрин в Excel
  UnloadInExcel: 16, // 0x00000010
  // Уведомление о новых записях на тестирование в два и более кружка ДСиТ одного заявителя
  EventRibbon: 17, // 0x00000011
  // Изменение организации текущего пользователя
  OverrideSelfOrganization: 18, // 0x00000012
  // Создание платных услуг
  CreatePaydableService: 19, // 0x00000013
  // Выбор типа посещения (онлайн/очное)
  SelectTypeOfAttendance: 20, // 0x00000014
  // Авторизация в МЭШ.Договор
  AuthorizationContracts: 21,
};
