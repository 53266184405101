import React, { useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, setLocale } from 'yup';
import { Panel } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { ServiceClassData, TrainingGroupItemType } from '../../../types/service-class';
import { EducationTypeEnum } from '../../../types/education-type';
import SimpleInput from '../../../components/fields/simple-input';
import ServiceField from '../components/fields/service';
import TrainingGroupField from '../components/fields/training-group';
import AddressField from '../components/fields/address';
import DateField from '../components/fields/date';
import PupilInGroupField from '../components/fields/pupil-in-group';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import serviceClassApi from '../../../lib/api/service-class';
import { ServiceClassContext } from '../service-class';
import { minCapacityValidation, validationCheckDate } from '../../../lib/utils/validation';
import { maxCommentLength } from '../../../lib/utils/service-class';
import { serviceClassStatusEnum } from '../../../mock-data/service-class-status';
import ParallelField from '../components/fields/parallel';
import WarningScheduleModal from '../components/modals/warning-schedule-modal';
import SavePanel from '../../../components/save-panel';

type Props = {
  setEditModeParent: React.Dispatch<React.SetStateAction<string | null>>;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DayCareServiceClassInfo: React.FC<Props> = ({ setEditModeParent, editMode, setEditMode }) => {
  const {
    serviceClassData,
    updateServiceClass,
    accessEditServiceClass,
    setStartDateSchedulePeriod,
    setEndDateSchedulePeriod,
    setCurrentValuesGroupDetails,
  } = useContext(ServiceClassContext);

  const initialErrors = useInitialErrors(serviceClassData, getValidationSchema(serviceClassData.capacity));
  // const [editMode, setEditMode] = useState<boolean>(!setEditModeParent);
  const [loading, setLoading] = useState(false);
  const [currentTrainingGroup, setCurrentTrainingGroup] = useState<TrainingGroupItemType | null>(null);
  const [showWarningScheduleModal, setShowWarningScheduleModal] = useState<boolean>(false);

  useEffect(() => {
    if (serviceClassData.trainStartDate && serviceClassData.trainEndDate) {
      setStartDateSchedulePeriod(new Date(serviceClassData.trainStartDate));
      setEndDateSchedulePeriod(new Date(serviceClassData.trainEndDate));
    }
  }, [serviceClassData, setEndDateSchedulePeriod, setStartDateSchedulePeriod]);

  const submit = async (data: ServiceClassData) => {
    if (data?.trainStartDate && data?.trainEndDate) {
      if (
        serviceClassData.trainStartDate !== data.trainStartDate ||
        serviceClassData.trainEndDate !== data.trainEndDate
      ) {
        setShowWarningScheduleModal(true);
      } else {
        setLoading(true);
        try {
          await serviceClassApi.updateServiceClass({
            ...data,
            capacity: Number(data.capacity),
          });
          updateServiceClass();
          setEditModeParent && setEditModeParent(null);
          setEditMode && setEditMode(false);
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <Formik
        onSubmit={(values, formikHelpers) => {
          submit(values);
          if (setCurrentValuesGroupDetails) {
            setCurrentValuesGroupDetails(values);
          }
          formikHelpers.setSubmitting(false);
        }}
        enableReinitialize
        initialValues={serviceClassData}
        validationSchema={getValidationSchema(serviceClassData.capacity)}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<ServiceClassData>) => {
          const { isValid, handleSubmit, submitForm, resetForm } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Panel
                title={() => <>Сведения о группе</>}
                headingControl={() => {
                  return !editMode &&
                    serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                    accessEditServiceClass ? (
                        <button
                          type="button"
                          onClick={() => {
                            setEditModeParent && setEditModeParent('group');
                            setEditMode && setEditMode(true);
                          }}
                          className="icon-group"
                        >
                          <span className="icon-group__icon">
                            <LmIcon
                              icon="filled-edit-edit"
                              size={20}
                              color="var(--LM-blue-200)"
                            />
                          </span>
                          <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                        </button>
                      ) : null;
                }}
              >
                <div className="container">
                  <div className="table-data">
                    <ServiceField
                      name="service"
                      editMode={editMode}
                      educationType={EducationTypeEnum.DayCareCentersEducation}
                      required
                      disabled={!!serviceClassData.included || serviceClassData.hasActiveLearners}
                    />

                    <ParallelField showRequiredIcon={editMode} />

                    <SimpleInput
                      label="Наименование группы"
                      name="name"
                      editMode={editMode}
                      required
                    />

                    <SimpleInput
                      name="code"
                      required
                      label="Код группы"
                    />

                    <AddressField
                      name="address"
                      editMode={editMode}
                      required
                      label="Адрес"
                      disabled={serviceClassData.hasActiveLearners}
                    />

                    <TrainingGroupField
                      name="trainingGroup"
                      editMode={editMode}
                      required
                      disabled={serviceClassData.hasActiveLearners}
                      setCurrentTrainingGroup={setCurrentTrainingGroup}
                    />
                    <DateField
                      required
                      name="train"
                      editMode={editMode}
                      label="Даты занятий"
                      calendarPosition="top-end"
                      currentTrainingGroup={currentTrainingGroup}
                      disabled={!!serviceClassData.included}
                    />

                    <PupilInGroupField
                      name="included"
                      label="Человек в группе"
                      editMode={editMode}
                      required
                    />

                    <SimpleTextArea
                      name="description"
                      editMode={editMode}
                      label="Комментарий"
                      placeholder="Введите..."
                      maxLength={maxCommentLength}
                    />
                  </div>
                </div>
              </Panel>

              {editMode && setEditModeParent && (
                <SavePanel
                  primaryButtonModifiers={{
                    loading,
                    disabled: !isValid,
                  }}
                  onClickSeconadaryButton={() => {
                    setEditModeParent && setEditModeParent(null);
                    setEditMode && setEditMode(false);
                    resetForm();
                  }}
                  onClickPrimaryButton={submitForm}
                />
              )}
            </form>
          );
        }}
      </Formik>
      {setEditMode && setEditModeParent && (
        <WarningScheduleModal
          onCloseHandler={() => setShowWarningScheduleModal(false)}
          show={showWarningScheduleModal}
          setEditModeParent={setEditModeParent}
          setEditMode={setEditMode}
        />
      )}
    </>
  );
};

export default DayCareServiceClassInfo;

setLocale({
  date: {
    max: 'Введённая дата является слишком большой',
    min: 'Введённая дата является слишком маленькой',
  },
});

const getValidationSchema = (initialCapacity?: number) => {
  const depsDate: [string, string] = ['trainStartDate', 'trainEndDate'];

  return objectYup().shape(
    {
      serviceId: stringYup().required('Выберите программу').nullable(),
      name: stringYup().nullable().required('Введите наименование группы').nullable(),
      address: stringYup().required('Введите адрес'),
      trainingGroupId: stringYup().required('Выберите план приема'),
      trainStartDate: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'trainStartDate',
          end: 'trainEndDate',
        },
        'Дата начала обучения больше даты окончания обучения',
        'start'
      ),
      trainEndDate: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'trainStartDate',
          end: 'trainEndDate',
        },
        'Дата окончания обучения меньше даты начала обучения',
        'end'
      ),
      capacity: minCapacityValidation(initialCapacity),
    },
    [depsDate]
  );
};
