import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object as objectYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikTextarea from '../../../components/formik/formik-textarea';
import FormikToggle from '../../../components/formik/formik-toggle';
import { notify } from '../../../redux/ducks/notifications';
import { RequestApproveData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import history from '../../../history';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import SavePanel from '../../../components/save-panel';

type Props = {
  setEditModeParent: (value: string | null) => void;
  approve: RequestApproveData;
  editable: boolean;
  requestId: number;
  updateRequest: () => void;
};

const Notify: React.FC<Props> = ({ setEditModeParent, approve, editable, requestId, updateRequest }) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(approve, getValidationSchema());

  const dispatch = useDispatch();

  const submitForm = useCallback(
    async (values: RequestApproveData) => {
      try {
        const id = await requestsApi.saveApprove(requestId, values);

        setEditModeParent(null);
        setEditMode(false);
        if (id !== requestId) {
          history.push(
            generateLink(routes.request, {
              id,
            })
          );
        } else {
          updateRequest();
        }
      } catch (ex: any) {
        dispatch(
          notify.danger({
            dataTest: 'notify',
            title: ex.data?.message,
          })
        );
      }
    },
    [requestId, setEditModeParent, updateRequest, dispatch]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => 'Уведомление о необходимости явки'}
        headingControl={() => {
          return !editMode && editable ? (
            <button
              type="button"
              onClick={() => {
                setEditModeParent('notify');
                setEditMode(true);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-edit"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        <Formik
          initialErrors={initialErrors}
          validationSchema={getValidationSchema()}
          onSubmit={submitForm}
          enableReinitialize
          initialValues={approve}
        >
          {(formikProps: FormikProps<RequestApproveData>) => {
            const { handleSubmit, submitForm, values, isSubmitting, isValid, resetForm } = formikProps;

            return (
              <form onSubmit={handleSubmit}>
                <div className="container">
                  <div className="table-data">
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Уведомить заявителя</div>
                      <div className="table-data__body">
                        <FormikToggle
                          disabled={!editMode || approve.isEnrollmentApproved}
                          name="isEnrollmentApproved"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Комментарий</div>
                      <div className="table-data__body word-break-all">
                        {editMode ? (
                          <FormikTextarea
                            name="enrollmentApproveComment"
                            maxRows={2}
                            placeholder="Введите текст комментария"
                            disabled={!editMode}
                          />
                        ) : (
                          values.enrollmentApproveComment || '—'
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {editMode && (
                  <SavePanel
                    primaryButtonModifiers={{
                      loading: isSubmitting,
                      disabled: !isValid,
                    }}
                    onClickPrimaryButton={submitForm}
                    onClickSeconadaryButton={() => {
                      setEditModeParent(null);
                      setEditMode(false);
                      resetForm();
                    }}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Panel>
    </>
  );
};

export default Notify;

const getValidationSchema = () => objectYup().shape({});
