import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/store';
import Routes from './containers/routes';
import GlobalStyle from './theme/global-styles';
import './assets/styles/app.sass';
import '@mes-ui/global-header/styles.css';
import '@mes-ui/global-footer/styles.css';
import GlobalHeader from './components/global-header';
import NotifyToastContainer from './components/notify-toast-container';

const App = () => {
  return (
    <>
      <GlobalStyle />
      <ReduxProvider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <GlobalHeader />
          <Routes />
          <NotifyToastContainer />
        </PersistGate>
      </ReduxProvider>
    </>
  );
};

export default App;
