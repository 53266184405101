import React, { useCallback, useEffect, useMemo, useState, Key } from 'react';
import { debounce, cloneDeep } from 'lodash';
import { Tree, TreeNodeType, Push } from '@mosru/esz_uikit';
import { LmInput, LmLoader } from '@mes-ui/lemma';
import SidePage from '../../../../../components/modals/side-page';
import { classificatorApi } from '../../../../../lib/api/classificator';
import { searchTreeElements } from '../../../../../components/tree-modal/helpers';
import { ClassificatorChildData, ClassificatorTreeData } from '../../../../../types/classificator';
import { getClassificatorList } from '../utils';

type CheckableTreeModalProps = {
  show: boolean;
  organizationId: number;
  educationTypeId: number;
  vedomstvoId?: number;
  yearOfTrainingId?: number;
  checkableKeys: Key[];
  onSave: (value: ClassificatorChildData[]) => void;
  onClose: (value: boolean) => void;
};

const CheckableTreeModal = ({
  show,
  organizationId,
  educationTypeId,
  yearOfTrainingId,
  vedomstvoId,
  checkableKeys,
  onSave,
  onClose,
}: CheckableTreeModalProps) => {
  const [initialData, setInitialData] = useState<TreeNodeType[]>([]);
  const [dataTree, setDataTree] = useState<ClassificatorTreeData[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);

  const convertToTreeNodeType = useCallback(
    (data: ClassificatorTreeData[]): TreeNodeType[] =>
      data.map(({ data, child }) => ({
        key: data.id,
        title: data.name,
        isLeaf: !child.length,
        children: convertToTreeNodeType(child),
        disabled: !!child.length,
        disableCheckbox: checkableKeys.includes(data.id),
        checkable: !child.length,
      })),
    [checkableKeys]
  );

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await classificatorApi.getClassificatorTree({
        educationTypeId,
        organizationId,
        yearOfTrainingId,
        vedomstvoId,
      });

      setDataTree(data);
      setInitialData(convertToTreeNodeType(data));
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  }, [educationTypeId, organizationId, yearOfTrainingId, vedomstvoId, convertToTreeNodeType]);

  useEffect(() => {
    if (show) {
      getData();
    } else {
      setCheckedKeys([]);
      setInitialData([]);
      setDataTree([]);
    }
  }, [show, getData]);

  const handleSearchChanged = useCallback(
    (value: string) => {
      const data = convertToTreeNodeType(dataTree);
      const copyArray = cloneDeep(data);
      const result = value ? searchTreeElements(value.toLowerCase().trim(), copyArray) : data;

      setInitialData(result);
      setLoading(false);
    },
    [dataTree, convertToTreeNodeType]
  );

  const debounceFn = useMemo(() => debounce(handleSearchChanged, DEBOUNCE_DELAY), [handleSearchChanged]);

  const handleDebouncedSearchChange = (value: Key) => {
    setLoading(true);
    setSearch(value as string);
    // @ts-ignore
    debounceFn(value as string);
  };

  const handleCheckedKey = (selectedKeys: Key[]) => {
    setCheckedKeys(selectedKeys.map((key) => Number(key)));
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleSave = () => {
    const list = getClassificatorList(dataTree);

    onSave(list.filter(({ id }) => checkedKeys.includes(id)));
    handleClose();
  };

  return (
    <SidePage
      dataTest="checkableTreeModal"
      open={show}
      title="Реестр профессий"
      description="Направленность > профиль > вид деятельности"
      primaryButtonModifiers={{
        disabled: !checkedKeys.length,
      }}
      classes={{
        body: loading ? 'flex flex-column' : undefined,
      }}
      onClose={handleClose}
      onSubmit={handleSave}
    >
      <LmInput
        dataTest="search"
        name="search"
        value={search}
        placeholder="Поиск..."
        leftInsideItem={{
          icon: 'filled-internet-code-search',
        }}
        classes={{
          wrapper: 'input-inside-item',
        }}
        resettable
        onChange={handleDebouncedSearchChange}
      />

      <Push size={16} />

      {loading ? (
        <div className="loader-auto">
          <LmLoader view="block" />
        </div>
      ) : (
        <Tree
          expandAll
          data={initialData}
          checkedKeys={checkedKeys}
          handleChange={handleCheckedKey}
        />
      )}
    </SidePage>
  );
};

export default CheckableTreeModal;

const DEBOUNCE_DELAY = 500;
