import React, { useEffect, useState } from 'react';
import { getUserCertificates, Certificate, createAttachedSignature } from 'crypto-pro';
import { useDispatch } from 'react-redux';
import { Heading, Push } from '@mosru/esz_uikit';
import { LmButton, LmInfoBox, LmRadio } from '@mes-ui/lemma';
import SwitcherForm from '../switcher';
import SelectCertificate from '../../../components/certificate';
import { authApi } from '../../../lib/api';
import { onSignIn } from '../../../redux/thunks/auth';

const Sign = () => {
  const [certificate, setCertificate] = useState<Certificate | null>(null);
  const [infoError, showInfoError] = useState(false);
  const [pluginError, showPluginError] = useState(false);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getCert = async () => {
      try {
        const certs = await getUserCertificates();

        setCertificates(certs);
      } catch (error) {
        showPluginError(true);
      }
    };

    getCert();
  }, []);

  const handleSubmit = async () => {
    if (certificate) {
      showInfoError(false);
      setIsSubmitting(true);
      //
      try {
        const sign = await createAttachedSignature(certificate.thumbprint, 'ui');
        const response = await authApi.loginBySign(sign);

        if (response.isLoggedIn) {
          await dispatch(onSignIn(response.token));
        } else {
          showInfoError(true);
        }
      } catch (e) {
        showInfoError(true);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Push size={8} />
      <SwitcherForm />
      <Push size={80} />
      <Heading
        label="Выбор сертификата"
        as="h3"
      />
      <Push size={16} />
      {certificates.length > 0 ? (
        certificates.map((cert) => (
          <LmRadio
            key={cert.name}
            boxSize="large"
            name={cert.thumbprint}
            dataTest={cert.thumbprint}
            onChange={() => setCertificate(cert)}
            checked={certificate?.thumbprint === cert.thumbprint}
          >
            <SelectCertificate
              name={cert.name}
              validTo={cert.validTo}
            />
          </LmRadio>
        ))
      ) : pluginError ? (
        <LmInfoBox
          dataTest="pluginError"
          variant="alert"
          hidenFooter
          description={
            <>
              Ошибка загрузки плагина «КриптоПро ЭЦП Browser plug-in». Для входа с помощью электронной подписи требуется
              установленный на компьютер плагин «КриптоПро ЭЦП Browser plug-in». Вы можете загрузить его, перейдя по
              ссылке:{' '}
              <a href="https://cryptopro.ru/products/cades/plugin/get_2_0">Скачать КриптоПро ЭЦП Browser plug-in</a>
            </>
          }
        />
      ) : (
        <span className="auth-not-certificate">У вас нет доступных для выбора сертификатов.</span>
      )}

      <Push size={16} />
      <LmButton
        dataTest="ready"
        type="button"
        loading={isSubmitting}
        disabled={!certificate}
        size="medium"
        widthFull
        onClick={handleSubmit}
      >
        Готово
      </LmButton>

      {infoError && (
        <>
          <Push size={20} />
          <LmInfoBox
            dataTest="signatureFileError"
            variant="alert"
            hidenFooter
            description="Некорректный файл электронной подписи. Выберите другой файл"
          />
        </>
      )}
    </>
  );
};

export default Sign;
