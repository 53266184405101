import React from 'react';
import FormikSelect from '../../../../../../../components/formik/formik-select';
import { findDocument, selectedDefaultDocument } from '../../../../../../../lib/utils/learners';
import { DocumentDataFinishedAndTypes, PropsDocumentType } from '../../../../../../../types/learners';

type Props = {
  docTypeId: number;
  newDocument: boolean;
  documentDataFinishedAndTypes: DocumentDataFinishedAndTypes;
};

export const DocumentType: React.FC<Props> = ({ docTypeId, newDocument, documentDataFinishedAndTypes }) => {
  const documents = documentDataFinishedAndTypes?.type?.map((item: PropsDocumentType) => ({
    label: item.name,
    value: item.id,
  }));

  const defaultTypeDocument = {
    label: 'Выберите тип документа',
    value: 0,
  };

  const document = findDocument(documentDataFinishedAndTypes.type, docTypeId);

  return (
    <FormikSelect
      size="small"
      withSearch
      required
      name="docTypeId"
      label="Тип документа"
      disabled={!newDocument}
      defaultValue={newDocument ? documents[0] : selectedDefaultDocument(document, defaultTypeDocument)}
      options={newDocument ? [documents[0]] : []}
      placeholder="Выберите тип документа"
    />
  );
};
