import React, { useContext, useMemo, useState } from 'react';
import { InteractiveChevronTemplate, LmButton, LmListItem } from '@mes-ui/lemma';
import SavePanel from '../../../../components/save-panel';
import Expulsion from '../../components/modals/expulsion';
import TransferGroup from '../../components/modals/transfer-group';
import RecoverySimple from '../../components/modals/recovery-simple';
import TransferStage from '../../components/modals/transfer-stage';
import TransferNextStage from '../../components/modals/transfer-next-stage';
import Specialization from '../../components/modals/specialization';
import DropDown from '../../../../components/drop-down';
import Union from '../../components/modals/union';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, learners, selected, openDetails, active } = useContext(ServiceClassTableContext);

  const { accessRecover } = useContext(ServiceClassContext);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);

  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferStagePopup, setOpenTransferStagePopup] = useState<boolean>(false);

  const [openTransferNextStagePopup, setOpenTransferNextStagePopup] = useState<boolean>(false);

  const [openSpecializationPopup, setOpenSpecializationPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);

  const [openUnionPopup, setOpenUnionPopup] = useState<boolean>(false);

  const disableStage = useMemo(() => {
    const selectedLearners = learners.filter((value) => selected.includes(value.id));

    return selectedLearners.some((item) => item.serviceId !== selectedLearners[0].serviceId);
  }, [learners, selected]);

  const handleDeselect = () => {
    setSelected([]);
    setOpenDetails(false);
  };

  const showExpulsionModal = () => {
    setOpenExpulsionPopup(true);
  };

  const showSpecializationModal = () => {
    setOpenSpecializationPopup(true);
  };

  const showUnionModal = () => {
    setOpenUnionPopup(true);
  };

  const showRecoveryModal = () => {
    setOpenRecoveryPopup(true);
  };

  return (
    <>
      {' '}
      {openDetails && (
        <SavePanel
          hideSecondaryButton
          hidePrimaryButton
        >
          <LmButton
            dataTest="cancelSelection"
            type="button"
            variant="text"
            size="medium"
            onClick={handleDeselect}
          >
            Отменить выбор
          </LmButton>
          {active ? (
            <>
              <LmButton
                dataTest="expel"
                type="button"
                color="danger"
                variant="outline"
                size="medium"
                onClick={showExpulsionModal}
              >
                Отчислить
              </LmButton>

              <LmButton
                dataTest="changeSpecialization"
                type="button"
                variant="outline"
                size="medium"
                onClick={showSpecializationModal}
              >
                Изменить специализацию
              </LmButton>

              <LmButton
                dataTest="union"
                type="button"
                variant="outline"
                size="medium"
                onClick={showUnionModal}
              >
                Объединить в подгруппу
              </LmButton>

              <DropDown
                dataTest="transfer"
                placement="top"
                component={(open) => (
                  <LmButton
                    dataTest="transfer"
                    type="button"
                    variant="outline"
                    size="medium"
                  >
                    Перевести
                    <InteractiveChevronTemplate open={open} />
                  </LmButton>
                )}
              >
                <>
                  <LmListItem
                    dataTest="otherGroup"
                    text="в другую группу обучения"
                    onClick={() => setOpenTransferGroupPopup(true)}
                  />
                  {disableStage || (
                    <LmListItem
                      dataTest="otherStage"
                      text="на другой этап"
                      onClick={() => setOpenTransferStagePopup(true)}
                    />
                  )}
                  <LmListItem
                    dataTest="nextStage"
                    text="на следующий этап"
                    onClick={() => setOpenTransferNextStagePopup(true)}
                  />
                </>
              </DropDown>
            </>
          ) : (
            accessRecover && (
              <LmButton
                dataTest="recover"
                type="button"
                variant="outline"
                size="medium"
                onClick={showRecoveryModal}
              >
                Восстановить
              </LmButton>
            )
          )}
        </SavePanel>
      )}
      <RecoverySimple
        open={openRecoveryPopup}
        close={() => setOpenRecoveryPopup(false)}
      />
      <Expulsion
        open={openExpulsionPopup}
        close={() => setOpenExpulsionPopup(false)}
      />
      <TransferStage
        open={openTransferStagePopup}
        close={() => setOpenTransferStagePopup(false)}
      />
      <Union
        open={openUnionPopup}
        close={() => setOpenUnionPopup(false)}
      />
      <TransferNextStage
        open={openTransferNextStagePopup}
        close={() => setOpenTransferNextStagePopup(false)}
      />
      <Specialization
        open={openSpecializationPopup}
        close={() => setOpenSpecializationPopup(false)}
      />
      <TransferGroup
        open={openTransferGroupPopup}
        close={() => setOpenTransferGroupPopup(false)}
      />
    </>
  );
};

export default PanelButtons;
