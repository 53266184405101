import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import FormikSelect from '../formik/formik-select';
import Field, { FieldProps } from './field';
import { dictionariesApi } from '../../lib/api/dictionaries';
import { SelectOptionType } from '../../types/entities';

type GenderProps = FieldProps<string> & {
  size?: 1 | 2 | 3;
  disabled?: boolean;
  parent?: string;
};
export const genderIdField = 'sexId';
export const genderNameField = 'sexName';

const Gender = ({
  label = 'Пол',
  name,
  editMode,
  required,
  size = 3,
  disabled = false,
  parent,
  ...props
}: GenderProps) => {
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);
  const [optionsSet, setOptionsSet] = useState<boolean>(false);
  const fieldId = parent ? `${parent}.${name}Id` : `${name}Id`;
  const fieldName = parent ? `${parent}.${name}Name` : `${name}Name`;
  const [lab, , helpersLabel] = useField(fieldName);
  const [id, , helpersId] = useField(fieldId);

  useEffect(() => {
    const fetchOptions = async () => {
      setOptionsData(await dictionariesApi.getGenders());
      setOptionsSet(true);
    };

    if (editMode && !optionsSet) {
      fetchOptions();
    }
  }, [editMode, optionsSet]);

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={lab.value}
      size={size}
      {...props}
    >
      <FormikSelect
        name={fieldId}
        label=""
        required
        size="small"
        placeholder="Выберите..."
        options={optionsData}
        disabled={disabled}
        selectedValue={(selected?: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : undefined);
          helpersId.setValue(selected ? selected.value : undefined);
        }}
        defaultValue={
          lab?.value && id?.value
            ? {
                value: id.value,
                label: lab.value,
              }
            : null
        }
      />
    </Field>
  );
};

export default Gender;
