import React, { useContext, useState } from 'react';
import SecurityHistory from '../../../components/change-history';
import ChangeHistorySearch from '../../../components/change-history/search';
import ViewContainer from '../../../components/view-container';
import { initialChangeHistoryFilterFormData } from '../../../mock-data/change-history-filter';
import { entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import { HistoryFilterFormData } from '../../../types/history';
import { ServiceClassContext } from '../service-class';
import Enrollments from '../../../components/change-history/enrollments';
import TransfersGroup from '../../../components/change-history/transfers-group';
import Expulsions from '../../../components/change-history/expulsions';
import Recoveries from '../../../components/change-history/recoveries';
import TransfersService from '../../../components/change-history/transfers-service';
import TransfersStage from '../../../components/change-history/transfers-stage';
import StartEndDateChange from '../../../components/change-history/start-end-date-change';

const ChangeHistory: React.FC = () => {
  const { serviceClassData } = useContext(ServiceClassContext);
  const [searchData, setSearchData] = useState<HistoryFilterFormData>(initialChangeHistoryFilterFormData);

  return (
    <ViewContainer
      filterComponent={
        <ChangeHistorySearch
          searchData={searchData}
          onChangeSearch={setSearchData}
          entityTypes={entityTypesForHistory.serviceClasses}
        />
      }
    >
      <SecurityHistory
        name="сведения о группе"
        extendEntityGuid={serviceClassData.extendEntityGuid}
        entityTypes={entityTypesForHistory.serviceClasses}
        defaultLoading
        noFilter
        search={searchData}
      />
      <Enrollments
        type="group"
        id={serviceClassData.id ?? 0}
      />
      <TransfersGroup
        type="group"
        id={serviceClassData.id ?? 0}
      />
      <Expulsions
        type="group"
        id={serviceClassData.id ?? 0}
      />
      <Recoveries
        type="group"
        id={serviceClassData.id ?? 0}
      />
      <TransfersService
        type="group"
        id={serviceClassData.id ?? 0}
        educationType={serviceClassData.educationTypeId}
      />
      <TransfersStage
        type="group"
        id={serviceClassData.id ?? 0}
      />
      <StartEndDateChange
        type="group"
        id={serviceClassData.id ?? 0}
      />
    </ViewContainer>
  );
};

export default ChangeHistory;
