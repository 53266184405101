import React from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../config/constants';
import TemplateServicesCreateDetails from './create/details';
import { goBack } from '../../../lib/utils';

const TemplateServicesCreate = () => {
  return (
    <>
      <LmSubHeader
        sticky
        description=""
        title="Новый шаблон услуги"
        dataTest="templateServiceCreateSubHeader"
        arrowOnClick={() => goBack(routes.myTemplates)}
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Шаблоны услуг',
            link: routes.myTemplates,
          },
          {
            label: 'Описание шаблона услуги',
          },
        ]}
      />
      <TemplateServicesCreateDetails />
    </>
  );
};

export default TemplateServicesCreate;
