import React from 'react';
import { Link } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import Dialog from '../../../../components/modals/dialog';
import { routes } from '../../../../config/constants';
import { generateLink } from '../../../../lib/utils';
import { DuplicateData } from '../../../../types/learners';

type Props = {
  show: boolean;
  duplicates: DuplicateData[];
  onCloseHandler: () => void;
};

const DuplicateModal = ({ show, duplicates, onCloseHandler }: Props) => {
  return (
    <Dialog
      dataTest="duplicateLearner"
      isOpenDialog={show}
      title="Такой обучающийся уже существует"
      description={
        <>
          <div>С введенными данными найден обучающийся:</div>
          {duplicates.map((item) => {
            return (
              <div>
                <Push
                  orientation="vertical"
                  size={8}
                />
                <Link
                  to={generateLink(routes.learner, {
                    id: item.Id,
                  })}
                  target="_blank"
                  className="brand-link font-size-small"
                >
                  {item.FullName}
                </Link>
              </div>
            );
          })}
          <Push
            orientation="vertical"
            size={32}
          />
          <div>Если необходимо связать сведения обучающихся, воспользуйтесь механизмом объединения личных дел.</div>
        </>
      }
      variant="information"
      buttonSecondaryText="Закрыть"
      onClickSeconadaryButton={onCloseHandler}
    />
  );
};

export default DuplicateModal;
