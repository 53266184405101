import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { OrderByTypeRequest, PaginationRequest, PagingList, Response, SelectOptionType } from '../../types/entities';
import {
  SearchLearnersInitialFormData,
  EducationFact,
  FinishedTypeDocument,
  Learner,
  RegistryLearner,
} from '../../types/learners';
import { downloadBase64File, transformSelectOptions } from '../utils';
import { OperationResultEnum } from '../../mock-data/operation-result-enum';
import { DocumentModalMoscow } from '../../types/document-modal-moscow';
import { DocumentModalOtherCity, FileData } from '../../types/document-modal-other';
import { ListPage, ServiceClassHistoryData } from '../../types/service-class';
import { sendReachGoal } from '../metrica';

type GetLearnersProps = SearchLearnersInitialFormData & OrderByTypeRequest & PaginationRequest;

export const getLearnersExcel = async (data: GetLearnersProps): Promise<Response<FileData>> => {
  const resp = await fetchRequest.post(`${apiService.data}/Learner/ToExcel`, data);

  downloadBase64File(resp.data.content, resp.data.data, resp.data.name);

  return resp;
};

export const postLearners = async (data: SearchLearnersInitialFormData): Promise<PagingList<RegistryLearner>> => {
  sendReachGoal('learner-search');

  return fetchRequest.post(`${apiService.data}/Learner/Search`, data);
};

export const getLearner = async (
  data: { pupilId: string; showExcluded?: boolean },
  showAccessDeny = true
): Promise<Learner.Data> => {
  return fetchRequest.get(`${apiService.data}/Learner`, data, {
    showAccessDeny,
  });
};

export const putLearner = async (data: Partial<Learner.Data>): Promise<number & { message?: string }> => {
  return await fetchRequest.put(`${apiService.data}/Learner`, data);
};

export const patchLearner = async (learnerId: number): Promise<Response<number>> => {
  try {
    fetchRequest.patch(`${apiService.data}/Learner/Restoration/${learnerId}`, null);

    return {
      data: learnerId,
      resultCode: OperationResultEnum.Success,
      errorMessage: '',
    };
  } catch (ex: any) {
    return {
      data: 0,
      resultCode: OperationResultEnum.Error,
      errorMessage: ex.message,
    };
  }
};

export const learnerMerge = async (
  id: number | string,
  data: (number | string)[]
): Promise<Response<Promise<null>>> => {
  return fetchRequest.post(`${apiService.data}/Learner/Merge?actualPupilId=${id}`, data);
};

export const getListRequest = async (data: { id: string; edType?: number }): Promise<Learner.ListStatementsProps[]> => {
  return fetchRequest.get(`${apiService.data}/Learner/${data.id}/Request/List`, data);
};

export const getEdFacts = async (contGuid: string): Promise<EducationFact[]> => {
  return fetchRequest.get(`${apiService.data}/Learner/Education/List/${contGuid}`);
};

export const getDocuments = async (data: { id: string }): Promise<Learner.Document[]> => {
  return fetchRequest.get(`${apiService.data}/Learner/${data.id}/Document/List`, {});
};

export const deleteLearner = async (data: { id: string }): Promise<Response<string | undefined>> =>
  fetchRequest.delete(`${apiService.data}/Learner/${data.id}`);

export const patchDocumentRestore = async (pupilId: string, docId: number): Promise<number> => {
  return fetchRequest.patch(`${apiService.data}/Learner/${pupilId}/Document/${docId}/Restoration`, {});
};

export const deleteDocument = async (pupilId: string, data: { docId: string }): Promise<number> => {
  return fetchRequest.delete(`${apiService.data}/Learner/${pupilId}/Document`, data);
};

export const getDocument = async (data: { pupilId: string; docId: string }): Promise<DocumentModalMoscow.Data> => {
  return fetchRequest.get(`${apiService.data}/Learner/${data.pupilId}/Document/${data.docId}`);
};

export const putDocument = async (data: Partial<DocumentModalMoscow.Data>): Promise<number> => {
  return fetchRequest.put(`${apiService.data}/Learner/${data.pupilId}/Document/${data.id}`, data);
};

export const postDocument = async (data: Partial<DocumentModalMoscow.Data>): Promise<number> => {
  return fetchRequest.post(`${apiService.data}/Learner/${data.pupilId}/Document`, data);
};

export const getDocumentOtherCity = async (
  pupilId: string,
  data: { id: string }
): Promise<DocumentModalOtherCity.InitialDataDocumentOtherCity> => {
  return fetchRequest.get(`${apiService.data}/Learner/${pupilId}/OtherCityDocument/${data.id}`);
};

export const putDocumentOtherCity = async (data: Partial<DocumentModalOtherCity.Data>): Promise<number> => {
  return fetchRequest.put(`${apiService.data}/Learner/${data.pupilId}/OtherCityDocument/${data.id}`, data);
};

export const postDocumentOtherCity = async (data: Partial<DocumentModalOtherCity.Data>): Promise<number> => {
  return fetchRequest.post(`${apiService.data}/Learner/${data.pupilId}/OtherCityDocument`, data);
};

export const postFileUploadOtherCity = async (pupilId: string, docId: number, docFile: FormData): Promise<FileData> => {
  return fetchRequest.file(`${apiService.data}/Learner/${pupilId}/OtherCityDocument/${docId}/File`, docFile);
};

export const deleteFileOtherCity = async (
  pupilId: string,
  docId: number,
  data: { fileId: number }
): Promise<number> => {
  return fetchRequest.delete(`${apiService.data}/Learner/${pupilId}/OtherCityDocument/File/${data.fileId}`);
};

export const getDocumentTypes = async (): Promise<PagingList<{ id: number; name: string; isArchive: boolean }>> => {
  return fetchRequest.get(`${apiService.data}/Dictionary/DocumentType/List`, {
    formTypeId: 7,
  });
};

export const getDocumentProgramModules = async (data: {
  serviceId: string | boolean | number | null;
}): Promise<SelectOptionType[]> => {
  const list = await fetchRequest.get(`${apiService.data}/Service/${data.serviceId}/ProgrammModule/List`);

  return transformSelectOptions(list);
};

export const getDocumentFinishedServices = async (data: {
  pupilId: number | undefined | string;
}): Promise<FinishedTypeDocument[]> => {
  return fetchRequest.get(`${apiService.data}/Learner/${data.pupilId}/FinishedService/List`);
};

export const patchDocumentReAccept = async (
  pupilId: number,
  data: {
    docId: number;
    catalogId: number;
    moduleId: number;
  }
): Promise<Response<number>> => {
  return fetchRequest.patch(`${apiService.data}/Learner/${pupilId}/Document/Reaccept`, data);
};

export const postRepresentative = async (
  pupilId: number,
  data: Omit<Learner.Representative, 'id'> & { pupilId: number }
): Promise<number> => await fetchRequest.post(`${apiService.data}/Learner/Representative/?pupilId=${pupilId}`, data);

export const putRepresentative = async (
  pupilId: number,
  data: Learner.Representative & { pupilId: number }
): Promise<number> => {
  return await fetchRequest.put(`${apiService.data}/Learner/Representative/${pupilId}`, data);
};

export const deleteRepresentative = async (id: number, pupilId: number) =>
  await fetchRequest.delete(`${apiService.data}/Learner/Representative/${id}?pupilId=${pupilId}`);

export const getHistory = async (
  pupilId: number,
  statusId: number,
  pageNumber = 1,
  pageSize = 10
): Promise<ListPage<ServiceClassHistoryData>> =>
  fetchRequest.get(`${apiService.data}/Learner/${pupilId}/History`, {
    statusId,
    pageNumber,
    pageSize,
  });

const learnerApi = {
  postLearners,
  getDocument,
  getEdFacts,
  putLearner,
  getHistory,
  getLearner,
  putDocument,
  learnerMerge,
  getDocuments,
  patchLearner,
  postDocument,
  deleteLearner,
  getListRequest,
  deleteDocument,
  getLearnersExcel,
  getDocumentTypes,
  putRepresentative,
  deleteRepresentative,
  deleteFileOtherCity,
  putDocumentOtherCity,
  patchDocumentRestore,
  getDocumentOtherCity,
  postDocumentOtherCity,
  patchDocumentReAccept,
  postFileUploadOtherCity,
  getDocumentProgramModules,
  getDocumentFinishedServices,
  postRepresentative,
};

export default learnerApi;
