import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
// @ts-ignore
import Files from 'react-files';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmIcon } from '@mes-ui/lemma';
import { ServiceDataInfo, ServiceFileTextType } from '../../../../types/service';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ReactFilesError, ReactFilesFile } from '../../../../types/files';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { blobToBase64, getFileNameFromDisposition } from '../../../../lib/utils/reports';

type Props = {
  editMode: boolean;
  ruleFile: ServiceFileTextType;
  setRuleFile: React.Dispatch<React.SetStateAction<ServiceFileTextType>>;
  parent?: string;
  disabled?: boolean;
};

export const RuleService: React.FC<Props> = ({ editMode, ruleFile, setRuleFile, parent, disabled }) => {
  const { values } = useFormikContext<ServiceDataInfo>();
  const getFieldFullName = useCallback((name: string) => (parent ? `${parent}.${name}` : name), [parent]);
  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;

      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  const downloadFile = async (id: string) => {
    const file = await serviceTemplateApi.getServiceFile(getFieldValue('serviceId'), id);

    if (file) {
      const downloadLink = document.createElement('a');

      downloadLink.href = (await blobToBase64(file.blob)) as string;
      downloadLink.download = getFileNameFromDisposition(file.contentDisposition);
      downloadLink.click();
    }
  };

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">Порядок предоставления</div>
      <div className="table-data__body">
        {editMode ? (
          <>
            <FormikTextarea
              name={getFieldFullName('ruleService')}
              maxRows={2}
              placeholder="Введите..."
              disabled={disabled}
            />
            {typeof ruleFile !== 'boolean' && ruleFile !== undefined && (
              <>
                <Push size={8} />
                <div className="icon-group">
                  <div className="icon-group__icon">
                    <LmIcon
                      icon="filled-files-attach-alt"
                      size={20}
                      color="var(--LM-blue-200)"
                    />
                  </div>
                  <div className="icon-group__text">{ruleFile.name}</div>
                  <button
                    disabled={disabled}
                    type="button"
                    className="icon-group__icon"
                    onClick={() => {
                      if (ruleFile.id !== '0' && ruleFile.id !== '') {
                        setRuleFile(true);
                      }
                    }}
                  >
                    <LmIcon
                      icon="filled-edit-close"
                      size={16}
                      color="var(--LM-neutrals-day-700)"
                    />
                  </button>
                </div>
              </>
            )}

            <Push size={8} />
            <div className="inline-block">
              <Files
                multiple={false}
                clickable={!disabled}
                onChange={(newFiles: ReactFilesFile[]) => {
                  setRuleFile(newFiles[0]);
                }}
                onError={(error: ReactFilesError) => {
                  console.error(error);
                }}
              >
                <LmButton
                  type="button"
                  variant="outline"
                  size="medium"
                  disabled={disabled}
                >
                  Прикрепить файл
                </LmButton>
              </Files>
            </div>
          </>
        ) : (
          <>
            {getFieldValue('ruleService') ?? '—'}
            {typeof ruleFile !== 'boolean' && ruleFile !== undefined && (
              <>
                <Push size={8} />
                <div className="icon-group">
                  <div className="icon-group__icon">
                    <LmIcon
                      icon="filled-files-attach-alt"
                      size={20}
                      color="var(--LM-blue-200)"
                    />
                  </div>
                  <button
                    type="button"
                    className="text-decoration-underline cursor-pointer brand-link"
                    onClick={() => downloadFile(ruleFile.id)}
                  >
                    {ruleFile.name}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
