import React, { useContext, useState } from 'react';
import { LmButton } from '@mes-ui/lemma';
import SavePanel from '../../../../components/save-panel';
import Expulsion from '../../components/modals/expulsion';
import Union from '../../components/modals/union';
import TransferGroup from '../../components/modals/transfer-group';
import RecoverySimple from '../../components/modals/recovery-simple';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import Change from '../../components/modals/change';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, openDetails } = useContext(ServiceClassTableContext);

  const { adminView, adminEdit } = useContext(ServiceClassContext);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);

  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);

  const [openUnionPopup, setOpenUnionPopup] = useState<boolean>(false);

  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);

  const handleDeselect = () => {
    setSelected([]);
    setOpenDetails(false);
  };

  const showExpulsionModal = () => {
    setOpenExpulsionPopup(true);
  };

  const showChangeModal = () => {
    setOpenChangeModal(true);
  };

  const showTransferGroupModal = () => {
    setOpenTransferGroupPopup(true);
  };

  const showUnionModal = () => {
    setOpenUnionPopup(true);
  };

  return (
    <>
      {openDetails && (
        <SavePanel
          hideSecondaryButton
          hidePrimaryButton
        >
          <LmButton
            dataTest="cancelSelection"
            type="button"
            variant="text"
            size="medium"
            onClick={handleDeselect}
          >
            Отменить выбор
          </LmButton>
          <LmButton
            dataTest="expel"
            type="button"
            color="danger"
            variant="outline"
            size="medium"
            onClick={showExpulsionModal}
          >
            Отчислить
          </LmButton>

          {(adminView || adminEdit) && (
            <LmButton
              dataTest="changeDates"
              type="button"
              variant="outline"
              size="medium"
              onClick={showChangeModal}
            >
              Изменить даты обучения
            </LmButton>
          )}

          <LmButton
            dataTest="transferGroup"
            type="button"
            variant="outline"
            size="medium"
            onClick={showTransferGroupModal}
          >
            Перевести в другую группу
          </LmButton>

          <LmButton
            dataTest="union"
            type="button"
            variant="outline"
            size="medium"
            onClick={showUnionModal}
          >
            Объединить в подгруппу
          </LmButton>
        </SavePanel>
      )}
      <RecoverySimple
        open={openRecoveryPopup}
        close={() => setOpenRecoveryPopup(false)}
      />
      <Expulsion
        open={openExpulsionPopup}
        close={() => setOpenExpulsionPopup(false)}
      />
      <Change
        open={openChangeModal}
        close={() => setOpenChangeModal(false)}
      />
      <TransferGroup
        open={openTransferGroupPopup}
        close={() => setOpenTransferGroupPopup(false)}
      />
      <Union
        open={openUnionPopup}
        close={() => setOpenUnionPopup(false)}
      />
    </>
  );
};

export default PanelButtons;
