import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LmSubHeader } from '@mes-ui/lemma';
import ViewContainer from '../../components/view-container';
import { routes } from '../../config/constants';
import useClearHistoryState from '../../hooks/use-clear-history-state';
import { historyState } from '../../mock-data/history-state';
import SearchInput from './filter/search-input';
import OrganizationTable from './table';
import OrganizationSearch from './filter/search';
import { SearchOrganizationInitialFormData } from '../../types/organization';
import { hasGeneralAccess, replaceHistoryState } from '../../lib/utils';
import { accessVedomst, generalAccess } from '../../mock-data/access-enum';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { getAccessSign } from './utils';

export const searchOrganizationInitialFormData = {
  query: '',
  excludeTest: true,
  showArchive: false,
  showNotAccepted: false,
  excludeWoVedomstvo: false,
  organizationNotDefined: false,
};

const OrganizationList = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdminView = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminView), [userProfile]);
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const showNotAcceptedToggle = getAccessSign(userProfile, isAdminEdit || isAdminView, true);

  const hasVerticalFilter = userProfile.vedomstvoId !== accessVedomst.Dogm || showNotAcceptedToggle;

  const [search, setSearch] = useState<SearchOrganizationInitialFormData>(
    window.history.state[historyState.search] || searchOrganizationInitialFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchOrganizationInitialFormData) => {
    setSearch(values);
    replaceHistoryState({
      [historyState.search]: values,
    });
  };

  return (
    <ViewContainer
      subHeader={
        <LmSubHeader
          sticky
          hideReturnButton
          title="Организации"
          dataTest="organizationsSubHeader"
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },

            {
              label: 'Организации',
            },
          ]}
          description=""
        />
      }
      contentFlexColumn={!hasVerticalFilter}
      filterComponent={
        hasVerticalFilter && (
          <OrganizationSearch
            submitForm={handleSubmitSearch}
            isAdminEdit={isAdminEdit}
            isAdminView={isAdminView}
            initialForm={search}
            showNotAcceptedToggle={showNotAcceptedToggle}
          />
        )
      }
    >
      {!hasVerticalFilter && (
        <SearchInput
          initialForm={search}
          submitForm={handleSubmitSearch}
        />
      )}
      <OrganizationTable
        isAdminEdit={isAdminEdit}
        isAdminView={isAdminView}
        search={search}
      />
    </ViewContainer>
  );
};

export default OrganizationList;
