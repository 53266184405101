import React, { useCallback, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmCheckbox, LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../../../components/formik/formik-input-number';
import { ServiceData } from '../../../../../../types/service';
import FormikToggle from '../../../../../../components/formik/formik-toggle';

type Props = { editMode: boolean; parent?: string; disabled?: boolean; isTemplate?: boolean };

const fieldFemaleStart = 'altFemaleStart';
const fieldFemaleEnd = 'altFemaleEnd';
const fieldMaleStart = 'altStart';
const fieldMaleEnd = 'altEnd';
const fieldAllStart = 'allStart';
const fieldAllEnd = 'allEnd';
const fieldIsFemalePresent = 'isFemalePresent';
const fieldIsMalePresent = 'isMalePresent';
const fieldGenderAll = 'genderAll';
const fieldCanChangeSexOrAge = 'canChangeSexOrAge';

const INPUT_NUMBER_WIDTH = 100;

export const GroupMembersField: React.FC<Props> = ({ isTemplate, editMode, parent, disabled }) => {
  const { initialValues, values, setFieldValue, errors } = useFormikContext<
    ServiceData & { allStart: number; allEnd: number }
  >();
  const [initialised, setInitialised] = useState(false);

  const femaleStart = parent ? `${parent}.${fieldFemaleStart}` : fieldFemaleStart;
  const femaleEnd = parent ? `${parent}.${fieldFemaleEnd}` : fieldFemaleEnd;

  const maleStart = parent ? `${parent}.${fieldMaleStart}` : fieldMaleStart;
  const maleEnd = parent ? `${parent}.${fieldMaleEnd}` : fieldMaleEnd;

  const allStart = parent ? `${parent}.${fieldAllStart}` : fieldAllStart;
  const allEnd = parent ? `${parent}.${fieldAllEnd}` : fieldAllEnd;

  const isFemalePresent = parent ? `${parent}.${fieldIsFemalePresent}` : fieldIsFemalePresent;
  const isMalePresent = parent ? `${parent}.${fieldIsMalePresent}` : fieldIsMalePresent;

  const genderAll = parent ? `${parent}.${fieldGenderAll}` : fieldGenderAll;

  const canChangeSexOrAge = parent ? `${parent}.${fieldCanChangeSexOrAge}` : fieldCanChangeSexOrAge;

  const [, , setAllStartLabel] = useField(allStart);
  const [, , setAllEndLabel] = useField(allEnd);

  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;

      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  useEffect(() => {
    setInitialised(false);
  }, [editMode, initialValues]);

  useEffect(() => {
    if (initialised) {
      return;
    }
    if (
      (getFieldValue(fieldIsFemalePresent) === true &&
        getFieldValue(fieldIsMalePresent) === true &&
        getFieldValue(fieldFemaleStart) === getFieldValue(fieldMaleStart) &&
        getFieldValue(fieldFemaleEnd) === getFieldValue(fieldMaleEnd)) ||
      (getFieldValue(fieldIsFemalePresent) === null &&
        getFieldValue(fieldIsMalePresent) === null &&
        getFieldValue(fieldFemaleStart) === null &&
        getFieldValue(fieldFemaleEnd) === null)
    ) {
      setFieldValue(genderAll, true);
      setFieldValue(allStart, getFieldValue(fieldMaleStart));
      setFieldValue(allEnd, getFieldValue(fieldMaleEnd));
      setFieldValue(isFemalePresent, true);
      setFieldValue(isMalePresent, true);
    }
    setInitialised(true);
  }, [values, initialised, allEnd, allStart, getFieldValue, setFieldValue, genderAll, isMalePresent, isFemalePresent]);

  const onChangeGender = (all: boolean) => {
    setFieldValue(genderAll, all);

    setTimeout(() => {
      setAllStartLabel.setTouched(true);
      setAllEndLabel.setTouched(true);
    }, 0);

    if (!all) {
      setFieldValue(isFemalePresent, false);
      setFieldValue(isMalePresent, false);
    } else {
      setFieldValue(isFemalePresent, true);
      setFieldValue(isMalePresent, true);
    }
  };

  const start = getFieldValue(fieldAllStart);

  useEffect(() => {
    if (getFieldValue(fieldAllStart) !== undefined) {
      setFieldValue(maleStart, getFieldValue(fieldAllStart));
      setFieldValue(femaleStart, getFieldValue(fieldAllStart));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  const end = getFieldValue(fieldAllEnd);

  useEffect(() => {
    if (getFieldValue(fieldAllEnd) !== undefined) {
      setFieldValue(maleEnd, getFieldValue(fieldAllEnd));
      setFieldValue(femaleEnd, getFieldValue(fieldAllEnd));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end]);

  const buildViewValue = (label: string, start: string, end: string) => (
    <>
      <div className="table-data__group">
        <div className="table-data__label">Пол</div>
        <div
          className="table-data__body"
          style={{
            width: 218,
          }}
        >
          {label}
        </div>
      </div>
      <div className="table-data__group">
        <div className="table-data__label">Возраст</div>
        {getFieldValue(start)} - {getFieldValue(end)} лет
      </div>
    </>
  );

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Состав группы {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="flex">
            <LmSwitcher
              dataTest="groupGender"
              size="small"
              disabled={disabled}
              value={getFieldValue(fieldGenderAll)}
              onChange={onChangeGender}
              variant="gray"
            >
              <LmSwitcherOption
                dataTest="switcher-genderAll-true"
                value
              >
                Все
              </LmSwitcherOption>
              <LmSwitcherOption
                dataTest="switcher-genderAll-false"
                value={false}
              >
                Муж/Жен
              </LmSwitcherOption>
            </LmSwitcher>

            {getFieldValue(fieldGenderAll) && (
              <>
                <Push
                  size={16}
                  orientation="horizontal"
                />
                <div
                  style={{
                    width: INPUT_NUMBER_WIDTH,
                  }}
                >
                  <FormikInputNumber
                    name={allStart}
                    placeholder="0"
                    disabled={disabled}
                  />
                </div>

                <Push
                  size={8}
                  orientation="horizontal"
                />
                <div>
                  <div className="table-row-item-height flex items-center">—</div>
                </div>
                <Push
                  size={8}
                  orientation="horizontal"
                />

                <div
                  style={{
                    width: INPUT_NUMBER_WIDTH,
                  }}
                >
                  <FormikInputNumber
                    name={allEnd}
                    placeholder="0"
                    disabled={disabled}
                  />
                </div>
              </>
            )}

            {!getFieldValue(fieldGenderAll) && (
              <>
                <Push
                  size={16}
                  orientation="horizontal"
                />
                <div className="table-row-item-height flex items-center">
                  <LmCheckbox
                    error={!!errors.info?.isMalePresent}
                    disabled={disabled}
                    name={isMalePresent}
                    dataTest={isMalePresent}
                    onChange={() => {
                      setFieldValue(isMalePresent, !getFieldValue(fieldIsMalePresent));
                    }}
                    checked={getFieldValue('isMalePresent')}
                  >
                    Муж
                  </LmCheckbox>
                </div>

                <Push
                  size={16}
                  orientation="horizontal"
                />
                <div
                  style={{
                    width: INPUT_NUMBER_WIDTH,
                  }}
                >
                  <FormikInputNumber
                    name={maleStart}
                    placeholder="0"
                    disabled={!getFieldValue(fieldIsMalePresent) || disabled}
                  />
                </div>

                <Push
                  size={8}
                  orientation="horizontal"
                />
                <div>
                  <div className="table-row-item-height flex items-center">—</div>
                </div>
                <Push
                  size={8}
                  orientation="horizontal"
                />

                <div
                  style={{
                    width: INPUT_NUMBER_WIDTH,
                  }}
                >
                  <FormikInputNumber
                    name={maleEnd}
                    placeholder="0"
                    disabled={!getFieldValue(fieldIsMalePresent) || disabled}
                  />
                </div>

                <Push
                  size={16}
                  orientation="horizontal"
                />

                <div className="table-row-item-height flex items-center">
                  <LmCheckbox
                    error={!!errors.info?.isFemalePresent}
                    name={isFemalePresent}
                    dataTest={isFemalePresent}
                    onChange={() => {
                      setFieldValue(isFemalePresent, !getFieldValue(fieldIsFemalePresent));
                    }}
                    checked={getFieldValue(fieldIsFemalePresent)}
                    disabled={disabled}
                  >
                    Жен
                  </LmCheckbox>
                </div>

                <Push
                  size={16}
                  orientation="horizontal"
                />
                <div
                  style={{
                    width: INPUT_NUMBER_WIDTH,
                  }}
                >
                  <FormikInputNumber
                    name={femaleStart}
                    placeholder="0"
                    disabled={!getFieldValue(fieldIsFemalePresent) || disabled}
                  />
                </div>

                <Push
                  size={8}
                  orientation="horizontal"
                />
                <div>
                  <div className="table-row-item-height flex items-center">—</div>
                </div>
                <Push
                  size={8}
                  orientation="horizontal"
                />

                <div
                  style={{
                    width: INPUT_NUMBER_WIDTH,
                  }}
                >
                  <FormikInputNumber
                    name={femaleEnd}
                    placeholder="0"
                    disabled={!getFieldValue(fieldIsFemalePresent) || disabled}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            {getFieldValue(fieldGenderAll) ? (
              buildViewValue('Все', 'allStart', 'allEnd')
            ) : (
              <>
                {getFieldValue(fieldIsMalePresent) && buildViewValue('Муж', 'altStart', 'altEnd')}
                <Push
                  size={128}
                  orientation="horizontal"
                />
                {getFieldValue(fieldIsFemalePresent) && buildViewValue('Жен', 'altFemaleStart', 'altFemaleEnd')}
              </>
            )}
          </div>
        )}
      </div>
      {isTemplate && (
        <div className="table-data__control">
          <FormikToggle
            disabled={!editMode}
            name={canChangeSexOrAge}
            size="small"
          />
        </div>
      )}
    </div>
  );
};
