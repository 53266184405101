import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import { TypeOfAttendanceId, TypeOfAttendanceName } from '../../../../../mock-data/type-of-attendance';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { SearchInitialFormData } from '../../../../../types/service';
import { ServiceRegistryContext } from '../../initial-data';
import { SelectOptionType } from '../../../../../types/entities';

const DEFAULT_VALUE = {
  label: 'Все',
  value: 0,
};

const FIELD_ID = 'typeOfAttendanceId';
const FIELD_NAME = 'typeOfAttendanceName';

const FieldVisitType: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<SearchInitialFormData>();

  const { visitTypeData } = React.useContext(ServiceRegistryContext);

  const additionalCondition = values.educationTypeId === EducationTypeEnum.VirtualAssistantEducation;

  useEffect(() => {
    setFieldValue(
      FIELD_ID,
      additionalCondition
        ? TypeOfAttendanceId.OnlineCircle
        : (values[FIELD_ID] || visitTypeData[0]?.value) ?? DEFAULT_VALUE.value
    );
    setFieldValue(
      FIELD_NAME,
      additionalCondition
        ? TypeOfAttendanceName.OnlineCircle
        : (values[FIELD_NAME] || visitTypeData[0]?.label) ?? DEFAULT_VALUE.label
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalCondition]);

  return (
    <FormikSelect
      label="Вид посещения"
      disabled={additionalCondition}
      name={FIELD_ID}
      size="small"
      options={visitTypeData}
      defaultValue={
        values[FIELD_ID]
          ? {
              label: values[FIELD_NAME] || '',
              value: values[FIELD_ID] as number,
            }
          : DEFAULT_VALUE
      }
      placeholder="Выберите..."
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue(FIELD_NAME, v.label);
        }
      }}
    />
  );
};

export default FieldVisitType;
