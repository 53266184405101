import React, { useCallback, useMemo } from 'react';
import { TreeLinks, TreeLinksNodeType } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { routeType, SidebarLinkType } from '../../mock-data/routes';
import { MenuSettings } from '../../types/entities';
import { AuthorizationData } from '../../types/authorization-data';

type Props = {
  userProfile: AuthorizationData;
  itemList: SidebarLinkType[];
  menuSetting?: MenuSettings[];
  isOpened?: boolean;
};
const TreeLinkWrapper = ({ userProfile, itemList, menuSetting, isOpened }: Props) => {
  const filteredList = useMemo(
    () =>
      menuSetting
        ? menuSetting.reduce((result: SidebarLinkType[], s) => {
          const item = itemList.find((x) => x.id === s.id);

          if (item?.checkAccess(userProfile)) {
            result.push({
              ...item,
              parent: s.parentId,
              name: s.menuName,
            });
          } else {
            console.error(`Error: menuSetting id:${s.id} not found in SidebarLink`);
          }

          return result;
        }, [])
        : itemList.reduce((result: SidebarLinkType[], s) => {
          if (s.checkAccess(userProfile)) {
            result.push(s);
          }

          return result;
        }, []),
    [menuSetting, userProfile, itemList]
  );

  const filteredTitleList = useMemo(
    () => filteredList.filter((s) => s && s.type & routeType.sidebarTitle),
    [filteredList]
  );

  const filteredHasChildList = useMemo(() => filteredList.filter((s) => s && s.hasChildren), [filteredList]);

  const getChildComponent = useCallback(
    (item: SidebarLinkType): TreeLinksNodeType => ({
      id: item.id,
      title: item.name,
      link: item.link || '',
      icon: () =>
        item.icon && (
          <LmIcon
            icon={item.icon}
            size={20}
          />
        ),
      children: item.hasChildren
        ? itemList.filter((s) => s.parent === item.id && s.checkAccess(userProfile)).map(getChildComponent)
        : [],
      redirect: item.redirect,
    }),
    [itemList, userProfile]
  );

  const getOpenedIds = useCallback((data: TreeLinksNodeType[]) => {
    let ids: number[] = [];

    data.forEach((item) => {
      if (item.children.length) {
        ids = [...ids, item.id, ...getOpenedIds(item.children)];
      }
    });

    return ids;
  }, []);

  return filteredTitleList.length ? (
    <>
      {filteredTitleList.map((item) => {
        const data = filteredList.filter((s) => s.parent === item.id).map(getChildComponent);
        const childrenIds = getOpenedIds(data);

        return (
          <div
            key={item.id}
            data-test={item.name}
          >
            <div className="container">
              <div className="sitemap-title">{item.name}</div>
            </div>
            <TreeLinks
              data={data}
              opened={isOpened ? childrenIds : []}
            />
          </div>
        );
      })}
    </>
  ) : filteredHasChildList.length ? (
    <>
      {filteredHasChildList.map((item) => {
        const data = [getChildComponent(item)];

        return (
          <React.Fragment key={item.id}>
            <div className="container">
              <div className="sitemap-title" />
            </div>
            <TreeLinks data={data} />
          </React.Fragment>
        );
      })}
    </>
  ) : (
    <TreeLinks data={filteredList.map(getChildComponent)} />
  );
};

export default TreeLinkWrapper;
