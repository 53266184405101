import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { RequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { getServiceEditLink } from '../../../lib/utils';

type Props = {
  data: RequestData;
};

const Info: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация об услуге'}>
        <div className="container">
          <div className="table-data">
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Наименование услуги</div>
              <div className="table-data__body">
                <Link
                  className="brand-link cursor-pointer"
                  to={getServiceEditLink(data.educationTypeId, data.trainingGroup.serviceId)}
                  target="_blank"
                >
                  {data.trainingGroup.serviceName}
                </Link>
              </div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Преподаватель</div>
              <div className="table-data__body">{data.trainingGroup.teacherName}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Причина записи</div>
              <div className="table-data__body">{data.requestApplyReasonName || '—'}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Дата начала занятий</div>
              <div className="table-data__body">{formatDate(data.trainingGroup.educationStartDate)}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Комментарий</div>
              <div className="table-data__body">{data.evaluation.commentEvaluation || '—'}</div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Info;
