import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import { routes } from '../../../config/constants';
import DayCareServiceClassDetails from './details';
import Favorite from '../../../components/favorite';
import { generateLink, goBack } from '../../../lib/utils';
import { serviceTypeEnum } from '../../../mock-data/print';
import ChangeHistory from '../change-history';
import { ServiceClassContext } from '../service-class';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import ConfirmDeletionModal from '../components/modals/confirm-deletion';
import AddArchiveModal from '../components/modals/add-archive';

const DayCareServiceClass: React.FC = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const { serviceClassData, accessAddArchive, isArchiveGroup, routeBack } = useContext(ServiceClassContext);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);

  const openModalDelete = () => {
    setShowModalDelete(true);
  };

  const openModalArchive = () => {
    setShowModalArchive(true);
  };

  const handlePrint = () =>
    window.open(
      generateLink(routes.printServiceClass, {
        type: serviceTypeEnum.dayCareCentersEducation,
        id: id as string,
      }),
      '_blank'
    );

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (serviceClassData.canBeDeleted) {
      buttons.push({
        dataTest: 'delete',
        buttonText: 'Удалить',
        rounded: 'small',
        variant: 'secondary',
        onClick: openModalDelete,
        icon: 'filled-hardware-hard-drive',
        disabled: !accessAddArchive,
      });
    } else {
      buttons.push({
        dataTest: 'toArchive',
        buttonText: 'В архив',
        rounded: 'small',
        variant: 'secondary',
        onClick: openModalArchive,
        icon: 'filled-hardware-hard-drive',
        disabled: isArchiveGroup || !accessAddArchive,
      });
    }
    buttons.push({
      dataTest: 'print',
      icon: 'filled-hardware-printer',
      buttonText: 'Печать',
      rounded: 'small',
      variant: 'secondary',
      onClick: handlePrint,
    });

    return buttons;
  };

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => goBack(routeBack)}
        title={`Группа продленного дня ${isArchiveGroup ? '(архивная)' : ''}`}
        dataTest="serviceClassDayCareSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Группы обучения',
            link: routes.serviceClasses,
          },
          {
            label: 'Группа по уходу и присмотру за детьми школьного возраста',
          },
        ]}
        tabs={[
          {
            title: 'Основные сведения',
            value: 1,
            dataTest: 'basicInfo',
          },
          {
            title: 'История изменений',
            value: 2,
            dataTest: 'changeHistory',
          },
        ]}
        className="content-panel"
        panels={[
          {
            children: <DayCareServiceClassDetails />,
            value: 1,
          },
          {
            children: <ChangeHistory />,
            value: 2,
            className: 'content-panel content-panel--auto',
            noPadding: true,
          },
        ]}
        buttonsOrAnyActions={getButtons()}
        titleChildren={
          <Favorite
            entityId={serviceClassData.id as number}
            typeEntity="группу обучения"
            currentEntityType={serviceClassData.name}
            entityTypeId={EntityTypeEnum.ServiceClass}
          />
        }
      />

      <ConfirmDeletionModal
        serviceClassCode={serviceClassData?.code as string}
        onCloseHandler={() => setShowModalDelete(false)}
        show={showModalDelete}
      />

      <AddArchiveModal
        onCloseHandler={() => setShowModalArchive(false)}
        show={showModalArchive}
      />
    </>
  );
};

export default DayCareServiceClass;
