import { Learner } from '../types/learners';

export const initialPupilDocument: Learner.DocumentData = {
  documentTypeId: undefined,
  docSeries: '',
  docNumber: '',
  docDate: undefined,
  docIssuer: '',
  id: 0,
  pupilId: 0,
  documentTypeName: '',
  docIssuerCode: null,
  typeIssuePlaceId: null,
  typeIssuePlaceName: null,
};
