import React, { useCallback, useState } from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import ViewContainer from '../../../components/view-container';
import { routes } from '../../../config/constants';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../../lib/utils';
import { historyState } from '../../../mock-data/history-state';
import LearnersSearch from './search';
import LearnersTable from './table';
import { SearchLearnersInitialFormData } from '../../../types/learners';

const Learners = () => {
  const [search, setSearch] = useState<SearchLearnersInitialFormData>();

  useClearHistoryState();

  const handleSubmitSearch = useCallback((values: SearchLearnersInitialFormData) => {
    setSearch(values);
    replaceHistoryState({
      [historyState.search]: values,
    });
  }, []);

  return (
    <ViewContainer
      subHeader={
        <LmSubHeader
          sticky
          title="Обучающиеся"
          dataTest="learnersSubHeader"
          description=""
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Обучающиеся',
            },
          ]}
          hideReturnButton
        />
      }
      filterComponent={
        <LearnersSearch
          submitForm={handleSubmitSearch}
          search={search}
        />
      }
    >
      <LearnersTable search={search} />
    </ViewContainer>
  );
};

export default Learners;
