import React, { ReactNode } from 'react';
import classNames from 'classnames';
import {
  LmSidePage,
  LmSidePageBody,
  LmSidePageFooter,
  LmSidePageHeader,
  LmActionBarProps,
  LmLoader,
} from '@mes-ui/lemma';
import { SidePageSize } from '@mes-ui/lemma/models/modalSize.model';

type SidePageClasses = {
  container?: string;
  header?: string;
  body?: string;
};

type SidePageProps = Pick<
  LmActionBarProps,
  'buttonSecondaryText' | 'buttonPrimaryText' | 'primaryButtonModifiers' | 'secondaryButtonModifiers'
> & {
  dataTest: string;
  open: boolean;
  title: string;
  description?: string;
  size?: SidePageSize;
  classes?: SidePageClasses;
  loading?: boolean;
  onClose: (show: boolean) => void;
  onSubmit: () => void;
  additionalHeader?: ReactNode;
  additionalButtons?: ReactNode;
  hideReturnButton?: boolean;
};

const SidePage: React.FC<SidePageProps> = ({
  dataTest,
  open,
  title,
  description,
  children,
  classes,
  size = 'medium',
  loading,
  onClose,
  onSubmit,
  additionalHeader,
  additionalButtons,
  buttonSecondaryText = 'Отмена',
  buttonPrimaryText = 'Сохранить',
  hideReturnButton = true,
  primaryButtonModifiers,
  secondaryButtonModifiers,
}) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <LmSidePage
      dataTest={dataTest}
      className={classes?.container}
      isOpenModal={open}
      onCloseModal={handleClose}
      isOpenOverlay
      size={size}
    >
      <LmSidePageHeader
        dataTest={dataTest}
        className={classes?.header}
        title={title}
        description={description}
        hideReturnButton={hideReturnButton}
        onCloseModal={handleClose}
      >
        {additionalHeader}
      </LmSidePageHeader>
      <LmSidePageBody className={classNames('modal__body', loading && 'flex', classes?.body)}>
        {loading ? (
          <div className="loader-auto">
            <LmLoader view="block" />
          </div>
        ) : (
          children
        )}
      </LmSidePageBody>
      {!loading && (
        <LmSidePageFooter
          dataTest={dataTest}
          buttonSecondaryText={buttonSecondaryText}
          buttonPrimaryText={buttonPrimaryText}
          primaryButtonModifiers={{
            size: 'medium',
            ...primaryButtonModifiers,
          }}
          secondaryButtonModifiers={{
            size: 'medium',
            ...secondaryButtonModifiers,
          }}
          onClickSeconadaryButton={handleClose}
          onClickPrimaryButton={onSubmit}
        >
          {additionalButtons}
        </LmSidePageFooter>
      )}
    </LmSidePage>
  );
};

export default SidePage;
