import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { formatPhoneNumber } from '../../../../../lib/utils/format-number';
import FormikInput from '../../../../../components/formik/formik-input';
import { phoneMask } from '../../../../../lib/utils/mask';
import ContactName from '../../fields/contact-name';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  editMode?: boolean;
  setEditMode?: (value: boolean) => void;
  parent?: string;
  isArchive?: boolean;
  required?: boolean;
  accessEditable?: boolean;
  published?: boolean;
  organizationId?: number;
};

const TemplateContactPerson: React.FC<Props> = ({
  setEditModeParent,
  editMode,
  setEditMode,
  parent,
  isArchive,
  required,
  accessEditable,
  published,
  organizationId,
}) => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  const fullNameField = parent ? `${parent}.fullName` : 'fullName';
  const phoneField = parent ? `${parent}.phone` : 'phone';

  const checkEditable = !editMode && setEditModeParent && !isArchive && accessEditable && !published;

  useEffect(() => {
    if (editMode) {
      setFieldValue(phoneField, formatPhoneNumber(values[phoneField], false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => <>Контактное лицо</>}
        headingControl={() => {
          return checkEditable ? (
            <button
              type="button"
              onClick={() => {
                setEditModeParent && setEditModeParent('contact');
                setEditMode && setEditMode(true);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-edit"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        <div className="container">
          <ContactName
            name={fullNameField}
            editMode={editMode}
            required={required}
            phoneField={phoneField}
            organizationId={organizationId}
          />

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Телефон {editMode && required && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <FormikInput
                  size="small"
                  maskRegex={phoneMask}
                  placeholder="(XXX) XXX-XX-XX"
                  leftPrefix="+7"
                  name={phoneField}
                  showErrorImmediately
                />
              ) : (
                values && formatPhoneNumber(values.phone)
              )}
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default TemplateContactPerson;
