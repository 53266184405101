import React from 'react';
import { TableDataType } from '@mosru/esz_uikit';
import { LoaderCustom } from '../../../../../components/loader-custom';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { useGetColumns } from './use-get-columns';
import SimpleTable from '../../../../../components/table/simple-table';

type Props = {
  loading: boolean;
  planData: TableDataType[];
  editPlan: (id: number) => void;
  setIdRemoveModal: (id: number) => void;
  educationType: EducationTypeEnum;
};

const EditPlan: React.FC<Props> = ({ loading, planData, editPlan, setIdRemoveModal, educationType }) => {
  const columns = useGetColumns(educationType, editPlan, setIdRemoveModal);

  return loading ? (
    <LoaderCustom size={20} />
  ) : columns.length ? (
    <SimpleTable
      dataTest="plans"
      data={planData}
      columns={columns}
    />
  ) : null;
};

export default EditPlan;
