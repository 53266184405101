import { HttpStatusCode } from 'axios';
import { notify } from '../../redux/ducks/notifications';
import { store } from '../../store/store';
import photoApi from '../api/photo';

export const getPhotoName = (name: string, accepts: string[]) =>
  name.replace(new RegExp(`(\\S)(${accepts.join('|')})`, 'gi'), `$1_${Date.now()}$2`);

const getNotifyEmptyName = () =>
  store.dispatch(
    notify.danger({
      dataTest: 'emptyName',
      title: 'Не указан sourceFileName у изображения',
    })
  );

const getErrorMessage = async (response: Response) => {
  let error;

  try {
    const resp = await response.clone().json();

    error = resp?.error_description;
  } catch {
    error = undefined;
  }

  return error;
};

export const postPhoto = async (fileName: string | undefined, file: Blob) => {
  if (!fileName) {
    getNotifyEmptyName();

    return Promise.reject();
  }

  try {
    return await photoApi.postPhotoS3(fileName, file);
  } catch (response) {
    const error = await getErrorMessage(response);

    if (response.status === HttpStatusCode.BadRequest && !error) {
      store.dispatch(
        notify.danger({
          dataTest: 'incorrectFileName',
          title:
            'Название файла содержит некорректные символы. Для исключения проблем с совместимостью рекомендуется использовать русские и/или латинские буквы, цифры в названии файла',
        })
      );
    } else {
      store.dispatch(
        notify.danger({
          dataTest: 'postPhoto',
          title: error || 'Не удалось сохранить изображение. Попробуйте еще раз',
        })
      );
    }

    return Promise.reject(error);
  }
};

export const deletePhoto = async (fileName: string | undefined) => {
  if (!fileName) {
    getNotifyEmptyName();

    return Promise.reject();
  }

  try {
    await photoApi.deletePhotoS3(fileName);
  } catch (e) {
    store.dispatch(
      notify.danger({
        dataTest: 'deletePhoto',
        title: e.error_description || 'Не удалось удалить изображение. Попробуйте еще раз',
      })
    );

    return Promise.reject(e);
  }
};
