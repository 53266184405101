import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import SavePanel from '../../../components/save-panel';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { addPhonePrefix } from '../../../lib/utils/format-number';
import { notify } from '../../../redux/ducks/notifications';
import FIO from '../components/fields/fio';
import { PersonRequestData } from '../../../types/requests';
import Document from '../components/fields/document';
import Email from '../../../components/fields/email';
import DocumentNumber from '../components/fields/document-number';
import Phone from '../../../components/fields/phone';
import history from '../../../history';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import requestsApi from '../../../lib/api/requests';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import Issued from '../components/fields/issued';
import { applicantValidationSchema } from './create';

type Props = {
  requestId: number;
  requestApplicant: PersonRequestData;
  setEditModeParent: (value: string | null) => void;
  editable: boolean;
  updateRequest: () => void;
  isSourceMosRu: boolean;
};

const ApplicantForm: React.FC<Props> = ({
  requestId,
  requestApplicant,
  setEditModeParent,
  editable,
  updateRequest,
  isSourceMosRu,
}) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(requestApplicant, applicantValidationSchema);
  const [formKey, setFormKey] = useState(Math.random());

  const dispatch = useDispatch();

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      try {
        values.isChild = false;
        const request = {
          ...values,
          phone: addPhonePrefix(values.phone),
        };

        const id = await requestsApi.saveRequestPerson(request);

        setEditModeParent(null);
        setEditMode(false);
        if (id !== requestId) {
          history.push(
            generateLink(routes.request, {
              id,
            })
          );
        } else {
          updateRequest();
        }
      } catch (ex: any) {
        dispatch(
          notify.danger({
            dataTest: 'saveApplicant',
            title: ex.data?.message,
          })
        );
      }
    },
    [setEditModeParent, updateRequest, requestId, dispatch]
  );

  return (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={applicantValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={requestApplicant}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, submitForm, isSubmitting, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о заявителе'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('applicant');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <FIO
                    showErrorImmediately
                    label="ФИО заявителя"
                    required
                    editMode={editMode}
                    disabled={isSourceMosRu}
                  />
                  <Phone
                    showErrorImmediately
                    name="phone"
                    editMode={editMode}
                    required
                  />
                  <Email
                    name="email"
                    editMode={editMode}
                    disabled={isSourceMosRu}
                  />
                  <Document
                    dependentFields
                    name="documentType"
                    editMode={editMode}
                    formType={FormTypeEnum.EditRequestApplicantDsitDkgm}
                  />
                  <Issued
                    name="issued"
                    editMode={editMode}
                  />
                  <DocumentNumber
                    editMode={editMode}
                    calendarPosition="top-end"
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  setFormKey(Math.random());
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ApplicantForm;
