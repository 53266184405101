import { LmButton, LmIcon, LmTooltip } from '@mes-ui/lemma';
import { Push, SelectOptionType } from '@mosru/esz_uikit';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { routes, searchServiceRegistryName } from '../../../../config/constants';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import lookupApi from '../../../../lib/api/lookup';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { generateLink, getServiceEditLink } from '../../../../lib/utils';
import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceClassContext } from '../../service-class';
import { tooltipTextForService } from '../../utils';
import { addressFieldId, addressFieldName } from './address';
import { moduleFieldId, moduleFieldName } from './module';
import { parallelFieldName } from './parallel';
import { teachersField } from './teachers';
import { trainingGroupFieldId, trainingGroupFieldName } from './training-group';

type ServiceProps = FieldProps<string> & {
  educationType: EducationTypeEnum;
  showSearchServiceBtn?: boolean;
  disabled?: boolean;
  isNewServiceClass?: boolean;
};
export const serviceIdField = 'serviceId';

const ServiceField = ({
  label = 'Программа',
  name,
  editMode,
  required,
  defaultValue = '—',
  educationType,
  showSearchServiceBtn,
  disabled,
  isNewServiceClass,
}: ServiceProps) => {
  const { initialValues, setFieldValue } = useFormikContext<any>();

  const { serviceClassData } = useContext(ServiceClassContext);

  const fieldName = 'fullServiceName';
  const fieldIdName = `${name}Id`;

  const fetchOptions = useCallback(
    async (query: string) => {
      const res = await lookupApi.getService(query, educationType, undefined, undefined);

      return res.map((item: any) => {
        const properties = JSON.parse(item.additionalPropertiesJson);
        const labelGpd = `${item.label}, ${properties?.Id || ''}`;
        const labelOther = `${item.label} ${properties?.TypeFinancingName ? `,${properties?.TypeFinancingName}` : ''} ${
          properties.PlaceAddress ? `,${properties?.PlaceAddress}` : ''
        } ${isNewServiceClass ? `,${item.value || ''}` : ''}`;

        const label = `${
          EducationTypeEnum.DayCareCentersEducation === serviceClassData.educationTypeId ? labelGpd : labelOther
        }`;

        return {
          ...item,
          label,
          typeOfAttendanceId: properties?.TypeOfAttendanceId,
        };
      });
    },
    [educationType, serviceClassData.educationTypeId, isNewServiceClass]
  );

  const [fieldLabel, , helpersLabel] = useField<string>(fieldName);
  const [fieldId, , helpersId] = useField<number | null>(fieldIdName);
  const [fieldTypeOfAttendanceId, , helpersTypeOfAttendanceId] = useField<number | null>('typeOfAttendanceId');

  const selectServiceItem = async (name: string, id: number, typeOfAttendanceId: number) => {
    helpersTypeOfAttendanceId.setValue(typeOfAttendanceId);
    helpersId.setValue(id);
    const service = await serviceTemplateApi.getServiceName(id);

    helpersLabel.setValue(service);
  };

  const setAddress = useCallback(async () => {
    if (fieldId.value && fieldTypeOfAttendanceId.value === 1) {
      const address = await serviceTemplateApi.getPlaceServiceAddresses({
        serviceId: fieldId.value,
      });

      if (address.length) {
        setFieldValue(addressFieldName, address[0].label);
        setFieldValue(addressFieldId, address[0].value);
      }
    }
  }, [fieldId.value, fieldTypeOfAttendanceId.value, setFieldValue]);

  useEffect(() => {
    if (initialValues.serviceId !== fieldId.value) {
      if (educationType === EducationTypeEnum.ChildrenEducation && fieldTypeOfAttendanceId.value === 1) {
        setAddress();
      } else {
        setFieldValue(addressFieldName, '');
        setFieldValue(addressFieldId, '');
      }

      setFieldValue(trainingGroupFieldId, '');
      setFieldValue(trainingGroupFieldName, '');

      if (serviceClassData?.educationTypeId === EducationTypeEnum.ProfessionalEducation) {
        setFieldValue(moduleFieldId, '');
        setFieldValue(moduleFieldName, '');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId.value]);

  // Обновление паралелли при изменении программы.
  useEffect(() => {
    if (fieldId.value) {
      const fetch = async () => {
        if (fieldId.value) {
          const res = await dictionariesApi.getParallelsByService(fieldId.value);

          const parallelList = res.map((item) => item.label).join(',');

          if (initialValues.serviceId !== fieldId.value) {
            if (serviceClassData?.educationTypeId === EducationTypeEnum.DayCareCentersEducation) {
              setFieldValue(parallelFieldName, parallelList);
            }
          }
        }
      };

      fetch();
    } else {
      setFieldValue(parallelFieldName, '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId.value]);

  // Обнуление списка преподавателей, при очистке программы.
  useEffect(() => {
    if (!fieldId.value && isNewServiceClass) {
      setFieldValue(teachersField, []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId.value]);

  useEffect(() => {
    const fetch = async () => {
      if (fieldId.value) {
        const serviceName = await serviceTemplateApi.getServiceName(fieldId.value);

        helpersLabel.setValue(serviceName);
      }
    };
    const getService = async () => {
      if (fieldId.value) {
        const service = await serviceTemplateApi.getServiceById(fieldId.value);

        service?.info?.typeOfAttendanceId && helpersTypeOfAttendanceId.setValue(service.info.typeOfAttendanceId);
      }
    };

    if (fieldId.value && !fieldLabel.value) {
      fetch();
      if (!fieldTypeOfAttendanceId.value) {
        getService();
      }
    }
  }, [fieldId.value, fieldLabel.value, fieldTypeOfAttendanceId.value, helpersLabel, helpersTypeOfAttendanceId]);

  const currentLabel =
    EducationTypeEnum.ProfessionalEducation === serviceClassData.educationTypeId ? (
      <>
        Образовательная <br />
        программа
      </>
    ) : (
      label
    );

  const handleFindService = () => {
    const myWnd: (Window & typeof globalThis) | any = window;

    myWnd.selectServiceItem = selectServiceItem;
    const searchWnd = myWnd.open(
      `${generateLink(routes.registerChildEducations, {})}?IsNewSearch=true&IsShowSelectButtons=true&StatusId=2`,
      searchServiceRegistryName,
      'width=1080,height=800,status=no,toolbar=no,menubar=no,location=no,directories=no,resizable=yes,scrollbars=yes'
    );

    searchWnd?.focus();
  };

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main flex flex-direction-row">
        <div>
          <span> {currentLabel}</span> <span>{editMode && required && <span className="table-data__required" />} </span>
        </div>

        {editMode && (
          <div
            style={{
              marginLeft: '10px',
            }}
          >
            <LmTooltip
              dataTest="textForService"
              withArrow
              placement="bottom"
              content={
                <div
                  style={{
                    textAlign: 'center',
                    width: '200px',
                    fontSize: '12px',
                  }}
                >
                  {tooltipTextForService[serviceClassData.educationTypeId]}
                </div>
              }
            >
              <LmIcon
                icon="outline-notifications-info"
                size={18}
                color="var(--LM-neutrals-day-700)"
              />
            </LmTooltip>
          </div>
        )}
      </div>

      <div className="table-data__body">
        {editMode ? (
          <div className="flex">
            <div className="flex-auto">
              <FormikSelect
                showTooltip
                name={fieldIdName}
                required
                size="small"
                withSearch
                options={[]}
                loadOptions={fetchOptions}
                selectedValue={(selected?: SelectOptionType) => {
                  helpersId.setValue(selected ? (selected.value as number) : null);
                  helpersLabel.setValue(selected ? selected.label : '');
                  // @ts-ignore
                  helpersTypeOfAttendanceId.setValue(selected ? selected.typeOfAttendanceId : null);
                }}
                defaultValue={
                  fieldId.value
                    ? {
                        value: fieldId.value,
                        label: fieldLabel.value,
                      }
                    : null
                }
                placeholder={showSearchServiceBtn ? 'Начните вводить или выберите...' : 'Начните вводить...'}
                disabled={disabled}
              />
            </div>

            {showSearchServiceBtn && (
              <>
                <Push
                  size={8}
                  orientation="horizontal"
                />
                <div className="flex-none">
                  <LmButton
                    dataTest="findService"
                    type="button"
                    onClick={handleFindService}
                  >
                    Подобрать объединение
                  </LmButton>
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            className="brand-link cursor-pointer"
            onClick={() => window.open(getServiceEditLink(educationType, serviceClassData.serviceId), '_blank')}
          >
            {fieldLabel.value || defaultValue}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceField;
