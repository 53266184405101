import { ReactText, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LmNotifyToast, LmNotifyToastList } from '@mes-ui/lemma';
import { deleteNotification } from '../../redux/ducks/notifications';
import { notificationsSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';

const NotifyToastContainer = () => {
  const { notifications } = useSelector((state: AppState) => ({
    notifications: notificationsSelector(state),
  }));
  const dispatch = useDispatch();

  const handleClose = useCallback(
    (currentId: ReactText) => {
      dispatch(deleteNotification(currentId));
    },
    [dispatch]
  );

  return notifications.length ? (
    <LmNotifyToastList
      position="top"
      dataTest="notifyToastList"
    >
      {notifications?.map(({ dataTest, ...rest }) => (
        <LmNotifyToast
          key={rest.id}
          onClose={handleClose}
          variant="filled"
          hasControlCloseTimeout
          {...rest}
          dataTest={`${rest.type}${dataTest ? `-${dataTest}` : ''}`}
        />
      ))}
    </LmNotifyToastList>
  ) : null;
};

export default NotifyToastContainer;
