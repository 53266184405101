import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RequestSourceEnum } from '../../../mock-data/request-source-enum';
import ChildForm from './child';
import Organization from './organization';
import Contract from './contract';
import ApplicantForm from './applicant';
import Education from './association';
import ActionsForm from './actions';
import { RequestData } from '../../../types/requests';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { hasAccessObjectAny } from '../../../lib/utils';
import { accessAction, accessObject } from '../../../types/authorization-data';

type DetailsProps = {
  data: RequestData;
  updateRequest: () => void;
};

const Details = ({ data, updateRequest }: DetailsProps) => {
  const [editMode, setEditMode] = useState<string | null>(null);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const btnEditDisabled = useMemo(
    () =>
      data.requestStatusId === RequestStatusEnum.Archive ||
      data.requestStatusId === RequestStatusEnum.Included ||
      data.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      data.requestStatusId === RequestStatusEnum.ApplicantRefused ||
      data.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      !hasAccessObjectAny(userProfile, [accessObject.RequestOP], accessAction.Edit),
    [data.requestStatusId, userProfile]
  );
  const actionEnable = useMemo(
    () =>
      (data.requestStatusId === RequestStatusEnum.DocumentAccepted &&
        data.requestSourceId !== RequestSourceEnum.Mpgu) ||
      data.requestStatusId === RequestStatusEnum.UnderConsideration ||
      data.requestStatusId === RequestStatusEnum.Included ||
      data.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      data.requestStatusId === RequestStatusEnum.ForInclude ||
      data.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      data.requestStatusId === RequestStatusEnum.ApplicantRefused,
    [data.requestSourceId, data.requestStatusId]
  );

  return (
    <>
      {(!editMode || editMode === 'child') && (
        <ChildForm
          data={data.child}
          setEditModeParent={setEditMode}
          editable={!btnEditDisabled}
          contingentLinkTypeId={data.contingentLinkTypeId}
          updateRequest={updateRequest}
        />
      )}

      {!editMode && <Organization data={data} />}
      {(!editMode || editMode === 'applicant') && (
        <ApplicantForm
          requestApplicant={data.applicant}
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          editable={!btnEditDisabled}
        />
      )}
      {!editMode && <Contract data={data.contract} />}
      {!editMode && (
        <Education
          data={data.trainingGroup}
          educationType={data.educationTypeId}
        />
      )}
      {(!editMode || editMode === 'actions') && actionEnable && (
        <ActionsForm
          enrollment={data.enrollment}
          decline={data.decline}
          requestStatusId={data.requestStatusId}
          serviceId={data.trainingGroup?.serviceId}
          requestId={data.id}
          updateRequest={updateRequest}
          requestData={data}
          extendEntityGuid={data.extendEntityGuid}
        />
      )}
    </>
  );
};

export default Details;
