import React, { useCallback, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { ServiceDataInfo } from '../../../../types/service';

type OrganizationProps = FieldProps<SelectOptionType> & { isCreate?: boolean; isForDayCareSchool?: boolean };

const OrganizationField = ({
  label = 'Общеобразовательная организация',
  name,
  editMode,
  required,
  parent,
  isCreate,
  disabled,
  isForDayCareSchool = false,
}: OrganizationProps) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const organizationId = parent ? `${parent}.${name}Id` : `${name}Id`;
  const organizationName = parent ? `${parent}.${name}Name` : `${name}Name`;

  const fetchOptions = useCallback(
    async (query: string) => await lookupApi.getOrganization(query, userProfile.vedomstvoId, isForDayCareSchool),
    [userProfile.vedomstvoId, isForDayCareSchool]
  );

  const { setFieldValue } = useFormikContext<ServiceDataInfo>();

  const [fieldLabel, , helpersLabel] = useField(organizationName);
  const [fieldValue, , helpersValue] = useField(organizationId);

  useEffect(() => {
    if (isCreate) {
      setFieldValue(organizationId, userProfile.organizationId);
      setFieldValue(organizationName, userProfile.organizationName);
    }
  }, [
    isCreate,
    organizationId,
    organizationName,
    setFieldValue,
    userProfile.organizationId,
    userProfile.organizationName,
  ]);

  return (
    <Field
      dataTest={name}
      label={label}
      editMode={editMode}
      required={required}
      value={fieldLabel.value}
    >
      <FormikSelect
        name={organizationId}
        required
        size="small"
        disabled={disabled}
        placeholder="Начните вводить..."
        loadOptions={fetchOptions}
        defaultValue={
          fieldValue.value
            ? {
                label: fieldLabel.value,
                value: fieldValue.value as number,
              }
            : null
        }
        selectedValue={(selected?: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : '');
          helpersValue.setValue(selected ? selected.value : null);
        }}
        options={[]}
      />
    </Field>
  );
};

export default OrganizationField;
