import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmButton, LmInfoBox, InteractiveChevronTemplate, LmListItem } from '@mes-ui/lemma';
import TableOptions from '../../../../components/table-options';
import DropDown from '../../../../components/drop-down';
import Dialog from '../../../../components/modals/dialog';
import { ScheduleData, TrainingGroupData } from '../../../../types/service';
import { apply, buildScheduleDisplayName } from '../../../../lib/utils/service';
import { formatDate, formatTime } from '../../../../lib/utils/date';
import history from '../../../../history';
import { routes } from '../../../../config/constants';
import { generateLink, generateUrlSearchString, hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../../types/authorization-data';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import SignModal from '../../../../components/sign-modal';
import { accessVedomst } from '../../../../mock-data/access-enum';
import { EducationTypeEnum } from '../../../../types/education-type';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  editable?: boolean;
  serviceId: number;
  tg: TrainingGroupData | undefined;
  getTrainingGroup: () => Promise<void>;
};

const TestsList: React.FC<Props> = ({ editable, serviceId, tg, getTrainingGroup }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const [signSchedule, setSignSchedule] = useState<ScheduleData | undefined>();
  const [removedId, setRemovedId] = useState<number | undefined>();

  const canEdit = useCallback(
    (schedule: ScheduleData) =>
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasAccessObjectAny(
          userProfile,
          [accessObject.ServiceArtHouse, accessObject.ServiceSport],
          accessAction.Edit
        )) &&
      schedule.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive,
    [userProfile]
  );

  const canRemove = useCallback(
    (schedule: ScheduleData): boolean =>
      canEdit(schedule) &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      schedule.requestTotalCount === 0,
    [canEdit]
  );
  const canPublicate = useCallback(
    (schedule: ScheduleData): boolean =>
      canEdit(schedule) &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      (!hasGeneralAccess(userProfile, generalAccess.UseSign) || schedule.isRetro),
    [canEdit, userProfile]
  );

  const canSign = useCallback(
    (schedule: ScheduleData): boolean =>
      canEdit(schedule) &&
      !schedule.isRetro &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      hasGeneralAccess(userProfile, generalAccess.UseSign),
    [userProfile, canEdit]
  );

  const canOnlyView = (schedule: ScheduleData): boolean =>
    schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Archive;

  const canCreateRequest = (schedule: ScheduleData): boolean => {
    return (
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasAccessObjectAny(
          userProfile,
          [accessObject.RequestArtHouse, accessObject.RequestSport],
          accessAction.Edit
        )) &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
      schedule.isAccessible &&
      (userProfile.vedomstvoId !== accessVedomst.Dsit || new Date().getMonth() > 9)
    );
  };

  const updateStatus = useCallback(
    async (scheduleId: number, statusId: TrainingGroupStatusEnum) => {
      await serviceTemplateApi.updateTrainingScheduleStatus({
        serviceId,
        educationTypeId: tg?.educationTypeId,
        scheduleOfTimetableId: scheduleId,
        trainingGroupStatusId: statusId,
      });
      getTrainingGroup();
    },
    [tg, serviceId, getTrainingGroup]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Расписание вступительных испытаний{' '}
            <span className="color-gray-dark">
              {' \u00A0'} {tg?.scheduleList?.length ?? 0}
            </span>
          </>
        )}
        headingControl={() => {
          return (
            editable &&
            (tg?.id ?? 0) > 0 && (
              <DropDown
                dataTest="addTest"
                component={(open) => (
                  <LmButton
                    dataTest="addTest"
                    type="button"
                    variant="outline"
                  >
                    Добавить
                    <InteractiveChevronTemplate open={open} />
                  </LmButton>
                )}
              >
                <>
                  <LmListItem
                    dataTest="tests"
                    text="Вступительные испытания"
                    onClick={() => {
                      history.push(
                        `${generateLink(routes.entranceTests, {
                          serviceId,
                          tgId: tg?.id ?? 0,
                          id: 0,
                        })}?isRetro=0`
                      );
                    }}
                  />
                  <LmListItem
                    dataTest="testsWithoutPublication"
                    text="Вступительные испытания без публикации на Mos.ru"
                    onClick={() => {
                      history.push(
                        `${generateLink(routes.entranceTests, {
                          serviceId,
                          tgId: tg?.id ?? 0,
                          id: 0,
                        })}?isRetro=1`
                      );
                    }}
                  />
                </>
              </DropDown>
            )
          );
        }}
      >
        {tg?.scheduleList ? (
          <SimpleTable
            data={tg.scheduleList}
            columns={[
              {
                dataIndex: 'date',
                title: 'Проведение испытаний',
                render: (item: any) => <span className="color-primary">{buildScheduleDisplayName(item)}</span>,
                width: '14%',
              },
              {
                dataIndex: 'address',
                title: 'Место проведения',
                render: (item: any) => item.placeOfTesting,
                width: '20%',
              },
              {
                dataIndex: 'amount',
                title: 'Размер группы / Подано / Черновиков /  Отказано',
                render: (item: any) => {
                  return (
                    <>
                      {item.volume}/
                      {item.requestTotalCount && (
                        <Link
                          to={{
                            pathname: routes.requests,
                            search: generateUrlSearchString({
                              serviceId: item.serviceId,
                              scheduleId: item.id,
                              educationTypeId: tg.educationTypeId ?? EducationTypeEnum.ArtHouseEducation,
                            }),
                          }}
                          target="_blank"
                          className="brand-link"
                        >
                          {item.requestTotalCount}
                        </Link>
                      )}
                      /
                      {item.requestDraftCount && (
                        <Link
                          to={{
                            pathname: routes.requests,
                            search: generateUrlSearchString({
                              serviceId: item.serviceId,
                              scheduleId: item.id,
                              requestStatusId: RequestStatusEnum.Draft,
                              educationTypeId: tg.educationTypeId ?? EducationTypeEnum.ArtHouseEducation,
                            }),
                          }}
                          target="_blank"
                          className="brand-link"
                        >
                          {item.requestDraftCount}
                        </Link>
                      )}
                      /
                      {item.requestDeclineCount && (
                        <Link
                          to={{
                            pathname: routes.requests,
                            search: generateUrlSearchString({
                              serviceId: item.serviceId,
                              scheduleId: item.id,
                              requestStatusId: RequestStatusEnum.IncludeDeclined,
                              educationTypeId: tg.educationTypeId ?? EducationTypeEnum.ArtHouseEducation,
                            }),
                          }}
                          target="_blank"
                          className="brand-link"
                        >
                          {item.requestDeclineCount}
                        </Link>
                      )}
                    </>
                  );
                },
                width: '16%',
              },
              {
                dataIndex: 'teacher',
                title: 'Преподаватель',
                render: (item: any) => item.teacher,
                width: '13%',
              },
              {
                dataIndex: 'periodMosru',
                title: 'Период приема заявлений на mos.ru',
                render: (item: any) => {
                  return (
                    <span>
                      {item.isRetro
                        ? 'Без публикации на Mos.ru'
                        : `c ${formatDate(item.requestStart || '')} ${
                            formatTime(item.requestTimeStart) || ''
                          } по ${formatDate(item.requestEnd || '')} ${formatTime(item.requestTimeEnd) || ''}`}
                    </span>
                  );
                },
              },
              {
                dataIndex: 'status',
                title: 'Статус расписания',
                render: (item: any) => item.trainingGroupStatusName,
                width: '13%',
              },
              {
                dataIndex: '',
                title: '',
                render: (item: any) => (
                  <DropDown
                    dataTest="testOptions"
                    component={() => <TableOptions />}
                  >
                    <>
                      {canPublicate(item) && (
                        <LmListItem
                          dataTest="publishTest"
                          text={item.isRetro ? 'Подтвердить' : 'Опубликовать'}
                          icon="outline-edit-checkmark-circle"
                          iconSize={20}
                          onClick={async () => await updateStatus(item.id, TrainingGroupStatusEnum.Signed)}
                        />
                      )}
                      {canSign(item) && (
                        <LmListItem
                          dataTest="signTest"
                          text="Подписать"
                          icon="outline-edit-edit"
                          iconSize={20}
                          onClick={() => {
                            setSignSchedule(item);
                          }}
                        />
                      )}
                      {canCreateRequest(item) && (
                        <LmListItem
                          dataTest="addRequest"
                          text="Подать заявление"
                          icon="outline-files-file"
                          iconSize={20}
                          onClick={async () => {
                            await apply(item.id);
                          }}
                        />
                      )}
                      {canEdit(item) && (
                        <LmListItem
                          dataTest="editTest"
                          text="Редактировать"
                          icon="outline-edit-edit"
                          iconSize={20}
                          onClick={() => {
                            history.push(
                              `${generateLink(routes.entranceTests, {
                                serviceId,
                                tgId: tg?.id ?? 0,
                                id: item.id,
                              })}?isRetro=${item.isRetro ? 1 : 0}`
                            );
                          }}
                        />
                      )}
                      {canRemove(item) && (
                        <LmListItem
                          dataTest="deleteTest"
                          text="Удалить"
                          icon="outline-edit-trash-alt"
                          iconSize={20}
                          onClick={() => {
                            setRemovedId(item.id);
                          }}
                        />
                      )}
                      {canOnlyView(item) && (
                        <LmListItem
                          dataTest="viewTest"
                          text="Просмотреть"
                          icon="outline-view-eye"
                          iconSize={20}
                          onClick={() => {
                            history.push(
                              `${generateLink(routes.entranceTests, {
                                serviceId,
                                tgId: tg?.id ?? 0,
                                id: item.id,
                              })}?isRetro=${item.isRetro ? 1 : 0}`
                            );
                          }}
                        />
                      )}
                    </>
                  </DropDown>
                ),
                width: '40px',
              },
            ]}
          />
        ) : (
          <div className="container">
            <LmInfoBox
              dataTest="testWarning"
              className="infobox--full-width"
              variant="warning"
              description="Расписание вступительных испытаний станет доступным для ввода после первого сохранения."
              hidenFooter
            />
            <Push size={24} />
          </div>
        )}
      </Panel>
      <Dialog
        dataTest="deleteTrainingSchedule"
        isOpenDialog={!!removedId}
        title="Удаление расписания вступительных испытаний"
        description="Вы уверены, что хотите удалить выбранное расписание вступительных испытаний? Обратите внимание, что вся информация о выбранном расписании вступительных испытаний будет утеряна."
        variant="alert"
        buttonPrimaryText="Да, удалить"
        callCloseAfterSubmit
        onClickPrimaryButton={async () => {
          if (removedId) {
            await serviceTemplateApi.deleteTrainingSchedule(serviceId, removedId);
            getTrainingGroup();
          }
        }}
        onClickSeconadaryButton={() => {
          setRemovedId(undefined);
        }}
      />
      <SignModal
        show={!!signSchedule}
        onCloseHandle={() => {
          setSignSchedule(undefined);
        }}
        ids={[
          {
            id: 0,
          },
        ]}
        getDataForSignHandle={async () => JSON.stringify(signSchedule)}
        setSignedDataHandle={async (id, sign) => {
          if (signSchedule) {
            try {
              await serviceTemplateApi.signTrainingSchedule({
                serviceId,
                educationTypeId: tg?.educationTypeId,
                signedDocument: sign,
                scheduleOfTimetableId: signSchedule.id,
              });
              setSignSchedule(undefined);
              getTrainingGroup();
            } catch {}
          }
        }}
      />
    </>
  );
};

export default TestsList;
