import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmLoader } from '@mes-ui/lemma';
import SavePanel from '../../../components/save-panel';
import FIO from '../components/fields/fio';
import BirthDate from '../components/fields/birthdate';
import Gender from '../../../components/fields/gender';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import MoscowRegistration from '../components/fields/moscow-registration';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import { PersonRequestData } from '../../../types/requests';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import {
  birthRecordIssued,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  lastName,
  middleName,
  stringRequired,
} from '../../../lib/utils/validation';
import { submitPersonRequestData } from '../utils';
import { DocumentTypeEnum } from '../../../mock-data/type-document';
import RegistryOffice from '../components/fields/registry-office';

type Props = {
  requestChild: PersonRequestData;
  contingentLinkTypeId: ContingentLinkTypeEnum;
  setEditModeParent: (value: string | null) => void;
  editable?: boolean;
  updateRequest: () => void;
};

const ChildForm: React.FC<Props> = ({
  requestChild,
  contingentLinkTypeId,
  setEditModeParent,
  editable,
  updateRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialErrors = useInitialErrors(requestChild, childFormValidationSchema);

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      await submitPersonRequestData(values, true, setLoading, setEditMode, setEditModeParent, updateRequest);
    },
    [setEditModeParent, updateRequest]
  );

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      onSubmit={submitForm}
      enableReinitialize
      initialValues={requestChild}
      validationSchema={childFormValidationSchema}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { submitForm, isSubmitting, isValid, resetForm, values } = formikProps;
        const isBirthRecord = values.documentTypeId === DocumentTypeEnum.BirthRecord;

        return (
          <form>
            <Push size={12} />
            <Panel
              title={() => 'Информация о ребенке'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('child');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <FIO
                    label="ФИО учащегося"
                    contingent={contingentLinkTypeId}
                    editMode={editMode}
                    disabled={editMode}
                  />
                  <BirthDate
                    name="dateOfBirth"
                    editMode={editMode}
                    disabled={editMode}
                  />
                  <Gender
                    name="sex"
                    editMode={editMode}
                    disabled={editMode}
                  />
                  <Document
                    name="documentType"
                    dependentFields
                    editMode={editMode}
                    disabled={editMode}
                  />
                  <DocumentNumber
                    editMode={editMode}
                    disabled={editMode}
                    calendarPosition="top-end"
                  />
                  {isBirthRecord ? (
                    <RegistryOffice
                      disabled={editMode}
                      name="issued"
                      codeName="issuerCode"
                      editMode={editMode}
                    />
                  ) : null}
                  <MoscowRegistration
                    editMode={editMode}
                    name="moscowRegistration"
                  />
                </div>
              </div>
            </Panel>
            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ChildForm;

const childFormValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  documentTypeId: stringRequired,
  dateOfIssue: dateOfIssue(true),
  series: documentSeries(true),
  number: documentNumber(true),
  issued: birthRecordIssued,
});
