/// Статус договора в заявлении
export enum ContractStatusEnum {
  // Проект договра
  Draft = 1,
  // Договор подписан
  Signed = 2,
  // Договор расторгнут
  Terminated = 3,
  // Договор аннулирован
  Canceled = 4,
  // Требуется подписание со стороны организации
  SigningRequiredByOrganization = 5,
}
