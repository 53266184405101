import React, { useContext } from 'react';
import FormikInput from '../../../../../../components/formik/formik-input';
import { DocumentModalContext } from '../../index';

type Props = {
  newDocument: boolean;
};

export const DocNumber: React.FC<Props> = ({ newDocument }) => {
  const { adminEdit } = useContext(DocumentModalContext);

  return (
    <FormikInput
      label="Номер"
      required
      size="small"
      name="docNumber"
      placeholder="Введите номер"
      disabled={!newDocument && !adminEdit}
    />
  );
};
