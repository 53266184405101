import { Certificate, getUserCertificates, createAttachedSignature } from 'crypto-pro';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox, LmRadio } from '@mes-ui/lemma';
import SelectCertificate from '../certificate';
import { authApi } from '../../lib/api';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { hasGeneralAccess } from '../../lib/utils';
import { generalAccess } from '../../types/authorization-data';
import { EducationTypeEnum } from '../../types/education-type';
import Popup from '../modals/popup';

type SignModalProps = {
  show: boolean;
  onCloseHandle: () => void;
  ids: { id: number; educationTypeId?: EducationTypeEnum }[];
  getDataForSignHandle: (id: number) => Promise<string>;
  setSignedDataHandle: (id: number, sign: string, educationTypeId?: EducationTypeEnum) => Promise<void>;
};

const SignModal = ({ show, onCloseHandle, ids, getDataForSignHandle, setSignedDataHandle }: SignModalProps) => {
  const [pluginError, showPluginError] = useState(false);
  const [certificateError, showCertificateError] = useState(false);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [certificate, setCertificate] = useState<Certificate | null>(null);
  const [isLoad, setIsLoad] = useState(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isFakeSign = hasGeneralAccess(userProfile, generalAccess.UseFakeSign);

  useEffect(() => {
    const getCert = async () => {
      try {
        const certs = await getUserCertificates();

        setCertificates(certs);
      } catch (error) {
        showPluginError(true);
      }
    };

    if (show && !isFakeSign) {
      getCert();
    }
  }, [show, isFakeSign]);

  const sign = useCallback(async () => {
    setIsLoad(true);
    if (isFakeSign) {
      for (const id of ids) {
        await setSignedDataHandle(id.id, '', id.educationTypeId);
      }
      onCloseHandle();
    }
    if (certificate) {
      if (await authApi.checkSign(await createAttachedSignature(certificate.thumbprint, 'ui'))) {
        for (const id of ids) {
          const data = await getDataForSignHandle(id.id);
          const dataSign = await createAttachedSignature(certificate?.thumbprint, data);

          await setSignedDataHandle(id.id, dataSign, id.educationTypeId);
        }
        onCloseHandle();
      } else {
        showCertificateError(true);
      }
    }
    setIsLoad(false);
  }, [ids, certificate, onCloseHandle, getDataForSignHandle, setSignedDataHandle, isFakeSign]);

  return (
    <Popup
      dataTest="certificatePopup"
      open={show}
      title="Выбор сертификата"
      primaryButtonModifiers={{
        loading: isLoad,
        disabled: !certificate && !isFakeSign,
      }}
      buttonPrimaryText="Подписать"
      onClose={onCloseHandle}
      onSubmit={sign}
    >
      {isFakeSign ? (
        <LmInfoBox
          dataTest="fakeSign"
          className="infobox--full-width"
          variant="success"
          description="Подписание произойдет фиктивной подписью"
          hidenFooter
        />
      ) : certificates.length > 0 ? (
        <>
          {certificates.map((cert) => (
            <>
              <LmRadio
                boxSize="large"
                name={cert.thumbprint}
                dataTest={cert.thumbprint}
                onChange={() => {
                  showCertificateError(false);
                  setCertificate(cert);
                }}
                checked={certificate?.thumbprint === cert.thumbprint}
              >
                <SelectCertificate
                  name={cert.name}
                  validTo={cert.validTo}
                />
              </LmRadio>
              <Push
                orientation="vertical"
                size={8}
              />
            </>
          ))}
          {certificateError && (
            <LmInfoBox
              dataTest="certificateError"
              className="infobox--full-width"
              variant="alert"
              description="Выбранный сертификат не подходит для подписания. Выберите другой сертификат."
              hidenFooter
            />
          )}
        </>
      ) : pluginError ? (
        <LmInfoBox
          dataTest="pluginError"
          className="infobox--full-width"
          variant="alert"
          description={
            <>
              Ошибка загрузки плагина «КриптоПро ЭЦП Browser plug-in». Для входа с помощью электронной подписи требуется
              установленный на компьютер плагин «КриптоПро ЭЦП Browser plug-in». Вы можете загрузить его, перейдя по
              ссылке:{' '}
              <a href="https://cryptopro.ru/products/cades/plugin/get_2_0">Скачать КриптоПро ЭЦП Browser plug-in</a>
            </>
          }
          hidenFooter
        />
      ) : (
        <span className="auth-not-certificate">У вас нет доступных для выбора сертификатов.</span>
      )}
    </Popup>
  );
};

export default SignModal;
