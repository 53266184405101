import React, { useState, useEffect } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps, useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmRadio } from '@mes-ui/lemma';

import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { PlaceServiceData, ScheduleData } from '../../../../types/service';
import { ScheduleDataEx } from '../tests';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  editCallBack: () => void;
  showEditButton: boolean;
  editMode: boolean;
  serviceId: string;
  scheduleId: number;
};

const PlaceTests: React.FC<Props> = ({ editCallBack, editMode, serviceId, showEditButton, scheduleId }) => {
  const [options, setOptions] = useState<PlaceServiceData[]>();
  const { values, setFieldValue } = useFormikContext<ScheduleDataEx>();
  const enabledOnlyDraft = values.trainingGroupStatusId === TrainingGroupStatusEnum.Draft;

  useEffect(() => {
    const fetch = async () => {
      const result = await serviceTemplateApi.getPlaceServices({
        serviceId,
        withOrgServicePlaces: true,
      });

      setOptions(result);
      if (result?.length > 0 && scheduleId === 0) {
        setFieldValue('placeServiceId', result[0].id);
      }
    };

    if (serviceId && editMode) {
      fetch();
    }
  }, [serviceId, setFieldValue, scheduleId, editMode]);

  return (
    <FormikField>
      {({ form }: FormikFieldProps<any, ScheduleData>) => {
        const { values, setFieldValue } = form;

        return (
          <>
            <Push size={12} />
            <Panel
              title={() => <>Место проведения испытаний</>}
              headingControl={() => {
                return !editMode && showEditButton ? (
                  <button
                    type="button"
                    onClick={editCallBack}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              {editMode ? (
                <SimpleTable
                  key={values.placeServiceId}
                  data={
                    options?.map((e, i) => {
                      return {
                        ...e,
                        number: i + 1,
                      };
                    }) ?? []
                  }
                  columns={[
                    {
                      dataIndex: '',
                      title: '',
                      render: (item: any) => (
                        <LmRadio
                          name="address"
                          dataTest={item.number}
                          onChange={() => {
                            setFieldValue('placeServiceId', item.id);
                            setFieldValue('placeOfTesting', item.fullAddress);
                            setFieldValue('placeOfTestingName', item.name);
                          }}
                          checked={values.placeServiceId ? item.id === values.placeServiceId : false}
                          disabled={!enabledOnlyDraft}
                          resetAllPadding
                        />
                      ),
                      width: '60px',
                    },
                    {
                      dataIndex: 'number',
                      title: '№',
                      render: (item: any) => item.number,
                      width: '40px',
                    },
                    {
                      dataIndex: 'name',
                      title: 'Наименование организации',
                      render: (item: any) => item.name,
                      width: '50%',
                    },
                    {
                      dataIndex: 'address',
                      title: 'Адрес',
                      render: (item: any) => item.fullAddress,
                    },
                  ]}
                />
              ) : (
                <SimpleTable
                  data={[
                    {
                      number: 1,
                      name: values.placeOfTestingName,
                      fullAddress: values.placeOfTesting,
                    },
                  ]}
                  columns={[
                    {
                      dataIndex: 'number',
                      title: '№',
                      render: (item: any) => item.number,
                      width: '40px',
                    },
                    {
                      dataIndex: 'name',
                      title: 'Наименование организации',
                      render: (item: any) => item.name,
                      width: '50%',
                    },
                    {
                      dataIndex: 'address',
                      title: 'Адрес',
                      render: (item: any) => item.fullAddress,
                    },
                  ]}
                />
              )}
            </Panel>
          </>
        );
      }}
    </FormikField>
  );
};

export default PlaceTests;
