import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import FormikInput from '../../../../components/formik/formik-input';
import { setAsyncValues } from '../../../../lib/utils/requests';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { Dictionary } from '../../components/fields/dictionary';
import { ServiceData } from '../../../../types/service';
import { TypeFinancingEnum } from '../../../../mock-data/type-financing-enum';
import { priceMask } from '../../../../lib/utils/mask';

const Financing = () => {
  const { values, dirty, setFieldValue } = useFormikContext<ServiceData>();

  const showFields = values.financing && values.financing.typeFinancingId !== TypeFinancingEnum.Free;

  useEffect(() => {
    if (dirty) {
      const fieldsToClean = [
        {
          key: 'financing.typeValueServiceId',
        },
        {
          key: 'financing.typeValueServiceName',
        },
        {
          key: 'financing.fullPrice',
        },
        {
          key: 'financing.lessonPrice',
        },
        {
          key: 'financing.periodPrice',
        },
        {
          key: 'financing.subsidiesPercent',
        },
      ];

      setAsyncValues(fieldsToClean, setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.financing?.typeFinancingId]);

  return (
    <Panel title={() => <>Финансирование</>}>
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Тип финансирования <span className="table-data__required" />
          </div>
          <div className="table-data__body">
            <Dictionary
              name="typeFinancing"
              parent="financing"
              editMode
              isNotTableMain
              dictionaryFunc={() => dictionariesApi.getTypeFinancingList(true)}
            />
          </div>
        </div>
        {showFields && (
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Периодичность оплаты <span className="table-data__required" />
            </div>
            <div className="table-data__body">
              <Dictionary
                name="typeValueService"
                parent="financing"
                editMode
                isNotTableMain
                dictionaryFunc={dictionariesApi.getTypeValueServiceList}
              />
            </div>
          </div>
        )}

        {showFields && (
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">Стоимость, руб</div>
            <div className="table-data__body">
              <div className="flex items-start">
                <div className="table-data__group">
                  <div className="table-data__label">
                    Общая <span className="table-data__required" />
                  </div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 140,
                    }}
                  >
                    <FormikInput
                      name="financing.fullPrice"
                      maskRegex={priceMask}
                      size="small"
                      placeholder="0,00"
                      textRight
                      rightPrefix="₽"
                    />
                  </div>
                </div>
                <Push
                  size={16}
                  orientation="horizontal"
                />
                <div className="table-data__group">
                  <div className="table-data__label">
                    За период <span className="table-data__required" />
                  </div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 140,
                    }}
                  >
                    <FormikInput
                      name="financing.periodPrice"
                      maskRegex={priceMask}
                      size="small"
                      placeholder="0,00"
                      textRight
                      rightPrefix="₽"
                    />
                  </div>
                </div>
                <Push
                  size={16}
                  orientation="horizontal"
                />
                <div className="table-data__group">
                  <div className="table-data__label">За занятие</div>
                  <div
                    className="table-data__body"
                    style={{
                      width: 140,
                    }}
                  >
                    <FormikInput
                      name="financing.lessonPrice"
                      maskRegex={priceMask}
                      size="small"
                      placeholder="0,00"
                      textRight
                      rightPrefix="₽"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default Financing;
