import React, { FocusEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import FormikInput from '../../../../../components/formik/formik-input';
import { timeMaskFunction } from '../../../../../lib/utils/mask';

type Props = {
  name: string;
  dependentDateName: string;
};

const removeEmptyCharacters = (time: string) => {
  return time.replace(/\s/g, '');
};

const roundingUpTime = (str: string) => {
  const splitAr = str.split(':');

  if (splitAr.length === 2) {
    const minutes = parseInt(splitAr[1], 10);
    let round = '00';

    if (minutes > 0 && minutes <= 15) {
      round = '15';
    } else if (minutes > 15 && minutes <= 30) {
      round = '30';
    } else if (minutes > 30 && minutes <= 45) {
      round = '45';
    }

    return `${splitAr[0]}:${round}`;
  }

  return str;
};

const TimeField: React.FC<Props> = ({ name, dependentDateName }) => {
  const { setFieldValue } = useFormikContext<any>();

  const [dependentDateFieldName] = useField(dependentDateName);

  const handleBlur = (ev: FocusEvent<HTMLInputElement>) => {
    const roundTime = ev.target.value ? roundingUpTime(removeEmptyCharacters(ev.target.value)) : '';

    setFieldValue(name, roundTime);

    if (dependentDateFieldName.value) {
      const [hours, minutes] = roundTime.split(':');

      const initialHourMinutesForDate = moment(dependentDateFieldName.value).set('hour', 0).set('minute', 0).toDate();

      const newDateStartMos = moment(initialHourMinutesForDate).add(hours, 'hours').add(minutes, 'minutes').toDate();

      setFieldValue(dependentDateName, newDateStartMos);
    }
  };

  return (
    <FormikInput
      name={name}
      maskRegex={timeMaskFunction}
      size="small"
      placeholder="00:00"
      rightInsideItem={{
        icon: 'outline-misc-clock',
      }}
      onBlur={handleBlur}
    />
  );
};

export default TimeField;
