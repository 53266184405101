import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import Popup from '../../../../components/modals/popup';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceClassTransferStage } from '../../../../types/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import serviceClassApi from '../../../../lib/api/service-class';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';

const initialValues: ServiceClassTransferStage = {
  serviceStageId: 0,
  documentTypeId: 3,
  docNumber: '',
  docDate: '',
  comment: '',
  educationTypeId: 0,
  megaRelationIds: [],
};

type Props = {
  open: boolean;
  close: () => void;
};

const TransferStage: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, learners, selected, fetchTable, serviceClassData } =
    useContext(ServiceClassTableContext);

  const [documents, setDocuments] = useState<SelectOptionType[]>([]);
  const [stages, setStages] = useState<SelectOptionType[]>([]);

  const [loading, setLoading] = useState(false);

  const selectedPeople = learners.filter((value) => selected.includes(value.id));

  initialValues.serviceStageId = selectedPeople[0]?.serviceStageId || 0;

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const submitForm = useCallback(
    async (values: ServiceClassTransferStage) => {
      values.megaRelationIds = selectedPeople.map((item) => item.id);
      values.educationTypeId = serviceClassData.educationTypeId;
      await serviceClassApi.transferStage(serviceClassData.id || 0, values);
      fetchTable();
      setOpenDetails(false);
      setSelected([]);
      close();
    },
    [
      selectedPeople,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      close,
      fetchTable,
      setOpenDetails,
      setSelected,
    ]
  );

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const documentTypes = await dictionariesApi.getDocumentTypes(3);

        setDocuments(documentTypes);

        if (serviceClassData.serviceId || selectedPeople[0].serviceId) {
          const stages = await serviceTemplateApi.getServiceAvalableStageList(
            serviceClassData.serviceId || selectedPeople[0].serviceId,
            selectedPeople.map((item) => item.id)
          );

          setStages(stages);
        }
      } finally {
        setLoading(false);
      }
    };

    open && fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, serviceClassData.serviceId]);

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
      initialErrors={initialErrors}
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<any>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="transferStage"
              open={open}
              title="Перевод на другой этап обучения"
              loading={loading}
              buttonPrimaryText="Перевести"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid,
              }}
              onSubmit={handleSubmit}
              onClose={close}
              onClearData={resetForm}
            >
              <>
                <LmInfoBox
                  dataTest="stageInfo"
                  className="infobox--full-width"
                  variant="information"
                  description="Для перевода обучающихся доступны этапы, на которых на данный момент не обучается ни один из выбранных детей"
                  hidenFooter
                />
                <Push size={16} />
                <FormikSelect
                  label="Этап обучения"
                  required
                  name="serviceStageId"
                  size="small"
                  withSearch
                  options={stages}
                  placeholder="Выберите..."
                />
                <Push size={16} />
                <FormikSelect
                  name="documentTypeId"
                  label="Тип документа"
                  required
                  size="small"
                  options={documents}
                  placeholder="Выберите..."
                />
                <Push size={16} />
                <FormikInput
                  label="Номер документа"
                  size="small"
                  name="docNumber"
                  placeholder="Введите..."
                />
                <Push size={16} />
                <FormikDatePicker
                  label="Дата"
                  size="small"
                  name="docDate"
                  disablePortal={false}
                  placement="top-start"
                />
                <Push size={16} />
                <FormikTextarea
                  label="Причина перевода"
                  name="comment"
                  placeholder="Введите..."
                  maxRows={2}
                />
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default TransferStage;

const getValidationSchema = () =>
  objectYup().shape({
    serviceStageId: stringYup().nullable().required('Выберите этап обучения'),
    documentTypeId: stringYup().nullable().required('Выберите тип документа'),
    docDate: docDate.nullable(),
  });
