import { LmButton, LmIcon } from '@mes-ui/lemma';
import { Push } from '@mosru/esz_uikit';
import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { downloadOrganizationSign } from '../../../lib/utils/organization';
import { accessVedomst, generalAccess } from '../../../mock-data/access-enum';
import { hasGeneralAccess } from '../../../lib/utils';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { Organization } from '../../../types/organization';

type Props = {
  organization: Organization;
  showSignModal: (value: boolean) => void;
};

const SingInfo = ({ organization, showSignModal }: Props) => {
  const [signedDetails, setSignedDetails] = useState(false);
  const [signedDetailsOiv, setSignedDetailsOiv] = useState(false);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const accessUseSign =
    hasGeneralAccess(userProfile, generalAccess.UseSign) || hasGeneralAccess(userProfile, generalAccess.UseFakeSign);

  const canSign = accessUseSign || isAdminEdit;
  const showSignInfo =
    !organization?.isArchive &&
    !!organization &&
    (organization.vedomstvoId === accessVedomst.Dsit ||
      organization.vedomstvoId === accessVedomst.Kdc ||
      organization.vedomstvoId === accessVedomst.Dkgm);

  return showSignInfo ? (
    <>
      {organization?.vedomstvoId !== accessVedomst.Dsit &&
        (organization?.vedomstvoSign ? (
          <>
            <div className="infobox infobox--success">
              <div className="infobox__head">
                <div className="infobox__body">
                  <div className="flex">
                    <LmIcon
                      icon="filled-security-shield"
                      color="var(--LM-green-200)"
                      size={18}
                      className="flex-none"
                    />
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    <span className="color-success-dark font-weight-bold">Подписано электронной подписью ОИВ</span>
                  </div>
                </div>
                <div className="infobox__control">
                  <button
                    data-test="collapsibleButton"
                    type="button"
                    onClick={() => setSignedDetailsOiv(!signedDetailsOiv)}
                    className="icon-group"
                  >
                    <span className="icon-group__text color-success-dark">
                      {signedDetailsOiv ? 'Свернуть' : 'Подробнее о подписи'}
                    </span>
                    <span className={classNames('icon-group__icon', !signedDetailsOiv && 'icon-group__icon--rotate')}>
                      <LmIcon
                        icon="filled-chevrons-small-up"
                        color="var(--LM-green-200)"
                        size={20}
                        className="flex-none"
                      />
                    </span>
                  </button>
                </div>
              </div>

              {signedDetailsOiv && (
                <div className="infobox__details">
                  <div className="org-signed-details">
                    <div className="org-signed-details__item">
                      <div className="org-signed-details__label">Подписал:</div>
                      <div className="org-signed-details__text">{organization.vedomstvoSign.signer}</div>
                    </div>
                    <div className="org-signed-details__item">
                      <div className="org-signed-details__label">Дата и время:</div>
                      <div className="org-signed-details__text">
                        {moment(organization.vedomstvoSign.date).format('DD.MM.YYYY в HH:mm:ss')}
                      </div>
                    </div>
                    <div className="org-signed-details__item">
                      <div className="org-signed-details__label">Файл:</div>
                      <div className="org-signed-details__text">
                        <span
                          onClick={() => {
                            const id = organization.vedomstvoSign ? organization.vedomstvoSign.signId : 0;

                            downloadOrganizationSign(id);
                          }}
                        >
                          {organization.vedomstvoSign.fileName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Push
              size={8}
              orientation="vertical"
            />
          </>
        ) : (
          <>
            <div className="infobox infobox--danger">
              <div className="infobox__head">
                <div className="infobox__body">
                  <div className="flex">
                    <LmIcon
                      icon="filled-notifications-alert-triangle"
                      size={18}
                      color="var(--LM-red-200)"
                      className="flex-none"
                    />
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    <span className="color-danger font-weight-bold">Не подписано электронной подписью ОИВ</span>
                  </div>
                </div>
              </div>
            </div>
            <Push
              size={8}
              orientation="vertical"
            />
          </>
        ))}

      {organization?.organizationSign ? (
        <div className="infobox infobox--success">
          <div className="infobox__head">
            <div className="infobox__body">
              <div className="flex">
                <LmIcon
                  icon="filled-security-shield"
                  color="var(--LM-green-200)"
                  size={18}
                  className="flex-none"
                />
                <Push
                  size={8}
                  orientation="horizontal"
                />
                <span className="color-success-dark font-weight-bold">Подписано электронной подписью организации</span>
              </div>
            </div>
            <div className="infobox__control">
              <button
                data-test="collapsibleButton"
                type="button"
                onClick={() => setSignedDetails(!signedDetails)}
                className="icon-group"
              >
                <span className="icon-group__text color-success-dark">
                  {signedDetails ? 'Свернуть' : 'Подробнее о подписи'}
                </span>
                <span className={classNames('icon-group__icon', !signedDetails && 'icon-group__icon--rotate')}>
                  <LmIcon
                    icon="filled-chevrons-small-up"
                    color="var(--LM-green-200)"
                    size={20}
                    className="flex-none"
                  />
                </span>
              </button>
            </div>
          </div>

          {signedDetails && (
            <div className="infobox__details">
              <div className="org-signed-details">
                <div className="org-signed-details__item">
                  <div className="org-signed-details__label">Подписал:</div>
                  <div className="org-signed-details__text">{organization.organizationSign.signer}</div>
                </div>
                <div className="org-signed-details__item">
                  <div className="org-signed-details__label">Дата и время:</div>
                  <div className="org-signed-details__text">
                    {moment(organization.organizationSign.date).format('DD.MM.YYYY в HH:mm:ss')}
                  </div>
                </div>
                <div className="org-signed-details__item">
                  <div className="org-signed-details__label">Файл:</div>
                  <div className="org-signed-details__text">
                    <span
                      onClick={() => {
                        const id = organization.organizationSign ? organization.organizationSign.signId : 0;

                        downloadOrganizationSign(id);
                      }}
                    >
                      {organization.organizationSign.fileName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="infobox infobox--danger">
          <div className="infobox__head">
            <div className="infobox__body">
              <div className="flex">
                <LmIcon
                  icon="filled-notifications-alert-triangle"
                  size={18}
                  color="var(--LM-red-200)"
                  className="flex-none"
                />
                <Push
                  size={8}
                  orientation="horizontal"
                />
                <span className="color-danger font-weight-bold">Не подписано электронной подписью организации</span>
              </div>
            </div>
            {canSign && (
              <div className="infobox__control">
                <LmButton
                  dataTest="sign"
                  type="button"
                  color="danger"
                  variant="outline"
                  onClick={() => showSignModal(true)}
                >
                  Подписать ЭП
                </LmButton>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default SingInfo;
