import React, { useContext, useMemo, useState } from 'react';
import { object as objectYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import SavePanel from '../../../../../components/save-panel';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import { addPhonePrefix } from '../../../../../lib/utils/format-number';
import { nullableMaxRusWithSpaceAndNumberStringAndSymbols, nullablePhone } from '../../../../../lib/utils/validation';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { ServiceContext } from '../../../index';
import TemplateContactPerson from './template-contact-person';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { ContactPersonData } from '../../../../../types/service';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const ContactPerson: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);

  const initialValues = useMemo(() => {
    return serviceData?.contactPerson || ({} as ContactPersonData);
  }, [serviceData?.contactPerson]);

  const initialErrors = useInitialErrors(initialValues, contactPersonValidationSchema());
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [loading, setLoading] = useState(false);

  const submitForm = async (values: ContactPersonData) => {
    setLoading(true);
    try {
      if (serviceData?.id) {
        const request = {
          ...values,
          phone: addPhonePrefix(values.phone),
        };

        await serviceTemplateApi.updateContactPerson(serviceData.id, request);
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        setLoading(false);
        updateService();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      validationSchema={contactPersonValidationSchema()}
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
    >
      {(formikProps: FormikProps<ContactPersonData>) => {
        const { isValid, handleSubmit, submitForm, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <TemplateContactPerson
              editMode={editMode}
              setEditMode={setEditMode}
              setEditModeParent={setEditModeParent}
              published={serviceData.serviceStatusId === ServiceStatusEnum.Signed}
              isArchive={serviceData.serviceStatusId === ServiceStatusEnum.Arhive}
              accessEditable={accessPanelEdit}
              organizationId={serviceData?.info?.organizationId}
            />

            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent && setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ContactPerson;

export const contactPersonValidationSchema = () =>
  objectYup().nullable().shape({
    fullName: nullableMaxRusWithSpaceAndNumberStringAndSymbols,
    phone: nullablePhone,
  });
