import React, { useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { generatePath } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import SavePanel from '../../../../components/save-panel';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import Financing from './financing';
import Photo from '../../components/panels/photo';
import Period from '../../child/panels/period';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ServiceData } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { Info } from './info';
import history from '../../../../history';
import { CreateContactPerson } from '../../components/panels/contact-person/create-contact-person';
import { CreateServiceContext } from '../../create';
import { routes } from '../../../../config/constants';
import { infoValidationSchema } from '../panels/info';
import { financingValidationSchema } from '../../components/panels/financing';
import { SelectOptionType } from '../../../../types/entities';
import { convertParallelInResponse, getHeaderLink, setGenderStartEndToBack } from '../../utils';

const DigitalTutorCreateCreateDetails = () => {
  const { serviceData, educationTypes, type } = useContext(CreateServiceContext);

  const initialErrors = useInitialErrors(serviceData, infoValidationSchema);

  const [load, setLoad] = useState<boolean>(false);

  const initialData = useMemo(() => {
    return {
      ...serviceData,
      info: {
        ...serviceData.info,
        isFemalePresent: true,
        isMalePresent: true,
        classificatorEKUId: undefined,
      },
      financing: {
        ...serviceData.financing,
        typeFinancingId: 2,
        typeValueServiceId: 1,
        typeFinancingName: 'Платно',
        typeValueServiceName: 'За месяц',
      },
    };
  }, [serviceData]);

  const submitForm = async (values: ServiceData) => {
    setLoad(true);
    try {
      if (values.contactPerson) {
        values.contactPerson.phone = addPhonePrefix(values.contactPerson.phone);
      }

      const idService = await serviceTemplateApi.createService({
        ...values,
        info: {
          ...setGenderStartEndToBack(values.info),
          parallelList: convertParallelInResponse(values.info.parallelList as SelectOptionType[]),
        },
      });

      history.push(
        generatePath(routes.service, {
          id: idService,
        })
      );
      setLoad(false);
    } finally {
      setLoad(false);
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
      initialValues={initialData}
      initialErrors={initialErrors}
      validationSchema={dataValidationSchema}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, submitForm, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Info />

            <CreateContactPerson parent="contactPerson" />

            <Push size={12} />

            <Panel title={() => 'Учебные периоды'}>
              <div className="container">
                <LmInfoBox
                  dataTest="periodWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Редактирование учебных периодов станет доступно после первого сохранения."
                  hidenFooter
                />
              </div>
              <Push size={24} />
            </Panel>

            <Push
              size={12}
              orientation="vertical"
            />

            <Period />

            <Financing />

            <Push size={12} />

            <Panel title={() => 'Планы приема'}>
              <div className="container">
                <LmInfoBox
                  dataTest="planWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Планы приема станут доступными для ввода после первого сохранения."
                  hidenFooter
                />
              </div>
              <Push size={24} />
            </Panel>

            <Photo />

            <SavePanel
              buttonPrimaryText="Создать услугу"
              primaryButtonModifiers={{
                loading: load,
                disabled: !isValid,
              }}
              onClickSeconadaryButton={() =>
                history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))
              }
              onClickPrimaryButton={submitForm}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default DigitalTutorCreateCreateDetails;

export const dataValidationSchema = () =>
  objectYup().shape({
    info: infoValidationSchema(),
    financing: financingValidationSchema(),
    contactPerson: contactPersonValidationSchema(),
  });
