import React, { Key, useState } from 'react';
import { LmButton, LmInput } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import { notificationMessages } from '../../../../lib/utils/learners';
import { notify } from '../../../../redux/ducks/notifications';
import { SplitModalListElement } from '../../../../types/learners';
import { allowOnlyNumbersNoSpace, preventInvalidPaste } from '../../../../lib/utils/validation';

type Props = {
  disabled?: boolean;
  data?: SplitModalListElement[];
  comparisonData?: SplitModalListElement[];
  onSelect: (value: string) => Promise<void>;
  labelId: string;
};

const MAX_VALUE = 2147483647;

const InputGroup = ({ onSelect, disabled, data, comparisonData, labelId }: Props) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState<string>();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const dispatch = useDispatch();

  const checkingMatch = comparisonData?.every((item) => item.id !== Number(value));
  const isUniqueValue = !data || data.every((item) => item.id !== Number(value));
  const disableBtn = !value || disabled || !!error || !isUniqueValue;

  const handleChangeValue = (value: Key) => {
    setValue(value as string);
    setError(value && Number(value) >= MAX_VALUE ? 'Введено недопустимое значение' : undefined);
  };

  const reset = () => {
    setValue('');
  };

  const handleSelect = async () => {
    if (checkingMatch) {
      try {
        setIsLoadingBtn(true);
        await onSelect(value);
      } finally {
        reset();
        setIsLoadingBtn(false);
      }
    } else {
      dispatch(
        notify.danger({
          dataTest: 'merge',
          title: notificationMessages.mergeError,
        })
      );
      reset();
    }
  };

  return (
    <div className="learners-form-group">
      <div className="learners-form-group__input">
        <LmInput
          dataTest={labelId}
          id={labelId}
          value={value}
          isError={!!error}
          explainText={error}
          resettable
          placeholder="Введите ID личного дела..."
          onChange={handleChangeValue}
          onKeyPress={(e) => allowOnlyNumbersNoSpace(e, true)}
          onPaste={(e) => preventInvalidPaste(e, /[^\d]/g)}
        />
      </div>
      <div className="learners-form-group__btn">
        <LmButton
          type="button"
          loading={isLoadingBtn}
          disabled={disableBtn}
          onClick={handleSelect}
        >
          Выбрать
        </LmButton>
      </div>
    </div>
  );
};

export default InputGroup;
