import { fetchRequest } from './index';
import { apiService } from '../../config/constants';

export const getPrivilegiesByPupil = async (data: { pupilId: string }): Promise<any> => {
  return await fetchRequest.get(`${apiService.data}/Learner/${data.pupilId}/Privilege`);
};

export const privilegiesApi = {
  getPrivilegiesByPupil,
};
