import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Panel } from '@mosru/esz_uikit';
import { historyState } from '../../mock-data/history-state';
import { entityTypeEnum, pageSizeOptions } from '../../mock-data';
import RemoveModal from '../../components/remove-modal';
import { FROM_PAGE } from '../../mock-data/query';
import { Favorite, SearchFavoriteFormData } from '../../types/favorite';
import FavoriteEdit from './edit';
import { generateLink, getServiceEditLink, replaceHistoryState, sliceText } from '../../lib/utils';
import { EntityTypeEnum } from '../../mock-data/entity-type-enum';
import { maxRegistryRows, routes } from '../../config/constants';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { useDeleteFavoriteMutation, useGetFavoritesQuery, useUpdateFavoriteMutation } from '../../store/favorites';
import AsyncTable from '../../components/table/async-table';

type Props = {
  search: SearchFavoriteFormData;
};

const getCurrentPageLink: { [index: number]: string } = {
  [EntityTypeEnum.Request]: routes.request,
  [EntityTypeEnum.Service]: routes.service,
  [EntityTypeEnum.Organization]: routes.organization,
  [EntityTypeEnum.ServiceClass]: routes.serviceClass,
  [EntityTypeEnum.Pupil]: routes.learner,
};

const FavoriteTable = ({ search }: Props) => {
  const [pageSize, setPageSize] = useState<number>(
    window.history.state[historyState.pageSize] || pageSizeOptions[0].value
  );
  const [removedId, setRemovedId] = useState(0);
  const [editObj, setEditObj] = useState<Favorite | null>();
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);

  const {
    data = {
      items: [],
      total: 0,
    },
    isFetching,
  } = useGetFavoritesQuery({
    startDate: search.startDate,
    endDate: search.endDate,
    entityTypeId: search.entityTypeId,
    query: search.query,
    date: search.date,
    pageSize,
    pageNumber: pageNum + 1,
  });
  const [deleteFavorite] = useDeleteFavoriteMutation();
  const [updateFavorite] = useUpdateFavoriteMutation();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    if (initPageNum) {
      setInitPageNum(0);
    } else {
      setPageNum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    replaceHistoryState({
      [historyState.pageSize]: pageSize,
    });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({
      [historyState.pageNumber]: pageNum,
    });
  }, [pageNum]);

  const onRemoveHandler = async () => {
    deleteFavorite(removedId);

    const elementsCurrentPage = data.total - pageSize * pageNum; // узнаем сколько элементов на посл.странице

    pageNum && elementsCurrentPage === 1 && setPageNum(pageNum - 1);

    setRemovedId(0);
  };

  return (
    <Panel
      title={() => (
        <>
          Избранные записи
          <span className="color-gray-dark">
            {' \u00A0'}
            {data.total}
          </span>
        </>
      )}
    >
      <AsyncTable
        loading={isFetching}
        pageSize={pageSize}
        setPageSize={setPageSize}
        data={data.items}
        setPageNumber={setPageNum}
        pageNum={pageNum}
        itemsAll={data.total}
        rowLimit={maxRegistryRows}
        loaderTitle="Загружаем избранные"
        loaderDescription="Нужно немного подождать"
        columns={[
          {
            dataIndex: 'entityName',
            title: 'Объект',
            render: (item: any) => {
              return (
                !item.isDeleted && (
                  <Link
                    to={{
                      pathname:
                        item.entityTypeId === EntityTypeEnum.Service
                          ? getServiceEditLink(item.educationTypeId, item.entityId)
                          : item.entityTypeId === EntityTypeEnum.Organization &&
                            item.entityId === userProfile.organizationId
                            ? routes.organizationInfo
                            : generateLink(getCurrentPageLink[item.entityTypeId], {
                              id: item.entityId,
                            }),
                      search: `${FROM_PAGE}=favorite`,
                    }}
                    className="brand-link"
                  >
                    {sliceText(item.entityName)}
                  </Link>
                )
              );
            },
            width: '15%',
          },
          {
            dataIndex: 'entityTypeId',
            title: 'Тип объекта',
            render: (item: any) => entityTypeEnum.find((type) => type.value === item.entityTypeId.toString())?.label,
            width: '13%',
          },
          {
            dataIndex: 'dateCreate',
            title: 'Добавлен',
            render: (item: any) => moment(item.dateCreate).format('DD.MM.yyyy HH:mm'),
            width: '14%',
          },
          {
            dataIndex: 'comment',
            title: 'Комментарий',
            render: (item: any) => (
              <FavoriteEdit
                setRemovedId={() => setRemovedId(item.id)}
                key={data.items.length}
                comment={editObj?.id === item.id ? editObj?.comment : item.comment}
                setComment={(comment) =>
                  setEditObj(
                    (prev) =>
                      prev && {
                        ...prev,
                        comment,
                      }
                  )
                }
                setEditMode={(mode) => {
                  setEditObj(
                    mode
                      ? item
                      : {
                          id: 0,
                          name: '',
                          code: '',
                        }
                  );
                }}
                editMode={editObj?.id === item.id}
                submitHandler={async () => {
                  updateFavorite({
                    ...item,
                    comment: editObj?.comment?.trim(),
                  });
                  setEditObj(null);
                }}
              />
            ),
          },
        ]}
      />
      <RemoveModal
        title="Избранное"
        description="Внимание! Данный объект будет удален из реестра «избранных» объектов. Хотите продолжить?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={onRemoveHandler}
      />
    </Panel>
  );
};

export default memo(FavoriteTable);
