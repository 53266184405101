import React from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import FormikSelect from '../../../../../../components/formik/formik-select';
import lookupApi from '../../../../../../lib/api/lookup';
import { DocumentDataFinishedAndTypes } from '../../../../../../types/learners';
import FormikInput from '../../../../../../components/formik/formik-input';
import { SelectOptionType } from '../../../../../../types/entities';

type Props = {
  listModules: SelectOptionType[];
  accessCheck: boolean;
  programName: string;
  organization: SelectOptionType | null;
  setAcceptEducationProgram: (value: SelectOptionType) => void;
  unionCatalogServicesId: number;
  setOrganization: (value: SelectOptionType | null) => void;
  documentDataFinishedAndTypes: DocumentDataFinishedAndTypes;
  acceptEducationProgram: SelectOptionType;
  hasCreateDocument: boolean;
};
export const AcceptFields: React.FC<Props> = ({
  setAcceptEducationProgram,
  programName,
  unionCatalogServicesId,
  accessCheck,
  documentDataFinishedAndTypes,
  setOrganization,
  acceptEducationProgram,
  listModules,
  organization,
  hasCreateDocument,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();

  const defaultValueEducation =
    programName && unionCatalogServicesId
      ? {
          label: programName,
          value: unionCatalogServicesId,
        }
      : null;

  const isDisabledModule = !acceptEducationProgram || !acceptEducationProgram.value;

  return (
    <>
      <FormikSelect
        size="small"
        name="catalogId"
        required
        label="Образовательная программа"
        selectedValue={(option?: SelectOptionType) => {
          if (option && option.additionalPropertiesJson) {
            const parse = JSON.parse(option.additionalPropertiesJson);

            setOrganization({
              label: parse.OrganizationName,
              value: parse.OrganizationId,
            });
            setAcceptEducationProgram(option);

            setFieldValue('moduleName', null);
            setFieldValue('moduleId', null);

            setFieldValue('catalogId', option.value);
            setFieldValue('catalogName', option.label);

            if (values.acceptEducationProgram !== option.value && option) {
              setFieldValue('moduleId', null);
              setFieldValue('moduleName', null);
            }
          } else {
            setFieldValue('catalogName', null);
            setFieldValue('catalogId', null);

            setFieldValue('moduleId', null);
            setFieldValue('moduleName', null);
          }
        }}
        loadOptions={async (query) => await lookupApi.getDocumentReacceptServices(query)}
        withSearch
        options={[]}
        disabled={!accessCheck || !hasCreateDocument}
        placeholder="Выберите образовательную программу"
        defaultValue={documentDataFinishedAndTypes.docFinished.length ? null : defaultValueEducation}
        showTooltip
      />

      <Push size={16} />

      <FormikSelect
        label="Модуль"
        size="small"
        withSearch
        name="moduleId"
        selectedValue={(option?: SelectOptionType) => {
          setFieldValue('moduleId', option?.value);
          setFieldValue('moduleName', option?.label);
        }}
        options={listModules || []}
        disabled={isDisabledModule}
        placeholder="Выберите..."
        explainText={!values.catalogId ? 'Сначала выберите образовательную программу' : undefined}
        showTooltip
      />

      <Push size={16} />

      <FormikInput
        label="Организация"
        size="small"
        name="organizationId"
        dataTest="acceptOrganizationName"
        disabled
        value={organization?.label}
        explainText={!organization?.label ? 'Сначала выберите образовательную программу' : undefined}
      />
    </>
  );
};
