import React, { useState } from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../config/constants';
import { goBack, replaceHistoryState } from '../../lib/utils';
import { historyState } from '../../mock-data/history-state';
import { EventParamsFormData } from '../../types/events';
import Params from './params';
import List from './list';
import { initialEventParams } from './utils';

const Events = () => {
  const [search, setSearch] = useState<EventParamsFormData | undefined>(
    window.history.state[historyState.search] ? undefined : initialEventParams
  );

  const handleChangeSearch = (values: EventParamsFormData) => {
    setSearch(values);
    replaceHistoryState({
      [historyState.search]: values,
    });
  };

  return (
    <>
      <LmSubHeader
        sticky
        title="Реестр уведомлений"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Реестр уведомлений ',
          },
        ]}
        arrowOnClick={() => goBack(routes.main)}
        description=""
        dataTest="eventsSubHeader"
      />
      <Params
        params={search}
        onChangeParams={handleChangeSearch}
      />
      {!!search && <List search={search} />}
    </>
  );
};

export default Events;
