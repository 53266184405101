import { useEffect } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import lookupApi from '../../../../lib/api/lookup';
import { SelectOptionType } from '../../../../types/entities';
import { RequestData } from '../../../../types/requests';

type RegistryOfficeProps = FieldProps<string> & {
  disabled?: boolean;
  parent?: string;
  codeName?: string;
};

const RegistryOffice = ({
  label = 'Кем внесена запись',
  name,
  editMode,
  required,
  disabled = false,
  parent,
  codeName,
}: RegistryOfficeProps) => {
  const fieldName = parent ? `${parent}.${name}` : `${name}`;
  const fieldCodeName = parent ? `${parent}.${codeName}` : `${codeName}`;

  const [field] = useField(fieldName);

  const { setFieldValue, setFieldTouched } = useFormikContext<FormikHelpers<RequestData>>();

  useEffect(() => {
    setFieldTouched(fieldName, true);
  }, [setFieldTouched, fieldName]);

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={field.value ?? '—'}
    >
      <FormikSelect
        name={fieldName}
        required={required}
        size="small"
        withSearch
        options={[]}
        loadOptions={async (query) => await lookupApi.getRegistryOffice(query)}
        selectedValue={(v?: SelectOptionType) => {
          const parse = v && v.additionalPropertiesJson ? JSON.parse(v.additionalPropertiesJson) : undefined;

          setFieldValue(fieldName, v?.label);
          fieldCodeName && setFieldValue(fieldCodeName, parse?.Code);
        }}
        defaultValue={
          field.value
            ? {
                value: null,
                label: field.value,
              }
            : null
        }
        placeholder="Начните вводить..."
        disabled={disabled}
      />
    </Field>
  );
};

export default RegistryOffice;
