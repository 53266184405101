import React from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { ContractRequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import SimpleTable from '../../../components/table/simple-table';
import requestsApi from '../../../lib/api/requests';
import { downloadBlobFile } from '../../../lib/utils';

type Props = {
  data: ContractRequestData | undefined;
};

const Contract: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация о договоре'}>
        {data ? (
          <SimpleTable
            data={[data]}
            pageSize={20}
            columns={[
              {
                dataIndex: 'number',
                title: '№ договора',
                render: (item: any) =>
                  item.contractNumber && (
                    <button
                      id="btn-contract"
                      type="button"
                      className="icon-group"
                      onClick={async () => {
                        const response = await requestsApi.getContract(item.id);

                        if (response) {
                          downloadBlobFile(response.contentDisposition, response.blob);
                        }
                      }}
                    >
                      <span className="icon-group__text font-weight-bold color-primary">№{item.contractNumber}</span>
                    </button>
                  ),
              },
              {
                dataIndex: 'status',
                title: 'Статус',
                render: (item: any) => item.contractStatusName,
              },
              {
                dataIndex: 'date',
                title: 'Дата',
                render: (item: any) => formatDate(item.contractStatusDate),
              },
            ]}
          />
        ) : (
          <div className="requests-no-data">Нет данных</div>
        )}
      </Panel>
    </>
  );
};

export default Contract;
