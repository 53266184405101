import SimpleInput from '../../../../components/fields/simple-input';

export const parallelFieldName = 'parallelsList';

type Props = {
  showRequiredIcon?: boolean;
  required?: boolean;
  editMode?: boolean;
};
const ParallelField: React.FC<Props> = ({ showRequiredIcon, required, editMode }) => {
  return (
    <SimpleInput
      label="Параллель"
      name="parallelsList"
      showRequiredIcon={showRequiredIcon}
      required={required}
      editMode={editMode}
    />
  );
};

export default ParallelField;
