import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FilterContentItem from '../../../../../components/filter/filter-content-item';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import { ReportFilterTypeEnum } from '../../../../../mock-data/report-filter-type-enum';
import { ReportFilterData } from '../../../../../types/reports';
import { SavedReportsFilterContext } from '../index';

type OrganizationItemProps = {
  organization: SelectOptionType | undefined;
  setOrganization: (value: SelectOptionType | undefined) => void;
};

const OrganizationItem = ({ organization, setOrganization }: OrganizationItemProps) => {
  const { setFieldValue } = useFormikContext<ReportFilterData>();

  const {
    getFilterName,
    getFilterLabel,
    getFilterData,
    getFilterAllOption,
    getFilterReadOnly,
    getFilterTypeAheadPlaceholder,
  } = useContext(SavedReportsFilterContext);

  return (
    <FilterContentItem
      title="Организация"
      dataTest="organization"
    >
      <FormikSelect
        label={getFilterLabel(ReportFilterTypeEnum.ByTerritory)}
        name={getFilterName(ReportFilterTypeEnum.ByTerritory)}
        size="small"
        withSearch
        showTooltip
        disabledPortalTooltip
        options={getFilterData(ReportFilterTypeEnum.ByTerritory)}
        defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByTerritory)}
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByTerritory)}
      />
      <FormikSelect
        size="small"
        withSearch
        showTooltip
        disabledPortalTooltip
        label={getFilterLabel(ReportFilterTypeEnum.ByVedomstvo)}
        name={getFilterName(ReportFilterTypeEnum.ByVedomstvo)}
        options={getFilterData(ReportFilterTypeEnum.ByVedomstvo)}
        defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByVedomstvo)}
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByVedomstvo)}
      />
      <FormikSelect
        size="small"
        label={getFilterLabel(ReportFilterTypeEnum.ByMrsd)}
        withSearch
        showTooltip
        disabledPortalTooltip
        name={getFilterName(ReportFilterTypeEnum.ByMrsd)}
        loadOptions={async (query) => await lookupApi.getOrganizationMrsd(query)}
        placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByMrsd).placeholder}
        explainText={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByMrsd).explainText}
        options={[]}
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByMrsd)}
      />
      <FormikSelect
        size="small"
        withSearch
        showTooltip
        disabledPortalTooltip
        label={getFilterLabel(ReportFilterTypeEnum.ByOrganization)}
        name={getFilterName(ReportFilterTypeEnum.ByOrganization)}
        options={[]}
        loadOptions={async (query) => await lookupApi.getOrganization(query)}
        selectedValue={(value?: SelectOptionType) => {
          if (!value) {
            setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassParallel), null);
            setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassLetter), null);
          }
          setOrganization(value);
        }}
        defaultValue={organization}
        placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByOrganization).placeholder}
        explainText={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByOrganization).explainText}
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByOrganization)}
      />
      <FormikSelect
        size="small"
        withSearch
        label={getFilterLabel(ReportFilterTypeEnum.ByTypeProvServ)}
        name={getFilterName(ReportFilterTypeEnum.ByTypeProvServ)}
        options={getFilterData(ReportFilterTypeEnum.ByTypeProvServ)}
        defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByTypeProvServ)}
        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByTypeProvServ)}
      />
    </FilterContentItem>
  );
};

export default OrganizationItem;
