// Токен для авторизации
export const authBasicToken = process.env.REACT_APP_AUTH_BASIC_TOKEN;

export const debugMode = process.env.NODE_ENV === 'development';
export const reduxDebugMode = process.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true';
export const loginDebugMode = process.env.REACT_APP_DEBUG_MODE;

export const dataApiUrl = process.env.REACT_APP_DATA_SERVICE_URL;
export const authApiUrl = process.env.REACT_APP_AUTH_API_URL;
export const reportApiUrl = process.env.REACT_APP_REPORT_API_URL;
export const eventApiUrl = process.env.REACT_APP_EVENT_API_URL;
export const filesApiUrl = process.env.REACT_APP_FILES_API_URL;
export const contingentApiUrl = process.env.REACT_APP_CONTINGENT_API_URL;
export const bookingApiUrl = process.env.REACT_APP_BOOKING_API_URL;
export const aupdApiUrl = process.env.REACT_APP_AUPD_API_URL || '';
export const yaKey = process.env.REACT_APP_YANDEX_METRIKA_KEY;

// Префикс нужен, что бы на одном хосте коректно уживались монолит и реакт
export const urlPrefix = process.env.REACT_APP_ROUTER_PREFIX || '/';

// Ключ в хранилище для кеширования данных
export const persistStorageKey = 'root';

// Максимальное количество отображаемых записей в реестрах
export const maxRegistryRows = 50000;

// название окна подбора ДО на вкалдке группы обучения
export const searchServiceRegistryName = 'serviceSearchRegistry';
// имя контейнера для АУПД токена
export const aupdTokenFieldName = 'aupd_token';

const locationOrigin = window.location.origin.endsWith('/')
  ? window.location.origin.slice(0, -1)
  : window.location.origin;

/**
 * Роуты сервисов для работы с api сервиса авторизации
 */
export const apiService = {
  auth: debugMode ? `${window.location.protocol}//${authApiUrl}` : `${locationOrigin}/${authApiUrl}`,
  data: debugMode ? `${window.location.protocol}//${dataApiUrl}` : `${locationOrigin}/${dataApiUrl}`,
  event: debugMode ? `${window.location.protocol}//${eventApiUrl}` : `${locationOrigin}/${eventApiUrl}`,
  files: debugMode ? `${window.location.protocol}//${filesApiUrl}` : `${locationOrigin}/${filesApiUrl}`,
  report: debugMode ? `${window.location.protocol}//${reportApiUrl}` : `${locationOrigin}/${reportApiUrl}`,
  contingent: debugMode ? `${window.location.protocol}//${contingentApiUrl}` : `${locationOrigin}/${contingentApiUrl}`,
  booking: debugMode ? `${window.location.protocol}//${bookingApiUrl}` : `${locationOrigin}/${bookingApiUrl}`,
};

export const routes: Record<string, string> = {
  main: `${urlPrefix}`,
  auth: `${urlPrefix}auth`,
  login: `${urlPrefix}auth/login`,
  sign: `${urlPrefix}auth/sign`,
  loginAupd: `${urlPrefix}auth/loginAupd`,
  changePassword: `${urlPrefix}auth/changePassword`,
  reports: `${urlPrefix}reports`,
  printRequest: `${urlPrefix}print/request/:type/:id`,
  printServiceClass: `${urlPrefix}print/serviceClass/:type/:id`,
  printService: `${urlPrefix}print/service/:id`,
  commonReport: `${urlPrefix}reports/:reportId`,
  savedReport: `${urlPrefix}reports/saved/:id`,
  services: `${urlPrefix}service`,
  service: `${urlPrefix}service/:id`,
  createService: `${urlPrefix}service/create/:edType`,
  createServiceByTemplate: `${urlPrefix}service/createByTemplate/:templateId`,
  trainingGroup: `${urlPrefix}service/:serviceId/trainingGroup/:id`,
  entranceTests: `${urlPrefix}service/:serviceId/trainingGroup/:tgId/schedule/:id`,
  myTemplates: `${urlPrefix}myTemplates`,
  myTemplate: `${urlPrefix}myTemplates/:id`,
  copyTemplate: `${urlPrefix}myTemplates/copy/:id`,
  createTemplate: `${urlPrefix}myTemplates/create/:edType`,
  dayCarePrograms: `${urlPrefix}dayCarePrograms`,
  dayCareProgram: `${urlPrefix}dayCarePrograms/:id`,
  programs: `${urlPrefix}programs`,
  program: `${urlPrefix}programs/:id`,
  registerChildEducations: `${urlPrefix}registerChildEducations`,
  registerChildEducation: `${urlPrefix}registerChildEducations/:id`,
  learners: `${urlPrefix}learners`,
  learner: `${urlPrefix}learners/:id`,
  registerEducationPrograms: `${urlPrefix}registerEducationPrograms`,
  registerEducationProgram: `${urlPrefix}registerEducationPrograms/:id`,
  registerServices: `${urlPrefix}registerServices`,
  serviceClasses: `${urlPrefix}serviceClasses`,
  serviceClass: `${urlPrefix}serviceClasses/:id`,
  createServiceClass: `${urlPrefix}serviceClasses/create/:edType`,
  teachers: `${urlPrefix}teachers`,
  teacher: `${urlPrefix}teachers/:id`,
  disciplines: `${urlPrefix}disciplines`,
  organizations: `${urlPrefix}organizations`,
  organization: `${urlPrefix}organizations/:id`,
  organizationInfo: `${urlPrefix}organizationInfo`,
  favorite: `${urlPrefix}favoritesSearch`,
  userManual: `${urlPrefix}userManuals`,
  faq: `${urlPrefix}faq`,
  normativeDoc: `${urlPrefix}normativeDoc`,
  mesh: `${urlPrefix}mesh`,
  siteMap: `${urlPrefix}siteMap`,
  room: `${urlPrefix}changeUserData`,
  accessDeny: `${urlPrefix}accessDeny`,
  technicalError: `${urlPrefix}technicalError`,
  unknownError: `${urlPrefix}unknownError`,
  requests: `${urlPrefix}requests`,
  request: `${urlPrefix}requests/:id`,
  createRequest: `${urlPrefix}requests/create/:edType`,
  createRequestBasedOn: `${urlPrefix}requests/create-based-on/:id`,
  createRequestByPupil: `${urlPrefix}requests/create-by-pupil/:edType/:pupilId`,
  createRequestBySchedule: `${urlPrefix}requests/create-by-schedule/:slotId`,
  events: `${urlPrefix}events`,
};
