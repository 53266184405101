import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RequestData } from '../../types/requests';
import { EducationTypeEnum } from '../../types/education-type';
import DkgmRequestCreate from './dkgm/create';
import requestsApi from '../../lib/api/requests';
import DonmRequestCreate from './donm/create';
import { access, RequestContext } from '../../lib/utils/requests';
import { userProfileSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';
import RequestProfCreate from './preparation/create';
import DayCareRequestCreate from './day-care/create';

const CreateRequestFromPupil = () => {
  const params = useParams<any>();
  const [request, setRequest] = useState<RequestData>();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    const getRequest = async () => {
      if (params.pupilId && params.edType) {
        const response = await requestsApi.buildRequestByPupil(params.pupilId, params.edType);

        setRequest(response);
      }
    };

    getRequest();
  }, [params.pupilId, params.edType]);

  const currentRequest = (edId: number) => {
    if (request) {
      switch (edId) {
        case EducationTypeEnum.ChildrenEducation:
        case EducationTypeEnum.ChildrenNonDogmEducation:
          return (
            <DonmRequestCreate
              request={request}
              educationType={edId}
            />
          );
        case EducationTypeEnum.ArtHouseEducation:
        case EducationTypeEnum.SportEducation:
          return (
            <DkgmRequestCreate
              request={request}
              educationType={edId}
            />
          );
        case EducationTypeEnum.ProfessionalEducation:
          return <RequestProfCreate request={request} />;
        case EducationTypeEnum.DayCareCentersEducation:
          return <DayCareRequestCreate request={request} />;
        default:
          return null;
      }
    }
  };

  return (
    <RequestContext.Provider value={access(userProfile)}>
      {currentRequest(parseInt(params.edType ?? 0))}
    </RequestContext.Provider>
  );
};

export default CreateRequestFromPupil;
