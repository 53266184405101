import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../types/state';
import { authApi } from '../../lib/api';
import { AuthorizationData, FullNameData } from '../../types/authorization-data';
import tokenManager from '../../lib/token-manager';

export const moduleName = 'userProfile';
export const SET_USER_PROFILE = `${moduleName}/SET_USER_PROFILE`;

export const reducerRecord: AuthorizationData = Object.freeze({
  id: 0,
  login: '',
  token: '',
  userTypeId: 0,
  userName: '',
  userIP: '',
  impersonationLogin: '',
  organizationId: 0,
  organizationName: '',
  vedomstvoId: 0,
  vedomstvoName: '',
  fullName: {} as FullNameData,
  snils: '',
  generalAccessList: [],
  objectAccessActionList: [],
  identity: {
    name: '',
    authenticationType: '',
    isAuthenticated: false,
  },
  email: '',
  roles: [],
  tokenAupd: '',
});

// Reducer
export default function reducer(state: AuthorizationData = reducerRecord, action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_PROFILE:
      return payload.data;

    default:
      return state;
  }
}

// AC
export const setUserProfile = (data: AuthorizationData) => ({
  type: SET_USER_PROFILE,
  payload: {
    data,
  },
});

// Thunks
export const getUserProfile = () => async (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => {
  const profile: AuthorizationData = await authApi.getProfile();

  if (profile) {
    profile.token = tokenManager.getToken();
  }
  dispatch(setUserProfile(profile));

  return profile;
};
