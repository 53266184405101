import React, { useCallback } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInfoBox, LmTooltip } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikSelect from '../../../components/formik/formik-select';
import FormikDatePicker from '../../../components/formik/formik-datepicker';
import FormikInput from '../../../components/formik/formik-input';
import { RequestData, RequestEnrollmentData } from '../../../types/requests';
import ShortNumberInfobox from '../components/short-number-infobox';
import EnrollmentAdmissionPlan from './components/enrollment-admission-plan';
import lookupApi from '../../../lib/api/lookup';
import Document from '../components/fields/document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import requestsApi from '../../../lib/api/requests';
import history from '../../../history';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import { docDate } from '../../../lib/utils/validation';
import SavePanel from '../../../components/save-panel';

type Props = {
  editMode: boolean;
  disabled: boolean;
  setEditMode: () => void;
  requestData: RequestData;
  updateRequest: () => void;
};

const ActionsFormEnrollment: React.FC<Props> = ({ editMode, disabled, setEditMode, requestData, updateRequest }) => {
  const initialErrors = useInitialErrors(requestData.enrollment, getValidationSchema());

  const submitForm = useCallback(
    async (values: RequestEnrollmentData) => {
      try {
        if (values.serviceClass?.id === 0 && values.serviceClass?.name) {
          values.isNewServiceClassNeed = true;
        }
        const id = await requestsApi.saveEnrollment(requestData.id, values);

        setEditMode();
        if (id !== requestData.id) {
          history.push(
            generateLink(routes.request, {
              id,
            })
          );
        } else {
          updateRequest();
        }
      } catch (ex: any) {
        console.error(ex);
      }
    },
    [requestData.id, setEditMode, updateRequest]
  );

  const cancel = () => {
    setEditMode();
  };

  return requestData.approve?.isEnrollmentApproved &&
    (requestData.evaluation?.isEvaluationCompleted || !requestData.trainingGroup?.isTestService) ? (
        <Formik
          enableReinitialize
          onSubmit={submitForm}
          initialErrors={initialErrors}
          initialValues={requestData.enrollment}
          validationSchema={getValidationSchema()}
        >
          {(formikProps: FormikProps<RequestEnrollmentData>) => {
            const { handleSubmit, submitForm, isValid, isSubmitting, values, setFieldValue, resetForm } = formikProps;

            return (
              <form onSubmit={handleSubmit}>
                <ShortNumberInfobox child={requestData.child} />

                <EnrollmentAdmissionPlan
                  name="trainingGroupId"
                  serviceId={requestData.trainingGroup?.serviceId}
                  scheduleOfTimetableId={requestData.trainingGroup?.scheduleOfTimetableId}
                  editMode={editMode}
                  disabled={disabled}
                />

                <div className="container">
                  <Push size={20} />
                  <div className="table-data">
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">
                        <div className="flex">
                          <div>
                        Группа обучения <div className="table-data__required" />
                          </div>
                          <Push
                            size={2}
                            orientation="horizontal"
                          />
                          <LmTooltip
                            withArrow
                            placement="right"
                            content="Для выбора группы введите часть названия или код группы. Для создания новой группы введите название новой группы. Новая группа будет создана при зачислении ученика."
                          >
                            <LmIcon
                              icon="outline-notifications-info"
                              size={18}
                              color="var(--LM-neutrals-day-700)"
                            />
                          </LmTooltip>
                        </div>
                      </div>
                      <div className="table-data__body">
                        <FormikSelect
                          placeholder={disabled ? '' : 'Начните вводить или выберите...'}
                          disabled={disabled}
                          name="serviceClass.id"
                          size="small"
                          withSearch
                          options={[]}
                          defaultValue={
                            values.serviceClass?.name && values.serviceClass?.id
                              ? {
                              label: values.serviceClass?.name,
                              value: values.serviceClass?.id,
                            }
                              : null
                          }
                          loadOptions={async (query) => {
                            const r = await lookupApi.getServiceClass(
                              query,
                              undefined,
                              requestData.educationTypeId,
                              8,
                              requestData.trainingGroup?.organizationId
                            );

                            return r.length
                              ? r
                              : [
                                  {
                                    value: 0,
                                    label: query,
                                  },
                                ];
                          }}
                          selectedValue={(v?: SelectOptionType) => {
                            if (v) {
                              setFieldValue('serviceClass.id', v.value);
                              setFieldValue('serviceClass.name', v.label);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">
                    Дата зачисления <div className="table-data__required" />
                      </div>
                      <div className="table-data__body">
                        <div className="table-data-grid-3">
                          <FormikDatePicker
                            disabled={disabled}
                            size="small"
                            name="enrollDate"
                            placeholder={disabled ? '' : 'ДД.ММ.ГГГ'}
                          />
                        </div>
                      </div>
                    </div>
                    <Document
                      name="enrollDocumentType"
                      label="Вид документа-основания"
                      editMode
                      formType={FormTypeEnum.EditRequestEnrollment}
                      required
                      disabled={disabled}
                      size={3}
                    />
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">
                    Номер и дата документа <div className="table-data__required" />
                      </div>
                      <div className="table-data__body">
                        <div className="table-data-grid-2">
                          <FormikInput
                            disabled={disabled}
                            size="small"
                            name="enrollDocNumber"
                            placeholder={disabled ? '' : 'Введите номер...'}
                          />
                          <FormikDatePicker
                            disabled={disabled}
                            size="small"
                            name="enrollDocDate"
                            placeholder={disabled ? '' : 'ДД.ММ.ГГГГ'}
                            placement="top-end"
                          />
                        </div>
                      </div>
                    </div>
                    <SimpleTextArea
                      editMode
                      label="Комментарий"
                      name="enrollComment"
                      disabled={disabled}
                      placeholder={disabled ? '' : 'Введите...'}
                    />
                  </div>
                </div>

                {editMode && (
                  <SavePanel
                    primaryButtonModifiers={{
                      loading: isSubmitting,
                      disabled: !isValid,
                    }}
                    onClickPrimaryButton={submitForm}
                    onClickSeconadaryButton={() => {
                      resetForm();
                      cancel();
                    }}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      ) : (
        <div className="container">
          <LmInfoBox
            dataTest="actionsEnrollmentWarning"
            className="infobox--full-width"
            variant="warning"
            description="Функционал зачисления будет доступен только после сохранения и подписания сведений о результатах вступительных испытаний, а так же после указания уведомления о необходимости явки для заключения договора."
            hidenFooter
          />
          <Push size={20} />
        </div>
      );
};

export default ActionsFormEnrollment;

const getValidationSchema = () =>
  objectYup().shape({
    enrollDate: docDate.required('Выберите дату зачисления').nullable(),
    enrollDocumentTypeId: stringYup().required('Выберите тип документа').nullable(),
    enrollDocNumber: stringYup().required('Введите номер документа').nullable(),
    enrollDocDate: docDate.required('Выберите дату документа').nullable(),
    serviceClass: objectYup()
      .required()
      .nullable()
      .shape({
        id: stringYup().required('Введите группу для зачисления').nullable(),
      }),
  });
