import React from 'react';
import { useFormikContext } from 'formik';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { ServiceRegistryContext } from '../../initial-data';
import { SelectOptionType } from '../../../../../types/entities';

const FieldDepartment: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const { vedomstvoData, isAdmin } = React.useContext(ServiceRegistryContext);

  const defaultValue = isAdmin
    ? values.vedomstvoId
      ? {
          label: values.vedomstvoName || '',
          value: values.vedomstvoId,
        }
      : {
          label: 'Все',
          value: null,
        }
    : {
        label: vedomstvoData[0]?.label,
        value: vedomstvoData[0]?.value,
      };

  return (
    <FormikSelect
      label="Департамент"
      name="vedomstvoId"
      size="small"
      options={vedomstvoData}
      disabled={!isAdmin}
      defaultValue={defaultValue}
      placeholder="Выберите..."
      showTooltip
      disabledPortalTooltip
      selectedValue={(v?: SelectOptionType) => {
        if (v) {
          setFieldValue('vedomstvoName', v.label);
        }
      }}
    />
  );
};

export default FieldDepartment;
