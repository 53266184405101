import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../components/formik/formik-input-number';

type Props = { editMode: boolean; isCreate?: boolean; parent?: string; disabled?: boolean };

enum GenderEnum {
  all = 'all',
  male = 'male',
  female = 'female',
}

export const GroupMembersField: React.FC<Props> = ({ editMode, parent, disabled }) => {
  const start = parent ? `${parent}.altStart` : 'altStart';
  const end = parent ? `${parent}.altEnd` : 'altEnd';

  const [startField] = useField(start);
  const [endField] = useField(end);

  const isFemalePresentFieldName = parent ? `${parent}.isFemalePresent` : 'isFemalePresent';
  const isMalePresentFieldName = parent ? `${parent}.isMalePresent` : 'isMalePresent';

  const [isFemalePresentField, , isFemalePresentFieldHelper] = useField(isFemalePresentFieldName);
  const [isMalePresentField, , isMalePresentFieldHelper] = useField(isMalePresentFieldName);

  const [gender, setGender] = useState<GenderEnum>(GenderEnum.all);

  useEffect(() => {
    if (isFemalePresentField.value && isMalePresentField.value) {
      setGender(GenderEnum.all);
    } else if (!isFemalePresentField.value && isMalePresentField.value) {
      setGender(GenderEnum.male);
    } else {
      setGender(GenderEnum.female);
    }
  }, [isFemalePresentField.value, isMalePresentField.value]);

  const onChangeGender = (type: GenderEnum) => {
    switch (type) {
      case GenderEnum.male:
        isFemalePresentFieldHelper.setValue(false);
        isMalePresentFieldHelper.setValue(true);
        break;

      case GenderEnum.female:
        isFemalePresentFieldHelper.setValue(true);
        isMalePresentFieldHelper.setValue(false);
        break;

      default:
        isFemalePresentFieldHelper.setValue(true);
        isMalePresentFieldHelper.setValue(true);
    }
  };

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Состав группы {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="flex items-start">
            <LmSwitcher
              dataTest="groupGender"
              size="small"
              value={gender}
              onChange={onChangeGender}
              variant="gray"
            >
              <LmSwitcherOption
                dataTest="switcher-gender-all"
                value={GenderEnum.all}
              >
                Все
              </LmSwitcherOption>
              <LmSwitcherOption
                dataTest="switcher-gender-mail"
                value={GenderEnum.male}
              >
                Муж
              </LmSwitcherOption>
              <LmSwitcherOption
                dataTest="switcher-gender-female"
                value={GenderEnum.female}
              >
                Жен
              </LmSwitcherOption>
            </LmSwitcher>
            <Push
              size={16}
              orientation="horizontal"
            />
            <FormikInputNumber
              placeholder="0"
              name={start}
              disabled={disabled}
              enableDefaultEvents
            />

            <Push
              size={8}
              orientation="horizontal"
            />
            <div>
              <div className="table-row-item-height flex items-center">—</div>
            </div>
            <Push
              size={8}
              orientation="horizontal"
            />

            <FormikInputNumber
              placeholder="0"
              name={end}
              disabled={disabled}
            />
          </div>
        ) : (
          <div className="flex items-center">
            <div className="table-data__group">
              <div className="table-data__label">Пол</div>
              <div
                className="table-data__body"
                style={{
                  width: 218,
                }}
              >
                {gender === 'all' ? 'Все' : gender === 'male' ? 'Муж' : 'Жен'}
              </div>
            </div>
            <Push
              size={16}
              orientation="horizontal"
            />
            <div className="table-data__group">
              <div className="table-data__label">Возраст</div>
              <div className="table-data__body">{`${startField.value} - ${endField.value}`} лет</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
