import { notify } from '../../../redux/ducks/notifications';
import { store } from '../../../store/store';
import { Discipline } from '../../../types/discipline';

export const transformData = (values: Discipline[] | undefined) => {
  let count = 1;

  if (!values) {
    return [];
  }

  return values
    .map((item: Discipline) => {
      return {
        id: item.id,
        count: count++,
        name: item.name,
      };
    })
    .sort((a, b) => a.count - b.count);
};

export const teacherError = {
  name: 'В блоке "Персональные данные" не заполнены обязательные поля.',
};

export const isError = () => {
  store.dispatch(
    notify.danger({
      dataTest: 'requiredFieldsPerson',
      title: teacherError.name,
    })
  );
};
