import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType, FormGroup } from '@mosru/esz_uikit';
import { LmFilterVertical, LmRadio } from '@mes-ui/lemma';
import { historyState } from '../../../mock-data/history-state';
import FormikSelect from '../../../components/formik/formik-select';
import {
  contactOptions,
  defaultOptionValue,
  searchTeachersInitialFormData,
  teacherInfoOptions,
} from '../../../mock-data/teacher';
import { SearchTeachersFormData } from '../../../types/teacher';
import lookupApi from '../../../lib/api/lookup';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { getCountDiff, hasAccessObjectAny, hasGeneralAccess, replaceHistoryState } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';

type Props = {
  submitForm: (values: SearchTeachersFormData) => void;
  initialForm: SearchTeachersFormData;
};

const TeachersSearch = ({ submitForm, initialForm }: Props) => {
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);

  const [organization, setOrganization] = useState<SelectOptionType | undefined>(undefined);
  const [discipline, setDiscipline] = useState<SelectOptionType | undefined>(undefined);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAcceptedEdit = useMemo(
    () =>
      (hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.ViewRegistry) &&
        !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) &&
        !hasGeneralAccess(userProfile, generalAccess.AdminView) &&
        !hasGeneralAccess(userProfile, generalAccess.AdminEdit)) ||
      (!hasGeneralAccess(userProfile, generalAccess.AdminView) &&
        hasGeneralAccess(userProfile, generalAccess.AdminEdit)),
    [userProfile]
  );

  useEffect(() => {
    if (!organization && (userProfile.organizationId || initialForm.organizationId)) {
      if (isAcceptedEdit || initialForm.organizationId) {
        setOrganization(
          initialForm.organizationId && initialForm.organizationName
            ? {
                label: initialForm.organizationName,
                value: initialForm.organizationId,
              }
            : {
                label: userProfile.organizationName,
                value: userProfile.organizationId,
              }
        );
      } else {
        setOrganization(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialForm.organizationId,
    initialForm.organizationName,
    isAcceptedEdit,
    userProfile.organizationId,
    userProfile.organizationName,
  ]);

  useEffect(() => {
    if (initialForm.disciplineId && initialForm.disciplineName && !discipline) {
      setDiscipline({
        label: initialForm.disciplineName,
        value: initialForm.disciplineId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialForm.disciplineId, initialForm.disciplineName]);

  const handleResetForm = (resetForm: (nextState?: Partial<FormikState<SearchTeachersFormData>>) => void) => {
    const newValues = {
      ...searchTeachersInitialFormData,
      organizationId: isAcceptedEdit ? userProfile.organizationId : searchTeachersInitialFormData.organizationId,
      organizationName: isAcceptedEdit ? userProfile.organizationName : undefined,
    };

    resetForm({
      values: newValues,
    });
    submitForm(newValues);
    setOrganization(isAcceptedEdit ? organization : undefined);
    setDiscipline(undefined);
  };

  const handleClickAdvancedSearch = (isOpen: boolean) => {
    replaceHistoryState({
      [historyState.openAdvanced]: isOpen,
    });
    setOpen(isOpen);
  };

  const handleSubmit = (values: SearchTeachersFormData, formikHelpers: FormikHelpers<SearchTeachersFormData>) => {
    const send =
      isAcceptedEdit && organization?.value === userProfile.organizationId
        ? {
            ...values,
            organizationId: userProfile.organizationId,
            organizationName: userProfile.organizationName,
          }
        : values;

    submitForm(send);
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialForm}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue, isSubmitting, resetForm }: FormikProps<SearchTeachersFormData>) => {
        const countDiff = getCountDiff(values, {
          ...searchTeachersInitialFormData,
          disciplineName: values.disciplineName,
          organizationName: values.organizationName,
          organizationId: isAcceptedEdit ? values.organizationId : searchTeachersInitialFormData.organizationId,
        });

        return (
          <form onSubmit={handleSubmit}>
            <LmFilterVertical
              dataTest="teacherFilter"
              classNameContent="filter-content"
              open={open}
              searchValue={values.query}
              searchPlaceholder="Поиск по преподавателям..."
              onChange={(value) => setFieldValue('query', value)}
              toggleOpen={handleClickAdvancedSearch}
              onClear={() => handleResetForm(resetForm)}
              buttonSecondaryText={countDiff ? `Сбросить фильтры (${countDiff})` : 'Сбросить фильтры'}
              primaryButtonModifiers={{
                loading: isSubmitting,
                type: 'submit',
              }}
            >
              <>
                <FormikSelect
                  label="Организация"
                  loadOptions={async (query) => await lookupApi.getOrganization(query)}
                  name="organizationId"
                  size="medium"
                  withSearch
                  showTooltip
                  disabledPortalTooltip
                  disabled={isAcceptedEdit}
                  selectedValue={(option) => {
                    setOrganization(option);
                    setFieldValue('organizationName', option?.label);
                  }}
                  placeholder="Начните вводить..."
                  defaultValue={organization}
                  options={[defaultOptionValue]}
                />
                <FormikSelect
                  label="Дисциплины"
                  loadOptions={async (query) => await lookupApi.getDisciplines(query)}
                  name="disciplineId"
                  size="medium"
                  withSearch
                  showTooltip
                  disabledPortalTooltip
                  defaultValue={discipline}
                  selectedValue={(option?: SelectOptionType) => {
                    setDiscipline(option);
                    setFieldValue('disciplineName', option?.label);
                  }}
                  placeholder="Начните вводить..."
                  options={[defaultOptionValue]}
                />
                <FormGroup label="Контактное лицо">
                  <>
                    {contactOptions.map(({ value, label }) => (
                      <LmRadio
                        key={label}
                        boxSize="large"
                        name={`isContactPerson-${value}`}
                        dataTest={`isContactPerson-${value}`}
                        onChange={() => {
                          setFieldValue('isContactPerson', value);
                        }}
                        checked={values.isContactPerson === value}
                      >
                        {label}
                      </LmRadio>
                    ))}
                  </>
                </FormGroup>
                <FormGroup label="Сведения о преподавателе в реестре преподавателей">
                  <>
                    {teacherInfoOptions.map(({ value, label }) => (
                      <LmRadio
                        key={label}
                        boxSize="large"
                        name={`presentInNSI-${value}`}
                        dataTest={`presentInNSI-${value}`}
                        onChange={() => {
                          setFieldValue('presentInNSI', value);
                        }}
                        checked={values.presentInNSI === value}
                      >
                        {label}
                      </LmRadio>
                    ))}
                  </>
                </FormGroup>
              </>
            </LmFilterVertical>
          </form>
        );
      }}
    </Formik>
  );
};

export default TeachersSearch;
