import { createContext } from 'react';
import { routes } from '../../config/constants';
import { RequestContextType, RequestListData, SearchRequestsInitialFormData } from '../../types/requests';
import { artHouseSport } from '../../containers/requests/list/table-columns/art-house-sport';
import { dogm } from '../../containers/requests/list/table-columns/dogm';
import { formatDate, formatDateAndTime, formatTime } from './date';
import { accessAction, accessObject, AuthorizationData, generalAccess } from '../../types/authorization-data';
import { ScheduleDays, ServiceClassListData } from '../../types/service-class';
import { RequestStatusEnum } from '../../mock-data/request-status-enum';
import { hasAccessObjectAny, hasGeneralAccess } from './index';
import { ServiceClassList } from '../../containers/requests/components/fields/service-classes-enrollment';

export const transformDataRequestsList = (data: RequestListData[], pageSize: number, pageNumber: number) => {
  return data.map((item, index) => {
    return {
      ...item,
      dateCreate: item.dateCreate ? formatDate(item.dateCreate) : '',
      requestDate: item.requestDate ? formatDate(item.requestDate) : '',
      testDate: item.testDate ? formatDate(item.testDate) : '',
      childDateOfBirth: item.childDateOfBirth ? formatDate(item.childDateOfBirth) : '',
      contractDate: item.contractDate ? formatDateAndTime(item.contractDate) : '',
      row: pageSize * (pageNumber - 1) + index + 1,
    };
  });
};

export const getCountDiff = (values: any, filters: SearchRequestsInitialFormData): number => {
  return Object.keys(values).reduce((acc, valueIndex) => {
    if (
      (!valueIndex.endsWith('Name') || valueIndex.startsWith('child')) &&
      !(values[valueIndex] === filters[valueIndex as keyof SearchRequestsInitialFormData]) &&
      values[valueIndex] !== null &&
      values[valueIndex] !== ''
    ) {
      acc++;
    }

    return acc;
  }, 0);
};

export const getColumns = (
  isArtHouseSportEducation: boolean,
  allowedEducationTypeId?: number,
  userProfile?: AuthorizationData
) => {
  return isArtHouseSportEducation ? artHouseSport(allowedEducationTypeId) : dogm(allowedEducationTypeId, userProfile);
};

export const checkDate = (value: SearchRequestsInitialFormData) => {
  const { requestDate } = value;

  const date = {
    start: requestDate && requestDate[0],
    end: requestDate && requestDate[1],
  };

  return requestDate
    ? {
        ...value,
        requestDate: [date.start, date.end],
      }
    : value;
};

export const formattingDocumentNumber = (numberDocument: string, docDate: string, requestStatusId: number) => {
  if (requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded) {
    const matches = numberDocument?.match(/№/gi) || [];

    return matches.length ? `${numberDocument} от ${docDate}` : '';
  }

  return '';
};

export const deadlineExpired = (value: string) => {
  const matches = value.match(/Ожидание прихода Заявителя для заключения договора/gi) || [];

  if (matches.length) {
    return value.replace(matches[0], 'Ожидание прихода заяв..');
  }

  return value;
};

export const clearField = (value: string | null | undefined, name: string, validateField: (field: string) => void) => {
  if (value === null) {
    setTimeout(() => {
      validateField(name);
    }, 0);
  }
};

export const infoChild = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Присутствуют',
    value: 1,
  },
  {
    label: 'Отсутствуют',
    value: 3,
  },
];

export const period = [
  {
    label: 'За последний год',
    value: true,
  },
  {
    label: 'За все время',
    value: false,
  },
];

export const privilegeStatusList = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: '0',
    label: 'Льгота не проверялась',
  },
  {
    value: '1',
    label: 'Ожидание подтверждения льгот',
  },
  {
    value: '2',
    label: 'Льгота подтверждена',
  },
  {
    value: '3',
    label: 'Льгота не подтверждена',
  },
];

// Функция для очистки поля с перерендером
export const setAsyncValues = async (
  data: { key?: string; action?: React.Dispatch<React.SetStateAction<number>> }[],
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
): Promise<void> => {
  const promise = (key?: string, setValue?: React.Dispatch<React.SetStateAction<number>>) => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        key && setFieldValue && setFieldValue(key, undefined);
        setValue && setValue(Math.random());
        resolve();
      });
    });
  };

  for (const value of data) {
    const { key, action } = value;

    await promise(key, action);
  }
};

export const removeEmptyDataForRequest = (requestData: { [key: string]: any }) => {
  for (const key in requestData) {
    if (requestData[key] === null || requestData[key] === undefined || requestData[key] === '') {
      delete requestData[key];
    }
  }

  return requestData;
};

const transformDay = (nameDay: string, day: ScheduleDays.Day[] | null): ScheduleDays.TransformDay => {
  let schedule: { name: string; fromTo: string }[] = [];
  const str: string[] = [];

  if (Array.isArray(day)) {
    schedule = day.map(({ f, t }) => ({
      name: nameDay,
      fromTo: `${formatTime(f)} - ${formatTime(t)}`,
    }));
  }

  if (schedule.length) {
    schedule.map((item) => {
      return str.push(item.fromTo);
    });
  }

  return {
    name: nameDay,
    fromTo: str.join(', '),
  };
};

const transformSchedule = (schedule: ScheduleDays.ScheduleData[]): ScheduleDays.TransformDays[] => {
  return schedule.map((item, index: number) => {
    return {
      id: item.id,
      period: `Период ${index + 1}:`,
      startEnd: `${formatDate(item.dateStart)} - ${formatDate(item.dateEnd)}`,
      monday: transformDay('Пн', item.monday),
      tuesday: transformDay('Вт', item.tuesday),
      wednesday: transformDay('Ср', item.wednesday),
      thursday: transformDay('Чт', item.thursday),
      friday: transformDay('Пт', item.friday),
      saturday: transformDay('Сб', item.saturday),
      sunday: transformDay('Вс', item.sunday),
    };
  });
};

export const transformServiceGroupList = (sc: ServiceClassListData<ScheduleDays.ScheduleData>): ServiceClassList => {
  return {
    id: sc.id,
    code: sc.code,
    name: sc.name,
    teacher: sc.teacherName,
    volumes: `${sc.capacity ? `${sc.capacity - sc.freeVolume}/${sc.capacity}` : 'без ограничений'}`,
    dates: transformSchedule(sc.schedule),
  };
};

export const transformServiceGroupListDonm = (sc: ServiceClassListData<ScheduleDays.ScheduleData>) => {
  return {
    id: sc.id,
    code: sc.code,
    name: sc.name,
    teacher: sc.teacherName,
    volumes: `${sc.capacity ? `${sc.freeVolume}/${sc.capacity}` : 'без ограничений'}`,
    dates: transformSchedule(sc.schedule),
  };
};

export const editTextInfobox = (text: string) => {
  const t = text.split('\n');

  for (let i = 0; i < t.length; i++) {
    if (i > 0 && i < t.length - 1) {
      t[i] = `— ${t[i].trim()}${t.length - 2 === i ? '.' : ';'}`;
    }
  }

  return t.join('<br />');
};

export const buildFormFieldName = (parent: string | undefined, fieldName: string) =>
  parent ? `${parent}.${fieldName}` : fieldName;

export const access = (userProfile: AuthorizationData, fromPage?: string) => {
  return {
    userProfile,
    oiv: hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV),
    adminView: hasGeneralAccess(userProfile, generalAccess.AdminView),
    adminEdit: hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    admin:
      hasGeneralAccess(userProfile, generalAccess.AdminView) && hasGeneralAccess(userProfile, generalAccess.AdminEdit),

    serviceDOGMEdit: hasAccessObjectAny(userProfile, [accessObject.ServiceDOGM], accessAction.Edit),
    serviceNonDOGMEdit: hasAccessObjectAny(userProfile, [accessObject.ServiceNonDOGM], accessAction.Edit),
    useSign: hasGeneralAccess(userProfile, generalAccess.UseSign),
    canViewRequestDOGM: hasAccessObjectAny(userProfile, [accessObject.RequestDOGM], accessAction.View),
    canEditRequestDOGM: hasAccessObjectAny(
      userProfile,
      [accessObject.RequestDOGM, accessObject.RequestNonDOGM],
      accessAction.Edit
    ),
    canEditRequestOP: hasAccessObjectAny(userProfile, [accessObject.RequestOP], accessAction.Edit),
    hasCheckPrivileges: hasGeneralAccess(userProfile, generalAccess.CheckPrivileges),
    routeBack: fromPage || routes.requests,
  };
};

export const RequestContext = createContext<RequestContextType>({} as RequestContextType);

export function getNameGroup(code?: string, name?: string): string {
  const comma = name && code ? ', ' : '';

  code = code ?? '';
  name = name ?? '';

  if (code || name) {
    return `${code}${comma}${name}`;
  } else {
    return 'Группа обучения не найдена';
  }
}
