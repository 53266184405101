import React, { useCallback, useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import SavePanel from '../../../components/save-panel';
import { notify } from '../../../redux/ducks/notifications';
import FIO from '../components/fields/fio';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import BirthDate from '../components/fields/birthdate';
import Gender from '../../../components/fields/gender';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import Snils from '../components/fields/snils';
import { PersonRequestData } from '../../../types/requests';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import { ChildrenSearchResult } from '../../../types/contingent';
import ContingentSearch from '../components/fields/contingent-search';
import requestsApi from '../../../lib/api/requests';
import { childrenValidationSchema } from './create';
import history from '../../../history';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import { RequestContext } from '../../../lib/utils/requests';
import { DocumentTypeEnum } from '../../../mock-data/type-document';
import RegistryOffice from '../components/fields/registry-office';

type Props = {
  requestId: number;
  requestChild: PersonRequestData;
  setEditModeParent: (value: string | null) => void;
  contingentLinkTypeId: ContingentLinkTypeEnum;
  editable: boolean;
  updateRequest: () => void;
};

const ChildForm: React.FC<Props> = ({
  requestId,
  requestChild,
  setEditModeParent,
  contingentLinkTypeId,
  editable,
  updateRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(requestChild, childrenValidationSchema);
  const [childrenList, setChildrenList] = useState<ChildrenSearchResult[] | null>(null);
  const { adminEdit } = useContext(RequestContext);
  const [formKey, setFormKey] = useState(Math.random());

  const dispatch = useDispatch();

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      try {
        values.isChild = true;
        const id = await requestsApi.saveRequestPerson(values);

        setEditModeParent(null);
        setEditMode(false);
        if (id !== requestId) {
          history.push(
            generateLink(routes.request, {
              id,
            })
          );
        } else {
          updateRequest();
        }
      } catch (ex: any) {
        dispatch(
          notify.danger({
            dataTest: 'saveChild',
            title: ex.data?.message,
          })
        );
      }
    },
    [setEditModeParent, updateRequest, requestId, dispatch]
  );

  return (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={childrenValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={requestChild}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, submitForm, isSubmitting, isValid, values } = formikProps;
        const isBirthRecord = values.documentTypeId === DocumentTypeEnum.BirthRecord;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о ребенке'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('child');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <FIO
                    showErrorImmediately
                    label="ФИО учащегося"
                    contingent={contingentLinkTypeId}
                    required
                    editMode={editMode}
                  />
                  <BirthDate
                    name="dateOfBirth"
                    searchButton={editMode}
                    required
                    editMode={editMode}
                    setChildrenList={setChildrenList}
                  />
                  <ContingentSearch
                    editMode={editMode}
                    childrenList={childrenList}
                    setChildrenList={setChildrenList}
                  />
                  <Gender
                    name="sex"
                    editMode={editMode}
                    required
                  />
                  <Document
                    required
                    dependentFields
                    name="documentType"
                    editMode={editMode}
                  />
                  <DocumentNumber
                    required
                    editMode={editMode}
                    showErrorImmediately
                    calendarPosition="top-end"
                  />
                  {isBirthRecord ? (
                    <RegistryOffice
                      name="issued"
                      codeName="issuerCode"
                      required
                      editMode={editMode}
                    />
                  ) : null}
                  <Snils
                    name="snils"
                    editMode={editMode}
                    disabled={!adminEdit}
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  setFormKey(Math.random());
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ChildForm;
