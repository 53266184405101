import React, { useState } from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import ViewContainer from '../../components/view-container';
import { routes } from '../../config/constants';
import useClearHistoryState from '../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../lib/utils';
import { historyState } from '../../mock-data/history-state';
import { SearchDisciplinesFormData } from '../../types/discipline';
import DisciplineSearch from './search';
import DisciplinesTable from './table';

export const initialFormData: SearchDisciplinesFormData = {
  query: '',
  showArchive: false,
};

const Disciplines = () => {
  const [search, setSearch] = useState<SearchDisciplinesFormData>(
    window.history.state[historyState.search] || initialFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchDisciplinesFormData) => {
    setSearch(values);
    replaceHistoryState({
      [historyState.search]: values,
    });
  };

  return (
    <ViewContainer
      contentFlexColumn
      subHeader={
        <LmSubHeader
          sticky
          title="Дисциплины"
          description=""
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Дисциплины',
            },
          ]}
          hideReturnButton
          dataTest="disciplinesSubHeader"
        />
      }
    >
      <DisciplineSearch
        submitForm={handleSubmitSearch}
        initialForm={search}
      />
      <DisciplinesTable search={search} />
    </ViewContainer>
  );
};

export default Disciplines;
