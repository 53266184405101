import React from 'react';
import { TreeNodeType } from '@mosru/esz_uikit';
import { LmSelectNew } from '@mes-ui/lemma';
import { TreeModalContext } from '../index';
import { SelectOptionType } from '../../../types/entities';

type Props = {
  currentCategory: SelectOptionType | null;
  selectOptions?: SelectOptionType[];
  setDataTreePopup: (value: TreeNodeType[]) => void;
  setCurrentCategory: (value: SelectOptionType) => void;
};

const SelectCategory: React.FC<Props> = ({ setCurrentCategory, currentCategory, setDataTreePopup, selectOptions }) => {
  const { setCurrentMenuClosePopup } = React.useContext(TreeModalContext);

  return (
    <LmSelectNew
      dataTest="selectCurrentMenuClosePopup"
      name="currentMenuClosePopup"
      onChange={(value: SelectOptionType | null) => {
        setCurrentCategory(value as SelectOptionType);
        if (setCurrentMenuClosePopup) {
          setCurrentMenuClosePopup(value);
        }
        setDataTreePopup([]);
      }}
      options={selectOptions ?? []}
      value={currentCategory}
      size="medium"
      placeholder="Выберите значение..."
      grouped={false}
      multiple={false}
      settingDropdownAsPopover={{
        disablePortal: true,
      }}
    />
  );
};

export default SelectCategory;
