import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import FormikInput from '../../../../../../components/formik/formik-input';
import { usePrevious } from '../../../../../../hooks/use-previous';
import { SubmitValues } from '../index';

const FiledServiceClass = () => {
  const { values, setFieldValue, setTouched } = useFormikContext<SubmitValues>();

  const serviceClassIdPrev = usePrevious(values.serviceClassId);

  useEffect(() => {
    if (!values.serviceClassId) {
      setFieldValue('serviceClassName', null);
      setTouched({}, false);
    }
  }, [serviceClassIdPrev, setFieldValue, setTouched, values.serviceClassId]);

  return (
    <FormikInput
      label="Наименование группы"
      required
      size="small"
      placeholder="Введите..."
      name="serviceClassName"
      disabled={values.serviceClassId > 0}
    />
  );
};

export default FiledServiceClass;
