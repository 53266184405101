import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmBadgeStatus, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import { RequestContext } from '../../../lib/utils/requests';
import Details from './details';
import ChangeHistory from '../../../components/change-history';
import Favorite from '../../../components/favorite';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import { getRequestStatusColor } from '../../../lib/utils/dictionary';
import { routes } from '../../../config/constants';
import { RequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { generateLink, getServiceEditLink, goBack, hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { generalAccess } from '../../../types/authorization-data';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import { serviceTypeEnum } from '../../../mock-data/print';
import { EducationTypeEnum } from '../../../types/education-type';

type DigitalTutorRequestProps = {
  requestData: RequestData;
  updateRequest: () => void;
};

const DigitalTutorRequest = ({ requestData, updateRequest }: DigitalTutorRequestProps) => {
  const { routeBack } = useContext(RequestContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const adminView: boolean = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminView), [userProfile]);
  const editServiceVA = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Edit),
    [userProfile]
  );

  const goBackToService = () => {
    window.open(
      getServiceEditLink(EducationTypeEnum.VirtualAssistantEducation, requestData.trainingGroup.serviceId),
      '_blank'
    );
  };

  const handlePrint = () => {
    window.open(
      generateLink(routes.printRequest, {
        type: serviceTypeEnum.digitalTutor,
        id: requestData.id,
      }),
      '_blank'
    );
  };

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (adminView || editServiceVA) {
      buttons.push({
        dataTest: 'goToService',
        buttonText: 'Вернуться к ОУ',
        rounded: 'small',
        variant: 'secondary',
        onClick: goBackToService,
      });
    }
    buttons.push({
      dataTest: 'print',
      icon: 'filled-hardware-printer',
      buttonText: 'Печать',
      rounded: 'small',
      variant: 'secondary',
      onClick: handlePrint,
    });

    return buttons;
  };

  return (
    <LmSubHeader
      sticky
      description={
        <>
          <Push size={12} />
          <div className="flex items-center">
            Дата заявления: {formatDate(requestData.dateRequest)}
            <Push
              size={20}
              orientation="horizontal"
            />
            Источник: {requestData.requestSourceName}
          </div>
          <Push size={12} />
        </>
      }
      arrowOnClick={() => goBack(routeBack)}
      titleChildren={
        <div className="flex items-center">
          <Favorite
            entityId={requestData.id}
            typeEntity="заявление"
            currentEntityType={`№${requestData.requestNumber || ''}`}
            entityTypeId={EntityTypeEnum.Request}
          />
          <Push
            size={20}
            orientation="horizontal"
          />
          <LmBadgeStatus
            dataTest="requestStatus"
            {...getRequestStatusColor(requestData.requestStatusId)}
          >
            {requestData.requestStatusName}
          </LmBadgeStatus>
        </div>
      }
      dataTest="requestDigitalTourSubHeader"
      title={`Заявление №${requestData.requestNumber || ''}`}
      routes={[
        {
          label: 'Главная',
          link: routes.main,
        },
        {
          label: 'Заявления',
          link: routes.requests,
        },
        {
          label: 'Сведения о заявлении',
        },
      ]}
      tabs={[
        {
          title: 'Основные сведения',
          value: 1,
          dataTest: 'basicInfo',
        },
        {
          title: 'История изменений',
          value: 2,
          dataTest: 'changeHistory',
        },
      ]}
      className="content-panel"
      panels={[
        {
          children: (
            <Details
              requestData={requestData}
              updateRequest={updateRequest}
            />
          ),
          value: 1,
        },
        {
          children: (
            <ChangeHistory
              name="сведения о заявлении"
              extendEntityGuid={requestData?.extendEntityGuid}
              entityTypes={entityTypesForHistory.requests}
            />
          ),
          value: 2,
          className: 'content-panel content-panel--auto',
          noPadding: true,
        },
      ]}
      buttonsOrAnyActions={getButtons()}
    />
  );
};

export default DigitalTutorRequest;
