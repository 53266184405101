import React from 'react';
import { LmCheckbox } from '@mes-ui/lemma';
import { ServiceClassPupil } from '../../../../types/service-class';

export const GetCheckboxColumn = (
  selected: number[],
  learners: ServiceClassPupil[],
  setSelected: (value: number[]) => void,
  setOpenDetails: (value: boolean) => void,
  disabled = false
) => ({
  dataIndex: '',
  title: (
    <LmCheckbox
      dataTest="all"
      checked={selected.length === learners.length}
      indeterminate={!!selected.length && selected.length !== learners.length}
      onChange={() => {
        const s = selected.length
          ? []
          : learners.filter((learner) => learner.statusId !== 3).map((selectedItem) => selectedItem.id);

        setSelected(s);
        setOpenDetails(!!s.length);
      }}
      disabled={learners?.filter((learner) => learner.statusId !== 3).length === 0 || disabled}
      resetAllPadding
    />
  ),
  render: (item: any) => (
    <LmCheckbox
      dataTest={`pupil-${item.count}`}
      checked={selected.includes(item.id)}
      onChange={() => {
        const s = selected.includes(item.id)
          ? selected.filter((selectedItem) => selectedItem !== item.id)
          : [...selected, item.id];

        setSelected(s);
        setOpenDetails(!!s.length);
      }}
      disabled={item.statusId === 3 || disabled}
      resetAllPadding
    />
  ),
  width: '60px',
});
