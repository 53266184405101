import React, { useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { useHistory } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../config/constants';
import { ServiceClassData } from '../../../types/service-class';
import OrganizationField from '../components/fields/organization';
import SimpleInput from '../../../components/fields/simple-input';
import TeacherField from '../components/fields/teacher';
import PupilInGroupField from '../components/fields/pupil-in-group';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import history from '../../../history';
import serviceClassApi from '../../../lib/api/service-class';
import { generateLink } from '../../../lib/utils';
import { ServiceClassContext } from '../service-class';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import VedomstvoField from '../components/fields/vedomstvo';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { maxCommentLength } from '../../../lib/utils/service-class';
import { AuthorizationData } from '../../../types/authorization-data';
import SavePanel from '../../../components/save-panel';
import { SelectOptionType } from '../../../types/entities';

const ServiceClassDkgmCreate = () => {
  const { serviceClassData, adminEdit, oiv } = useContext(ServiceClassContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const historyRouter = useHistory();

  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const vedomstvoData: SelectOptionType[] = useGetDataDepartment();
  const sortedlist = vedomstvoData.filter((item: SelectOptionType) => item.value === 4 || item.value === 3);

  const submitForm = async (values: any) => {
    setLoadBtn(true);
    try {
      const id = await serviceClassApi.createServiceClass(values);

      history.push(
        generateLink(routes.serviceClass, {
          id,
        })
      );
    } catch {}
    setLoadBtn(false);
  };

  const initialData = useMemo(() => {
    return {
      ...serviceClassData,
      vedomstvoId: adminEdit ? 3 : userProfile.vedomstvoId,
      vedomstvoName: adminEdit ? 'Департамент культуры' : userProfile.vedomstvoName,
      organizationId: adminEdit || oiv ? null : userProfile?.organizationId,
      organizationName: adminEdit || oiv ? null : userProfile?.organizationName,
    } as ServiceClassData & AuthorizationData;
  }, [
    adminEdit,
    oiv,
    serviceClassData,
    userProfile.vedomstvoId,
    userProfile.vedomstvoName,
    userProfile?.organizationId,
    userProfile?.organizationName,
  ]);

  const initialErrors = useInitialErrors(initialData, getValidationSchema());

  const handleCancel = () => {
    history.push(routes.serviceClasses);
  };

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => historyRouter.push(generateLink(routes.serviceClasses, {}))}
        title="Новая группа"
        dataTest="serviceClassDkgmCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Группы обучения',
            link: routes.serviceClasses,
          },
          {
            label: 'Группа обучения',
          },
        ]}
      />
      <Push size={12} />
      <Formik
        onSubmit={(values, formikHelpers) => {
          submitForm(values);
          formikHelpers.setSubmitting(false);
        }}
        enableReinitialize
        initialValues={initialData}
        validationSchema={getValidationSchema()}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<ServiceClassData & AuthorizationData>) => {
          const { values, isValid, handleSubmit, submitForm } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Panel title={() => <>Сведения о группе</>}>
                <div className="container">
                  <div className="table-data">
                    <VedomstvoField
                      required
                      list={sortedlist}
                      editMode
                      name="vedomstvo"
                      disabled={
                        (!!values.id && values.id > 0 && !adminEdit) || (Boolean(values.vedomstvoId) && !adminEdit)
                      }
                    />
                    <OrganizationField
                      required
                      label="Организация"
                      name="organization"
                      editMode
                      placeholder="Начните вводить..."
                      educationType={serviceClassData.educationTypeId}
                      disabled={!(adminEdit || oiv)}
                    />

                    <SimpleInput
                      required
                      name="name"
                      editMode
                      label="Наименование группы"
                    />

                    <TeacherField
                      editMode
                      label="Руководитель"
                      name="supervisorTeacher"
                    />

                    <PupilInGroupField
                      name="included"
                      label="Человек в группе"
                      editMode
                      isNewServiceClass
                    />

                    <SimpleTextArea
                      name="description"
                      label="Комментарий"
                      editMode
                      placeholder="Введите..."
                      maxLength={maxCommentLength}
                    />
                  </div>
                </div>
              </Panel>

              <SavePanel
                buttonPrimaryText="Создать группу"
                primaryButtonModifiers={{
                  loading: loadBtn,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={handleCancel}
                onClickPrimaryButton={submitForm}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ServiceClassDkgmCreate;

const getValidationSchema = () =>
  objectYup().shape({
    vedomstvoId: stringYup().nullable().required('Выберите департамент'),
    organizationId: stringYup().nullable().required('Выберите организацию'),
    name: stringYup().nullable().required('Введите наименование группы').nullable(),
    capacity: numberYup()
      .min(1, 'Предельное число обучающихся в группе не может быть отрицательным числом или нулем')
      .nullable(),
  });
