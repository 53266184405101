import React, { useState } from 'react';
import classNames from 'classnames';
import { Push } from '@mosru/esz_uikit';
import { ScheduleDays } from '../../../../types/service-class';
import { ReactComponent as IconUp } from '../../../../assets/images/icons/up-primary.svg';

type Props = {
  periodsSchedule: ScheduleDays.TransformDays[];
};

export const Period = ({ periodsSchedule }: Props) => {
  const [isToggleOpen, setToggleOpen] = useState(0);

  if (periodsSchedule) {
    return (
      <>
        {periodsSchedule.map((item: ScheduleDays.TransformDays) => {
          const days = (
            <div>
              {renderDay(item.monday)}
              {renderDay(item.tuesday)}
              {renderDay(item.wednesday)}
              {renderDay(item.thursday)}
              {renderDay(item.friday)}
              {renderDay(item.saturday)}
              {renderDay(item.sunday)}
            </div>
          );

          return periodsSchedule.length > 1 ? (
            <div
              className="flex flex-column"
              key={item.id}
            >
              <div className="flex items-center">
                <span>{item.period}&nbsp;</span>
                <span
                  role="presentation"
                  className="brand-link cursor-pointer flex items-center"
                  onClick={() => setToggleOpen(isToggleOpen ? 0 : item.id)}
                >
                  {item.startEnd}
                  <span
                    className={classNames(
                      'icon-group__icon',
                      !(isToggleOpen === item.id) && 'icon-group__icon--rotate'
                    )}
                  >
                    <IconUp />
                  </span>
                </span>
              </div>
              {isToggleOpen === item.id && (
                <>
                  <Push
                    orientation="vertical"
                    size={8}
                  />
                  <div>{days}</div>
                  <Push
                    orientation="vertical"
                    size={8}
                  />
                </>
              )}
            </div>
          ) : (
            <div>{days}</div>
          );
        })}
      </>
    );
  }

  return null;
};

function renderDay(day: ScheduleDays.TransformDay): JSX.Element | null {
  return day.fromTo ? (
    <div>
      <span className="color-gray-dark">{day.name}&nbsp;&nbsp;</span>
      {day?.fromTo}
    </div>
  ) : null;
}
