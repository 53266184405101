import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import SavePanel from '../../../../../../components/save-panel';
import useInitialErrors from '../../../../../../hooks/formik-initial-errors';
import { ServiceStatusEnum } from '../../../../../../mock-data/service-status-enum';
import { EntranceTestsData } from '../../../../../../types/service';
import { serviceTemplateApi } from '../../../../../../lib/api/service-template';
import { ServiceContext } from '../../../../index';
import TestsEdit, { testValidationSchema } from './tests-edit';
import { templateApi } from '../../../../../../lib/api/template';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
  parent?: string;
};

const Tests: React.FC<Props> = ({ parent, isTemplate, setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const initialValues = serviceData.test ?? ({} as EntranceTestsData);
  const initialErrors = useInitialErrors(initialValues, testValidationSchema());
  const [editMode, setEditMode] = useState(!setEditModeParent);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitTest = async (values: EntranceTestsData) => {
    try {
      if (isTemplate) {
        await templateApi.updateTemplateTest(serviceData.id, {
          isRequired: values.isRequired,
          toursNumber: values.toursNumber,
          periodGettingDocument: values.periodGettingDocument,
          canChangeTestAndToursNumber: values.canChangeTestAndToursNumber,
          canChangePeriodGettingDocument: values.canChangePeriodGettingDocument,
          educationTypeId: serviceData.educationTypeId,
          templateId: serviceData.id,
        });
      } else {
        await serviceTemplateApi.updateTest(serviceData.id, {
          ...values,
          educationTypeId: serviceData.educationTypeId,
        });
      }
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } catch {}
  };

  return (
    <Formik
      onSubmit={(value) => submitTest(value)}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={testValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<EntranceTestsData>) => {
        const { isSubmitting, isValid, resetForm, handleSubmit, submitForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <TestsEdit
              parent={parent}
              editMode={editMode}
              isTemplate={isTemplate}
              setEditMode={setEditMode}
              setEditModeParent={setEditModeParent}
              checkEditable={checkEditable}
            />
            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent && setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Tests;
