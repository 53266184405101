import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { apiService } from '../../config/constants';
import api from '../../lib/api/api';
import axiosBaseQuery from '../../lib/api/axios-base-query';
import { MenuSettings, Response } from '../../types/entities';

export const menuSettingsApi = createApi({
  reducerPath: 'menuSettingsApi',
  baseQuery: axiosBaseQuery({
    baseURL: `${apiService.data}/MenuSettings`,
  }),
  endpoints: (builder) => ({
    getMenuSettings: builder.query<Response<MenuSettings[]>, void>({
      query: () => {
        return api.GET();
      },
    }),
  }),
});

export const { useGetMenuSettingsQuery } = menuSettingsApi;
