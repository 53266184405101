import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';
// import { ServiceClassContext } from '../service-class';
import ServiceClassGroup from './group';
import ServiceClassTable from './group-composition';

const ServiceClassDkgmDetails: React.FC = () => {
  // const { serviceClassData } = useContext(ServiceClassContext);

  const [editMode, setEditMode] = useState<string | null>(null);

  return (
    <>
      <Push size={12} />
      {(!editMode || editMode === 'group') && <ServiceClassGroup setEditModeParent={setEditMode} />}
      <Push size={12} />
      {!editMode && <ServiceClassTable />}
    </>
  );
};

export default ServiceClassDkgmDetails;
