// Тип представительства
export enum TypeRepresentationEnum {
  // Мать
  Mother = 1,

  // Отец
  Father = 2,

  // Законный представитель
  LegalRepresentative = 3,

  // Заявитель
  Declarant = 4,

  // Попечитель
  Trustee = 5,

  // Контактное лицо
  Contact = 6,

  // Ребенок
  Child = 7,

  // Супруг
  Spouse = 8,

  // Родственник
  Relative = 9,

  // Другой
  Other = 10,

  // Учитель
  Teacher = 11,
}
