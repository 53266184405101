import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../../config/constants';
import DayCareCreateDetails from './details';
import { getHeaderLink } from '../../utils';
import { CreateServiceContext } from '../../create';

const DayCareCreate = () => {
  const { currentEducationType, educationTypes, type, serviceData } = useContext(CreateServiceContext);

  const historyRouter = useHistory();

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => historyRouter.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))}
        title="Программа по уходу и присмотру"
        dataTest="serviceDayCareCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Программы по уходу и присмотру за детьми школьного возраста',
            link: getHeaderLink(educationTypes, type, currentEducationType),
          },
          {
            label: 'Описание программы по уходу и присмотру за детьми школьного возраста',
          },
        ]}
      />
      <DayCareCreateDetails />
    </>
  );
};

export default DayCareCreate;
