import React, { useState } from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import ViewContainer from '../../../components/view-container';
import { routes } from '../../../config/constants';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../../lib/utils';
import { historyState } from '../../../mock-data/history-state';
import { searchTeachersInitialFormData } from '../../../mock-data/teacher';
import TeachersSearch from './search';
import { SearchTeachersFormData } from '../../../types/teacher';
import TeachersTable from './table';

const TeacherList = () => {
  const [search, setSearch] = useState<SearchTeachersFormData>(
    window.history.state[historyState.search] || searchTeachersInitialFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchTeachersFormData) => {
    setSearch(values);
    replaceHistoryState({
      [historyState.search]: values,
    });
  };

  return (
    <ViewContainer
      subHeader={
        <LmSubHeader
          sticky
          description=""
          hideReturnButton
          title="Преподаватели"
          dataTest="teachersSubHeader"
          routes={[
            {
              label: 'Главная',
              link: routes.main,
            },
            {
              label: 'Преподаватели',
            },
          ]}
        />
      }
      filterComponent={
        <TeachersSearch
          submitForm={handleSubmitSearch}
          initialForm={search}
        />
      }
    >
      <TeachersTable search={search} />
    </ViewContainer>
  );
};

export default TeacherList;
