import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, array as arrayYup, ValidationError } from 'yup';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../../components/formik/formik-input-number';
import Popup from '../../../../../components/modals/popup';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import { validationCheckDate } from '../../../../../lib/utils/validation';
import DateField from '../../fields/add-plan/date';
import TimeField from '../../fields/add-plan/time';
import { ScheduleData, TrainingGroupData, TrainingStageData } from '../../../../../types/service';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { EducationTypeEnum } from '../../../../../types/education-type';

type Props = {
  show: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  planList: TrainingGroupData[];
  postSubmit: () => void;
  currentIdPlan?: number;
  educationType: EducationTypeEnum;
  serviceId: number;
};

const separator = (
  <div className="flex items-center flex-none table-row-item-height">
    <Push
      size={8}
      orientation="horizontal"
    />
    —
    <Push
      size={8}
      orientation="horizontal"
    />
  </div>
);

const DayCarePlanModal: React.FC<Props> = ({
  show,
  setShowAddModal,
  planList,
  serviceId,
  educationType,
  postSubmit,
  currentIdPlan,
}) => {
  const [initialValues, setInitialValues] = useState<TrainingGroupData>({
    volume: 0,
  } as TrainingGroupData);
  const initialErrors = useInitialErrors(initialValues, getValidationSchema(planList, currentIdPlan));
  const [formKey, setFormKey] = useState<number>(0);

  const submitTrainingGroup = async (plan: TrainingGroupData | undefined) => {
    if (plan) {
      if (!plan.id || plan.id === 0) {
        if (plan.scheduleList) {
          plan.scheduleList[0].trainingGroupId = 0;
        }
        await serviceTemplateApi.createTrainingGroup(serviceId, {
          ...plan,
          educationTypeId: educationType,
        });
      } else {
        await serviceTemplateApi.updateTrainingGroup(serviceId, {
          ...plan,
          educationTypeId: educationType,
        });
      }
    }
    postSubmit();
  };

  useEffect(() => {
    let plan: TrainingGroupData | undefined;

    if (currentIdPlan) {
      plan = planList?.find((p) => p.id === currentIdPlan);
      if (plan) {
        setInitialValues(plan);
        setFormKey(Math.random());
      }
    }
    if (!plan) {
      setInitialValues({
        volume: 0,
        scheduleList: [
          {
            requestTimeStart: '08:00',
            requestTimeEnd: '22:00',
          },
        ],
      } as TrainingGroupData);
      setFormKey(Math.random());
    }
  }, [currentIdPlan, planList]);

  const hideModal = useCallback(() => {
    setShowAddModal(false);
  }, [setShowAddModal]);

  const modalTitle = useMemo(() => (currentIdPlan ? 'Изменить план приема' : 'Добавить план приема'), [currentIdPlan]);

  return (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema(planList, currentIdPlan)}
      onSubmit={submitTrainingGroup}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<TrainingGroupData>) => {
        const { handleSubmit, isSubmitting, isValid, errors, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="planPopup"
              open={show}
              title={modalTitle}
              size="large"
              primaryButtonModifiers={{
                disabled: !isValid || isSubmitting,
              }}
              onClearData={resetForm}
              onClose={hideModal}
              onSubmit={handleSubmit}
            >
              <>
                <FormikFormGroup
                  name=""
                  label="Дата начала занятий / Учебный период"
                  required
                >
                  <div className="flex items-start">
                    <div
                      className="flex flex-auto"
                      style={{
                        width: '50%',
                      }}
                    >
                      <DateField name="stageList[0].dateStart" />
                    </div>
                    {separator}
                    <div
                      className="flex flex-auto"
                      style={{
                        width: '50%',
                      }}
                    >
                      <DateField name="stageList[0].dateEnd" />
                    </div>
                  </div>
                </FormikFormGroup>

                <Push size={16} />
                <FormikInputNumber
                  label="Количество мест"
                  required
                  name="volume"
                  disabled
                />

                <Push size={8} />
                <LmInfoBox
                  dataTest="volumeWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Данный параметр рассчитывается автоматически и является суммой значений предельного кол-ва человек в связанных группах обучения."
                  hidenFooter
                />

                <Push size={16} />
                <FormikFormGroup
                  name=""
                  label="Период приема заявлений на Mos.ru"
                  required
                >
                  <div className="flex items-start">
                    <div
                      className="flex flex-auto"
                      style={{
                        width: errors?.scheduleList ? '244px' : '',
                      }}
                    >
                      <DateField
                        name="scheduleList[0].requestStart"
                        dependentTimeName="scheduleList[0].requestTimeStart"
                        placement="top-start"
                      />
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <div className="flex-none input-time">
                        <TimeField
                          name="scheduleList[0].requestTimeStart"
                          dependentDateName="scheduleList[0].requestStart"
                        />
                      </div>
                    </div>
                    {separator}
                    <div
                      className="flex flex-auto"
                      style={{
                        width: errors?.scheduleList ? '244px' : '',
                      }}
                    >
                      <DateField
                        name="scheduleList[0].requestEnd"
                        dependentTimeName="scheduleList[0].requestTimeEnd"
                        placement="top-start"
                      />
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <div className="flex-none input-time">
                        <TimeField
                          name="scheduleList[0].requestTimeEnd"
                          dependentDateName="scheduleList[0].requestEnd"
                        />
                      </div>
                    </div>
                  </div>
                </FormikFormGroup>
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default DayCarePlanModal;

const getValidationSchema = (planList: TrainingGroupData[], currentIdPlan?: number) => {
  const depsDate: [string, string] = ['dateStart', 'dateEnd'];
  const depsMosDate: [string, string] = ['requestStart', 'requestEnd'];
  const tgList = planList;
  const tgId = currentIdPlan ?? 0;

  const dateValidation = objectYup().shape(
    {
      dateStart: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'dateStart',
          end: 'dateEnd',
        },
        'Дата начала учебного периода не может быть больше даты окончания',
        'start'
      ),
      dateEnd: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'dateStart',
          end: 'dateEnd',
        },
        'Дата окончания учебного периода не может быть меньше даты начала',
        'end'
      ),
    },
    [depsDate]
  );

  const dateMosValidation = objectYup().shape(
    {
      requestStart: validationCheckDate(
        'Выберите дату начала',
        {
          start: 'requestStart',
          end: 'requestEnd',
        },
        'Дата начала приема заявлений больше даты окончания приема заявлений',
        'start'
      ),
      requestEnd: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'requestStart',
          end: 'requestEnd',
        },
        'Дата окончания приема заявлений меньше даты начала приема заявлений',
        'end'
      ),
      requestTimeStart: stringYup().nullable().required('Введите время начала'),
      requestTimeEnd: stringYup().nullable().required('Введите время окончания'),
    },
    [depsMosDate]
  );

  return objectYup().shape(
    {
      stageList: arrayYup()
        .test('stageList', 'ошибка', function (values) {
          const errors: ValidationError[] = [];
          const stages = values as TrainingStageData[];

          if (stages?.length > 0 && stages[0].dateStart) {
            // проверяем продолжительность
            if (stages[0].dateEnd) {
              const start = new Date(stages[0].dateStart);

              start.setFullYear(start.getFullYear() + 1);
              if (start < new Date(stages[0].dateEnd)) {
                errors.push(
                  new ValidationError(
                    'Дата окончания учебного периода выходит за рамки продолжительности программы обучения',
                    undefined,
                    'stageList[0].dateEnd'
                  )
                );
              }
            }

            // проверяем нет ли уже плана на этот год
            if (tgList?.length > 0) {
              const currentStart = new Date(stages[0].dateStart);

              for (let i = 0; i < tgList.length; i++) {
                const start = new Date(tgList[i].stageList[0]?.dateStart);

                if (tgId !== tgList[i].id && currentStart.getFullYear() === start.getFullYear()) {
                  errors.push(
                    new ValidationError(
                      `На ${currentStart.getFullYear()} год уже есть план приема.`,
                      undefined,
                      'stageList[0].dateStart'
                    )
                  );
                  break;
                }
              }
            }
          }

          return errors.length > 0 ? new ValidationError(errors) : true;
        })
        .of(dateValidation)
        .required(),
      scheduleList: arrayYup()
        .test('stageList', 'ошибка', function (values) {
          const schedules = values as ScheduleData[];
          const errors: ValidationError[] = [];

          if (schedules?.length > 0) {
            if (schedules[0].requestStart) {
              const start = new Date(schedules[0].requestStart);

              if (schedules[0].requestEnd) {
                const end = new Date(schedules[0].requestEnd);
                const diff = end.getFullYear() - start.getFullYear();

                if (diff > 1) {
                  errors.push(
                    new ValidationError(
                      'Периода приема заявлений не может быть больше 1 года',
                      undefined,
                      'scheduleList[0].requestEnd'
                    )
                  );
                }

                const tg = this.parent as TrainingGroupData;

                if (tg?.stageList?.length > 0) {
                  const a = new Date(schedules[0].requestEnd);
                  const b = new Date(tg.stageList[0].dateEnd);

                  if (b < a) {
                    errors.push(
                      new ValidationError(
                        'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
                        undefined,
                        'scheduleList[0].requestEnd'
                      )
                    );
                  }
                }
              }
            }
          }

          return errors.length > 0 ? new ValidationError(errors) : true;
        })
        .of(dateMosValidation)
        .required(),
    },
    [depsDate, depsMosDate]
  );
};
