import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { LmLoader } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { ContractStatusEnum } from '../../../mock-data/contract-status-enum';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import DeclineReason from '../components/fields/decline-reason';
import { RequestDeclineData } from '../../../types/requests';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import InfoboxRefusal from '../components/infobox-refusal';
import { submitRefusal } from '../utils';
import { RefuseModal } from '../components/modals';
import { requestDeclineReasonId } from '../../../lib/utils/validation';
import SavePanel from '../../../components/save-panel';

type Props = {
  editMode: boolean;
  setEditMode: () => void;
  decline: RequestDeclineData;
  requestStatusId: RequestStatusEnum;
  requestId: number;
  updateRequest: () => void;
  changeStatusRequest?: () => void;
  contractStatusId?: number;
};

const ActionsFormRefusal: React.FC<Props> = ({
  editMode,
  setEditMode,
  decline,
  requestStatusId,
  requestId,
  updateRequest,
  changeStatusRequest,
  contractStatusId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formKey, setFormKey] = useState<number>(0);

  const notAvailableRefusal =
    requestStatusId === RequestStatusEnum.AcceptedElectronically ||
    requestStatusId === RequestStatusEnum.WaitingDraftContract ||
    (requestStatusId === RequestStatusEnum.WaitForElectronicAccept &&
      contractStatusId === ContractStatusEnum.SigningRequiredByOrganization);

  const infoBoxText =
    requestStatusId === RequestStatusEnum.AcceptedElectronically
      ? 'В данном статусе отказ в зачислении недоступен'
      : undefined;

  const initialErrors = useInitialErrors(decline, getValidationSchema());
  const submitForm = useCallback(
    async (values: RequestDeclineData) => {
      await submitRefusal(values, requestId, setLoading, setShowModal, setEditMode, updateRequest);
      // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
      changeStatusRequest && changeStatusRequest();
      updateRequest();
      setFormKey(Math.random());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestId, updateRequest, setEditMode]
  );

  useEffect(() => {
    setFormKey(Math.random());
  }, [decline]);

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : notAvailableRefusal ? (
    <InfoboxRefusal description={infoBoxText} />
  ) : (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={decline}
    >
      {(formikProps: FormikProps<RequestDeclineData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="table-data">
                <DeclineReason
                  name="requestDeclineReason"
                  disabled={!editMode}
                  editMode={editMode}
                  requestId={requestId}
                  required
                />
                <SimpleTextArea
                  name="declineComment"
                  disabled={!editMode}
                  editMode={editMode}
                  label="Комментарий"
                  placeholder={editMode ? 'Введите...' : ''}
                />
              </div>
            </div>
            {editMode && (
              <SavePanel
                buttonPrimaryText="Отправить отказ"
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickPrimaryButton={() => setShowModal(true)}
                onClickSeconadaryButton={() => {
                  resetForm();
                  setEditMode();
                  setFormKey(Math.random());
                }}
              />
            )}
            <RefuseModal
              showModal={showModal}
              setShowModal={setShowModal}
              handleSubmit={handleSubmit}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionsFormRefusal;

const getValidationSchema = () =>
  objectYup().shape({
    requestDeclineReasonId,
  });
