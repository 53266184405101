import React, { useMemo, useState } from 'react';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmSwitcher, LmSwitcherOption } from '@mes-ui/lemma';
import ActionsFormEnrollment from './actions-form-enrollment';
import ActionsFormRefusal from './actions-form-refusal';
import ActionsFormEnrolled from './actions-form-enrolled';
import { RequestData, RequestDeclineData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';

type Props = {
  requestData?: RequestData;
  decline: RequestDeclineData;
  requestStatusId: RequestStatusEnum;
  serviceId: number;
  requestId: number;
  extendEntityGuid: string;
  updateRequest: () => void;
  setEditModeParent: (value: string | null) => void;
  isNewService: boolean;
};

const ActionsForm: React.FC<Props> = ({
  requestStatusId,
  serviceId,
  decline,
  requestId,
  updateRequest,
  setEditModeParent,
  extendEntityGuid,
  requestData,
  isNewService,
}) => {
  const showDecline = useMemo(
    () =>
      requestStatusId !== RequestStatusEnum.Included &&
      requestStatusId !== RequestStatusEnum.IncludedExcluded &&
      requestStatusId !== RequestStatusEnum.Archive &&
      requestStatusId !== RequestStatusEnum.ApplicantRefused &&
      requestStatusId !== RequestStatusEnum.New,
    [requestStatusId]
  );

  const showEnrollment = useMemo(
    () =>
      requestStatusId !== RequestStatusEnum.IncludeDeclined &&
      requestStatusId !== RequestStatusEnum.IncludedExcluded &&
      requestStatusId !== RequestStatusEnum.ApplicantRefused &&
      requestStatusId !== RequestStatusEnum.WaitForElectronicAccept &&
      requestStatusId !== RequestStatusEnum.New &&
      requestStatusId !== RequestStatusEnum.Included &&
      requestStatusId !== RequestStatusEnum.Archive &&
      requestStatusId !== RequestStatusEnum.WaitingSendingDocuments &&
      requestStatusId !== RequestStatusEnum.WaitingDraftContract &&
      requestStatusId !== RequestStatusEnum.WaitingDocumentVerification,
    [requestStatusId]
  );
  const [refusal, setRefusal] = useState(!showEnrollment);

  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <Push size={12} />
      {showDecline || showEnrollment ? (
        <Panel
          title={() => (showDecline && !showEnrollment ? 'Отказ в зачислении' : 'Действия по заявлению')}
          headingControl={() =>
            showDecline &&
            showEnrollment && (
              <LmSwitcher
                dataTest="groupActions"
                size="small"
                onChange={setRefusal}
                variant="gray"
                value={refusal}
              >
                <LmSwitcherOption
                  dataTest="switcher-show-enrollment"
                  value={false}
                >
                  Зачисление
                </LmSwitcherOption>
                <LmSwitcherOption
                  dataTest="switcher-show-decline"
                  value
                >
                  Отказ в зачислении
                </LmSwitcherOption>
              </LmSwitcher>
            )
          }
        >
          {refusal ? (
            <ActionsFormRefusal
              decline={decline}
              requestId={requestId}
              serviceId={serviceId}
              editMode={editMode}
              setEditMode={() => {
                setEditModeParent('');
                setEditMode(false);
              }}
              updateRequest={updateRequest}
              disabled={requestStatusId === RequestStatusEnum.IncludeDeclined}
              requestStatusId={requestStatusId}
              isNewService={isNewService}
            /> // !@#$ + disabled when btnSaveDsbl
          ) : (
            <ActionsFormEnrollment
              requestId={requestId}
              setEditMode={() => {
                setEditModeParent('');
                setEditMode(false);
              }}
              updateRequest={updateRequest}
              extendEntityGuid={extendEntityGuid}
              requestData={requestData}
            />
          )}
        </Panel>
      ) : (
        requestStatusId !== RequestStatusEnum.ApplicantRefused && (
          <Panel
            title={() =>
              requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded
                ? 'Зачисление'
                : 'Действия по заявлению'
            }
          >
            <ActionsFormEnrolled enrollment={requestData?.enrollment} />
          </Panel>
        )
      )}
    </>
  );
};

export default ActionsForm;
