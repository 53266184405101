import React, { useRef, useState } from 'react';
import moment from 'moment';
import { Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { AdvertisementViewData } from '../../types/static-content';

type Props = {
  adv: AdvertisementViewData;
  changeState: (id: number) => void;
};

const Notification: React.FC<Props> = ({ adv, changeState }) => {
  const [open, setOpen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    !adv.isRead && !open && changeState(adv.id);
    setOpen((prevState) => !prevState);
  };

  return (
    <div className="desktop-notification">
      <div className="desktop-notification__head">
        <div
          className="desktop-notification__title cursor-pointer"
          onClick={handleClick}
        >
          <span className={`font-weight-bold font-size-base ${adv.isRead ? 'color-gray-dark' : ''}`}>{adv.topic}</span>
          {!adv.isRead && !open && (
            <div className="flex items-center">
              <Push
                size={8}
                orientation="horizontal"
              />
              <div className="event-new-badge" />
            </div>
          )}
        </div>
        <div
          onClick={handleClick}
          className="drop-down-btn-round  cursor-pointer"
        >
          {open ? (
            <LmIcon
              icon="filled-chevrons-small-up"
              size={24}
              color="var(--LM-neutrals-day-900)"
            />
          ) : (
            <LmIcon
              icon="filled-chevrons-small-down"
              size={24}
              color="var(--LM-neutrals-day-900)"
            />
          )}
        </div>
      </div>
      <div className="color-gray-dark font-size-xsmall">
        {adv.publicationDate ? moment(adv.publicationDate).format('DD.MM.YYYY в HH:mm') : ''}
      </div>
      <div className="desktop-notification__body">
        {open && (
          <div
            className={`desktop-notification__text ${adv.isRead ? 'color-gray-dark' : ''}`}
            ref={elementRef}
            dangerouslySetInnerHTML={{
              __html: adv.text,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Notification;
