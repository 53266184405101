import React from 'react';
import { useField } from 'formik';
import { LmDatePicker, LmDatePickerProps } from '@mes-ui/lemma';

export type FormicDatePickerProps = Omit<LmDatePickerProps, 'name' | 'size' | 'onChangeDate' | 'onChange'> & {
  name: string;
  size?: 'small' | 'medium' | 'large';
  onChangeValue?: (date: Date | Date[] | null) => void;
  showErrorImmediately?: boolean;
};

const FormikDatePicker: React.FC<FormicDatePickerProps> = ({
  dataTest,
  name,
  size,
  fullWidth = true,
  onChangeValue,
  selectedDay,
  lastSelectDay,
  placeholder,
  showErrorImmediately,
  disablePortal = true,
  readOnly,
  ...props
}) => {
  const [{ value, onChange, onBlur, ...field }, meta, helpers] = useField(name);

  const metaErrorText = showErrorImmediately ? meta.error : meta.touched ? meta.error : undefined;
  const isError = props.isError || !!metaErrorText;
  const explainText = metaErrorText || props.explainText;

  const start =
    selectedDay ||
    (value ? (Array.isArray(value) ? (value[0] ? new Date(value[0]) : undefined) : new Date(value)) : undefined);

  const end = lastSelectDay || (value && Array.isArray(value) && value[1] ? new Date(value[1]) : undefined);

  const handleChange = (value: Date | Date[] | undefined) => {
    !meta.touched && helpers.setTouched(true, true);
    const newValue = Array.isArray(value) && !value[0] && !value[1] ? null : value ?? null;

    helpers.setValue(newValue);
    onChangeValue?.(newValue);
  };

  return (
    <LmDatePicker
      {...field}
      {...props}
      datePickerOnBlur={() => setTimeout(() => helpers.setTouched(true), 0)}
      placeholder={placeholder ?? (props.isIntervalDate ? 'ДД.ММ.ГГГГ — ДД.ММ.ГГГГ' : 'ДД.ММ.ГГГГ')}
      dataTest={dataTest || name}
      inputSize={size}
      selectedDay={start}
      lastSelectDay={end}
      isError={isError}
      explainText={explainText}
      onChangeDate={handleChange}
      fullWidth={fullWidth}
      disablePortal={disablePortal}
      readOnly={props.isIntervalDate || readOnly}
    />
  );
};

export default FormikDatePicker;
