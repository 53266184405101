import React, { useEffect, useMemo, useState } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import { buildFormFieldName, setAsyncValues } from '../../../../lib/utils/requests';
import { docNumberDateOfIssueField, docNumberField, docNumberSeriesField } from './document-number';
import { issuePlace } from './issued-place';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { IssuedPlaceEnum } from '../../../../mock-data/issued-place';
import { RequestData } from '../../../../types/requests';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { filterEducations } from '../../../../lib/utils/education';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { EducationTypeEnum } from '../../../../types/education-type';

type DocumentProps = {
  formType?: FormTypeEnum;
  disabled?: boolean;
  size?: 1 | 2 | 3;
  parent?: string;
  dependentFields?: boolean;
  onChangeDocument?: (value?: SelectOptionType) => void;
} & FieldProps<string>;

export const documentTypeField = 'documentTypeId';
export const documentTypeFieldName = 'documentTypeName';
export const issuedFieldName = 'issued';
const Document = ({
  label = 'Документ, удост. личность',
  name,
  editMode,
  required,
  formType = FormTypeEnum.EditRequestOther,
  disabled = false,
  size,
  parent,
  dependentFields,
  onChangeDocument,
}: DocumentProps) => {
  const { setFieldValue } = useFormikContext<FormikHelpers<RequestData>>();
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);

  const fieldId = `${name}Id`;
  const fieldName = `${name}Name`;

  const documentTypeIdField = buildFormFieldName(parent, fieldId);
  const documentTypeNameField = buildFormFieldName(parent, fieldName);

  const [documentTypeId, , setDocumentTypeId] = useField(documentTypeIdField);
  const [documentTypeName, , setDocumentTypeName] = useField(documentTypeNameField);

  const numberField = buildFormFieldName(parent, docNumberField);
  const seriesField = buildFormFieldName(parent, docNumberSeriesField);
  const issueField = buildFormFieldName(parent, docNumberDateOfIssueField);
  const issuePlaceField = buildFormFieldName(parent, issuePlace);
  const issuedField = buildFormFieldName(parent, issuedFieldName);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const educationTypeData = useMemo(
    () =>
      filterEducations(
        userProfile.objectAccessActionList,
        [],
        undefined,
        accessObject.Requests,
        accessAction.ViewRegistry,
        true
      ),
    [userProfile.objectAccessActionList]
  );

  const changeDocument = (option?: SelectOptionType) => {
    setDocumentTypeName.setValue(option?.label);
    setDocumentTypeId.setValue(option?.value);
    onChangeDocument?.(option);
  };

  useEffect(() => {
    if (!documentTypeId?.value) {
      if (optionsData.length > 0) {
        changeDocument(optionsData[0]);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [documentTypeId?.value, optionsData]);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await dictionariesApi.getDocumentTypes(formType);

      setOptionsData(response);
    };

    if (editMode) {
      fetchOptions();
    }
  }, [editMode, formType]);

  const clearDependentFields = (id: number) => {
    const dataToClearValues = [
      {
        key: seriesField,
      },
      {
        key: numberField,
      },
      {
        key: issueField,
      },
      {
        key: issuedField,
      },
    ];

    if (id !== DocumentTypeEnum.BirthCertificateForeign) {
      dataToClearValues.push({
        key: issuePlaceField,
      });
      setAsyncValues(dataToClearValues, setFieldValue);
    } else {
      setAsyncValues(dataToClearValues, setFieldValue);
      setFieldValue(issuePlaceField, IssuedPlaceEnum.ForeignStates);
    }
  };

  // Если с бэкенда пришли значения "documentTypeId" и "documentTypeName" которых нет,
  // в данном типе обучения то мы устанавливаем дефолтное значение
  useEffect(() => {
    if (parent === 'child') {
      const documentDayCareOptions = [
        DocumentTypeEnum.BirthCertificate,
        DocumentTypeEnum.BirthCertificateForeign,
        DocumentTypeEnum.BirthRecord,
      ];

      const checkDayCareCentersEducation =
        educationTypeData[0].value === EducationTypeEnum.DayCareCentersEducation &&
        !documentDayCareOptions.includes(documentTypeId?.value);

      const checkOtherTypeEducation = ![
        ...documentDayCareOptions,
        DocumentTypeEnum.Passport,
        DocumentTypeEnum.ForeignPassport,
      ].includes(documentTypeId?.value);

      if (checkDayCareCentersEducation || checkOtherTypeEducation) {
        changeDocument(optionsData[0]);
      }
    }
  }, [documentTypeId?.value, educationTypeData, optionsData, parent, setDocumentTypeId, setDocumentTypeName]);

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={documentTypeName.value}
      size={size || undefined}
      defaultValue="—"
    >
      <FormikSelect
        required
        name={documentTypeIdField}
        size="small"
        options={optionsData}
        defaultValue={
          documentTypeId.value && documentTypeName.value
            ? {
                value: documentTypeId.value,
                label: documentTypeName.value,
              }
            : null
        }
        placeholder="Выберите документ"
        disabled={disabled}
        selectedValue={(v?: SelectOptionType) => {
          changeDocument(v);

          if (dependentFields) {
            clearDependentFields(Number(v?.value));
          }
        }}
      />
    </Field>
  );
};

export default Document;
