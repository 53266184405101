import { fetchRequest } from '.';
import { apiService } from '../../config/constants';
import { BookingResultData, BookingSlotData } from '../../types/booking';

const preBookingByscheduleOfTimetable = async (scheduleOfTimetableId: number): Promise<BookingResultData> =>
  await fetchRequest.post(
    `${apiService.booking}/Booking/TrainingGroup?scheduleOfTimetable=${scheduleOfTimetableId}`,
    {}
  );

const preBookingByServiceClass = async (serviceClassId: number): Promise<BookingResultData> =>
  await fetchRequest.post(`${apiService.booking}/Booking/ServiceClass?serviceClassId=${serviceClassId}`, {});

const deletePreBookingByServiceClass = async (bookingId: string, serviceClassId: number): Promise<void> =>
  await fetchRequest.delete(
    `${apiService.booking}/Booking/ServiceClass/${bookingId}`,
    {
      serviceClassId,
    },
    {
      showAccessDeny: false,
    }
  );

const getTrainingGroupSlots = async (
  scheduleId?: number,
  bookingTypeId = 1,
  serviceClassId?: number
): Promise<BookingSlotData[]> => {
  const result = (await fetchRequest.post(`${apiService.booking}/Slot/Search`, {
    IsActual: true,
    BookingTypeId: bookingTypeId, // BookingTypeEnum.TrainingGroupBooking
    TrainingGroupData: {
      ScheduleOfTimetableId: scheduleId,
    },
    ServiceClassData: {
      serviceClassId,
    },
  })) as BookingSlotData[] | undefined | null;

  return result
    ? result.filter(
      (s) => s.isActual && (!s.volume || s.volume - (s.volumeUsed ?? 0) - (s.volumeUnapprovedUsed ?? 0) > 0)
    )
    : [];
};

const makePreBooking = async (slotId: string | undefined): Promise<BookingResultData> =>
  await fetchRequest.post(`${apiService.booking}/Booking?slotId=${slotId}`, {});
const undoPreBooking = async (slotId: string, bookingId: string) =>
  await fetchRequest.delete(`${apiService.booking}/Booking/${bookingId}`, {
    slotId,
  });

export const bookingApi = {
  preBookingByscheduleOfTimetable,
  preBookingByServiceClass,
  deletePreBookingByServiceClass,
  getTrainingGroupSlots,
  makePreBooking,
  undoPreBooking,
};
