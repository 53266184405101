import React, { useContext } from 'react';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../../config/constants';
import { generateLink } from '../../../../lib/utils';
import CreateDetails from './create-details';
import history from '../../../../history';
import { CreateServiceContext } from '../../create';

const ServiceCreate = () => {
  const { serviceData } = useContext(CreateServiceContext);
  const cancelHandler = () => {
    const url = serviceData.info.templateId
      ? generateLink(routes.myTemplate, {
        id: serviceData.info.templateId,
      })
      : generateLink(routes.services, {});

    history.push(url);
  };

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={cancelHandler}
        title="Новая образовательная услуга"
        dataTest="serviceDkgmDsitCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Образовательные услуги',
            link: routes.services,
          },
          {
            label: 'Описание образовательной услуги',
          },
        ]}
      />
      <CreateDetails cancelHandler={cancelHandler} />
    </>
  );
};

export default ServiceCreate;
