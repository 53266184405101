import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import Popup from '../../../../components/modals/popup';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import serviceClassApi from '../../../../lib/api/service-class';
import { ServiceClassRejectionPopup } from '../../../../types/service-class';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { docDate } from '../../../../lib/utils/validation';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { ServiceClassContext } from '../../service-class';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceClassTableContext } from '../../utils';
import { SelectOptionType } from '../../../../types/entities';

type Props = {
  open: boolean;
  close: () => void;
};

const Expulsion: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { updateServiceClass } = useContext(ServiceClassContext);

  const { admin } = useContext(ServiceClassContext);

  const [reasons, setReasons] = useState<SelectOptionType[]>([]);

  const [documents, setDocuments] = useState<SelectOptionType[]>([]);

  const defaultReason = reasons.find((item) => item.value === 4);
  const defaultDocumentType = documents.find((item) => item.value === 3);

  const initialValues = useMemo(() => {
    return {
      excludeReasonId: defaultReason?.value,
      documentTypeId: defaultDocumentType?.value,
    };
  }, [defaultDocumentType?.value, defaultReason?.value]);

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const accessDocumentType = () => {
    if (admin) {
      return !(
        serviceClassData.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
        serviceClassData.educationTypeId === EducationTypeEnum.SportEducation ||
        serviceClassData.educationTypeId === EducationTypeEnum.ArtHouseEducation
      );
    } else {
      return !(
        hasAccessObjectAny(userProfile, [accessObject.ServiceClassSport], accessAction.Edit) ||
        hasAccessObjectAny(userProfile, [accessObject.ServiceClassArtHouse], accessAction.Edit) ||
        hasAccessObjectAny(userProfile, [accessObject.ServiceClassNonDOGM], accessAction.Edit)
      );
    }
  };

  const accessExamParticipate = () => {
    if (admin) {
      return serviceClassData.educationTypeId === EducationTypeEnum.ProfessionalEducation;
    } else {
      return hasAccessObjectAny(userProfile, [accessObject.ServiceClassOP], accessAction.Edit);
    }
  };

  useEffect(() => {
    if (serviceClassData.educationTypeId) {
      const fetch = async () => {
        const reasons = await dictionariesApi.postListPupilDeclineReason(
          serviceClassData.educationTypeId,
          userProfile.vedomstvoId as number
        );

        setReasons(reasons);
      };

      open && fetch();
    }
  }, [open, selected, serviceClassData.educationTypeId, userProfile.vedomstvoId]);

  useEffect(() => {
    const fetch = async () => {
      const documentTypes = await dictionariesApi.getDocumentTypes(3);

      setDocuments(documentTypes);
    };

    open && fetch();
  }, [open]);

  const submitForm = useCallback(
    async (data: ServiceClassRejectionPopup) => {
      if (serviceClassData.id) {
        await serviceClassApi.rejectionServiceClass(serviceClassData.id, {
          ...data,
          megaRelationIds: selected,
          serviceClassId: serviceClassData.teacher.serviceClassId as number,
          educationTypeId: serviceClassData.educationTypeId,
        });

        setOpenDetails(false);
        fetchTable();
        setSelected([]);
        close();
        updateServiceClass();
      }
    },
    [
      setSelected,
      close,
      setOpenDetails,
      selected,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      serviceClassData.teacher.serviceClassId,
      updateServiceClass,
      fetchTable,
    ]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
      initialErrors={initialErrors}
      initialValues={initialValues as unknown as ServiceClassRejectionPopup}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<ServiceClassRejectionPopup>) => {
        const { handleSubmit, isSubmitting, isValid, setFieldValue, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="expulsionPopup"
              open={open}
              title="Отчисление из группы"
              buttonPrimaryText="Отчислить"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid,
              }}
              onClearData={resetForm}
              onClose={close}
              onSubmit={handleSubmit}
            >
              <>
                <FormikSelect
                  label="Тип документа"
                  required
                  size="small"
                  withSearch
                  disabled={accessDocumentType()}
                  options={documents}
                  name="documentTypeId"
                  placeholder="Выберите..."
                />

                <Push size={16} />

                <FormikInput
                  label="Номер документа"
                  size="small"
                  name="docNumber"
                  placeholder="Введите..."
                />

                <Push size={16} />

                <FormikDatePicker
                  label="Дата документа"
                  size="small"
                  name="docDate"
                  disablePortal={false}
                />
                <Push size={16} />
                <FormikSelect
                  label="Причина отчисления"
                  required
                  size="small"
                  withSearch
                  options={reasons}
                  name="excludeReasonId"
                  selectedValue={(value?: SelectOptionType) => {
                    setFieldValue('excludeReasonName', value?.label);
                  }}
                  placeholder="Выберите..."
                />
                <Push size={16} />

                {accessExamParticipate() && (
                  <>
                    <FormikCheckbox
                      label="Участвовал в сдаче аттестационного экзамена"
                      name="isExamParticipate"
                      boxSize="small"
                    />

                    <Push size={16} />
                  </>
                )}

                <LmInfoBox
                  dataTest="expulsionError"
                  className="infobox--full-width"
                  variant="alert"
                  description="Обратите внимание, при нажатии на кнопку “Отчислить” сведениям у отмеченных обучающихся будет присвоен статус Отчислен."
                  hidenFooter
                />
              </>
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default Expulsion;

const getValidationSchema = () =>
  objectYup().shape({
    documentTypeId: stringYup().required('Выберите тип документа'),
    excludeReasonId: stringYup().required('Выберите причину'),
    docDate: docDate.nullable(),
  });
