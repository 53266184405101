import React, { useCallback } from 'react';
import contingentApi from '../../../../../lib/api/contingents';
import requestsApi from '../../../../../lib/api/requests';
import { typePersonEnum } from '../../../../../mock-data/type-person-enum';
import { LikeModalProps, ListPerson } from '../../../../../types/like-modal';
import LikeModal from './components';

const ChildLikeModal = ({ show, onClose, extendEntityGuid, requestId, data, updateRequest }: LikeModalProps) => {
  const getListPerson = useCallback(async (): Promise<ListPerson[] | undefined> => {
    if (extendEntityGuid) {
      return await contingentApi.getPersonLike(extendEntityGuid);
    }
  }, [extendEntityGuid]);

  const createNewPerson = async () => {
    if (requestId) {
      return await requestsApi.newPerson(requestId, typePersonEnum.child);
    }
  };

  return (
    <LikeModal
      open={show}
      title="Проверка сведений об обучающемся"
      close={onClose}
      data={data}
      requestId={requestId}
      updateRequest={updateRequest}
      extendEntityGuid={extendEntityGuid}
      getList={getListPerson}
      createNewPerson={createNewPerson}
      isShortBirthRecordCheck
    />
  );
};

export default ChildLikeModal;
