import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LmButton, LmIcon, LmTooltip, LmBadgeStatus } from '@mes-ui/lemma';
import { Panel, Push } from '@mosru/esz_uikit';
import { historyState } from '../../../mock-data/history-state';
import { ReactComponent as IconExcel } from '../../../assets/images/teachers/excel.svg';
import { generateLink, hasGeneralAccess, replaceHistoryState, showExcelErrorData } from '../../../lib/utils';
import { maxRegistryRows, routes } from '../../../config/constants';
import learnerApi from '../../../lib/api/learner';
import { checkStatuses, numberWithCommas, transformDataLearners } from '../../../lib/utils/learners';
import { pageSizeOptions } from '../../../mock-data';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { generalAccess } from '../../../mock-data/access-enum';
import SplitModal from './split-modal';
import { SearchLearnersInitialFormData } from '../../../types/learners';
import AsyncTable from '../../../components/table/async-table';

type Props = {
  search: SearchLearnersInitialFormData | undefined;
};

const LearnersTable: React.FC<Props> = ({ search }) => {
  const [pageSize, setPageSize] = useState(window.history.state[historyState.pageSize] || pageSizeOptions[0].value);
  const [modal, showModal] = useState(false);

  const [tableData, setTableData] = useState<any>([]);
  const [tableTotal, setTableTotal] = useState<number>(0);
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<SearchLearnersInitialFormData>();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const fetchData = useCallback(async () => {
    if (currentSearch) {
      setLoading(true);
      const defaultData = {
        ...currentSearch,
        organizationName: undefined,
        pageSize,
        pageNumber: pageNum + 1,
      };

      const learners = await learnerApi.postLearners(defaultData);

      setTableData(transformDataLearners(learners.items, defaultData.pageSize, defaultData.pageNumber));
      setTableTotal(learners.total);
      setLoading(false);
    }
  }, [currentSearch, pageSize, pageNum]);

  useLayoutEffect(() => {
    if (search) {
      setCurrentSearch(search);
      if (initPageNum) {
        setInitPageNum(0);
      } else {
        setPageNum(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    replaceHistoryState({
      [historyState.pageSize]: pageSize,
    });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({
      [historyState.pageNumber]: pageNum,
    });
  }, [pageNum]);

  const handleExportFile = async () => {
    setLoadingFile(true);
    try {
      await learnerApi.getLearnersExcel({
        ...currentSearch,
        pageSize,
        pageNumber: pageNum + 1,
      });
    } catch (e) {
      showExcelErrorData();
    } finally {
      setLoadingFile(false);
    }
  };

  return (
    <>
      <Panel
        title={() => (
          <>
            Обучающиеся
            <span className="color-gray-dark">
              {' \u00A0'}
              {numberWithCommas(tableTotal)}
            </span>
          </>
        )}
        headingControl={() => (
          <>
            <LmButton
              dataTest="export"
              type="button"
              variant="secondary"
              loading={loadingFile}
              onClick={handleExportFile}
            >
              <span className="icon-button-right">
                Экспорт
                <IconExcel />
              </span>
            </LmButton>
            <Push
              orientation="horizontal"
              size={12}
            />

            {hasGeneralAccess(userProfile, generalAccess.AdminEdit) && (
              <LmButton
                type="button"
                icon="filled-edit-copy"
                iconSize={20}
                onClick={() => showModal(true)}
              >
                Объединить личные дела
              </LmButton>
            )}
          </>
        )}
      >
        <AsyncTable
          loading={loading}
          data={tableData}
          pageNum={pageNum}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsAll={tableTotal}
          rowLimit={maxRegistryRows}
          setPageNumber={setPageNum}
          loaderTitle="Загружаем реестр обучающихся"
          loaderDescription="Нужно немного подождать"
          columns={[
            {
              dataIndex: 'row',
              title: '№',
              render: (item: any) => item.row,
              width: '88px',
            },
            {
              dataIndex: 'name',
              title: 'ФИО',
              render: (item: any) => (
                <Link
                  className="learners-table-name"
                  to={`${routes.learners}/${item.id}`}
                  type="button"
                >
                  {item.name}
                </Link>
              ),

              width: '25%',
            },
            {
              dataIndex: 'hasInContingent',
              title: (
                <span
                  style={{
                    margin: '0 auto',
                  }}
                >
                  Реестр
                </span>
              ),
              render: (item: any) => (
                <div className="text-center">
                  <LmTooltip
                    withArrow
                    placement="top"
                    content={
                      item.hasInContingent
                        ? 'Сведения найдены в Реестре контингента'
                        : 'Сведения в Реестре контингента отсутствуют'
                    }
                  >
                    <div className="flex justify-center">
                      {item.hasInContingent ? (
                        <LmIcon
                          icon="outline-edit-checkmark-circle"
                          size={20}
                          color="var(--LM-green-200)"
                        />
                      ) : (
                        <LmIcon
                          icon="outline-edit-close-circle"
                          size={20}
                          color="var(--LM-red-200)"
                        />
                      )}
                    </div>
                  </LmTooltip>
                </div>
              ),
            },
            {
              dataIndex: 'sexId',
              title: 'Пол',
              render: (item: any) => item.sexId,
            },
            {
              dataIndex: 'birthDate',
              title: 'Дата рождения',
              render: (item: any) => item.birthDate,
            },
            {
              dataIndex: 'statuses',
              title: 'Статусы',
              render: (item: any) => (
                <div className="flex">
                  {checkStatuses(item.statuses).included ? (
                    <LmBadgeStatus
                      dataTest="learnerEnrolledStatus"
                      color="green"
                    >{`Зачислен (${checkStatuses(item.statuses).included})`}</LmBadgeStatus>
                  ) : (
                    ''
                  )}

                  {checkStatuses(item.statuses).included ? (
                    <Push
                      orientation="horizontal"
                      size={12}
                    />
                  ) : (
                    ''
                  )}

                  {checkStatuses(item.statuses).excluded ? (
                    <LmBadgeStatus
                      dataTest="learnerExpelledStatus"
                      color="orange"
                    >{`Отчислен (${checkStatuses(item.statuses).excluded})`}</LmBadgeStatus>
                  ) : (
                    ''
                  )}
                </div>
              ),
            },
            {
              dataIndex: '',
              title: '',
              render: (item: any) => (
                <Link
                  to={generateLink(routes.learner, {
                    id: item.id,
                  })}
                >
                  <LmIcon
                    icon="filled-arrows-arrow-right"
                    size={24}
                    color="var(--LM-neutrals-day-700)"
                  />
                </Link>
              ),
              width: '72px',
            },
          ]}
        />
      </Panel>
      <SplitModal
        modal={modal}
        showModal={showModal}
      />
    </>
  );
};

export default LearnersTable;
