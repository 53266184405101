import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { FormGroup } from '@mosru/esz_uikit';
import { LmFilterVertical, LmRadio } from '@mes-ui/lemma';
import FilterLoader from '../../../components/loader-custom/filter-loader';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { historyState } from '../../../mock-data/history-state';
import { EducationTypeEnum } from '../../../types/education-type';
import FormikInput from '../../../components/formik/formik-input';
import FormikSelect from '../../../components/formik/formik-select';
import {
  genderOptions,
  informationOptions,
  statusOptions,
  transformPrivelegiesData,
} from '../../../lib/utils/learners';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { adminAccess, getCountDiff, hasGeneralAccess, replaceHistoryState } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import FormikToggle from '../../../components/formik/formik-toggle';
import { SearchLearnersInitialFormData } from '../../../types/learners';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import lookupApi from '../../../lib/api/lookup';
import { filterEducations } from '../../../lib/utils/education';
import { ServiceField } from './components/fileds/service';
import { VedomstvoField } from './components/fileds/vedomstvo';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { sendReachGoal } from '../../../lib/metrica';
import { SelectOptionType } from '../../../types/entities';

type Props = {
  search: SearchLearnersInitialFormData | undefined;
  submitForm: (values: SearchLearnersInitialFormData) => void;
};

export const searchLearnersInitialFormData: SearchLearnersInitialFormData = {
  query: '',
  statusId: '',
  sexId: '',
  serviceId: null,
  vedomstvoId: null,
  childFirstName: '',
  childLastName: '',
  childMiddleName: '',
  organizationId: null,
  contingentLinkTypeId: '',
  privilegeCategoryId: null,
  hasActivePrivileges: false,
  educationTypeId: EducationTypeEnum.All,
};

const LearnersSearch: React.FC<Props> = ({ search, submitForm }) => {
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const [initialForm, setInitialForm] = useState(searchLearnersInitialFormData);

  const [educationType, setEducationType] = useState<SelectOptionType | undefined>(undefined);
  const [educationTypeData, setEducationTypeData] = useState<SelectOptionType[]>([]);

  const [vedomstvo, setVedomstvo] = useState<SelectOptionType | undefined>(undefined);

  const [organization, setOrganization] = useState<SelectOptionType | undefined>(undefined);

  const [service, setService] = useState<SelectOptionType | undefined>(undefined);

  const [initialValues, setInitialValues] = useState<SearchLearnersInitialFormData>(
    search || window.history.state[historyState.search] || initialForm
  );

  const [isLoadingService, setIsLoadingService] = useState(true);
  const [isLoadingEdType, setIsLoadingEdType] = useState(true);

  const isLoading = isLoadingService || isLoadingEdType;

  const vedomstvoOptions: SelectOptionType[] = useGetDataDepartment();

  const [privelegies, setPrivelegies] = useState<any>([]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdmin = adminAccess('edit-or-view', userProfile);

  const canChangeOrganization: boolean = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const initEdTypes = useMemo(
    () =>
      filterEducations(
        userProfile.objectAccessActionList,
        [],
        undefined,
        accessObject.Pupils,
        accessAction.ViewRegistry
      ),
    [userProfile.objectAccessActionList]
  );

  const getEducationType = (edType: SelectOptionType[]) => {
    const historyEdType = window.history.state[historyState.search]?.educationTypeId;

    return edType.length > 0
      ? historyEdType
        ? edType.find(({ value }) => value === historyEdType) ?? undefined
        : edType[0]
      : undefined;
  };

  useEffect(() => {
    search && setInitialValues(search);
  }, [search]);

  // инициализация search
  useEffect(() => {
    if (!canChangeOrganization) {
      setOrganization({
        label: userProfile.organizationName,
        value: userProfile.organizationId ?? '',
      });
    }

    setEducationTypeData(initEdTypes);
    const currentEducationType = getEducationType(initEdTypes);

    setEducationType(currentEducationType);

    submitForm(
      window.history.state[historyState.search] || {
        ...searchLearnersInitialFormData,
        vedomstvoId: isAdmin ? null : userProfile.vedomstvoId,
        organizationId: canChangeOrganization ? null : userProfile.organizationId,
        educationTypeId: initEdTypes.length > 0 ? currentEducationType?.value : undefined,
      }
    );
  }, [
    canChangeOrganization,
    isAdmin,
    submitForm,
    userProfile.organizationId,
    userProfile.organizationName,
    userProfile.vedomstvoId,
    initEdTypes,
  ]);

  useEffect(() => {
    setVedomstvo(
      isAdmin
        ? vedomstvoOptions.find(({ value }) => value === initialValues.vedomstvoId) ?? undefined
        : {
            label: userProfile.vedomstvoName,
            value: userProfile.vedomstvoId as number,
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, vedomstvoOptions]);

  useEffect(() => {
    const fetchData = async () => {
      const educationTypes = await dictionariesApi.getEducationTypes();

      if (hasGeneralAccess(userProfile, generalAccess.CheckPrivileges)) {
        const privelegiesAll = await dictionariesApi.getPrivilegeCategories();
        const transformPrivelegies = transformPrivelegiesData(privelegiesAll);

        setPrivelegies(transformPrivelegies);
      }

      const edTypes = filterEducations(
        userProfile.objectAccessActionList,
        educationTypes,
        undefined,
        accessObject.Pupils,
        accessAction.ViewRegistry
      );

      setEducationTypeData(edTypes);
      setEducationType(getEducationType(edTypes));
    };

    fetchData().finally(() => {
      setIsLoadingEdType(false);
    });
  }, [userProfile]);

  useEffect(() => {
    setInitialForm((prevState) => ({
      ...prevState,
      educationTypeId: initEdTypes.length > 0 ? initEdTypes[0].value : undefined,
      organizationId: canChangeOrganization ? null : userProfile.organizationId,
      vedomstvoId: isAdmin ? null : userProfile.vedomstvoId,
      serviceId: null,
    }));
  }, [initEdTypes, canChangeOrganization, isAdmin, userProfile.organizationId, userProfile.vedomstvoId]);

  useEffect(() => {
    const fetch = async () => {
      const id = search?.serviceId as number;
      const service = await serviceTemplateApi.getServiceName(id);

      setService({
        label: service,
        value: id,
      });
    };

    if (search?.serviceId && !service?.label) {
      fetch().finally(() => {
        setIsLoadingService(false);
      });
    } else {
      search && setIsLoadingService(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search?.serviceId]);

  useEffect(() => {
    if (search?.organizationName && search?.organizationId && !organization?.label) {
      setOrganization({
        label: search.organizationName,
        value: search.organizationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search?.organizationName, search?.organizationId]);

  // установить департамент по-умолчанию при сбросе формы
  const setDefaultVedomstvo = () => {
    setVedomstvo(
      isAdmin
        ? undefined
        : {
            label: userProfile.vedomstvoName,
            value: userProfile.vedomstvoId as number,
          }
    );
  };

  // установить организацию по-умолчанию при сбросе формы или изменении департамента
  const setDefaultOrganization = () => {
    setOrganization(
      canChangeOrganization
        ? undefined
        : {
            label: userProfile.organizationName,
            value: userProfile.organizationId ?? '',
          }
    );
  };

  // установить вид образования по-умолчанию при сбросе формы
  const setDefaultEducationType = () => {
    setEducationType(initEdTypes.length > 0 ? initEdTypes[0] : undefined);
  };

  // обработчик сброса формы
  const onResetForm = () => {
    setDefaultEducationType();
    setDefaultVedomstvo();
    setDefaultOrganization();
    setService(undefined);
    submitForm(initialForm);
  };

  const onEducationTypeSelect = (value?: SelectOptionType) => {
    setDefaultOrganization();
    setService(undefined);
    setEducationType(value);
  };

  const onVedomstvoSelect = (value?: SelectOptionType) => {
    setDefaultOrganization();
    setService(undefined);
    setVedomstvo(value);
  };

  const onOrganizationSelect = (value?: SelectOptionType) => {
    setService(undefined);
    setOrganization(value);
  };

  const handleClickAdvancedSearch = (isOpen: boolean) => {
    replaceHistoryState({
      [historyState.openAdvanced]: isOpen,
    });
    setOpen(isOpen);
  };

  const handleSearch = (
    values: SearchLearnersInitialFormData,
    formikHelpers: FormikHelpers<SearchLearnersInitialFormData>
  ) => {
    sendReachGoal('onclick-learner-search');

    submitForm({
      ...values,
      vedomstvoId: (vedomstvo?.value as number) ?? null,
      organizationId: (organization?.value as number) ?? null,
      educationTypeId: educationType?.value as number,
      serviceId: (service?.value as number) ?? null,
    });
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSearch}
    >
      {({
        handleSubmit,
        values,
        isSubmitting,
        setFieldValue,
        resetForm,
      }: FormikProps<SearchLearnersInitialFormData>) => {
        const countDiff = getCountDiff(values, {
          ...initialForm,
          organizationName: values.organizationName,
        });

        return (
          <Form onSubmit={handleSubmit}>
            <LmFilterVertical
              dataTest="learnerFilter"
              classNameContent="filter-content"
              open={open}
              searchValue={values.query}
              searchPlaceholder="Поиск по обучающимся..."
              onChange={(value) => setFieldValue('query', value)}
              toggleOpen={handleClickAdvancedSearch}
              onClear={() => {
                resetForm({
                  values: initialForm,
                });
                onResetForm();
              }}
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: isLoading,
                type: 'submit',
              }}
              secondaryButtonModifiers={{
                disabled: isLoading,
              }}
              buttonSecondaryText={countDiff ? `Сбросить фильтры (${countDiff})` : 'Сбросить фильтры'}
            >
              {isLoading ? (
                <FilterLoader />
              ) : (
                <>
                  <FormGroup label="ФИО обучающегося">
                    <div className="filter-content">
                      <FormikInput
                        name="childLastName"
                        placeholder="Фамилия"
                        size="small"
                      />
                      <FormikInput
                        name="childFirstName"
                        placeholder="Имя"
                        size="small"
                      />
                      <FormikInput
                        name="childMiddleName"
                        placeholder="Отчество"
                        size="small"
                      />
                    </div>
                  </FormGroup>
                  <FormikSelect
                    name="educationTypeId"
                    size="small"
                    label="Тип образования"
                    withSearch
                    showTooltip
                    disabledPortalTooltip
                    options={educationTypeData}
                    disabled={educationTypeData.length === 1}
                    defaultValue={educationType}
                    selectedValue={(option?: SelectOptionType) => {
                      setFieldValue('organizationId', null);
                      setFieldValue('serviceId', null);
                      onEducationTypeSelect(option);
                    }}
                    placeholder="Выберите тип образования..."
                  />
                  <VedomstvoField
                    vedomstvo={vedomstvo}
                    setVedomstvo={setVedomstvo}
                    vedomstvoOptions={vedomstvoOptions}
                    onVedomstvoSelect={onVedomstvoSelect}
                  />
                  <FormikSelect
                    name="organizationId"
                    label="Образовательная организация"
                    size="small"
                    withSearch
                    showTooltip
                    disabledPortalTooltip
                    loadOptions={async (query) => await lookupApi.getOrganization(query, vedomstvo?.value)}
                    disabled={!canChangeOrganization}
                    defaultValue={organization}
                    selectedValue={(option?: SelectOptionType) => {
                      setFieldValue('serviceId', null);
                      setFieldValue('organizationName', option?.label);
                      onOrganizationSelect(option);
                    }}
                    options={[]}
                    placeholder="Начните вводить..."
                  />
                  <ServiceField
                    service={service}
                    setService={setService}
                    vedomstvo={vedomstvo?.value as number}
                    educationType={educationType?.value as number}
                    organization={organization?.value as number}
                  />
                  <FormGroup label="Пол">
                    <>
                      {genderOptions.map(({ value, label }) => (
                        <LmRadio
                          key={label}
                          boxSize="large"
                          name={`sexId-${value}`}
                          dataTest={`sexId-${value}`}
                          onChange={() => {
                            setFieldValue('sexId', value);
                          }}
                          checked={values.sexId === value}
                        >
                          {label}
                        </LmRadio>
                      ))}
                    </>
                  </FormGroup>

                  <FormGroup label="Сведения об обучающемся в Контингенте">
                    <>
                      {informationOptions.map(({ value, label }) => (
                        <LmRadio
                          key={label}
                          boxSize="large"
                          name={`contingentLinkTypeId-${value}`}
                          dataTest={`contingentLinkTypeId-${value}`}
                          onChange={() => {
                            setFieldValue('contingentLinkTypeId', value);
                          }}
                          checked={values.contingentLinkTypeId === value}
                        >
                          {label}
                        </LmRadio>
                      ))}
                    </>
                  </FormGroup>
                  <FormGroup label="Статус обучающегося">
                    <>
                      {statusOptions.map(({ value, label }) => (
                        <LmRadio
                          key={label}
                          boxSize="large"
                          name={`statusId-${value}`}
                          dataTest={`statusId-${value}`}
                          onChange={() => {
                            setFieldValue('statusId', value);
                          }}
                          checked={values.statusId === value}
                        >
                          {label}
                        </LmRadio>
                      ))}
                    </>
                  </FormGroup>

                  {hasGeneralAccess(userProfile, generalAccess.CheckPrivileges) && (
                    <>
                      <FormikSelect
                        name="privilegeCategoryId"
                        label="Льготная категория"
                        size="small"
                        withSearch
                        showTooltip
                        disabledPortalTooltip
                        options={privelegies}
                        defaultValue={{
                          label: 'Все',
                          value: null,
                        }}
                        placeholder="Начните вводить наименование льготной категории..."
                      />
                      <FormikToggle
                        label="Отображать обучающихся, имеющих активные льготные категории"
                        name="hasActivePrivileges"
                      />
                    </>
                  )}
                </>
              )}
            </LmFilterVertical>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LearnersSearch;
