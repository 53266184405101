import React, { useContext, useMemo, useState } from 'react';
import { array as arrayYup, number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import SavePanel from '../../../../components/save-panel';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import FormikInput from '../../../../components/formik/formik-input';
import { SelectOptionType } from '../../../../types/entities';
import { ServiceDataInfo } from '../../../../types/service';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { GroupMembersField } from '../../components/fields/group-members';
import { ServiceDuration } from '../../components/fields/service-duration';
import { Dictionary } from '../../components/fields/dictionary';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { altEndValidation, altStartValidation, durationOfTraining, maxNumber } from '../../../../lib/utils/validation';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { ServiceContext } from '../..';
import ParallelField, { ParallelOptionsByResponse } from '../../components/fields/parallel';
import { convertParallelInResponse, convertParallelInSelect, setGenderStartEndToBack } from '../../utils';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import { hasGeneralAccess } from '../../../../lib/utils';
import { generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import BudgetPlacesField from '../components/budget-places-field';
import OrganizationField from '../../components/fields/organization';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const Info: React.FC<Props> = ({ setEditModeParent }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { serviceData, budgetPlaces, accessPanelEdit, updateService, adminEdit } = useContext(ServiceContext);

  const initialData = useMemo(() => {
    if (serviceData.info) {
      return {
        ...serviceData.info,
        parallelList: convertParallelInSelect(serviceData?.info?.parallelList as ParallelOptionsByResponse[]),
      };
    } else {
      return {} as ServiceDataInfo;
    }
  }, [serviceData?.info]);

  const initialErrors = useInitialErrors(serviceData.info, infoValidationSchema());
  const [editMode, setEditMode] = useState(!setEditModeParent);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const checkEditable =
    !editMode && setEditModeParent && serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitForm = async (values: ServiceDataInfo) => {
    setLoadBtn(true);
    if (values?.serviceId) {
      try {
        await serviceTemplateApi.updateService(values.serviceId, {
          ...setGenderStartEndToBack(values),
          parallelList: convertParallelInResponse(values.parallelList as SelectOptionType[]),
        });
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        setLoadBtn(false);
        updateService();
      } finally {
        setLoadBtn(false);
      }
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialData}
      validationSchema={infoValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceDataInfo>) => {
        const { handleSubmit, submitForm, values, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => <>Сведения об образовательной программе</>}
              headingControl={() => {
                return checkEditable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent && setEditModeParent('info');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <OrganizationField
                  name="organization"
                  label={
                    <>
                      Образовательная <br /> организация
                    </>
                  }
                  required
                  editMode={editMode}
                  disabled={
                    !(
                      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
                      hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV)
                    )
                  }
                />

                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Наименование программы {editMode && <span className="table-data__required" />}
                  </div>
                  <div className="table-data__body">
                    <div className="table-data-grid-3-1 items-center">
                      <div>
                        {editMode ? (
                          <FormikInput
                            name="name"
                            size="small"
                            placeholder="Введите..."
                            disabled={hasGeneralAccess(userProfile, generalAccess.AdminView)}
                          />
                        ) : (
                          values.name || '—'
                        )}
                      </div>

                      <div>
                        <div className="table-data__group">
                          <div className="table-data__label">Код</div>
                          <div className="table-data__body">{values.code || '—'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ParallelField
                  name="parallel"
                  editMode={editMode}
                  required
                />

                <ClassificatorNameField
                  name="classificator"
                  label="Профессия"
                  modalTitle="Реестр базовых направлений"
                  disabled
                />

                <BudgetPlacesField budgetPlaces={budgetPlaces} />

                <LessonLevelField
                  isNotTableMain={false}
                  label="Квалификация"
                  editMode={editMode}
                  name="programmLevel"
                />

                <ServiceDuration editMode={editMode} />

                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">Занятия</div>
                  <div className="table-data__body">
                    <div className="flex items-center">
                      <div className="table-data__group">
                        <div className="table-data__label">
                          Форма {editMode && <span className="table-data__required" />}
                        </div>
                        <div
                          className="table-data__body"
                          style={{
                            width: 200,
                          }}
                        >
                          <Dictionary
                            name="serviceForm"
                            editMode={editMode}
                            isNotTableMain
                            dictionaryFunc={dictionariesApi.getServiceForms}
                          />
                        </div>
                      </div>

                      <Push
                        size={16}
                        orientation="horizontal"
                      />

                      <div className="table-data__group">
                        <div className="table-data__label">
                          Вид посещения {editMode && <span className="table-data__required" />}
                        </div>
                        <div
                          className="table-data__body"
                          style={{
                            width: 200,
                          }}
                        >
                          <Dictionary
                            name="typeOfAttendance"
                            editMode={editMode}
                            isNotTableMain
                            dictionaryFunc={() => dictionariesApi.getVisitTypes(true)}
                          />
                        </div>
                      </div>

                      <Push
                        size={16}
                        orientation="horizontal"
                      />
                      <div className="table-data__group">
                        <div className="table-data__label">Часов в неделю</div>
                        <div className="table-data__body">
                          {editMode ? (
                            <FormikInputNumber
                              name="hoursPerWeek"
                              placeholder="0"
                              maxLength={2}
                              max={99}
                              disabled={values.hasMegaRelations}
                            />
                          ) : (
                            values.hoursPerWeek || '—'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <GroupMembersField
                  editMode={editMode}
                  disabled={values.hasMegaRelations && !adminEdit}
                />

                <SimpleTextArea
                  name="programmService"
                  label="Описание программы"
                  editMode={editMode}
                  required
                  placeholder="Введите..."
                  maxLength={1000}
                />
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: loadBtn,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent && setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Info;

export const infoValidationSchema = () =>
  objectYup().shape({
    organizationId: numberYup().nullable().required('Введите образовательную организацию'),
    name: stringYup().required('Введите наименование').nullable(),
    classificatorEKUId: stringYup().nullable().required('Выберите профессию'),
    programmLevelId: stringYup().nullable().required('Выберите квалификацию'),
    hoursPerWeek: maxNumber(99).nullable(),
    altStart: altStartValidation(),
    altEnd: altEndValidation(),
    durationOfTraining: durationOfTraining(9),
    durationOfTrainingMonths: durationOfTraining(12),
    durationOfTrainingWeeks: durationOfTraining(54),
    durationOfTrainingDays: durationOfTraining(54),
    programmService: stringYup().nullable().required('Введите описание программы'),
    parallelList: arrayYup()
      .min(1, 'Выберите параллель обучения')
      .required('Должен состоять из списка параллелей')
      .nullable(),
  });
