export enum DurationOfTrainingUnitEnum {
  // В годах.
  Years = 1,
  // В месяцах
  Month,
  // В неделях.
  Weeks,
  // В днях
  Days,
}
