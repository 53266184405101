import { createGlobalStyle } from 'styled-components';
import './normalize.css';
import { GlobalStyleVars, styleVars } from './vars';
import '@mes-ui/lemma/styles/font.css';
import '@mes-ui/lemma/styles/allVariables.css';
import '@mes-ui/lemma/styles/index.css';

const GlobalStyle = createGlobalStyle`  
  ${GlobalStyleVars}

  
  
  html {
    font-family: ${styleVars.fontFamilyBase};
    font-size: ${styleVars.fontSizeBase};
    font-weight: ${styleVars.fontWeightBase};
    line-height: ${styleVars.lineHeightBase};
    color: ${styleVars.textColor};

    // Reset the box-sizing
    box-sizing: border-box;
    * {
      &, &:before, &:after {
        box-sizing: inherit;
      }
    }

    // Reset outline 
    * {
      outline: none;
    }
    //input {
    //  -webkit-appearance: none;
    //  -moz-appearance: none;
    //  appearance: none;
    //}

    input:autofill {
      background: #fff; /* or any other */
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 0 white !important;
      transition: background-color 5000s ease-in-out 0s;
    }




    // Reset buttons
    button {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      cursor: pointer;
    }

    // Reset svg    
    svg {
      display: block;
    }
  }

  body {
    -webkit-overflow-scrolling: touch;

    // Font smoothing
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyle;
