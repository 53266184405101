import { LmNotifyToastProps } from '@mes-ui/lemma';
import { AnyAction } from 'redux';
import { NotificationItem, NotifyArg } from '../types/entities';

export const moduleName = 'notifications';

export const ADD_NOTIFICATION = `${moduleName}/ADD_NOTIFICATION`;
export const DELETE_NOTIFICATION = `${moduleName}/DELETE_NOTIFICATION`;

// Reducer
export default function reducer(state: NotificationItem[] = [], action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case ADD_NOTIFICATION:
      return [...state, payload.data];

    case DELETE_NOTIFICATION:
      return state.filter(({ id }) => payload.id !== id);

    default:
      return state;
  }
}

// AC
export const addNotification = (data: NotifyArg, type: LmNotifyToastProps['type']) => ({
  type: ADD_NOTIFICATION,
  payload: {
    data: {
      ...data,
      type,
      id: `notification_${Math.random().toString(16).slice(2)}`,
    },
  },
});

export const deleteNotification = (id: string | number) => ({
  type: DELETE_NOTIFICATION,
  payload: {
    id,
  },
});

export const notify = {
  primary: (notifyArgs: NotifyArg) => addNotification(notifyArgs, 'info'),
  success: (notifyArgs: NotifyArg) => addNotification(notifyArgs, 'success'),
  warning: (notifyArgs: NotifyArg) => addNotification(notifyArgs, 'warning'),
  danger: (notifyArgs: NotifyArg) => addNotification(notifyArgs, 'error'),
};
