import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { LmBadgeStatus, LmButton, LmSubHeader } from '@mes-ui/lemma';
import { ISubHeaderTitleButton } from '@mes-ui/lemma/organisms/LmSubHeader/SubHeaderTitleButtons';
import Informer from '../../../components/informer';
import Dialog from '../../../components/modals/dialog';
import Details from './details';
import { getRequestStatusColor } from '../../../lib/utils/dictionary';
import Favorite from '../../../components/favorite';
import { EntityTypeEnum, entityTypesForHistory } from '../../../mock-data/entity-type-enum';
import ChangeHistory from '../../../components/change-history';
import { RequestData } from '../../../types/requests';
import { formatDate, formatDateAndTime } from '../../../lib/utils/date';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { routes } from '../../../config/constants';
import requestsApi from '../../../lib/api/requests';
import { generateLink, goBack, hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { RequestContext } from '../../../lib/utils/requests';

type RequestDayCareProps = {
  requestData: RequestData;
  updateRequest: () => void;
  setRequestErrorMessage: Dispatch<SetStateAction<string>>;
  requestErrorMessage: string;
};

const RequestDayCare = ({
  requestData,
  updateRequest,
  setRequestErrorMessage,
  requestErrorMessage,
}: RequestDayCareProps) => {
  const [showModal, setShowModal] = useState(false);

  const { adminView, routeBack } = useContext(RequestContext);

  const [editMode, setEditMode] = useState<string | null>(null);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const roleAccess =
    hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
    hasGeneralAccess(userProfile, generalAccess.AdminView) ||
    (hasAccessObjectAny(userProfile, [accessObject.RequestDayCare], accessAction.Create) &&
      !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV));

  const checkOnRequestStatusId =
    roleAccess &&
    (requestData.requestStatusId === RequestStatusEnum.New ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.Archive ||
      requestData.requestStatusId === RequestStatusEnum.Draft ||
      requestData.requestStatusId === RequestStatusEnum.Excluded ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded);

  // Добавить проверку: организация ученика, указанная в реестре контингента, не соответствует организации пользователя
  const checkisCopyRequestAllowed = requestData?.isCopyRequestAllowed;

  const checkShowCreateFromCurrent = checkOnRequestStatusId && checkisCopyRequestAllowed && !adminView;

  const addRequestInArchive = async () => {
    await requestsApi.setRequestStatus(
      requestData?.id,
      requestData?.educationTypeId,
      requestData?.requestStatusId,
      RequestStatusEnum.Archive
    );

    updateRequest();
  };

  const checkAddArchiveButton =
    hasAccessObjectAny(userProfile, [accessObject.RequestDayCare], accessAction.Archive) &&
    (requestData.requestStatusId === RequestStatusEnum.Draft || requestData.requestStatusId === RequestStatusEnum.New);

  const submitRegistration = async () => {
    try {
      await requestsApi.setRequestStatus(
        requestData.id,
        requestData.educationTypeId,
        requestData.requestStatusId,
        RequestStatusEnum.UnderConsideration
      );

      updateRequest();
      setShowModal(false);
    } catch (error) {
      const textError = error?.data?.messages.length > 1 ? error?.data?.messages?.join(' ;') : error?.data?.messages[0];

      if (textError) {
        setRequestErrorMessage(textError);
        updateRequest();
      }

      setShowModal(false);
    }
  };

  const showNumberIcon =
    requestData.requestStatusId === RequestStatusEnum.New ||
    requestData.requestStatusId === RequestStatusEnum.Archive ||
    requestData.requestStatusId === RequestStatusEnum.Draft;

  const handleConsider = async () => {
    await requestsApi.setRequestStatus(
      requestData.id,
      requestData.educationTypeId,
      requestData.requestStatusId,
      RequestStatusEnum.ForInclude
    );
    updateRequest();
  };

  const handleCreate = () => {
    window.open(
      generateLink(routes.createRequestBasedOn, {
        id: requestData.id,
      }),
      '_blank'
    );
  };

  const getButtons = (): ISubHeaderTitleButton[] => {
    const buttons: ISubHeaderTitleButton[] = [];

    if (checkShowCreateFromCurrent) {
      buttons.push({
        dataTest: 'createFromCurrent',
        buttonText: 'Создать на основе текущего',
        rounded: 'small',
        variant: 'secondary',
        onClick: handleCreate,
        icon: 'filled-edit-plus',
      });
    }
    if (checkAddArchiveButton) {
      buttons.push({
        dataTest: 'toArchive',
        buttonText: 'В архив',
        rounded: 'small',
        variant: 'secondary',
        onClick: addRequestInArchive,
        icon: 'filled-hardware-hard-drive',
      });
    }

    return buttons;
  };

  return (
    <LmSubHeader
      sticky
      description={
        <>
          <Push size={12} />
          <div className="flex items-center">
            Дата заявления: {formatDate(requestData.dateRequest)}
            <Push
              size={20}
              orientation="horizontal"
            />
            Источник: {requestData.requestSourceName}
          </div>
          <Push size={12} />
        </>
      }
      arrowOnClick={() => goBack(routeBack)}
      titleChildren={
        <div className="flex items-center">
          <Favorite
            entityId={requestData.id}
            typeEntity="заявление"
            currentEntityType={`${!showNumberIcon ? '№' : ''}${requestData.requestNumber || ''}`}
            entityTypeId={EntityTypeEnum.Request}
          />
          <Push
            size={20}
            orientation="horizontal"
          />
          <LmBadgeStatus
            dataTest="requestStatus"
            {...getRequestStatusColor(requestData.requestStatusId)}
          >
            {requestData.requestStatusName}
          </LmBadgeStatus>
        </div>
      }
      dataTest="requestDayCareSubHeader"
      title={`Заявление ${!showNumberIcon ? '№' : ''}${requestData.requestNumber || ''}`}
      routes={[
        {
          label: 'Главная',
          link: routes.main,
        },
        {
          label: 'Заявления',
          link: routes.requests,
        },
        {
          label: 'Сведения о заявлении',
        },
      ]}
      buttonsOrAnyActions={getButtons()}
      tabs={[
        {
          title: 'Основные сведения',
          value: 1,
          dataTest: 'basicInfo',
        },
        {
          title: 'История изменений',
          value: 2,
          dataTest: 'changeHistory',
        },
      ]}
      className="content-panel"
      panels={[
        {
          children: (
            <>
              {requestData.requestStatusId === RequestStatusEnum.ForInclude && !editMode && (
                <>
                  <Push size={12} />
                  <Informer
                    dataTest="enrollmentDanger"
                    color="danger"
                    title="Внимание!"
                    description={
                      <>
                        Зачисление / отчисление по текущему заявлению должно быть произведено до{' '}
                        <span className="informer__text--bold">{formatDateAndTime(requestData.actionDeadline)}</span>. В
                        противном случае заявление станет просроченным.
                      </>
                    }
                  />
                </>
              )}

              {requestData.requestStatusId === RequestStatusEnum.ForInclude && !editMode && (
                <>
                  <Push size={12} />
                  <Informer
                    dataTest="dateForContractWarning"
                    color="warning"
                    description={
                      <>
                        Дата явки заявителя для заключения договора:{' '}
                        <span className="informer__text--bold">{formatDate(requestData.actionDeadline)}</span>
                      </>
                    }
                  />
                </>
              )}

              {requestData.requestStatusId === RequestStatusEnum.UnderConsideration && !editMode && (
                <>
                  <Push size={12} />
                  <Informer
                    dataTest="deadlineDanger"
                    color="danger"
                    title={`Срок обработки заявления истекает ${formatDateAndTime(requestData.actionDeadline)}`}
                    description="Необходимо перевести текущее заявление в статус “Ожидание прихода заявителя для заключения
                        договора”. Для этого нажмите кнопку “Рассмотрено”."
                    controls={
                      <LmButton
                        dataTest="reviewed"
                        type="button"
                        color="danger"
                        onClick={handleConsider}
                      >
                        Рассмотрено
                      </LmButton>
                    }
                  />
                </>
              )}

              {requestData.requestStatusId === RequestStatusEnum.New && !editMode && (
                <>
                  <Push size={12} />
                  <Informer
                    dataTest="registerDanger"
                    color="danger"
                    title="Зарегистрируйте заявление!"
                    description={
                      <>
                        Автоматическая архивация текущего заявления будет произведена{' '}
                        <span className="informer__text--bold">{formatDateAndTime(requestData.actionDeadline)}</span>
                      </>
                    }
                    controls={
                      <LmButton
                        dataTest="register"
                        type="button"
                        color="danger"
                        disabled={Boolean(requestData?.dayCareRegisterRequestMessage)}
                        onClick={() => setShowModal(true)}
                      >
                        Зарегистрировать
                      </LmButton>
                    }
                  />
                  <Dialog
                    dataTest="applicationRegistration"
                    isOpenDialog={showModal}
                    title="Регистрация заявления"
                    description="Хотите зарегистрировать заявление?"
                    variant="question"
                    buttonPrimaryText="Зарегистрировать"
                    onClickPrimaryButton={submitRegistration}
                    onClickSeconadaryButton={() => setShowModal(false)}
                  />
                </>
              )}

              <Details
                data={requestData}
                updateRequest={updateRequest}
                requestErrorMessage={requestErrorMessage}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            </>
          ),
          value: 1,
        },
        {
          children: (
            <ChangeHistory
              name="сведения о заявлении"
              extendEntityGuid={requestData?.extendEntityGuid}
              entityTypes={entityTypesForHistory.requests}
            />
          ),
          value: 2,
          className: 'content-panel content-panel--auto',
          noPadding: true,
        },
      ]}
    />
  );
};

export default RequestDayCare;
