import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import Informer from '../../../components/informer';
import { ContractStatusEnum } from '../../../mock-data/contract-status-enum';
import { RequestSourceEnum } from '../../../mock-data/request-source-enum';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import requestsApi from '../../../lib/api/requests';
import { EducationTypeEnum } from '../../../types/education-type';
import { formatDate } from '../../../lib/utils/date';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
  isAdmin: boolean;
};

const Status: React.FC<Props> = ({ requestData, updateRequest, isAdmin }) => {
  const isVisibleRegisterVisit = requestData.isNewService
    ? (requestData.requestStatusId === RequestStatusEnum.New ||
        requestData.requestStatusId === RequestStatusEnum.WaitingDocumentVerification ||
        requestData.requestStatusId === RequestStatusEnum.WaitingSendingDocuments) &&
      isAdmin
    : requestData.requestStatusId === RequestStatusEnum.New;

  const isVisibleDocumentsSubmitted =
    (requestData.educationTypeId === EducationTypeEnum.ChildrenEducation ||
      requestData.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation) &&
    (requestData.isNewService
      ? requestData.requestSourceId !== RequestSourceEnum.Mpgu ||
        requestData.requestStatusId === RequestStatusEnum.ForInclude
      : (requestData.requestStatusId === RequestStatusEnum.WaitForElectronicAccept &&
          requestData.contract?.contractStatusId !== ContractStatusEnum.SigningRequiredByOrganization) ||
        requestData.requestStatusId === RequestStatusEnum.ForInclude);

  const isVisibleDocSubmittedBtn =
    requestData.educationTypeId !== EducationTypeEnum.ChildrenNonDogmEducation &&
    (!requestData.isNewService || requestData.requestSourceId !== RequestSourceEnum.Mpgu);

  const registerVisit = async () => {
    try {
      await requestsApi.setRequestStatus(
        requestData.id,
        requestData.educationTypeId,
        requestData.requestStatusId,
        RequestStatusEnum.ForInclude
      );
    } finally {
      updateRequest();
    }
  };

  const docsSubmitted = async () => {
    try {
      await requestsApi.setRequestStatus(
        requestData.id,
        requestData.educationTypeId,
        requestData.requestStatusId,
        RequestStatusEnum.DocumentAccepted
      );
    } finally {
      updateRequest();
    }
  };

  return (
    <>
      {isVisibleRegisterVisit && (
        <>
          <Push size={12} />
          <Informer
            dataTest="enrollmentDanger"
            color="danger"
            description="Для перевода заявления в статус “Ожидание прихода заявителя для заключения договора” нажмите кнопку “Зарегистрировать личный визит”"
            controls={
              <LmButton
                dataTest="registerVisit"
                type="button"
                color="danger"
                onClick={registerVisit}
              >
                Зарегистрировать личный визит
              </LmButton>
            }
          />
        </>
      )}

      {isVisibleDocumentsSubmitted && (
        <>
          <Push size={12} />
          <Informer
            dataTest="documentsWarning"
            color="warning"
            description={
              requestData.requestStatusId === RequestStatusEnum.WaitForElectronicAccept ? (
                'Для перевода заявления в статус “Поданы документы” нажмите кнопку “Поданы документы”'
              ) : (
                <>
                  Дата явки заявителя для заключения договора:{' '}
                  {requestData?.actionDeadline ? (
                    <span className="informer__text--bold">{formatDate(requestData.actionDeadline)}</span>
                  ) : (
                    '—'
                  )}
                </>
              )
            }
            controls={
              isVisibleDocSubmittedBtn ? (
                <LmButton
                  dataTest="documentsSubmitted"
                  type="button"
                  color="warning"
                  onClick={docsSubmitted}
                >
                  Поданы документы
                </LmButton>
              ) : undefined
            }
          />
        </>
      )}
    </>
  );
};

export default Status;
