import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { useSelector } from 'react-redux';
import { ReactComponent as IconLogin } from '../../assets/images/login/logo.svg';
import { aupdApiUrl } from '../../config/constants';
import Img from '../../assets/images/errors/access.svg';
import { profilesSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';

const LoginLayoutError = () => {
  const { profiles } = useSelector((state: AppState) => ({
    profiles: profilesSelector(state),
  }));

  return (
    <div className="login-layout">
      <div className="login-layout__header">
        <IconLogin />
      </div>
      <div className="login-layout__body">
        <div className="error-container">
          <Push size={40} />
          <div className="error-heading">Упс!</div>
          <Push size={8} />
          <div className="error-lead">Не удалось произвести авторизацию пользователя.</div>
          <Push size={16} />
          {profiles?.length ? (
            <>
              Выберите другую организацию или <a href={`${aupdApiUrl}/choice`}>вернитесь в личный кабинет МЭШ</a>
            </>
          ) : (
            <>
              <a href={`${aupdApiUrl}/choice`}>Вернитесь в личный кабинет МЭШ</a> или попробуйте позднее.
            </>
          )}
          <Push size={34} />
          <img
            src={Img}
            alt=""
            className="error-img"
          />
        </div>
      </div>
      <div className="login-layout__footer">
        <div className="login-layout-footer-nav">
          <div className="login-layout-footer-nav__item">
            <a href="tel:+74955393838">+7 (495) 539-38-38</a>
          </div>
          <div className="login-layout-footer-nav__item">
            <a href="mailto:dop_hd@mos.ru">dop_hd@mos.ru</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayoutError;
