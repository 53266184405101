import React, { useEffect, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmTextArea } from '@mes-ui/lemma';
import { useAddFavoriteMutation, useGetFavoriteQuery } from '../../store/favorites';
import { Favorite } from '../../types/favorite';
import Popup from '../modals/popup';

type Props = {
  show: boolean;
  title: string;
  entityId: number;
  entityTypeId: number;
  typeEntity?: string;
  resetComment: number | undefined;
  setId: (value: number | undefined) => void;
  setEntityId: (value: number) => void;
  setCommentTooltip: (value: string) => void;
  onCloseHandler: () => void;
  currentEntityType: string;
};

const ModalFavorite = ({
  setId,
  show,
  typeEntity,
  resetComment,
  onCloseHandler,
  title,
  setEntityId,
  entityId,
  entityTypeId,
  setCommentTooltip,
  currentEntityType,
}: Props) => {
  const [comment, setComment] = useState<string>('');
  const { data } = useGetFavoriteQuery({
    entityId,
    entityTypeId,
  });
  const [addFavorite] = useAddFavoriteMutation();

  useEffect(() => {
    if (data) {
      setId(data.data.id);
      setComment(data.data.comment);
      setCommentTooltip(data.data.comment);
      setEntityId(data.data.entityId);
    }
  }, [data, setCommentTooltip, setEntityId, setId]);

  useEffect(() => {
    if (!resetComment) {
      setComment('');
    }
  }, [resetComment]);

  const save = () => {
    if (comment.trim() === '') {
      return;
    }

    const fetch = async () => {
      const data = {
        comment,
        entityId,
        entityTypeId,
      };

      addFavorite(data as Favorite);
    };

    onCloseHandler();
    fetch();
  };

  return (
    <Popup
      dataTest="addFavoritePopup"
      open={show}
      title={title}
      primaryButtonModifiers={{
        disabled: !comment,
      }}
      buttonPrimaryText="Добавить"
      onClose={onCloseHandler}
      onSubmit={save}
    >
      <div>
        Вы собираетесь добавить {typeEntity} {currentEntityType} в Избранные записи.
      </div>
      <Push
        orientation="vertical"
        size={25}
      />
      <div>Управлять избранными записями вы сможете из раздела "Избранное" в главном меню.</div>

      <Push
        orientation="vertical"
        size={25}
      />

      <LmTextArea
        name="comment"
        dataTest="comment"
        value={comment}
        widthFull
        placeholder="Введите..."
        minRows={3}
        maxRows={3}
        onChange={(value) => setComment(value as string)}
      />
    </Popup>
  );
};

export default ModalFavorite;
