import React from 'react';
import { useField } from 'formik';
import { LmTextArea, LmTextAreaProps } from '@mes-ui/lemma';

export type FormikTextareaProps = Omit<LmTextAreaProps, 'name' | 'onChange' | 'value'> & {
  name: string;
  value?: React.ReactText;
  showErrorImmediately?: boolean;
};

const FormikTextarea: React.FC<FormikTextareaProps> = ({
  name,
  value: valueProp,
  dataTest,
  showErrorImmediately,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const value = valueProp || field.value || '';

  const error = showErrorImmediately ? meta.error : (meta.touched && meta.error) || '';

  const handleChange = (
    value: React.ReactText,
    name?: string | null,
    event?: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    field.onChange(event);
    helpers.setTouched(true, true);
    helpers.setValue(value);
  };

  return (
    <LmTextArea
      {...field}
      {...props}
      labelPosition="fix"
      widthFull
      minRows={props.minRows || props.maxRows}
      rows={3}
      value={value}
      dataTest={dataTest || name}
      isError={props.isError || !!error}
      explainText={error || props.explainText}
      onChange={handleChange}
    />
  );
};

export default FormikTextarea;
