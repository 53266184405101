import React from 'react';
import { useSelector } from 'react-redux';
import { userProfileSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';
import GlobalFooter from '../global-footer';
import Footer from './index';

const FooterWrapper = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  return userProfile.tokenAupd ? <GlobalFooter /> : <Footer />;
};

export default FooterWrapper;
