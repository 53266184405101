import React from 'react';
import { LmIcon, LmText, LmTooltip } from '@mes-ui/lemma';
import { Push } from '@mosru/esz_uikit';
import FormikInput from '../../../../components/formik/formik-input';
import { priceMask } from '../../../../lib/utils/mask';
import { Finance } from './finansing';

type Props = {
  editMode: boolean;
  values: Finance;
  parent?: string;
};

export const ERROR_MESSAGE = 'Стоимость за занятие не должна превышать стоимость за месяц';

const FormFinancing = ({ editMode, values, parent }: Props) => {
  const hasError =
    editMode &&
    !!parseFloat(String(values.periodPrice)) &&
    !!parseFloat(String(values.lessonPrice)) &&
    parseFloat(String(values.periodPrice)) < parseFloat(String(values.lessonPrice));

  return (
    <div className="container">
      <div className="table-data__item table-data__group">
        <div className="table-data__label table-data__label--main">
          Тип финансирования {editMode && <span className="table-data__required" />}
        </div>
        <div className="table-data__body">Платно</div>
      </div>
      <div className="table-data__item table-data__group">
        <div className="table-data__label table-data__label--main">
          Стоимость, руб
          {editMode && (
            <>
              <span className="table-data__required" />
              <Push
                size={4}
                orientation="horizontal"
              />
              <LmTooltip
                withArrow
                placement="top"
                content={ERROR_MESSAGE}
                width="247px"
              >
                <LmIcon
                  icon="outline-notifications-info"
                  size={20}
                  color="var(--LM-neutrals-day-500)"
                />
              </LmTooltip>
            </>
          )}
        </div>
        <div className="table-data__body">
          <div className="flex items-start">
            <div className="table-data-grid-3 flex-auto">
              <div className="table-data__group">
                <div className="table-data__label">За месяц</div>
                <div className="table-data__body">
                  {editMode ? (
                    <FormikInput
                      name={parent ? `${parent}.periodPrice` : 'periodPrice'}
                      size="small"
                      placeholder="0,00"
                      textRight
                      rightPrefix="₽"
                      maxLength={6}
                      maskRegex={priceMask}
                      showErrorImmediately={hasError}
                    />
                  ) : (
                    values.periodPrice || '—'
                  )}
                </div>
              </div>
              <div className="table-data__group">
                <div className="table-data__label">За занятие</div>
                <div className="table-data__body">
                  {editMode ? (
                    <FormikInput
                      textRight
                      rightPrefix="₽"
                      size="small"
                      name={parent ? `${parent}.lessonPrice` : 'lessonPrice'}
                      placeholder="0,00"
                      maskRegex={priceMask}
                      showErrorImmediately={hasError}
                    />
                  ) : (
                    values.lessonPrice || '—'
                  )}
                </div>
              </div>
              {hasError && (
                <div className="table-data__group">
                  <LmText
                    variant="md"
                    color="error"
                  >
                    {ERROR_MESSAGE}
                  </LmText>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormFinancing;
