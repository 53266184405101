import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useGetScrollElement } from '../../hooks/use-get-scroll-element';

type InfiniteScrollProps<T> = {
  data: T[];
  renderItem: (item: T) => ReactNode;
  pageSize?: number;
};

const DEFAULT_SIZE = 10;
const OFFSET_SCROLL = 20;

const InfiniteScroll = <T extends unknown>({ data, renderItem, pageSize = DEFAULT_SIZE }: InfiniteScrollProps<T>) => {
  const [list, setList] = useState<T[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const scrollElem = useGetScrollElement();

  const handleScroll = useCallback(() => {
    if (
      scrollElem &&
      scrollElem.clientHeight + scrollElem.scrollTop >= scrollElem.scrollHeight - OFFSET_SCROLL &&
      list.length < data.length
    ) {
      setPageNumber((prevState) => prevState + 1);
    }
  }, [data.length, list.length, scrollElem]);

  useEffect(() => {
    // Запуск handleScroll до скроллинга
    if (list.length === pageSize && !pageNumber) {
      handleScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, pageNumber]);

  useEffect(() => {
    if (pageNumber) {
      setPageNumber(0);
    } else {
      setList(data.slice(0, pageSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageSize]);

  useEffect(() => {
    setList((prevState) =>
      (pageNumber ? prevState : []).concat(data.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    scrollElem?.addEventListener('scroll', handleScroll);

    return () => {
      scrollElem?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, scrollElem]);

  return <>{list.map(renderItem)}</>;
};

export default InfiniteScroll;
