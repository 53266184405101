import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { debugMode, routes } from '../../config/constants';
import { AppState } from '../../redux/types/state';
import { isAuthorizedSelector, userProfileSelector } from '../../redux/selectors';
import { redirectToLogin } from '../../lib/utils';
import history from '../../history';
import { AuthorizationData } from '../../types/authorization-data';

type Props = RouteProps & {
  checkAccess?: (userProfile: AuthorizationData) => boolean;
};

const ProtectedRoute = (props: Props) => {
  const { checkAccess } = props;
  const { isAuthorized, userProfile } = useSelector((state: AppState) => ({
    isAuthorized: isAuthorizedSelector(state),
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    const fetch = async () => {
      const { logout } = queryString.parse(window.location.search);

      // logout нужен для фиксации процесса выхода из профия.
      // Если его не использовать, то возникается ситуация с редиректом на страницу логинки, а затем уже редиректом на api sso
      if (!isAuthorized && logout !== 'true') {
        // eslint-disable-next-line no-console
        debugMode && console.warn('redirected to login from src/containers/routes/protected.tsx');
        // Здесь используем replace, чтобы решить проблему перехода по кнопке "Назад" на странице логина.
        await redirectToLogin({
          redirectUrl: window.location.pathname,
          replace: true,
        });
      }

      // Проверка прав пользователя
      if (checkAccess && !checkAccess(userProfile)) {
        history.push(routes.accessDeny);
      }
    };

    fetch();
  }, [isAuthorized, userProfile, checkAccess]);

  return isAuthorized ? <Route {...props} /> : null;
};

export default ProtectedRoute;
