import React from 'react';
import SimpleInput, { SimpleInputProps } from '../../../../components/fields/simple-input';

const Issued: React.FC<SimpleInputProps> = (props) => {
  return (
    <SimpleInput
      label="Кем выдан"
      {...props}
    />
  );
};

export default Issued;
