import React, { useContext, useState } from 'react';
import Info from './panels/info';
import Photo from '../components/panels/photo';
import ContactPerson from '../components/panels/contact-person';
import Period from '../child/panels/period';
import Financing from '../components/panels/financing';
import { ServiceContext } from '../index';
import PublicationStatus from '../components/publication-status';
import PlanTemplate from '../components/panels/plan';

const DigitalTutorDetails: React.FC = () => {
  const { serviceData, accessPanelEdit } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState<string | null>(null);

  return (
    <>
      {accessPanelEdit && (
        <PublicationStatus
          editMode={editMode}
          educationType={serviceData.educationTypeId}
          serviceStatusId={serviceData.serviceStatusId}
        />
      )}

      {(!editMode || editMode === 'info') && <Info setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'contact') && <ContactPerson setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'period') && <Period setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'financing') && <Financing setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'plan') && <PlanTemplate editable />}
      {(!editMode || editMode === 'photo') && <Photo setEditModeParent={setEditMode} />}
    </>
  );
};

export default DigitalTutorDetails;
