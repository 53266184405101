export const helpPanels = [
  {
    title: 'Изменение алгоритма регистрации заявлений',
    shortDescription:
      'В соответствии с Правилами подачи заявления и зачисления детей в государственные учреждения города Москвы на обучение по дополнительным общеобразовательным программам и для предоставления услуг по программам спортивной подготовки и проведению занятий по ...',
    fullDescription:
      '<span>В соответствии с Правилами подачи заявления и зачисления детей в государственные учреждения города Москвы на обучение по дополнительным общеобразовательным программам и для предоставления услуг по программам спортивной подготовки и проведению занятий по физической культуре и спорту изменен алгоритм регистрации заявлений:</span>' +
      '<ol>1. Для учреждений дополнительного образования департамента культуры:<br/>' +
      'На первый год обучения возможность зарегистрировать заявление в личном кабинете оператора организации не доступна, все заявления поступают только через портал Mos.ru;<br/>' +
      'На второй и последующие года обучения возможность зарегистрировать заявление доступна в личном кабинете оператора.' +
      '<br/><br/>2. Для учреждений департамента спорта и туризма:<br/>' +
      'На первый год обучения до 01 октября текущего года возможность зарегистрировать заявление в личном кабинете оператора организации не доступна, все заявления поступают только через портал Mos.ru;<br/>' +
      'На первый год обучения с 1 октября до окончания срока приема заявлений возможность зарегистрировать заявление доступна в личном кабинете оператора;<br/>' +
      'На второй и последующие года обучения возможность зарегистрировать заявление доступна в личном кабинете оператора.' +
      '<br/><br/>3. Для организаций дополнительного образования Департамента образования:<br/>' +
      'На первый год обучения возможность зарегистрировать заявление в личном кабинете оператора организации не доступна, все заявления поступают только через портал Mos.ru;<br/>' +
      'На второй и последующие года обучения возможность зарегистрировать заявление доступна в личном кабинете оператора.' +
      '</ol>' +
      'Для остальных организаций (общеобразовательные организации, организации среднего профессионального образования и т.д.) возможность зарегистрировать заявление в личном кабинете оператора организации доступна всегда.',
  },
  {
    title: 'Вопросы по доступу в ЕСЗ',
    shortDescription:
      'В случае, если Система оповещает Вас об истечении срока действия Вашего личного пароля и выдает запрос на его замену, Вам необходимо выполнить следующие действия…',
    fullDescription:
      '<span>В случае, если Система оповещает Вас об истечении срока действия Вашего личного пароля и выдает запрос на его замену, Вам необходимо выполнить следующие действия:</span>' +
      '<ol>1. В поле «Логин» вписать Ваш существующий логин.' +
      '<br/>2. В поле «Пароль» вписать действующий (старый) пароль.' +
      '<br/>3. Нажать «Войти в систему». ' +
      '<br/>4. На форме «Создание пароля» в поле «Новый пароль» вписать Ваш вновь придуманный личный пароль, который должен соответствовать следующим критериям: пароль должен содержать не менее 6 символов, состоять из букв латинского алфавита и цифр и должен обязательно содержать не менее одной буквы в нижнем регистре и не менее одной буквы в верхнем регистре, и может (не обязательно) содержать спец. символы и/или цифры.' +
      '<br/>5. В поле «Подтверждение нового пароля» повторить ввод нового пароля.' +
      '<br/>6. Далее нажать кнопку «Сохранить и войти в систему»</ol>' +
      '<svg style="transform: translate(-2px, 4px); display: inline" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.97016 15.75C5.72816 15.75 4.54766 15.2505 3.64616 14.3438C1.85516 12.5415 1.78166 9.684 3.48191 7.97325L9.02141 2.40075C9.59816 1.82025 10.3767 1.5 11.2129 1.5C12.1077 1.5 12.9582 1.85925 13.6069 2.51175C14.8977 3.81 14.9472 5.87325 13.7164 7.11075L8.17016 12.6825C7.81091 13.0448 7.32716 13.2435 6.80816 13.2435C6.25991 13.2435 5.74016 13.0245 5.34566 12.6278C4.55591 11.832 4.53116 10.5637 5.29091 9.79875L10.4097 4.6575C10.7022 4.3635 11.1762 4.362 11.4702 4.6545C11.7634 4.947 11.7649 5.42175 11.4724 5.715L6.35441 10.857C6.17441 11.0385 6.19916 11.3588 6.40991 11.5702C6.51941 11.6805 6.66491 11.7435 6.80816 11.7435C6.89066 11.7435 7.00916 11.7233 7.10666 11.625L12.6529 6.05325C13.3032 5.3985 13.2544 4.28475 12.5434 3.56925C11.8632 2.88525 10.7089 2.83125 10.0849 3.45825L4.54541 9.03075C3.42491 10.158 3.49841 12.0675 4.71041 13.2863C5.32766 13.908 6.13016 14.25 6.97016 14.25C7.72091 14.25 8.41691 13.9665 8.92841 13.452L14.4687 7.8795C14.7604 7.58625 15.2352 7.584 15.5292 7.8765C15.8224 8.169 15.8239 8.643 15.5322 8.937L9.99191 14.5095C9.19691 15.309 8.12366 15.75 6.97016 15.75Z" fill="#E0EDFF"/>' +
      '</svg><a href="https://esz.mos.ru/Files/voprosy_po_dostypy_v_ESZ.docx">Памятка по вопросам изменения пароля</a>',
  },
  {
    title: 'Получение электронной подписи',
    shortDescription:
      'Для получения электронной подписи подведомственным организациям Департамента культуры необходимо обращаться в отдел информатизации отрасли ГКУ "Технический центр Департамента культуры города Москвы".',
  },
];
