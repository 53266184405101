import React, { useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import Popup from '../../../../components/modals/popup';
import serviceClassApi from '../../../../lib/api/service-class';
import { ServiceClassContext } from '../../service-class';
import { ServiceClassTableContext } from '../../utils';

const initialValues = {
  comment: '',
};

type Props = {
  open: boolean;
  close: () => void;
};

type FormData = {
  comment: string;
  educationTypeId: number;
  serviceClassName: string;
  megaRelationIds: number[];
  serviceClassId: number;
};

const RecoverySimple: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const { updateServiceClass } = useContext(ServiceClassContext);

  const submitForm = async (values: FormData) => {
    if (serviceClassData.id) {
      await serviceClassApi.restorationServiceClass(serviceClassData.id, {
        ...values,
        educationTypeId: serviceClassData.educationTypeId,
        serviceClassName: serviceClassData.name,
        megaRelationIds: selected,
        serviceClassId: serviceClassData.id,
      });

      fetchTable();
      setOpenDetails(false);
      setSelected([]);
      close();
      updateServiceClass();
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
      initialValues={initialValues as FormData}
    >
      {(formikProps: FormikProps<FormData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Popup
              dataTest="recoveryPopup"
              open={open}
              title="Восстановление обучающегося"
              buttonPrimaryText="Восстановить"
              primaryButtonModifiers={{
                loading: isSubmitting,
                disabled: !isValid,
              }}
              onClearData={resetForm}
              onSubmit={handleSubmit}
              onClose={close}
            >
              <FormikTextarea
                label="Причина восстановления"
                name="comment"
                placeholder="Введите..."
                maxRows={2}
              />
            </Popup>
          </form>
        );
      }}
    </Formik>
  );
};

export default RecoverySimple;
