import { AppState } from './types/state';
import { moduleName as appModule } from './ducks/app';
import { moduleName as userProfile } from './ducks/user-profile';
import { moduleName as systemSettings } from './ducks/system-settings';
import { moduleName as aupdOrganizations } from './ducks/aupd-organizations';
import { moduleName as notifications } from './ducks/notifications';
import { moduleName as booking } from './ducks/booking';

export const isAuthorizedSelector = (state: AppState) => state[appModule].authorized;

export const currentProfileSelector = (state: AppState) => state[appModule].currentProfile;

export const profilesSelector = (state: AppState) => state[appModule].profiles;
/** Дает доступ к профилю пользователя в хранилище состояния */
export const userProfileSelector = (state: AppState) => state[userProfile];

export const userSettingsSelector = (state: AppState) => state[systemSettings];

export const aupdOrganizationsSelector = (state: AppState) => state[aupdOrganizations];

export const notificationsSelector = (state: AppState) => state[notifications];

export const bookingSelector = (state: AppState) => state[booking].booking;
