import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../../config/constants';
import ServiceChildCreateDetails from './details';
import { getHeaderLink } from '../../utils';
import { CreateServiceContext } from '../../create';

const ServiceChildCreate = () => {
  const history = useHistory();
  const { serviceData, educationTypes, type } = useContext(CreateServiceContext);

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))}
        title="Новое детское объединение"
        dataTest="serviceChildCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Детские объединения',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          {
            label: 'Описание детского объединения',
          },
        ]}
      />
      <ServiceChildCreateDetails />
    </>
  );
};

export default ServiceChildCreate;
