import React from 'react';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmButton, LmListItem } from '@mes-ui/lemma';
import DropDown from '../../../../components/drop-down';
import TableOptions from '../../../../components/table-options';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import { Learner } from '../../../../types/learners';
import { fieldValidation } from './helpers';
import learnerApi from '../../../../lib/api/learner';

const Edit: React.FC<{
  id: number;
  editMode: boolean;
  editObj: Learner.TransformRepresentative;
  submitHandler: () => void;
  checkAllValidate: boolean;
  pupilId: number;
  removeRepresentative: (val: number) => void;
  setEditMode: (v: boolean) => void;
  setRepresentative: (v: boolean) => void;
  setBlockAddBtn: (value: boolean) => void;
  typeRepresentative: SelectOptionType | undefined;
  setValidate: (obj: Learner.ValidateRepresentatives) => void;
  newRepresentative?: boolean;
  updateLearn: (value: boolean) => void;
}> = ({
  setEditMode,
  editObj,
  editMode,
  submitHandler,
  setRepresentative,
  checkAllValidate,
  removeRepresentative,
  id,
  typeRepresentative,
  setValidate,
  pupilId,
  setBlockAddBtn,
  updateLearn,
}) => {
  const send = async () => {
    if (editMode) {
      if (editObj && editObj.id) {
        const { id, email, phone, name, typeRepresentationId } = editObj;

        await learnerApi.putRepresentative(editObj.id, {
          id,
          email,
          phone: addPhonePrefix(phone),
          lastName: name.lastName,
          firstName: name.firstName,
          middleName: name.middleName,
          typeRepresentationId: typeRepresentationId || (typeRepresentative?.value as number) || 0,
          pupilId,
        });
      } else {
        submitHandler();
      }
    }

    setEditMode(false);
    setBlockAddBtn(false);
    setRepresentative(false);
    updateLearn(true);
  };

  const clear = () => {
    setEditMode(false);
    setBlockAddBtn(false);
    setRepresentative(false);
    setValidate({
      phone: 0,
      email: 0,
      lastName: 0,
      firstName: 0,
      middleName: 0,
    });
  };

  return (
    <div className="disciplines-table-comment right">
      <div className="disciplines-table-comment__controls">
        {editMode ? (
          <>
            <LmButton
              dataTest="closeRepresentative"
              type="button"
              variant="secondary"
              icon="filled-edit-close"
              iconSize={20}
              onClick={clear}
            />
            <Push
              size={12}
              orientation="horizontal"
            />
            <LmButton
              dataTest="submitRepresentative"
              type="button"
              color="success"
              variant="outline"
              icon="filled-edit-checkmark"
              iconSize={20}
              disabled={
                !(
                  fieldValidation(editObj.name.firstName) &&
                  fieldValidation(editObj.name.lastName) &&
                  fieldValidation(editObj.phone, true) &&
                  typeRepresentative?.value
                ) || checkAllValidate
              }
              onClick={send}
            />
          </>
        ) : (
          <DropDown
            dataTest="representativeOptions"
            component={() => <TableOptions />}
          >
            <>
              <LmListItem
                dataTest="editRepresentative"
                text="Редактировать"
                icon="outline-edit-edit"
                iconSize={20}
                onClick={() => setEditMode(true)}
              />
              <LmListItem
                dataTest="deleteRepresentative"
                text="Удалить"
                icon="outline-edit-trash-alt"
                iconSize={20}
                onClick={() => {
                  removeRepresentative(id);
                }}
              />
            </>
          </DropDown>
        )}
      </div>
    </div>
  );
};

export default Edit;
