import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import { SearchInitialFormData } from '../../../../../types/service';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { SelectOptionType } from '../../../../../types/entities';

type Props = {
  setOpenTreePopup: (value: boolean) => void;
  basicDirection: SelectOptionType | null | undefined;
  title: string;
  setBasicDirection: (value: SelectOptionType | null) => void;
  additionalCondition: boolean;
};

const FieldDirection: React.FC<Props> = ({
  setOpenTreePopup,
  basicDirection,
  title,
  setBasicDirection,
  additionalCondition,
}) => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  useEffect(() => {
    if (basicDirection) {
      setFieldValue('classificatorEKUId', basicDirection.value);
      setFieldValue('classificationName', basicDirection.label);
    }
  }, [basicDirection, setFieldValue]);

  const initDefaultValue = values.classificatorEKUId
    ? {
        value: values.classificatorEKUId,
        label: values.classificationName ?? '',
      }
    : null;

  const defaultValue = additionalCondition
    ? {
        value: EducationTypeEnum.DayCareCentersEducation,
        label: 'Группы по присмотру и уходу за детьми школьного возраста',
      }
    : initDefaultValue;

  return (
    <div className="flex items-end">
      <div className="flex-auto">
        <FormikSelect
          label={title}
          disabled={additionalCondition}
          size="small"
          withSearch
          options={[]}
          name="classificatorEKUId"
          showTooltip
          disabledPortalTooltip
          placeholder="Начните вводить или выберите..."
          loadOptions={async (query) => await lookupApi.getClassificator(query, values.educationTypeId as number)}
          defaultValue={defaultValue}
          selectedValue={(v?: SelectOptionType) => {
            if (v) {
              setFieldValue('classificationName', v.label);
            } else {
              setFieldValue('classificationName', '');
              setBasicDirection(null);
            }
          }}
        />
      </div>
      <Push
        size={10}
        orientation="horizontal"
      />
      <LmButton
        dataTest="options"
        type="button"
        variant="outline"
        icon="filled-controls-options-horizontal"
        iconSize={20}
        disabled={additionalCondition}
        onClick={() => {
          setOpenTreePopup(true);
        }}
      />
    </div>
  );
};

export default FieldDirection;
