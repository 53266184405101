import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInfoBox } from '@mes-ui/lemma';
import FormikInputNumber from '../../../../components/formik/formik-input-number';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikToggle from '../../../../components/formik/formik-toggle';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import lookupApi from '../../../../lib/api/lookup';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { formatDate, formatDateAndTime, formatTime } from '../../../../lib/utils/date';
import { TypesOfRepetitionEnum } from '../../../../mock-data/type-of-repetition';
import { ScheduleDataEx } from '../tests';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import DateField from '../../components/fields/add-plan/date';
import TimeField from '../../components/fields/add-plan/time';
import ScheduleRepetition from '../components/schedule-repetition';

type Props = {
  editMode: boolean;
  editCallBack: () => void;
  showEditButton: boolean;
  isRetro: boolean;
};

const ScheduleTests: React.FC<Props> = ({ editMode, editCallBack, isRetro, showEditButton }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { values, setFieldValue } = useFormikContext<ScheduleDataEx>();
  const enabledOnlyDraft = values.trainingGroupStatusId === TrainingGroupStatusEnum.Draft;

  useEffect(() => {
    setFieldValue(
      'typesOfRepetitionId',
      !values.repeat
        ? TypesOfRepetitionEnum.NoRepeat
        : values.typesOfRepetitionId === TypesOfRepetitionEnum.NoRepeat
          ? TypesOfRepetitionEnum.RepeatDaily
          : values.typesOfRepetitionId
    );
  }, [values.repeat, setFieldValue, values.typesOfRepetitionId]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => <>Расписание проведения испытаний</>}
        headingControl={() => {
          return !editMode && showEditButton ? (
            <button
              type="button"
              onClick={editCallBack}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-edit"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        <div className="container">
          {values.trainingGroupStatusId === TrainingGroupStatusEnum.Signed && values.sign && (
            <LmInfoBox
              dataTest="signSuccess"
              className="infobox--full-width"
              variant="success"
              description={`Пользователь: ${values.sign.signer}. Дата: ${formatDateAndTime(values.sign.date)}`}
              hidenFooter
            />
          )}

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Дата проведения испытаний {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              <div className="flex">
                <div>
                  {editMode ? (
                    <div
                      style={{
                        width: 180,
                      }}
                    >
                      <DateField
                        name="periodFrom"
                        dependentTimeName=""
                        showErrorImmediately={values.id > 0}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center flex-none table-row-item-height">
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      {values.periodFrom ? formatDate(values.periodFrom) : '—'}
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                    </div>
                  )}
                </div>
                <Push
                  size={16}
                  orientation="horizontal"
                />
                {!isRetro && (
                  <FormikToggle
                    label="Повторять"
                    name="repeat"
                    size="small"
                    disabled={!editMode || !enabledOnlyDraft}
                  />
                )}
                {values.repeat && (
                  <>
                    {(editMode || values.endDate) && (
                      <>
                        <Push
                          size={16}
                          orientation="horizontal"
                        />
                        <FormikToggle
                          label={editMode ? 'Дата окончания' : 'Окончить повтор'}
                          name="endDate"
                          size="small"
                          disabled={!editMode || !enabledOnlyDraft}
                        />
                      </>
                    )}

                    {values.endDate && (
                      <>
                        <Push
                          size={16}
                          orientation="horizontal"
                        />
                        {editMode ? (
                          <FormikDatePicker
                            size="small"
                            name="periodTo"
                            disabled={!enabledOnlyDraft}
                            width={180}
                          />
                        ) : (
                          <div className="flex items-center flex-none table-row-item-height">
                            <Push
                              size={8}
                              orientation="horizontal"
                            />
                            {values.periodTo ? formatDate(values.periodTo) : '—'}
                            <Push
                              size={8}
                              orientation="horizontal"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <ScheduleRepetition
            editMode={editMode}
            enabled={enabledOnlyDraft}
          />

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Время проведения испытаний {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <div className="flex">
                  <div className="flex-none input-time">
                    <TimeField
                      name="timeStart"
                      dependentDateName=""
                    />
                  </div>
                  <div className="flex items-center flex-none table-row-item-height">
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                    —
                    <Push
                      size={8}
                      orientation="horizontal"
                    />
                  </div>
                  <div className="flex-none input-time">
                    <TimeField
                      name="timeEnd"
                      dependentDateName=""
                    />
                  </div>
                </div>
              ) : (
                `${formatTime(values.timeStart)} — ${formatTime(values.timeEnd)}`
              )}
            </div>
          </div>

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Размер группы на испытания {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <FormikInputNumber
                  name="volume"
                  placeholder="0"
                />
              ) : (
                values.volume || '—'
              )}
            </div>
          </div>

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">Преподаватель</div>
            <div className="table-data__body">
              {editMode ? (
                <FormikSelect
                  required
                  name="teacher"
                  size="small"
                  withSearch
                  options={[]}
                  placeholder="Начните вводить..."
                  loadOptions={async (query) => await lookupApi.getTeachers(query, userProfile.organizationId)}
                  defaultValue={
                    values?.teacher
                      ? {
                          value: values.teacherId,
                          label: values?.teacher,
                        }
                      : null
                  }
                  selectedValue={(option: any) => {
                    setFieldValue('teacherId', option?.value || undefined);
                    setFieldValue('teacher', option?.label || undefined);
                  }}
                  disabled={!enabledOnlyDraft}
                />
              ) : (
                values.teacher || '—'
              )}
            </div>
          </div>

          {!isRetro && (
            <>
              <div className="table-data__item table-data__group">
                <div className="table-data__label table-data__label--main">
                  <div>Начало приема заявлений на Mos.ru {editMode && <span className="table-data__required" />}</div>
                </div>
                <div className="table-data__body">
                  <div className="table-data-grid-2">
                    <div className="table-data__group">
                      <div className="table-data__label">Дата</div>
                      <div className="table-data__body">
                        {editMode ? (
                          <DateField
                            name="requestStart"
                            dependentTimeName="requestTimeStart"
                            showErrorImmediately={values.id > 0}
                          />
                        ) : values.requestStart ? (
                          formatDate(values.requestStart)
                        ) : (
                          '—'
                        )}
                      </div>
                    </div>
                    <div className="table-data__group">
                      <div className="table-data__label">Время</div>
                      <div className="table-data__body">
                        <div className="flex-none input-time">
                          {editMode ? (
                            <TimeField
                              name="requestTimeStart"
                              dependentDateName="requestStart"
                            />
                          ) : (
                            values.requestTimeStart || '—'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-data__item table-data__group">
                <div className="table-data__label table-data__label--main">
                  <div>
                    Окончание приема заявлений на Mos.ru {editMode && <span className="table-data__required" />}
                  </div>
                </div>
                <div className="table-data__body">
                  <div className="table-data-grid-2">
                    <div className="table-data__group">
                      <div className="table-data__label">Дата</div>
                      <div className="table-data__body">
                        {editMode ? (
                          <DateField
                            name="requestEnd"
                            dependentTimeName="requestTimeEnd"
                            showErrorImmediately={values.id > 0}
                          />
                        ) : values.requestEnd ? (
                          formatDate(values.requestEnd)
                        ) : (
                          '—'
                        )}
                      </div>
                    </div>
                    <div className="table-data__group">
                      <div className="table-data__label">Время</div>
                      <div className="table-data__body">
                        <div className="flex-none input-time">
                          {editMode ? (
                            <TimeField
                              name="requestTimeEnd"
                              dependentDateName="requestEnd"
                            />
                          ) : (
                            values.requestTimeEnd || '—'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">Комментарий</div>
            <div className="table-data__body">
              {editMode ? (
                <FormikTextarea
                  name="comment"
                  maxRows={2}
                  placeholder="Введите..."
                  disabled={!enabledOnlyDraft}
                />
              ) : (
                values.comment || '—'
              )}
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default ScheduleTests;
