import { TreeNodeType, Key } from '@mosru/esz_uikit';

export type Element = {
  id: string;
  name: string;
};

type Data = Element & {
  programmLevel: Element[];
};

export const transformProfession = (data: Data[]) => {
  const profession = data?.map((item: Data) => {
    return {
      value: Number(item.id),
      name: item.name,
      state: item.programmLevel,
    };
  });

  const idLastElement = profession[profession.length - 1];

  let stage: { label: string; value: string }[] = [];

  if (profession.length) {
    stage = idLastElement.state?.map((item: Element) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  }

  return {
    profession: {
      value: idLastElement?.value,
      label: profession.map((item: { name: string }) => item.name).join(' / '),
    },
    stage: stage.length ? stage : [],
  };
};

export const appendData = (keyName: Key, dataState: TreeNodeType[], asyncData: TreeNodeType[]) => {
  for (const key in dataState) {
    if (dataState[key].key === keyName) {
      dataState[key].children = asyncData;
    } else if (dataState[key].children.length) {
      appendData(keyName, dataState[key].children, asyncData);
    }
  }
};

export const summElementTree = (array: TreeNodeType[]) => {
  return array.reduce((current: number, item: TreeNodeType) => {
    let result = current + 1;

    if (item.children.length) {
      result += summElementTree(item.children);
    }

    return result;
  }, 0);
};

export const searchTreeElements = (value: string, array: TreeNodeType[], expandedKeys: Key[] = []) => {
  return array.filter((item: TreeNodeType): number | boolean | null => {
    const title =
      typeof item.title === 'string'
        ? item.title
        : typeof item.title === 'object' && item.title && 'props' in item.title
          ? item.title.props.title
          : '';

    if (item.children) {
      item.children = searchTreeElements(value, item.children, expandedKeys);
      item.children.length && expandedKeys.push(item.key);
    }
    if (title.toLowerCase().indexOf(value) !== -1) {
      return true;
    }
    if (item.children) {
      return item.children.length;
    }

    return null;
  });
};

export const mockCategoryTreeModal = [
  {
    label: 'Детские объединения департамента образования',
    value: 1,
  },
  {
    label: 'Спортивные секции',
    value: 16,
  },
  {
    label: 'Дома творчества',
    value: 32,
  },
  {
    label: 'Детские объединения',
    value: 64,
  },
];
