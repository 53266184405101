import React, { useCallback, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Crop } from 'react-image-crop/src/ReactCrop';
import 'react-image-crop/dist/ReactCrop.css';
// @ts-ignore
import Files from 'react-files';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmInput } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import photoApi from '../../../../lib/api/photo';
import systemPropertyApi from '../../../../lib/api/system-propetry';
import { deletePhoto, getPhotoName, postPhoto } from '../../../../lib/utils/photo';
import { SystemPropertyEnum } from '../../../../mock-data/system-property-enum';
import { notify } from '../../../../redux/ducks/notifications';
import { ReactFilesError, ReactFilesFile } from '../../../../types/files';
import { ArrImageType } from '../../../../types/organization';
import PhotoItem from './photo-item';

type OrganizationPhotoProps = {
  isArchive: boolean;
  checkSigned: boolean;
  organizationId: number;
};

const defaultCrop: Crop = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: '%',
};
const defaultName = 'Без имени';
const maxCountImg = 12;

export const ACCEPTS = ['.jpg', '.jpeg', '.png'];
export const REG_EXP_ACCEPTS = new RegExp(`\\S\\.(${ACCEPTS.join('|').replaceAll('.', '')})$`, 'gi');
export const ACCEPTS_ERROR = `Допустимые расширения файла: ${ACCEPTS.join(', ')}`;

const OrganizationPhoto = ({ organizationId, isArchive, checkSigned }: OrganizationPhotoProps) => {
  const [currentFile, setCurrentFile] = useState<ReactFilesFile | null>(null); // обрабатываемый url blob
  const [currentImage, setCurrentImage] = useState<HTMLImageElement | null>(null); // кропнутая картинка в стейте
  const [currentName, setCurrentName] = useState('');
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [currentCrop, setCurrentCrop] = useState<Crop>(defaultCrop);
  const [arrImages, setArrImages] = useState<ArrImageType[]>([]);
  const [nameError, setNameError] = useState<string>();

  const dispatch = useDispatch();

  const getNotifyFileError = () =>
    dispatch(
      notify.danger({
        dataTest: 'correctFile',
        title: 'Некорректный файл. Выберите другое изображение и повторите загрузку.',
      })
    );

  const clear = useCallback(() => {
    setCurrentFile(null);
    setCurrentImage(null);
    setCurrentCrop(defaultCrop);
    setCurrentName('');
    setCurrentId(null);
    setNameError(undefined);
  }, []);

  useEffect(() => {
    const initPhotos = async () => {
      const photos = await photoApi.getOrganizationPhotos(organizationId);
      const s3Url = await systemPropertyApi.getSystemProperty(SystemPropertyEnum.S3StorageAPIUrl);

      if (photos) {
        const images: ArrImageType[] = [];

        for (const item of photos) {
          const image = await dataURItoBlob(`${s3Url}/${item.sourceFileName}`);

          images.push({
            id: item.id ?? 0,
            name: item.name,
            crop: {
              width: Math.round(item.width),
              height: Math.round(item.height),
              x: 0,
              y: 0,
              unit: 'px',
            },
            cropImage: image,
            sourceFileName: item.sourceFileName,
            originalFile: {
              ...image,
              lastModified: Math.random(),
              name: item.name,
              id: item.id?.toString() ?? '',
              extension: 'jpg',
              sizeReadable: image.size.toString(),
              preview: {
                type: 'image',
                url: URL.createObjectURL(image),
              },
            } as ReactFilesFile,
          });
        }
        setArrImages(images);
      }
    };

    initPhotos();
  }, [organizationId]);

  useEffect(() => {
    if (arrImages.length >= maxCountImg) {
      dispatch(
        notify.warning({
          dataTest: 'maxPhoto',
          title: 'Максимально можно сохранить 12 фотографий',
        })
      );
    }
  }, [arrImages, dispatch]);

  useEffect(() => {
    setNameError(currentName && !currentName.match(REG_EXP_ACCEPTS) ? ACCEPTS_ERROR : undefined);
  }, [currentName]);

  const save = async () => {
    if (!currentImage || !currentFile) {
      return;
    }
    const image = (await getCroppedImg(currentImage, currentCrop)) as Blob;

    const photoName = currentId
      ? arrImages.find(({ id }) => currentId === id)?.sourceFileName
      : getPhotoName(currentName, ACCEPTS);

    try {
      await postPhoto(photoName, image);
    } catch {
      return;
    }

    const photoId = await photoApi.putOrganizationPhoto({
      id: currentId,
      name: currentName || defaultName,
      orgId: organizationId,
      width: Math.round(currentCrop.width),
      height: Math.round(currentCrop.height),
      body: image,
      sourceFileName: photoName,
    });
    const crop: Crop = {
      ...currentCrop,
      x: 0,
      y: 0,
    };

    setArrImages(
      currentId
        ? arrImages.map((item) => {
          if (photoId === item.id) {
            return {
              id: photoId ?? 0,
              name: currentName,
              crop,
              cropImage: image,
              sourceFileName: photoName,
              originalFile: {
                ...currentFile,
                name: currentName,
                preview: {
                  ...currentFile.preview,
                  url: URL.createObjectURL(image),
                },
              },
            };
          }

          return item;
        })
        : [
            ...arrImages,
            {
              id: photoId ?? 0,
              name: currentName,
              crop,
              cropImage: image,
              sourceFileName: photoName,
              originalFile: {
                ...currentFile,
                name: currentName,
                preview: {
                  ...currentFile.preview,
                  url: URL.createObjectURL(image),
                },
              },
            },
          ]
    );
    clear();
  };

  const handleDelete = useCallback(
    async (item: ArrImageType) => {
      try {
        await deletePhoto(item.sourceFileName);
      } catch {
        return;
      }
      if (item.id === currentId) {
        clear();
      }

      await photoApi.deleteOrganizationPhoto(item.id, organizationId);
      setArrImages((prevState) => prevState.filter((arrItem) => arrItem.id !== item.id));
    },
    [clear, currentId, organizationId]
  );

  const handleSelectPhoto = useCallback((item: ArrImageType) => {
    setCurrentName(item.name);
    setCurrentFile(item.originalFile ?? null);
    setCurrentId(item.id);
    setCurrentCrop(item.crop);
  }, []);

  return (
    <>
      <Push size={12} />
      <div className="container">
        <div className="org-photo">
          <div className="org-photo__object">
            {currentFile ? (
              <div className="org-crop-photo">
                <div className="org-crop-photo__object">
                  <ReactCrop
                    src={currentFile.preview.url}
                    onImageError={() => {
                      clear();
                      getNotifyFileError();
                    }}
                    onImageLoaded={(image) => {
                      setCurrentImage(image);
                      setCurrentName(currentFile.name);
                      setCurrentCrop({
                        x: 0,
                        y: 0,
                        width: image.width,
                        height: image.height,
                        unit: 'px',
                      });

                      return false;
                    }}
                    crop={currentCrop}
                    onChange={(crop) => setCurrentCrop(crop)}
                  />
                </div>
                {!checkSigned && !isArchive && (
                  <div className="org-crop-photo__name">
                    <LmInput
                      dataTest="photoName"
                      id="photoName"
                      name="photoName"
                      value={currentName}
                      placeholder="Введите имя изображения"
                      isError={!!nameError}
                      explainText={nameError}
                      resettable
                      onChange={(value) => {
                        setCurrentName(value as string);
                      }}
                    />
                    <Push size={12} />
                    <div className="flex justify-end">
                      <LmButton
                        type="button"
                        variant="outline"
                        onClick={clear}
                      >
                        Отмена
                      </LmButton>
                      <Push
                        size={8}
                        orientation="horizontal"
                      />
                      <LmButton
                        type="button"
                        disabled={!currentName || !currentImage || !!nameError}
                        onClick={save}
                      >
                        Сохранить
                      </LmButton>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Files
                multiple={false}
                className="org-photo-dropzone"
                onChange={(files: ReactFilesFile[]) => {
                  if (!checkSigned && !isArchive && !(arrImages.length >= maxCountImg)) {
                    files[0] && setCurrentFile(files[0]);
                  }
                }}
                onError={(error: ReactFilesError) => {
                  getNotifyFileError();
                  console.error(error);
                }}
                accepts={ACCEPTS}
                clickable={!checkSigned && !isArchive && !(arrImages.length >= maxCountImg)}
              >
                <div className="org-photo-dropzone__title">Перетащите файл сюда, чтобы загрузить</div>
                <Push size={4} />
                Формат файлов: JPG, PNG
                <Push size={12} />
                <LmButton
                  type="button"
                  disabled={checkSigned || isArchive || arrImages.length >= maxCountImg}
                >
                  Выбрать файл
                </LmButton>
              </Files>
            )}
          </div>
          <div className="org-photo__list">
            <ul className="org-photo-ul">
              {arrImages.map((item) => (
                <PhotoItem
                  key={item.id}
                  item={item}
                  currentId={currentId}
                  checkSigned={checkSigned}
                  isArchive={isArchive}
                  onSelect={handleSelectPhoto}
                  onDelete={handleDelete}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function getCroppedImg(image: HTMLImageElement, crop: Crop) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return;
  }

  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/jpeg',
      0.85
    );
  });
}

const dataURItoBlob = async (dataURI: string) => (await fetch(dataURI)).blob();

export default OrganizationPhoto;
