import React from 'react';
import { LmIcon } from '@mes-ui/lemma';
import { DocListType } from '../../types/static-content';

const DocList: React.FC<{ data: DocListType[] }> = ({ data }) => {
  return (
    <ul className="doc-list">
      {data.map((item) => {
        const externalLink = item.link.match(/http/);

        return (
          <li
            key={item.title}
            className="doc-list__item"
          >
            <div className="container">
              <div className="doc-media">
                {externalLink && !item.isIconFile ? (
                  <LmIcon
                    icon="filled-edit-link-alt"
                    size={20}
                    className="doc-media__img"
                    color="var(--LM-neutrals-day-700)"
                  />
                ) : (
                  <LmIcon
                    icon="outline-files-file"
                    size={20}
                    className="doc-media__img"
                    color="var(--LM-neutrals-day-700)"
                  />
                )}
                <div className="doc-media__body">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.link}
                    className="doc-media__link"
                  >
                    {item.title}
                  </a>
                </div>
                {externalLink && !item.isIconFile ? (
                  <LmIcon
                    icon="filled-arrows-diagonal-arrow-right-up"
                    size={20}
                    className="doc-media__img"
                    color="var(--LM-neutrals-day-700)"
                  />
                ) : (
                  <LmIcon
                    icon="filled-files-download"
                    size={20}
                    className="doc-media__img"
                    color="var(--LM-neutrals-day-700)"
                  />
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default DocList;
