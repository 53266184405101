import React, { Key, Reducer, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInput, LmSelectNew } from '@mes-ui/lemma';
import MaskedInput from '../../../../components/masked-input';
import { addPhonePrefix, cutToNumbers, formatPhoneNumber } from '../../../../lib/utils/format-number';
import { phoneMask } from '../../../../lib/utils/mask';
import Edit from './edit';
import {
  defaultRepresentation,
  editName,
  NewRepresentatives,
  transformRepresentatives,
  typeRepresentation,
} from '../../../../lib/utils/learners';
import { Learner } from '../../../../types/learners';
import learnerApi from '../../../../lib/api/learner';
import { hasAccessObjectParent } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { checkValidation, validateMessage } from './helpers';
import { TypeRepresentationEnum } from '../../../../mock-data/type-representation-enum';
import { LoaderCustom } from '../../../../components/loader-custom';
import SimpleTable from '../../../../components/table/simple-table';

type Props = {
  loading: boolean;
  userData: Learner.Data;
  updateLearn: (val: boolean) => void;
};

const LearnerRepresentatives: React.FC<Props> = ({ userData, loading, updateLearn }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [validate, setValidate] = useReducer<
    Reducer<Learner.ValidateRepresentatives, Partial<Learner.ValidateRepresentatives>>
  >(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      lastName: 0,
      firstName: 0,
      middleName: 0,
      email: 0,
      phone: 0,
    }
  );

  const [checkAllValidate, setCheckAllValidate] = useState<boolean>(false);

  const [tableData, setTableData] = useState<Learner.TransformRepresentative[]>([]);

  const [representatives, setRepresentatives] = useState<Learner.TransformRepresentative[]>([]);

  const [editObj, setEditObj] = useState<Learner.TransformRepresentative>(NewRepresentatives);

  const [blockAddBtn, setBlockAddBtn] = useState<boolean>(false);

  // если true нельзя создать нового представителя
  const [newRepresentative, setNewRepresentative] = useState<boolean>(false);
  const [typeRepresentative, setTypeRepresentative] = useState<SelectOptionType>();

  useEffect(() => {
    setRepresentatives(transformRepresentatives(userData.representatives));
  }, [userData.representatives]);

  useEffect(() => {
    setTableData(representatives);
  }, [representatives]);

  const deleteRepresentative = async (id: number) => {
    if (id) {
      const representative = representatives.find((item: Learner.TransformRepresentative) => item.id === id);

      if (representative && representative.id) {
        await learnerApi.deleteRepresentative(userData.id, representative.id);
      }
      updateLearn(true);
    }
  };

  const addRepresentative = () => {
    delete NewRepresentatives.id;
    delete NewRepresentatives.type;

    const newTableData = new Array(NewRepresentatives).concat(tableData);

    setTableData(newTableData);
    setTypeRepresentative({
      label: 'Мать',
      value: TypeRepresentationEnum.Mother,
    });
    setEditObj(NewRepresentatives);
    setNewRepresentative(true);
  };

  const submitForm = async () => {
    await learnerApi.postRepresentative(userData.id, {
      email: editObj.email,
      phone: addPhonePrefix(editObj.phone),
      pupilId: userData.id,
      lastName: editObj.name.lastName,
      firstName: editObj.name.firstName,
      middleName: editObj.name.middleName,
      typeRepresentationId: typeRepresentative?.value as number,
    });
    updateLearn(true);
    setNewRepresentative(false);
  };

  const searchActiveType = (value: string) => typeRepresentation.find((item: SelectOptionType) => item.label === value);

  const errorLastName =
    !editObj.name.lastName && !newRepresentative ? 'Введите фамилию' : validateMessage(validate.lastName ?? 0);

  const errorFirstName =
    !editObj.name.firstName && !newRepresentative ? 'Введите имя' : validateMessage(validate.firstName ?? 0);

  const errorMiddleName = validateMessage(validate.middleName ?? 0);

  const errorPhone =
    !editObj.phone && !newRepresentative ? 'Введите номер телефона' : validateMessage(validate.phone ?? 0);

  const errorEmail = validateMessage(validate.email ?? 0);

  useEffect(() => {
    setCheckAllValidate(!!Object.values(validate).filter((item) => item).length);
  }, [validate]);

  const handleNameChange = (name: string) => (value: Key) => {
    const valueString = value as string;

    setEditObj((prev: Learner.TransformRepresentative) => ({
      ...prev,
      name: {
        ...prev.name,
        [name]: valueString,
      },
    }));

    checkValidation(valueString, setValidate, name);
  };

  const handlePhoneChange = (value: Key) => {
    const valueString = value as string;

    setEditObj((prev: Learner.TransformRepresentative) => {
      checkValidation(cutToNumbers(valueString) || '', setValidate, 'phone', false, true);

      return {
        ...prev,
        phone: valueString,
      };
    });
  };

  const handleEmailChange = (value: Key) => {
    const valueString = value as string;

    setEditObj((prev: Learner.TransformRepresentative) => {
      return {
        ...prev,
        email: valueString,
      };
    });

    checkValidation(valueString, setValidate, 'email', true);
  };

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Законные представители
            <span className="color-gray-dark">
              {' \u00A0'} {tableData.length ? tableData.length : ''}
            </span>
          </>
        )}
        headingControl={() =>
          hasAccessObjectParent(userProfile, accessObject.Pupils, accessAction.Edit) &&
          !userData.isArchive && (
            <button
              disabled={newRepresentative || blockAddBtn}
              type="button"
              onClick={addRepresentative}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-plus"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          )
        }
      >
        {loading ? (
          <LoaderCustom size={20} />
        ) : tableData.length ? (
          <SimpleTable
            dataTest="representatives"
            data={tableData}
            // pageSize={Number.MAX_SAFE_INTEGER}
            columns={[
              {
                dataIndex: 'type',
                title: 'Тип представительства',
                render: (item: any) => {
                  editObj.id === item.id && setBlockAddBtn(true);

                  return editObj.id === item.id ? (
                    <LmSelectNew
                      dataTest="selectTypeRepresentative"
                      name="typeRepresentative"
                      onChange={(selectedOption: SelectOptionType | null) => {
                        const option = selectedOption as SelectOptionType;

                        setTypeRepresentative(option);

                        setEditObj((prev: Learner.TransformRepresentative) => ({
                          ...prev,
                          typeRepresentationId: option.value as number,
                        }));
                      }}
                      options={typeRepresentation}
                      value={typeRepresentative || null}
                      withSearch
                      size="small"
                      placeholder="Тип представительства"
                      grouped={false}
                      multiple={false}
                      settingDropdownAsPopover={{
                        disablePortal: true,
                      }}
                    />
                  ) : (
                    item.type
                  );
                },
                width: '21%',
              },
              {
                dataIndex: 'name',
                title: 'Фамилия, имя, отчество',
                render: (item: any) => {
                  return editObj.id === item.id ? (
                    <>
                      <LmInput
                        dataTest="lastName"
                        name="lastName"
                        value={editObj.name.lastName}
                        placeholder="Фамилия"
                        resettable
                        isError={!!errorLastName}
                        explainText={errorLastName}
                        onChange={handleNameChange('lastName')}
                      />

                      <Push
                        orientation="vertical"
                        size={12}
                      />

                      <LmInput
                        dataTest="firstName"
                        name="firstName"
                        value={editObj.name.firstName}
                        placeholder="Имя"
                        resettable
                        isError={!!errorFirstName}
                        explainText={errorFirstName}
                        onChange={handleNameChange('firstName')}
                      />

                      <Push
                        orientation="vertical"
                        size={12}
                      />
                      <LmInput
                        dataTest="middleName"
                        name="middleName"
                        value={editObj.name.middleName}
                        placeholder="Отчество (если есть)"
                        resettable
                        isError={!!errorMiddleName}
                        explainText={errorMiddleName}
                        onChange={handleNameChange('middleName')}
                      />
                    </>
                  ) : (
                    editName(item.name.lastName, item.name.firstName, item.name.middleName)
                  );
                },
                width: '21%',
              },
              {
                dataIndex: 'phone',
                title: 'Номер телефона',
                render: (item: any) => {
                  return editObj.id === item.id ? (
                    <MaskedInput
                      dataTest="phone"
                      maskRegex={phoneMask}
                      name="phone"
                      value={editObj.phone}
                      placeholder="(XXX) XXX-XX-XX"
                      leftPrefix="+7"
                      resettable
                      isError={!!errorPhone}
                      explainText={errorPhone}
                      onChange={handlePhoneChange}
                    />
                  ) : (
                    item.phone
                  );
                },
                width: '21%',
              },
              {
                dataIndex: 'email',
                title: 'E-mail',
                render: (item: any) => {
                  return editObj.id === item.id ? (
                    <LmInput
                      dataTest="email"
                      name="email"
                      value={editObj.email}
                      placeholder="E-mail"
                      resettable
                      isError={!!errorEmail}
                      explainText={errorEmail}
                      onChange={handleEmailChange}
                    />
                  ) : (
                    item.email
                  );
                },
                width: '21%',
              },

              {
                dataIndex: '',
                title: '',
                render: (item: any) =>
                  hasAccessObjectParent(userProfile, accessObject.Pupils, accessAction.Edit) &&
                  !userData.isArchive && (
                    <Edit
                      id={item.id}
                      editObj={editObj}
                      pupilId={userData.id}
                      setValidate={setValidate}
                      updateLearn={updateLearn}
                      submitHandler={submitForm}
                      setBlockAddBtn={setBlockAddBtn}
                      editMode={editObj.id === item.id}
                      checkAllValidate={checkAllValidate}
                      typeRepresentative={typeRepresentative}
                      setRepresentative={setNewRepresentative}
                      removeRepresentative={deleteRepresentative}
                      setEditMode={(mode) => {
                        const data = transformRepresentatives(userData.representatives);

                        setTableData(data);

                        setEditObj(
                          mode
                            ? {
                                ...item,
                                phone: formatPhoneNumber(item.phone, false),
                              }
                            : NewRepresentatives
                        );
                        setTypeRepresentative(item.type ? searchActiveType(item.type) : defaultRepresentation);
                      }}
                    />
                  ),
              },
            ]}
          />
        ) : (
          <div className="learner-no-data">Сведения о законных представителях отсутствуют</div>
        )}
      </Panel>
    </>
  );
};

export default LearnerRepresentatives;
