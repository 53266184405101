import { apiService } from '../../config/constants';
import { fetchRequest } from './index';
import { OrderByTypeRequest, PaginationRequest, PagingList } from '../../types/entities';
import { Discipline, SearchDisciplinesFormData } from '../../types/discipline';

type GetDisciplineProps = SearchDisciplinesFormData & OrderByTypeRequest & PaginationRequest;

const getDisciplines = async (params?: GetDisciplineProps): Promise<PagingList<Discipline>> =>
  await fetchRequest.post(`${apiService.data}/Discipline/search`, params, {
    isProtected: true,
  });

const getDiscipline = async (id: number) =>
  await fetchRequest.get(
    `${apiService.data}/Discipline/${id}`,
    {},
    {
      isProtected: true,
    }
  );

const deleteDiscipline = async (id: number) =>
  await fetchRequest.delete(
    `${apiService.data}/Discipline/${id}`,
    {
      id,
    },
    {
      isProtected: true,
    }
  );

const saveDiscipline = async (params: Discipline) =>
  await fetchRequest.post(`${apiService.data}/Discipline`, params, {
    isProtected: true,
  });

const updateDiscipline = async (params: Discipline) =>
  await fetchRequest.put(`${apiService.data}/Discipline/${params.id}`, params, {
    isProtected: true,
  });

const restoreDiscipline = async (id: number) =>
  await fetchRequest.put(
    `${apiService.data}/Discipline/Restoration/${id}`,
    {},
    {
      isProtected: true,
    }
  );

const disciplineApi = {
  getDisciplines,
  getDiscipline,
  deleteDiscipline,
  updateDiscipline,
  saveDiscipline,
  restoreDiscipline,
};

export default disciplineApi;
