import { useField } from 'formik';
import React from 'react';
import classNames from 'classnames';
import { FieldProps } from '../../../../components/fields/field';
import FormikInputNumber from '../../../../components/formik/formik-input-number';

type PupilInGroupFieldProps = FieldProps<string> & {
  isNewServiceClass?: boolean;
};

const PupilInGroupField = ({
  label = 'Даты занятий',
  name,
  editMode,
  required,
  defaultValue = '—',
  isNewServiceClass,
}: PupilInGroupFieldProps) => {
  const fieldMaxPupilName = 'capacity';
  const fieldEnrolledName = `${name}`;

  const [fieldMaxPupil] = useField(fieldMaxPupilName);
  const [fieldEnrolled] = useField(fieldEnrolledName);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        {' '}
        {label} {editMode && required ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        <div className="table-data-grid-2">
          <div className="table-data__group">
            <div className="table-data__label">Предельное число</div>
            <div className="table-data__body">
              {editMode ? (
                <FormikInputNumber
                  placeholder="0"
                  maxLength={8}
                  name={fieldMaxPupilName}
                  excludeZero
                />
              ) : (
                fieldMaxPupil.value || defaultValue
              )}
            </div>
          </div>
          {!isNewServiceClass && (
            <div className="table-data__group">
              <div className="table-data__label">Зачислено</div>
              <div className={classNames('table-data__body', editMode && 'table-data-row-control')}>
                {fieldEnrolled.value || defaultValue}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PupilInGroupField;
