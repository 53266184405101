import { useFormikContext } from 'formik';
import React from 'react';
import { LmIcon } from '@mes-ui/lemma';
import { DocumentTypeEnum } from '../../../../../../mock-data/type-document';
import { Learner } from '../../../../../../types/learners';
import { DocIssuer } from './doc-issuer';
import { DocSeriesNumberDate } from './doc-series-number-date';
import { DocumentType } from './document-type';
import { SelectOptionType } from '../../../../../../types/entities';

type DocumentProps = {
  index: number;
  editMode: boolean;
};

const initDocument = {
  documentTypeId: DocumentTypeEnum.BirthCertificate,
  docSeries: '',
  docNumber: '',
  docDate: undefined,
  docIssuer: '',
};

const Document = ({ index, editMode }: DocumentProps) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const handleChangeDocType = (option?: SelectOptionType) => {
    if (option?.value) {
      setFieldValue(`pupilDocument[${index}]`, {
        ...initDocument,
        documentTypeId: option.value,
      });
    }
  };

  return (
    <>
      <DocumentType
        index={index}
        editMode={editMode}
        onChangeDocType={handleChangeDocType}
      />

      <DocSeriesNumberDate
        index={index}
        editMode={editMode}
      />

      <DocIssuer
        index={index}
        name={`pupilDocument[${index}].docIssuer`}
        codeName={`pupilDocument[${index}].docIssuerCode`}
        editMode={editMode}
        value={values.pupilDocument[index]?.docIssuer}
      />

      {editMode &&
        values.pupilDocument[0]?.documentTypeId === DocumentTypeEnum.BirthCertificate &&
        index === values.pupilDocument.length - 1 && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main" />
          <div className="table-data__body">
            <button
              id="btn-add-doc"
              type="button"
              className="icon-group"
              onClick={() =>
                setFieldValue('pupilDocument', [
                  ...values.pupilDocument,
                  {
                    ...initDocument,
                    id: Math.random(),
                  },
                ])
              }
            >
              <span className="icon-group__icon">
                <LmIcon
                  icon="filled-edit-plus"
                  size={20}
                  color="var(--LM-blue-200)"
                />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Document;
