import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Panel } from '@mosru/esz_uikit';
import { pageSizeOptions } from '../../../../mock-data';
import { ServiceClassPupil, ServiceClassTableContextType } from '../../../../types/service-class';

import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceClassContext } from '../../service-class';
import TabsGroup from '../../components/table/tabs';
import HeadingControl from '../../components/table/heading-control';
import TableGroupComposition from './table';
import PanelButtons from './panel-buttons';
import { useFetchTable } from '../../../../hooks/fetch-table';
import { ServiceClassTableContext } from '../../utils';

const ServiceClassTable: React.FC = () => {
  const { serviceClassData } = useContext(ServiceClassContext);

  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [pageNum, setPageNum] = useState(0);
  const [selected, setSelected] = useState<number[]>([]);
  const [active, setActive] = useState<boolean>(true);
  const [learners, setLearners] = useState<ServiceClassPupil[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageNum(0);
  }, [pageSize]);

  const [fetchTable, setSearch, searchString, filterLearners] = useFetchTable(
    pageSize,
    setLoading,
    pageNum,
    setPageNum,
    !active,
    setLearners,
    setTotalCount,
    serviceClassData.id,
    EducationTypeEnum.SportEducation
  );

  const value: ServiceClassTableContextType = useMemo(
    () => ({
      pageSize,
      setPageSize,
      setLoading,
      loading,
      selected,
      setSelected,
      setActive,
      setOpenDetails,
      setTotalCount,
      openDetails,
      totalCount,
      active,
      setSearch,
      pageNum,
      setPageNum,
      filterLearners,
      searchString,
      fetchTable,
      serviceClassData,
      learners,
    }),
    [
      pageSize,
      setPageSize,
      setLoading,
      loading,
      selected,
      setSelected,
      setActive,
      setOpenDetails,
      setTotalCount,
      openDetails,
      totalCount,
      active,
      setSearch,
      pageNum,
      setPageNum,
      filterLearners,
      searchString,
      fetchTable,
      serviceClassData,
      learners,
    ]
  );

  return (
    <ServiceClassTableContext.Provider value={value}>
      <Panel
        title={() => <TabsGroup />}
        headingControl={() => <HeadingControl />}
      >
        <TableGroupComposition />
      </Panel>

      <PanelButtons />
    </ServiceClassTableContext.Provider>
  );
};

export default ServiceClassTable;
